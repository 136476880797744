import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  getEmployeeWithholdRequests,
  doApproveDeductionRequest,
  confirmEmployeeWithHoldRequest,
} from "../../../store/payroll/actions";
import {
  Typography,
  Box,
  IconButton,
  Tooltip,
  TextField,
  Button,
} from "@mui/material";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  CardContent,
  Toolbar,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import loadingGif from "../../assets/loading.gif";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import RejectDeductionRequestDialog from "../components/RejectDeductionRequestDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import DeductionRejectionReasonDialog from "../components/DeductionRejectionReasonDialog";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const RequestedDeductions = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Payrolls | Requested deductions";
  });

  const {
    user,
    loading,
    employeeWithholdRequests,
    getEmployeeWithholdRequests,
    confirmEmployeeWithHoldRequest,
  } = props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const [baseUrl, setBaseUrl] = useState(
    window.location.protocol + "//" + window.location.host
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [filteredWithholdRequests, setFilteredWithholdRequests] = useState([
    ...employeeWithholdRequests,
  ]);
  const [showSupportingDocument, setShowSupportingDocument] = useState(false);

  const [selectedEmployeeWithhold, setSelectedEmployeeWithhold] =
    useState(null);
  const [confirmRejectedEmployeeWithhold, setConfirmRejectedEmployeeWithhold] =
    useState(false);

  const [showRejectionReason, setShowRejectionReason] = useState(false);

  useEffect(() => {
    getEmployeeWithholdRequests();
  }, []);

  useEffect(() => {
    const filtered = [...employeeWithholdRequests].filter(
      ({
        creditorName,
        firstName,
        lastName,
        positionName,
        employeeId,
        bankName,
        acountNumber,
      }) =>
        (
          (creditorName || "") +
          (firstName || "") +
          (lastName || "") +
          (positionName || "") +
          (employeeId || "") +
          (bankName || "") +
          (acountNumber || "")
        )
          .toLowerCase()
          .includes(searchTeam.toLowerCase())
    );

    setFilteredWithholdRequests(filtered);
    paginate(filtered);
  }, [searchTeam, employeeWithholdRequests]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(filteredWithholdRequests);
  }, [
    itemOffset,
    itemsPerPage,
    employeeWithholdRequests,
    filteredWithholdRequests,
  ]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredWithholdRequests.length || 0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };
  const onClose = () => {
    setShowSupportingDocument(false);
  };
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>REQUESTED DEDUCTIONS</span>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  disabled={!employeeWithholdRequests.length}
                  onSearch={onSearch}
                  placeholder="Search…"
                />
              </Box>
            </Toolbar>
          </AppBar>
          {confirmRejectedEmployeeWithhold && (
            <RejectDeductionRequestDialog
              confirmRejectedEmployeeWithhold={confirmRejectedEmployeeWithhold}
              setConfirmRejectedEmployeeWithhold={
                setConfirmRejectedEmployeeWithhold
              }
              selectedEmployeeWithhold={selectedEmployeeWithhold}
            />
          )}

          {showRejectionReason && (
            <DeductionRejectionReasonDialog
              showRejectionReason={showRejectionReason}
              setShowRejectionReason={setShowRejectionReason}
              reason={selectedEmployeeWithhold.rejectReason}
            />
          )}
          {showSupportingDocument && (
            <>
              <Dialog
                aria-labelledby="customized-dialog-title"
                open={showSupportingDocument}
                fullWidth
                maxWidth="lg"
              >
                <DialogTitle className="text-primary">
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    className="mr-5"
                  >
                    <AttachFileIcon className="mb-2 mr-2 text-dark" />
                    View Supporting document
                  </Typography>

                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </DialogTitle>

                <DialogContent
                  dividers
                  sx={{ margin: 0, padding: 0, height: "600px" }}
                >
                  <div style={{ height: "100%" }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={baseUrl + selectedEmployeeWithhold.documentUrl}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              // margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              // boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              // MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              className="py-0"
              style={{
                height: "auto",
                maxHeight: "63vh",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <table className="table-striped table-hover table-sm  fixTableHead mt-3">
                <thead>
                  <tr>
                    <td style={{ backgroundColor: "#f6f6f6" }}>#</td>
                    <td style={{ backgroundColor: "#f6f6f6" }}>EmployeeId</td>
                    <td style={{ backgroundColor: "#f6f6f6" }}>
                      EmployeeNames
                    </td>
                    <td style={{ backgroundColor: "#f6f6f6" }}>Position</td>
                    <td style={{ backgroundColor: "#f6f6f6" }}>Creditor</td>
                    <td
                      className="text-center"
                      style={{ backgroundColor: "#f6f6f6" }}
                    >
                      Request Type
                    </td>

                    <td
                      className="text-center"
                      style={{ backgroundColor: "#f6f6f6" }}
                    >
                      Requested By
                    </td>

                    <td
                      colSpan={2}
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-center"
                    >
                      Monthly Amount Commited
                    </td>
                    <td
                      style={{ backgroundColor: "#e9ecef" }}
                      className="text-right"
                    >
                      TotalToPay
                    </td>
                    <td
                      style={{ backgroundColor: "#f6f6f6" }}
                      className="text-right"
                    >
                      EffectiveDate
                    </td>
                    <td
                      style={{ backgroundColor: "#f6f6f6" }}
                      className="text-right"
                    >
                      EndDate
                    </td>

                    <td
                      style={{ backgroundColor: "#f6f6f6" }}
                      className="text-center"
                    >
                      Supporting Docs
                    </td>

                    <td
                      className="text-center"
                      style={{ backgroundColor: "#f6f6f6" }}
                    >
                      Status
                    </td>

                    <td
                      className="text-center"
                      style={{ backgroundColor: "#f6f6f6" }}
                    ></td>
                  </tr>
                </thead>

                <tbody>
                  {paginatedItems.map((unitWithhold, uaIndex) => (
                    <EmployeeDeductionItem
                      key={uaIndex}
                      unitWithhold={unitWithhold}
                      setConfirmRejectedEmployeeWithhold={
                        setConfirmRejectedEmployeeWithhold
                      }
                      setShowRejectionReason={setShowRejectionReason}
                      setSelectedEmployeeWithhold={setSelectedEmployeeWithhold}
                      setShowSupportingDocument={setShowSupportingDocument}
                      uaIndex={uaIndex}
                      confirmEmployeeWithHoldRequest={
                        confirmEmployeeWithHoldRequest
                      }
                    />
                  ))}
                </tbody>
              </table>

              <div
                className="justify-content-center"
                style={{ overflow: "hidden" }}
              >
                {!!paginatedItems.length &&
                  employeeWithholdRequests.length >= itemsPerPage && (
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                      activeLinkClassName="active"
                    />
                  )}
              </div>

              {!filteredWithholdRequests.length && !loading && (
                <div className="text-center">
                  <p className="lead">No deduction requests found</p>
                </div>
              )}

              {loading && !employeeWithholdRequests.length && (
                <div className="text-center">
                  <img src={loadingGif} height="128" alt="loading" />
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  employeeWithholdRequests,
  supportingDocumentTypes,
}) => {
  return { user, loading, employeeWithholdRequests, supportingDocumentTypes };
};
export default connect(mapStateToProps, {
  getEmployeeWithholdRequests,
  doApproveDeductionRequest,
  confirmEmployeeWithHoldRequest,
})(RequestedDeductions);

const EmployeeDeductionItem = (props) => {
  const {
    unitWithhold,
    setConfirmRejectedEmployeeWithhold,
    setSelectedEmployeeWithhold,
    setShowRejectionReason,
    setShowSupportingDocument,
    uaIndex,
    confirmEmployeeWithHoldRequest,
  } = props;

  const [confirmApproveEmployeeWithhold, setConfirmApproveEmployeeWithhold] =
    useState(false);

  const [status, setStatus] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const [rejectReasonHasError, setRejectReasonHasError] = useState(false);

  return (
    <>
      <tr
        style={{
          background: uaIndex % 2 === 0 ? "" : "#f6f7f7",
          borderBottom: "2px dotted rgb(7, 142, 206)!important",
        }}
      >
        <td>{uaIndex + 1}</td>
        <td>{unitWithhold.employeeId}</td>
        <td>
          {unitWithhold.lastName} {unitWithhold.firstName}
        </td>
        <td>
          {unitWithhold.positionName}
          <br />{" "}
          <small className="text-primary">({unitWithhold.unitName})</small>{" "}
        </td>
        <td>
          {unitWithhold.creditorName} <br />
          <small>
            {unitWithhold.acountNumber} ({unitWithhold.bankName})
          </small>{" "}
        </td>
        <td className="text-center">
          <span
            className={`d-block badge badge-${
              unitWithhold.requestType === "S" ? "danger" : "secondary"
            }`}
          >
            {unitWithhold.requestType === "N"
              ? "New"
              : unitWithhold.requestType === "I"
              ? "Increase"
              : unitWithhold.requestType === "D"
              ? "Decrease"
              : unitWithhold.requestType === "S"
              ? "Stopping"
              : ""}
          </span>
        </td>

        <td className="text-center text-uppercase">{unitWithhold.createdBy}</td>

        <td style={{ backgroundColor: "#e9ecef" }} className="text-right">
          {unitWithhold.calculationMethodId === 1 ? (
            <>
              {unitWithhold.requestType === "N" && (
                <span>
                  <CurrencyFormat
                    value={unitWithhold.amount || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              )}

              {unitWithhold.requestType === "I" && (
                <span>
                  <CurrencyFormat
                    value={unitWithhold.currentAmount || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  {" => "}
                  <CurrencyFormat
                    value={unitWithhold.amount || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              )}

              {unitWithhold.requestType === "D" && (
                <span>
                  <CurrencyFormat
                    value={unitWithhold.currentAmount || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  {" => "}
                  <CurrencyFormat
                    value={
                      unitWithhold.currentAmount - unitWithhold.amount > 0
                        ? unitWithhold.amount
                        : 0
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              )}

              {unitWithhold.requestType === "S" && (
                <span>
                  <CurrencyFormat
                    value={unitWithhold.currentAmount || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                  {" => "}
                  <CurrencyFormat
                    value={0}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </span>
              )}
            </>
          ) : (
            unitWithhold.rate
          )}
          <span
            className={`badge ml-1 badge-${
              unitWithhold.calculationMethodId === 1
                ? "light text-info"
                : "secondary"
            }`}
          >
            {" "}
            {unitWithhold.calculationMethodId === 1 ? "RWF" : "RATE"}
          </span>
        </td>

        <td
          style={{ backgroundColor: "#e9ecef", borderLeft: "none" }}
          className="text-left"
        >
          <span
            className="badge badge-light text-uppercase text-info"
            style={{ fontSize: "12px" }}
          >
            {"/ " + unitWithhold.basePayrollComponentName}
          </span>
          {/* / {unitWithhold.basePayrollComponentName.replace(/ /g, "")}{" "} */}
        </td>

        <td style={{ backgroundColor: "#e9ecef" }} className="text-right">
          {!!unitWithhold.totalAmount && (
            <span>
              <CurrencyFormat
                value={unitWithhold.totalAmount || 0}
                displayType={"text"}
                thousandSeparator={true}
              />
              <span className={`badge ml-1 badge-light text-info`}>RWF</span>
            </span>
          )}
          {!unitWithhold.totalAmount && <span>-</span>}
        </td>

        <td className="text-right">
          {unitWithhold.startDate
            ? moment(unitWithhold.startDate).format("yyyy-MM-DD")
            : ""}{" "}
        </td>

        <td className="text-right">
          {unitWithhold.endDate
            ? moment(unitWithhold.endDate).format("yyyy-MM-DD")
            : ""}{" "}
        </td>

        <td className="text-center " style={{ borderLeft: "none" }}>
          {unitWithhold.documentUrl && (
            <button
              className="btn btn-sm btn-link"
              style={{ cursor: "pointer" }}
              // title={"Attached supporting document"}
              onClick={() => {
                setSelectedEmployeeWithhold(unitWithhold);
                setShowSupportingDocument(true);
              }}
            >
              <AttachFileIcon />
              SupportingDocs
            </button>
          )}
        </td>

        <td className="text-center">
          <span
            className={`d-block badge badge-${
              unitWithhold.status === "N"
                ? "secondary"
                : unitWithhold.status === "A"
                ? "success"
                : unitWithhold.status === "R"
                ? "danger"
                : "pill"
            }`}
          >
            {unitWithhold.status === "N"
              ? "Penging"
              : unitWithhold.status === "A"
              ? "Approved"
              : unitWithhold.status === "R"
              ? "Rejected"
              : ""}
          </span>
        </td>

        <td className="text-center">
          <span className="ml-2">
            {unitWithhold.status === "N" && (
              <>
                <button
                  className="btn btn-success btn-sm mr-1 py-0"
                  onClick={() => {
                    setStatus("A");
                    setConfirmApproveEmployeeWithhold(true);
                  }}
                >
                  Approve
                </button>
                <button
                  className="btn btn-danger btn-sm py-0"
                  onClick={() => {
                    setStatus("R");
                    setConfirmApproveEmployeeWithhold(true);
                  }}
                >
                  Reject
                </button>
              </>
            )}
          </span>
        </td>
      </tr>

      {confirmApproveEmployeeWithhold && (
        <ConfirmationDialog
          confirmationDialog={confirmApproveEmployeeWithhold}
          message={
            <div>
              Are you sure you want to
              <strong
                className={`text-${status === "A" ? "success" : "danger"}`}
              >
                {status === "A" ? " approve " : " reject "}
              </strong>
              this request?
              {status === "R" && (
                <div className="mt-2">
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={3}
                    name="reason"
                    autoFocus
                    label="Reason for rejection"
                    placeholder="Reason for rejection"
                    className="mt-3"
                    value={rejectReason}
                    variant="outlined"
                    onChange={(e) => {
                      setRejectReason(e.target.value || "");
                      setRejectReasonHasError(false);
                    }}
                  />
                  {rejectReasonHasError && (
                    <small className="text-danger">
                      Reason for rejection is required
                    </small>
                  )}
                </div>
              )}
            </div>
          }
          setConfirmationDialog={setConfirmApproveEmployeeWithhold}
          onYes={() => {
            if (!rejectReason && (!status || status === "R"))
              return setRejectReasonHasError(true);

            confirmEmployeeWithHoldRequest(
              {
                requestId: unitWithhold.id,
                statusId: status,
                commitmentId: unitWithhold.commitmentId,
                rejectReason,
              },
              setConfirmApproveEmployeeWithhold
            );
          }}
        />
      )}
    </>
  );
};
