import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  TextField,
  DialogActions,
} from "@mui/material";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import {
  getPanels,
  removePanel,
  savePanel,
  updatePanel,
} from "../../../store/recruitment/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
import { doesExamEnded, doesExamStarted } from "../../common/components/Utils";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { showError } from "../../toastify";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const Panel = (props) => {
  const {
    loading,
    showPanels,
    setShowPanels,
    selectedExam,
    panels,
    getPanels,
    removePanel,
    savePanel,
    updatePanel,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [selectedPanel, setSelectedPanel] = useState(null);
  const [panelId, setPanelId] = useState("0");
  const [confirmRemovePanel, setConfirmRemovePanel] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const onClose = () => {
    setShowPanels(false);
  };

  useEffect(() => {
    getPanels(selectedExam.id);
  }, [selectedExam]);

  return (
    <>
      <Dialog open={showPanels} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6" noWrap component="div" maxWidth={550}>
            <span style={{ color: "rgb(7, 142, 206)" }}>Panels</span>
            <span className="ml-2">
              @
              {selectedExam.advertisement
                ? selectedExam.advertisement.requisition.position.name
                : ""}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              height: "500px",
            }}
          >
            <div className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  if (hasPermissions(["IS_CBM", "IS_HR"])) {
                    setShowAdd(true);
                  } else {
                    showError("Make sure that you are HR or CBM!");
                  }
                }}
                style={{
                  ...InactiveButton,
                  // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                  float: "right",
                }}
                size="md"
              >
                <span className="material-icons">add</span> Panel
              </Button>
            </div>
            <div className="mt-3">
              <PreLoader isLoading={loading && !panels.length} />

              <NoRecordMessage
                isEmpty={!loading && !panels.length}
                title={"No Panels found!"}
              />

              {!loading && !!panels.length && (
                <div className="mt-3">
                  <table className="table table-striped table-hover table-sm  fixTableHead">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col" className="text-left">
                          Panel
                        </th>

                        <th scope="col" className="text-right">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {panels.map((panel, index) => (
                        <tr key={index}>
                          <td className="text-left">
                            <span style={{ fontSize: "10px" }}>
                              {panel.panel}{" "}
                            </span>
                          </td>

                          <td className="text-right">
                            {" "}
                            <button
                              disabled={!hasPermissions(["IS_CBM", "IS_HR"])}
                              onClick={() => {
                                setSelectedPanel(panel);
                                setShowAdd(true);
                              }}
                              className="btn btn-primary mr-2"
                            >
                              {" "}
                              <EditIcon />
                            </button>
                            <button
                              disabled={
                                !hasPermissions(["IS_CBM", "IS_HR"]) ||
                                panelId === panel.id
                              }
                              onClick={() => {
                                setSelectedPanel(panel);
                                setConfirmRemovePanel(true);
                              }}
                              className="btn btn-danger"
                            >
                              {panelId === panel.id ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                <RemoveIcon />
                              )}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {showAdd && (
              <AddPanel
                showAdd={showAdd}
                setShowAdd={setShowAdd}
                savePanel={savePanel}
                updatePanel={updatePanel}
                exam={selectedExam}
                panel={selectedPanel || null}
              />
            )}
            {confirmRemovePanel && (
              <ConfirmationDialog
                confirmationDialog={confirmRemovePanel}
                message={`Are you sure you want to remove this panel @${selectedPanel.panel}?`}
                setConfirmationDialog={setConfirmRemovePanel}
                onYes={() => {
                  removePanel(
                    selectedPanel,
                    setPanelId,
                    setConfirmRemovePanel,
                    setSelectedPanel
                  );
                }}
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, panels }) => {
  return {
    loading,
    panels,
  };
};
export default connect(mapStateToProps, {
  getPanels,
  removePanel,
  savePanel,
  updatePanel,
})(Panel);
const AddPanel = (props) => {
  const { showAdd, setShowAdd, savePanel, updatePanel, panel, exam } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    panel: panel?.panel || "",
    examId: exam?.id || "",
    id: panel?.id || "",
  });

  const onSave = () => {
    if (!formData.panel) {
      showError("Please fill required field");
      return false;
    }
    panel
      ? updatePanel(formData, setIsLoading, onClose)
      : savePanel(formData, setIsLoading, onClose);
  };

  const onClose = () => {
    setShowAdd(false);

    setFormData({ panel: "", examId: "" });
  };

  return (
    <Dialog onClose={onClose} open={showAdd} fullWidth maxWidth="xs">
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          {!!panel?.id ? "Edit" : "Add new"} panel
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="row no-gutters mt-2">
          <div className="col-9">
            <TextField
              size="small"
              name="panel"
              fullWidth
              label="Type panel"
              variant="outlined"
              value={formData.panel}
              onChange={(e) => {
                setFormData({ ...formData, panel: e.target.value || "" });
              }}
            />
          </div>
          <div className="col-3">
            <button
              type="button"
              className="btn btn-primary text-uppercase ml-2"
              disabled={isLoading}
              onClick={onSave}
            >
              {isLoading ? "Wait..." : !!panel ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="py-3"></DialogActions>
    </Dialog>
  );
};
