import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  CardContent,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import ReactPaginate from "react-paginate";
import moment from "moment";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import {
  getRejectedTrainingRequests,
  getSingleTrainingRequest,
} from "../../../store/training/actions";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TableSkeleton from "../../common/components/TableSkeleton";
import ViewTrainingRequestDialog from "../components/ViewTrainingRequestDialog";
import BackdropLoader from "../../common/components/BackdropLoader";
import { useRef } from "react";
import RequestCard from "../components/RequestCard";

const RejectedRequests = (props) => {
  const {
    user,
    rejectedTrainingRequests,
    getRejectedTrainingRequests,
    getSingleTrainingRequest,
  } = props;
  useEffect(() => {
    document.title = `IPPIS - Training(${rejectedTrainingRequests.length}) | Rejected Requests`;
  });
  const [showTrainingRequestDetails, setShowTrainingRequestDetails] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [filteredRequests, setFilteredRequests] = useState([
    ...rejectedTrainingRequests,
  ]);
  const theme = useTheme();

  useEffect(() => {
    if (!rejectedTrainingRequests.length)
      getRejectedTrainingRequests(setLoading);
  }, []);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(rejectedTrainingRequests);
  }, [itemOffset, itemsPerPage, rejectedTrainingRequests]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % rejectedTrainingRequests.length || 0;

    setItemOffset(newOffset);
  };
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-4">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  // sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span>TRAINING</span>
                  <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                    <span
                      className="material-icons  text-dark"
                      style={{ position: "relative", top: "4px" }}
                    >
                      chevron_right
                    </span>
                  </span>

                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      Rejected Requests
                    </span>
                  </span>
                </Typography>
              </div>

              <div className="col-lg-8"></div>
            </Toolbar>
          </AppBar>

          {showTrainingRequestDetails && (
            <ViewTrainingRequestDialog
              showTrainingRequestDetails={showTrainingRequestDetails}
              setShowTrainingRequestDetails={setShowTrainingRequestDetails}
            />
          )}

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <table className="table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col" className="text-left">
                      No
                    </th>
                    <th scope="col" className="text-left">
                      EmployeeName
                    </th>
                    {/* <th scope="col" className="text-left">
                      TrainingTitle
                    </th> */}

                    <th scope="col" className="text-left">
                      Institution
                    </th>

                    <th scope="col" className="text-left">
                      Position
                    </th>

                    <th scope="col" className="text-left">
                      RequestType
                    </th>
                    <th scope="col" className="text-right">
                      RequestedAt
                    </th>
                    <th scope="col" className="text-right">
                      DepartureDate
                    </th>
                    <th scope="col" className="text-right">
                      Status
                    </th>

                    <th scope="col" className="text-right">
                      <span className="mr-3">Actions</span>
                    </th>
                  </tr>
                </thead>

                {loading ? (
                  <TableSkeleton cols={9} />
                ) : (
                  <tbody>
                    {paginatedItems.map((request, index) => (
                      <RequestCard
                        request={request}
                        index={index}
                        key={index}
                        setLoading1={setLoading1}
                        loading1={loading1}
                        setShowTrainingRequestDetails={
                          setShowTrainingRequestDetails
                        }
                        getSingleTrainingRequest={getSingleTrainingRequest}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              {!!paginatedItems.length &&
                rejectedTrainingRequests.length >= itemsPerPage && (
                  <div
                    className="justify-content-center mt-2"
                    style={{ overflow: "hidden" }}
                  >
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={pageCount}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                      activeLinkClassName="active"
                    />
                  </div>
                )}

              <NoRecordMessage
                isEmpty={!rejectedTrainingRequests.length && !loading}
                title="No Results found"
              />
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, rejectedTrainingRequests }) => {
  return { user, loading, rejectedTrainingRequests };
};
export default connect(mapStateToProps, {
  getRejectedTrainingRequests,
  getSingleTrainingRequest,
})(RejectedRequests);
