import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Button,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Input,
  Checkbox,
  LinearProgress,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  saveRequest,
  cancelEmployeeExitRequest,
} from "../../../store/exit/actions";

import {
  getUnitPositions,
  getEntities,
  getEntitySectors,
} from "../../../store/structure/actions";
import { getRequestDocumentSignerDetails } from "../../../store/common/actions";

import { isEmpty } from "lodash";
import moment from "moment";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import CommonLetter from "../../Documents/LetterFormat/CommonLetter";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { removeDuplicates } from "../../common/components/Utils";
import axios from "axios";

const steps = ["Fill Request Form", "Fill Request Letter"];
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const EmployeeExitRequestDialog = (props) => {
  const {
    loading,
    onWait,
    showEmployeeExitForm,
    setShowEmployeeExitForm,
    selectedEmployee,
    saveRequest,
    exitTypes,
    units,
    employeeExitRequest,
    cancelEmployeeExitRequest,
    getUnitPositions,
    positions,
    getEntities,
    entities,
    requestDocumentSignerDetail,
    getRequestDocumentSignerDetails,
    getEntitySectors,
    entitySectors,
    user,
  } = props;

  useEffect(() => {
    if (requestDocumentSignerDetail) getRequestDocumentSignerDetails();
  }, []);

  useEffect(() => {
    if (!entities.length) getEntities();
  }, []);

  //getEntities();
  const [loadingEntitySectors, setLoadingEntitySectors] = useState(false);
  const [exitType, setExitType] = useState(null);
  const [entity, setEntity] = useState(null);
  const [unit, setUnit] = useState(null);
  const [position, setPosition] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [ccData, setCCData] = useState([]);
  const [entitySectorUnits, setEntitySectorUnits] = useState([]);
  const [employeeExitForm, setEmployeeExitForm] = useState({
    employeeId: selectedEmployee.employeeId,
    employeePositionId: selectedEmployee.employeePositionId,
    positionId: selectedEmployee.positionId,
    exitTypeId: "",
    effectiveDate: moment().format("YYYY-MM-DD"),
    blacklistPeriod: 0,
    reason: "",
    referenceLetterId: "",
    referenceLetterDate: "",
    provisionalRefLetterId: "",
    provisionalRefLetterDate: "",
    summaryConclusionsCommittee: "",
    reportInternalDisciplinaryCommittee: "",
    providingFeedbackRefLetterDate: "",
    providingFeedbackRefLetterId: "",
    providingExplanationLetterId: "",
    providingExplanationLetterDate: "",
    employeeExplanationLetterDate: "",
    secondmentPositionName: "",
    secondmentInstitutionName: "",
    docLetterNo: "",
    docReferenceId: !!requestDocumentSignerDetail?.docReferenceId
      ? requestDocumentSignerDetail.docReferenceId
      : null,
    cc: [],
  });

  const [errors, setErrors] = useState({
    exitTypeHasError: false,
    reasonHasError: false,
    effectiveDateHasError: false,
    entityHasError: false,
    unitHasError: false,
    positionHasError: false,
    referenceLetterIdError: false,
    referenceLetterDateError: false,
    provisionalRefLetterDateError: false,
    provisionalRefLetterIdError: false,
    summaryConclusionsCommitteeError: false,
    reportInternalDisciplinaryCommitteeError: false,
    providingFeedbackRefLetterDateError: false,
    providingFeedbackRefLetterIdError: false,
    providingExplanationLetterIdError: false,
    providingExplanationLetterDateError: false,
    employeeExplanationLetterDateError: false,
    secondmentInstitutionNameError: false,
    secondmentPositionNameError: false,
    docLetterNoError: false,
    effectiveDateError: false,
    ccHasError: false,
  });
  const formValidator1 = () => {
    const error = {
      referenceLetterIdError: false,
      referenceLetterDateError: false,
      provisionalRefLetterIdError: false,
      provisionalRefLetterDateError: false,
      summaryConclusionsCommitteeError: false,
      reportInternalDisciplinaryCommitteeError: false,
      providingFeedbackRefLetterDateError: false,
      providingFeedbackRefLetterIdError: false,
      providingExplanationLetterIdError: false,
      providingExplanationLetterDateError: false,
      employeeExplanationLetterDateError: false,
      secondmentInstitutionNameError: false,
      secondmentPositionNameError: false,
      docLetterNoError: false,
      ccHasError: false,
      hasError: false,
      effectiveDateError: false,
    };

    // docLetterNoError:false,
    // if (
    //   !employeeExitForm.docLetterNo &&
    //   exitType &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.docLetterNoError = true;
    //   error.hasError = true;
    // }
    if (
      !employeeExitForm.referenceLetterId &&
      exitType &&
      ![8, 9].includes(exitType.id) &&
      !!exitType.enableToGenerateLetter
    ) {
      error.referenceLetterIdError = true;
      error.hasError = true;
    }
    if (
      !employeeExitForm.effectiveDate &&
      exitType &&
      !!exitType.enableToGenerateLetter
    ) {
      error.referenceLetterIdError = true;
      error.hasError = true;
    }
    if (
      !employeeExitForm.referenceLetterDate &&
      exitType &&
      ![8, 9].includes(exitType.id) &&
      !!exitType.enableToGenerateLetter
    ) {
      error.referenceLetterDateError = true;
      error.hasError = true;
    }
    // start removal from office
    // if (
    //   !employeeExitForm.provisionalRefLetterId &&
    //   exitType &&
    //   ![8,9].includes(exitType.id) &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.provisionalRefLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.provisionalRefLetterDate &&
    //   exitType &&
    //   ![8,9].includes(exitType.id) &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.provisionalRefLetterDateError = true;
    //   error.hasError = true;
    // }

    // if (
    //   !employeeExitForm.reportInternalDisciplinaryCommittee &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.reportInternalDisciplinaryCommitteeError = true;
    //   error.hasError = true;
    // }
    if (
      !employeeExitForm.summaryConclusionsCommittee &&
      exitType &&
      exitType.id === 8 &&
      !!exitType.enableToGenerateLetter
    ) {
      error.summaryConclusionsCommitteeError = true;
      error.hasError = true;
    }
    // if (
    //   !employeeExitForm.providingFeedbackRefLetterId &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingFeedbackRefLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.providingFeedbackRefLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingFeedbackRefLetterDateError = true;
    //   error.hasError = true;
    // }

    // if (
    //   !employeeExitForm.providingExplanationLetterId &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingExplanationLetterIdError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.providingExplanationLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.providingExplanationLetterDateError = true;
    //   error.hasError = true;
    // }
    // if (
    //   !employeeExitForm.employeeExplanationLetterDate &&
    //   exitType &&
    //   exitType.id === 8 &&
    //   !!exitType.enableToGenerateLetter
    // ) {
    //   error.employeeExplanationLetterDateError = true;
    //   error.hasError = true;
    // }
    if (
      !employeeExitForm.secondmentInstitutionName &&
      exitType &&
      exitType.id === 10 &&
      !!exitType.enableToGenerateLetter
    ) {
      error.secondmentInstitutionNameError = true;
      error.hasError = true;
    }
    if (
      !employeeExitForm.secondmentPositionName &&
      exitType &&
      exitType.id === 10 &&
      !!exitType.enableToGenerateLetter
    ) {
      error.secondmentPositionNameError = true;
      error.hasError = true;
    }

    // if (!ccData.length && !!exitType.enableToGenerateLetter) {
    //   error.ccHasError = true;
    //   error.hasError = true;
    // }
    setErrors(error);

    if (error.hasError) {
      showError(`Please fill required field!`);
      return true;
    }
    return false;
  };

  const formValidator = () => {
    const error = {
      exitTypeHasError: false,
      reasonHasError: false,
      effectiveDateHasError: false,
      entityHasError: false,
      unitHasError: false,
      positionHasError: false,
      hasError: false,
    };

    if (!exitType) {
      error.exitTypeHasError = true;
      error.hasError = true;
    }

    if (!employeeExitForm.reason) {
      error.reasonHasError = true;
      error.hasError = true;
    }

    if (!employeeExitForm.effectiveDate) {
      error.effectiveDateHasError = true;
      error.hasError = true;
    }

    if (!!exitType && exitType.id === 0 && !unit) {
      error.unitHasError = true;
      error.hasError = true;
    }

    if (!!exitType && exitType.id === 0 && !position) {
      error.positionHasError = true;
      error.hasError = true;
    }

    if (!!exitType && exitType.id === 6 && !entity) {
      error.entityHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const getBlacklistPeriod = () =>
    +(exitType?.blacklistPeriod || 0) > 1
      ? (exitType?.blacklistPeriod || 0).toString() + " Years"
      : (exitType?.blacklistPeriod || 0).toString() + " Year";

  const onClose = () => {
    setShowEmployeeExitForm(false);
  };

  const onSave = () => {
    if (!exitType) {
      showError(`Please fill the exit type!`);
      return false;
    }

    if ([0, 6].includes(+exitType.id)) {
      if (!position) {
        showError(`No position selected.`);
        return false;
      }
      if (+position.numberOfVacantPosts === 0) {
        showError(`This position isn't available. there is (${position.numberOfVacantPosts}) number of vacant posts`);
        return false;
      }
    }

    saveRequest(
      {
        ...employeeExitForm,
        effectiveDate: moment(employeeExitForm.effectiveDate).format(
          "YYYY-MM-DD"
        ),
        entityId: entity?.entity?.id || null,
        positionId: position?.id || null,
        exitTypeId: exitType.id,
        cc: ccData || [],
      },
      onClose
    );
  };

  const getNo = (idToSearch, data, key = "id") => {
    return data.findIndex((item) => item[key] === idToSearch) + 1;
  };

  useEffect(() => {
    let date = new Date();
    if (!!exitType) {
      if (exitType.effectiveInDays === 0) {
        setEmployeeExitForm({
          ...employeeExitForm,
          effectiveDate: moment().format("YYYY-MM-DD"),
        });
      } else {
        setEmployeeExitForm({
          ...employeeExitForm,
          effectiveDate: moment()
            .add(exitType.effectiveInDays, "days")
            .format("YYYY-MM-DD"),
        });
      }
    }
  }, [exitType]);

  useEffect(() => {
    if (!!requestDocumentSignerDetail?.docReferenceId) {
      setEmployeeExitForm({
        ...employeeExitForm,
        docReferenceId: requestDocumentSignerDetail.docReferenceId,
      });
    }
  }, [requestDocumentSignerDetail]);

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const handleNext = () => {
    let allowNextStep = false;

    if (activeStep === 0) {
      if (!formValidator()) {
        if (
          [0, 6].includes(exitType.id) &&
          !!position &&
          position.numberOfVacantPosts <= 0
        ) {
          showError("No vacant posts found");
        } else {
          allowNextStep = true;
        }
      }
    }

    if (activeStep === 1) {
      if (!formValidator1()) allowNextStep = true;
    }

    if (allowNextStep && activeStep === 1) onSave();

    if (allowNextStep && activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getEntitySectorUnits = async (entitySectorId) => {
    try {
      setLoadingEntitySectors(true);
      const { data } = await axios.get(
        "/api/structure/entity-sector-units/" + entitySectorId
      );
      setEntitySectorUnits(data);
      setLoadingEntitySectors(false);
    } catch (error) {
      setLoadingEntitySectors(false);
      showError(error);
    }
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeeExitForm}
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">
                  EXIT REQUEST{" "}
                  {employeeExitRequest && employeeExitRequest?.exitType
                    ? " OF " + employeeExitRequest.exitType
                    : ""}
                  {exitType ? " OF " + exitType.name.toUpperCase() : ""} |{" "}
                </span>
                <span className="ml-0">
                  {selectedEmployee?.lastName} {selectedEmployee?.firstName}
                </span>
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          {exitType && !!exitType.enableToGenerateLetter && (
            <div className="mt-4">
              <Box sx={{ width: "100%", mt: 0 }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    // stepProps.completed = isCompleted;

                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </div>
          )}
        </DialogTitle>
        <DialogContent className="pb-2">
          <Box sx={{ width: "100%", minHeight: "95vh" }} className="mt-1">
            <div className="py-3">
              {/* {!isEmpty(employeeExitRequest) && ( */}
              <>
                {activeStep === 0 && (
                  <>
                    <div className="row px-3 mt-4">
                      <div className="col-12 mb-2">
                        {" "}
                        {(loading || loadingEntitySectors) && (
                          <LinearProgress />
                        )}
                      </div>
                      <div className="col-12">
                        <Autocomplete
                          size="small"
                          id="exitType"
                          defaultValue={null}
                          value={exitType}
                          options={exitTypes}
                          disabled={loading}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, exitType) => {
                            setExitType(exitType || null);
                            setPosition(null);
                            setUnit(null);
                            setEntity(null);

                            if (exitType.effectiveInDays === 0) {
                              setEmployeeExitForm({
                                ...employeeExitForm,
                                effectiveDate: moment().format("YYYY-MM-DD"),
                              });
                            } else {
                              setEmployeeExitForm({
                                ...employeeExitForm,
                                effectiveDate: moment()
                                  .add(exitType.effectiveInDays, "days")
                                  .format("YYYY-MM-DD"),
                              });
                            }

                            setEmployeeExitForm({
                              ...employeeExitForm,
                              exitTypeId: exitType?.id || "",
                            });
                            if (exitType.id === 6 && !entitySectors.length)
                              getEntitySectors();
                            setErrors({ ...errors, exitTypeHasError: false });
                            // if (!!exitType && exitType.id === 6) getEntities();
                          }}
                          getOptionLabel={(option) =>
                            getNo(option?.id, exitTypes) + ":" + option?.name
                          }
                          renderOption={(props, exitType) => (
                            <Box component="li" {...props}>
                              {getNo(exitType?.id, exitTypes)}: {exitType?.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              required={true}
                              disabled={loading}
                              {...params}
                              label="Select exit type"
                              placeholder="Select exit type"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "ExitType", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        {errors.exitTypeHasError && (
                          <small className="text-danger mb-1">
                            Please, select exit type
                          </small>
                        )}
                      </div>

                      {!!exitType && exitType.id === 6 && (
                        <div className="col-12 mt-3">
                          <Autocomplete
                            size="small"
                            id="entity"
                            defaultValue={null}
                            value={entity}
                            options={entitySectors}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, entity) => {
                              setEntity(entity || null);
                              setPosition(null);
                              setUnit(null);
                              setEntitySectorUnits([]);
                              getEntitySectorUnits(entity.id);

                              setErrors({ ...errors, entityHasError: false });
                            }}
                            getOptionLabel={(option) =>
                              option?.entity.name +
                              (entity?.entity.acronym
                                ? "(" + entity?.entity.acronym + ")"
                                : "")
                            }
                            renderOption={(props, entity) => (
                              <Box component="li" {...props}>
                                {entity?.entity.name}
                                {entity?.entity.acronym && (
                                  <>({entity?.entity.acronym})</>
                                )}{" "}
                                <span className="ml-1 badge badge-primary">
                                  {" "}
                                  {entity?.sector.name}{" "}
                                </span>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                {...params}
                                label="Select the institution you are transferring to"
                                placeholder="Select the institution you are transferring to"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "entity", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.entityHasError && (
                            <small className="text-danger mb-1">
                              Please, select the institution
                            </small>
                          )}
                        </div>
                      )}

                      {!!exitType && exitType.id === 6 && (
                        <div className="col-12 mt-3">
                          <Autocomplete
                            size="small"
                            id="unit"
                            defaultValue={null}
                            disabled={!entitySectorUnits.length}
                            value={unit}
                            options={entitySectorUnits}
                            isOptionEqualToValue={(option, value) =>
                              option.unitId === value.unitId
                            }
                            onChange={(event, unit) => {
                              setUnit(unit || null);
                              setPosition(null);

                              setErrors({ ...errors, unitHasError: false });

                              if (!!unit) getUnitPositions(unit.unitId);
                            }}
                            getOptionLabel={(option) => option?.unitName}
                            renderOption={(props, unit) => (
                              <Box component="li" {...props}>
                                {unit?.unitName}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                {...params}
                                label="Select unit"
                                placeholder="Select unit"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "unit", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.unitHasError && (
                            <small className="text-danger mb-1">
                              Please, select unit
                            </small>
                          )}
                        </div>
                      )}

                      {!!exitType && exitType.id === 0 && (
                        <div className="col-12 mt-3">
                          <Autocomplete
                            size="small"
                            id="unit"
                            defaultValue={null}
                            value={unit}
                            options={units}
                            isOptionEqualToValue={(option, value) =>
                              option.unitId === value.unitId
                            }
                            onChange={(event, unit) => {
                              setUnit(unit || null);
                              setPosition(null);

                              setErrors({ ...errors, unitHasError: false });

                              if (!!unit) getUnitPositions(unit.unitId);
                            }}
                            getOptionLabel={(option) => option?.unitName}
                            renderOption={(props, unit) => (
                              <Box component="li" {...props}>
                                {unit?.unitName}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                {...params}
                                label="Select unit"
                                placeholder="Select unit"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "unit", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.unitHasError && (
                            <small className="text-danger mb-1">
                              Please, select unit
                            </small>
                          )}
                        </div>
                      )}

                      {((!!exitType && exitType.id === 0) ||
                        (!!exitType && exitType.id === 6)) && (
                        <div className="col-12 mt-3">
                          <Autocomplete
                            disabled={!positions.length}
                            size="small"
                            id="position"
                            defaultValue={null}
                            value={position}
                            options={positions.filter(
                              ({ id }) => id != selectedEmployee.positionId
                            )}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, position) => {
                              setPosition(position || null);
                              // setEmployeeExitForm({
                              //   ...employeeExitForm,
                              //   positionId: position?.id || "",
                              // });

                              setErrors({ ...errors, positionHasError: false });
                            }}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, position) => (
                              <>
                                <Box component="li" {...props}>
                                  {position?.name}{" "}
                                  <span className="badge badge-secondary ml-1">
                                    {position?.employeeGroupName}
                                  </span>{" "}
                                  <span className="badge badge-primary ml-1">
                                    ({position?.numberOfVacantPosts})VacantPosts
                                  </span>
                                </Box>
                                <Divider />
                                <Divider />
                              </>
                            )}
                            renderInput={(params) => (
                              <TextField
                                required={true}
                                {...params}
                                label="Select position"
                                placeholder="Select position"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "position", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.positionHasError && (
                            <small className="text-danger mb-1">
                              Please, select position
                            </small>
                          )}
                        </div>
                      )}

                      {(!exitType ||
                        (!!exitType &&
                          exitType.id !== 0 &&
                          exitType.id !== 6)) && (
                        <div className="col-12 mt-3 ">
                          <TextField
                            style={{ backgroundColor: "#eee" }}
                            fullWidth
                            size="small"
                            name="blacklistPeriod"
                            label="Blacklist Period"
                            value={getBlacklistPeriod()}
                            disabled
                            onChange={(e) => {
                              setEmployeeExitForm({
                                ...employeeExitForm,
                                blacklistPeriod: e.target.value,
                              });
                            }}
                          />
                          {!!exitType?.blacklistPeriod ||
                            (0 > 0 && (
                              <span className="badge badge-primary">
                                To be Blacklisted in {getBlacklistPeriod()}
                              </span>
                            ))}
                        </div>
                      )}
                      <div className="col-12 mt-3 ">
                        <TextField
                          fullWidth
                          size="small"
                          name="reason"
                          required={true}
                          multiline
                          rows={3}
                          value={employeeExitForm.reason}
                          label={`Exit reason for ${exitType?.name || ""}`}
                          placeholder={`Exit reason for ${
                            exitType?.name || ""
                          }`}
                          inputProps={{ maxLength: 459 }}
                          onChange={(e) => {
                            setEmployeeExitForm({
                              ...employeeExitForm,
                              reason: e.target.value,
                            });

                            setErrors({ ...errors, reasonHasError: false });
                          }}
                        />
                        {errors.reasonHasError && (
                          <small className="text-danger mb-1">
                            Please, type exit reason.
                          </small>
                        )}
                      </div>

                      <div className="col-12 mt-3 ">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label="Effective Date"
                            inputFormat="dd/MM/yyyy"
                            // disabled={
                            //   !exitType ||
                            //   (exitType && exitType.effectiveInDays > 0)
                            // }
                            value={employeeExitForm.effectiveDate}
                            onChange={(date) => {
                              setEmployeeExitForm({
                                ...employeeExitForm,
                                effectiveDate: date,
                              });

                              setErrors({
                                ...errors,
                                effectiveDateHasError: false,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                onKeyDown={(e) => e.preventDefault()}
                                required={true}
                                fullWidth
                                size="small"
                                {...params}
                              />
                            )}
                          />

                          {errors.effectiveDateHasError && (
                            <small className="text-danger mt-1">
                              Effective date is required{" "}
                            </small>
                          )}
                        </LocalizationProvider>
                        {exitType && exitType.effectiveInDays > 0 && (
                          <span className="badge badge-primary">
                            This request will be effective after{" "}
                            {exitType.effectiveInDays} days if you don't adjust
                            the date
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {activeStep === 1 &&
                  exitType &&
                  !!exitType.enableToGenerateLetter && (
                    <>
                      <div className="mx-2 w-full">
                        <CommonLetter
                          exitTypeId={exitType.id}
                          recipientPositionName={selectedEmployee.positionName}
                          institutionName={selectedEmployee.entityName}
                          transferredInstitutionName={
                            exitType && exitType.id === 0
                              ? selectedEmployee.entityName
                              : entity?.entity.name || ""
                          }
                          transferredPositionName={
                            position ? position.name : ""
                          }
                          docReferenceId={
                            employeeExitForm?.docReferenceId || ""
                          }
                          effectiveInDays={exitType.effectiveInDays}
                          effectiveDate={moment(
                            employeeExitForm.effectiveDate
                          ).format("LL")}
                          recipientName={
                            selectedEmployee.lastName.toUpperCase() +
                            " " +
                            selectedEmployee.firstName
                          }
                          recipientPhone={selectedEmployee.phoneNumber}
                          recipientEmail={selectedEmployee.email}
                          currentPositionName={selectedEmployee.positionName}
                          subTitle={
                            "Dear " +
                            (selectedEmployee.genderId === "F"
                              ? "Madam"
                              : "Sir")
                          }
                          competentAuthorityName={
                            !!requestDocumentSignerDetail
                              ? requestDocumentSignerDetail
                                  .competentAuthorityUser.names
                              : ""
                          }
                          competentAuthorityPosition={
                            !!requestDocumentSignerDetail
                              ? requestDocumentSignerDetail
                                  ?.competentAuthorityUser?.positionName
                              : ""
                          }
                          enableToFillLetter={true}
                          docLetterNoInput={
                            <>
                              <Input
                                autoFocus
                                error={!!errors.docLetterNoError}
                                value={employeeExitForm.docLetterNo}
                                style={{ width: "150px" }}
                                inputProps={{
                                  "aria-label": "add doc No",
                                }}
                                onChange={(e) => {
                                  const docLetterNo = e.target.value;
                                  setEmployeeExitForm({
                                    ...employeeExitForm,
                                    docLetterNo,
                                  });

                                  setErrors({
                                    ...errors,
                                    docLetterNoError: false,
                                  });
                                }}
                              />
                            </>
                          }
                          effectiveDateInput={
                            <>
                              <Input
                                type="date"
                                error={!!errors.effectiveDateError}
                                value={employeeExitForm.effectiveDate}
                                style={{ width: "150px" }}
                                inputProps={{
                                  "aria-label": "add reference date",
                                }}
                                onChange={(e) => {
                                  const effectiveDate = e.target.value;
                                  setEmployeeExitForm({
                                    ...employeeExitForm,
                                    effectiveDate,
                                  });

                                  setErrors({
                                    ...errors,
                                    effectiveDateError: false,
                                  });
                                }}
                              />
                            </>
                          }
                          docReferenceIdInput={
                            <>
                              <Input
                                autoFocus
                                error={!!errors.referenceLetterIdError}
                                value={employeeExitForm.referenceLetterId}
                                style={{ width: "150px" }}
                                inputProps={{
                                  "aria-label": "add reference no",
                                }}
                                onChange={(e) => {
                                  const referenceLetterId = e.target.value;
                                  setEmployeeExitForm({
                                    ...employeeExitForm,
                                    referenceLetterId,
                                  });

                                  setErrors({
                                    ...errors,
                                    referenceLetterIdError: false,
                                  });
                                }}
                              />
                            </>
                          }
                          docReferenceDateInput={
                            <>
                              <Input
                                type="date"
                                error={!!errors.referenceLetterDateError}
                                value={employeeExitForm.referenceLetterDate}
                                style={{ width: "150px" }}
                                inputProps={{
                                  "aria-label": "add reference date",
                                }}
                                onChange={(e) => {
                                  const referenceLetterDate = e.target.value;
                                  setEmployeeExitForm({
                                    ...employeeExitForm,
                                    referenceLetterDate,
                                  });

                                  setErrors({
                                    ...errors,
                                    referenceLetterDateError: false,
                                  });
                                }}
                              />
                            </>
                          }
                          summaryConclusionsCommitteeInput={
                            <>
                              <TextField
                                fullWidth
                                size="small"
                                multiline
                                error={!!errors.referenceLetterDateError}
                                rows={4}
                                name="summaryConclusionsCommittee"
                                maxLength={500}
                                label="Type the summary of the conclusions of the committee.."
                                variant="outlined"
                                placeholder="Type the summary of the conclusions of the committee.."
                                value={
                                  employeeExitForm.summaryConclusionsCommittee
                                }
                                onChange={(e) => {
                                  const summaryConclusionsCommittee =
                                    e.target.value;
                                  setEmployeeExitForm({
                                    ...employeeExitForm,
                                    summaryConclusionsCommittee,
                                  });

                                  setErrors({
                                    ...errors,
                                    summaryConclusionsCommitteeError: false,
                                  });
                                }}
                              />
                              {errors.summaryConclusionsCommitteeError && (
                                <small className="text-danger mb-1">
                                  This field is required
                                </small>
                              )}
                            </>
                          }
                          secondmentInstitutionNameInput={
                            <>
                              <Input
                                error={!!errors.secondmentInstitutionNameError}
                                value={
                                  employeeExitForm.secondmentInstitutionName
                                }
                                style={{ width: "250px" }}
                                inputProps={{
                                  "aria-label": "secondmentInstitutionName",
                                }}
                                onChange={(e) => {
                                  const secondmentInstitutionName =
                                    e.target.value;
                                  setEmployeeExitForm({
                                    ...employeeExitForm,
                                    secondmentInstitutionName,
                                  });

                                  setErrors({
                                    ...errors,
                                    secondmentInstitutionNameError: false,
                                  });
                                }}
                              />
                            </>
                          }
                          secondmentPositionNameInput={
                            <>
                              <Input
                                error={!!errors.secondmentPositionNameError}
                                value={employeeExitForm.secondmentPositionName}
                                style={{ width: "250px" }}
                                inputProps={{
                                  "aria-label": "secondmentPositionName",
                                }}
                                onChange={(e) => {
                                  const secondmentPositionName = e.target.value;
                                  setEmployeeExitForm({
                                    ...employeeExitForm,
                                    secondmentPositionName,
                                  });

                                  setErrors({
                                    ...errors,
                                    secondmentPositionNameError: false,
                                  });
                                }}
                              />
                            </>
                          }
                          ccSelectForm={
                            <>
                              <Autocomplete
                                size="small"
                                fullWidth
                                multiple
                                id="ccData"
                                options={entities.map(
                                  ({ name, institutionEmail, id }) => ({
                                    name,
                                    institutionEmail,
                                    id,
                                  })
                                )}
                                value={ccData}
                                onChange={(e, cc) => {
                                  const uniqueCc = removeDuplicates(cc);
                                  setCCData(uniqueCc);
                                  const ccHasError = false;
                                  setErrors({ ...errors, ccHasError });
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      checked={
                                        selected ||
                                        !!ccData.find(
                                          ({ id }) => id === option.id
                                        )
                                      }
                                    />
                                    {option.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={"Select the institution to copy"}
                                    placeholder={
                                      "Select the institution to copy"
                                    }
                                  />
                                )}
                              />

                              {errors.ccHasError && (
                                <small className="text-danger mb-1">
                                  Please, select the institution to copy
                                </small>
                              )}
                            </>
                          }
                          // reasonSuspension={employeeExitForm.reason}
                          ccData={ccData}
                        />
                      </div>
                    </>
                  )}
              </>
              {/* )} */}
            </div>
          </Box>
        </DialogContent>
        {/* {!isEmpty(employeeExitRequest) && ( */}
        <DialogActions className="d-flex justify-content-center pb-3">
          {activeStep > 0 && exitType && !!exitType.enableToGenerateLetter && (
            <Button
              style={{
                ...InactiveButton,
                // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                float: "right",
              }}
              size="md"
              disabled={activeStep === 0 || loading}
              onClick={handleBack}
            >
              Back
            </Button>
          )}
          {exitType && !!exitType.enableToGenerateLetter && (
            <Box sx={{ flex: "1 1 auto" }} />
          )}

          {exitType && !!exitType.enableToGenerateLetter ? (
            <Button
              disabled={loading || onWait || !exitType}
              onClick={handleNext}
              color={activeStep === 1 ? "success" : "primary"}
              variant="contained"
              size="md"
            >
              {onWait ? "Wait.." : <>{activeStep === 1 ? "Submit" : "Next"}</>}
            </Button>
          ) : (
            <Button
              color="success"
              variant="contained"
              disabled={loading || onWait || !exitType}
              onClick={onSave}
              size="md"
            >
              {onWait ? "Wait.." : <>Submit</>}
            </Button>
          )}
        </DialogActions>
        {/* )} */}
      </Dialog>

      {showConfirmationDialog && (
        <ConfirmationDialog
          confirmationDialog={showConfirmationDialog}
          message={`Are you sure you want to cancel the exit request for ${
            selectedEmployee?.lastName + " " + selectedEmployee?.firstName
          }?`}
          setConfirmationDialog={setShowConfirmationDialog}
          onYes={() => {
            cancelEmployeeExitRequest(
              employeeExitRequest.id,
              setShowConfirmationDialog
            );
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  exitTypes,
  employeeExitRequest,
  units,
  positions,
  entities,
  requestDocumentSignerDetail,
  entitySectors,
  user,
}) => {
  return {
    loading,
    onWait,
    exitTypes,
    employeeExitRequest,
    units,
    positions,
    entities,
    requestDocumentSignerDetail,
    entitySectors,
    user,
  };
};
export default connect(mapStateToProps, {
  saveRequest,
  cancelEmployeeExitRequest,
  getUnitPositions,
  getEntities,
  getEntitySectors,
  getRequestDocumentSignerDetails,
})(EmployeeExitRequestDialog);
