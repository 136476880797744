import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Tabs,
  Tab,
  Input,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import { reviewTrainingRequest } from "../../../store/training/actions";
import { showError } from "../../toastify";
import { Editor } from "../../common/components/Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
import AuthorizationLetter from "./AuthorizationLetter";
import { a11yProps } from "../../common/components/TabPanel";
import AuthorizationPermit from "./AuthorizationPermit";
import moment from "moment";
import ErrorMessage from "../../common/components/ErrorMessage";
import TextEditorComponent from "../../common/components/TextEditorComponent";
import { capitalize } from "lodash";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
const draftAuthorizationContentByMinistryMxWords = 2000;
const MifotraReview2TrainingRequestDialog = (props) => {
  const {
    loading,
    level,
    showReviewRequest,
    setShowReviewRequest,
    setShowTrainingRequestDetails,
    request,
    reviewTrainingRequest,
  } = props;
  //trainingFundProviders
  const [tabValue, setTabValue] = useState(0);
  const { hasPermissions } = useRequiredAuth();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    referenceNo: request.referenceNo,
    level: level,
    permitReferenceNo: !!window.localStorage.getItem(
      `${request.referenceNo}-permitReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-permitReferenceNo`)
      : request.permitReferenceNo,
    letterReferenceNo: !!window.localStorage.getItem(
      `${request.referenceNo}-letterReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-letterReferenceNo`)
      : request.letterReferenceNo,
    copiedInstitutionEmail: !!window.localStorage.getItem(
      `${request.referenceNo}-copiedInstitutionEmail`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-copiedInstitutionEmail`
        )
      : "info@presidency.gov.rw,info@minaffet.gov.rw,info@minecofin.gov.rw,info@npsc.gov.rw",
    copiedInstitution: !!window.localStorage.getItem(
      `${request.referenceNo}-copiedInstitution`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-copiedInstitution`)
      : `<div style="font-family: Times New Roman;align-items:justify;line-height:1.5em">
<ul>
<li>H.E. The President of The Republic of Rwanda</li>
<li>Hon. Minister of Foreign Affairs and International Cooperation</li>
<li>Hon. Minister of Finance and Economic Planning</li>
${
  request.requestType === "LongTerm"
    ? "<li>Hon. Minister of Education</li>"
    : ""
}
<li>Hon. ${capitalize(
          request.ministryEntityName
            .toUpperCase()
            .replace("MINISTRY", "MINISTER")
        )}</li>
    
<li>Executive Secretary of NPSC</li>
</ul></div>`,

    draftAuthorizationPermitContent: !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationPermitContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationPermitContent`
        )
      : request.draftAuthorizationPermitContent
      ? request.draftAuthorizationPermitContent
      : `<div style="font-family: Times New Roman;align-items:justify;line-height:1.5em">I, ${
          request?.mifotraApproverFirstName
        } ${
          request?.mifotraApproverLastName
        }, Minister of Public Service and Labour, do hereby certify that ${
          request.employeeTitle
        }. ${request.lastName.toUpperCase()} ${request.firstName}, ${
          request.positionName
        } in the ${capitalize(
          request.entityName
        )} is authorised to attend a training on ${capitalize(
          request.trainingTitle
        )} in ${request.countryForTraining} from ${moment(
          request.trainingDurationFrom
        ).format("MMMM Do, YYYY")} to ${moment(
          request.trainingDurationTo
        ).format("MMMM Do, YYYY")}. <br />
${
  !request.isTrainingInsideCountry
    ? `The date of departure is ${moment(request.departureDate).format(
        "MMMM Do, YYYY"
      )} and the returning date is ${moment(request.returningDate).format(
        "MMMM Do, YYYY"
      )}.`
    : ""
} <br /> ${request.coveredRelatedCostsDetails.trim()}.<br />
I highly appreciate any assistance accorded to ${
          request.genderId === "M" ? "him" : "her"
        } in this regard.</div>
`,
    draftAuthorizationLetterContent: !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationLetterContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationLetterContent`
        )
      : request.draftAuthorizationLetterContent
      ? request.draftAuthorizationLetterContent
      : `<div style="font-family: Times New Roman;align-items:justify;line-height:1.5em">
    Reference is made to letter N<sup>o</sup> ${
      request.minstryLetterReferenceNo
    } of ${moment(request.ministryApprovedDate).format(
          "MMMM Do, YYYY"
        )} from the  Hon. ${capitalize(
          request.ministryEntityName
            .toUpperCase()
            .replace("MINISTRY", "MINISTER")
        )} requesting authorization for training in favour of ${request.lastName.toUpperCase()} ${
          request.firstName
        };
I would like to acknowledge to Your Excellency that ${
          request.employeeTitle
        }. ${request.lastName.toUpperCase()} ${capitalize(
          request.firstName
        )}, ${request.positionName} in the ${capitalize(
          request.entityName
        )} is authorised to attend a training on ${capitalize(
          request.trainingTitle
        )}, ${request.countryForTraining} from ${moment(
          request.trainingDurationFrom
        ).format("MMMM Do, YYYY")} to ${moment(
          request.trainingDurationTo
        ).format("MMMM Do, YYYY")}. ${
          !request.isTrainingInsideCountry
            ? `The date of departure is ${moment(request.departureDate).format(
                "MMMM Do, YYYY"
              )} and the returning date is ${moment(
                request.returningDate
              ).format("MMMM Do, YYYY")}.`
            : ""
        } ${request.coveredRelatedCostsDetails.trim()}.</br>
Attached are the documents related to the training.</br>
Please accept, Right Hon. Prime Minister, the assurances of my usual highest consideration and esteem.</div>`,
    comments: !!window.localStorage.getItem(`${request.referenceNo}-comments`)
      ? window.localStorage.getItem(`${request.referenceNo}-comments`)
      : ``,
  });
  useEffect(() => {
    setFormData({
      ...formData,
      coveredRelatedCostsDetails: request.coveredRelatedCostsDetails,
    });
  }, [request, level]);

  const [errors, setErrors] = useState({
    commentHasError: false,
    coveredRelatedCostsDetailsHasError: false,
    copiedInstitutionHasError: false,
    copiedInstitutionEmailHasError: false,
    draftAuthorizationPermitContentHasError: false,
    draftAuthorizationLetterContentHasError: false,
    draftAuthorizationContentMaxCharHasError: false,
    draftAuthorizationContentMaxCharHasError2: false,
    draftAuthorizationLetterContentHasError2: false,
  });

  const formValidator = () => {
    const error = {
      draftAuthorizationPermitContentHasError: false,
      hasError: false,
    };

    if (!formData.draftAuthorizationPermitContent) {
      error.draftAuthorizationPermitContentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };
  const getEditorContent = (data) => {
    let draftAuthorizationContentMaxCharHasError = false;
    // setHasTextEditorChanged(data.isChanged);
    setFormData({
      ...formData,
      draftAuthorizationPermitContent: data.contents,
    });
    request.draftAuthorizationPermitContent = data.contents;
    if (!!data.isMaxChar) draftAuthorizationContentMaxCharHasError = true;

    setErrors({
      ...errors,
      draftAuthorizationContentMaxCharHasError:
        draftAuthorizationContentMaxCharHasError,
    });
  };
  const getEditorContent2 = (data) => {
    let draftAuthorizationContentMaxCharHasError2 = false;
    // setHasTextEditorChanged2(data.isChanged);
    setFormData({
      ...formData,
      draftAuthorizationLetterContent: data.contents,
    });

    request.draftAuthorizationLetterContent = data.contents;
    if (!!data.isMaxChar) draftAuthorizationContentMaxCharHasError2 = true;

    setErrors({
      ...errors,
      draftAuthorizationContentMaxCharHasError2:
        draftAuthorizationContentMaxCharHasError2,
    });
  };

  const formValidator2 = () => {
    const error = {
      commentHasError: false,
      coveredRelatedCostsDetailsHasError: false,
      copiedInstitutionHasError: false,
      copiedInstitutionEmailHasError: false,
      draftAuthorizationLetterContentHasError: false,

      hasError: false,
    };

    if (!formData.copiedInstitution) {
      error.copiedInstitutionHasError = true;
      error.hasError = true;
    }

    if (!formData.copiedInstitutionEmail) {
      error.copiedInstitutionEmailHasError = true;
      error.hasError = true;
    }

    if (!formData.draftAuthorizationLetterContent) {
      error.draftAuthorizationLetterContentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    const error = {
      commentHasError: false,
      hasError: false,
    };

    if (!formData.comments) {
      error.commentHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    if (!error.hasError) {
      formData.permitReferenceNo = !!window.localStorage.getItem(
        `${request.referenceNo}-permitReferenceNo`
      )
        ? window.localStorage.getItem(
            `${request.referenceNo}-permitReferenceNo`
          )
        : formData.permitReferenceNo;
      formData.letterReferenceNo = !!window.localStorage.getItem(
        `${request.referenceNo}-letterReferenceNo`
      )
        ? window.localStorage.getItem(
            `${request.referenceNo}-letterReferenceNo`
          )
        : formData.letterReferenceNo;

      formData.copiedInstitutionEmail = !!window.localStorage.getItem(
        `${request.referenceNo}-copiedInstitutionEmail`
      )
        ? window.localStorage.getItem(
            `${request.referenceNo}-copiedInstitutionEmail`
          )
        : formData.copiedInstitutionEmail;
      formData.copiedInstitution = !!window.localStorage.getItem(
        `${request.referenceNo}-copiedInstitution`
      )
        ? window.localStorage.getItem(
            `${request.referenceNo}-copiedInstitution`
          )
        : formData.copiedInstitution;

      formData.draftAuthorizationPermitContent = !!window.localStorage.getItem(
        `${request.referenceNo}-draftAuthorizationPermitContent`
      )
        ? window.localStorage.getItem(
            `${request.referenceNo}-draftAuthorizationPermitContent`
          )
        : formData.draftAuthorizationPermitContent;
      formData.draftAuthorizationLetterContent = !!window.localStorage.getItem(
        `${request.referenceNo}-draftAuthorizationLetterContent`
      )
        ? window.localStorage.getItem(
            `${request.referenceNo}-draftAuthorizationLetterContent`
          )
        : formData.draftAuthorizationLetterContent;
      formData.comments = !!window.localStorage.getItem(
        `${request.referenceNo}-comments`
      )
        ? window.localStorage.getItem(`${request.referenceNo}-comments`)
        : formData.comments;

      reviewTrainingRequest(formData, setShowTrainingRequestDetails, onClose);
    }
  };
  const updateFormData = () => {
    formData.permitReferenceNo = !!window.localStorage.getItem(
      `${request.referenceNo}-permitReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-permitReferenceNo`)
      : formData.permitReferenceNo;
    formData.letterReferenceNo = !!window.localStorage.getItem(
      `${request.referenceNo}-letterReferenceNo`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-letterReferenceNo`)
      : formData.letterReferenceNo;

    formData.copiedInstitutionEmail = !!window.localStorage.getItem(
      `${request.referenceNo}-copiedInstitutionEmail`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-copiedInstitutionEmail`
        )
      : formData.copiedInstitutionEmail;
    formData.copiedInstitution = !!window.localStorage.getItem(
      `${request.referenceNo}-copiedInstitution`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-copiedInstitution`)
      : formData.copiedInstitution;

    formData.draftAuthorizationPermitContent = !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationPermitContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationPermitContent`
        )
      : formData.draftAuthorizationPermitContent;
    formData.draftAuthorizationLetterContent = !!window.localStorage.getItem(
      `${request.referenceNo}-draftAuthorizationLetterContent`
    )
      ? window.localStorage.getItem(
          `${request.referenceNo}-draftAuthorizationLetterContent`
        )
      : formData.draftAuthorizationLetterContent;
    formData.comments = !!window.localStorage.getItem(
      `${request.referenceNo}-comments`
    )
      ? window.localStorage.getItem(`${request.referenceNo}-comments`)
      : formData.comments;
    setFormData({
      ...formData,
    });
  };
  const onClose = () => {
    setFormData({
      referenceNo: "",
      level: "",
      coveredRelatedCostsDetails: "",
      copiedInstitution: "",
      copiedInstitutionEmail: "",
      comments: ``,
    });
    setShowReviewRequest(false);
  };

  const getEditorContent3 = (data) => {
    setFormData({
      ...formData,
      copiedInstitution: data.contents,
    });
    request.copiedInstitution = data.contents;
  };

  return (
    <>
      <Dialog
        //onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showReviewRequest}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Make Request Review
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          {tabValue !== 2 && (
            <div className="row">
              <div className="col-12 mt-1">
                {" "}
                <Tabs
                  className="mt-2 justify-content-center"
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="tabs"
                  allowScrollButtonsMobile
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    className="text-capitalize"
                    label="Training Authorization"
                    {...a11yProps(0)}
                  />
                  <Tab
                    disabled={tabValue === 0}
                    disableRipple={tabValue === 0}
                    className="text-capitalize"
                    label="Training Letter To PM"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent
          dividers
          style={{ maxHeight: activeStep === 0 ? "100vh" : "100vh" }}
        >
          <div className="row">
            {tabValue === 2 && (
              <div className="col-12 mt-1">
                <strong>1. Training Authorization </strong>
              </div>
            )}
            <div className="col-12 mt-1">
              {(tabValue === 0 || tabValue === 2) && (
                <AuthorizationPermit
                  headOfInstitution={{
                    firstName: request?.mifotraApproverFirstName || "",
                    lastName: request?.mifotraApproverLastName || "",
                    title: request?.mifotraApproverTitle || "",
                    positionName: request?.mifotraApproverPositionName || "",
                    institutionName:
                      request?.mifotraApproverInstitutionName || "",
                    canAddInstitutionName:
                      request?.mifotraApproverCanAddInstitutionName || false,
                  }}
                  permitReferenceNo={
                    !!window.localStorage.getItem(
                      `${request.referenceNo}-permitReferenceNo`
                    )
                      ? window.localStorage.getItem(
                          `${request.referenceNo}-permitReferenceNo`
                        )
                      : formData.permitReferenceNo
                  }
                  draftAuthorizationPermitContentInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <TextEditorComponent
                          id="editor-textarea4"
                          options={{
                            autoSave: false,
                            localStorageKey: `${request.referenceNo}-draftAuthorizationPermitContent`,
                            // hasChanged: hasTextEditorChanged,
                            // api: `/api/training/update-authorization-contents/${request.referenceNo}`,
                            maxCharCount:
                              draftAuthorizationContentByMinistryMxWords,
                            defaultValue: !!window.localStorage.getItem(
                              `${request.referenceNo}-draftAuthorizationPermitContent`
                            )
                              ? window.localStorage.getItem(
                                  `${request.referenceNo}-draftAuthorizationPermitContent`
                                )
                              : formData.draftAuthorizationPermitContent,
                          }}
                          onEditorChange={getEditorContent}
                        />

                        <ErrorMessage
                          hasError={
                            errors.draftAuthorizationPermitContentHasError
                          }
                          message="This field is required"
                        />
                      </>
                    )
                  }
                  permitReferenceNoInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <Input
                          autoFocus
                          value={
                            !!window.localStorage.getItem(
                              `${request.referenceNo}-permitReferenceNo`
                            )
                              ? window.localStorage.getItem(
                                  `${request.referenceNo}-permitReferenceNo`
                                )
                              : formData.permitReferenceNo
                          }
                          inputProps={{
                            "aria-label": "permitReferenceNo",
                          }}
                          onChange={(e) => {
                            const permitReferenceNo = e.target.value;
                            window.localStorage.setItem(
                              `${request.referenceNo}-permitReferenceNo`,
                              permitReferenceNo
                            );
                            setFormData({
                              ...formData,
                              permitReferenceNo,
                            });
                          }}
                        />
                      </>
                    )
                  }
                  request={request}
                  draftAuthorizationPermitContent={
                    !!window.localStorage.getItem(
                      `${request.referenceNo}-draftAuthorizationPermitContent`
                    )
                      ? window.localStorage.getItem(
                          `${request.referenceNo}-draftAuthorizationPermitContent`
                        )
                      : formData.draftAuthorizationPermitContent
                  }
                  signContent={
                    <table>
                      <tr>
                        <td
                          style={{
                            width: "15%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        >
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        >
                          {/* {request?.mifotraDelegateApproverPositionName && hasPermissions(["IS_TRAINING_MIFOTRA_DELEGATED_APPROVER"]) && <>
                      
                          <p style={{ fontWeight: 800,fontStyle: "italic", color: "blue" }}>
                          For {request?.mifotraDelegateApproverFirstName || ""}{" "}
                                {request?.mifotraDelegateApproverLastName || ""}
                              </p>

                              <p style={{ fontWeight: 800,fontStyle: "italic", color: "blue" }}>
                                {request?.mifotraDelegateApproverPositionName || ''}
                                
                              </p>
                        </> } */}
                        </td>
                      </tr>
                    </table>
                  }
                />
              )}
            </div>
            {tabValue === 2 && (
              <div className="col-12 mt-1">
                <strong>2. Authorization Letter To PM</strong>
              </div>
            )}
            <div className="col-12 mt-1">
              {(tabValue === 1 || tabValue === 2) && (
                <AuthorizationLetter
                  headOfInstitution={{
                    firstName: request?.mifotraApproverFirstName || "",
                    lastName: request?.mifotraApproverLastName || "",
                    title: request?.mifotraApproverTitle || "",
                    positionName: request?.mifotraApproverPositionName || "",
                    institutionName:
                      request?.mifotraApproverInstitutionName || "",
                    canAddInstitutionName:
                      request?.mifotraApproverCanAddInstitutionName || false,
                  }}
                  request={request}
                  draftAuthorizationLetterContent={
                    !!window.localStorage.getItem(
                      `${request.referenceNo}-draftAuthorizationLetterContent`
                    )
                      ? window.localStorage.getItem(
                          `${request.referenceNo}-draftAuthorizationLetterContent`
                        )
                      : formData.draftAuthorizationLetterContent
                  }
                  letterReferenceNo={formData.letterReferenceNo}
                  letterReferenceNoInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <Input
                          autoFocus
                          value={
                            !!window.localStorage.getItem(
                              `${request.referenceNo}-letterReferenceNo`
                            )
                              ? window.localStorage.getItem(
                                  `${request.referenceNo}-letterReferenceNo`
                                )
                              : formData.letterReferenceNo
                          }
                          inputProps={{
                            "aria-label": "letterReferenceNo",
                          }}
                          onChange={(e) => {
                            const letterReferenceNo = e.target.value;
                            window.localStorage.setItem(
                              `${request.referenceNo}-letterReferenceNo`,
                              letterReferenceNo
                            );
                            setFormData({
                              ...formData,
                              letterReferenceNo,
                            });
                          }}
                        />
                      </>
                    )
                  }
                  draftAuthorizationLetterContentInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <TextEditorComponent
                          id="editor-textarea5"
                          options={{
                            autoSave: false,
                            localStorageKey: `${request.referenceNo}-draftAuthorizationLetterContent`,
                            // hasChanged: hasTextEditorChanged2,
                            // api: `/api/training/update-authorization-letter-contents/${request.referenceNo}`,
                            maxCharCount:
                              draftAuthorizationContentByMinistryMxWords,
                            defaultValue: !!window.localStorage.getItem(
                              `${request.referenceNo}-draftAuthorizationLetterContent`
                            )
                              ? window.localStorage.getItem(
                                  `${request.referenceNo}-draftAuthorizationLetterContent`
                                )
                              : formData.draftAuthorizationLetterContent,
                          }}
                          onEditorChange={getEditorContent2}
                        />

                        <ErrorMessage
                          hasError={
                            errors.draftAuthorizationLetterContentHasError
                          }
                          message="This field is required"
                        />
                      </>
                    )
                  }
                  copiedInstitutionEmailInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <br />
                        <strong>
                          Copied Institution Email Ex:
                          info@mifotra.gov.rw,info@rdb.rw
                        </strong>
                        <TextField
                          fullWidth
                          size="small"
                          rows={4}
                          name="copiedInstitutionEmail"
                          placeholder="Ex: info@mifotra.gov.rw,info@rdb.rw"
                          variant="outlined"
                          value={
                            !!window.localStorage.getItem(
                              `${request.referenceNo}-copiedInstitutionEmail`
                            )
                              ? window.localStorage.getItem(
                                  `${request.referenceNo}-copiedInstitutionEmail`
                                )
                              : formData.copiedInstitutionEmail
                          }
                          onChange={(e) => {
                            const copiedInstitutionEmail = e.target.value;
                            setFormData({
                              ...formData,
                              copiedInstitutionEmail: copiedInstitutionEmail,
                            });
                            window.localStorage.setItem(
                              `${request.referenceNo}-copiedInstitutionEmail`,
                              copiedInstitutionEmail
                            );
                            setErrors({
                              ...errors,
                              copiedInstitutionEmailHasError: false,
                            });
                          }}
                        />
                        <ErrorMessage
                          hasError={errors.copiedInstitutionEmailHasError}
                          message="This field is required"
                        />
                      </>
                    )
                  }
                  copiedInstitutionInput={
                    tabValue === 2 ? (
                      ""
                    ) : (
                      <>
                        <br />
                        <strong>Provide copied institution</strong>
                        <TextEditorComponent
                          id="editor-textarea6"
                          options={{
                            autoSave: false,
                            height: "auto",
                            localStorageKey: `${request.referenceNo}-copiedInstitution`,
                            maxCharCount:
                              draftAuthorizationContentByMinistryMxWords,
                            defaultValue: !!window.localStorage.getItem(
                              `${request.referenceNo}-copiedInstitution`
                            )
                              ? window.localStorage.getItem(
                                  `${request.referenceNo}-copiedInstitution`
                                )
                              : formData.copiedInstitution,
                          }}
                          onEditorChange={getEditorContent3}
                        />

                        <ErrorMessage
                          hasError={errors.copiedInstitutionHasError}
                          message="This field is required"
                        />
                      </>
                    )
                  }
                  copiedInstitution={formData.copiedInstitution}
                  signContent={
                    <table>
                      <tr>
                        <td
                          style={{
                            width: "15%",
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        >
                          <span className="d-block text-danger">
                            Not signed
                          </span>
                        </td>
                        <td
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                          }}
                        >
                          {/* {request?.mifotraDelegateApproverPositionName && hasPermissions(["IS_TRAINING_MIFOTRA_DELEGATED_APPROVER"]) && <>
                      
                          <p style={{ fontWeight: 800,fontStyle: "italic", color: "blue" }}>
                          For {request?.mifotraDelegateApproverFirstName || ""}{" "}
                                {request?.mifotraDelegateApproverLastName || ""}
                              </p>

                              <p style={{ fontWeight: 800,fontStyle: "italic", color: "blue" }}>
                                {request?.mifotraDelegateApproverPositionName || ''}
                                
                              </p>
                        </> } */}
                        </td>
                      </tr>
                    </table>
                  }
                />
              )}
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          {tabValue === 0 && (
            <>
              <button
                onClick={() => {
                  if (
                    !formValidator() &&
                    !errors.draftAuthorizationContentMaxCharHasError
                  ) {
                    updateFormData();
                    setTabValue(1);
                  }
                }}
                type="button"
                className="btn btn-primary  px-4"
                disabled={loading}
              >
                Next To Modify Letter
              </button>
            </>
          )}
          {tabValue === 1 && (
            <>
              <button
                onClick={() => {
                  updateFormData();
                  setTabValue(0);
                }}
                type="button"
                className="btn btn-secondary  px-4"
                disabled={loading}
              >
                Back To Edit authorization
              </button>
              <button
                onClick={() => {
                  if (!formValidator2()) {
                    updateFormData();
                    setTabValue(2);
                  }
                }}
                type="button"
                className="btn btn-primary  px-4"
                disabled={loading}
              >
                View Letter(s) & Submit
              </button>
            </>
          )}
          {tabValue === 2 && (
            <>
              <div
                className="p-3"
                style={{
                  backgroundColor: "#fafafa",
                  border: "1px solid #17a2b8",
                }}
              >
                <div className="row">
                  <div className="col-12 mt-0">
                    <ReactQuill
                      theme="snow"
                      placeholder="Type your comments..."
                      value={formData.comments}
                      modules={Editor.modules}
                      formats={Editor.formats}
                      onChange={(value) => {
                        const comments = value;
                        setFormData({
                          ...formData,
                          comments,
                        });
                        window.localStorage.setItem(
                          `${request.referenceNo}-comments`,
                          comments
                        );
                        const commentHasError = false;
                        setErrors({ ...errors, commentHasError });
                      }}
                    />
                    <ErrorMessage
                      hasError={errors.commentHasError}
                      message="A comment is needed."
                    />
                  </div>
                  <div className="col-12 mt-1">
                    <button
                      onClick={() => {
                        if (!formValidator()) {
                          updateFormData();
                          setTabValue(1);
                        }
                      }}
                      type="button"
                      className="btn btn-secondary  px-4"
                      disabled={loading}
                    >
                      Back to edit
                    </button>
                    <button
                      onClick={onSave}
                      type="button"
                      className="btn btn-primary text-uppercase  px-4 float-right"
                      disabled={loading}
                    >
                      {loading ? "Wait..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  reviewTrainingRequest,
})(MifotraReview2TrainingRequestDialog);
