import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
  ListItemButton,
  ListItemText,
  List,
  ListSubheader,
  Card,
  CardHeader,
  ListItem,
  CardActions,
  ListItemAvatar,
  Avatar,
  Autocomplete,
} from "@mui/material";

import { connect } from "react-redux";
import NoRecordMessage from "../../common/components/NoRecordMessage";

import { editJobLevel } from "../../../store/structure/actions";
import ReactPaginate from "react-paginate";

const JobLevels = (props) => {
  const { loading, user, levels, knowledgeLevels, editJobLevel } = props;

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(levels.filter(({ id }) => id > "05"));
  }, [itemOffset, itemsPerPage, levels]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
        levels.filter(({ id }) => id > "05").length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  return (
    <Card className="border elevated-1 hoverable-card" variant="outlined">
      <div
        className="mb-3"
        style={{
          height: "55vh",
          overflow: "auto",
        }}
      >
        <List
          dense
          component="div"
          subheader={
            <ListSubheader
              className="border-bottom  font-weight-bold"
              component="div"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                // backgroundColor: "#e6ebf1",
              }}
            >
              Job Levels
            </ListSubheader>
          }
        >
          {paginatedItems?.map((level) => (
            <JobLevelCard
              index={getNo(
                levels.filter(({ id }) => id > "05"),
                "id",
                level.id
              )}
              key={level.id}
              loading={loading}
              user={user}
              level={level}
              knowledgeLevels={knowledgeLevels}
              editJobLevel={editJobLevel}
            />
          ))}
        </List>
        <NoRecordMessage
          isEmpty={!paginatedItems?.length}
          title="No record found. "
        />
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <>
            {/* <span className="pr-1 d-none d-md-inline">Next</span> */}
            <i className="fas fa-angle-double-right"></i>
          </>
        }
        previousLabel={
          <>
            <i className="fas fa-angle-double-left"></i>
            {/* <span className="pl-1  d-none d-md-inline">Previous</span> */}
          </>
        }
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
        activeLinkClassName="active"
      />
    </Card>
  );
};

const mapStateToProps = ({ user, loading, levels, knowledgeLevels }) => {
  return {
    user,
    loading,
    levels,
    knowledgeLevels,
  };
};

export default connect(mapStateToProps, { editJobLevel })(JobLevels);

const JobLevelCard = (props) => {
  const { loading, user, level, index, knowledgeLevels, editJobLevel } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [knowledgeLevel, setKnowledgeLevel] = useState(
    knowledgeLevels.find(({ id }) => id === level.knowledgeLevelId) || null
  );
  const [showEdit, setShowEdit] = useState(false);

  // useEffect(() => {
  //   setKnowledgeLevel(
  //     knowledgeLevels.find(({ id }) => id === level.knowledgeLevelId) || null
  //   );
  // }, [level]);

  return (
    <>
      <ListItem
        divider
        key={level.id}
        secondaryAction={
          <span>
            <span
              style={{ fontSize: "12px" }}
              className={`material-icons text-${
                level.isKnowledgeLevelActive ? "success" : "danger"
              }`}
            >
              fiber_manual_record
            </span>
            <IconButton
              size="small"
              onClick={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowEdit(true);
                  }}
                  className="text-primary font-weight-bold"
                >
                  <span className="material-icons mr-2">edit_note</span>
                  Edit
                </MenuItem>
              </span>
            </Menu>
          </span>
        }
      >
        <ListItemAvatar>
          <Avatar variant="square">{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <strong className="text-primary">
              Level {level.name !== "0" ? level.name : "N/A"}
            </strong>
          }
          secondary={
            <span className={`d-inline-flex align-items-center `}>
              {level.knowledgeLevelName}
            </span>
          }
        />
      </ListItem>

      {showEdit && (
        <Dialog
          onClose={() => setShowEdit(false)}
          open={showEdit}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="h6" noWrap component="div">
              <span className="text-primary">Edit Job Level</span>
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => setShowEdit(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                minHeight: "5vh",
              }}
            >
              <TextField
                style={{ backgroundColor: "#eee" }}
                InputProps={{ readOnly: true }}
                autoFocus
                fullWidth
                size="small"
                name="levelName"
                label={`Level`}
                placeholder={`Level`}
                variant="outlined"
                className="mt-2"
                value={`Level ${level.name !== "0" ? level.name : "N/A"}`}
              />
              <Autocomplete
                className="mt-3"
                size="small"
                id="knowledgeLevel"
                name="knowledgeLevel"
                defaultValue={null}
                value={knowledgeLevel}
                options={knowledgeLevels.filter(({ isActive }) => !!isActive)}
                onChange={(event, level) => {
                  setKnowledgeLevel(level || null);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <ListItem dense {...props}>
                    <ListItemText primary={option.name} />
                  </ListItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Knowledge level"
                    placeholder="Knowledge level"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            </div>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-4">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                editJobLevel(
                  { id: level.id, knowledgeLevelId: knowledgeLevel?.id },
                  () => {
                    setShowEdit(false);
                  }
                )
              }
              type="button"
              className="px-5"
              disabled={loading || !knowledgeLevel}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
