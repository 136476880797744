import training from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";
import commonTypes from "../common/action-types";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...training };

export const onUploadUnsignedFile = (payload, onClose, setIsUploading) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setIsUploading(true);
      const { data } = await axios.post(
        "/api/e-files/unsigned-files",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch({
        type: types.ADD_UNSIGNED_TMP_FILE,
        data,
      });
      setIsUploading(false);
      onClose();
      dispatch({ type: types.END_LOADING });
      showSuccess("File uploaded successfully");
    } catch (error) {
      setIsUploading(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const onRemoveUnsignedFile = (payload, setIsDeleting) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setIsDeleting(true);
      await axios.delete("/api/e-files/unsigned-files/" + payload.id);

      dispatch({
        type: types.DELETE_UNSIGNED_TMP_FILE,
        data: payload,
      });
      setIsDeleting(false);
      dispatch({ type: types.END_LOADING });
      showSuccess("File deleted successfully");
    } catch (error) {
      setIsDeleting(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getUnsignedTmpFiles = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/e-files/unsigned-files");

      dispatch({ type: types.SET_UNSIGNED_TMP_FILES, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const getSignedFiles = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/e-files/signed-files");

      dispatch({ type: types.SET_SIGNED_FILES, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteUnsignedTmpFile = (file, setConfirmRemoveFile) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      await axios.delete("/api/e-files/unsigned-files/" + file.id);

      dispatch({ type: types.END_LOADING });
      showSuccess("File removed successfully");

      dispatch({
        type: types.DELETE_UNSIGNED_TMP_FILE,
        data: file,
      });

      setConfirmRemoveFile(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
export const onSignFiles = (
  payload,
  setIsSigning,
  setShowApproverForm,
  setPkiPassword,
  history
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      setIsSigning(true);
      const { data } = await axios.post("/api/e-files/sign-files", payload);
      dispatch({ type: types.SET_UNSIGNED_TMP_FILES, data: [] });
      dispatch({
        type: types.ADD_SIGNED_TMP_FILE,
        data: data,
      });

      setPkiPassword("");
      setShowApproverForm(false);
      setIsSigning(false);
      dispatch({ type: types.END_LOADING });
      showSuccess("Document(s) signed successfully");
      history.push("/documents/e-sign");
    } catch (error) {
      setIsSigning(false);
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
