import {
  Tooltip,
  Card,
  Typography,
  CardHeader,
  CircularProgress,
  Box,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  deleteIndicator,
  approveIndicator,
  getIndicatorAssignments,
  downloadEmployeeContractReport,
  downloadEmployeePerformanceReport,
} from "../../../store/rbm/actions";
import CustomProgressLine from "./CustomProgressLine";
import ContractForm from "./ContractForm";
import ContractDetailsDialog from "./ContractDetailsDialog";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DownloadEmployeeRBMReport } from "./MonitorEmployeeCard";

const SubordinateCard = (props) => {
  const {
    // selectedOutput,
    subordinate,
    // setIsEditing,
    // setShowIndicatorForm,
    // deleteIndicator,
    // approveIndicator,
    // user,
    // isChildIndicator,
    // getIndicatorAssignments,
    loading,
    downloadEmployeeContractReport,
    downloadEmployeePerformanceReport,
    selectedFiscalYear,
  } = props;

  const [score, setScore] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setScore(subordinate.totalScore);
    }, 500);
  }, [subordinate]);

  const [showContractDialog, setShowContractDialog] = useState(false);
  const [showContractItemsDialog, setShowContractItemsDialog] = useState(false);

  return (
    <>
      <Card
        className={`rounded hoverable-card mb-2 mt-2 pb-3`}
        variant="outlined"
        style={{
          border: "none",
        }}
      >
        <CardHeader
          className="pb-0 pt-2"
          avatar={
            <Tooltip title="Progress">
              <Box
                sx={{
                  position: "relative",
                  display: "inline-flex",
                  cursor: "default",
                }}
              >
                <CircularProgress
                  className={`text-${
                    score < 50
                      ? "danger"
                      : score < 70
                      ? "warning"
                      : score < 100
                      ? "info"
                      : "success"
                  }`}
                  variant="determinate"
                  value={score}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ fontSize: "7px" }}
                    color="text.secondary"
                    className={`text-${
                      score < 50
                        ? "danger"
                        : score < 70
                        ? "warning"
                        : score < 100
                        ? "info"
                        : "success"
                    }`}
                  >
                    {`${score.toFixed(2)}%`}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
            // <Tooltip title={subordinate.names}>
            //   <Box
            //     sx={{
            //       position: "relative",
            //       display: "inline-flex",
            //       cursor: "default",
            //     }}
            //   >
            //     <Avatar
            //       sizes="large"
            //       style={{ border: "1px solid #d1cbcb" }}
            //       alt={subordinate.names}
            //       className=""
            //       src={
            //         subordinate.profileImage
            //           ? `data:image/jpeg;base64,${subordinate.profileImage}`
            //           : defaultProfile
            //       }
            //     />
            //   </Box>
            // </Tooltip>
          }
          title={
            <span className="d-flex flex-column">
              <span className="d-flex align-items-center text-uppercase font-weight-bold text-primary">
                <strong>{subordinate.no}:</strong> {subordinate.names}
                {!subordinate.competencyTotalScore &&
                  !!subordinate.numOfActivities &&
                  subordinate.numOfActivities ===
                    subordinate.scoredActivities && (
                    <span
                      className="badge badge-warning ml-1"
                      style={{
                        fontWeight: "bold !important",
                      }}
                    >
                      Competency not scored
                    </span>
                  )}
              </span>
              <span className="d-block text-primary ml-1 text-sm">
                {subordinate.positionName}{" "}
                <strong className="text-dark">
                  @{subordinate.unitName.toUpperCase()}
                </strong>
              </span>
            </span>
          }
          subheader={
            <span className="d-flex align-items-center flex-wrap justify-content-between ">
              <span className="d-flex align-items-center flex-wrap">
                <span className="">
                  <CustomProgressLine value={subordinate.totalScore} />

                  <span className="d-flex align-items-center  flex-wrap">
                    <span
                      className={`align-items-center px-1 mx-1 text-primary badge text-uppercase border badge-light font-weight-bold text-uppercase`}
                      style={{
                        cursor: "default",
                        borderRadius: "0px",
                      }}
                    >
                      {subordinate.numOfActivities} Items
                    </span>
                    <span
                      className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                      style={{
                        cursor: "default",
                        borderRadius: "8px",
                      }}
                    >
                      <span
                        className={`${
                          subordinate.ontrackActivities > 0
                            ? "badge badge-secondary"
                            : ""
                        } mr-1`}
                      >
                        {subordinate.ontrackActivities}
                      </span>
                      Ontrack
                    </span>
                    <span
                      className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                      style={{
                        cursor: "default",
                        // borderRight: "2px solid #ccd0d6",
                        // borderLeft: "2px solid #ccd0d6",
                        borderRadius: "8px",
                      }}
                    >
                      <span
                        className={`${
                          subordinate.dueActivities > 0
                            ? "badge badge-warning"
                            : ""
                        } mr-1`}
                      >
                        {subordinate.dueActivities}
                      </span>
                      Due
                    </span>
                    <span
                      className={`d-flex align-items-center px-1 mx-1 text-dark text-uppercase badge badge-light`}
                      style={{
                        cursor: "default",
                        // borderRight: "2px solid #ccd0d6",
                        // borderLeft: "2px solid #ccd0d6",
                        // borderRadius: "8px",
                      }}
                    >
                      <span
                        className={`${
                          subordinate.reportedActivities > 0
                            ? "badge badge-primary"
                            : ""
                        } mr-1`}
                      >
                        {subordinate.reportedActivities}
                      </span>
                      Reported
                    </span>
                    <span
                      className={`d-flex align-items-center px-1 mx-1 text-dark text-uppercase badge badge-light`}
                      style={{
                        cursor: "default",
                        // borderRight: "2px solid #ccd0d6",
                        // borderLeft: "2px solid #ccd0d6",
                        // borderRadius: "8px",
                      }}
                    >
                      <span
                        className={`${
                          subordinate.scoredActivities > 0
                            ? "badge badge-success"
                            : ""
                        } mr-1`}
                      >
                        {subordinate.scoredActivities || 0}
                      </span>
                      Scored
                    </span>

                    <span
                      className={`d-flex align-items-center px-1 mx-1 text-dark text-uppercase badge badge-light`}
                      style={{
                        cursor: "default",
                        // borderRight: "2px solid #ccd0d6",
                        // borderLeft: "2px solid #ccd0d6",
                        // borderRadius: "8px",
                      }}
                    >
                      <span
                        className={`${
                          subordinate.rejectedActivities > 0
                            ? "badge badge-danger"
                            : ""
                        } mr-1`}
                      >
                        {subordinate.rejectedActivities || 0}
                      </span>{" "}
                      Rejected
                    </span>

                    <span
                      className={`d-flex align-items-center  text-${
                        (subordinate.targetAchieved * 100) /
                          (subordinate.target || 1) <
                        50
                          ? "danger"
                          : (subordinate.targetAchieved * 100) /
                              (subordinate.target || 1) <
                            70
                          ? "warning"
                          : (subordinate.targetAchieved * 100) /
                              (subordinate.target || 1) <
                            90
                          ? "info"
                          : "success"
                      } px-2 py-0 ml-2`}
                      style={{
                        border: "none",
                        cursor: "default",
                        fontWeight: "lighter",
                        fontSize: "13px",
                      }}
                    >
                      <span
                        className={`badge badge-${
                          (subordinate.targetAchieved * 100) /
                            (subordinate.target || 1) <
                          50
                            ? "danger"
                            : (subordinate.targetAchieved * 100) /
                                (subordinate.target || 1) <
                              70
                            ? "warning"
                            : (subordinate.targetAchieved * 100) /
                                (subordinate.target || 1) <
                              90
                            ? "info"
                            : "success"
                        } mr-1`}
                      >
                        {subordinate.targetAchieved}%
                      </span>
                      Target achieved
                    </span>
                  </span>
                </span>
              </span>
            </span>
          }
          action={
            <Box className="d-flex align-items-center">
              <span className="mt-3 text-center">
                <span className="text-center d-block">
                  <span className="d-block">
                    <span className="d-flex ">
                      {!!subordinate.numOfActivities && (
                        <span>
                          <DownloadEmployeeRBMReport
                            title={
                              !subordinate.contractId
                                ? "Employee doesn't have RBM Contract"
                                : "Download Employee Performance Report"
                            }
                            downloadEmployeePerformanceReport={
                              downloadEmployeePerformanceReport
                            }
                            downloadEmployeeContractReport={
                              downloadEmployeeContractReport
                            }
                            disabled={!subordinate.contractId || loading}
                            employee={{
                              id: subordinate.employeeId || subordinate.id,
                              contractTypeName: subordinate.contractTypeName,
                              contractId: subordinate.contractId,
                            }}
                            selectedFiscalYear={selectedFiscalYear}
                          />
                          {/* <IconButton
                              disabled={!subordinate.contractId}
                              color="primary"
                              size="small"
                              onClick={() =>
                                downloadEmployeeContractReport(
                                  subordinate?.contractId
                                )
                              }
                            >
                              <i className="fas fa-download"></i>
                            </IconButton> */}
                        </span>
                      )}

                      {!subordinate.unContracted && (
                        <span className=" badge badge-pill">
                          <small>
                            {subordinate.contractTypeName} <br />
                            contract
                          </small>
                        </span>
                      )}

                      {!!subordinate.unContracted && (
                        <span
                          style={{ color: "#dc3545" }}
                          className=" badge badge-pill"
                        >
                          <span className="text-danger">No contract</span>
                        </span>
                      )}

                      {!subordinate.unContracted && (
                        <button
                          disabled={
                            loading
                            // || env !== "local"
                          }
                          onClick={() => setShowContractItemsDialog(true)}
                          className="ml-2 py-0 btn btn-primary btn-sm"
                        >
                          <OpenInNewIcon /> Evaluate
                        </button>
                      )}
                    </span>
                  </span>
                </span>
              </span>
            </Box>
          }
        />
      </Card>
      <Divider />
      <Divider />

      {showContractItemsDialog && (
        <ContractDetailsDialog
          open={showContractItemsDialog}
          onClose={() => setShowContractItemsDialog(false)}
          selectedSubordinate={subordinate}
        />
      )}

      {showContractDialog && (
        <ContractForm
          showDialog={showContractDialog}
          setShowDialog={setShowContractDialog}
          subordinate={subordinate}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, selectedFiscalYear }) => {
  return { user, loading, selectedFiscalYear };
};
export default connect(mapStateToProps, {
  deleteIndicator,
  approveIndicator,
  getIndicatorAssignments,
  downloadEmployeeContractReport,
  downloadEmployeePerformanceReport,
})(SubordinateCard);
