import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  LinearProgress,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Autocomplete,
  Box,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  changeBonusEmployment,
  getPerformanceEmployeeEmployments,
} from "../../../store/rbm/actions";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
import { showError } from "../../toastify";
import {
  getActiveEmployeeGroups,
  getActivePayrollGroups,
  getActiveSourceOfFunds,
} from "../../../store/payroll/actions";

const RBMEmployeePositionsDialog = (props) => {
  const {
    onWait,
    loading,
    costCenters,
    employeeGroups,
    payrollGroups,
    sourceOfFunds,
    paginatedItems,
    setPaginatedItems,
    showEmployeePositions,
    setShowEmployeePositions,
    getPerformanceEmployeeEmployments,
    changeBonusEmployment,
    getActivePayrollGroups,
    getActiveEmployeeGroups,
    getActiveSourceOfFunds,
  } = props;

  const [loadingData, setLoadingData] = useState(true);
  const [employeePositions, setEmployeePositions] = useState([]);
  const [changePayrollSettings, setChangePayrollSettings] = useState(false);
  const [payrollSettings, setPayrollSettings] = useState({
    costCenter: null,
    employeeGroup: null,
    payrollGroup: null,
    sourceOfFund: null,
  });

  useEffect(() => {
    if (!!showEmployeePositions) {
      getPerformanceEmployeeEmployments(
        showEmployeePositions.id,
        (status, res) => {
          setLoadingData(status);
          if (!status && !!res) {
            setEmployeePositions(res);
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    if (!!payrollSettings.costCenter)
      getActiveEmployeeGroups(payrollSettings.costCenter.id);
  }, [payrollSettings.costCenter?.id]);

  useEffect(() => {
    if (!!payrollSettings.costCenter && !!payrollSettings.employeeGroup)
      getActivePayrollGroups(
        payrollSettings.costCenter.id,
        payrollSettings.employeeGroup.id
      );
  }, [payrollSettings.employeeGroup?.id]);

  useEffect(() => {
    if (
      !!payrollSettings.costCenter &&
      !!payrollSettings.employeeGroup &&
      !!payrollSettings.payrollGroup
    )
      getActiveSourceOfFunds(
        payrollSettings.costCenter.id,
        payrollSettings.employeeGroup.id,
        payrollSettings.payrollGroup.id
      );
  }, [payrollSettings.payrollGroup?.id]);

  const onClose = () => {
    setShowEmployeePositions(null);
  };

  const handleSubmit = (formData) => {
    if (!showEmployeePositions.bonusId) {
      showError("You are not allowed to change employment for this data!");
      return;
    }
    changeBonusEmployment(
      showEmployeePositions?.bonusId,
      formData,
      (status, res) => {
        setLoadingData(status);
        if (!status && !!res) {
          const prevData = [...(paginatedItems || [])];
          const index = prevData.findIndex(
            (item) => item.bonusId === showEmployeePositions?.bonusId
          );
          if (index >= 0) {
            prevData[index].employeePositionId =
              formData.employeePositionId || prevData[index].employeePositionId;
            prevData[index].employeeGroupName =
              payrollSettings.employeeGroup?.name ||
              prevData[index].employeeGroupName;
            setPaginatedItems(prevData);
          }
          onClose();
        }
      }
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={!!showEmployeePositions}
        fullWidth
        maxWidth={changePayrollSettings ? "sm" : "lg"}
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            All Employee Positions For{" "}
            <span className="font-weight-bold">
              {showEmployeePositions?.names}
            </span>{" "}
            <Button
              disabled={loading || !showEmployeePositions?.bonusId}
              onClick={() => setChangePayrollSettings(!changePayrollSettings)}
              variant="contained"
              size="small"
              disableElevation
              color={changePayrollSettings ? "secondary" : "primary"}
              style={{
                borderRadius: "3px",
                height: "20px",
                // width: "80px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              {!changePayrollSettings
                ? "Change Payroll Settings"
                : "Cancel Change Payroll Settings"}
            </Button>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        {(loading || loadingData || onWait) && <LinearProgress />}
        <DialogContent dividers>
          <TableContainer component={Paper}>
            {!!changePayrollSettings && (
              <div style={{ minHeight: "28vh" }} className="px-3 pb-3">
                <div className="row">
                  <div className="col-12 mt-3">
                    <Autocomplete
                      disabled={loadingData || loading || onWait}
                      size="small"
                      id="costCenter"
                      defaultValue={null}
                      value={payrollSettings.costCenter}
                      options={costCenters}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, costCenter) => {
                        setPayrollSettings({
                          costCenter: costCenter || null,
                          employeeGroup: null,
                          payrollGroup: null,
                          sourceOfFund: null,
                        });
                      }}
                      getOptionLabel={(option) => option.id + "-" + option.name}
                      renderOption={(props, costCenter) => (
                        <Box component="li" {...props}>
                          {costCenter.id + " - " + costCenter.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Cost center"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <Autocomplete
                      disabled={
                        !payrollSettings.costCenter ||
                        loadingData ||
                        loading ||
                        onWait
                      }
                      size="small"
                      id="employeeGroup"
                      defaultValue={null}
                      value={payrollSettings.employeeGroup}
                      options={employeeGroups}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, employeeGroup) => {
                        setPayrollSettings({
                          ...payrollSettings,
                          employeeGroup: employeeGroup || null,
                          payrollGroup: null,
                          sourceOfFund: null,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, employeeGroup) => (
                        <Box component="li" {...props}>
                          {employeeGroup.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employee group"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <Autocomplete
                      disabled={
                        !payrollSettings.employeeGroup ||
                        loadingData ||
                        loading ||
                        onWait
                      }
                      size="small"
                      id="payrollGroup"
                      defaultValue={null}
                      value={payrollSettings.payrollGroup}
                      options={payrollGroups}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, payrollGroup) => {
                        setPayrollSettings({
                          ...payrollSettings,
                          payrollGroup: payrollGroup || null,
                          sourceOfFund: null,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, payrollGroup) => (
                        <Box component="li" {...props}>
                          {payrollGroup.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Payroll group"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-12 mt-3">
                    <Autocomplete
                      disabled={
                        !payrollSettings.payrollGroup ||
                        loadingData ||
                        loading ||
                        onWait
                      }
                      size="small"
                      id="sourceOfFund"
                      defaultValue={null}
                      value={payrollSettings.sourceOfFund}
                      options={sourceOfFunds}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, sourceOfFund) => {
                        setPayrollSettings({
                          ...payrollSettings,
                          sourceOfFund: sourceOfFund || null,
                        });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, sourceOfFund) => (
                        <Box component="li" {...props}>
                          {sourceOfFund.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Source of fund"
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </div>

                  <div className="col-12 mt-3 d-flex justify-content-center">
                    <button
                      onClick={() =>
                        handleSubmit({
                          costCenterId: payrollSettings.costCenter.id,
                          employeeGroupId: payrollSettings.employeeGroup.id,
                          payrollGroupId: payrollSettings.payrollGroup.id,
                          sourceOfFundId: payrollSettings.sourceOfFund.id,
                        })
                      }
                      type="button"
                      className="btn btn-primary text-uppercase px-4"
                      disabled={
                        loading ||
                        loadingData ||
                        onWait ||
                        !payrollSettings.costCenter ||
                        !payrollSettings.employeeGroup ||
                        !payrollSettings.payrollGroup ||
                        !payrollSettings.sourceOfFund
                      }
                    >
                      {loadingData ? "Changing..." : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {!changePayrollSettings && (
              <Table
                //   sx={{ minWidth: 650 }}
                size="small"
                aria-label="List of all employee positions"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Unit</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell align="right">Cost Center</TableCell>
                    <TableCell align="right">Employee Group</TableCell>
                    <TableCell align="right">Payroll Group</TableCell>
                    <TableCell align="right">Source Of Fund</TableCell>
                    <TableCell align="right">Employment Date</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                {loadingData && !employeePositions.length ? (
                  <TableSkeleton cols={9} isMUI />
                ) : (
                  <TableBody>
                    {employeePositions.map((row) => (
                      <TableRow
                        key={row.employeePositionId}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.unitName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.position}
                        </TableCell>
                        <TableCell align="right">{row.costCenterId}</TableCell>
                        <TableCell align="right">
                          {row.employeeGroup}{" "}
                          {row.employeeGroupId === 3 && !row.hasContract && (
                            <span
                              className="text-danger font-weight-bold"
                              style={{
                                fontSize: "11px",
                              }}
                            >
                              (No Contract)
                            </span>
                          )}
                        </TableCell>
                        <TableCell align="right">{row.payrollGroup}</TableCell>
                        <TableCell align="right">{row.sourceOfFund}</TableCell>
                        <TableCell align="right">
                          {moment(row.employmentDate).format("l")}
                        </TableCell>
                        <TableCell align="right">
                          {row.employmentStatus}
                        </TableCell>
                        <TableCell align="right">
                          {!!showEmployeePositions?.employeePositionId &&
                          row.employeePositionId ===
                            showEmployeePositions?.employeePositionId ? (
                            <span className="text-success">
                              <i className="fas fa-check-double"></i> Selected
                            </span>
                          ) : (
                            <Button
                              disabled={
                                loading || !showEmployeePositions?.bonusId
                              }
                              onClick={() =>
                                handleSubmit({
                                  employeePositionId: row.employeePositionId,
                                })
                              }
                              variant="contained"
                              size="small"
                              disableElevation
                              color="primary"
                              style={{
                                borderRadius: "3px",
                                height: "20px",
                                width: "80px",
                                alignItems: "center",
                                fontSize: ".75rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textTransform: "initial",
                              }}
                            >
                              Select
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            )}
          </TableContainer>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  performanceBonusScores,
  selectedFiscalYear,
  costCenters,
  employeeGroups,
  payrollGroups,
  sourceOfFunds,
}) => {
  return {
    loading,
    onWait,
    performanceBonusScores,
    selectedFiscalYear,
    costCenters,
    employeeGroups,
    payrollGroups,
    sourceOfFunds,
  };
};
export default connect(mapStateToProps, {
  getPerformanceEmployeeEmployments,
  changeBonusEmployment,
  getActivePayrollGroups,
  getActiveEmployeeGroups,
  getActiveSourceOfFunds,
})(RBMEmployeePositionsDialog);
