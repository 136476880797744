import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import rwandaLogo from "../../assets/rwanda-logo.png";
import mailboxIcon from "../../assets/mailbox.png";
import callIcon from "../../assets/call.png";
import webIcon from "../../assets/link.png";
import emailIcon from "../../assets/email.png";
import twitterIcon from "../../assets/twitter.png";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getSalaryStructure,
  deleteSalaryStructure,
  getLetterCustomization,
} from "../../../store/structure/actions";
import moment from "moment";
import axios from "axios";
import AvatarEditor from "react-avatar-editor";
import download from "downloadjs";

const LetterTemplateDialog = (props) => {
  const {
    loading,
    onWait,
    selectedEntitySector,
    showLetterTemplate,
    setShowLetterTemplate,
    entity,
    letterCustomization,
    getLetterCustomization,
  } = props;
  const [showEditHeaderDialog, setShowEditHeaderDialog] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const onClose = () => {
    setShowLetterTemplate(false);
  };
  useEffect(() => {
    getLetterCustomization(selectedEntitySector.id);
  }, []);

  const downloadTemplate = async () => {
    try {
      setIsDownloading(true);


      const response = await fetch("/api/structure/download-template-letter/" + selectedEntitySector.id,);
      const data = await response.json();

      const { filename, mimeType, base64 } = data;
      // Convert Base64 to Blob
      const blob = new Blob([Uint8Array.from(atob(base64), (c) => c.charCodeAt(0))], { type: mimeType });

      // Create a URL and trigger download
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showLetterTemplate}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              <div className="header_cont">
                <span className="ml-0 text-dark">Letter Template | </span>{" "}
                <span className="ml-0">
                  {entity && entity.name}{" "}
                  <span className="badge badge-secondary">
                    {selectedEntitySector && selectedEntitySector.sector.name}
                  </span>
                </span>{" "}
              </div>
            </div>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {" "}
          <div className="letterMainContent">
            <div className="letter ">
              {!!letterCustomization &&
                !!letterCustomization.isHeaderLogoSettled ? (
                <>
                  <div className="d-flex w-100">
                    <img
                      style={{ width: "100%" }}
                      src={
                        "data:image/png;base64," +
                        letterCustomization.letterHeaderLogo
                      }
                    />
                    <span
                      style={{
                        position: "relative",
                        right: "66px",
                        width: "45px",
                        height: "40px",
                        backgroundColor: "#fff",
                        border: "1px solid #c8c6c6",
                      }}
                    >
                      <IconButton
                        // disabled={employee.statusId === 30}
                        onClick={() => {
                          setShowEditHeaderDialog(true);
                        }}
                        size="small"
                        edge="end"
                        color="primary"
                      >
                        <span className="material-icons">edit</span>
                      </IconButton>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="letterhead d-flex flex-column justify-content-center w-100">
                    <div
                      className="letterhead-title w-100"
                      style={{ fontWeight: "bold" }}
                    >
                      REPUBLIC OF RWANDA
                    </div>
                    <div className="w-100">
                      <img
                        style={{ width: "100px", height: "100px" }}
                        src={rwandaLogo}
                        alt="Logo"
                      />
                    </div>
                    <div
                      className="letterhead-subtitle w-100"
                      style={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                      {(entity && entity.name?.toUpperCase()) || ""}
                    </div>
                    <span
                      style={{
                        width: "45px",
                        position: "relative",
                        backgroundColor: "#fff",
                        border: "1px solid #c8c6c6",
                      }}
                    >
                      <IconButton
                        // disabled={employee.statusId === 30}
                        onClick={() => {
                          setShowEditHeaderDialog(true);
                        }}
                        size="small"
                        edge="end"
                        color="primary"
                      >
                        <span className="material-icons">add</span>
                      </IconButton>
                    </span>
                  </div>
                </>
              )}

              {showEditHeaderDialog && (
                <EditHeaderDialog
                  showEditHeaderDialog={showEditHeaderDialog}
                  setShowEditHeaderDialog={setShowEditHeaderDialog}
                  selectedEntitySector={selectedEntitySector}
                />
              )}
              <div
                style={{
                  backgroundColor: "#078ece",
                  height: "3px",
                  border: "1px solid rgb(7, 142, 206)",
                  borderRadius: "2px",
                }}
              ></div>

              <div className="subletterhead">
                <table>
                  <tr>
                    <td style={{ width: "70%" }}></td>
                    <td style={{ width: "30%" }}>
                      <p
                        className="date"
                        style={{
                          fontWeight: "bold",
                          float: "left",
                          textAlign: "left",
                        }}
                      ></p>
                    </td>
                  </tr>
                </table>
              </div>
              <button onClick={() => downloadTemplate()}>
                {isDownloading ? "Downloading..." : "Download Template"}
              </button>
              <div className="recipient" style={{ height: "45vh" }}>
                {/* <!-- Recipient's name and address go here --> */}
              </div>

              <p>
                <div>
                  <div
                    style={{ backgroundColor: "#078ece", height: "3px" }}
                  ></div>
                  <div
                    style={{ backgroundColor: "#e5cb05", height: "2px" }}
                  ></div>
                  <div
                    style={{ backgroundColor: "#199e05", height: "1px" }}
                  ></div>

                  <p
                    className="left"
                    style={{
                      fontSize: "11px",
                      color: "#000",
                      position: "relative",
                      marginTop: "8px",
                    }}
                  >
                    <strong style={{ paddingRight: "10px" }}>
                      <img
                        src={mailboxIcon}
                        alt={entity?.poBox || ""}
                        className="footer-icon"
                      />{" "}
                      {entity?.poBox || ""}
                    </strong>

                    <span style={{ paddingRight: "10px" }}>
                      <img
                        src={callIcon}
                        alt={entity?.institutionPhone || ""}
                        className="footer-icon"
                      />{" "}
                      <a
                        href={`tel:${entity?.institutionPhone || ""}`}
                        style={{ color: "#078ece" }}
                      >
                        {entity?.institutionPhone || ""}
                      </a>
                    </span>

                    <span style={{ paddingRight: "10px" }}>
                      <img
                        src={webIcon}
                        alt={entity?.websiteUrl || ""}
                        className="footer-icon"
                      />{" "}
                      <a
                        href={`https://${entity?.websiteUrl || ""}`}
                        style={{ color: "#078ece" }}
                      >
                        {entity?.websiteUrl || ""}
                      </a>
                    </span>

                    <span style={{ paddingRight: "10px" }}>
                      <img
                        src={emailIcon}
                        alt={entity?.institutionEmail || ""}
                        className="footer-icon"
                      />{" "}
                      <a
                        href={`mailto:${entity?.institutionEmail || ""}`}
                        style={{ color: "#078ece" }}
                      >
                        {entity?.institutionEmail || ""}
                      </a>
                    </span>

                    <img
                      src={twitterIcon}
                      alt={entity?.twitterPage || ""}
                      className="footer-icon"
                    />
                    <span style={{ paddingRight: "10px", color: "#078ece" }}>
                      <a
                        href={`https://x.com/${entity?.twitterPage || ""}`}
                        style={{ color: "#078ece" }}
                      >
                        {entity?.twitterPage || ""}
                      </a>
                    </span>
                  </p>
                </div>
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, letterCustomization }) => {
  return {
    loading,
    onWait,
    letterCustomization,
  };
};
export default connect(mapStateToProps, {
  getSalaryStructure,
  deleteSalaryStructure,
  getLetterCustomization,
})(LetterTemplateDialog);

const EditHeaderDialog = (props) => {
  const {
    showEditHeaderDialog,
    setShowEditHeaderDialog,
    selectedEntitySector,
  } = props;
  const dispatch = useDispatch();

  const onClose = () => {
    setShowEditHeaderDialog(false);
  };
  const [imageBase64, setImageBase64] = useState("");
  const [error, setError] = useState("");



  const [avatar, setAvatar] = useState({
    selectedImage: "",
    scaleValue: 0.88,
  });



  const [isSaving, setIsSaving] = useState(false);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    // Load the image as a data URL
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;

      // Ensure the image dimensions meet the requirements
      img.onload = () => {
        if (img.width < 700 || img.height < 100) {
          setError("Image must be at least 700px wide and 100px tall.");
          setImageBase64("");
          return;
        }

        // Convert the image to Base64
        const canvas = document.createElement("canvas");
        canvas.width = img.width; // Keep original resolution
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const base64 = canvas.toDataURL(file.type); // Convert to Base64
        setImageBase64(base64);
        setError("");
      };

      img.onerror = () => {
        setError("Invalid image file.");
        setImageBase64("");
      };
    };

    reader.onerror = () => {
      setError("Error reading file.");
    };

    reader.readAsDataURL(file);
  };


  const onSave = () => {
    if (!!error) {
      //  setError("Try to fix error(s) to continue.");
        return;
      }
      if (!imageBase64) {
        setError("No file uploaded");
        return;
      }
      const formData = new FormData();
      formData.append("entitySectorId", selectedEntitySector.id);

      formData.append(
        "logo",
        imageBase64.split(",")[1]
      );

      submit(formData);
    
  };

  const submit = async (formData) => {
    try {
      setIsSaving(true);

      const { data } = await axios.post("/api/auth/letter-header", formData);
      dispatch({
        type: "SET_LETTER_CUSTOMIZATION",
        data: data,
      });
      setShowEditHeaderDialog(false);

      setIsSaving(false);
    } catch (error) {
      setIsSaving(false);
    }
  };



  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEditHeaderDialog}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle className="text-primary font-weight-bold">
          <span className="d-flex align-items-center">Edit Header</span>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent className="pb-1">
          <form>
            <div className="row">
              <div className={`col-12`}>
                <small>Select image of your header section (PNG, JPG)</small>
                <div>
                  <input type="file" accept="image/*" onChange={handleImageUpload} />
                  {error && <p style={{ color: "red" }}>{error}</p>}
                  {imageBase64 && (
                    <div>
                      <h3>Preview:</h3>
                      <img src={imageBase64} alt="Uploaded" style={{ maxWidth: "100%" }} />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center py-3">
                <button
                  onClick={onSave}
                  type="button"
                  className="btn btn-primary text-uppercase  px-4"
                  disabled={isSaving}
                >
                  {isSaving ? "Wait..." : "Save"}
                </button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
