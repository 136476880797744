import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  getEntitySectorCases,
  deleteDisciplinaryCase,
  getCase,
} from "../../../store/discipline/actions";
import { getSupervisorEmployees } from "../../../store/structure/actions";
import defaultProfile from "../../assets/default-profile.jpg";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  IconButton,
  Popover,
  RadioGroup,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import ReactPaginate from "react-paginate";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";
import CaseCard from "../components/CaseCard";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

import BackdropLoader from "../../common/components/BackdropLoader";
import NewDisplinarlyCaseRequestDialog from "../components/NewDisplinarlyCaseRequestDialog";
import ViewCaseDialog from "../components/ViewCaseDialog";
const ActiveButton = {
  // float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const AllCases = (props) => {
  const {
    getEntitySectorCases,
    user,
    entitySectorCases,
    getCase,
    getSupervisorEmployees,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  useEffect(() => {
    document.title = `IPPIS - Discipline(${entitySectorCases.length}) | InProgress Cases`;
  });
  const [showCaseDetail, setShowCaseDetail] = useState(false);
  const location = useLocation();
  const { statusCode } = location.state;
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [searchTeam, setSearchTeam] = useState("");
  const [searchEmployeeTeam, setSearchEmployeeTeam] = useState("");
  const [hoveredEmployee, setHoveredEmployee] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [loadEmployees, setLoadEmployees] = useState(false);
  const theme = useTheme();
  const [
    showNewDisplinarlyCaseRequestDialog,
    setShowNewDisplinarlyCaseRequestDialog,
  ] = useState(false);
  const [filteredRequests, setFilteredRequests] = useState([
    ...entitySectorCases,
  ]);

  const [filteredEmployees, setFilteredEmployees] = useState([...employees]);
  useEffect(() => {
    if (!employees.length && hasPermissions(["IS_HEAD_OF_UNIT"]))
      getSupervisorEmployees((status, data) => {
        setLoadEmployees(status);
        if (!status && data) {
          setEmployees(data || []);
        }
      });
  }, []);

  useEffect(() => {
    // console.log(statusCode);
    getEntitySectorCases(setLoading, statusCode || "IN_PROGRESS_CASES");
  }, [statusCode]);

  useEffect(() => {
    const filtered = [...entitySectorCases].filter(
      ({
        id,
        effectiveDate,
        firstName,
        lastName,
        positionName,
        caseSanctionName,
        createdOn,
        caseStatusName,
      }) =>
        (id || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (effectiveDate || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) ||
        (firstName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (lastName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (firstName + " " + lastName || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) ||
        (lastName + " " + firstName || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) ||
        (positionName || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (createdOn || "").toLowerCase().includes(searchTeam.toLowerCase()) ||
        (caseStatusName || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase()) ||
        (caseSanctionName || "")
          .toLowerCase()
          .includes(searchTeam.toLowerCase())
    );

    setFilteredRequests(filtered);
    paginate(filtered);
  }, [searchTeam, entitySectorCases]);

  useEffect(() => {
    const filtered = [...employees].filter(
      ({ employeeId, firstName, lastName, names, positionName }) =>
        (employeeId || "")
          .toLowerCase()
          .includes(searchEmployeeTeam.toLowerCase()) ||
        (names || "")
          .toLowerCase()
          .includes(searchEmployeeTeam.toLowerCase()) ||
        (firstName || "")
          .toLowerCase()
          .includes(searchEmployeeTeam.toLowerCase()) ||
        (lastName || "")
          .toLowerCase()
          .includes(searchEmployeeTeam.toLowerCase()) ||
        (firstName + " " + lastName || "")
          .toLowerCase()
          .includes(searchEmployeeTeam.toLowerCase()) ||
        (lastName + " " + firstName || "")
          .toLowerCase()
          .includes(searchEmployeeTeam.toLowerCase()) ||
        (positionName || "")
          .toLowerCase()
          .includes(searchEmployeeTeam.toLowerCase())
    );

    setFilteredEmployees(filtered);
  }, [searchEmployeeTeam, employees]);

  // let statuses = Array.from(
  //   new Set(entitySectorCases.map((caseItem) => caseItem.caseStatusName))
  // );

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(filteredRequests);
  }, [itemOffset, itemsPerPage, filteredRequests]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredRequests.length || 0;

    setItemOffset(newOffset);
  };
  const onSearch = (term) => {
    setSearchTeam(term);
  };

  const onSearchEmployee = (term) => {
    setSearchEmployeeTeam(term);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleOpenInitiateCase = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseInitiateCase = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <div className="w-100">
        <AppBar
          position="static"
          elevation={0}
          style={{ overflow: "hidden" }}
          className="app-bar w-100"
        >
          <Toolbar className="row">
            <div className="col-lg-7">
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>DISCIPLINE</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    In Progress Cases
                  </span>
                </span>
              </Typography>
            </div>

            <div className="col-lg-5">
              <div className="row">
                <div
                  className={`${
                    hasPermissions(["IS_HEAD_OF_UNIT"])
                      ? "col-lg-10"
                      : "col-lg-12"
                  }`}
                >
                  <SearchBox
                    disabled={!entitySectorCases.length}
                    onSearch={onSearch}
                    placeholder="Search…"
                  />
                </div>
                {showCaseDetail && (
                  <ViewCaseDialog
                    showCaseDetail={showCaseDetail}
                    setShowCaseDetail={setShowCaseDetail}
                  />
                )}
                {showNewDisplinarlyCaseRequestDialog && (
                  <NewDisplinarlyCaseRequestDialog
                    showNewDisplinarlyCaseRequestDialog={
                      showNewDisplinarlyCaseRequestDialog
                    }
                    setShowNewDisplinarlyCaseRequestDialog={
                      setShowNewDisplinarlyCaseRequestDialog
                    }
                    employee={hoveredEmployee}
                    handleCloseInitiateCase={handleCloseInitiateCase}
                    setShowCaseDetail={setShowCaseDetail}
                    isFirstCategory={true}
                  />
                )}

                {hasPermissions(["IS_HEAD_OF_UNIT"]) && (
                  <>
                    {" "}
                    <div className="col-lg-2">
                      <Button
                        aria-describedby={id}
                        onClick={handleOpenInitiateCase}
                        style={{
                          ...ActiveButton,
                          // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                          float: "right",
                        }}
                        size="md"
                      >
                        <span className="material-icons">add</span>Initiate Case
                      </Button>

                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseInitiateCase}
                        anchorOrigin={{
                          vertical: "right",
                          horizontal: "top",
                        }}
                        anchorPosition={{
                          top: "40px",
                          left: "-50px",
                        }}
                      >
                        <Card sx={{ maxWidth: "100%" }}>
                          <CardHeader
                            sx={true}
                            action={
                              <IconButton
                                aria-label="close"
                                onClick={handleCloseInitiateCase}
                              >
                                <span className="material-icons">close</span>
                              </IconButton>
                            }
                            subheader={<>Choose an employee</>}
                          />
                          <Divider />
                          <div className="px-2 pt-2">
                            <SearchBox
                              disabled={!employees.length}
                              onSearch={onSearchEmployee}
                              placeholder="Search employee…"
                            />
                          </div>

                          <CardContent
                            style={{ maxHeight: "500px", overflowX: "auto" }}
                          >
                            {loadEmployees && (
                              <>
                                {" "}
                                <BackdropLoader isLoading={true} />
                              </>
                            )}

                            {filteredEmployees.map((employee, index) => (
                              <>
                                <Card
                                  onMouseEnter={() =>
                                    setHoveredEmployee(employee)
                                  }
                                  onClick={() => {
                                    setHoveredEmployee(employee);
                                    setShowNewDisplinarlyCaseRequestDialog(
                                      true
                                    );
                                    // handleCloseInitiateCase();
                                  }}
                                  // onMouseLeave={() => setHoveredEmployee(null)}
                                  onFocus={() => setHoveredEmployee(employee)}
                                  onBlur={() => setHoveredEmployee(null)}
                                  key={index + 1}
                                  className={`rounded hoverable-card mb-2 mt-2 pb-3`}
                                  variant="outlined"
                                  sx={{
                                    backgroundColor:
                                      hoveredEmployee === employee
                                        ? `#cdeef8`
                                        : "",
                                    border: "none",
                                    cursor:
                                      hoveredEmployee === employee
                                        ? "pointer"
                                        : "",
                                  }}
                                >
                                  <CardHeader
                                    className="pb-0 pt-2"
                                    avatar={
                                      <Tooltip title={employee.names}>
                                        <Box
                                          sx={{
                                            position: "relative",
                                            display: "inline-flex",
                                            cursor: "default",
                                          }}
                                        >
                                          <Avatar
                                            sizes="large"
                                            style={{
                                              border: "1px solid #d1cbcb",
                                            }}
                                            alt={employee.names}
                                            className=""
                                            src={defaultProfile}
                                          />
                                        </Box>
                                      </Tooltip>
                                    }
                                    title={
                                      <span
                                        className="d-flex flex-column"
                                        style={{ maxWidth: "400px" }}
                                      >
                                        <span className="d-flex align-items-center text-uppercase font-weight-bold text-primary">
                                          {employee.names}
                                        </span>
                                        <span className="d-block text-primary ml-1 text-sm text-truncate">
                                          {employee.positionName}{" "}
                                          {/* <strong className="text-dark">
                                            @{employee.unitName.toUpperCase()}
                                          </strong> */}
                                        </span>
                                      </span>
                                    }
                                    subheader={
                                      <span className="d-flex align-items-center flex-wrap justify-content-between ">
                                        <span className="d-flex align-items-center flex-wrap">
                                          <span className="">
                                            <span className="d-flex align-items-center  flex-wrap">
                                              <span
                                                className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                                style={{
                                                  cursor: "default",
                                                  borderRadius: "8px",
                                                }}
                                              >
                                                <span
                                                  className={`badge badge-primary mr-1`}
                                                >
                                                  {employee?.totalCases || 0}
                                                </span>
                                                All cases
                                              </span>

                                              <span
                                                className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                                style={{
                                                  cursor: "default",
                                                  borderRadius: "8px",
                                                }}
                                              >
                                                <span
                                                  className={`badge badge-warning mr-1`}
                                                >
                                                  {employee?.totalOngoing || 0}
                                                </span>
                                                Ongoing
                                              </span>
                                              <span
                                                className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                                style={{
                                                  cursor: "default",
                                                  // borderRight: "2px solid #ccd0d6",
                                                  // borderLeft: "2px solid #ccd0d6",
                                                  borderRadius: "8px",
                                                }}
                                              >
                                                <span
                                                  className={`badge badge-success mr-1`}
                                                >
                                                  {employee?.totalSanctioned ||
                                                    0}
                                                </span>
                                                Sanctioned Cases
                                              </span>

                                              <span
                                                className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                                style={{
                                                  cursor: "default",
                                                  // borderRight: "2px solid #ccd0d6",
                                                  // borderLeft: "2px solid #ccd0d6",
                                                  borderRadius: "8px",
                                                }}
                                              >
                                                <span
                                                  className={`badge badge-secondary mr-1`}
                                                >
                                                  {employee?.totalClosed || 0}
                                                </span>
                                                Closed Cases
                                              </span>

                                              <span
                                                className={`d-flex align-items-center px-1 mx-1 text-dark text-uppercase badge badge-light`}
                                                style={{
                                                  cursor: "default",
                                                  // borderRight: "2px solid #ccd0d6",
                                                  // borderLeft: "2px solid #ccd0d6",
                                                  // borderRadius: "8px",
                                                }}
                                              >
                                                <span
                                                  className={`badge badge-danger mr-1`}
                                                >
                                                  {employee?.totalRejected || 0}
                                                </span>{" "}
                                                Rejected
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        {/* {hoveredEmployee ===
                                                  employee && (
                                                  <>
                                                  <span className="d-flex align-items-center flex-wrap  px-2">
                                                    <Button
                                                      aria-describedby={id}
                                                      // onClick={handleOpenInitiateCase}
                                                      style={{
                                                        borderRadius: "8px",
                                                        border: `1px solid rgb(7, 142, 206)`,
                                                        display: "flex",
                                                        color: "#fff",
                                                        alignItems: "left",
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        textTransform: "initial",
                                                        backgroundColor: "rgb(7, 142, 206)",
                                                        "&:hover": {
                                                          backgroundColor: "#f0f2f5",
                                                        },
                                                        // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                                                        float: "right",
                                                      }}
                                                      size="xs"
                                                    >
                                                      <span className="material-icons">
                                                        add
                                                      </span>
                                                      Initiate
                                                    </Button>
                                                    </span>
                                                  </>
                                                )} */}
                                      </span>
                                    }
                                    action={
                                      <>
                                        {/* {hoveredEmployee === employee && (
                                          <IconButton className="text-success" aria-label="comment">
                                            <ArrowOutwardIcon />
                                          </IconButton>
                                        )} */}
                                      </>
                                    }
                                  />
                                </Card>
                                <Divider />
                                <Divider />
                              </>
                            ))}
                          </CardContent>
                          {/* {currentSanction && ( */}
                          <CardActions
                            disableSpacing
                            className="py-3 d-flex justify-content-center"
                          ></CardActions>
                          {/* )} */}
                        </Card>
                      </Popover>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <div
          style={{
            padding: theme.spacing(0.4),
            height: "auto",
            maxHeight: "auto",
            margin: "1.2em",
            backgroundColor: "#fff",
            borderRadius: "0.5rem",
            overflow: "visible",
            boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
            MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
          }}
        >
          <CardContent
            style={{
              padding: ".1em",
              height: "auto",
              minHeight: "auto",
              overflow: "auto",
            }}
          >
            <table className="table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col" className="text-left">
                    No
                  </th>
                  <th scope="col" className="text-left">
                    EmployeeName
                  </th>

                  <th scope="col" className="text-left">
                    Position
                  </th>

                  {/* <th scope="col" className="text-left">
                  Unit
                </th> */}

                  <th scope="col" className="text-left">
                    Category
                  </th>
                  <th scope="col" className="text-left">
                    Sanction
                  </th>

                  <th scope="col" className="text-right">
                    RequestedAt
                  </th>
                  <th scope="col" className="text-right">
                    UpdatedAt
                  </th>
                  <th scope="col" className="text-right">
                    Status
                  </th>

                  <th scope="col" className="text-right">
                    <span className="mr-3">Actions</span>
                  </th>
                </tr>
              </thead>

              {loading ? (
                <TableSkeleton cols={9} />
              ) : (
                <tbody>
                  {paginatedItems.map((request, index) => (
                    <CaseCard
                      user={user}
                      request={request}
                      index={index}
                      key={index}
                      setLoading1={setLoading1}
                      loading1={loading1}
                      getCase={getCase}
                    />
                  ))}
                </tbody>
              )}
            </table>

            {!!paginatedItems.length &&
              entitySectorCases.length >= itemsPerPage && (
                <div
                  className="justify-content-center mt-2"
                  style={{ overflow: "hidden" }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                    activeLinkClassName="active"
                  />
                </div>
              )}

            <NoRecordMessage
              isEmpty={!filteredRequests.length && !loading}
              title="No Results found"
            />
          </CardContent>
        </div>
      </div>
    </Box>
  );
};

const mapStateToProps = ({ user, entitySectorCases }) => {
  return { user, entitySectorCases };
};
const mapDispatchToProps = {
  getEntitySectorCases,
  deleteDisciplinaryCase,
  getCase,
  getSupervisorEmployees,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllCases);
