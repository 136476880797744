import leaveTypes from "./action-types";
import axios from "axios";
import { showError, showSuccess } from "../../app/toastify";

import commonTypes from "../common/action-types";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...leaveTypes };

export const getPublicHolidays = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get("/api/leave/public-holidays");

      dispatch({
        type: types.SET_PUBLIC_HOLIDAYS,
        data,
      });
      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const countLeaveDays = (
  { startDate, endDate, leavePlanId, employeePositionId, isPostponed },
  cb
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        `/api/leave/count-leave-days/${startDate}/${endDate}/${
          leavePlanId || ""
        }${leavePlanId && employeePositionId ? `/${employeePositionId}` : ""}${
          leavePlanId && employeePositionId && isPostponed ? "/1" : ""
        }`
      );
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const createLeavePlan = (formData, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.post(
        `/api/leave/create-leave-plan`,
        formData
      );
      if (!formData.isForPostponement && !formData.leavePlanId) {
        dispatch({
          type: types.SET_MY_LEAVE_PLANS,
          data,
        });
        showSuccess(`Leave plan created successfully`);
      } else {
        dispatch({
          type: types.CHANGE_LEAVE_PLAN_STATUS,
          data: data[0],
        });
        showSuccess(
          `${
            !!formData.isForPostponement
              ? "Leave postponed"
              : "Leave plan updated"
          } successfully`
        );
      }
      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const getSubordinateLeavePlan = (query, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        "/api/leave/get-subordinate-leave-plan?" +
          new URLSearchParams(query).toString()
      );

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getMyAnnualLeavePlan = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get("/api/leave/get-my-leave-plan");

      dispatch({
        type: types.SET_MY_LEAVE_PLANS,
        data,
      });
      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const cancelLeavePlan = (leavePlanId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.delete(
        `/api/leave/cancel-leave-plan/${leavePlanId}`
      );
      dispatch({
        type: types.CANCEL_LEAVE_PLAN,
        data: leavePlanId,
      });
      showSuccess("Leave plan cancelled successfully");
      dispatch({ type: types.END_LOADING });
      cb(false, true);
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getUnitAnnualLeavePlan = (
  cb,
  employeePositionId,
  isForRequest = "false"
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get(
        `/api/leave/get-unit-leave-plan` +
          (employeePositionId ? `/${employeePositionId}/${isForRequest}` : "")
      );

      dispatch({
        type: types.SET_MY_UNIT_LEAVE_PLANS,
        data,
      });

      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const geEmployeeInLeavePeriod = (
  { startDate, endDate, leavePlanId },
  cb
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, []);
      const { data } = await axios.get(
        `/api/leave/leaves-in-period/${startDate}/${endDate}/${
          leavePlanId || ""
        }`
      );
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const changeLeavePlanStatus = (formData, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.put(
        `/api/leave/change-leave-plan-status`,
        formData
      );
      dispatch({
        type: types.CHANGE_LEAVE_PLAN_STATUS,
        data,
      });
      showSuccess(`Action done successfully`);
      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const geLeaveTypes = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get(`/api/leave/leave-types`);
      cb(false);
      dispatch({
        type: types.SET_LEAVE_TYPES,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const geGetLeaveReasons = (leaveTypeId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, []);
      const { data } = await axios.get(
        `/api/leave/leave-reasons/${leaveTypeId}`
      );
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const createOtherLeaveRequest = (formData, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.post(
        `/api/leave/request-other-leave`,
        formData
      );
      dispatch({
        type: types.SET_MY_OTHER_LEAVE_REQUESTS,
        data,
      });
      showSuccess(
        `Leave Request ${
          !!formData.leavePlanId ? "updated" : "created"
        } successfully`
      );
      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const getMyOtherLeaveRequests = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get(
        "/api/leave/get-my-other-leave-requests"
      );

      dispatch({
        type: types.SET_MY_OTHER_LEAVE_REQUESTS,
        data,
      });
      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getOtherLeaveRequest = (query, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        `/api/leave/get-other-leave-request?` +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_OTHER_LEAVE_REQUESTS,
        data: data.records,
      });

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const changeOtherLeaveStatus = (formData, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.put(
        `/api/leave/change-other-leave-status`,
        formData
      );
      dispatch({
        type: types.CHANGE_OTHER_LEAVE_REQUEST_STATUS,
        data,
      });
      showSuccess(`Action done successfully`);
      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const cancelLeaveRequest = (leaveRequestId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      await axios.delete(`/api/leave/cancel-leave-request/${leaveRequestId}`);
      dispatch({
        type: types.CANCEL_OTHER_LEAVE_REQUEST,
        data: leaveRequestId,
      });
      showSuccess("Leave plan cancelled successfully");
      dispatch({ type: types.END_LOADING });
      cb(false, true);
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const geGetLeaveLogs = (leaveId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, []);
      const { data } = await axios.get(`/api/leave/get-leave-logs/${leaveId}`);
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPostponedLeaveDetails = (leaveId, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, []);
      const { data } = await axios.get(
        `/api/leave/get-postponed-leave-details/${leaveId}`
      );
      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getEmployeeInLeave = (query, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get(
        "/api/leave/get-employee-in-leave?" +
          new URLSearchParams(query).toString()
      );

      dispatch({
        type: types.SET_EMPLOYEE_IN_LEAVE,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      cb(false);
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getAnnualEmployeeLeaves = ({ isWeekly }, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get(
        "/api/leave/get-annual-employee-leaves?" +
          new URLSearchParams({ isWeekly }).toString()
      );

      dispatch({
        type: isWeekly
          ? types.SET_WEEKLY_LEAVE_SUMMARY
          : types.SET_ANNUAL_LEAVE_EMPLOYEES,
        data: data,
      });
      dispatch({ type: types.END_LOADING });
      cb(false);
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const calculateReturnDate = ({ startDate, numOfDays }, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        `/api/leave/calculate-return-date/${startDate}/${numOfDays}`
      );

      cb(false, data);
      dispatch({ type: types.END_LOADING });
      cb(false, null);
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPieAnnualLeavePlan = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(`/api/leave/get-pie-annual-leave-plan`);

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getLeaveTypeBalances = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(`/api/leave/get-leave-type-balances`);

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPieInLeaveStatus = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(`/api/leave/get-pie-in-leave-status`);

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getOtherLeaveSummary = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(`/api/leave/get-other-leave-summary`);

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const acceptLeavePlans = (formData, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.put(`/api/leave/accept-leave-plans`, {
        data: formData,
      });
      dispatch({
        type: types.ACCEPT_LEAVE_PLANS,
        data,
      });
      showSuccess(`Action done successfully`);
      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const getEntityAnnualLeavePlans = (cb, unitId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, []);
      const { data } = await axios.get(
        `/api/leave/entity-annual-leave${!!unitId ? `/${unitId}` : ""}`
      );

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, []);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const confirmAllLeavePlans = (cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, false);
      const { data } = await axios.put(`/api/leave/confirm-leave-plans`);

      showSuccess(data);

      cb(false, true);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getUnitWithLeaves = (
  { leaveType = "annual", status = "" },
  cb
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true);
      const { data } = await axios.get(
        `/api/leave/get-unit-with-leaves/${leaveType}${
          status ? `/${status}` : ""
        }`
      );

      dispatch({
        type:
          leaveType === "annual"
            ? types.SET_UNIT_WITH_ANNUAL_LEAVE
            : types.SET_UNIT_WITH_OTHER_LEAVE,
        data,
      });
      cb(false);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getMonitorLeavePlan = (query, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      cb(true, null);
      const { data } = await axios.get(
        "/api/leave/get-monitor-leave-plan?" +
          new URLSearchParams(query).toString()
      );

      cb(false, data);
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      cb(false, null);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};