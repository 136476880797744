import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  getSalaryIndexGrid,
  updateSalaryIndexGrid,
} from "../../../store/structure/actions";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import { Typography, Box, Toolbar, Skeleton } from "@mui/material";

const SalaryIndexGrid = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Org. Structure | Salary Index Grid";
  });

  const {
    salaryIndexGrid,
    getSalaryIndexGrid,
    updateSalaryIndexGrid,
    loading,
    onWait,
  } = props;

  const [hoveredIndexValue, sethoveredIndexValue] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (!salaryIndexGrid) getSalaryIndexGrid();
  }, []);

  return (
    <div
      className="px-4 fixTableHead"
      style={{
        backgroundColor: "#fff",
        borderRadius: "0.5rem",
        boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
        MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
        height: "65vh",
        width: "100vw",
        margin: "0px",
        overflowX: "auto",
      }}
    >
      {loading && !onWait && (
        <div className="container mt-2" style={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </div>
      )}

      {salaryIndexGrid &&
        (!loading || onWait) &&
        !!salaryIndexGrid.columns.length && (
          <table className=" tablex table-bordered fixTableHead">
            <thead>
              <tr>
                {salaryIndexGrid.columns.map((column, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && (
                      <th
                        style={
                          index === 1
                            ? { textAlign: "center" }
                            : { textAlign: "right" }
                        }
                      >
                        {column === "hasSubTransportAllowance"
                          ? "Sub.Tran"
                          : column === "hasHousingAllowance"
                          ? "Hous"
                          : column === "hasTransportAllowance"
                          ? "Tran"
                          : column === "name"
                          ? "LEVEL"
                          : column.toUpperCase()}
                      </th>
                    )}
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {salaryIndexGrid &&
                salaryIndexGrid.rows.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={
                      hoveredIndexValue &&
                      hoveredIndexValue.column !== "name" &&
                      hoveredIndexValue.rowIndex === rowIndex
                        ? "hovered-row"
                        : ""
                    }
                  >
                    {salaryIndexGrid.columns.map((column, colIndex) => (
                      <React.Fragment key={colIndex}>
                        {colIndex > 0 && (
                          <td
                            style={
                              colIndex === 1
                                ? { textAlign: "center" }
                                : { textAlign: "right" }
                            }
                            onMouseOver={() => {
                              const level = row[salaryIndexGrid.columns[0]];
                              sethoveredIndexValue({
                                level,
                                column,
                                rowIndex,
                                colIndex,
                              });
                            }}
                            onMouseLeave={() => sethoveredIndexValue(null)}
                            className={
                              hoveredIndexValue &&
                              hoveredIndexValue.column !== "name" &&
                              hoveredIndexValue.rowIndex === rowIndex &&
                              hoveredIndexValue.colIndex === colIndex &&
                              !(
                                column === "hasHousingAllowance" ||
                                column === "hasTransportAllowance" ||
                                column === "hasSubTransportAllowance"
                              )
                                ? "hovered-td-box"
                                : hoveredIndexValue &&
                                  hoveredIndexValue.column !== "name" &&
                                  hoveredIndexValue.colIndex === colIndex
                                ? "hovered-td"
                                : ""
                            }
                          >
                            {hoveredIndexValue &&
                            hoveredIndexValue.column !== "name" &&
                            hoveredIndexValue.rowIndex === rowIndex &&
                            hoveredIndexValue.colIndex === colIndex ? (
                              <GridInput
                                level={
                                  hoveredIndexValue && hoveredIndexValue.level
                                }
                                column={column}
                                colIndex={colIndex}
                                rowIndex={rowIndex}
                                row={row}
                                updateSalaryIndexGrid={updateSalaryIndexGrid}
                              />
                            ) : column === "hasHousingAllowance" ||
                              column === "hasSubTransportAllowance" ||
                              column === "hasTransportAllowance" ? (
                              <GridInput
                                level={
                                  hoveredIndexValue && hoveredIndexValue.level
                                }
                                column={column}
                                colIndex={colIndex}
                                rowIndex={rowIndex}
                                row={row}
                                updateSalaryIndexGrid={updateSalaryIndexGrid}
                              />
                            ) : (
                              <React.Fragment>
                                {colIndex === 1 ? (
                                  <span className="align-level-centered">
                                    {" "}
                                    {row[column]}{" "}
                                  </span>
                                ) : (
                                  <span className="align-indexvalue-right">
                                    {" "}
                                    {row[column]}{" "}
                                  </span>
                                )}
                              </React.Fragment>
                            )}
                          </td>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        )}
    </div>
  );
};

const mapStateToProps = ({ loading, onWait, salaryIndexGrid }) => {
  return { loading, onWait, salaryIndexGrid };
};
export default connect(mapStateToProps, {
  getSalaryIndexGrid,
  updateSalaryIndexGrid,
})(SalaryIndexGrid);

const GridInput = (props) => {
  const { level, column, colIndex, rowIndex, row, updateSalaryIndexGrid } =
    props;
  const [value, setValue] = useState(row[column] ? row[column] : "");
  const [valueBoolen, setValueBoolen] = useState(row[column] ? true : false);

  const onSaveChanges = (level, scale, value, type) => {
    if (type === "checkbox") {
      value = value ? 1 : 0;

      updateSalaryIndexGrid(level, scale, value, type, setValueBoolen);
    }
    if (type === "number") {
      value = value ? parseInt(value) : null;

      updateSalaryIndexGrid(level, scale, value, type, setValue);
    }
  };

  return (
    <>
      {column === "hasHousingAllowance" ||
      column === "hasTransportAllowance" ||
      column === "hasSubTransportAllowance" ? (
        <input
          type="checkbox"
          name={column + "-" + colIndex + "-" + rowIndex}
          // onBlur={() => console.log("Saved")}
          value={valueBoolen}
          checked={valueBoolen ? "checked" : ""}
          // onChange={(e) => {
          //   const value = !valueBoolen;

          //   row[column] = value;

          //   onSaveChanges(level, column, value, "checkbox");
          // }}
        />
      ) : (
        <input
          readOnly
          type="text"
          name={column + "-" + colIndex + "-" + rowIndex}
          // onKeyPress={(e) =>
          //   e.key === "Enter" && onSaveChanges(level, column, value, "number")
          // }

          value={value}
          // onChange={(e) => {
          //   const value = e.target.value || "";
          //   row[column] = value;
          //   setValue(value);
          // }}
        />
      )}
    </>
  );
};
