import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Typography,
  Autocomplete,
  Box,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  assignOrCreatePlanningEntity,
  getEntitySectorPlanningEntities,
} from "../../../store/structure/actions";

const SetPlanningEntityDialog = (props) => {
  const {
    loading,
    showPlanningForm,
    setShowPlanningForm,
    selectedUnit,
    setSelectedUnit,
    getEntitySectorPlanningEntities,
    assignOrCreatePlanningEntity,
    setRefreshUnits,
    planningEntities,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    unitId: selectedUnit.unitId,
    planningEntityId: null,
    affectUnitChildren: true,
    isPlanningEntity: null,
  });
  const [selectedPlanningEntity, setSelectedPlanningEntity] = useState(null);

  useEffect(() => {
    if (!planningEntities.length) {
      getEntitySectorPlanningEntities((status) => {
        setIsLoading(status);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (planningEntities.length === 1) {
      setSelectedPlanningEntity(planningEntities[0] || null);
      setFormData({
        ...formData,
        planningEntityId: planningEntities[0].id,
      });
    }
  }, [planningEntities]);

  useEffect(() => {
    if (
      formData.isPlanningEntity === false &&
      !!planningEntities.length &&
      !!selectedUnit
    ) {
      const thisEntity = planningEntities.find(
        (item) => item.id === selectedUnit.planningEntityId
      );
      setSelectedPlanningEntity(thisEntity || null);
      setFormData({
        ...formData,
        planningEntityId: thisEntity.id,
      });
    }
  }, [formData.isPlanningEntity]);

  const onSave = () => {
    assignOrCreatePlanningEntity(formData, (status, res) => {
      setIsLoading(status);
      if (!status && !!res) {
        setRefreshUnits(true);
        onClose();
      }
    });
  };

  const onClose = () => {
    setShowPlanningForm(false);
    setSelectedUnit("");
    setFormData({
      unitId: null,
      planningEntityId: null,
      affectUnitChildren: false,
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showPlanningForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            <span className="text-dark font-weight-bold">
              RBM Planning Entity Settings
            </span>{" "}
            @{selectedUnit.unitName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <FormControl
            className="mt-3 mb-0 d-block"
            disabled={isLoading || loading}
          >
            <FormLabel id="isPlanningEntity" className="mb-0">
              Make{" "}
              <span className="font-weight-bold">{selectedUnit.unitName}</span>{" "}
              a planning entity?
            </FormLabel>
            <RadioGroup
              className="mt-0"
              row
              name="isPlanningEntity"
              value={formData.isPlanningEntity}
              onChange={(event) => {
                formData.isPlanningEntity =
                  event.target.value === "true" ? true : false;
                setFormData({ ...formData });
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>

          {formData.isPlanningEntity === false && (
            <Autocomplete
              className="mt-3"
              size="small"
              id="planning-entity"
              defaultValue={null}
              disabled={isLoading || loading}
              value={selectedPlanningEntity || null}
              options={planningEntities}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, planningEntity) => {
                setSelectedPlanningEntity(planningEntity || null);
                setFormData({
                  ...formData,
                  planningEntityId: planningEntity.id,
                });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, planningEntity) => (
                <Box component="li" {...props}>
                  {planningEntity.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Planning Entities"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          )}

          {formData.isPlanningEntity !== null && (
            <FormControl
              className="mt-3 mb-0 d-block"
              disabled={isLoading || loading}
            >
              <FormLabel id="affectUnitChildren" className="mb-0">
                Apply change to children units?
              </FormLabel>
              <RadioGroup
                className="mt-0"
                row
                name="affectUnitChildren"
                value={formData.affectUnitChildren}
                onChange={(event) => {
                  formData.affectUnitChildren =
                    event.target.value === "true" ? true : false;
                  setFormData({ ...formData });
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={
              loading ||
              isLoading ||
              formData.isPlanningEntity === null ||
              (selectedUnit &&
                selectedUnit.planningEntityId === formData.unitId &&
                !!formData.isPlanningEntity) ||
              (selectedUnit &&
                selectedUnit.planningEntityId === formData.planningEntityId &&
                formData.isPlanningEntity === false)
            }
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, planningEntities }) => {
  return {
    loading,
    planningEntities,
  };
};
export default connect(mapStateToProps, {
  getEntitySectorPlanningEntities,
  assignOrCreatePlanningEntity,
})(SetPlanningEntityDialog);
