import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getEmployeesEvaluations,
  downloadEmployeePerformanceReport,
  downloadInstitutionEmployeesScores,
  getEmployeeEvaluationSummary,
  downloadCSVEmployeeEvaluations,
} from "../../../store/rbm/actions";
import MonitorEmployeeCard from "../components/MonitorEmployeeCard";
import RBMPageHeader from "../components/RBMPageHeader";
import ContractDetailsDialog from "../components/ContractDetailsDialog";
import {
  getRRADepartments,
  getTreeUnits,
} from "../../../store/structure/actions";
import { isEmpty } from "lodash";

const MonitorEmployees = (props) => {
  const {
    user,
    employeesEvaluations,
    loading,
    selectedFiscalYear,
    selectedUserEntity,
    selectedPlanningEntityId,
    getEmployeesEvaluations,
    downloadInstitutionEmployeesScores,
    getEmployeeEvaluationSummary,
    rraDepartments,
    getRRADepartments,
    units,
    getTreeUnits,
    downloadCSVEmployeeEvaluations,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [evaluationSummary, setEvaluationSummary] = useState({
    allEmployee: 0,
    score: 0,
    numOfActivities: 0,
    onTrackActivities: 0,
    overdueActivities: 0,
    reportedActivities: 0,
    scoredActivities: 0,
    // targetShare: 0,
    // achievedTarget: 0,
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [loadingContract, setLoadingContract] = useState(true);
  const [generateReport, setGenerateReport] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [rraDepartment, setRRADepartment] = useState(null);
  const [rraSemester, setRRASemester] = useState(null);
  const [sortedUnits, setSortedUnits] = useState([]);
  const [showPendingEvaluation, setShowPendingEvaluation] = useState(false);
  const [risaReportFilter, setRISAReportFilter] = useState({
    allEmployee: true,
    notSharedStaff: true,
    showSharedStaff: true,
    showSharedITStaff: true,
  });

  const handleLoadEmployee = (query, setLoading) => {
    if (setLoading) {
      getEmployeesEvaluations(query, setTotalRecords, setLoading);
    } else {
      getEmployeesEvaluations(query, setTotalRecords, setLoader);
    }
  };

  useEffect(() => {
    document.title = "IPPIS - RBM Monitor Employees";
  }, []);

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };
    handleLoadEmployee(query, setLoadingContract);
    getEmployeeEvaluationSummary((res) => {
      if (res) setEvaluationSummary(res);
    });
  }, [selectedFiscalYear, selectedUserEntity]);

  useEffect(() => {
    if (generateReport && sortedUnits.length === 0 && !isEmpty(units)) {
      const sorted = units.sort((a, b) => {
        if (a.parentUnitId === null) return -1;
        if (b.parentUnitId === null) return 1;
        return 0;
      });
      setSortedUnits(sorted);
    }
  }, [generateReport, units]);

  useEffect(() => {
    if (generateReport) {
      if (!rraDepartments.length) getRRADepartments();
      if (isEmpty(units)) getTreeUnits(user?.selectedEntitySector?.unitId);
    }
  }, [generateReport]);

  useEffect(() => {
    if (!!sortedUnits.length && sortedUnits[0].parentUnitId !== null) {
      setSortedUnits([
        {
          unitId: null,
          unitName: "All",
          parentUnitId: null,
        },
        ...sortedUnits,
      ]);
    }
  }, [sortedUnits]);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };

      handleLoadEmployee(query);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    handleLoadEmployee(query);
  };

  const handleClose = () => {
    setGenerateReport(false);
    setRRADepartment(null);
    setRRASemester(null);
    setSelectedUnit(null);
  };
  const [isDownloading, setIsDownloading] = useState(false);

  return (
    <>
      <div className="row no-gutter justify-content-center">
        <div className="col-12 col-lg-11 px-0 px-md-3">
          <RBMPageHeader
            labels={[
              {
                title: "Employee",
                value: evaluationSummary.allEmployee,
                type: "number",
              },
              {
                title: "Items",
                value: evaluationSummary.numOfActivities || 0,
                type: "number",
              },
              {
                title: "OnTrack",
                value: evaluationSummary.onTrackActivities || 0,
                type: "number",
                onClick: () => {
                  const query = {
                    searchTerm: "ontrack",
                    page: currentPage,
                    size: itemPerPage,
                  };
                  setSearchTerm("ontrack");
                  handleLoadEmployee(query);
                },
              },
              {
                title: "Overdue",
                value: evaluationSummary.overdueActivities || 0,
                type: "number",
                onClick: () => {
                  const query = {
                    searchTerm: "overdue",
                    page: currentPage,
                    size: itemPerPage,
                  };
                  setSearchTerm("overdue");
                  handleLoadEmployee(query);
                },
              },
              {
                title: "Reported",
                value: evaluationSummary.reportedActivities || 0,
                type: "number",
                onClick: () => {
                  const query = {
                    searchTerm: "reported",
                    page: currentPage,
                    size: itemPerPage,
                  };
                  setSearchTerm("reported");
                  handleLoadEmployee(query);
                },
              },
              {
                title: "Scored",
                value: evaluationSummary.scoredActivities || 0,
                type: "number",
                onClick: () => {
                  const query = {
                    searchTerm: "scored",
                    page: currentPage,
                    size: itemPerPage,
                  };
                  setSearchTerm("scored");
                  handleLoadEmployee(query);
                },
              },
              // {
              //   title: "Target achieved",
              //   value: (
              //     ((evaluationSummary.achievedTarget || 0) /
              //       (evaluationSummary.targetShare || 0)) *
              //     100
              //   ).toFixed(2),
              //   type: "percentage",
              // },
            ]}
            progress={{
              title: "MONITORING EMPLOYEE PROGRESS",
              value: evaluationSummary.score,
            }}
            buttons={[
              {
                fun: () => setGenerateReport(true),
                title: "Generate Report",
              },
            ]}
            disabled={loading || loader || loadingContract}
            loading={loading || loader || loadingContract}
            isForRRA={
              user?.selectedEntitySector?.id ===
              "1203000001-090000000000000001-11"
            }
            csvDownloadBtn={{
              fun: () => downloadCSVEmployeeEvaluations(setIsDownloading),
            }}
            isDownloading={isDownloading}
          />

          <div className="rbm-shadow rounded px-4 py-3 ">
            <div className="form-group mt-2 mb-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                disabled={loading || loader || loadingContract}
                onChange={(e) => onSearch(e.target.value)}
                value={searchTerm}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    handleLoadEmployee(query);
                  }
                }}
              />

              <span
                className={`px-0 badge badge-pill btn-link cursor-pointer text-uppercase text-${
                  showPendingEvaluation ? "blue" : "secondary"
                } `}
                onClick={() => {
                  const query = {
                    searchTerm: searchTerm,
                    page: currentPage,
                    size: itemPerPage,
                    showPendingEvaluation: !showPendingEvaluation,
                  };
                  handleLoadEmployee(query);
                  setShowPendingEvaluation(!showPendingEvaluation);
                }}
              >
                <i className="fas fa-thumbtack"></i> Evaluation Not Complete
              </span>
            </div>

            {employeesEvaluations &&
              employeesEvaluations.map((employee) => (
                <MonitorEmployeeCard
                  key={employee.no}
                  employee={employee}
                  setSelectedEmployee={setSelectedEmployee}
                />
              ))}

            {!employeesEvaluations.length && (loading || loadingContract) && (
              <div>
                <Skeleton
                  variant="rectangular"
                  className="mb-3 mt-2"
                  height={118}
                />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
              </div>
            )}

            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecords / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!employeesEvaluations.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    handleLoadEmployee(query);
                  }}
                >
                  {[10, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          </div>

          {employeesEvaluations &&
            !employeesEvaluations[0] &&
            !loading &&
            !loader &&
            !loadingContract && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container">
                  <p className="lead">No employees found.</p>
                </div>
              </div>
            )}
        </div>
      </div>

      {selectedEmployee && (
        <ContractDetailsDialog
          open={true}
          onClose={() => setSelectedEmployee(null)}
          selectedSubordinate={{
            ...selectedEmployee,
            names: selectedEmployee.lastName + " " + selectedEmployee.firstName,
          }}
          isForMonitoring
        />
      )}

      {generateReport && (
        <Dialog onClose={handleClose} open={true} fullWidth maxWidth="sm">
          <DialogTitle>
            <Typography
              variant="overline"
              className="text-truncate"
              display="block"
              style={{ maxWidth: "70%" }}
            >
              Generate RBM Employee Report
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className="row mt-2">
              {user?.selectedEntitySector?.id !==
                "1203000001-090000000000000001-11" && (
                <div className="col-12">
                  <Autocomplete
                    size="small"
                    id="selectedUnit"
                    defaultValue={null}
                    value={selectedUnit || sortedUnits[0] || null}
                    options={sortedUnits}
                    isOptionEqualToValue={(option, value) =>
                      option.unitId === value.unitId
                    }
                    onChange={(event, selectedUnit) => {
                      setSelectedUnit(selectedUnit || null);
                    }}
                    getOptionLabel={(option) => option.unitName}
                    renderOption={(props, selectedUnit) => (
                      <>
                        <Box component="li" {...props}>
                          {selectedUnit.unitName}
                        </Box>
                        <Divider />
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Unit"
                        placeholder="Unit"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "selectedUnit",
                        }}
                      />
                    )}
                  />
                </div>
              )}
              {user?.selectedEntitySector?.id ===
                "1203000001-090000000000000001-11" && (
                <div className="col-12 row">
                  <div className="col-8">
                    <Autocomplete
                      size="small"
                      id="rraDepartment"
                      defaultValue={null}
                      value={rraDepartment || { id: null, name: "All" }}
                      options={[{ id: null, name: "All" }, ...rraDepartments]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, rraDepartment) => {
                        setRRADepartment(rraDepartment || null);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, rraDepartment) => (
                        <>
                          <Box component="li" {...props}>
                            {rraDepartment.name}
                          </Box>
                          <Divider />
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Department"
                          placeholder="Department"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "rraDepartment", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>

                  <span className="col-4">
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="rraSemester"
                      defaultValue={null}
                      value={rraSemester || { id: 0, name: "All" }}
                      options={[
                        { id: 0, name: "All" },
                        { id: 1, name: "1st" },
                        { id: 2, name: "2nd" },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, rraSemester) => {
                        setRRASemester(rraSemester || null);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, rraSemester) => (
                        <Box component="li" {...props}>
                          {rraSemester.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Semester"
                          placeholder="Semester"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "rraSemester", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </span>
                </div>
              )}

              {user?.selectedEntitySector?.id ===
                "1903000000-150000000000000000-11" && (
                <div className="mt-3 mx-3">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="sm"
                          checked={risaReportFilter.allEmployee}
                          onChange={() =>
                            setRISAReportFilter({
                              allEmployee: !risaReportFilter.allEmployee,
                              notSharedStaff: !risaReportFilter.allEmployee,
                              showSharedStaff: !risaReportFilter.allEmployee,
                              showSharedITStaff: !risaReportFilter.allEmployee,
                            })
                          }
                        />
                      }
                      label="All employees"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="sm"
                          checked={risaReportFilter.notSharedStaff}
                          onChange={() =>
                            setRISAReportFilter({
                              ...risaReportFilter,
                              notSharedStaff: !risaReportFilter.notSharedStaff,
                              allEmployee:
                                !risaReportFilter.notSharedStaff &&
                                risaReportFilter.showSharedStaff &&
                                risaReportFilter.showSharedITStaff,
                            })
                          }
                        />
                      }
                      label="Not Shared"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="sm"
                          checked={risaReportFilter.showSharedStaff}
                          onChange={() =>
                            setRISAReportFilter({
                              ...risaReportFilter,
                              showSharedStaff:
                                !risaReportFilter.showSharedStaff,
                              allEmployee:
                                risaReportFilter.notSharedStaff &&
                                !risaReportFilter.showSharedStaff &&
                                risaReportFilter.showSharedITStaff,
                            })
                          }
                        />
                      }
                      label="Shared Staff"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="sm"
                          checked={risaReportFilter.showSharedITStaff}
                          onChange={() =>
                            setRISAReportFilter({
                              ...risaReportFilter,
                              showSharedITStaff:
                                !risaReportFilter.showSharedITStaff,
                              allEmployee:
                                risaReportFilter.notSharedStaff &&
                                risaReportFilter.showSharedStaff &&
                                !risaReportFilter.showSharedITStaff,
                            })
                          }
                        />
                      }
                      label="Shared IT Staff"
                    />
                  </FormGroup>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="d-flex items-center justify-content-center w-100">
              <Button
                variant="contained"
                startIcon={
                  <span className="material-icons-round">file_download</span>
                }
                size="small"
                className="ml-3"
                onClick={() => {
                  downloadInstitutionEmployeesScores(
                    user.selectedEntitySector.id,
                    selectedPlanningEntityId,
                    {
                      unitId: selectedUnit?.parentUnitId
                        ? selectedUnit?.unitId
                        : "",
                      unitName: selectedUnit?.unitName || "",
                      rraDepartmentId: rraDepartment?.id || "",
                      rraDepartmentName: rraDepartment?.name || "",
                      rraSemesterId: rraSemester?.id || "",
                      rraSemesterName: rraSemester?.name || "",
                      ...risaReportFilter,
                    }
                  );
                  handleClose();
                }}
              >
                Download
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      )}

      <div
        className={`page-loading-void position-fixed ${
          loader && !!employeesEvaluations[0] ? "d-block" : "d-none"
        }`}
      >
        {loader && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  user,
  employeesEvaluations,
  loading,
  selectedFiscalYear,
  selectedUserEntity,
  rraDepartments,
  units,
  selectedPlanningEntityId,
}) => {
  return {
    user,
    employeesEvaluations,
    loading,
    selectedFiscalYear,
    selectedUserEntity,
    rraDepartments,
    units,
    selectedPlanningEntityId,
  };
};
export default connect(mapStateToProps, {
  getEmployeesEvaluations,
  getEmployeeEvaluationSummary,
  downloadEmployeePerformanceReport,
  downloadInstitutionEmployeesScores,
  getRRADepartments,
  getTreeUnits,
  downloadCSVEmployeeEvaluations,
})(MonitorEmployees);
