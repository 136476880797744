import {
  TextField,
  Box,
  Autocomplete,
  InputAdornment,
  IconButton,
  Skeleton,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  saveSalaryStructure,
  getEmployeeGroups,
  getHiringModes,
  getLevels,
  getEchelons,
  getVJobClassifications,
  getJobFields,
} from "../../../store/structure/actions";

import { showError } from "../../../app/toastify";

const AddSalaryStructureDialog = (props) => {
  const {
    loading,
    employeeGroups,
    selectedEntitySector,
    echelons,
    hiringModes,
    vJobClassifications,
    getVJobClassifications,
    getEmployeeGroups,
    getHiringModes,
    setShowAddNewForm,
    saveSalaryStructure,
    jobFields,
    getJobFields,
    getEchelons,
    levels,
  } = props;

  const [formData, setFormData] = useState({
    hiringModeId: "",
    employeeGroupId: "",
    scaleId: "",
    indexValue: 0,
    numberOfPosts: 0,
    name: "",
    levelId: "",
    jobClassificationId: "",
    jobFieldId: "",
    entitySectorId: selectedEntitySector ? selectedEntitySector.id : 0,
  });
  const [employeeGroup, setEmployeeGroup] = useState(null);
  const [hiringMode, setHiringMode] = useState(null);
  const [jobClassification, setJobClassification] = useState(null);
  const [showAddJobFieldForm, setAddJobFieldForm] = useState(false);

  const [jobField, setJobField] = useState(null);
  const [echelon, setEchelon] = useState(null);

  const [errors, setErrors] = useState({
    nameHasError: false,
    positionHasError: false,
    hiringModeHasError: false,
    employeeGroupHasError: false,
    jobFieldHasError: false,
    numberOfPostsHasError: false,
    echelonHasError: false,
    indexValueHasError: false,
  });

  useEffect(() => {
    getEmployeeGroups();
    getHiringModes();
    getJobFields();
    getEchelons();

    if (!vJobClassifications.length)
      getVJobClassifications(selectedEntitySector.entityClassId);
  }, [selectedEntitySector]);

  const formValidator = () => {
    const error = {
      positionHasError: false,
      hiringModeHasError: false,
      employeeGroupHasError: false,
      jobFieldHasError: false,
      numberOfPostsHasError: false,
      echelonHasError: false,
      indexValueHasError: false,
      hasError: false,
    };

    if (!formData.name) {
      error.nameHasError = true;
      error.hasError = true;
    }

    if (!employeeGroup) {
      error.employeeGroupHasError = true;
      error.hasError = true;
    }

    if (!echelon) {
      error.echelonHasError = true;
      error.hasError = true;
    }
    if (!hiringMode) {
      error.hiringModeHasError = true;
      error.hasError = true;
    }

    if (!formData.indexValue) {
      error.indexValueHasError = true;
      error.hasError = true;
    }
    if (!formData.numberOfPosts) {
      error.numberOfPostsHasError = true;
      error.hasError = true;
    }

    if (jobClassification && !jobClassification.jobFieldId && !jobField) {
      error.jobFieldHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveSalaryStructure(
        formData,
        setFormData,
        setShowAddNewForm,
        setJobClassification
      );
    }
  };

  const onClose = () => {
    setShowAddNewForm(false);

    setFormData({
      hiringModeId: "",
      employeeGroupId: "",
      scaleId: "",
      indexValue: 0,
      numberOfPosts: 0,
      name: "",
      levelId: "",
      jobClassificationId: "",
      jobFieldId: "",
    });

    setErrors({
      nameHasError: false,
      positionHasError: false,
      hiringModeHasError: false,
      employeeGroupHasError: false,
      jobFieldHasError: false,
      numberOfPostsHasError: false,
      echelonHasError: false,
      indexValueHasError: false,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <div
            className="elevated rounded p-3 mt-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-12 text-left">
                <h6 className="mb-2">Job Classifications</h6>
              </div>
            </div>
            {!vJobClassifications.length && (
              <div className="row mt-3">
                <div className="col-12">
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </div>
              </div>
            )}

            <div className="row mt-3">
              <div className="col-12">
                <Autocomplete
                  size="small"
                  id="jobClassification"
                  defaultValue={null}
                  value={jobClassification}
                  options={vJobClassifications}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, jobClassification) => {
                    setJobClassification(jobClassification || null);
                    const jobClassificationId = jobClassification
                      ? jobClassification.id
                      : "";
                    const levelId = jobClassification
                      ? jobClassification.levelId
                      : "";
                    const jobFieldId = jobClassification
                      ? jobClassification.jobFieldId
                      : "";
                    setFormData({
                      ...formData,
                      jobClassificationId,
                      levelId,
                      jobFieldId,
                    });
                  }}
                  getOptionLabel={(option) => option.jobName}
                  renderOption={(props, jobClassification) => (
                    <Box component="li" {...props}>
                      {jobClassification.jobName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Job Title"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "jobClassification", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <TextField
                  fullWidth
                  size="small"
                  rows={4}
                  name="levelName"
                  variant="outlined"
                  label="Level"
                  placeholder="Level"
                  disabled
                  value={jobClassification?.levelName || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {}}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <TextField
                  fullWidth
                  size="small"
                  rows={4}
                  name="jobCategoryName"
                  variant="outlined"
                  placeholder="Job Category"
                  label="Job Category"
                  disabled
                  value={jobClassification?.jobCategoryName || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {}}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <TextField
                  fullWidth
                  size="small"
                  rows={4}
                  name="entityClassName"
                  variant="outlined"
                  disabled
                  placeholder="Entity Class"
                  label="Entity Class"
                  value={jobClassification?.entityClassName || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {}}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div
            className="elevated rounded p-3 mt-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-12 text-left">
                <h6 className="mb-2">Salary Structure Details</h6>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <TextField
                  fullWidth
                  size="small"
                  rows={4}
                  name="name"
                  autoFocus
                  label="Title"
                  variant="outlined"
                  placeholder="Title"
                  value={formData.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const name = e.target.value;

                    const nameHasError = false;
                    setErrors({ ...errors, nameHasError });
                    setFormData({ ...formData, name });
                  }}
                />

                {errors.nameHasError && (
                  <div className="text-danger mb-2">Title is required </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-6 mt-3">
                <TextField
                  fullWidth
                  size="small"
                  rows={4}
                  name="numberOfPosts"
                  autoFocus
                  label="Number of posts"
                  variant="outlined"
                  placeholder="Number of posts"
                  value={formData.numberOfPosts}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const numberOfPosts = e.target.value;

                    const numberOfPostsHasError = false;
                    setErrors({ ...errors, numberOfPostsHasError });
                    setFormData({ ...formData, numberOfPosts });
                  }}
                />

                {errors.numberOfPostsHasError && (
                  <div className="text-danger mb-2">
                    Number of posts is required{" "}
                  </div>
                )}
              </div>

              <div className="col-6 mt-3">
                <TextField
                  fullWidth
                  size="small"
                  rows={4}
                  name="indexValue"
                  autoFocus
                  label="Index value"
                  variant="outlined"
                  placeholder="Index value"
                  value={formData.indexValue}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const indexValue = e.target.value;

                    const indexValueHasError = false;
                    setErrors({ ...errors, indexValueHasError });
                    setFormData({ ...formData, indexValue });
                  }}
                />

                {errors.indexValueHasError && (
                  <div className="text-danger mb-2">
                    Index value is required{" "}
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-6 mt-2">
                <Autocomplete
                  size="small"
                  id="employee-group"
                  defaultValue={null}
                  value={employeeGroup}
                  options={employeeGroups}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, employeeGroup) => {
                    setEmployeeGroup(employeeGroup || null);

                    const employeeGroupId = employeeGroup
                      ? employeeGroup.id
                      : "";
                    setFormData({ ...formData, employeeGroupId });

                    const employeeGroupHasError = false;
                    setErrors({ ...errors, employeeGroupHasError });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, employeeGroup) => (
                    <Box component="li" {...props}>
                      {employeeGroup.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employment group"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.employeeGroupHasError && (
                  <div className="text-danger mt-2">
                    Employment group is required{" "}
                  </div>
                )}
              </div>

              <div className="col-6 mt-2">
                <Autocomplete
                  size="small"
                  id="hiring-mode"
                  defaultValue={null}
                  value={hiringMode}
                  options={hiringModes}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, hiringMode) => {
                    setHiringMode(hiringMode || null);
                    const hiringModeId = hiringMode ? hiringMode.id : "";
                    setFormData({ ...formData, hiringModeId });
                    const hiringModeHasError = false;
                    setErrors({ ...errors, hiringModeHasError });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, hiringMode) => (
                    <Box component="li" {...props}>
                      {hiringMode.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hiring mode"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.hiringModeHasError && (
                  <div className="text-danger mt-2">
                    Hiring mode is required{" "}
                  </div>
                )}
              </div>
              <div className="col-6 mt-2">
                <div className="row">
                  <div className="col-10">
                    <Autocomplete
                      size="small"
                      style={{ width: "100%" }}
                      id="job-field"
                      defaultValue={null}
                      value={jobField}
                      options={jobFields}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, jobField) => {
                        setJobField(jobField || null);
                        const jobFieldId = jobField ? jobField.id : "";
                        setFormData({ ...formData, jobFieldId });
                        const jobFieldHasError = false;
                        setErrors({ ...errors, jobFieldHasError });
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, jobField) => (
                        <Box component="li" {...props}>
                          {jobField.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Career  field"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "jobField", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    {errors.jobFieldHasError && (
                      <div className="text-danger mt-2">
                        Career field is required{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-2">
                    <IconButton
                      onClick={() => {
                        setAddJobFieldForm(true);
                      }}
                      color="primary"
                      size="small"
                    >
                      <span className="material-icons">add</span>
                    </IconButton>
                  </div>
                </div>
              </div>

              <div className="col-6 mt-2">
                <Autocomplete
                  size="small"
                  id="echelon"
                  defaultValue={null}
                  value={echelon}
                  options={echelons}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, echelon) => {
                    setEchelon(echelon || null);
                    const scaleId = echelon ? echelon.id : "";
                    setFormData({ ...formData, scaleId });
                    const echelonHasError = false;
                    setErrors({ ...errors, echelonHasError });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, echelon) => (
                    <Box component="li" {...props}>
                      {echelon.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Scale"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "echelon", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {errors.echelonHasError && (
                  <div className="text-danger mt-2">Scale is required </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-12">
          <div
            className="elevated rounded p-3 mt-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="d-flex justify-content-center py-0 p-2">
              <button
                onClick={onSave}
                type="button"
                className="btn btn-primary text-uppercase  px-4"
                disabled={!jobClassification || loading}
              >
                {jobClassification && loading ? "Wait..." : "Save"}
              </button>
              &nbsp; &nbsp;
              <button
                onClick={() => onClose()}
                type="button"
                className="btn btn-default text-uppercase"
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  loading,
  employeeGroups,
  hiringModes,
  echelons,
  levels,
  user,
  vJobClassifications,
  jobFields,
}) => {
  return {
    loading,
    user,
    employeeGroups,
    hiringModes,
    echelons,
    levels,
    vJobClassifications,
    jobFields,
  };
};
export default connect(mapStateToProps, {
  getEmployeeGroups,
  getHiringModes,
  saveSalaryStructure,
  getLevels,
  getEchelons,
  getVJobClassifications,
  getJobFields,
})(AddSalaryStructureDialog);
