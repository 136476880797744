import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  getEmployeeAnnualSalaries,
  addEmployeeToBonus,
} from "../../../store/payroll/actions";
import { useState } from "react";
import { currencyFormat } from "../../common/components/Utils";
import { showError } from "../../toastify";

const AddToBonusDialog = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    employee,
    employeeAnnualSalaries,
    addEmployeeToBonus,
    selectedFiscalYear,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  useEffect(() => {
    setAddExternal(false);
  }, [showDialog]);

  const [allPeriods, setAllPeriods] = useState([...employeeAnnualSalaries]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [addExternal, setAddExternal] = useState(true);

  const addAll = () => {
    setSelectedItems([...employeeAnnualSalaries]);
  };

  const getTotalAdded = () =>
    selectedItems.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue.basePay;
    }, 0);

  const generatePeriodArray = (startingPeriod, endingPeriod, ignore) => {
    const startDate = new Date(startingPeriod + "-01");
    const endDate = new Date(endingPeriod + "-01");
    const ignoreSet = new Set(ignore.map((item) => item.periodId));
    const result = [];

    // Loop through months from startingPeriod to endingPeriod
    while (startDate <= endDate) {
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, "0"); // Get month in 'MM' format
      const periodId = `${year}-${month}`;

      // Add to result if period is not in the ignore set
      if (!ignoreSet.has(periodId)) {
        result.push({
          periodId,
          payrollTypeId: null,
          payrollTypeName: null,
          basePay: null,
          isExternal: true,
        });
      }

      // Move to the next month
      startDate.setMonth(startDate.getMonth() + 1);
    }

    return result;
  };

  useEffect(() => {
    if (addExternal) {
      const startMonth = [5, 14].includes(user.selectedEntitySector.sectorId)
        ? "09"
        : "07";
      const closeMonth = [5, 14].includes(user.selectedEntitySector.sectorId)
        ? "08"
        : "06";
      const temp = generatePeriodArray(
        `${selectedFiscalYear.name.split("/")[0]}-${startMonth}`,
        `${selectedFiscalYear.name.split("/")[1]}-${closeMonth}`,
        [...selectedItems, ...employeeAnnualSalaries]
      );

      setAllPeriods([...employeeAnnualSalaries, ...temp]);
    } else {
      setSelectedItems([
        ...selectedItems.filter(({ isExternal }) => !isExternal),
      ]);
      setAllPeriods([...employeeAnnualSalaries]);
    }
  }, [addExternal]);

  const onSave = () => {
    const payload = [];

    selectedItems.forEach(({ periodId, basePay, payrollTypeId }) =>
      payload.push({
        employeeId: employee.id,
        entityId: user.selectedEntitySector.entityId,
        fiscalYearId: selectedFiscalYear.id,
        periodId,
        payrollTypeId,
        basePay,
        createdBy: user.id,
      })
    );

    addEmployeeToBonus(
      payload,
      employee.oldEmployeeId,
      employee.ignoreSixMonths,
      setShowDialog
    );
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="sm"
        open={showDialog}
      >
        <DialogTitle className="pb-0 mb-0 text-uppercase">
          <Typography className="text-dark">
            Annual Salaries |{" "}
            <span className="text-primary">{employee.names}</span>
            <Button
              disabled={loading}
              onClick={() => setAddExternal(!addExternal)}
              variant="contained"
              size="small"
              disableElevation
              color={addExternal ? "warning" : "primary"}
              className="ml-2"
              style={{
                borderRadius: "3px",
                height: "20px",
                // width: "80px",
                alignItems: "center",
                fontSize: ".75rem",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textTransform: "initial",
              }}
            >
              {!addExternal ? "Add Other Payrolls" : "Cancel Other Payrolls"}
            </Button>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            className="mt-2"
            style={{
              minHeight: "40vh",
            }}
          >
            <div className="row mx-0">
              <table className=" table-striped table-hover table-sm ">
                <thead>
                  <tr>
                    <th className="text-right">#</th>
                    <th className="text-right">Month</th>
                    <th>Payroll Type</th>
                    <th className="text-right">Basic Salary</th>
                    <th className="text-right">Status</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>

                <tbody>
                  {allPeriods.map((item, index) => (
                    <BonusCard
                      key={index}
                      item={item}
                      index={index}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                    />
                  ))}

                  <tr>
                    <td
                      style={{
                        backgroundColor: "#e9ecef",
                      }}
                    ></td>
                    <td
                      className="text-primary text-right text-uppercase font-weight-bold"
                      style={{
                        fontSize: "12px",
                        width: "100px",
                        backgroundColor: "#e9ecef",
                      }}
                    >
                      Annual Basic Salary:
                    </td>
                    <td colSpan={3} className="text-right">
                      <span className="text-primary">
                        {" "}
                        {selectedItems.length} Month
                        {selectedItems.length > 1 ? "s" : ""} added
                      </span>

                      <span
                        className="px-2 py-1 d-block text-right"
                        style={{
                          fontSize: "16px",
                          backgroundColor: "#9ce5ad",
                          borderRadius: "5px",
                        }}
                      >
                        {currencyFormat(getTotalAdded())} FRW
                      </span>
                    </td>
                    <td
                      className="text-right pt-3"
                      style={{
                        padding: "2px",
                        width: "96px",
                      }}
                    >
                      {employeeAnnualSalaries.length > selectedItems.length &&
                        (!addExternal ||
                          allPeriods.length ===
                            employeeAnnualSalaries.length) && (
                          <Button
                            onClick={() => {
                              addAll();
                            }}
                            variant="contained"
                            size="small"
                            disableElevation
                            color="success"
                            style={{
                              borderRadius: "3px",
                              alignItems: "center",
                              fontSize: ".75rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textTransform: "initial",
                            }}
                          >
                            <span className="material-icons mr-1">
                              add_circle
                            </span>
                            Add All
                          </Button>
                        )}
                      {allPeriods.length === selectedItems.length && (
                        <Button
                          onClick={() => {
                            setSelectedItems([]);
                          }}
                          variant="contained"
                          size="small"
                          disableElevation
                          color="error"
                          style={{
                            borderRadius: "3px",
                            alignItems: "center",
                            fontSize: ".75rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textTransform: "initial",
                          }}
                        >
                          <span className="material-icons mr-1">
                            remove_circle
                          </span>
                          Remove All
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <Button
            disabled={loading || !selectedItems.length}
            color="primary"
            variant="contained"
            onClick={onSave}
            className="ml-2 px-5"
          >
            {loading ? "Wait..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  employeeAnnualSalaries,
  selectedFiscalYear,
}) => {
  return { user, loading, employeeAnnualSalaries, selectedFiscalYear };
};
export default connect(mapStateToProps, {
  getEmployeeAnnualSalaries,
  addEmployeeToBonus,
})(AddToBonusDialog);

const BonusCard = (props) => {
  const { item, index, selectedItems, setSelectedItems } = props;
  const isSelected = selectedItems.find(
    ({ periodId, payrollDetailId }) =>
      periodId === item.periodId && payrollDetailId === item.payrollDetailId
  );
  const [formValue, setFormValue] = useState({
    payrollTypeId: "",
    payrollTypeName: "",
    basePay: 0,
  });

  const handleAdd = () => {
    if (
      item.isExternal &&
      (formValue.basePay <= 0 || !formValue.payrollTypeId)
    ) {
      showError("Please add Payroll type and Basic Salary");
      return;
    }

    const temp = [...selectedItems];
    if (!item.isExternal) {
      temp.push(item);
    } else {
      temp.push({
        ...item,
        payrollTypeId: +formValue.payrollTypeId,
        payrollTypeName: +formValue.payrollTypeId === 1 ? "Salary" : "Area",
        basePay: formValue.basePay,
      });
    }
    setSelectedItems(temp);
  };

  return (
    <tr>
      <th
        className="text-right"
        style={{
          backgroundColor: "#e9ecef",
        }}
      >
        <span>{index + 1}</span>
      </th>
      <th
        className="text-right"
        style={{
          backgroundColor: "#e9ecef",
        }}
      >
        <span>{item.periodId}</span>
      </th>
      <td>
        {!item.isExternal && !isSelected && <span>{item.payrollTypeName}</span>}
        {!!isSelected && <span>{isSelected.payrollTypeName}</span>}
        {item.isExternal && !isSelected && (
          <select
            value={formValue.payrollTypeId}
            onChange={(e) =>
              setFormValue({ ...formValue, payrollTypeId: e.target.value })
            }
          >
            <option value="" disabled>
              Select
            </option>
            <option value="1">Salary</option>
            <option value="2">Area</option>
          </select>
        )}
      </td>
      <td className="text-right">
        {!item.isExternal && !isSelected && (
          <span>{currencyFormat(item.basePay)}</span>
        )}
        {!!isSelected && <span>{currencyFormat(isSelected.basePay)}</span>}
        {item.isExternal && !isSelected && (
          <input
            type="number"
            placeholder="Basic Salary"
            value={+formValue.basePay}
            onChange={(e) => {
              setFormValue({
                ...formValue,
                basePay: +e.target.value >= 0 ? +e.target.value : 0,
              });
            }}
          />
        )}
      </td>

      <td className="text-right">
        {isSelected && (
          <span className="text-success font-weight-bold">
            <i className="fas fa-check-double"></i> Added
          </span>
        )}
        {!isSelected && <span>Not added</span>}
      </td>

      <td
        className="text-right"
        style={{
          padding: "2px",
        }}
      >
        {!isSelected && (
          <Button
            disabled={
              item.isExternal &&
              (formValue.basePay <= 0 || !formValue.payrollTypeId)
            }
            onClick={handleAdd}
            variant="outlined"
            size="small"
            disableElevation
            color="primary"
            style={{
              borderRadius: "3px",
              height: "20px",
              // width: "96px",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
            }}
          >
            <span className="material-icons mr-1">add</span>
            Add
          </Button>
        )}

        {isSelected && (
          <Button
            onClick={() => {
              const temp = [...selectedItems];
              temp.splice(
                selectedItems.findIndex(
                  ({ periodId }) => periodId === item.periodId
                ),
                1
              );
              setSelectedItems(temp);
            }}
            variant="text"
            size="small"
            disableElevation
            color="error"
            style={{
              borderRadius: "3px",
              height: "20px",
              // width: "96px",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
            }}
          >
            <span className="material-icons mr-1" style={{ fontSize: "18px" }}>
              delete_forever
            </span>
            Remove
          </Button>
        )}

        {/* <button
            type="button"
            className="btn d-inline-flex btn-outline-primary btn-sm py-0"
          >
            <span className="material-icons mr-1">add</span> Add
          </button> */}
      </td>
    </tr>
  );
};
