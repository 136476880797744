import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Skeleton,
  InputAdornment,
  Badge,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  saveUnitCreditor,
  getUnitWithholds,
  deleteUnitWithHolds,
} from "../../../store/structure/actions";

import { useTheme } from "@mui/material/styles";
import { showError } from "../../../app/toastify";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import NumberFormat from "react-number-format";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const UnitDeductions = (props) => {
  const {
    loading,
    onWait,
    selectedUnit,
    saveUnitCreditor,
    getUnitWithholds,
    deleteUnitWithHolds,
    unitWithholds,
    creditors,
    showUnitDeductionDialog,
    setShowUnitDeductionDialog,
    calculationMethods,
    basePayrollComponents,
  } = props;

  const [creditor, setCreditor] = useState(null);
  const [calculationMethod, setCalculationMethod] = useState(null);
  const [basePayrollComponent, setBasePayrollComponent] = useState(null);
  const [selectedUnitCreditor, setSelectedUnitCreditor] = useState(null);
  const [confirmRemoveUnitCreditor, setConfirmRemoveUnitCreditor] =
    useState(false);
  const theme = useTheme();

  const [formData, setFormData] = useState({
    unitId: selectedUnit.unitId,
    creditorId: "",
    amount: 0,
    rate: 0,
    startDate: new Date(),
    id: 0,
  });

  const [add, setAdd] = useState(false);
  const [isRate, setIsRate] = useState(false);

  const [errors, setErrors] = useState({
    creditorHasError: false,
    amountHasError: false,
    startDateHasError: false,
    rateAmountHasError: false,
  });

  useEffect(() => {
    getUnitWithholds(selectedUnit.unitId);

    setAdd(add);
  }, [selectedUnit]);

  useEffect(() => {
    const add = unitWithholds.length > 0 ? false : true;

    setAdd(add);
  }, [unitWithholds]);

  const formValidator = () => {
    const error = {
      creditorHasError: false,
      hasError: false,
      amountHasError: false,
      startDateHasError: false,
      totalAmountHasError: false,
    };

    if (!formData.creditorId) {
      error.creditorHasError = true;
      error.hasError = true;
    }
    if (!formData.startDate) {
      error.startDateHasError = true;
      error.hasError = true;
    }

    if (!!calculationMethod && calculationMethod.id === 1 && !formData.amount) {
      error.amountHasError = true;
      error.hasError = true;
    }

    if (!!calculationMethod && calculationMethod.id === 2 && !formData.rate) {
      error.rateHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    // console.log(formData);
    if (!formValidator()) {
      let canEditCreditor = false;
      if (parseInt(formData.id) > 0 && add) {
        canEditCreditor = true;
      }

      saveUnitCreditor(
        formData,
        setFormData,
        setAdd,
        setCreditor,
        canEditCreditor
      );
    }
  };

  const onClose = () => {
    setShowUnitDeductionDialog(false);
  };

  useEffect(() => {
    if (!!creditor) {
      setCalculationMethod(
        calculationMethods.find(({ id }) => id === creditor.calculationMethodId)
      );
      setBasePayrollComponent(
        basePayrollComponents.find(
          ({ id }) => id === creditor.basePayrollComponentId
        )
      );
      setFormData({
        ...formData,
        rate: creditor.rate,
        startDate: formData.startDate || new Date(),
      });
    } else {
      setCalculationMethod(null);
      setBasePayrollComponent(null);
      setIsRate(false);
      setFormData({
        ...formData,
        rate: 0,
        amount: 0,
        startDate: new Date(),
      });
    }
  }, [creditor]);

  useEffect(() => {
    if (!!calculationMethod) {
      setIsRate(calculationMethod.id === 1 ? false : true);
    }
  }, [calculationMethod]);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showUnitDeductionDialog}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle className="text-dark">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          <strong className="text-primary">{selectedUnit.unitName}</strong>{" "}
          WithHolds
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ minHeight: "50vh" }}>
          {confirmRemoveUnitCreditor && (
            <ConfirmationDialog
              confirmationDialog={confirmRemoveUnitCreditor}
              message={`Are you sure you want to remove this creditor @${
                selectedUnitCreditor.creditor &&
                selectedUnitCreditor.creditor.name
              }?`}
              setConfirmationDialog={setConfirmRemoveUnitCreditor}
              onYes={() => {
                deleteUnitWithHolds(
                  selectedUnitCreditor,
                  setConfirmRemoveUnitCreditor,
                  setSelectedUnitCreditor
                );
              }}
            />
          )}

          {loading && !onWait && (
            <div className="container mt-5" style={{ width: "100%" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </div>
          )}

          {(!loading || onWait) && (
            <div>
              {!add && (
                <Button
                  className="mb-2"
                  onClick={() => {
                    setAdd(true);
                  }}
                  style={{
                    ...ActiveButton,
                    padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                    float: "right",
                  }}
                  size="sm"
                >
                  <span className="material-icons">add</span>
                </Button>
              )}
              {add && (
                <div
                  className="elevated rounded p-0 mt-3 p-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                  }}
                >
                  <div className="row mt-3 ">
                    {!!creditors.length && (
                      <div className="mb-3 col-12 col-lg-6">
                        <div className="">
                          <Autocomplete
                            size="small"
                            id="creditor"
                            defaultValue={null}
                            value={creditor}
                            options={creditors}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, creditor) => {
                              setErrors({ ...errors, creditorHasError: false });
                              setCreditor(creditor || null);

                              const creditorId = creditor ? creditor.id : "";
                              // const basePayrollComponentId = creditor
                              //   ? creditor.basePayrollComponentId
                              //   : "";
                              // const calculationMethodId = creditor
                              //   ? creditor.calculationMethodId
                              //   : "";

                              setFormData({
                                ...formData,
                                creditorId,
                                id: 0,
                                // basePayrollComponentId,
                                // calculationMethodId,
                              });
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, creditor) => (
                              <Box component="li" {...props}>
                                {creditor.name}{" "}
                                {creditor.calculationMethodId === 2 && (
                                  <span className="badge badge-info ml-1">
                                    {creditor.calculationMethodName}
                                  </span>
                                )}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select creditors"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "creditor", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {errors.creditorHasError && (
                            <small className="text-danger mb-3">
                              Please, select creditor
                            </small>
                          )}
                        </div>
                      </div>
                    )}

                    <div
                      className={`mb-3 col-12  ${
                        !isRate ? "col-lg-6" : "col-lg-3"
                      }`}
                    >
                      <div className="">
                        <Autocomplete
                          style={{ backgroundColor: "#eee" }}
                          size="small"
                          disabled
                          id="Method"
                          defaultValue={null}
                          value={calculationMethod}
                          options={calculationMethods}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, calculationMethod) => {
                            setCalculationMethod(calculationMethod || null);
                            const calculationMethodId = calculationMethod
                              ? calculationMethod.id
                              : "";
                            setFormData({ ...formData, calculationMethodId });
                          }}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, calculationMethod) => (
                            <Box component="li" {...props}>
                              {calculationMethod.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Method"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "calculationMethod", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        {/* {errors.calculationMethod && (
                          <small className="text-danger mb-3">
                            CalculationMethod is required{" "}
                          </small>
                        )} */}
                      </div>
                    </div>

                    {/* <div className="col-12 col-lg-3">
                      <div className="">
                        <TextField
                          fullWidth
                          size="small"
                          rows={4}
                          name="totalAmount"
                          autoFocus
                          label="Total Deduction Amount"
                          variant="outlined"
                          placeholder="Total Deduction Amount"
                          className="mb-3"
                          value={formData.totalAmount}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            const totalAmount = e.target.value;
                            setFormData({ ...formData, totalAmount });
                          }}
                        />
                        {errors.totalAmountHasError && (
                          <div className="text-danger mb-2">
                            Total Deduction Amount is required{" "}
                          </div>
                        )}
                      </div>
                    </div> */}

                    {isRate && (
                      <div className="mb-3 col-12 col-lg-3">
                        <div className="">
                          <Autocomplete
                            style={{ backgroundColor: "#eee" }}
                            size="small"
                            disabled
                            id="baseComponent"
                            defaultValue={null}
                            value={basePayrollComponent}
                            options={basePayrollComponents}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            onChange={(event, basePayrollComponent) => {
                              setBasePayrollComponent(
                                basePayrollComponent || null
                              );
                              const basePayrollComponentId =
                                basePayrollComponent
                                  ? basePayrollComponent.id
                                  : "";
                              setFormData({
                                ...formData,
                                basePayrollComponentId,
                              });
                            }}
                            getOptionLabel={(option) => option.name}
                            renderOption={(props, basePayrollComponent) => (
                              <Box component="li" {...props}>
                                {basePayrollComponent.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Base Component"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "baseComponent", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          {/* {errors.basePayrollComponent && (
                          <small className="text-danger mb-3">
                            Base component is required
                          </small>
                        )} */}
                        </div>
                      </div>
                    )}

                    <div className="mb-3 col-12 col-lg-6">
                      <TextField
                        style={{ backgroundColor: "#eee" }}
                        disabled
                        size="small"
                        fullWidth
                        name="acountNumber"
                        type="text"
                        label="Acount Number"
                        variant="outlined"
                        placeholder="Acount Number"
                        value={!!creditor ? creditor.acountNumber : ""}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    <div className="mb-3 col-12 col-lg-6">
                      <TextField
                        style={{ backgroundColor: "#eee" }}
                        disabled
                        size="small"
                        fullWidth
                        name="bank"
                        type="text"
                        label="Bank"
                        variant="outlined"
                        placeholder="Bank"
                        value={!!creditor ? creditor.bankName : ""}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                      />
                    </div>

                    <div className="mb-3 col-12 col-lg-6">
                      <div className="">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label="Effective Date"
                            inputFormat="MM/dd/yyyy"
                            value={formData.startDate || new Date()}
                            onChange={(date) => {
                              setErrors({
                                ...errors,
                                startDateHasError: false,
                              });
                              const startDate = date;
                              setFormData({ ...formData, startDate });

                              const startDateHasError = false;
                              setErrors({ ...errors, startDateHasError });
                            }}
                            renderInput={(params) => (
                              <TextField size="small" fullWidth {...params} />
                            )}
                          />

                          {errors.startDateHasError && (
                            <small className="text-danger mt-1">
                              Effective Date is required{" "}
                            </small>
                          )}
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div className="mb-3 col-12 col-lg-6">
                      {!isRate && (
                        <div className="">
                          <NumberFormat
                            fullWidth
                            size="small"
                            label="Amount"
                            name="amount"
                            variant="outlined"
                            placeholder="RWF 0"
                            prefix={"RWF "}
                            customInput={TextField}
                            value={formData.amount}
                            thousandSeparator={true}
                            onBlur={() => {
                              if (formData.amount === "")
                                setFormData({
                                  ...formData,
                                  amount: 0,
                                });
                            }}
                            onKeyDown={() => {
                              if (+formData.amount === 0)
                                setFormData({
                                  ...formData,
                                  amount: "",
                                });
                            }}
                            onChange={(e) => {
                              setErrors({ ...errors, amountHasError: false });
                              const amount = e.target.value.split("RWF ");

                              setFormData({
                                ...formData,
                                amount: !!amount[1]
                                  ? amount[1].replace(/,/g, "")
                                  : 0,
                              });
                            }}
                          />
                        </div>
                      )}

                      {isRate && (
                        <div className="">
                          <TextField
                            style={{ backgroundColor: "#eee" }}
                            size="small"
                            disabled
                            fullWidth
                            name="rate"
                            label="Rate"
                            variant="outlined"
                            placeholder="Rate"
                            value={formData.rate}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                              ),
                            }}
                            onChange={(e) => {
                              setErrors({ ...errors, rateHasError: false });
                              const rate = e.target.value;
                              setFormData({ ...formData, rate });
                            }}
                          />
                          {errors.rateHasError && (
                            <small className="text-danger mb-2">
                              Rate is required{" "}
                            </small>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <Divider className="my-1" />
                  <div className="d-flex justify-content-center py-0 p-2">
                    <button
                      onClick={onSave}
                      type="button"
                      className="btn btn-primary text-uppercase"
                      disabled={loading || onWait}
                    >
                      {onWait ? "Wait..." : "Save"}
                    </button>{" "}
                    {!!unitWithholds.length && (
                      <React.Fragment>
                        &nbsp; &nbsp;
                        <button
                          onClick={() => {
                            setAdd(false);
                            setFormData({
                              unitId: selectedUnit.unitId,
                              creditorId: "",
                              amount: 0,
                              totalAmount: 0,
                              startDate: null,
                              id: 0,
                            });
                          }}
                          type="button"
                          className="btn  btn-default text-uppercase"
                          disabled={loading}
                        >
                          Cancel
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}

              {!!unitWithholds.length && (
                <React.Fragment>
                  <table className="table table-bordered table-sm mt-3">
                    <thead>
                      <tr>
                        <th colSpan={8} style={{ textAlign: "center" }}>
                          ACTIVE WITHHOLDS
                        </th>
                      </tr>
                      <tr>
                        <td style={{ backgroundColor: "#f6f6f6" }}>#</td>
                        <td style={{ backgroundColor: "#f6f6f6" }}>Name</td>
                        <td style={{ backgroundColor: "#f6f6f6" }}>Amount</td>
                        <td style={{ backgroundColor: "#f6f6f6" }}>
                          Base Component
                        </td>
                        <td style={{ backgroundColor: "#f6f6f6" }}>
                          Effective Date
                        </td>
                        {/* <td style={{ backgroundColor: "#f6f6f6" }}>End Date</td> */}
                        <td style={{ backgroundColor: "#f6f6f6" }}></td>
                      </tr>
                    </thead>
                    <tbody>
                      {unitWithholds.map((unitWithhold, uaIndex) => (
                        <UnitDeduction
                          key={uaIndex}
                          unitWithhold={unitWithhold}
                          setCreditor={setCreditor}
                          setFormData={setFormData}
                          setConfirmRemoveUnitCreditor={
                            setConfirmRemoveUnitCreditor
                          }
                          setAdd={setAdd}
                          setSelectedUnitCreditor={setSelectedUnitCreditor}
                          uaIndex={uaIndex}
                        />
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </DialogContent>

      <DialogActions className="py-4"></DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, onWait, unitWithholds }) => {
  return {
    loading,
    onWait,
    unitWithholds,
  };
};
export default connect(mapStateToProps, {
  saveUnitCreditor,
  getUnitWithholds,
  deleteUnitWithHolds,
})(UnitDeductions);

const UnitDeduction = (props) => {
  const {
    unitWithhold,
    // setCreditor,
    // setFormData,
    setConfirmRemoveUnitCreditor,
    // setAdd,
    setSelectedUnitCreditor,
    uaIndex,
  } = props;

  return (
    <tr
      style={{
        background: uaIndex % 2 === 0 ? "" : "#f6f7f7",
        borderBottom: "2px dotted rgb(7, 142, 206)!important",
      }}
    >
      <td>{uaIndex + 1}</td>
      <td> {unitWithhold.creditorName} </td>
      <td>
        <span
          className={`badge mr-1 badge-${
            unitWithhold.calculationMethodId === 1
              ? "light text-info"
              : "secondary"
          }`}
        >
          {" "}
          {unitWithhold.calculationMethodId === 1 ? "RWF" : "RATE"}
        </span>
        {unitWithhold.calculationMethodId === 1 ? (
          <CurrencyFormat
            value={unitWithhold.amount}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          unitWithhold.rate
        )}
      </td>

      <td>{unitWithhold.basePayrollComponentName} </td>

      <td>
        {unitWithhold.startDate
          ? moment(unitWithhold.startDate).format("yyyy-MM-DD")
          : ""}{" "}
      </td>
      {/* <td>
        {unitWithhold.endDate
          ? moment(unitWithhold.endDate).format("yyyy-MM-DD")
          : ""}
      </td> */}

      <td>
        <IconButton
          size="small"
          color="error"
          onClick={() => {
            setSelectedUnitCreditor(unitWithhold);
            setConfirmRemoveUnitCreditor(true);
          }}
        >
          <span className="material-icons">delete</span>
        </IconButton>
        {/* {listMenu(unitWithhold)} */}
      </td>
    </tr>
  );
};
