import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  getArchivedPayrolls,
  getSupportingDocumentTypes,
} from "../../../store/payroll/actions";
import { Typography, Box, Button } from "@mui/material";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Skeleton, CardContent, Toolbar, List } from "@mui/material";
import loadingGif from "../../assets/loading.gif";
import PayrollCard from "../components/PayrollCard";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";

const ArchivedPayrolls = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Payrolls | Archived Payrolls";
  });

  const {
    user,
    loading,
    archivedPayrolls,
    getArchivedPayrolls,
    supportingDocumentTypes,
    getSupportingDocumentTypes,
    totalArchivedPayrolls,
  } = props;
  const theme = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );

  const [archivedPayrollLoader, setArchivedPayrollLoader] = useState(false);
  const [archivedPayrollOnNextPage, setArchivedPayrollOnNextPage] =
    useState(false);

  useEffect(() => {
    if (!supportingDocumentTypes.length) getSupportingDocumentTypes();
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };
    // if (!archivedPayrolls.length)
    getArchivedPayrolls(query, setArchivedPayrollLoader);
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const currentPage = 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };
    getArchivedPayrolls(query, setArchivedPayrollLoader);
  };

  const getNo = (idToSearch, data) => {
    return data.findIndex(({ id }) => id === idToSearch) + 1;
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };
    getArchivedPayrolls(query, setArchivedPayrollOnNextPage);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>ARCHIVED PAYROLLS</span>
              </Typography>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox
                  disabled={!archivedPayrolls.length}
                  onSearch={(search) => {
                    setSearchTerm(search);
                    if (!search) {
                      const currentPage = 1;
                      setCurrentPage(currentPage);
                      const query = {
                        searchTerm: "",
                        page: currentPage,
                        size: itemPerPage,
                      };
                      getArchivedPayrolls(query, setArchivedPayrollOnNextPage);
                    }
                  }}
                  placeholder="Search…"
                />
                <Button
                  disabled={!searchTerm}
                  onClick={() => onSearch()}
                  variant="contained"
                  size="small"
                  disableElevation
                  className="mr-2 "
                  style={{
                    borderRadius: "8px",
                    height: "38px",
                    border: `1px solid rgb(7, 142, 206)`,
                    color: "#fff",
                    alignItems: "center",
                    fontSize: ".75rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "initial",
                    backgroundColor: "rgb(7, 142, 206)",
                    marginLeft: "5px",
                    "&:hover": {
                      backgroundColor: "#f0f2f5",
                    },
                  }}
                >
                  <span className="material-icons">search</span>Go!
                </Button>
              </Box>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              // margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              // boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              // MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              className="py-0"
              style={{
                height: "auto",
                maxHeight: "63vh",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <table className="table table-striped table-sm fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th>#</th>
                    <th style={{ width: "174px" }}>Payroll Number</th>
                    <th
                      className="px-1 text-center"
                      colSpan={2}
                      style={{
                        backgroundColor: "#e5f6fd",
                        borderBottom: "none",
                        borderTop: "none",
                        borderLeft: "1px solid #dee2e6",
                        borderRight: "1px solid #dee2e6",
                      }}
                    >
                      RSSB DECLARATIONS
                    </th>
                    <th className="px-1 text-center text-uppercase">
                      Tin Number
                    </th>
                    <th className="px-1">Period</th>

                    <th className="px-1">Payroll Type</th>

                    <th className="px-1">Cost Center</th>
                    <th className="px-1">Payroll Group</th>
                    <th className="px-1">Source Of Funds</th>
                    <th className="px-1 text-right">Total Salary</th>
                    <th className="px-1 text-right">Total Lumpsum</th>
                    <th className="px-1 text-right">Total Paid</th>
                    <th className="px-1">OP Number</th>
                    <th className="px-1">Last Processing Time</th>
                    <th className="px-1 text-center">Employees</th>

                    <th className="pl-1 text-center">Status</th>

                    <th className="px-1">Actions</th>
                  </tr>
                </thead>
                {archivedPayrollLoader ? (
                  <TableSkeleton cols={18} rows={15} />
                ) : (
                  <tbody>
                    <BackdropLoader isLoading={archivedPayrollOnNextPage} />
                    {archivedPayrolls.map((payroll, index) => (
                      <PayrollCard
                        key={index}
                        payrollIndex={
                          archivedPayrolls.length -
                          getNo(payroll.id, archivedPayrolls) +
                          1
                        }
                        payroll={payroll}
                        type="Archived"
                        query={{
                          searchTerm: searchTerm,
                          page: currentPage,
                          size: itemPerPage,
                        }}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <div
                className="d-flex justify-content-center mb-3 align-items-center"
                style={{ overflow: "hidden" }}
              >
                {!archivedPayrollLoader && (
                  <>
                    <label> Item per page:</label>

                    <select
                      className="custom-select custom-select-sm mr-3"
                      style={{ width: "100px" }}
                      value={itemPerPage}
                      onChange={(event) => {
                        const itemPerPage = +event.target.value;
                        window.localStorage.setItem(
                          "ITEM_PER_PAGE",
                          itemPerPage
                        );
                        setItemPerPage(itemPerPage);
                        const query = {
                          searchTerm: searchTerm,
                          page: currentPage,
                          size: itemPerPage,
                        };
                        getArchivedPayrolls(
                          query,
                          setArchivedPayrollOnNextPage
                        );
                      }}
                    >
                      {[10, 50, 100].map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>
                    <div className="mt-3">
                      <ReactPaginate
                        initialPage={currentPage - 1}
                        breakLabel="..."
                        nextLabel={
                          <>
                            <span className="pr-1 d-none d-md-inline">
                              Next
                            </span>
                            <i className="fas fa-angle-double-right"></i>
                          </>
                        }
                        previousLabel={
                          <>
                            <i className="fas fa-angle-double-left"></i>
                            <span className="pl-1  d-none d-md-inline">
                              Previous
                            </span>
                          </>
                        }
                        pageRangeDisplayed={3}
                        // marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        pageCount={Math.ceil(
                          totalArchivedPayrolls / itemPerPage
                        )}
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                        previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                        nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                        activeLinkClassName="active"
                      />
                    </div>
                  </>
                )}
              </div>

              {!archivedPayrolls.length && !archivedPayrollLoader && (
                <div className="text-center">
                  <p className="lead">No archived payroll found</p>
                </div>
              )}
            </CardContent>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  archivedPayrolls,
  supportingDocumentTypes,
  totalArchivedPayrolls,
}) => {
  return {
    user,
    loading,
    archivedPayrolls,
    supportingDocumentTypes,
    totalArchivedPayrolls,
  };
};
export default connect(mapStateToProps, {
  getArchivedPayrolls,
  getSupportingDocumentTypes,
})(ArchivedPayrolls);
