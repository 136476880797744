import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

import React, { useState } from "react";
import { connect } from "react-redux";
import {
  updateWrittenExam,
  updateOralExam,
} from "../../../store/recruitment/actions";
import { showError } from "../../../app/toastify";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ErrorMessage from "../../common/components/ErrorMessage";
import moment from "moment";
import { Editor } from "../../common/components/Utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
const UpdateScheduledExamDialog = (props) => {
  const {
    loading,
    showUpdateScheduledExamForm,
    setShowUpdateScheduledExamForm,

    updateWrittenExam,
    updateOralExam,
    advertisement,
    examType,
    exam,
    currentDate,
    isExamDateUnlocked,
    user,
  } = props;

  const [formData, setFormData] = useState({
    advertisementId: advertisement ? advertisement.id : 0,
    durationMin: exam ? exam.durationMin : 0,
    totalMarks: exam ? exam.totalMarks : 0,
    seatingDate: exam ? exam.seatingDate : null,
    startTime: exam ? exam.startTime : null,
    hasPDF: exam && exam.hasPDF ? exam.hasPDF : false,
    endTime: exam ? exam.endTime : "",
    examId: exam ? exam.id : 0,
    testForWrittenSkills: exam.testForWrittenSkills
      ? exam.testForWrittenSkills
      : "",
    attachment: exam.attachment ? exam.attachment : "",
    attachmentCopied: exam.attachment ? exam.attachment : "",
    testForWrittenSkillsCopied: exam.testForWrittenSkills
      ? exam.testForWrittenSkills
      : "",
    venue: exam ? exam.venue : "",
    pdfAttachment: "",
    notice: exam ? exam.notice : "",
  });
  const [showUploadedDocument, setShowUploadedDocument] = useState(false);
  const [didUploadPDF, setDidUploadPDF] = useState(false);
  const [base64Data, setBase64Data] = useState(
    exam.attachment ? exam.attachment : ""
  );
  const [errors, setErrors] = useState({
    durationMinHasError: false,
    totalMarksHasError: false,
    seatingDateHasError: false,
    startTimeHasError: false,
    endTimeHasError: false,
    hasError: false,
    venueHasError: false,
    pdfAttachmentHasError: false,
    testForWrittenSkillsHasError: false,
    noticeHasError: false,
  });

  const formValidator = () => {
    const error = {
      durationMinHasError: false,
      totalMarksHasError: false,
      seatingDateHasError: false,
      startTimeHasError: false,
      endTimeHasError: false,
      pdfAttachmentHasError: false,
      testForWrittenSkillsHasError: false,
      hasError: false,
      noticeHasError: false,
    };
    if (
      !(
        (user?.selectedEntitySector?.id || null) ===
        "0108000000-14090000000000000-10"
      ) &&
      formData.testForWrittenSkillsCopied &&
      !formData.testForWrittenSkills &&
      !!advertisement.willHaveOralExam
    ) {
      error.testForWrittenSkillsHasError = true;
      error.hasError = true;
    }

    if (!formData.seatingDate) {
      error.seatingDateHasError = true;
      error.hasError = true;
    }

    if (!formData.durationMin) {
      error.durationMinHasError = true;
      error.hasError = true;
    }

    if (!formData.totalMarks) {
      error.totalMarksHasError = true;
      error.hasError = true;
    }
    if (!formData.notice) {
      error.noticeHasError = true;
      error.hasError = true;
    }

    if (!formData.startTime) {
      error.startTimeHasError = true;
      error.hasError = true;
    }

    if (examType === "Written" && !formData.endTime) {
      error.endTimeHasError = true;
      error.hasError = true;
    }

    if (!formData.venue && examType === "Oral") {
      error.venueHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      formData.advertisementId = advertisement ? advertisement.id : 0;
      if (examType === "Written") {
        if (!errors.pdfAttachmentHasError) {
          formData.seatingDate = moment(formData.seatingDate).format(
            "MM/DD/YYYY"
          );
          // formData.seatingDate = formData.seatingDate.toString();
          formData.didUploadPDF = didUploadPDF;

          formData.pdfAttachment =
            !!didUploadPDF && formData.pdfAttachment
              ? formData.pdfAttachment.split(",")[1]
              : null;

          updateWrittenExam(
            formData,
            setFormData,
            setShowUpdateScheduledExamForm
          );
        } else {
          showError("Invalid Pdf File");
        }
      }
      if (examType === "Oral") {
        updateOralExam(formData, setFormData, setShowUpdateScheduledExamForm);
      }
    }
  };

  const onClose = () => {
    setFormData({
      advertisementId: advertisement ? advertisement.id : 0,
      durationMin: 0,
      totalMarks:
        advertisement &&
        advertisement.willHaveOralExam &&
        advertisement.willHaveWrittenExam
          ? 50
          : 100,
      seatingDate: null,
      startTime: null,
      hasPDF: false,
      endTime: "",
      venue: "",
    });
    setShowUpdateScheduledExamForm(false);
  };

  const convertToLower = (value) => {
    return value.toLowerCase();
  };

  const handleUploadedPDFFile = (event) => {
    let pdfAttachment = event.target.files[0];

    setErrors({ ...errors, pdfAttachmentHasError: false });
    setBase64Data(exam.attachment ? exam.attachment : "");
    setDidUploadPDF(false);
    if (
      !(
        pdfAttachment.type === "application/pdf" &&
        convertToLower(pdfAttachment.name.substr(-4)) === ".pdf"
      )
    ) {
      setErrors({ ...errors, pdfAttachmentHasError: true });
      showError("File should be pdf format");
    } else {
      const reader = new FileReader();
      setDidUploadPDF(true);
      reader.onload = (e) => {
        // Get the Base64 data
        const base64Data = e.target.result;
        setBase64Data(base64Data);
        setFormData({
          ...formData,
          pdfAttachment: base64Data,
        });
      };

      // Read the file as Data URL
      reader.readAsDataURL(pdfAttachment);
    }
  };

  const addDays = (currentDate, days) => {
    var date = currentDate ? new Date(currentDate) : new Date(currentDate);
    date.setDate(date.getDate() + days);
    return date;
  };
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showUpdateScheduledExamForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography noWrap variant="overline" display="block">
            <span className="text-primary">
              Update Scheduled {examType} Exam
            </span>{" "}
            @ {advertisement.positionName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {showUploadedDocument && base64Data && (
            <PreviewPdfUrl
              isBase64={true}
              showDocument={showUploadedDocument}
              setShowDocument={setShowUploadedDocument}
              document={{
                name: `View Document`,
                url: base64Data || "",
              }}
            />
          )}
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              {examType === "Oral" && (
                <div className="col-lg-12 mt-2">
                  <TextField
                    disabled={exam.wasExamMarkingPublished || exam.isActive}
                    fullWidth
                    size="small"
                    rows={4}
                    name="venue"
                    autoFocus
                    label="Venue"
                    variant="outlined"
                    placeholder="Venue"
                    value={formData.venue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      const venue = e.target.value;
                      setFormData({ ...formData, venue });
                      const venueHasError = false;
                      setErrors({ ...errors, venueHasError });
                    }}
                  />

                  <ErrorMessage
                    hasError={errors.venueHasError}
                    message="Venue is required"
                  />
                </div>
              )}

              <div className="col-lg-12 mt-3">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={exam.wasExamMarkingPublished || exam.isActive}
                    label="Seating Date"
                    inputFormat="MM/dd/yyyy"
                    value={formData.seatingDate}
                    minDate={
                      !isExamDateUnlocked ? addDays(currentDate, 3) : null
                    }
                    onChange={(date) => {
                      const seatingDate = date;
                      let seatingDateHasError = false;
                      if (
                        new Date(seatingDate).getDate() <
                        new Date(addDays(currentDate, 3)).getDate()
                      ) {
                        seatingDateHasError = true;
                      }

                      setFormData({ ...formData, seatingDate });

                      setErrors({ ...errors, seatingDateHasError });
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                <ErrorMessage
                  hasError={errors.seatingDateHasError}
                  message="Seating date is required"
                />
              </div>

              <div className="col-lg-12 mt-3">
                <TextField
                  fullWidth
                  size="small"
                  label="Start Time"
                  type="time"
                  disabled={
                    !formData.seatingDate ||
                    !formData.durationMin ||
                    errors.seatingDateHasError ||
                    exam.isActive ||
                    exam.wasExamMarkingPublished
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.startTime || ""}
                  onChange={(e) => {
                    const startTime = e.target.value;
                    const seatingDate = moment(formData.seatingDate).format(
                      "YYYY-MM-DD"
                    );
                    const seatingDateTime = seatingDate + " " + startTime;
                    const endTime = moment(seatingDateTime)
                      .add(formData.durationMin, "m")
                      .format("HH:mm");

                    setFormData({
                      ...formData,
                      startTime,
                      endTime,
                    });
                    setErrors({
                      ...errors,
                      startTimeHasError: false,
                      endTimeHasError: false,
                    });
                  }}
                />

                <ErrorMessage
                  hasError={errors.startTimeHasError}
                  message="Start time is required"
                />
              </div>
              {examType === "Written" && (
                <div className="col-lg-12 mt-3">
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    label="End Time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formData.endTime}
                    onChange={(e) => {
                      setErrors({ ...errors, startTimeHasError: false });
                    }}
                  />

                  <ErrorMessage
                    hasError={errors.endTimeHasError}
                    message="End time is required"
                  />
                </div>
              )}

              <div className="col-lg-12 mt-3 ">
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  name="notice"
                  label={`Notification message`}
                  variant="outlined"
                  placeholder={`Notice`}
                  value={formData.notice}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    const notice = e.target.value;
                    setFormData({
                      ...formData,
                      notice,
                    });

                    setErrors({
                      ...errors,
                      noticeHasError: false,
                    });
                  }}
                />

                <ErrorMessage
                  hasError={errors.noticeHasError}
                  message="Notice is required"
                />
              </div>

              {!(
                (user?.selectedEntitySector?.id || null) ===
                "0108000000-14090000000000000-10"
              ) &&
                examType === "Written" &&
                !!advertisement.willHaveOralExam && (
                  <div className="col-lg-12 mt-3">
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      size="small"
                      name="testForWrittenSkills"
                      label={`Test For Written Skills`}
                      variant="outlined"
                      placeholder={`Test For Written Skills`}
                      value={formData.testForWrittenSkills}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        const testForWrittenSkills = e.target.value;
                        setFormData({
                          ...formData,
                          testForWrittenSkills,
                        });

                        setErrors({
                          ...errors,
                          testForWrittenSkillsHasError: false,
                        });
                      }}
                    />

                    <ErrorMessage
                      hasError={errors.testForWrittenSkillsHasError}
                      message="Test For Written Skills is required"
                    />
                  </div>
                )}
              {exam.hasPDF && examType === "Written" && (
                <div className="col-lg-12 mt-3 ">
                  Edit Attached Exam(* only pdf file)
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-paperclip"></i>
                      </span>
                    </div>

                    <input
                      disabled={exam.wasExamMarkingPublished}
                      type="file"
                      name="file"
                      className="form-control"
                      accept="application/pdf"
                      placeholder="Select pdf file"
                      onChange={(e) => handleUploadedPDFFile(e)}
                    />
                    {base64Data && (
                      <>
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => {
                              setShowUploadedDocument(true);
                            }}
                          >
                            View document
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                  <ErrorMessage
                    hasError={errors.pdfAttachmentHasError}
                    message={
                      <>
                        <div>
                          Please check following issues:
                          <br />
                          File should be pdf format.
                        </div>
                      </>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading || exam.wasExamMarkingPublished}
          >
            {loading ? "Updating..." : "Update"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return {
    loading,
    user,
  };
};
export default connect(mapStateToProps, {
  updateWrittenExam,
  updateOralExam,
})(UpdateScheduledExamDialog);
