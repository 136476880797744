import commonTypes from "../common/action-types";
import recruitment from "../recruitment/action-types";
import psychometric from "./action-types";

import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";
import * as arrayToTree from "array-to-tree";
import download from "downloadjs";
import { isSessionExpired } from "../common/actions";

const types = { ...commonTypes, ...recruitment, ...psychometric };

export const getPsychometricLanguages = (knowledgeLevelId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/psy/psychometric-languages/" + knowledgeLevelId
      );

      dispatch({
        type: types.SET_PSYCHOMETRIC_LANGUAGES,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getKnowledgeDomains = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/psy/knowledge-domains");

      dispatch({
        type: types.SET_KNOWLEDGE_DOMAINS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const setKnowledgeDomain = (payload, onCloseDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/psy/knowledge-domains", payload);

      dispatch({
        type: types.ADD_OR_UPDATE_KNOWLEDGE_DOMAIN,
        data: data.domain,
      });

      dispatch({
        type: types.SET_KNOWLEDGE_SUB_DOMAINS,
        data: data.subdomains,
      });

      dispatch({ type: types.END_LOADING });

      onCloseDialog();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getKnowledgeSubDomains = (knowledgeLevelId = "") => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/psy/knowledge-sub-domains/" + knowledgeLevelId
      );

      dispatch({
        type: types.SET_KNOWLEDGE_SUB_DOMAINS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const setKnowledgeSubDomain = (payload, onCloseDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post(
        "/api/psy/knowledge-sub-domains",
        payload
      );

      dispatch({
        type: types.ADD_OR_UPDATE_KNOWLEDGE_SUB_DOMAIN,
        data: data,
      });

      dispatch({ type: types.END_LOADING });

      onCloseDialog();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPositionPsychometricLanguages = (positionId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/psy/position-psychometric-languages/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_PSYCHOMETRIC_LANGUAGES,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getPositionKnowledgeSubDomains = (positionId) => {
  return async (dispatch) => {
    try {
      // dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/psy/position-knowledge-subdomains/" + positionId
      );

      dispatch({
        type: types.SET_POSITION_KNOWLEDGE_SUBDOMAINS,
        data,
      });

      // dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deletePositionPsychometricLanguage = (id, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/psy/position-psychometric-languages/" + id);

      onClose();

      dispatch({
        type: types.DELETE_POSITION_PSYCHOMETRIC_LANGUAGE,
        id,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deletePositionKnowledgeSubDomain = (id, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/psy/position-knowledge-subdomains/" + id);

      onClose();

      dispatch({
        type: types.DELETE_POSITION_KNOWLEDGE_SUBDOMAIN,
        id,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePositionPsychometricLanguage = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/psy/position-psychometric-languages",
        payload
      );

      onClose();

      dispatch({
        type: types.SAVE_POSITION_PSYCHOMETRIC_LANGUAGE,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const savePositionKnowledgeSubDomain = (payload, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/psy/position-knowledge-subdomains",
        payload
      );

      onClose();

      dispatch({
        type: types.SAVE_POSITION_KNOWLEDGE_SUBDOMAIN,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getQuestionBankQuestions = (
  query,
  setTotalRequests,
  setLoading
) => {
  return async (dispatch) => {
    setLoading(true);

    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/psy/question-bank-questions" +
          "?" +
          new URLSearchParams(query).toString()
      );
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_QUESTIONBANK_QUESTIONS,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addOrUpdateQuestionBankQuestion = (formData, onCloseForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/psy/question-bank-questions",
        formData
      );
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_QUESTIONBANK_QUESTION,
        data,
      });

      showSuccess("Question saved successfully");

      onCloseForm();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const addOrUpdateTempQuestionBankQuestion = (formData, onCloseForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/psy/new-questions", formData);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_TEMP_QUESTIONBANK_QUESTION,
        data,
      });

      showSuccess("Question saved successfully");

      onCloseForm();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteQuestionBankQuestion = (id, onCloseDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.delete(
        "/api/psy/question-bank-questions/" + id
      );
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.DELETE_QUESTIONBANK_QUESTION,
        id,
      });

      dispatch({
        type: types.SET_QUESTIONBANK_KNOWLEDGE_DOMAINS,
        data: data,
      });

      showSuccess("Deleted successfully");

      onCloseDialog();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const disableQuestionBankQuestion = (
  { questionId, isDisabled },
  onCloseDialog
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put(
        "/api/psy/disable-question-bank-question",
        { questionId, isDisabled }
      );
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_QUESTIONBANK_QUESTION,
        data,
      });

      showSuccess(`${isDisabled ? "Disabled" : "Enabled"} successfully`);

      onCloseDialog();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getQuestionBankKnowledgeDomains = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/psy/question-bank-knowledge-domains"
      );

      dispatch({
        type: types.SET_QUESTIONBANK_KNOWLEDGE_DOMAINS,
        data: data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getKnowledgeLevels = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/api/psy/knowledge-levels");

      dispatch({
        type: types.SET_KNOWLEDGE_LEVELS,
        data: data,
      });
    } catch (error) {
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const setKnowledgeLevel = (payload, onCloseDialog) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.post("/api/psy/knowledge-levels", payload);

      dispatch({
        type: types.ADD_OR_UPDATE_KNOWLEDGE_LEVEL,
        data: data.knowledgeLevel,
      });

      dispatch({
        type: "SET_LEVELS",
        data: data.levels,
      });

      dispatch({ type: types.END_LOADING });

      onCloseDialog();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const uploadPSYQuestions = (questions, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/psy/upload-new-questions", {
        questions,
      });

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_TEMP_QUESTIONBANK_QUESTIONS,
        data,
      });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getTempPSYQuestions = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/psy/new-questions");

      dispatch({
        type: types.SET_TEMP_QUESTIONBANK_QUESTIONS,
        data,
      });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteTempPSYQuestion = (id, confirmRemoveQuestion) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/psy/new-questions/" + id);

      dispatch({ type: types.END_LOADING });
      showSuccess("Question deleted Successfully");

      dispatch({
        type: types.DELETE_TEMP_QUESTIONBANK_QUESTION,
        id,
      });
      confirmRemoveQuestion(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const deleteAllTempPSYQuestions = (setConfirmRemoveAll) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      await axios.delete("/api/psy/new-questions");

      dispatch({ type: types.END_LOADING });
      showSuccess("All Questions deleted Successfully");

      dispatch({
        type: types.SET_TEMP_QUESTIONBANK_QUESTIONS,
        data: [],
      });
      setConfirmRemoveAll(false);
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const confirmPSYQuestions = (confirmed, history, onClose, id = "") => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/psy/confirm-new-questions/" + id,
        { confirmed }
      );

      dispatch({ type: types.END_LOADING });

      showSuccess(`${confirmed ? "Confirmed" : "Rejected"} successfully`);

      onClose();

      if (!id) {
        if (!!confirmed) {
          dispatch({
            type: types.SET_TEMP_QUESTIONBANK_QUESTIONS,
            data: [],
          });

          history.push("/recruitment/psychometric/bank");
        } else {
          dispatch({
            type: types.SET_TEMP_QUESTIONBANK_QUESTIONS,
            data,
          });
        }
      } else {
        if (!!confirmed)
          dispatch({
            type: types.DELETE_TEMP_QUESTIONBANK_QUESTION,
            id,
          });
        else
          dispatch({
            type: types.ADD_OR_UPDATE_TEMP_QUESTIONBANK_QUESTION,
            data,
          });
      }
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const requestToEdit = (
  { questionId, editorId, editorMessage },
  onCloseForm
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post("/api/psy/edit-requests", {
        questionId,
        editorId,
        editorMessage,
      });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({
        type: types.ADD_OR_UPDATE_QUESTIONBANK_QUESTION,
        data: data,
      });

      showSuccess("The request has been successfully submitted to the editor.");

      onCloseForm();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getActiveCandidates = (
  query,
  setTotalRequests,
  setLoading,
  disableLoding = false
) => {
  return async (dispatch) => {
    setLoading(true);

    try {
      if (!disableLoding) dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/psy/active-candidates" +
          "?" +
          new URLSearchParams(query).toString()
      );
      if (!disableLoding) dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_ACTIVE_CANDIDATES,
        data: data.records,
      });

      setTotalRequests(data.totalRecords);

      setLoading(false);
    } catch (error) {
      if (!disableLoding) dispatch({ type: types.END_LOADING });

      setLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const psyLockCandidate = (
  { sessionId, isSessionLocked, comment },
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.put("/api/psy/lock-candidate-session", {
        sessionId,
        isSessionLocked,
        comment,
      });
      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.ADD_OR_UPDATE_ACTIVE_CANDIDATE,
        data: data,
      });

      onClose();
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getApplicantSessionLogs = (applicantSessionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/psy/applicant-session-logs/" + applicantSessionId
      );

      dispatch({
        type: types.SET_APPLICANT_SESSION_LOGS,
        data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getDashboardChartsData = (period) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        "/api/psy/dashboard-charts-data/" + period
      );

      dispatch({ type: types.END_LOADING });

      dispatch({
        type: types.SET_DASHBOARD_CHART_DATA,
        data: data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisitionPsychometricLanguages = (
  requisitionId,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/psy/requisition-psychometric-languages/" + requisitionId
      );

      dispatch({
        type: types.SET_REQUISITION_PSYCHOMETRIC_LANGUAGES,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const getRequisitionPsychometricSubDomains = (
  requisitionId,
  setIsLoading
) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        "/api/psy/requisition-knowledge-subdomains/" + requisitionId
      );

      dispatch({
        type: types.SET_REQUISITION_KNOWLEDGE_SUBDOMAINS,
        data,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};

export const downloadDashboardData = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      const { data } = await axios.get("/api/psy/download-dashboard-data", {
        responseType: "blob",
      });

      download(new Blob([data]), `dashboard-data.xlsx`, ".xlsx");

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });

      showError(error);
    }
  };
};

export const getQuestionLogs = (questionId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get("/api/psy/question-logs/" + questionId);

      dispatch({
        type: types.SET_QUESTION_LOGS,
        data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      if (isSessionExpired(error)) {
        dispatch({ type: types.SET_REQUIRE_AUTHENTICATION });
      }
      showError(error);
    }
  };
};
