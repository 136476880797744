export const defaultState = {
  userFound: null,
  entitySectors: [],
  entitySectorUnits: [],
  costCenters: [],
  entitySectorCostCenters: [],
  entitySectorCreditors: [],
  entitySectorPayrollTypes: [],
  examCenters: [],
  centerIpAddress: [],
};
