export const defaultState = {
  lookup: {},
  userEducations: [],
  userExperiences: [],
  applicationEducations: [],
  userLanguages: [],
  userDisabilities: [],
  userCertificates: [],
  userReferees: [],
  userPublications: [],
  userCV: "",
  userProfileStatus: {},
  applicationProfileStatus: {},
  rebApplicantPreferences: {},
  applicationCertificates: [],
  applicationExperiences: [],
  applicationDisabilities: [],
  applicationLanguages: [],
  applicationPublications: [],
  applicationReferees: [],
  applicationCV:[],
  filterUserSkills:[],
  totalSkillsSummary:{}
};
