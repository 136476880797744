import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getSupervisorSubordinates } from "../../../store/rbm/actions";
import TableSkeleton from "../../common/components/TableSkeleton";

const EmployeeSubordinatesDialog = (props) => {
  const { onClose, supervisor, getSupervisorSubordinates } = props;
  const [subordinates, setSubordinates] = React.useState([]);
  const [loadingData, setLoadingData] = React.useState(true);

  useEffect(() => {
    getSupervisorSubordinates(supervisor.id, (status, data) => {
      setLoadingData(status);
      if (!status) {
        setSubordinates(data);
      }
    });
  }, []);

  return (
    <>
      <Dialog open={true} fullWidth maxWidth="lg">
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            List of subordinates |{" "}
            <strong>
              {supervisor.lastName} {supervisor.firstName}
            </strong>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer>
            <Table size="small" aria-label="Your annual leave balance">
              <TableHead>
                <TableRow
                  style={{
                    borderLeft: "2px solid #e9ecef",
                    borderRight: "2px solid #e9ecef",
                  }}
                >
                  <TableCell>Employee ID</TableCell>
                  <TableCell>Names</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>RBM Contract</TableCell>
                </TableRow>
              </TableHead>
              {loadingData ? (
                <TableSkeleton cols={5} isMUI />
              ) : (
                <TableBody>
                  {subordinates.map((subordinate) => (
                    <TableRow key={subordinate.id} className="tr-hovered">
                      <TableCell>{subordinate.employeeId}</TableCell>
                      <TableCell>
                        {subordinate.names}
                        {!!subordinate.isNotActive && (
                          <span
                            className=" badge badge-warning"
                            style={{ fontSize: ".75rem", fontweight: "bold" }}
                          >
                            Exit
                          </span>
                        )}
                      </TableCell>
                      <TableCell>{subordinate.unitName}</TableCell>
                      <TableCell>
                        {!!subordinate.isActing && (
                          <strong
                            className="text-primary"
                            style={{ fontSize: ".75rem", fontweight: "bold" }}
                          >
                            Ag.
                          </strong>
                        )}
                        {subordinate.positionName}
                      </TableCell>
                      <TableCell>
                        {!!subordinate.contractTypeId && (
                          <span className=" badge badge-pill">
                            <small>
                              {subordinate.contractType} <br />
                              contract
                            </small>
                          </span>
                        )}

                        {!subordinate.contractTypeId && (
                          <span
                            style={{ color: "#dc3545" }}
                            className=" badge badge-pill"
                          >
                            <span className="text-danger">No contract</span>
                          </span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                  {!subordinates.length && (
                    <TableRow>
                      <TableCell colSpan={5} className="text-center">
                        No subordinates found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  getSupervisorSubordinates,
})(EmployeeSubordinatesDialog);
