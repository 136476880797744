import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
  ListItemButton,
  ListItemText,
  List,
  ListSubheader,
  Card,
  CardHeader,
  ListItem,
  CardActions,
  ListItemAvatar,
  Avatar,
  Autocomplete,
} from "@mui/material";

import { connect } from "react-redux";
import NoRecordMessage from "../../common/components/NoRecordMessage";

import { setKnowledgeSubDomain } from "../../../store/psychometric/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ReactPaginate from "react-paginate";

const KnowledgeSubDomains = (props) => {
  const {
    loading,
    user,
    knowledgeSubDomains,
    knowledgeDomains,
    setKnowledgeSubDomain,
  } = props;

  const [knowledgeDomain, setKnowledgeDomain] = useState(null);

  const [showForm, setShowForm] = useState(false);
  const [subDomain, setSubDomain] = useState("");

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(knowledgeSubDomains);
  }, [itemOffset, itemsPerPage, knowledgeSubDomains]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % knowledgeSubDomains.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    knowledgeDomainId: null,
    isActive: true,
  });

  useEffect(() => {
    if (!!formData.knowledgeDomainId)
      setKnowledgeDomain(
        knowledgeDomains.find(({ id }) => id === formData.knowledgeDomainId) ||
          null
      );
  }, [formData.knowledgeDomainId]);

  return (
    <Card className="border elevated-1 hoverable-card" variant="outlined">
      <div
        className="mb-3"
        style={{
          height: "55vh",
          overflow: "auto",
        }}
      >
        <List
          dense
          component="div"
          subheader={
            <ListSubheader
              className=" border-bottom font-weight-bold d-flex justify-content-between"
              component="div"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
              }}
            >
              <span>Knowledge Sub Domains</span>
              <IconButton color="inherit" onClick={() => setShowForm(true)}>
                <span className="material-icons">add</span>
              </IconButton>
            </ListSubheader>
          }
        >
          {paginatedItems?.map((knowledgeSubDomain, index) => (
            <KnowledgeSubDomainCard
              index={getNo(knowledgeSubDomains, "id", knowledgeSubDomain.id)}
              key={knowledgeSubDomain.id}
              loading={loading}
              user={user}
              knowledgeSubDomain={knowledgeSubDomain}
              setKnowledgeSubDomain={setKnowledgeSubDomain}
              setFormData={setFormData}
              setShowForm={setShowForm}
              knowledgeDomains={knowledgeDomains}
            />
          ))}
        </List>
        <NoRecordMessage
          isEmpty={!paginatedItems?.length}
          title="No record found. "
        />
      </div>

      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <>
            {/* <span className="pr-1 d-none d-md-inline">Next</span> */}
            <i className="fas fa-angle-double-right"></i>
          </>
        }
        previousLabel={
          <>
            <i className="fas fa-angle-double-left"></i>
            {/* <span className="pl-1  d-none d-md-inline">Previous</span> */}
          </>
        }
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
        activeLinkClassName="active"
      />

      {showForm && (
        <Dialog
          onClose={() => setShowForm(false)}
          open={showForm}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="h6" noWrap component="div">
              <span className="text-primary">Add New Sub-Domain</span>
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => setShowForm(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                minHeight: "5vh",
              }}
            >
              <Autocomplete
                className="mt-3"
                size="small"
                id="knowledgeDomain"
                name="knowledgeDomain"
                defaultValue={null}
                value={knowledgeDomain}
                options={knowledgeDomains.filter(({ isActive }) => !!isActive)}
                onChange={(event, domain) => {
                  setKnowledgeDomain(domain || null);
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <ListItem dense {...props}>
                    <ListItemText primary={option.name} />
                  </ListItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Domain"
                    placeholder="Domain"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />

              <TextField
                autoFocus
                fullWidth
                size="small"
                name="levelName"
                label={`Sub-Domain`}
                placeholder={`Sub-Domain`}
                variant="outlined"
                className="mt-2"
                value={formData.name || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: e.target.value || "",
                  });
                }}
              />
            </div>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-4">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setKnowledgeSubDomain(
                  { ...formData, knowledgeDomainId: knowledgeDomain?.id },
                  () => {
                    setFormData({
                      id: null,
                      name: "",
                      knowledgeDomainId: null,
                      isActive: true,
                    });
                    setShowForm(false);
                  }
                )
              }
              type="button"
              className="px-5"
              disabled={loading || !knowledgeDomain || !formData.name}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
};

const mapStateToProps = ({
  user,
  loading,
  knowledgeSubDomains,
  knowledgeDomains,
}) => {
  return {
    user,
    loading,
    knowledgeSubDomains,
    knowledgeDomains,
  };
};

export default connect(mapStateToProps, { setKnowledgeSubDomain })(
  KnowledgeSubDomains
);

const KnowledgeSubDomainCard = (props) => {
  const {
    loading,
    user,
    knowledgeSubDomain,
    index,
    setKnowledgeSubDomain,
    knowledgeDomains,
    setFormData,
    setShowForm,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);

  const knowledgeDomain = knowledgeDomains.find(
    ({ id }) => id === knowledgeSubDomain.knowledgeDomainId
  );

  return (
    <>
      <ListItem
        divider
        key={knowledgeSubDomain.id}
        secondaryAction={
          <span>
            <IconButton
              size="small"
              onClick={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();

                    setFormData({
                      id: knowledgeSubDomain.id,
                      name: knowledgeSubDomain.name,
                      knowledgeDomainId: knowledgeSubDomain.knowledgeDomainId,
                      isActive: knowledgeSubDomain.isActive,
                    });
                    setShowForm(true);
                  }}
                  className={`text-primary`}
                >
                  <span className="material-icons mr-2">edit</span>
                  Edit
                </MenuItem>
              </span>

              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowConfirmDisable(true);
                  }}
                  className={`text-${
                    !knowledgeSubDomain.isActive ? "success" : "danger"
                  } font-weight-light`}
                >
                  {knowledgeSubDomain.isActive && (
                    <span className="material-icons mr-2">close</span>
                  )}
                  {!knowledgeSubDomain.isActive && (
                    <span className="material-icons mr-2">check</span>
                  )}
                  {knowledgeSubDomain.isActive ? "Disable" : "Enable"}
                </MenuItem>
              </span>
            </Menu>
          </span>
        }
      >
        <ListItemAvatar>
          <Avatar variant="square">{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<span>{knowledgeSubDomain.name}</span>}
          secondary={
            <>
              <span className="d-block">
                {knowledgeSubDomain.knowledgeDomainName}
              </span>
              <span
                className={`d-inline-flex align-items-center text-${
                  knowledgeSubDomain.isActive ? "success" : "danger"
                }`}
              >
                <span
                  style={{ fontSize: "12px" }}
                  className={`material-icons text-${
                    knowledgeSubDomain.isActive ? "success" : "danger"
                  }`}
                >
                  fiber_manual_record
                </span>
                {knowledgeSubDomain.isActive ? "Active" : "Disabled"}
              </span>
            </>
          }
        />
      </ListItem>

      {showConfirmDisable && (
        <ConfirmationDialog
          disabled={!knowledgeDomain?.isActive}
          confirmationDialog={showConfirmDisable}
          message={
            <>
              {knowledgeDomain?.isActive ? (
                <>
                  Are you sure you want to{" "}
                  <strong
                    className={`text-${
                      knowledgeSubDomain.isActive ? "danger" : "success"
                    }`}
                  >
                    {knowledgeSubDomain.isActive ? "disable" : "enable"}
                  </strong>{" "}
                  this sub domain?
                </>
              ) : (
                <>
                  <p className="alert alert-danger mb-0">
                    This subdomain cannot be enabled. Please activate its parent
                    domain first. <br />{" "}
                    <strong>({knowledgeDomain?.name})</strong>.
                  </p>
                </>
              )}
            </>
          }
          setConfirmationDialog={setShowConfirmDisable}
          noPreFormat={true}
          onYes={() => {
            setKnowledgeSubDomain(
              {
                id: knowledgeSubDomain.id,
                name: knowledgeSubDomain.name,
                knowledgeDomainId: knowledgeSubDomain.knowledgeDomainId,
                isActive: !knowledgeSubDomain.isActive,
              },
              () => setShowConfirmDisable(false)
            );
          }}
        />
      )}
    </>
  );
};
