import React, { useState } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import { Toolbar, Typography, Box, Button } from "@mui/material";
import Qualifications from "../../admin/components/Qualifications";


const SkillsQualifications = (props) => {
  const [createQualification, setCreateQualification] = useState(false);

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="d-flex align-items-center justify-content-between">
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>SKILLS</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    User Qualifications
                  </span>
                </span>
              </Typography>
            </Toolbar>
            <div className="mx-1">
              <div className="row">
              <div className="col-12">
                <Button
                      variant="contained"
                      size="small"
                      onClick={() => setCreateQualification(true)}
                      className=" mx-md-0 float-right"
                      title="Add Qualification"
                    >
                      <span className="d-none d-md-block">
                        Add Qualification
                      </span>
                      <span className="d-md-none d-block">Add</span>
                    </Button>
                </div>
                <div className="col-12">
                  {" "}
                  <Qualifications
                    createQualification={createQualification}
                    setCreateQualification={setCreateQualification}
                  />
                </div>
              </div>
            </div>
          </AppBar>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps, {})(SkillsQualifications);
