import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import MenuBar from "./components/MenuBar";
import UserGuide from "./pages/UserGuide";
import Employees from "./pages/Employees";
import Dashboard from "./pages/Dashboard";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import ContractualsLayout from "./contractuals/Layout";
import FindEmployee from "./pages/FindEmployee";
import PrivateRoute from "../common/guard/PrivateRoute";
import { countEmployeeRequests } from "../../store/structure/actions";
import EmployeeRequest from "./pages/EmployeeRequest";

import { getVerticalRanks } from "../../store/structure/actions";

const Layout = (props) => {
  const { user, countEmployeeRequests, getVerticalRanks } = props;
  const history = useHistory();
  useEffect(() => {
    if (isEmpty(user)) return history.push("/");
  }, []);

  useEffect(() => {
    getVerticalRanks();
  }, []);

  return (
    <React.Fragment>
      <MenuBar />
      <Switch>
        <Route path="/employees/units" component={Employees} />
        <Route path="/employees/all" component={Dashboard} />
        <Route path="/employees/contracts" component={ContractualsLayout} />
        {/* <Route path="/employees/requests" component={EmployeeRequest} /> */}

        <PrivateRoute
          children={<FindEmployee />}
          permissions={["IS_CBM"]}
          path="/employees/find-employee-details"
        />
        <Route path="/employees/user-guide" component={UserGuide} />

        <Route
          path="/employees"
          component={() => <Redirect to="/employees/units" />}
        />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, {
  countEmployeeRequests,
  getVerticalRanks,
})(Layout);

//export  const StructureDrawer=connect(mapStateToProps)(Layout);
