import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  Toolbar,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment,
  Select,
  Badge,
  IconButton,
  Menu,
  LinearProgress,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Divider,
} from "@mui/material";

import UserResume from "../../profile/UserResume";
import {
  getUserDetails,
  getFilteredUserSkills,
} from "../../../store/profile/actions";

import { getEntitySectors } from "../../../store/structure/actions";
import BackdropLoader from "../../common/components/BackdropLoader";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";

import {
  getCountries,
  getDegrees,
  getEducationalInstitutions,
  getQualifications,
} from "../../../store/profile/actions";

const getSchoolYears = (currentDate = new Date()) => {
  const d = new Date(currentDate);
  const currentYear = d.getFullYear();
  let i = 0;
  let result = [];
  while (i <= 50) {
    result.push({ id: i + 1, name: (+currentYear - i).toString() });
    i++;
  }
  return result;
};

const genders = [
  { id: "M", name: "Male" },
  { id: "F", name: "Female" },
];
const employments = [
  { id: "Employed", name: "Employed" },
  { id: "Unemployed", name: "Unemployed" },
];
const SkillsProfile = (props) => {
  const {
    loading,
    filterUserSkills,
    getUserDetails,
    getFilteredUserSkills,
    entitySectors,
    getEntitySectors,

    getCountries,
    getDegrees,
    getEducationalInstitutions,
    getQualifications,

    lookup,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [entitySector, setEntitySector] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(entitySectors);
  const [gender, setGender] = useState(null);
  const [employmentStatus, setEmploymentStatus] = useState(null);
  const [minAge, setMinAge] = useState(null);
  const [maxAge, setMaxAge] = useState(null);

  const [minYearsExperience, setMinYearsExperience] = useState(null);
  const [maxYearsExperience, setMaxYearsExperience] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [totalSkillProfiles, setTotalSkillProfiles] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [skillProfileLoader, setSkillProfileLoader] = useState(false);
  const [skillProfileLoaderOnNextPage, setSkillProfileLoaderOnNextPage] =
    useState(false);
  const [country, setCountry] = useState(null);
  const [institution, setInstitution] = useState(null);
  const [degree, setDegree] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [year, setYear] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!lookup.countries) getCountries();
    if (!lookup.degrees) getDegrees();
  }, []);

  useEffect(() => {
    if (!!country) getEducationalInstitutions(country.id);
  }, [country]);

  useEffect(() => {
    if (!!degree) getQualifications(degree.id);
  }, [degree]);

  useEffect(() => {
    if (!entitySectors.length) getEntitySectors();
  }, []);

  useEffect(() => {
    // Filter options based on the keyword
    const filtered = [...entitySectors].filter(
      (option) =>
        option?.entity?.name.toLowerCase().includes(keyword.toLowerCase()) ||
        (option?.entity?.acronym &&
          option?.entity?.acronym.toLowerCase().includes(keyword.toLowerCase()))
    );
    if (filtered.length === 1) {
      setEntitySector(filtered[0]);
    }
    setFilteredOptions(filtered);
  }, [keyword, entitySectors]);

  const onFind = async (e) => {
    e.preventDefault();
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    getFilteredUserSkills(
      { ...fields(), page: _currentPage, size: itemPerPage },
      setTotalSkillProfiles,
      setSkillProfileLoader
    );
  };
  const fields = () => {
    return {
      searchTerm: searchTerm,
      employeeStatus: employmentStatus?.id || null,
      minAge: minAge || null,
      maxAge: maxAge || null,
      gender: gender?.id || null,
      minYearsExperience: minYearsExperience || null,
      maxYearsExperience: maxYearsExperience || null,
      entitySectorId: entitySector?.id || null,

      positionId: null,
      levelId: null,
      degreeId: degree?.id || null,
      fieldOfStudyId: null,
      qualificationId: qualification?.id || null,
      nationalId: null,
      placeOfBirth: null,
      countryOfStudyId: country?.id || null,
      schoolId: institution?.id || null,
      yearOfCompletion: year?.name || null,
    };
  };
  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    getFilteredUserSkills(
      {
        ...fields(),
        page: currentPage,
        size: itemPerPage,
      },
      setTotalSkillProfiles,
      setSkillProfileLoaderOnNextPage
    );
  };

  const filterDegrees = () =>
    lookup.degrees?.filter(({ id }) => id !== 9 && id !== 10) || [];

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="d-flex align-items-center justify-content-between">
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>SKILLS</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    User Profiles
                  </span>
                </span>
              </Typography>
            </Toolbar>
            <div className="mx-1">
              <div className="row">
                <div className="col-3">
                  <div
                    className="elevated p-3"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #17a2b8",
                      minHeight: "auto",
                    }}
                  >
                    <form onSubmit={onFind}>
                      <div className="row">
                        <div className="col-12 mb-1">
                          {skillProfileLoader && <LinearProgress />}
                        </div>
                        <div
                          className="col-12"
                          style={{
                            height: "55vh",
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          <div className="row">
                            <div className="col-12 mb-1">
                              <TextField
                                fullWidth
                                size="small"
                                rows={4}
                                name="searchTerm"
                                label={`Search by any keyword..`}
                                placeholder={`Search by any keyword..`}
                                variant="outlined"
                                className="font-weight-bold mb-1"
                                value={searchTerm || ""}
                                onChange={(e) => {
                                  setSearchTerm(e.target.value || "");
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <span
                                        style={{
                                          borderRadius: "0px",
                                        }}
                                      >
                                        <span className="material-icons mt-2">
                                          search
                                        </span>
                                      </span>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            <div className="col-12 mb-2">
                              <Autocomplete
                                loading={loading}
                                size="small"
                                id="entitySector"
                                defaultValue={null}
                                value={entitySector}
                                options={filteredOptions}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, entitySector) => {
                                  setEntitySector(entitySector || null);
                                }}
                                getOptionLabel={(option) =>
                                  (option?.entity?.acronym
                                    ? "" + option?.entity.acronym + " / "
                                    : "") + option?.entity?.name
                                }
                                renderOption={(props, option) => (
                                  <Box component="li" {...props}>
                                    {option?.entity?.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    // required={true}
                                    {...params}
                                    label="Search by employer"
                                    placeholder="Search by employer"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "entitySector", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                            </div>
                            <div className="col-12 mb-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={country}
                                options={lookup.countries || []}
                                disabled={loading}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, country) => {
                                  setCountry(country || null);
                                  setInstitution(null);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, country) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={country.id}
                                  >
                                    {country.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Search by country of study"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                              {/* {formError && formError.type === "country" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
                            </div>

                            <div className="col-12 mb-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={institution}
                                options={lookup.educationalInstitutions || []}
                                disabled={loading}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, institute) => {
                                  setInstitution(institute || null);
                                  // setFormError(null);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, institute) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={institute.id}
                                  >
                                    {institute.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Search by university"
                                    placeholder="Search by university"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                              {/* {formError && formError.type === "institution" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
                            </div>

                            <div className="col-12 mb-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={degree}
                                options={filterDegrees()}
                                disabled={loading}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, degree) => {
                                  setDegree(degree || null);
                                  setQualification(null);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, degree) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={degree.id}
                                  >
                                    {degree.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Search by degree"
                                    placeholder="Search by degree"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                              {/* {formError && formError.type === "degree" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
                            </div>

                            <div className="col-12 mb-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={qualification}
                                options={lookup.qualifications || []}
                                disabled={loading}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, qualification) => {
                                  setQualification(qualification || null);
                                  // setFormError(null);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, qualification) => (
                                  <Box
                                    component="li"
                                    {...props}
                                    key={qualification.id}
                                  >
                                    {qualification.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Search by qualification"
                                    placeholder="Search by qualification"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                              {/* {formError && formError.type === "qualification" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
                            </div>

                            <div className="col-12 mb-2">
                              <Autocomplete
                                size="small"
                                defaultValue={null}
                                value={year}
                                options={getSchoolYears()}
                                disabled={loading}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(event, year) => {
                                  setYear(year || null);
                                  // setFormError(null);
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderOption={(props, year) => (
                                  <Box component="li" {...props} key={year.id}>
                                    {year.name}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    size="small"
                                    style={{
                                      height: "25px !important",
                                      position: "relative",
                                      borderRadius: "8px",
                                      padding: "1px !important",
                                    }}
                                    {...params}
                                    label="Year of completion"
                                    placeholder="Year of completion"
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: "name", // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                              {/* {formError && formError.type === "year" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
                            </div>
                            <div className="col-12 mb-2">
                              <div className="row">
                                <div className="col-6">
                                  <fieldset className="form-group border p-2 mt-2">
                                    <legend
                                      className="w-100"
                                      style={{
                                        color: "rgba(0, 0, 0, 0.6)",
                                        fontFamily:
                                          '"Roboto","Helvetica","Arial",sans-serif',
                                        fontWeight: 400,
                                        fontSize: "0.82rem",
                                        lineHeight: "1em",
                                        letterSpacing: "0.00938em",
                                      }}
                                    >
                                      Filter by gender
                                    </legend>
                                    <div className="form-group1">
                                      <FormControl>
                                        <RadioGroup
                                          aria-labelledby="gender-label"
                                          // defaultValue="female"
                                          name="gender-label-group"
                                          className="row"
                                        >
                                          {genders.map((row, index) => (
                                            <FormControlLabel
                                              className="col-6"
                                              key={index + row.id}
                                              value={row.name}
                                              control={
                                                <Radio
                                                  onClick={() => {
                                                    if (row === gender)
                                                      setGender(null);
                                                  }}
                                                />
                                              }
                                              label={row.name}
                                              onChange={() => setGender(row)}
                                              checked={row === gender}
                                            />
                                          ))}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </fieldset>
                                </div>
                                <div className="col-6">
                                  <fieldset className="form-group border p-2 mt-2">
                                    <legend
                                      className="w-100"
                                      style={{
                                        color: "rgba(0, 0, 0, 0.6)",
                                        fontFamily:
                                          '"Roboto","Helvetica","Arial",sans-serif',
                                        fontWeight: 400,
                                        fontSize: "0.82rem",
                                        lineHeight: "1em",
                                        letterSpacing: "0.00938em",
                                      }}
                                    >
                                      Filter by status
                                    </legend>
                                    <div className="form-group1">
                                      <FormControl>
                                        <RadioGroup
                                          aria-labelledby="emplStatus-label"
                                          // defaultValue="female"
                                          name="emplStatus-label-group"
                                          className="row"
                                        >
                                          {employments.map((row, index) => (
                                            <FormControlLabel
                                              className="col-6"
                                              key={index + row.id}
                                              value={row.name}
                                              control={
                                                <Radio
                                                  onClick={() => {
                                                    if (
                                                      row === employmentStatus
                                                    )
                                                      setEmploymentStatus(null);
                                                  }}
                                                />
                                              }
                                              label={row.name}
                                              onChange={() =>
                                                setEmploymentStatus(row)
                                              }
                                              checked={row === employmentStatus}
                                            />
                                          ))}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  </fieldset>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 mb-1">
                              <fieldset className="form-group border p-2 mt-2">
                                <legend
                                  className="w-auto px-2"
                                  style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Age range
                                </legend>
                                <div className="form-group1">
                                  <div className="row">
                                    <div className="col-5">
                                      <TextField
                                        fullWidth
                                        size="small"
                                        rows={2}
                                        name="minAge"
                                        label={`Min age`}
                                        placeholder={`Min age`}
                                        variant="outlined"
                                        className="font-weight-bold"
                                        value={minAge || ""}
                                        onChange={(e) => {
                                          setMinAge(e.target.value || "");
                                        }}
                                      />
                                    </div>
                                    <div className="col-2">
                                      <Divider className="mt-3" />
                                    </div>
                                    <div className="col-5">
                                      <TextField
                                        fullWidth
                                        size="small"
                                        rows={2}
                                        name="maxAge"
                                        label={`Max age`}
                                        placeholder={`Max age`}
                                        variant="outlined"
                                        className="font-weight-bold"
                                        value={maxAge || ""}
                                        onChange={(e) => {
                                          setMaxAge(e.target.value || "");
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>

                            <div className="col-12 mb-2">
                              <fieldset className="form-group border p-2 mt-2">
                                <legend
                                  className="w-auto px-2"
                                  style={{
                                    color: "rgba(0, 0, 0, 0.6)",
                                    fontFamily:
                                      '"Roboto","Helvetica","Arial",sans-serif',
                                    fontWeight: 400,
                                    fontSize: "0.82rem",
                                    lineHeight: "1em",
                                    letterSpacing: "0.00938em",
                                  }}
                                >
                                  Work experience range
                                </legend>
                                <div className="form-group1 mb-1">
                                  <div className="row">
                                    <div className="col-5">
                                      <TextField
                                        fullWidth
                                        size="small"
                                        rows={2}
                                        name="minYearsExperience"
                                        label={`Min year exp.`}
                                        placeholder={`Min year exp.`}
                                        variant="outlined"
                                        className="font-weight-bold"
                                        value={minYearsExperience || ""}
                                        onChange={(e) => {
                                          setMinYearsExperience(
                                            e.target.value || ""
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-2">
                                      <Divider className="mt-3" />
                                    </div>
                                    <div className="col-5">
                                      <TextField
                                        fullWidth
                                        size="small"
                                        rows={2}
                                        name="maxYearsExperience"
                                        label={`Max year exp.`}
                                        placeholder={`Max year exp.`}
                                        variant="outlined"
                                        className="font-weight-bold"
                                        value={maxYearsExperience || ""}
                                        onChange={(e) => {
                                          setMaxYearsExperience(
                                            e.target.value || ""
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 py-2">
                          <Divider />
                        </div>
                        <div className="col-12">
                          <button
                            disabled={skillProfileLoader}
                            type="submit"
                            className="btn btn-primary text-uppercase ml-1 d-flex align-items-center float-right"
                          >
                            <span className="material-icons">search</span>
                            Search
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-9">
                  <div
                    style={{
                      marginLeft: "1em",
                      marginRight: "1em",
                      backgroundColor: "#fff",
                      borderRadius: "0.5rem",
                      boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                      MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                      height: "55vh",
                      overflowY: "scroll",
                    }}
                  >
                    {!skillProfileLoader && !skillProfileLoaderOnNextPage && (
                      <strong>Total records: {totalSkillProfiles}</strong>
                    )}
                    <table className="table table-striped table-hover table-sm  fixTableHead">
                      <thead>
                        <tr style={{ backgroundColor: "#f0f2f5" }}>
                          <th scope="col">#</th>
                          <th scope="col">Names</th>
                          <th scope="col">Sex</th>
                          <th scope="col">Age</th>
                          <th scope="col">Nationality</th>
                          <th scope="col">PlaceOfBirth</th>
                          <th scope="col">Experience</th>
                          <th scope="col">YearInService</th>
                          <th scope="col">HightestDegree</th>
                          <th scope="col">Employer</th>
                          <th scope="col">Position</th>
                          <th scope="col">Level</th>
                          <th scope="col" className="text-right">
                            Status
                          </th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      {!!skillProfileLoader ? (
                        <TableSkeleton cols={14} rows={15} />
                      ) : (
                        <tbody>
                          <tr>
                            <td>
                              <BackdropLoader
                                isLoading={skillProfileLoaderOnNextPage}
                              />
                            </td>
                          </tr>
                          {filterUserSkills.map((employee, index) => (
                            <UserItem
                              employee={employee}
                              index={index}
                              key={index}
                              getUserDetails={getUserDetails}
                            />
                          ))}
                        </tbody>
                      )}
                    </table>
                    {!filterUserSkills.length && (
                      <div className="jumbotron jumbotron-fluid text-center  mt-0">
                        <div className="container py-5">
                          <p className="lead">
                            {skillProfileLoader
                              ? "Searching..."
                              : "No record found"}{" "}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* {!skillProfileLoader && ( */}
                  <div
                    className="d-flex justify-content-center  align-items-center"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="mt-3">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                          <>
                            <span className="pr-1 d-none d-md-inline">
                              Next
                            </span>
                            <i className="fas fa-angle-double-right"></i>
                          </>
                        }
                        previousLabel={
                          <>
                            <i className="fas fa-angle-double-left"></i>
                            <span className="pl-1  d-none d-md-inline">
                              Previous
                            </span>
                          </>
                        }
                        pageRangeDisplayed={3}
                        // marginPagesDisplayed={1}
                        onPageChange={handlePageClick}
                        pageCount={Math.ceil(totalSkillProfiles / itemPerPage)}
                        renderOnZeroPageCount={null}
                        containerClassName="pagination"
                        pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                        previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                        nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                        activeLinkClassName="active"
                      />
                    </div>

                    {!!filterUserSkills.length && (
                      <select
                        className="custom-select custom-select-sm ml-2"
                        style={{ width: "100px" }}
                        value={itemPerPage}
                        onChange={(event) => {
                          const itemPerPage = +event.target.value;
                          window.localStorage.setItem(
                            "ITEM_PER_PAGE",
                            itemPerPage
                          );
                          setItemPerPage(itemPerPage);

                          getFilteredUserSkills(
                            {
                              ...fields(),
                              page: currentPage,
                              size: itemPerPage,
                            },
                            setTotalSkillProfiles,
                            setSkillProfileLoaderOnNextPage
                          );
                        }}
                      >
                        {[10, 50, 100].map((item, index) => (
                          <option key={index}>{item}</option>
                        ))}
                      </select>
                    )}
                  </div>
                  {/*  )} */}
                </div>
              </div>
            </div>
          </AppBar>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  filterUserSkills,
  entitySectors,
  lookup,
}) => {
  return { user, loading, filterUserSkills, entitySectors, lookup };
};
export default connect(mapStateToProps, {
  getUserDetails,
  getFilteredUserSkills,
  getEntitySectors,
  getCountries,
  getDegrees,
  getEducationalInstitutions,
  getQualifications,
})(SkillsProfile);

const UserItem = ({ index, employee, loading, getUserDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showEmployeeProfileDialog, setShowEmployeeProfileDialog] =
    useState(false);

  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowEmployeeProfileDialog(true);
  };

  return (
    <>
      <tr key={index}>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {employee.id}
        </th>
        <td>
          {employee.lastName} {employee.firstName}
        </td>
        <td>{employee.genderId === "M" ? "Male" : "Female"}</td>
        <td>{employee.age}</td>

        <td>{employee.nationality}</td>

        <td>{employee.placeOfBirth}</td>
        <td>
          {employee.yearsExperience} year{employee.yearsExperience > 1 && "s"}
        </td>
        <td>
          {employee.yearsService} year
          {employee.yearsyearsServiceExperience > 1 && "s"}
        </td>

        <td>{employee.highestDegree}</td>
        <td>{employee.employer ? employee.employer : "N/A"}</td>
        <td>{employee.position ? employee.position : "N/A"}</td>
        <td>
          {employee.level && (
            <>
              <span className="d-flex align-items-end justify-content-end">
                <strong
                  className="text-primary"
                  style={{ fontSize: ".75rem", fontweight: "bold" }}
                >
                  {employee.level}
                </strong>
              </span>
            </>
          )}
        </td>
        <td className="text-right">
          {!!employee.employmentStatus ? (
            <strong
              className={`ml-1 badge badge-${
                employee.employmentStatus === "Employed" ? "success" : "warning"
              }`}
              style={{ fontSize: ".75rem", fontweight: "bold" }}
            >
              {employee.employmentStatus}
            </strong>
          ) : (
            "N/A"
          )}
        </td>
        <td>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  getUserDetails(employee.id, handleOpenCurrentProfile);
                }}
                className="text-primary font-weight-light"
              >
                <span className="material-icons mr-1">account_circle</span>
                {loading ? "Wait...." : "View Profile"}
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>

      {showEmployeeProfileDialog && (
        <UserResume
          showProfileDialog={showEmployeeProfileDialog}
          setShowProfileDialog={setShowEmployeeProfileDialog}
          canEdit={false}
          allowedToEditEmail={false}
          allowedToEditEmploymentDate={false}
          enabledToViewPhoto={true}
        />
      )}
    </>
  );
};
