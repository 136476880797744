import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { textCapitalized } from "../../common/components/Utils";
import {
  getAppealDetails,
  getAppealResolved,
  closeAppeal,
  downloadAppeal,
  confirmFirstLevelAppealDecision,
  confirmSecondLevelAppealDecision,
} from "../../../store/e-appeal/actions";
import { getCase } from "../../../store/discipline/actions";
import moment from "moment";
import FirstLevelAppealDecisionDialog from "./FirstLevelAppealDecisionDialog";
import SecondLevelAppealDecisionDialog from "./SecondLevelAppealDecisionDialog";
import PreLoader from "../../common/components/PreLoader";
import ViewExtraAttachments from "./ViewExtraAttachments";
import AppealResolveDialog from "./AppealResolveDialog";
import ActionDialog from "../../common/components/ActionDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ViewAppealResolved from "./ViewAppealResolved";
import AssignAppealToSupervisor from "./AssignAppealToSupervisor";
import PrintIcon from "@mui/icons-material/Print";
import SanctionLetter from "./SanctionLetter";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ViewCaseDialog from "../../discipline/components/ViewCaseDialog";

const ViewAppealDialog = (props) => {
  const {
    user,
    loading,
    appeal,
    viewAppeal,
    setViewAppeal,
    getAppealDetails,
    appealDetails,
    getAppealResolved,
    appealResolved,
    closeAppeal,
    appealCategory,
    downloadAppeal,
    confirmFirstLevelAppealDecision,
    confirmSecondLevelAppealDecision,
    getCase,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [showAppealResolved, setAppealResolved] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [makeAppealFLDecision, setMakeAppealFLDecision] = useState(false);
  const [makeAppealSLDecision, setMakeAppealSLDecision] = useState(false);
  const [resolveAppeal, setResolveAppeal] = useState(false);
  const [confirmAppealResolved, setConfirmAppealResolved] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [showAssignAppealToSupervisor, setShowAssignAppealToSupervisor] =
    useState(false);
  const [confirmFLAppealDecision, setConfirmFLAppealDecision] = useState(false);
  const [confirmSLAppealDecision, setConfirmSLAppealDecision] = useState(false);

  const [showCaseDetail, setShowCaseDetail] = useState(false);
  const [firstLevelAppeal, setFirstLevelAppeal] = useState(null);
  const [secondLevelAppeal, setSecondLevelAppeal] = useState(null);

  const [firstLevelAttachments, setFirstLevelAttachments] = useState([]);
  const [firstLevelDecisionAttachments, setFirstLevelDecisionAttachments] =
    useState([]);
  const [secondLevelAttachments, setSecondLevelAttachments] = useState([]);
  const [secondLevelDecisionAttachments, setSecondLevelDecisionAttachments] =
    useState([]);
  useEffect(() => {
    getAppealDetails(appeal.referenceNo);
    if (appeal && appeal.appealIdL2) getAppealResolved(appeal.appealIdL2);
  }, [appeal]);

  useEffect(() => {
    const firstLevelAppeal = appealDetails.find(({ level }) => level === 1);
    const secondLevelAppeal = appealDetails.find(({ level }) => level === 2);

    if (firstLevelAppeal) {
      const firstLevelAttachments = firstLevelAppeal.appealAttachments.filter(
        ({ createdBy }) => createdBy === firstLevelAppeal.employeeId
      );
      const firstLevelDecisionAttachments =
        firstLevelAppeal.appealAttachments.filter(
          ({ createdBy }) => createdBy === firstLevelAppeal.decisionBy
        );

      setFirstLevelAttachments(firstLevelAttachments);
      setFirstLevelDecisionAttachments(firstLevelDecisionAttachments);
    }

    if (secondLevelAppeal) {
      const secondLevelAttachments = secondLevelAppeal.appealAttachments.filter(
        ({ createdBy }) => createdBy === secondLevelAppeal.employeeId
      );
      const secondLevelDecisionAttachments =
        secondLevelAppeal.appealAttachments.filter(
          ({ createdBy }) => createdBy === secondLevelAppeal.decisionBy
        );

      setSecondLevelAttachments(secondLevelAttachments);
      setSecondLevelDecisionAttachments(secondLevelDecisionAttachments);
    }

    setFirstLevelAppeal(firstLevelAppeal);
    setSecondLevelAppeal(secondLevelAppeal);
  }, [appealDetails]);

  const onClose = () => {
    setViewAppeal(false);
    setConfirmSLAppealDecision(false);
    setConfirmFLAppealDecision(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={viewAppeal}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-10">
              <div className="col-112">
                <h5>
                  {firstLevelAppeal && (
                    <>
                      <small className="text-primary fw-bold text-truncate">
                        {textCapitalized(firstLevelAppeal.employee.firstName)}{" "}
                        {textCapitalized(firstLevelAppeal.employee.lastName)}
                      </small>{" "}
                    </>
                  )}
                </h5>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col">
                  {appeal && (
                    <button
                      type="button"
                      onClick={() =>
                        downloadAppeal(
                          appeal.referenceNo,
                          appeal.subjectL1,
                          setIsDownloading
                        )
                      }
                      className="btn btn-sm my-2 print"
                    >
                      <PrintIcon className="text-primary float-end " />
                    </button>
                  )}
                </div>
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {makeAppealFLDecision && (
            <FirstLevelAppealDecisionDialog
              makeAppealFLDecision={makeAppealFLDecision}
              setMakeAppealFLDecision={setMakeAppealFLDecision}
              appeal={firstLevelAppeal}
            />
          )}

          {makeAppealSLDecision && (
            <SecondLevelAppealDecisionDialog
              makeAppealSLDecision={makeAppealSLDecision}
              setMakeAppealSLDecision={setMakeAppealSLDecision}
              appeal={secondLevelAppeal}
            />
          )}
          <div className="col-md-12 col-lg-12 col-xl-12">
            <div
              className="pl-3 pr-3 pb-3 m-2"
              style={{
                position: "relative",
                height: "400px",
                overflow: "auto",
              }}
            >
              <PreLoader isLoading={loading} />
              {firstLevelAppeal && (
                <div className="w-100  mt-0 float-start">
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <span
                      className="badge rounded-pill badge-primary px-3 fw-bold"
                      style={{
                        // backgroundColor: "#f5f6f7"
                        backgroundColor: "#dfe7f6",
                        color: " #2c58a0",
                      }}
                    >
                      First level Appeal
                    </span>
                  </div>

                  {appeal && firstLevelAppeal && !!firstLevelAppeal.caseId && (
                    <div className="w-100 d-flex flex-row justify-content-end">
                      <button
                        type="button"
                        disabled={loading1}
                        onClick={() =>
                          getCase(
                            firstLevelAppeal.caseId,
                            setLoading1,
                            setShowCaseDetail
                          )
                        }
                        className="btn btn-secondary btn-sm my-2"
                      >
                        {loading1 ? "Wait..." : "View displinarly case"}
                      </button>
                    </div>
                  )}

                  <div
                    className={`d-flex flex-row ${
                      !firstLevelAppeal.caseId ? "mt-4" : ""
                    } justify-content-start mt-0 w-100`}
                  >
                    <div className="w-100">
                      <div
                        className="card rounded-3  w-100"
                        style={{
                          // backgroundColor: "#f5f6f7"
                          background: "rgba(0, 99, 207, 0.08)",
                          borderColor: "rgba(0, 99, 207, 0.08)",
                        }}
                      >
                        <div
                          className="card-header"
                          style={{
                            // backgroundColor: "#f5f6f7"
                            background: "rgb(223, 231, 246)",
                            color: " #2c58a0",
                          }}
                        >
                          <div className="row">
                            <div className="col-6">
                              <small className="small card-title fw-bold text-truncate">
                                {appeal && appeal.subjectL1}
                              </small>
                            </div>
                            <div className="col-6">
                              {appealCategory !== "TaggedAppeals" &&
                                firstLevelAppeal &&
                                !firstLevelAppeal.decision &&
                                firstLevelAppeal.statusId === 1 && (
                                  <div className=" d-flex justify-content-end ">
                                    {hasPermissions(["IS_HR"]) && (
                                      <>
                                        <a
                                          onClick={() =>
                                            setShowAssignAppealToSupervisor(
                                              true
                                            )
                                          }
                                          className={
                                            appeal.appealAssignedPositionId1
                                              ? "btn btn-secondary btn-sm my-1 mr-2"
                                              : "btn btn-primary btn-sm my-1 mr-2"
                                          }
                                          href="#!"
                                        >
                                          {appeal.appealAssignedPositionId1
                                            ? "Re-assign"
                                            : "Assign"}
                                        </a>
                                      </>
                                    )}

                                    {appeal.role === appeal.decisionMaker && (
                                      <a
                                        onClick={() =>
                                          setMakeAppealFLDecision(true)
                                        }
                                        className="btn btn-success btn-sm my-1"
                                        href="#!"
                                      >
                                        Make Decision
                                      </a>
                                    )}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="card-body"
                          style={{
                            // backgroundColor: "#f5f6f7"
                            background: "rgb(223, 231, 246)",
                          }}
                        >
                          <p
                            className="small p-0 ms-2 mb-0 rounded-3"
                            dangerouslySetInnerHTML={{
                              __html: firstLevelAppeal.message,
                            }}
                          ></p>
                          <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                            {!!firstLevelAttachments.length && (
                              <ViewExtraAttachments
                                files={firstLevelAttachments}
                              />
                            )}
                            {!!appeal.caseId && (
                              <>
                                <SanctionLetter
                                  caseSanctionName={
                                    appeal?.caseSanctionName || ""
                                  }
                                  sanctionDocReferenceNo={
                                    appeal?.sanctionDocReferenceNo || ""
                                  }
                                />
                              </>
                            )}
                          </p>
                          <p
                            className="small text-muted mb-0 rounded-3 mt-2 float-end"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            {moment(firstLevelAppeal.createdOn).format("lll")}
                          </p>

                          <div className="w-100  mt-0 float-start">
                            {firstLevelAppeal && firstLevelAppeal.decision && (
                              <>
                                {/* <div className="d-flex flex-row justify-content-center mt-4">
                                  <span className="badge rounded-pill badge-success ml-3 fw-bold">
                                    First level decision
                                  </span>
                                </div> */}
                                <div className="d-flex flex-row justify-content-start ml-3 mt-4 mr-3 w-100 float-start">
                                  <div
                                    className="card  w-100"
                                    style={{
                                      backgroundColor: "#fff",
                                      borderLeft: "2px solid #3f51b5",
                                      borderLeft: "2px solid #3f51b5",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <div
                                      className="card-header"
                                      style={{
                                        backgroundColor: "#fff",
                                      }}
                                    >
                                      <div className="row">
                                        <div className="col-6">
                                          <small className="small card-title text-primary fw-bold text-truncate">
                                            {textCapitalized(
                                              firstLevelAppeal.entitySector
                                                .entity.name
                                            )}
                                            (
                                            {textCapitalized(
                                              firstLevelAppeal.entitySector
                                                .sector.name
                                            )}
                                            )
                                          </small>

                                          <small
                                            style={{
                                              fontSize: "11px",
                                            }}
                                            className="float-end w-100 fw-bold"
                                          >
                                            Decision taken by:
                                            {textCapitalized(
                                              firstLevelAppeal.decisionMaker
                                                .firstName
                                            )}{" "}
                                            {textCapitalized(
                                              firstLevelAppeal.decisionMaker
                                                .lastName
                                            )}
                                          </small>
                                        </div>
                                        <div className="col-6">
                                          {!secondLevelAppeal &&
                                            [2].includes(
                                              firstLevelAppeal.statusId
                                            ) && (
                                              <div className=" d-flex justify-content-end ">
                                                <a
                                                  onClick={() =>
                                                    setMakeAppealFLDecision(
                                                      true
                                                    )
                                                  }
                                                  className={
                                                    "btn btn-secondary btn-sm my-1 mr-2"
                                                  }
                                                  href="#!"
                                                >
                                                  Edit
                                                </a>

                                                <a
                                                  onClick={() =>
                                                    setConfirmFLAppealDecision(
                                                      true
                                                    )
                                                  }
                                                  className="btn btn-success btn-sm my-1"
                                                  href="#!"
                                                >
                                                  Approve
                                                </a>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="card-body"
                                      style={{
                                        backgroundColor: "#fff",
                                      }}
                                    >
                                      <p className="small p-0 ms-0 mb-0 rounded-3">
                                        {" "}
                                        <span
                                          className={`badge badge-pill mr-3 ${
                                            firstLevelAppeal.isValid
                                              ? "badge-success"
                                              : "badge-danger"
                                          }`}
                                          style={{
                                            backgroundColor:
                                              firstLevelAppeal.isValid
                                                ? "#d6f0e0"
                                                : "#f9e1e5",
                                            color: firstLevelAppeal.isValid
                                              ? "#0d6832"
                                              : "#af233a",
                                          }}
                                        >
                                          Your appeal is{" "}
                                          {!firstLevelAppeal.isValid && "not"}{" "}
                                          valid
                                        </span>
                                        <p className="mt-2">
                                          {firstLevelAppeal.decision}
                                        </p>
                                      </p>
                                      <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                                        <>
                                          {!!firstLevelDecisionAttachments.length && (
                                            <ViewExtraAttachments
                                              files={
                                                firstLevelDecisionAttachments
                                              }
                                            />
                                          )}
                                        </>
                                      </p>
                                      <div className="row mt-0">
                                        <div className="col-12">
                                          <p className="small mt-3 mb-0 rounded-3 text-primary float-end ">
                                            {firstLevelAppeal &&
                                              firstLevelAppeal.statusId === 3 &&
                                              !secondLevelAppeal && (
                                                <span
                                                  className="badge rounded-pill badge-success mr-3 fw-bold"
                                                  style={{
                                                    backgroundColor: "#dfe7f6",
                                                    color: " #2c58a0",
                                                  }}
                                                >
                                                  Appeal accepted{" "}
                                                  {firstLevelAppeal.closedBy}
                                                </span>
                                              )}

                                            <span
                                              style={{
                                                fontSize: "11px",
                                              }}
                                            >
                                              {moment(
                                                firstLevelAppeal.decisionOn
                                              ).format("lll")}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {secondLevelAppeal && (
                <div className="w-100  mt-3 float-start">
                  <div className="d-flex flex-row justify-content-center mt-3">
                    <span
                      className="badge rounded-pill badge-success ml-3 fw-bold"
                      style={{
                        backgroundColor: "#d6f0e0",
                        color: "#0d6832",
                      }}
                    >
                      Second level Appeal
                    </span>
                  </div>

                  <div className="d-flex flex-row justify-content-start mt-4  w-100 float-start">
                    <div className="w-100">
                      <div
                        className="card rounded-3"
                        style={{
                          // backgroundColor: "#f5f6f7"
                          background: "#d6f0e0",
                          borderColor: "#d6f0e0",
                        }}
                      >
                        <div
                          className="card-header"
                          style={{
                            // backgroundColor: "#f5f6f7"
                            background: "#d6f0e0",
                            color: " #2c58a0",
                          }}
                        >
                          <div className="row">
                            <div className="col-8">
                              <small className="small card-title fw-bold text-truncate">
                                {appeal && appeal.subjectL1}
                              </small>
                            </div>
                            <div className="col-4">
                              {appealCategory !== "TaggedAppeals" &&
                                secondLevelAppeal &&
                                !secondLevelAppeal.decision &&
                                secondLevelAppeal.statusId === 1 &&
                                appeal &&
                                appeal.role === "NPSC" && (
                                  <div className=" d-flex justify-content-end ">
                                    <a
                                      onClick={() =>
                                        setMakeAppealSLDecision(true)
                                      }
                                      className="btn  btn-success text-capitalize"
                                      href="#!"
                                    >
                                      Make Decision
                                    </a>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <p
                            className="small p-0 ms-2 mb-0 rounded-3"
                            dangerouslySetInnerHTML={{
                              __html: secondLevelAppeal.message,
                            }}
                          ></p>
                          <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                            <>
                              {" "}
                              {!!secondLevelAttachments.length && (
                                <ViewExtraAttachments
                                  files={secondLevelAttachments}
                                />
                              )}
                            </>
                          </p>
                          <p
                            className="small text-muted mb-0 rounded-3 mt-2 float-end"
                            style={{
                              fontSize: "11px",
                            }}
                          >
                            {moment(secondLevelAppeal.createdOn).format("lll")}
                          </p>

                          <div className="w-100  mt-0 float-start">
                            {secondLevelAppeal &&
                              secondLevelAppeal.decision && (
                                <>
                                  <div className="d-flex flex-row justify-content-start ml-3 mt-4 mr-3 w-100 float-start">
                                    <div
                                      className="card  w-100"
                                      style={{
                                        backgroundColor: "#fff",
                                        borderLeft: "2px solid #3f51b5",
                                        borderLeft: "2px solid #3f51b5",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      <div
                                        className="card-header"
                                        style={{
                                          backgroundColor: "#fff",
                                        }}
                                      >
                                        <div className="row">
                                          <div className="col-7">
                                            <small className="small card-title text-primary fw-bold">
                                              National Public Service Commission
                                            </small>

                                            <small
                                              style={{
                                                fontSize: "11px",
                                              }}
                                              className="float-end w-100 fw-bold"
                                            >
                                              Decision taken by:
                                              {textCapitalized(
                                                secondLevelAppeal.decisionMaker
                                                  .firstName
                                              )}
                                              {textCapitalized(
                                                secondLevelAppeal.decisionMaker
                                                  .lastName
                                              )}
                                            </small>
                                          </div>

                                          <div className="col-5 float-right">
                                            {secondLevelAppeal &&
                                              [4].includes(
                                                secondLevelAppeal.statusId
                                              ) && (
                                                <div className=" d-flex justify-content-end ">
                                                  <a
                                                    onClick={() =>
                                                      setMakeAppealSLDecision(
                                                        true
                                                      )
                                                    }
                                                    className={
                                                      "btn btn-secondary btn-sm my-1 mr-2"
                                                    }
                                                    href="#!"
                                                  >
                                                    Edit
                                                  </a>

                                                  <a
                                                    onClick={() =>
                                                      setConfirmSLAppealDecision(
                                                        true
                                                      )
                                                    }
                                                    className="btn btn-success btn-sm my-1"
                                                    href="#!"
                                                  >
                                                    Approve
                                                  </a>
                                                </div>
                                              )}

                                            {appeal &&
                                              secondLevelAppeal &&
                                              secondLevelAppeal.isValid &&
                                              secondLevelAppeal.statusId ===
                                                5 && (
                                                <>
                                                  {appeal.role ===
                                                    appeal.decisionMaker &&
                                                    !appealResolved && (
                                                      <a
                                                        onClick={() =>
                                                          setResolveAppeal(true)
                                                        }
                                                        className="btn ml-2 float-right btn-success btn-sm text-capitalize "
                                                        href="#!"
                                                      >
                                                        Resolve now!
                                                      </a>
                                                    )}
                                                </>
                                              )}

                                            {secondLevelAppeal &&
                                              secondLevelAppeal.isValid &&
                                              secondLevelAppeal.statusId ===
                                                5 &&
                                              appeal &&
                                              appeal.role === "NPSC" &&
                                              appealResolved && (
                                                <a
                                                  onClick={() =>
                                                    setConfirmAppealResolved(
                                                      true
                                                    )
                                                  }
                                                  className="btn btn-primary ml-2 btn-sm text-capitalize float-end"
                                                  href="#!"
                                                >
                                                  Close an appeal
                                                </a>
                                              )}

                                            {secondLevelAppeal &&
                                              secondLevelAppeal.isValid &&
                                              appealResolved && (
                                                <a
                                                  onClick={() =>
                                                    setAppealResolved(true)
                                                  }
                                                  className="btn float-right  btn-success btn-sm text-capitalize "
                                                  href="#!"
                                                >
                                                  View resolution
                                                </a>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body">
                                        <p className="small p-0 ms-1 mb-0 rounded-3">
                                          <span
                                            className={`badge badge-pill mr-3 ${
                                              secondLevelAppeal.isValid
                                                ? "badge-success"
                                                : "badge-danger"
                                            } `}
                                            style={{
                                              backgroundColor:
                                                secondLevelAppeal.isValid
                                                  ? "#d6f0e0"
                                                  : "#f9e1e5",
                                              color: secondLevelAppeal.isValid
                                                ? "#0d6832"
                                                : "#af233a",
                                            }}
                                          >
                                            Your appeal is{" "}
                                            {!secondLevelAppeal.isValid &&
                                              "not"}{" "}
                                            valid
                                          </span>
                                          <p className="mt-2">
                                            {secondLevelAppeal.decision}
                                          </p>
                                        </p>
                                        <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                                          <>
                                            {!!secondLevelDecisionAttachments.length && (
                                              <ViewExtraAttachments
                                                files={
                                                  secondLevelDecisionAttachments
                                                }
                                              />
                                            )}
                                          </>
                                        </p>
                                        <p className="small mt-1 mb-0 rounded-3 text-primary float-end ">
                                          <span
                                            style={{
                                              fontSize: "11px",
                                            }}
                                          >
                                            {moment(
                                              secondLevelAppeal.decisionOn
                                            ).format("lll")}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <AppealResolveDialog
              resolveAppeal={resolveAppeal}
              setResolveAppeal={setResolveAppeal}
              appealId={appeal.appealIdL2}
            />

            {secondLevelAppeal &&
              secondLevelAppeal.isValid &&
              secondLevelAppeal.statusId === 5 &&
              appeal &&
              appeal.role !== "NPSC" &&
              appealResolved && (
                <div>
                  <div className="d-flex flex-row justify-content-center mt-4">
                    <span className="badge rounded-pill ap-badge-info ml-3 p-2 fw-bold">
                      Waiting for NPSC to close an appeal.
                    </span>
                  </div>
                </div>
              )}

            {secondLevelAppeal &&
              secondLevelAppeal.isValid &&
              secondLevelAppeal.statusId === 5 &&
              appeal &&
              appeal.role === "NPSC" &&
              !appealResolved && (
                <div>
                  <div className="d-flex flex-row justify-content-center mt-4">
                    <span className="badge rounded-pill ap-badge-info ml-3 p-2 fw-bold">
                      Waiting for {appeal.decisionMaker || "Institution"} to
                      Resolve an appeal
                    </span>
                  </div>
                </div>
              )}

            {secondLevelAppeal &&
              secondLevelAppeal.isValid &&
              secondLevelAppeal.statusId === 5 &&
              appeal &&
              appeal.role === "NPSC" &&
              appealResolved && (
                <div>
                  <div className="d-flex flex-row justify-content-center mt-4">
                    <span className="badge rounded-pill ap-badge-info ml-3 p-2 fw-bold">
                      Appeal Resolved. You can close it now!
                    </span>
                  </div>
                </div>
              )}

            {secondLevelAppeal && secondLevelAppeal.statusId === 3 && (
              <div>
                <div className="d-flex flex-row justify-content-center mt-4">
                  <span className="badge rounded-pill ap-badge-danger ml-3 fw-bold">
                    Appeal closed
                  </span>
                </div>
              </div>
            )}
            {showCaseDetail && (
              <ViewCaseDialog
                showCaseDetail={showCaseDetail}
                setShowCaseDetail={setShowCaseDetail}
              />
            )}
            {confirmAppealResolved && (
              <ConfirmationDialog
                confirmationDialog={confirmAppealResolved}
                message={`Are you sure you want to close an appeal?`}
                setConfirmationDialog={setConfirmAppealResolved}
                onYes={() => {
                  closeAppeal(
                    appeal.appealIdL2,
                    setIsSubmitting,
                    setConfirmAppealResolved
                  );
                }}
              />
            )}
            {confirmFLAppealDecision && (
              <ConfirmationDialog
                confirmationDialog={confirmFLAppealDecision}
                message={`Are you sure you want to approve this appeal?`}
                setConfirmationDialog={setConfirmFLAppealDecision}
                onYes={() => {
                  confirmFirstLevelAppealDecision(
                    {
                      appealId: firstLevelAppeal.id,
                      isValid: firstLevelAppeal.isValid,
                    },
                    onClose
                  );
                }}
              />
            )}
            {confirmSLAppealDecision && (
              <ConfirmationDialog
                confirmationDialog={confirmSLAppealDecision}
                message={`Are you sure you want to approve this appeal?`}
                setConfirmationDialog={setConfirmSLAppealDecision}
                onYes={() => {
                  confirmSecondLevelAppealDecision(
                    {
                      appealId: secondLevelAppeal.id,
                      isValid: secondLevelAppeal.isValid,
                    },
                    onClose
                  );
                }}
              />
            )}
            {showAppealResolved && (
              <ViewAppealResolved
                showAppealResolved={showAppealResolved}
                setAppealResolved={setAppealResolved}
                contents={
                  <>
                    {appealResolved && secondLevelAppeal && (
                      <div className="w-100  float-start">
                        <div className="d-flex flex-row justify-content-start mt-4 mr-3 w-100 float-start">
                          <div
                            className="card  w-100"
                            style={{
                              backgroundColor: "#fff",
                              borderLeft: "2px solid #3f51b5",
                              borderLeft: "2px solid #3f51b5",
                              borderRadius: "4px",
                            }}
                          >
                            <div
                              className="card-header"
                              style={{
                                backgroundColor: "#fff",
                              }}
                            >
                              <small className="small card-title text-primary fw-bold text-truncate">
                                {textCapitalized(
                                  firstLevelAppeal.entitySector.entity.name
                                )}
                                (
                                {textCapitalized(
                                  firstLevelAppeal.entitySector.sector.name
                                )}
                                )
                              </small>

                              <small
                                style={{
                                  fontSize: "11px",
                                }}
                                className="float-end w-100 fw-bold"
                              >
                                Resolved by:
                                {textCapitalized(
                                  appealResolved.resolver.firstName
                                )}{" "}
                                {textCapitalized(
                                  appealResolved.resolver.lastName
                                )}
                              </small>
                            </div>
                            <div className="card-body w-100">
                              <p
                                className="small p-0 ms-2 mb-0 rounded-3"
                                dangerouslySetInnerHTML={{
                                  __html: appealResolved.message,
                                }}
                              ></p>
                              <p className="small p-0 ms-2 mt-1 mb-0 rounded-3">
                                {!!appealResolved.appealResolvedAttachments
                                  .length && (
                                  <ViewExtraAttachments
                                    files={
                                      appealResolved.appealResolvedAttachments
                                    }
                                  />
                                )}
                              </p>
                              <p className="small mt-2 mb-0 rounded-3 text-primary float-end ">
                                <span
                                  style={{
                                    fontSize: "11px",
                                  }}
                                >
                                  {moment(appealResolved.resolvedOn).format(
                                    "lll"
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                }
              />
            )}
            {isSubmitting && (
              <ActionDialog showAction={isSubmitting} action="Wait..." />
            )}
            {isDownloading && (
              <ActionDialog
                showAction={isDownloading}
                action="Downloading..."
              />
            )}

            {showAssignAppealToSupervisor && firstLevelAppeal && (
              <AssignAppealToSupervisor
                appealAssignedPositionId={appeal.appealAssignedPositionId1}
                appealId={firstLevelAppeal.id}
                showAssignAppealToSupervisor={showAssignAppealToSupervisor}
                setShowAssignAppealToSupervisor={
                  setShowAssignAppealToSupervisor
                }
              />
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  appeal,
  appealDetails,
  appealResolved,
}) => {
  return {
    user,
    loading,
    appeal,
    appealDetails,
    appealResolved,
  };
};
export default connect(mapStateToProps, {
  getAppealDetails,
  getAppealResolved,
  closeAppeal,
  downloadAppeal,
  confirmFirstLevelAppealDecision,
  confirmSecondLevelAppealDecision,
  getCase,
})(ViewAppealDialog);
