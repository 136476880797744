import { Skeleton } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import JobClassificationQualificationCard from "./JobClassificationQualificationCard";
import ReactPaginate from "react-paginate";

const JobClassificationQualifications = (props) => {
  const {
    loading,
    onWait,
    jobClassificationId,
    jobClassificationQualifications,
  } = props;

  //PAGINATION

  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 8;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(jobClassificationQualifications);
  }, [itemOffset, itemsPerPage, jobClassificationQualifications]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
        jobClassificationQualifications.length || 0;
    setItemOffset(newOffset);
  };

  return (
    <>
      {!!jobClassificationQualifications.length && (!loading || onWait) && (
        <table className="table table-striped table-hover table-sm mt-2">
          <thead>
            <tr style={{ backgroundColor: "#f0f2f5" }}>
              <th scope="col px-0">#</th>
              <th scope="col px-0">Qualifications</th>
              <th scope="col px-0">Required work experience</th>
              <th scope="col px-0"></th>
            </tr>
          </thead>

          <tbody>
            {paginatedItems.map((jcQualification, index) => (
              <JobClassificationQualificationCard
                index={index}
                jcQualification={jcQualification}
                key={jcQualification.id}
              />
            ))}
          </tbody>
        </table>
      )}

      {(!loading || onWait) && !paginatedItems.length && (
        <div
          className="jumbotron jumbotron-fluid text-center mt-2"
          style={{ width: "100%" }}
        >
          <div className="container">
            <p className="lead">No qualifications found</p>
          </div>
        </div>
      )}

      {loading && !onWait && (
        <div className="container " style={{ width: "100%" }}>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </div>
      )}

      {(!loading || onWait) &&
        !!paginatedItems.length &&
        jobClassificationQualifications.length >= itemsPerPage && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
            activeLinkClassName="active"
          />
        )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  jobClassificationQualifications,
}) => {
  return {
    loading,
    onWait,
    jobClassificationQualifications,
  };
};

export default connect(mapStateToProps, {})(JobClassificationQualifications);
