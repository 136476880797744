import { defaultState } from "./state";
import payroll from "./action-types";
import commonTypes from "../common/action-types";
import { isEmpty } from "lodash";
const types = { ...commonTypes, ...payroll };

const tempPayrolls = (
  tempPayrollsState = defaultState.tempPayrolls,
  action
) => {
  switch (action.type) {
    case types.SET_TEMP_PAYROLLS:
      return action.data;

    case types.GENERATE_PAYROLL: {
      const tmpState = [...tempPayrollsState];

      action?.data?.forEach((payroll) => {
        const index = tmpState.findIndex(({ id }) => id === payroll.id);

        if (index < 0) tmpState.unshift(payroll);
        else tmpState[index] = payroll;
      });

      return tmpState;
    }

    case types.UPDATE_TEMP_PAYROLL: {
      const tmpState = [...tempPayrollsState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index < 0) return tmpState;

      tmpState[index] = action.data;

      return tmpState;
    }

    case types.DELETE_TEMP_PAYROLL: {
      const tmpState = [...tempPayrollsState];
      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index < 0) return tmpState;

      tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.tempPayrolls;

    default:
      return tempPayrollsState;
  }
};

const archivedPayrolls = (
  archivedPayrollsState = defaultState.archivedPayrolls,
  action
) => {
  switch (action.type) {
    case types.SET_ARCHIVED_PAYROLLS:
      return action.data;

    case types.UPDATE_ARCHIVED_PAYROLL: {
      const tmpState = [...archivedPayrollsState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index < 0) return tmpState;

      tmpState[index] = action.data;

      return tmpState;
    }

    case types.DELETE_ARCHIVED_PAYROLL: {
      const tmpState = [...archivedPayrollsState];
      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index < 0) return tmpState;

      tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.archivedPayrolls;

    default:
      return archivedPayrollsState;
  }
};

const approvalPayrolls = (
  approvalPayrollsState = defaultState.approvalPayrolls,
  action
) => {
  switch (action.type) {
    case types.SET_APPROVAL_PAYROLLS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.approvalPayrolls;

    default:
      return approvalPayrollsState;
  }
};

const payrollTypes = (
  payrollTypesState = defaultState.payrollTypes,
  action
) => {
  switch (action.type) {
    case types.SET_PAYROLL_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.payrollTypes;

    default:
      return payrollTypesState;
  }
};

const sourceOfFunds = (
  sourceOfFundsState = defaultState.sourceOfFunds,
  action
) => {
  switch (action.type) {
    case types.SET_SOURCE_FOUNDS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.sourceOfFunds;

    default:
      return sourceOfFundsState;
  }
};

// const employeeGroups = (
//   employeeGroupsState = defaultState.employeeGroups,
//   action
// ) => {
//   switch (action.type) {
//     case types.SET_EMPLOYEE_GROUPS:
//       return action.data;

//     case types.CLEAN_STATE:
//       return defaultState.employeeGroups;

//     default:
//       return employeeGroupsState;
//   }
// };

// const arrearTypes = (arrearTypesState = defaultState.arrearTypes, action) => {
//   switch (action.type) {
//     case types.SET_ARREAR_TYPES:
//       return action.data;

//     case types.CLEAN_STATE:
//       return defaultState.arrearTypes;

//     default:
//       return arrearTypesState;
//   }
// };

const payrollPreviewed = (
  payrollPreviewedState = defaultState.payrollPreviewed,
  action
) => {
  switch (action.type) {
    case types.PREVIEW_PAYROLL:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.payrollPreviewed;

    default:
      return payrollPreviewedState;
  }
};

const allowances = (allowancesState = defaultState.allowances, action) => {
  switch (action.type) {
    case types.SET_ALLOWANCES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.allowances;

    default:
      return allowancesState;
  }
};

const systemAllowances = (
  systemAllowancesState = defaultState.systemAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_SYSTEM_ALLOWANCES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.systemAllowances;

    default:
      return systemAllowancesState;
  }
};
//creditors

const creditors = (creditorssState = defaultState.creditors, action) => {
  switch (action.type) {
    case types.SET_CREDITORS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.creditors;

    default:
      return creditorssState;
  }
};

const costCenters = (costCentersState = defaultState.costCenters, action) => {
  switch (action.type) {
    case types.SET_COST_CENTERS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.costCenters;

    default:
      return costCentersState;
  }
};

const payrollGroups = (
  payrollGroupsState = defaultState.payrollGroups,
  action
) => {
  switch (action.type) {
    case types.SET_PAYROLL_GROUPS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.payrollGroups;

    default:
      return payrollGroupsState;
  }
};

const basePayrollComponents = (
  currentState = defaultState.basePayrollComponents,
  action
) => {
  switch (action.type) {
    case types.SET_BASE_PAYROLL_COMPONENTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.basePayrollComponents;

    default:
      return currentState;
  }
};

const calculationMethods = (
  currentState = defaultState.calculationMethods,
  action
) => {
  switch (action.type) {
    case types.SET_CALCULATION_METHODES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.calculationMethods;

    default:
      return currentState;
  }
};

const payrollDeclarations = (
  declarationsState = defaultState.payrollDeclarations,
  action
) => {
  switch (action.type) {
    case types.SET_PAYROLL_DECLARATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.payrollDeclarations;

    default:
      return declarationsState;
  }
};

const payHistories = (
  payHistoriesState = defaultState.payHistories,
  action
) => {
  switch (action.type) {
    case types.SET_PAY_HISTORIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.payHistories;

    default:
      return payHistoriesState;
  }
};

const currencies = (currenciesState = defaultState.currencies, action) => {
  switch (action.type) {
    case types.SET_CURRENCIES:
      return action.data;

    case types.UPDATE_CURRENCY: {
      const tmpState = [...currenciesState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);
      if (index >= 0) tmpState[index] = action.data;
      return tmpState;
    }
    case types.CLEAN_STATE:
      return defaultState.currencies;

    default:
      return currenciesState;
  }
};

const payrollDetails = (
  payrollDetailsState = defaultState.payrollDetails,
  action
) => {
  switch (action.type) {
    case types.SET_PAYROLL_DETAILS: {
      let tmpState = { ...payrollDetailsState };

      if (!!action.data?.payrollId)
        tmpState[action.data.payrollId] = action.data;

      return tmpState;
    }

    case types.UPDATE_PAYROLL_CHANGE_LIST: {
      let tmpState = { ...payrollDetailsState };

      if (!!action.data?.payrollId) {
        const changeList = tmpState[action.data.payrollId].changeList;

        const index = changeList.findIndex(({ id }) => id === action.data.id);

        if (index >= 0) {
          changeList[index] = action.data;
        }

        tmpState[action.data.payrollId] = {
          ...tmpState[action.data.payrollId],
          changeList: changeList,
        };
      }

      return tmpState;
    }

    case types.UPDATE_PAYROLL_APPROVERS: {
      let tmpState = { ...payrollDetailsState };

      if (!!action.data?.payrollId)
        tmpState[action.data.payrollId] = {
          ...tmpState[action.data.payrollId],
          approvals: action.data.approvals,
        };

      return tmpState;
    }

    case types.GENERATE_PAYROLL: {
      let tmpState = { ...payrollDetailsState };

      action?.data?.forEach((payroll) => {
        if (!!payroll.id) delete tmpState[payroll.id];
      });

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.payrollDetails;

    default:
      return payrollDetailsState;
  }
};

const payrollDashboardData = (
  payrollDashboardState = defaultState.payrollDashboardData,
  action
) => {
  switch (action.type) {
    case types.SET_PAYROLL_DASHBOARD:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.payrollDashboardData;

    default:
      return payrollDashboardState;
  }
};

const rssbDeclarationError = (
  rssbDeclarationErrorState = defaultState.rssbDeclarationError,
  action
) => {
  switch (action.type) {
    case types.SET_RSSB_DECLARATION_ERROR:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.rssbDeclarationError;

    default:
      return rssbDeclarationErrorState;
  }
};

const supportingDocumentTypes = (
  supportingDocumentTypesState = defaultState.supportingDocumentTypes,
  action
) => {
  switch (action.type) {
    case types.SET_SUPPORTING_DOCUMENT_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.supportingDocumentTypes;

    default:
      return supportingDocumentTypesState;
  }
};

const employeeWithholdRequests = (
  employeeWithholdRequestsState = defaultState.employeeWithholdRequests,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_WITHHOLD_REQUESTS:
      return action.data;
    case types.UPDATE_EMPLOYEE_WITHHOLD_REQUEST: {
      const tmpState = [...employeeWithholdRequestsState];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index < 0) return tmpState;

      tmpState[index] = action.data;

      return tmpState;
    }
    case types.CLEAN_STATE:
      return defaultState.employeeWithholdRequests;

    default:
      return employeeWithholdRequestsState;
  }
};

const payrollSupportingDocuments = (
  payrollSupportingDocumentsState = defaultState.payrollSupportingDocuments,
  action
) => {
  switch (action.type) {
    case types.SET_PAYROLL_SUPPORTING_DOCUMENTS:
      return action.data;

    case types.ADD_NEW_PAYROLL_SUPPORTING_DOCUMENT:
      const tmpState = [...payrollSupportingDocumentsState];
      tmpState.push(action.data);
      return tmpState;

    case types.REMOVE_PAYROLL_SUPPORTING_DOCUMENT: {
      const tmpState = [...payrollSupportingDocumentsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.payrollSupportingDocuments;

    default:
      return payrollSupportingDocumentsState;
  }
};

const noOfEmployeeWithholdRequests = (
  noOfEmployeeWithholdRequestsState = defaultState.noOfEmployeeWithholdRequests,
  action
) => {
  switch (action.type) {
    case types.SET_NO_OF_EMPLOYEE_WITHHOLD_REQUESTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.noOfEmployeeWithholdRequests;

    default:
      return noOfEmployeeWithholdRequestsState;
  }
};

const totalArchivedPayrolls = (
  totalArchivedPayrollsState = defaultState.totalArchivedPayrolls,
  action
) => {
  switch (action.type) {
    case types.SET_TOTAL_ARCHIVED_PAYROLLS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.totalArchivedPayrolls;

    default:
      return totalArchivedPayrollsState;
  }
};
//currentArchivedPayrollPage
const currentArchivedPayrollPage = (
  currentArchivedPayrollPageState = defaultState.currentArchivedPayrollPage,
  action
) => {
  switch (action.type) {
    case types.SET_CURRENT_ARCHIVED_PAYROLL_PAGE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.currentArchivedPayrollPage;

    default:
      return currentArchivedPayrollPageState;
  }
};

const performanceBonusScores = (
  performanceBonusScoresState = defaultState.performanceBonusScores,
  action
) => {
  switch (action.type) {
    case types.SET_PERFORMANCE_BONUS_SCORES:
      return action.data;

    case types.UPDATE_PERFORMANCE_BONUS_SCORE: {
      const tmpState = [...performanceBonusScoresState];

      const index = tmpState.findIndex(
        ({ id, oldEmployeeId }) =>
          id === action.data.id || oldEmployeeId === action.data.id
      );

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.REMOVE_PERFORMANCE_BONUS_SCORE: {
      const tmpState = [...performanceBonusScoresState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.performanceBonusScores;

    default:
      return performanceBonusScoresState;
  }
};

const employeeAnnualSalaries = (
  employeeAnnualSalariesState = defaultState.employeeAnnualSalaries,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_ANNUAL_SALARIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employeeAnnualSalaries;

    default:
      return employeeAnnualSalariesState;
  }
};
const pbfIndexGroup = (
  pbfIndexGroupState = defaultState.pbfIndexGroup,
  action
) => {
  switch (action.type) {
    case types.SET_PBF_INDEX_GROUP: {
      return action.data;
    }
    case types.CLEAN_STATE:
      return defaultState.pbfIndexGroup;

    default:
      return pbfIndexGroupState;
  }
};

const employeePbfSettings = (
  employeePbfSettingsState = defaultState.employeePbfSettings,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_PBF_SETTINGS:
      return [employeePbfSettingsState, ...action.data];

    case types.ADD_EMPLOYEE_PBF_SETTING: {
      const tmpState = [...employeePbfSettingsState];
      tmpState.push(action.data);
      return tmpState;
    }

    case types.UPDATE_EMPLOYEE_PBF_SETTING: {
      const tmpState = [...employeePbfSettingsState];

      const index = tmpState.findIndex(
        ({ periodId, employeeId }) =>
          periodId === action.data.oldPeriodId &&
          employeeId === action.data.employeePbf.employeeId
      );

      if (index >= 0) tmpState[index] = action.data.employeePbf;

      return tmpState;
    }

    case types.REMOVE_EMPLOYEE_PBF_SETTING: {
      const tmpState = [...employeePbfSettingsState];

      const index = tmpState.findIndex(
        ({ periodId, employeeId }) =>
          periodId === action.data.periodId &&
          employeeId === action.data.employeeId
      );

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeePbfSettings;

    default:
      return employeePbfSettingsState;
  }
};

const changeReasons = (
  changeReasonsState = defaultState.changeReasons,
  action
) => {
  switch (action.type) {
    case types.SET_CHANGE_REASONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.changeReasons;

    default:
      return changeReasonsState;
  }
};

const individualSupportingDocuments = (
  individualSupportingDocumentsState = defaultState.individualSupportingDocuments,
  action
) => {
  switch (action.type) {
    case types.SET_INDIVIDUAL_SUPPORTING_DOCUMENTS:
      return action.data;

    case types.ADD_NEW_INDIVIDUAL_SUPPORTING_DOCUMENT:
      const tmpState = [...individualSupportingDocumentsState];
      tmpState.push(action.data);
      return tmpState;

    case types.REMOVE_INDIVIDUAL_SUPPORTING_DOCUMENT: {
      const tmpState = [...individualSupportingDocumentsState];

      const index = tmpState.findIndex(({ id }) => id === action.id);

      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.individualSupportingDocuments;

    default:
      return individualSupportingDocumentsState;
  }
};

export default {
  supportingDocumentTypes,
  payrollSupportingDocuments,
  payrollDashboardData,
  payrollDetails,
  currencies,
  tempPayrolls,
  archivedPayrolls,
  costCenters,
  // employeeGroups,
  approvalPayrolls,
  payrollTypes,
  sourceOfFunds,
  // arrearTypes,
  payrollPreviewed,
  allowances,
  systemAllowances,
  creditors,
  payrollGroups,
  payrollDeclarations,
  basePayrollComponents,
  calculationMethods,
  payHistories,
  rssbDeclarationError,
  employeeWithholdRequests,
  noOfEmployeeWithholdRequests,
  totalArchivedPayrolls,
  currentArchivedPayrollPage,
  performanceBonusScores,
  employeeAnnualSalaries,
  pbfIndexGroup,
  employeePbfSettings,
  individualSupportingDocuments,
  changeReasons,
};
