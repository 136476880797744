import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSelectedPlanningEntity,
  selectPlanningEntity,
} from "../../../store/rbm/actions";
import { getEntitySectorPlanningEntities } from "../../../store/structure/actions";

const PlanningEntitySwitcher = (props) => {
  const {
    planningEntities,
    selectedPlanningEntityId,
    disabled,
    getSelectedPlanningEntity,
    getEntitySectorPlanningEntities,
    selectPlanningEntity,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!planningEntities?.length) {
      getEntitySectorPlanningEntities((status) => {
        setLoading(status);
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedPlanningEntityId) {
      getSelectedPlanningEntity();
    }
  }, [selectedPlanningEntityId, planningEntities]);

  const handleSelectEntity = (entityId) => {
    selectPlanningEntity(entityId, setLoading);
  };

  const selectedPlanningEntity = planningEntities.find(
    (item) => item.id === selectedPlanningEntityId
  );  

  return (
    <>
      {planningEntities.length > 1 && (
        <Box className="mr-2">
          <Button
            disabled={disabled || loading}
            variant="text"
            disableElevation
            onClick={handleOpenMenu}
            sx={{ textTransform: "capitalize" }}
            endIcon={
              <span className="material-icons text-light">
                keyboard_arrow_down
              </span>
            }
          >
            <span
              className={`text-dark mr-1 `}
              style={{
                fontFamily:
                  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                fontSize: "1rem",
                fontWeight: "400",
                lineHeight: "1.5",
              }}
            >
              <span className="text-light ">
                {selectedPlanningEntity?.name || "Planning Entity"}{" "}
              </span>
            </span>
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {planningEntities.map((entity) => (
              <MenuItem
                key={entity.id}
                onClick={() => {
                  handleSelectEntity(entity.id);
                  handleCloseMenu();
                }}
              >
                <span className={`font-weight-light`}>{entity.name}</span>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = ({ planningEntities, selectedPlanningEntityId }) => {
  return { planningEntities, selectedPlanningEntityId };
};
export default connect(mapStateToProps, {
  selectPlanningEntity,
  getSelectedPlanningEntity,
  getEntitySectorPlanningEntities,
})(PlanningEntitySwitcher);
