import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Menu,
  MenuItem,
  Badge,
  Divider,
  Button,
  CardContent,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

import {
  getApplicantExamSessions,
  onChangeApplicantSession,
  onUnlockApplicantSession,
  getExamCenters,
  onMarkApplicantAsCheated,
} from "../../../store/recruitment/actions";
import PercentIcon from "@mui/icons-material/Percent";
import moment from "moment";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import SearchBox from "../../common/components/SearchBox";
import ReactPaginate from "react-paginate";
import socketIOClient from "socket.io-client";
import ApplicantExamSessionLogsDialog from "./ApplicantExamSessionLogsDialog";
import SessionAdditionalMinutesDialog from "./SessionAdditionalMinutesDialog";
import { SOCKET_EVENTS, doesExamEnded } from "../../common/components/Utils";
import RECRUITMENT_ACTION_TYPE from "../../../store/recruitment/action-types";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import BackdropLoader from "../../common/components/BackdropLoader";
import TableSkeleton from "../../common/components/TableSkeleton";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ExamKey from "./ExamKey";
const ITEM_HEIGHT = 60;
const SOCKET_API = "http://172.27.8.38:9590";
const timeItervals = 60000; //Set interval for every 5 minutes (300,000 milliseconds)
const ApplicantExamSessionsDialog = (props) => {
  const {
    loading,
    exam,
    showSessions,
    setShowSessions,
    getApplicantExamSessions,
    applicantExamSessions,
    onChangeApplicantSession,
    onUnlockApplicantSession,
    onMarkApplicantAsCheated,
    getExamCenters,
    examCenters,
    center,
    disableCentersOption,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const [examCenter, setExamCenter] = useState(
    center
      ? center
      : examCenters.length === 1
      ? examCenters[0]
      : {
          id: "0",
          center: {
            name: "All",
            id: "0",
          },
        }
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [disableAutoRefresh, setDisableAutoRefresh] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!examCenters.length) getExamCenters(exam.id);
  }, [exam]);

  // useEffect(() => {
  //   // Creates a WebSocket connection
  //   socketRef.current = socketIOClient(SOCKET_API, {
  //     reconnectionAttempts: 3,
  //     reconnectionDelayMax: 10000,
  //   });

  //   // Listens for incoming messages
  //   socketRef.current.on("connect", () => {
  //     console.log("connected");
  //   });

  //   // Destroys the socket reference
  //   // when the connection is closed
  //   return () => {
  //     socketRef.current.disconnect();
  //   };
  // }, []);
  useEffect(() => {
    dispatch({
      type: "SET_APPLICANT_EXAM_SESSIONS",
      data: [],
    });
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      examCenterId: examCenter?.id,
    };

    getApplicantExamSessions(exam.id, query, setTotalRequests, setIsLoading);
  }, []);
  useEffect(() => {
    // Set interval for every 5 minutes (300,000 milliseconds)
    if (!disableAutoRefresh && !exam.wasExamMarkingPublished) {
      const intervalId = setInterval(() => {
        const query = {
          searchTerm: searchTerm,
          page: currentPage,
          size: itemPerPage,
          examCenterId: examCenter?.id,
        };
        getApplicantExamSessions(
          exam.id,
          query,
          setTotalRequests,
          setIsLoading1
        );
      }, timeItervals);

      // Clear interval when the component is unmounted or when you want to stop it
      return () => clearInterval(intervalId);
    }
  }, [searchTerm]);
  // Empty dependency array means it runs once on mount

  const onSearch = () => {
    setDisableAutoRefresh(false);
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      examCenterId: examCenter?.id,
    };
    setDisableAutoRefresh(true);
    getApplicantExamSessions(exam.id, query, setTotalRequests, setIsLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
      examCenterId: examCenter?.id,
    };
    getApplicantExamSessions(exam.id, query, setTotalRequests, setIsLoading1);
  };

  // useEffect(
  //   () => {
  //     console.log("called");
  //     socketRef.current.on(
  //       SOCKET_EVENTS.RECRUITMENT.START_APPLICANT_SESSION_SERVER,
  //       (session) => {
  //         if (session)
  //           dispatch({
  //             type: RECRUITMENT_ACTION_TYPE.START_APPLICANT_EXAM_SESSIONS,
  //             data: session,
  //           });
  //       }
  //     );
  //   },
  //
  //   []
  // );
  // useEffect(
  //   () => {
  //     socket.on("LOCK_APPLICANT_SESSION_SERVER", (data) => {
  //       console.log(data);
  //       // if (lockedSession)
  //       //   dispatch({
  //       //     type: "UPDATE_APPLICANT_EXAM_SESSIONS",
  //       //     data: lockedSession,
  //       //   });
  //     });
  //   },
  //
  //   []
  // );

  // useEffect(
  //   () => {
  //     socket.on(`lockedApplicantSessionExams-${exam.id}`, (lockedSession) => {
  //       if (lockedSession)
  //         dispatch({
  //           type: "UPDATE_APPLICANT_EXAM_SESSIONS",
  //           data: lockedSession,
  //         });
  //     });
  //   },
  //
  //   [exam]
  // );

  const emitSession = () => {
    // socket.emit("LOCK_APPLICANT_SESSION_CLIENT", {
    //   examId: "123",
    // });
  };

  const onClose = () => {
    // socket.disconnect();
    setShowSessions(false);
  };

  // useEffect(() => {
  //   const filtered = [...applicantExamSessions].filter(({ sessionKey }) =>
  //     sessionKey.toLowerCase().includes(searchTeam.toLowerCase())
  //   );

  //   setFilteredApplicantExamSessions(filtered);
  //   paginate(filtered);
  // }, [searchTeam, applicantExamSessions]);

  //PAGINATION

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showSessions}
        fullWidth
        maxWidth={"xl"}
      >
        <DialogTitle>
          <Typography variant="h6" noWrap component="div" maxWidth={510}>
            <span className="text-dark">Written Exam</span>

            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "5px" }}
              >
                chevron_right
              </span>
            </span>

            <span className="ml-0">
              {exam?.advertisement?.requisition?.position?.name}
              {exam && exam?.positionName}
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            height: "600px",
          }}
          dividers
        >
          <CardContent
            style={{
              // padding: ".6em",
              height: "auto",
              minHeight: "auto",
              overflow: "hidden",
            }}
          >
            <div className="row">
              <div className="col-lg-6">
                <Typography variant="h6" noWrap component="div">
                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    {searchTerm ? "Filtered Sessions" : "List of Sessions"}(
                    {totalRequests})
                  </span>
                  <span className="ml-4">
                    <ExamKey
                      disableCentersOption={disableCentersOption}
                      examId={exam.id}
                      examCenterId={examCenter?.id || 0}
                    />
                  </span>
                </Typography>
              </div>

              <div className="col-lg-6">
                <div className="d-flex justify-content-right  align-items-right">
                  <Autocomplete
                    className="mr-2"
                    size="small"
                    id="examCenter"
                    disabled={disableCentersOption}
                    defaultValue={null}
                    value={examCenter}
                    options={examCenters}
                    style={{ width: "300px" }}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    onChange={(event, examCenter) => {
                      setExamCenter(examCenter || null);
                      // setExamCenterId(examCenter?.id || '0');
                      // window.localStorage.setItem("examCenterId", examCenter?.id || '0');
                      const currentPage = 1;
                      setCurrentPage(currentPage);
                      const query = {
                        searchTerm: "",
                        page: currentPage,
                        size: itemPerPage,
                        examCenterId: examCenter?.id,
                      };
                      getApplicantExamSessions(
                        exam.id,
                        query,
                        setTotalRequests,
                        setIsLoading1
                      );
                    }}
                    getOptionLabel={(option) => option?.center?.name}
                    renderOption={(props, examCenter) => (
                      <Box component="li" {...props}>
                        {examCenter?.center?.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        disabled={disableCentersOption}
                        required={true}
                        {...params}
                        label="Select center"
                        placeholder="Select center"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "examCenter", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <SearchBox
                    onSearch={(search) => {
                      setSearchTerm(search);

                      if (!search) {
                        setDisableAutoRefresh(true);
                        const currentPage = 1;
                        setCurrentPage(currentPage);
                        const query = {
                          searchTerm: "",
                          page: currentPage,
                          size: itemPerPage,
                          examCenterId: examCenter?.id,
                        };
                        getApplicantExamSessions(
                          exam.id,
                          query,
                          setTotalRequests,
                          setIsLoading1
                        );
                      }
                    }}
                    placeholder="Search…"
                  />
                  <Button
                    disabled={!searchTerm}
                    onClick={() => onSearch()}
                    variant="contained"
                    size="small"
                    disableElevation
                    className="mr-2 "
                    style={{
                      borderRadius: "8px",
                      height: "38px",
                      border: `1px solid rgb(7, 142, 206)`,
                      color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      backgroundColor: "rgb(7, 142, 206)",
                      marginLeft: "5px",
                      "&:hover": {
                        backgroundColor: "#f0f2f5",
                      },
                    }}
                  >
                    <span className="material-icons">search</span>GO
                  </Button>
                  <Button
                    title="Reload"
                    // disabled={!examinerOralExamResults.length}
                    onClick={() => {
                      const query = {
                        searchTerm: searchTerm,
                        page: 1,
                        size: itemPerPage,
                        examCenterId: examCenter?.id,
                      };
                      getApplicantExamSessions(
                        exam.id,
                        query,
                        setTotalRequests,
                        setIsLoading1
                      );
                    }}
                    variant="contained"
                    size="small"
                    color="success"
                    disableElevation
                    className="mr-2 "
                    style={{
                      borderRadius: "8px",
                      height: "38px",
                    }}
                  >
                    <span className="material-icons">refresh</span>
                  </Button>
                  {/* <Button
                        onClick={() => onSearch()}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2 "
                        style={{
                          borderRadius: "8px",
                          height: "38px",
                          border: `1px solid rgb(7, 142, 206)`,
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "rgb(7, 142, 206)",
                          marginLeft: "5px",
                          "&:hover": {
                            backgroundColor: "#f0f2f5",
                          },
                        }}
                      >
                        <span className="material-icons">refresh</span>Refresh
                      </Button> */}
                </div>
              </div>
            </div>
          </CardContent>

          <div className="row mt-3">
            <div className="col-lg-12 mt-3">
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col" className="text-left">
                      Status
                    </th>
                    <th scope="col" className="text-left">
                      ApplicantId
                    </th>
                    <th scope="col" className="text-left">
                      SessionID
                    </th>

                    <th scope="col" className="text-left">
                      Names
                    </th>
                    <th scope="col" className="text-left">
                      Email
                    </th>

                    <th scope="col" className="text-left">
                      PhoneNumber
                    </th>
                    <th scope="col" className="text-left">
                      IDNumber
                    </th>

                    <th scope="col" className="text-left">
                      IP Address
                    </th>
                    <th scope="col" className="text-center">
                      StartedTime
                    </th>
                    <th scope="col" className="text-center">
                      SubmittedTime
                    </th>
                    <th scope="col" className="text-center">
                      Score
                    </th>
                    {!exam.isCanceled && (
                      <th scope="col" className="text-center">
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                {isLoading ? (
                  <TableSkeleton cols={12} />
                ) : (
                  <tbody>
                    <BackdropLoader isLoading={isLoading1} />
                    {applicantExamSessions.map((session, index) => (
                      <ApplicantExamSessionCard
                        session={session}
                        index={index}
                        key={index}
                        onChangeApplicantSession={onChangeApplicantSession}
                        onUnlockApplicantSession={onUnlockApplicantSession}
                        onMarkApplicantAsCheated={onMarkApplicantAsCheated}
                      />
                    ))}
                  </tbody>
                )}
              </table>
              <NoRecordMessage
                isEmpty={!applicantExamSessions.length && !isLoading}
                title="No record found"
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-1">
          {!isLoading && (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  // marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRequests / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!applicantExamSessions.length && (
                <select
                  className="custom-select custom-select-sm ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                      examCenterId: examCenter?.id,
                    };
                    getApplicantExamSessions(
                      exam.id,
                      query,
                      setTotalRequests,
                      setIsLoading
                    );
                  }}
                >
                  {[10, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          )}
        </DialogActions>

        {/* 
        {showUnlockSession && (
          <UnlockSessionExamDialog
            showUnlockSession={showUnlockSession}
            selectedSession={selectedSession}
            setUnlockSession={setUnlockSession}
          />
        )} */}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, applicantExamSessions, examCenters }) => {
  return {
    loading,
    applicantExamSessions,
    examCenters,
  };
};
export default connect(mapStateToProps, {
  getApplicantExamSessions,
  onChangeApplicantSession,
  onUnlockApplicantSession,
  onMarkApplicantAsCheated,
  getExamCenters,
})(ApplicantExamSessionsDialog);

const ApplicantExamSessionCard = (props) => {
  const {
    session,
    onChangeApplicantSession,
    onUnlockApplicantSession,
    onMarkApplicantAsCheated,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [reason, setReason] = useState("");
  const [showChangePCSession, setShowChangePCSession] = useState(false);
  const [showUnlockPCSession, setShowUnlockPCSession] = useState(false);
  const [showIsMarkedAsCheated, setShowIsMarkedAsCheated] = useState(false);

  const [showSessionAdditionalMinutes, setSessionAdditionalMinutes] =
    useState(false);

  const [showApplicantExamSessionLogs, setShowApplicantExamSessionLogs] =
    useState(false);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr>
        <th
          scope="row"
          className={`${
            ["Locked", "Cheated"].includes(session.statusName)
              ? "bg-danger"
              : session.statusName === "Active"
              ? "bg-primary"
              : session.statusName === "Submitted"
              ? "bg-success"
              : "bg-secondary"
          } text-white`}
        >
          {session.statusName}
        </th>

        <td className="text-left">{session.applicationId}</td>
        <td className="text-left">
          {session.sessionKey ? (
            session.sessionKey
          ) : (
            <>
              <span className="text-warning">Session inactive</span>
            </>
          )}
        </td>

        <td>
          <span className="text-left">
            {session.firstName} {session.lastName}
          </span>
        </td>
        <td className="text-left">{session.email}</td>
        <td className="text-left">{session.phoneNumber}</td>
        <td className="text-left">{session.idNumber}</td>
        <td className="text-left">{session.centerIPAddress}</td>
        <td className="text-center">
          {" "}
          {session.sessionStartedOn}
          {/* {session.sessionStartedOn
            ? moment(session.sessionStartedOn).format("ll")
            : ""} */}
        </td>
        <td className="text-center">
          {session.submittedOn}
          {/* {session.submittedOn ? moment(session.submittedOn).format("ll") : ""} */}
        </td>

        <td className="text-center">
          {session.score && session.submittedOn ? (
            <>
              <div
                className="text-center"
                style={{
                  margin: "0 auto",
                  padding: "2px",
                  width: "57px",
                  height: "auto",
                  border: `solid 3px ${
                    session.score >= 50 ? "#28a745" : "#dc3545"
                  }`,
                  borderRadius: "50px",
                  textAlign: "center",
                }}
              >
                {session.score} <PercentIcon style={{ fontSize: "13px" }} />
              </div>
            </>
          ) : (
            "N/A"
          )}
        </td>

        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span
                className={
                  session.isSessionLocked &&
                  session.isSessionActive &&
                  !session.submittedOn
                    ? "material-icons text-danger"
                    : "material-icons text-primary"
                }
              >
                more_vert
              </span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                maxHeight: ITEM_HEIGHT * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={
                  !hasPermissions(["IS_HR"]) || !!session.isMarkedAsCheated
                }
                onClick={() => {
                  handleCloseMenu();
                  setReason("");
                  setShowIsMarkedAsCheated(true);
                }}
                className="text-danger font-weight-light"
              >
                <i className="fas fa-unlock mr-2 text-danger"></i> Mark
                {!!session.isMarkedAsCheated && <>ed</>} as cheated
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={
                  !hasPermissions(["IS_HR", "IS_INVIGILATOR"]) ||
                  !session.isSessionLocked ||
                  !!session.isMarkedAsCheated
                }
                onClick={() => {
                  handleCloseMenu();
                  setReason("");
                  setShowUnlockPCSession(true);
                }}
                className="text-success font-weight-light"
              >
                <i className="fas fa-unlock mr-2 text-success"></i> Unlock PC
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={
                  !hasPermissions(["IS_HR", "IS_INVIGILATOR"]) ||
                  session.submittedOn ||
                  !session.sessionKey ||
                  !!session.isMarkedAsCheated
                }
                onClick={() => {
                  handleCloseMenu();
                  setReason("");
                  setShowChangePCSession(true);
                }}
                className="text-primary font-weight-light"
              >
                <i className="fas fa-unlock mr-2 text-primary"></i> Change PC
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowApplicantExamSessionLogs(true);
                }}
                className="text-dark font-weight-light"
              >
                <i className="fas fa-eye mr-2 text-primary"></i> View Logs
              </MenuItem>
              {hasPermissions(["IS_HR"]) && <Divider className="my-1" />}
            </span>
            {hasPermissions(["IS_HR"]) && (
              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setSessionAdditionalMinutes(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <i className="fas fa-plus mr-2 text-primary"></i> Additional
                  Minutes
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>
      {showApplicantExamSessionLogs && (
        <ApplicantExamSessionLogsDialog
          showApplicantExamSessionLogs={showApplicantExamSessionLogs}
          selectedSession={session}
          setShowApplicantExamSessionLogs={setShowApplicantExamSessionLogs}
        />
      )}

      {showSessionAdditionalMinutes && (
        <SessionAdditionalMinutesDialog
          showSessionAdditionalMinutes={showSessionAdditionalMinutes}
          selectedSession={session}
          setSessionAdditionalMinutes={setSessionAdditionalMinutes}
        />
      )}
      {showChangePCSession && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showChangePCSession}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-primary">change</strong> this session
                key of {session.firstName} {session.lastName}?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                autoFocus
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowChangePCSession}
          onYes={() => {
            onChangeApplicantSession(
              { sessionId: session.id, reason },
              setShowChangePCSession
            );
          }}
        />
      )}
      {showUnlockPCSession && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showUnlockPCSession}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-success">unlock</strong> PC of{" "}
                {session.firstName} {session.lastName}?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                autoFocus
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowUnlockPCSession}
          onYes={() => {
            onUnlockApplicantSession(
              { sessionId: session.id, reason },
              setShowUnlockPCSession
            );
          }}
        />
      )}

      {showIsMarkedAsCheated && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showIsMarkedAsCheated}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-success">mark</strong> this candidate(
                {session.firstName} {session.lastName}) as cheated?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                autoFocus
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowIsMarkedAsCheated}
          onYes={() => {
            onMarkApplicantAsCheated(
              { sessionId: session.id, reason },
              setShowIsMarkedAsCheated
            );
          }}
        />
      )}
    </>
  );
};
