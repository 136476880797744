import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  Typography,
  LinearProgress,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { showError } from "../../../app/toastify";
import { getAllEmployeeEmployments } from "../../../store/structure/actions";
import moment from "moment";
import {
  addRBMTemplateScores,
  getPerformanceBonusScores,
} from "../../../store/payroll/actions";

const AddEmployeeBonusResultDialog = (props) => {
  const {
    showAddEmployeeResultForm,
    setShowAddEmployeeResultForm,
    loading,
    user,
    onWait,
    selectedFiscalYear,
    performanceBonusScores,
    getAllEmployeeEmployments,
    addRBMTemplateScores,
    getPerformanceBonusScores,
  } = props;

  const [formData, setFormData] = useState({
    entitySectorId: user?.selectedEntitySector?.id || "",
    unitId: null,
    employeeGroupId: null,
    // level: null,
    employeePositionId: null,
    employeeId: "",
    lastName: "",
    firstName: "",
    fiscalYearId: selectedFiscalYear?.id || "",
    score: 0,
    rate: 0,
    months: 0,
    annualBasicSalary: 0,
    basicBonus: 0,
    wasAdded: false,
    category: "",
  });
  const [employments, setEmployments] = useState([]);

  const [errors, setErrors] = useState({
    employeeIdHasError: false,
    lastNameHasError: false,
    firstNameHasError: false,
    fiscalYearIdHasError: false,
    scoreHasError: false,
    rateHasError: false,
    monthsHasError: false,
    annualBasicSalaryHasError: false,
    employeePositionHasError: false,
    basicBonusHasError: false,
    categoryHasError: false,
    hasError: false,
  });

  const [requesting, setRequesting] = useState(false);

  const formValidator = () => {
    const error = {
      employeeIdHasError: false,
      lastNameHasError: false,
      firstNameHasError: false,
      fiscalYearIdHasError: false,
      scoreHasError: false,
      rateHasError: false,
      monthsHasError: false,
      annualBasicSalaryHasError: false,
      employeePositionHasError: false,
      basicBonusHasError: false,
      categoryHasError: false,
      hasError: false,
    };

    if (!formData.employeeId) {
      error.employeeIdHasError = true;
      error.hasError = true;
    }
    if (!formData.lastName) {
      error.lastNameHasError = true;
      error.hasError = true;
    }
    if (!formData.firstName) {
      error.firstNameHasError = true;
      error.hasError = true;
    }

    if (!formData.fiscalYearId) {
      error.fiscalYearIdHasError = true;
      error.hasError = true;
    }
    if (!formData.score) {
      error.scoreHasError = true;
      error.hasError = true;
    }
    if (!formData.rate) {
      error.rateHasError = true;
      error.hasError = true;
    }
    if (!formData.employeePositionId) {
      error.employeePositionHasError = true;
      error.hasError = true;
    }
    if (selectedFiscalYear.id === "2122" && !formData.months) {
      error.monthsHasError = true;
      error.hasError = true;
    }
    if (selectedFiscalYear.id === "2122" && !formData.annualBasicSalary) {
      error.annualBasicSalaryHasError = true;
      error.hasError = true;
    }
    if (selectedFiscalYear.id === "2122" && !formData.basicBonus) {
      error.basicBonusHasError = true;
      error.hasError = true;
    }
    if (!formData.category) {
      error.categoryHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    if (formData.score < 70) {
      showError("Score should be greater than 70");
      return true;
    }
    if (
      selectedFiscalYear.id === "2122" &&
      (formData.months > 12 || formData.months < 6)
    ) {
      showError("Months should be between 6 and 12");
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!!formData.score) {
      setFormData({
        ...formData,
        rate: +formData.score >= 80 ? 5 : +formData.score >= 60 ? 3 : 0,
        category:
          +formData.score >= 80
            ? "Indashyikirwa"
            : +formData.score >= 70
            ? "Inkomezamihigo"
            : +formData.score >= 60
            ? "Inkangurirwamihigo"
            : "Unspecified",
      });
    }
  }, [formData.score]);

  useEffect(() => {
    if (!!formData.annualBasicSalary && !!formData.rate && !!formData.months) {
      setFormData({
        ...formData,
        wasAdded: true,
      });
    }
  }, [formData.annualBasicSalary, formData.months, formData.rate]);

  useEffect(() => {
    if (formData.employeeId.length === 10) {
      if (
        !!performanceBonusScores.find(({ id }) => id === formData.employeeId)
      ) {
        showError("Employee already added");
      } else {
        getAllEmployeeEmployments(formData.employeeId, (status, data) => {
          setRequesting(status);
          if (!status && data && !data.user) {
            showError("Employee not found");
          }
          setEmployments(data?.employments || []);
          setFormData({
            ...formData,
            lastName: data?.user?.lastName || "",
            firstName: data?.user?.firstName || "",
          });
        });
      }
    }
  }, [formData.employeeId]);

  useEffect(() => {
    setFormData({
      ...formData,
      fiscalYearId: selectedFiscalYear?.id || "",
    });
  }, [showAddEmployeeResultForm, selectedFiscalYear]);

  const onClose = () => {
    setErrors({
      employeeIdHasError: false,
      lastNameHasError: false,
      firstNameHasError: false,
      fiscalYearIdHasError: false,
      scoreHasError: false,
      rateHasError: false,
      monthsHasError: false,
      annualBasicSalaryHasError: false,
      basicBonusHasError: false,
      categoryHasError: false,
      hasError: false,
    });
    setFormData({
      entitySectorId: user?.selectedEntitySector?.id || "",
      employeeId: "",
      lastName: "",
      firstName: "",
      fiscalYearId: selectedFiscalYear?.id || "",
      score: 0,
      rate: 0,
      months: 0,
      annualBasicSalary: 0,
      basicBonus: 0,
      wasAdded: false,
      category: "",
    });
    setShowAddEmployeeResultForm(false);
  };

  const onSubmit = () => {
    if (!formValidator()) {
      addRBMTemplateScores([formData], (status, res, error) => {
        setRequesting(status);
        if (res && !error) {
          getPerformanceBonusScores();
          onClose();
        } else if (error) {
          showError(
            error?.message || "Something went wrong, please try again later!"
          );
        }
      });
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAddEmployeeResultForm}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Add Employee Bonus for {selectedFiscalYear?.name}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        {(onWait || requesting) && <LinearProgress />}
        <DialogContent dividers>
          <div style={{ minHeight: "30vh" }}>
            <div className="row">
              <div className="col-12 mb-3 mt-1">
                <TextField
                  className="w-100"
                  error={errors.employeeIdHasError}
                  size="small"
                  label="Employee ID"
                  name="employeeId"
                  value={formData.employeeId}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      employeeId: e.target.value,
                    });
                    setErrors({
                      ...errors,
                      employeeIdHasError: false,
                      lastNameHasError: false,
                      firstNameHasError: false,
                    });
                  }}
                  disabled={onWait || requesting}
                />
              </div>

              <div className="col-12 mb-3">
                <TextField
                  className="w-100"
                  error={errors.lastNameHasError || errors.firstNameHasError}
                  size="small"
                  label="Full Name"
                  name="fullName"
                  value={formData.lastName + " " + formData.firstName}
                  disabled
                />
              </div>

              {!!employments.length && (
                <div className="col-12 mb-3">
                  <Autocomplete
                    size="small"
                    id="employeePosition"
                    defaultValue={null}
                    error={errors.employeePositionHasError}
                    value={
                      employments.find(
                        (e) => e.id === formData.employeePositionId
                      ) || null
                    }
                    options={employments}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, employeePosition) => {
                      setFormData({
                        ...formData,
                        employeePositionId: employeePosition?.id || null,
                        unitId: employeePosition?.unitId || null,
                        employeeGroupId:
                          employeePosition?.employeeGroupId || null,
                        // level: employeePosition?.levelId || null,
                      });
                      setErrors({ ...errors, employeePositionHasError: false });
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <div {...props} key={option.id}>
                        <span>
                          <span className="d-block text-primary">
                            {option.name}
                          </span>
                          <small className="d-block">
                            {!!option.isActing && (
                              <span className="text-primary font-weight-bold">
                                Ag.
                              </span>
                            )}{" "}
                            {option.employeeGroupId === 3 && (
                              <span className="text-muted">
                                - Under Contract
                              </span>
                            )}{" "}
                          </small>
                          <small className="d-block text-uppercase mb-1">
                            {moment(option.startDate).format("DD/MM/YYYY")} -{" "}
                            {option.endDate
                              ? moment(option.endDate).format("DD/MM/YYYY")
                              : "Present"}
                          </small>
                          <Divider />
                          <Divider />
                        </span>
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee Position"
                        inputProps={{
                          ...params.inputProps,
                          // autoComplete: "employeePosition", // disable autocomplete and autofill
                        }}
                      />
                    )}
                    disabled={onWait || requesting}
                  />
                </div>
              )}

              <div className="col-6 mb-3">
                <TextField
                  className="w-100"
                  error={errors.scoreHasError}
                  size="small"
                  name="score"
                  label="Score"
                  value={formData.score}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      score: +e.target.value,
                    });
                    setErrors({ ...errors, scoreHasError: false });
                  }}
                  disabled={onWait || requesting}
                />
              </div>

              <div className="col-6 mb-3">
                <TextField
                  className="w-100"
                  error={errors.rateHasError}
                  size="small"
                  name="rate"
                  label="Rate"
                  value={formData.rate}
                  disabled
                />
              </div>
              <div
                className={`mb-3 ${
                  selectedFiscalYear.id === "2122" ? "col-6" : "col-12"
                }`}
              >
                <TextField
                  className="w-100"
                  error={errors.categoryHasError}
                  size="small"
                  name="category"
                  label="Category"
                  value={formData.category}
                  disabled
                />
              </div>

              {selectedFiscalYear.id === "2122" && (
                <div className="col-6 mb-3">
                  <TextField
                    className="w-100"
                    error={errors.monthsHasError}
                    size="small"
                    name="months"
                    label="Months"
                    value={formData.months}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        months: +e.target.value,
                      });
                      setErrors({ ...errors, monthsHasError: false });
                    }}
                    disabled={onWait || requesting}
                  />
                </div>
              )}
              {selectedFiscalYear.id === "2122" && (
                <div className="col-6 mb-3">
                  <TextField
                    className="w-100"
                    error={errors.annualBasicSalaryHasError}
                    size="small"
                    name="annualBasicSalary"
                    label="Annual Basic Salary"
                    value={formData.annualBasicSalary}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        annualBasicSalary: +e.target.value,
                      });
                      setErrors({
                        ...errors,
                        annualBasicSalaryHasError: false,
                      });
                    }}
                    disabled={onWait || requesting}
                  />
                </div>
              )}
              {selectedFiscalYear.id === "2122" && (
                <div className="col-6 mb-3">
                  <TextField
                    className="w-100 text-center"
                    error={errors.basicBonusHasError}
                    size="small"
                    name="basicBonus"
                    label="Basic Bonus"
                    value={formData.basicBonus}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        basicBonus: +e.target.value,
                      });
                      setErrors({ ...errors, basicBonusHasError: false });
                    }}
                    disabled={onWait || requesting}
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSubmit}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading || onWait || requesting}
          >
            {onWait ? "Saving..." : "Add Bonus"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  performanceBonusScores,
  selectedFiscalYear,
}) => {
  return {
    loading,
    onWait,
    user,
    performanceBonusScores,
    selectedFiscalYear,
  };
};
export default connect(mapStateToProps, {
  getAllEmployeeEmployments,
  addRBMTemplateScores,
  getPerformanceBonusScores,
})(AddEmployeeBonusResultDialog);
