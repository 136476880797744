import {
  Tooltip,
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Divider,
  Chip,
  Badge,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import defaultProfile from "../../assets/default-profile.jpg";
import moment from "moment";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EvaluationDialog from "./EvaluationDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import {
  removeActivityAssignment,
  removeIndicatorAssignment,
  removeOutputAssignment,
} from "../../../store/rbm/actions";

const ActivityCard = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    activity,
    index,
    no,
    selectedSubordinate,
    removeActivityAssignment,
    removeIndicatorAssignment,
    removeOutputAssignment,
    isForMonitoring,
  } = props;
  const open = Boolean(anchorEl);
  const [assignmentToBeRemoved, setAssignmentToBeRemoved] = useState(null);
  const [confirmRemoveAssignment, setConfirmRemoveAssignment] = useState(false);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showEvaluationDialog, setShowEvaluationDialog] = useState(false);

  const weights = [
    { id: 3, name: "High" },
    { id: 2, name: "Medium" },
    { id: 1, name: "Low" },
    { id: 0, name: "Zero" },
  ];

  const handleRemoveAssignments = (assignment) => {
    if (assignment.itemTypeId === 1) {
      removeOutputAssignment(
        {
          outputId: assignment?.assignmentId,
          contractId: selectedSubordinate.contractId,
          quarterId: assignment.quarterId,
          statusId: assignment.statusId,
        },
        () => {
          setConfirmRemoveAssignment(false);
        }
      );
    } else if (assignment.itemTypeId === 2) {
      removeIndicatorAssignment(
        {
          employeePositionId: assignment.employeePositionId,
          indicatorId: assignment?.id,
          assignmentId: assignment?.assignmentId,
          contractId: selectedSubordinate.contractId,
          quarterId: assignment.quarterId,
          statusId: assignment.statusId,
        },
        setConfirmRemoveAssignment
      );
    } else if (assignment.itemTypeId === 3) {
      removeActivityAssignment(
        {
          id: assignment?.assignmentId,
          contractId: selectedSubordinate.contractId,
          quarterId: assignment.quarterId,
          statusId: assignment.statusId,
        },
        setConfirmRemoveAssignment
      );
    }
  };

  return (
    <>
      <Card
        className="mb-3 py-3 rounded hoverable-card"
        variant="outlined"
        style={{ backgroundColor: "#fff" }}
      >
        <CardHeader
          className="py-0"
          avatar={
            <Tooltip title="Score">
              <span
                style={{ fontSize: "12px", cursor: "default" }}
                className={`font-weight-bold  text-${
                  activity.score < 50
                    ? "danger"
                    : activity.score < 70
                    ? "warning"
                    : activity.score < 100
                    ? "info"
                    : "success"
                }`}
              >
                {activity.score.toFixed(2)} %
              </span>
            </Tooltip>
          }
          action={
            <>
              <Box>
                <IconButton
                  size="small"
                  className="ml-2"
                  onClick={handleOpenMenu}
                  aria-label="settings"
                >
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={activity.statusId !== 3}
                  >
                    <span className="material-icons">more_vert</span>
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem disabled>MENUS</MenuItem>

                  <span>
                    <Divider className="my-0" />
                    <MenuItem
                      onClick={() => {
                        handleCloseMenu();
                        setShowEvaluationDialog(true);
                      }}
                      className="text-dark font-weight-light"
                    >
                      <span className="material-icons mr-2">file_present</span>{" "}
                      Go to reports
                    </MenuItem>
                  </span>
                  {[1, 2].includes(activity.statusId) && !isForMonitoring && (
                    <span>
                      <Divider className="my-0" />
                      <MenuItem
                        onClick={() => {
                          handleCloseMenu();
                          setConfirmRemoveAssignment(true);
                          setAssignmentToBeRemoved(activity);
                        }}
                        className="text-danger font-weight-light"
                      >
                        <span className="material-icons-outlined text-danger mr-2">
                          delete
                        </span>{" "}
                        Remove Assignment
                      </MenuItem>
                    </span>
                  )}
                </Menu>
              </Box>
            </>
          }
          title={
            <>
              <span className="text-uppercase d-inline-flex align-items-center">
                <strong className="font-weight-light">
                  {" "}
                  {(index || no) + 1}:{activity.itemTypeName}
                </strong>
                <ArrowRightIcon className="text-primary" />
                {activity.name}{" "}
              </span>
            </>
          }
          subheader={
            <span>
              <span className="d-flex align-items-center justify-content-between flex-wrap">
                <span className="d-flex flex-wrap">
                  <Tooltip title="Weight">
                    <span
                      className="align-items-center"
                      style={{
                        cursor: "default",
                      }}
                    >
                      <span className="badge badge-pill text-dark px-0">
                        {weights.find(({ id }) => id === activity.weight)
                          ?.name || "n/a"}
                      </span>
                    </span>
                  </Tooltip>
                  <Tooltip title="Your target">
                    <span
                      className="align-items-center px-1 mr-2 text-info"
                      style={{
                        cursor: "default",
                      }}
                    >
                      Target: {activity.targetShare || "N/A"}
                      {activity.measurementUnit}
                      {(activity.achievedTarget > 0 ||
                        activity.targetShare > 0) && (
                        <span>
                          {" "}
                          |{" "}
                          <span
                            className={`badge badge-${
                              activity.achievedTarget ===
                                activity.targetShare && activity.targetShare > 0
                                ? "success"
                                : activity.achievedTarget > activity.targetShare
                                ? "success"
                                : "secondary"
                            }`}
                          >
                            {activity.achievedTarget} Achieved{" "}
                          </span>
                        </span>
                      )}
                    </span>
                  </Tooltip>
                  {!!activity.isRRAKey && (
                    <span
                      style={{ cursor: "default" }}
                      className="badge text-primary"
                    >
                      <i className="fas fa-key"></i> Key departmental priority
                    </span>
                  )}
                  <span className="d-flex align-items-center flex-wrap">
                    {activity?.assignments?.map((assignment) => (
                      <Tooltip
                        key={assignment.id}
                        title={`${assignment.lastName} ${assignment.firstName} assigned`}
                      >
                        <Chip
                          size="small"
                          className="border border-light mr-1"
                          avatar={
                            <Avatar
                              style={{ border: "1px solid #d1cbcb" }}
                              alt={assignment.firstName}
                              src={
                                assignment.profileImage
                                  ? `data:image/jpeg;base64,${assignment.profileImage}`
                                  : defaultProfile
                              }
                            />
                          }
                          label={assignment.firstName}
                          variant="outlined"
                        />
                      </Tooltip>
                    ))}
                  </span>
                </span>

                <span className="d-flex flex-wrap ">
                  <span className="text-info ">
                    Deadline: {moment(activity.endOn).format("ll")}
                    <span>
                      <span
                        className={`d-flex align-items-center justify-content-center badge mx-4 badge-${
                          activity.statusId === 1
                            ? "secondary"
                            : activity.statusId === 3
                            ? "primary"
                            : activity.statusId === 4
                            ? "success"
                            : "danger"
                        }`}
                      >
                        <span
                          style={{ fontSize: "17px" }}
                          className="material-icons "
                        >
                          {activity.statusId === 1 && <>pending_actions</>}
                          {activity.statusId === 3 && <>file_present</>}
                          {activity.statusId === 4 && <>done_all</>}
                          {activity.statusId === 5 && <>close</>}
                          {activity.statusId === 2 && <>error</>}
                        </span>
                        <span style={{ fontSize: "10px" }}>
                          {activity.statusName}
                          {activity.statusId === 1 && <>...</>}
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          }
        />
      </Card>

      {showEvaluationDialog && (
        <EvaluationDialog
          assignment={activity}
          showDialog={showEvaluationDialog}
          setShowDialog={setShowEvaluationDialog}
          index={index}
          no={no}
          selectedSubordinate={selectedSubordinate}
        />
      )}

      {confirmRemoveAssignment && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveAssignment}
          message={`Are you sure you want to remove this assignment?`}
          setConfirmationDialog={setConfirmRemoveAssignment}
          onYes={() => handleRemoveAssignments(assignmentToBeRemoved)}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {
  removeActivityAssignment,
  removeIndicatorAssignment,
  removeOutputAssignment,
})(ActivityCard);
