import React, { useState } from "react";
import { CopyAll as CopyIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";

const CopyToClipboard = ({ value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <span>{value}</span>
      <IconButton
        aria-label="Copy to clipboard"
        size="small"
        onClick={handleCopy}
        className="ml-2"
        title="Copy to clipboard"
        disabled={copied}
      >
        <CopyIcon fontSize="small" />
      </IconButton>
    </div>
  );
};

export default CopyToClipboard;
