import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  loadPeriodicTempPayrolls,
  loadPeriodicArchPayrolls,
} from "../../../store/admin/actions";
import TableSkeleton from "../../common/components/TableSkeleton";
import PayrollCard from "../../payroll/components/PayrollCard";
import ReactPaginate from "react-paginate";

const PayrollOversight = (props) => {
  const {
    selectedPeriod,
    payrollType,
    filteredData,
    // redux
    loading,
    payrollOversight,
    loadPeriodicTempPayrolls,
    loadPeriodicArchPayrolls,
  } = props;
  const [loadingOversight, setLoadingOversight] = useState(true);

  useEffect(() => {
    if (payrollType === "Temp") {
      if (loadingOversight) {
        loadPeriodicTempPayrolls("all", setLoadingOversight);
      } else {
        setLoadingOversight(false);
      }
    } else if (payrollType === "Archived") {
      if (payrollOversight === null || payrollOversight.length === 0) {
        loadPeriodicArchPayrolls(
          selectedPeriod ? selectedPeriod.periodId : 0,
          setLoadingOversight
        );
      } else if ((selectedPeriod && !loadingOversight) || loadingOversight) {
        loadPeriodicArchPayrolls(
          selectedPeriod ? selectedPeriod.periodId : 0,
          setLoadingOversight
        );
      } else {
        setLoadingOversight(false);
      }
    }
    return () => {
      setLoadingOversight(true);
    };
  }, [selectedPeriod, payrollType]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(filteredData);
  }, [itemOffset, itemsPerPage, filteredData, filteredData]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredData.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (idToSearch) => {
    return filteredData.findIndex(({ id }) => id === idToSearch) + 1;
  };

  return (
    <div
      // className="mb-3"
      style={{
        marginLeft: "1em",
        marginRight: "1em",
        backgroundColor: "#fff",
        borderRadius: "0.5rem",
        boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
        MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
        height: "70vh",
        overflowY: "auto",
      }}
    >
      <table className="table-striped table-hover table-sm w-100">
        <thead>
          <tr>
            <th>#</th>
            <th>Entity </th>
            <th
              className="px-1 text-center"
              colSpan={2}
              style={{
                backgroundColor: "#e5f6fd",
                borderBottom: "none",
                borderTop: "none",
                borderLeft: "1px solid #dee2e6",
                borderRight: "1px solid #dee2e6",
              }}
            >
              DECLARATION
            </th>
            <th className="px-1">Tin Number</th>
            <th className="px-1">Period</th>
            <th className="px-1">Payroll Type</th>
            <th className="px-1">Cost Center</th>
            <th className="px-1">Payroll Group</th>
            <th className="px-1">Source Of Funds</th>
            <th className="px-1 text-right">Total Salary</th>
            <th className="px-1 text-right">Total Lumpsum</th>
            <th className="px-1 text-right">Total Paid</th>
            <th className="px-1">Last Processing Time</th>
            <th className="px-1 text-center">Employees</th>
            <th className="pl-1 text-center">Status</th>
            <th className="px-1">Actions</th>
          </tr>
        </thead>

        {loadingOversight ? (
          <TableSkeleton cols={17} rows={16} />
        ) : (
          <tbody>
            {paginatedItems.length ? (
              paginatedItems.map((payroll, index) => (
                <PayrollCard
                  key={payroll.id}
                  payrollIndex={getNo(payroll.id)}
                  payroll={payroll}
                  type={payrollType}
                  viewType="admin"
                />
              ))
            ) : (
              <tr>
                <td
                  colSpan={17}
                  align="center"
                  className="py-3 font-weight-bold"
                >
                  No data found!
                </td>
              </tr>
            )}
          </tbody>
        )}
      </table>
      <div
        className="justify-content-center mt-2"
        style={{ overflow: "hidden" }}
      >
        {!!filteredData.length && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
            activeLinkClassName="active"
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ loading, adminPayroll }) => {
  return {
    loading,
    payrollOversight: adminPayroll.adminPayrollOversight,
  };
};

const mapDispatchToProps = {
  loadPeriodicTempPayrolls,
  loadPeriodicArchPayrolls,
};
export default connect(mapStateToProps, mapDispatchToProps)(PayrollOversight);
