import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  MenuItem,
  Divider,
  Badge,
  IconButton,
  Menu,
} from "@mui/material";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import moment from "moment";
import { getInvigilatorWrittenExams } from "../../../store/recruitment/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import SensorsIcon from "@mui/icons-material/Sensors";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Attandance from "../components/Attandance";
import AssessmentIcon from "@mui/icons-material/Iso";
import ApplicantExamSessionsDialog from "../components/ApplicantExamSessionsDialog";
import TableSkeleton from "../../common/components/TableSkeleton";
import { doesExamEnded, doesExamStarted } from "../../common/components/Utils";
import WrittenExamMarking from "../components/WrittenExamMarking";
import ManageWrittenExamQuestionDialog from "../components/ManageWrittenExamQuestionDialog";
const ITEM_HEIGHT = 60;
const InvigilatorExamCard = (props) => {
  const {
    index,
    setSelectedWrittenExam,
    writtenExam,
    setShowAttandanceLists,
    setShowSessions,
    setShowUploadWrittenExamQuestionsForm,
    setShowWrittenExamMarking,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <td className="text-left">
          {" "}
          {writtenExam.entityName}({" "}
          <strong className="text-primary text-truncate">
            {writtenExam.unitName}
          </strong>{" "}
          )
        </td>
        <td className="text-left"> {writtenExam.positionName}</td>
        <td className="text-left">{writtenExam.centerName}</td>
        <td className="text-left">
          {" "}
          {writtenExam.seatingDate
            ? moment(writtenExam.seatingDate).format("MMMM Do YYYY")
            : ""}
        </td>
        <td className="text-center"> {writtenExam.durationMin} min</td>
        <td className="text-center">
          {writtenExam.startTime
            ? moment(
                writtenExam.seatingDate.split("T")[0] +
                  " " +
                  writtenExam.startTime
              ).format("HH:mm A")
            : ""}
        </td>
        <td className="text-center">
          {writtenExam.endTime
            ? moment(
                writtenExam.seatingDate.split("T")[0] +
                  " " +
                  writtenExam.endTime
              ).format("HH:mm A")
            : ""}
        </td>

        <td className="text-center">
          {writtenExam.isCanceled ? (
            <span className="px-1 py-1 badge  badge-danger">Canceled</span>
          ) : doesExamStarted(writtenExam) && !!writtenExam.isActive ? (
            <span className="px-1 py-1 badge  badge-primary">
              Exam Inprogress
            </span>
          ) : doesExamEnded(writtenExam) && !!writtenExam.isActive ? (
            <span className="px-1 py-1 badge  badge-warning">Exam Ended</span>
          ) : writtenExam.wasExamMarkingPublished ? (
            <span className="px-1 py-1 badge  badge-success">
              Exam Marking Published
            </span>
          ) : (
            <span className="px-1 py-1 badge  badge-secondary">Scheduled</span>
          )}
        </td>

        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                maxHeight: ITEM_HEIGHT * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {!writtenExam.isCanceled && (
              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setSelectedWrittenExam(writtenExam);
                    setShowAttandanceLists(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <PersonAddAlt1Icon className="mr-2 text-primary" />
                  Attendance
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {writtenExam.isExaminer && (
              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setSelectedWrittenExam(writtenExam);
                    setShowUploadWrittenExamQuestionsForm(true);
                  }}
                  className="font-weight-light"
                >
                  <LibraryBooksIcon className="mr-2 text-primary" />
                  Exam Questions
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}
            {writtenExam.isExaminer && (
              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setSelectedWrittenExam(writtenExam);
                    setShowWrittenExamMarking(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <AssessmentIcon className="mr-2 text-primary" /> Marking
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedWrittenExam(writtenExam);
                  setShowSessions(true);
                }}
                className="text-dark font-weight-light"
              >
                <SensorsIcon className="mr-2 text-primary" /> Sessions
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>
    </>
  );
};

const InvigilatorWrittenExam = (props) => {
  const { user, loading, invigilatorWrittenExams, getInvigilatorWrittenExams } =
    props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const [showAttandanceLists, setShowAttandanceLists] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const [selectedWrittenExam, setSelectedWrittenExam] = useState(null);
  const [filteredInvigilatorWrittenExams, setFilteredInvigilatorWrittenExams] =
    useState([...invigilatorWrittenExams]);
  const [
    showUploadWrittenExamQuestionsForm,
    setShowUploadWrittenExamQuestionsForm,
  ] = useState(false);
  const [showWrittenExamMarking, setShowWrittenExamMarking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getInvigilatorWrittenExams(setIsLoading);
  }, []);

  useEffect(() => {
    const filtered = [...invigilatorWrittenExams].filter(
      ({ positionName, unitName, venue }) =>
        positionName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        unitName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        venue.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredInvigilatorWrittenExams(filtered);
    paginate(filtered);
  }, [searchTeam, invigilatorWrittenExams]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 5;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(filteredInvigilatorWrittenExams);
  }, [
    itemOffset,
    itemsPerPage,
    invigilatorWrittenExams,
    filteredInvigilatorWrittenExams,
  ]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
        filteredInvigilatorWrittenExams.length || 0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-8">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  // sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span>RECRUITMENT</span>
                  <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                    <span
                      className="material-icons  text-dark"
                      style={{ position: "relative", top: "4px" }}
                    >
                      chevron_right
                    </span>
                  </span>

                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    Invigilator Exams({filteredInvigilatorWrittenExams.length})
                  </span>
                </Typography>
              </div>

              <div className="col-lg-4">
                <SearchBox onSearch={onSearch} placeholder="Search…" />
              </div>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>

                    <th scope="col" className="text-left">
                      InstitutionName
                    </th>
                    <th scope="col" className="text-left">
                      Test Name
                    </th>

                    <th scope="col" className="text-left">
                      Center
                    </th>
                    <th scope="col" className="text-left">
                      Seating Date
                    </th>
                    <th scope="col" className="text-center">
                      Duration
                    </th>

                    <th scope="col" className="text-center">
                      Start Time
                    </th>

                    <th scope="col" className="text-center">
                      End Time
                    </th>

                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>

                {isLoading ? (
                  <TableSkeleton cols={10} />
                ) : (
                  <tbody>
                    {paginatedItems.map((invigilatorWrittenExam, index) => (
                      <InvigilatorExamCard
                        writtenExam={invigilatorWrittenExam}
                        setSelectedWrittenExam={setSelectedWrittenExam}
                        setShowAttandanceLists={setShowAttandanceLists}
                        setShowSessions={setShowSessions}
                        setShowUploadWrittenExamQuestionsForm={
                          setShowUploadWrittenExamQuestionsForm
                        }
                        setShowWrittenExamMarking={setShowWrittenExamMarking}
                        index={index}
                        key={index}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <NoRecordMessage
                isEmpty={!filteredInvigilatorWrittenExams.length && !isLoading}
                title={"No Written Exam found"}
              />
            </CardContent>
          </div>
          {showAttandanceLists && (
            <Attandance
              showAttandanceLists={showAttandanceLists}
              selectedExam={selectedWrittenExam}
              examTypeId="1"
              setShowAttandanceLists={setShowAttandanceLists}
            />
          )}
          {showSessions && (
            <ApplicantExamSessionsDialog
              exam={selectedWrittenExam}
              showSessions={showSessions}
              setShowSessions={setShowSessions}
              disableCentersOption={!!selectedWrittenExam?.examCenterId}
              center={{
                id: selectedWrittenExam?.examCenterId || 0,
                center: {
                  name: selectedWrittenExam?.centerName || "",
                  id: selectedWrittenExam?.centerId || "",
                },
              }}
            />
          )}

          {showUploadWrittenExamQuestionsForm && (
            <ManageWrittenExamQuestionDialog
              exam={selectedWrittenExam}
              selectedAdvertisement={selectedWrittenExam}
              showUploadWrittenExamQuestionsForm={
                showUploadWrittenExamQuestionsForm
              }
              setShowUploadWrittenExamQuestionsForm={
                setShowUploadWrittenExamQuestionsForm
              }
            />
          )}
          {showWrittenExamMarking && (
            <WrittenExamMarking
              exam={selectedWrittenExam}
              showWrittenExamMarking={showWrittenExamMarking}
              setShowWrittenExamMarking={setShowWrittenExamMarking}
            />
          )}
          <div
            className="justify-content-center mb-3"
            style={{ overflow: "hidden" }}
          >
            {!!paginatedItems.length &&
              invigilatorWrittenExams.length >= itemsPerPage && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              )}
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, invigilatorWrittenExams }) => {
  return { user, loading, invigilatorWrittenExams };
};
export default connect(mapStateToProps, {
  getInvigilatorWrittenExams,
})(InvigilatorWrittenExam);
