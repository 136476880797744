import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getPermissions, deletePermission } from "../../../store/admin/actions";
import { IconButton, Button, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddOrUpdatePermissionDialog from "./AddOrUpdatePermissionDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const Permission = (props) => {
  const { user, loading, permissions, getPermissions, deletePermission } =
    props;
  const [showPermissionForm, setShowPermissionForm] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState("");

  const [confirmRemovePermission, setConfirmRemovePermission] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    getPermissions();
  }, []);

  return (
    <div>
      <div className="col-lg-12 mb-2">
        <Button
          onClick={() => setShowPermissionForm(true)}
          style={{
            ...ActiveButton,
            padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
            float: "right",
            marginBottom: "10px",
            marginRight: "-12px",
          }}
          size="sm"
        >
          <span className="material-icons">add</span> New
        </Button>

        {showPermissionForm && (
          <AddOrUpdatePermissionDialog
            selectedPermission={selectedPermission}
            showPermissionForm={showPermissionForm}
            setShowPermissionForm={setShowPermissionForm}
          />
        )}
        {confirmRemovePermission && (
          <ConfirmationDialog
            confirmationDialog={confirmRemovePermission}
            message={`Are you sure you want to remove this permission @${selectedPermission.name}?`}
            setConfirmationDialog={setConfirmRemovePermission}
            onYes={() => {
              deletePermission(
                selectedPermission,
                setConfirmRemovePermission,
                setSelectedPermission
              );
            }}
          />
        )}
      </div>
      <table className="table table-bordered mt-2">
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>Name</th>
            <th style={{ textAlign: "left" }}>Roles</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {permissions.map((permission, index) => (
            <React.Fragment key={index}>
              {permission.name !== "IS_ADMIN" && (
                <tr>
                  <td style={{ textAlign: "left" }}>{permission.name}</td>

                  <td style={{ textAlign: "left" }}>
                    {permission.roles &&
                      !!permission.roles.length &&
                      permission.roles.map((role, index2) => (
                        <React.Fragment key={index2}>
                          <Chip
                            color="primary"
                            label={role.name}
                            variant="outlined"
                            style={{ margin: "5px" }}
                            size="small"
                          />
                        </React.Fragment>
                      ))}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => {
                        setSelectedPermission(permission);
                        setConfirmRemovePermission(true);
                      }}
                      className=" mr-2"
                    >
                      <span className="material-icons">delete</span>
                    </IconButton>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = ({ user, loading, permissions }) => {
  return { user, loading, permissions };
};
export default connect(mapStateToProps, { getPermissions, deletePermission })(
  Permission
);
