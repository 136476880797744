const types = {
  SET_KNOWLEDGE_DOMAINS: "SET_KNOWLEDGE_DOMAINS",
  ADD_OR_UPDATE_KNOWLEDGE_DOMAIN: "ADD_OR_UPDATE_KNOWLEDGE_DOMAIN",

  SET_KNOWLEDGE_SUB_DOMAINS: "SET_KNOWLEDGE_SUB_DOMAINS",
  ADD_OR_UPDATE_KNOWLEDGE_SUB_DOMAIN: "ADD_OR_UPDATE_KNOWLEDGE_SUB_DOMAIN",
  SET_PSYCHOMETRIC_LANGUAGES: "SET_PSYCHOMETRIC_LANGUAGES",
  SET_POSITION_KNOWLEDGE_SUBDOMAINS: "SET_POSITION_KNOWLEDGE_SUBDOMAINS",
  SET_POSITION_PSYCHOMETRIC_LANGUAGES: "SET_POSITION_PSYCHOMETRIC_LANGUAGES",

  DELETE_POSITION_KNOWLEDGE_SUBDOMAIN: "DELETE_POSITION_KNOWLEDGE_SUBDOMAIN",
  DELETE_POSITION_PSYCHOMETRIC_LANGUAGE:
    "DELETE_POSITION_PSYCHOMETRIC_LANGUAGE",

  SAVE_POSITION_KNOWLEDGE_SUBDOMAIN: "SAVE_POSITION_KNOWLEDGE_SUBDOMAIN",
  SAVE_POSITION_PSYCHOMETRIC_LANGUAGE: "SAVE_POSITION_PSYCHOMETRIC_LANGUAGE",

  SET_QUESTIONBANK_QUESTIONS: "SET_QUESTIONBANK_QUESTIONS",
  ADD_OR_UPDATE_QUESTIONBANK_QUESTION: "ADD_OR_UPDATE_QUESTIONBANK_QUESTION",
  DELETE_QUESTIONBANK_QUESTION: "DELETE_QUESTIONBANK_QUESTION",
  SET_QUESTIONBANK_KNOWLEDGE_DOMAINS: "SET_QUESTIONBANK_KNOWLEDGE_DOMAINS",

  SET_KNOWLEDGE_LEVELS: "SET_KNOWLEDGE_LEVELS",
  ADD_OR_UPDATE_KNOWLEDGE_LEVEL: "ADD_OR_UPDATE_KNOWLEDGE_LEVEL",

  SET_TEMP_QUESTIONBANK_QUESTIONS: "SET_TEMP_QUESTIONBANK_QUESTIONS",
  ADD_OR_UPDATE_TEMP_QUESTIONBANK_QUESTION:
    "ADD_OR_UPDATE_TEMP_QUESTIONBANK_QUESTION",
  DELETE_TEMP_QUESTIONBANK_QUESTION: "DELETE_TEMP_QUESTIONBANK_QUESTION",
  SET_ACTIVE_CANDIDATES: "SET_ACTIVE_CANDIDATES",
  ADD_OR_UPDATE_ACTIVE_CANDIDATE: "ADD_OR_UPDATE_ACTIVE_CANDIDATE",
  DELETE_ACTIVE_CANDIDATE: "DELETE_ACTIVE_CANDIDATE",

  SET_APPLICANT_SESSION_LOGS: "SET_APPLICANT_SESSION_LOGS",
  SET_DASHBOARD_CHART_DATA: "SET_DASHBOARD_CHART_DATA",

  SET_REQUISITION_PSYCHOMETRIC_LANGUAGES:
    "SET_REQUISITION_PSYCHOMETRIC_LANGUAGES",
  SET_REQUISITION_KNOWLEDGE_SUBDOMAINS: "SET_REQUISITION_KNOWLEDGE_SUBDOMAINS",

  SET_QUESTION_LOGS: "SET_QUESTION_LOGS",
};

export default types;
