import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Tabs,
  Tab,
  Checkbox,
  TextField,
  Autocomplete,
  Card,
  CardHeader,
  Link,
  Popover,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Avatar,
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InfoIcon from "@mui/icons-material/Info";
import defaultPofile from "../../assets/default-profile.jpg";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  removeDuplicates,
  requestStatusColor,
  textCapitalized,
} from "../../common/components/Utils";
import moment from "moment";
import ErrorMessage from "../../common/components/ErrorMessage";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

import { a11yProps } from "../../common/components/TabPanel";
import { getHeadOfInstitution } from "../../../store/structure/actions";
import { getUserDetails } from "../../../store/profile/actions";
import ViewCaseLogs from "./ViewCaseLogs";
import ViewCaseAttachments from "./ViewCaseAttachments";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import {
  getFaultWithSanction,
  updateCaseFault,
  removeCaseFault,
  updateCaseDesc,
  cancelCaseRequest,
  reviewCaseRequest,
  reportCaseToTheCommittee,
  onConfirmToCloseCase,
} from "../../../store/discipline/actions";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ViewExplanationLetterDialog from "./ViewExplanationLetterDialog";
import PreviewPdfUrl from "../../common/components/PreviewPdfUrl";
import ViewSanctionLetterDialog from "./ViewSanctionLetterDialog";
import CommitteeReportFormDialog from "./CommitteeReportFormDialog";
import ViewProvidingExplanationFeedbackLetterDialog from "./ViewProvidingExplanationFeedbackLetterDialog";
import RequestProvisionSuspension from "./RequestProvisionSuspension";
import { showError } from "../../toastify";

const ViewCaseDialog = (props) => {
  const {
    user,
    showCaseDetail,
    setShowCaseDetail,
    caseRequest,
    updateCaseFault,
    getFaultWithSanction,
    removeCaseFault,
    updateCaseDesc,
    cancelCaseRequest,
    reviewCaseRequest,
    reportCaseToTheCommittee,
    faults,
    onConfirmToCloseCase,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [showAddFaultForm, setShowAddFaultForm] = useState(false);
  const [showRemoveFaultForm, setShowRemoveFaultForm] = useState(false);
  const [showCaseDescForm, setShowCaseDescForm] = useState(false);
  const [showCaseReportingForm, setShowCaseReportingForm] = useState(false);

  const [caseDescription, setCaseDescription] = useState(
    caseRequest?.request?.caseDescription || null
  );

  const [showFault, setShowFault] = useState(null);
  const [tmpFaults, setTmpFaults] = useState([]);
  const [isEditable, setIsEditable] = useState(
    (hasPermissions(["IS_HEAD_OF_UNIT"]) &&
      [1].includes(caseRequest?.request?.caseStatusId) &&
      caseRequest?.request?.decisionLevel === 1) ||
      (hasPermissions(["IS_HR", "IS_DOCUMENT_VERIFIER"]) &&
        [1].includes(caseRequest?.request?.caseStatusId) &&
        caseRequest?.request?.decisionLevel === 2)
      ? true
      : false
  );

  const [filteredFaults, setFilteredFaults] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (!faults.length) getFaultWithSanction();
  }, []);

  useEffect(() => {
    if (!!faults.length) {
      let _tmpFaults = [];
      faults.forEach((fault) => {
        if (!caseRequest?.faults.find(({ faultId }) => fault.id === faultId)) {
          _tmpFaults.push(fault);
        }
      });
      setFilteredFaults(_tmpFaults);
    }
  }, [caseRequest, faults]);

  useEffect(() => {
    setCaseDescription(caseRequest?.request?.caseDescription || null);
    setIsEditable(
      (hasPermissions(["IS_HEAD_OF_UNIT"]) &&
        [1].includes(caseRequest?.request?.caseStatusId) &&
        caseRequest?.request?.decisionLevel === 1) ||
        (hasPermissions(["IS_HR", "IS_DOCUMENT_VERIFIER"]) &&
          [1].includes(caseRequest?.request?.caseStatusId) &&
          caseRequest?.request?.decisionLevel === 2)
        ? true
        : false
    );
  }, [caseRequest]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onClose = () => {
    setShowCaseDetail(false);
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCaseDetail}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <div className="row">
            <div className="col-9">
              <h5>
                View Case #{caseRequest?.request?.id} -{" "}
                <span
                  className={`badge py-1 px-1 badge-${requestStatusColor(
                    [
                      {
                        className: "success",
                        statusIds: [11, 13],
                      },
                      {
                        className: "danger",
                        statusIds: [4],
                      },

                      {
                        className: "primary",
                        statusIds: [3, 5, 8],
                      },
                      {
                        className: "warning",
                        statusIds: [2, 6, 9, 10, 12],
                      },

                      {
                        className: "secondary",
                        statusIds: [1, 6, 7],
                      },
                    ],
                    caseRequest.request.caseStatusId
                  )}`}
                >
                  {caseRequest.request.caseStatusName}
                </span>{" "}
              </h5>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col"></div>
                <div className="col">
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 2,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <span className="material-icons">close</span>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col">
                  <Tabs
                    className="mt-2"
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="tabs"
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className="text-capitalize"
                      label="Details"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="text-capitalize"
                      label="Attachments
             "
                      {...a11yProps(1)}
                    />

                    <Tab
                      className="text-capitalize"
                      label="Comments"
                      {...a11yProps(2)}
                    />
                    <Tab
                      className="text-capitalize"
                      label="Administrative letters"
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </div>
                <div className="col">
                  {/* setShowCaseReportingForm */}
                  {!!caseRequest.request
                    .isProvisionSuspensionLetterRequired && (
                    <>
                      <span className="badge badge-primary text-right float-right my-3 mx-2">
                        Provisionally suspension requested
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="mt-0 mx-4" dividers>
          {tabValue === 0 && (
            <Box sx={{ width: "100%", minHeight: "80vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <div className="card service-card valid mb-2 mt-1">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      I. Identification
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-2">
                          <div className="row mt-0">
                            <div className="col-3 justify-content-center text-uppercase text-center">
                              <div
                                className="card mb-4 pb-2"
                                style={{
                                  backgroundColor: "#fff",
                                  backgroundClip: "border-box",
                                  border: "1px solid rgba(0,0,0,0.175)",
                                  borderRadius: "0.5rem",
                                  boxShadow:
                                    " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                                }}
                              >
                                <div className="card-body text-center">
                                  <div
                                    style={{
                                      height: "auto",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <>
                                      {caseRequest?.profilePicture && (
                                        <img
                                          className="mt-0 rounded  text-center"
                                          src={
                                            "data:image/jpg;base64," +
                                            caseRequest?.profilePicture
                                          }
                                          style={{
                                            border: "1px solid #a6d3df",
                                            height: "128px",
                                            // width: "130px",
                                            overflow: "hidden",
                                            // borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                      {!caseRequest?.profilePicture && (
                                        <img
                                          className="mt-0  rounded text-center"
                                          src={defaultPofile}
                                          style={{
                                            // border: "1px solid #a6d3df",
                                            height: "128px",
                                            width: "100px",
                                            overflow: "hidden",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      )}
                                    </>
                                  </div>

                                  {caseRequest?.request?.isAvailable && (
                                    <span
                                      className="text-success font-weight-bolder"
                                      style={{
                                        position: "relative",
                                        top: "10px",
                                      }}
                                    >
                                      <span
                                        className="material-icons d-block"
                                        style={{ fontSize: "48px" }}
                                      >
                                        check_circle_outline
                                      </span>
                                      <span
                                        style={{ fontSize: "12px" }}
                                        className="mt-n1 d-block"
                                      >
                                        Available
                                      </span>
                                    </span>
                                  )}
                                  {!caseRequest?.request?.isAvailable && (
                                    <>
                                      <span
                                        className="text-danger font-weight-bolder"
                                        style={{
                                          position: "relative",
                                          top: "10px",
                                        }}
                                      >
                                        <span
                                          className="material-icons d-block"
                                          style={{ fontSize: "48px" }}
                                        >
                                          highlight_off
                                        </span>
                                        <span
                                          style={{ fontSize: "12px" }}
                                          className="mt-n1 d-block"
                                        >
                                          {caseRequest?.request
                                            ?.employeeStatusId === 1 ? (
                                            <>Suspended</>
                                          ) : (
                                            <>Exited</>
                                          )}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-9">
                              <div
                                className="card"
                                style={{
                                  backgroundColor: "#fff",
                                  backgroundClip: "border-box",
                                  border: "1px solid rgba(0,0,0,0.175)",
                                  borderRadius: "0.5rem",
                                  boxShadow:
                                    " 0 2px 15px -3px rgba(0,0,0,0.07),0 10px 20px -2px rgba(0,0,0,0.04)",
                                }}
                              >
                                <div className="card-body text-left">
                                  <div className="row">
                                    <div className="col-6">
                                      <div
                                        className="user-contact"
                                        style={{ padding: "0px!important" }}
                                      >
                                        <div className="">
                                          <div>
                                            <p className="contact_p">
                                              {" "}
                                              Employee Id:{" "}
                                              <span className="contact_span">
                                                {
                                                  caseRequest?.request
                                                    ?.employeeId
                                                }{" "}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Names:{" "}
                                              <span className="contact_span">
                                                {" "}
                                                {caseRequest?.request
                                                  ?.employeeTitle
                                                  ? " ." +
                                                    caseRequest?.request
                                                      ?.employeeTitle
                                                  : ""}
                                                <span className="ml-1">
                                                  {textCapitalized(
                                                    caseRequest?.request
                                                      ?.lastName
                                                  )}{" "}
                                                  {textCapitalized(
                                                    caseRequest?.request
                                                      ?.firstName
                                                  )}
                                                </span>
                                              </span>
                                            </p>

                                            <p className="contact_p">
                                              {" "}
                                              DOB:{" "}
                                              <span className="contact_span">
                                                {" "}
                                                {moment(
                                                  caseRequest?.request
                                                    ?.dateOfBirth
                                                ).format("ll")}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              National Id:{" "}
                                              <span className="contact_span">
                                                {caseRequest?.request?.idNumber}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Gender:{" "}
                                              <span className="contact_span">
                                                {caseRequest?.request
                                                  ?.gender === "M"
                                                  ? "Male"
                                                  : "Female"}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Civil Status:{" "}
                                              <span className="contact_span">
                                                {
                                                  caseRequest?.request
                                                    ?.civilStatus
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div
                                        className="user-contact"
                                        style={{ padding: "0px!important" }}
                                      >
                                        <div className="">
                                          <div>
                                            <p className="contact_p">
                                              {" "}
                                              PhoneNumber:{" "}
                                              <span className="contact_span">
                                                {
                                                  caseRequest?.request
                                                    ?.phoneNumber
                                                }{" "}
                                              </span>
                                            </p>

                                            <p className="contact_p">
                                              {" "}
                                              Personal Email:{" "}
                                              <span
                                                className="contact_span"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {caseRequest?.request?.email}
                                              </span>
                                            </p>
                                            <p className="contact_p">
                                              {" "}
                                              Other Email:{" "}
                                              <span
                                                className="contact_span"
                                                style={{ fontSize: "12px" }}
                                              >
                                                {
                                                  caseRequest?.request
                                                    ?.professionalEmail
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Card
                                className={`rounded hoverable-card mb-2 mt-2 py-2`}
                                variant="outlined"
                                sx={{
                                  backgroundColor: `#cdeef8`,
                                  border: "none",
                                }}
                              >
                                <CardHeader
                                  className="pb-0 pt-0"
                                  avatar={
                                    <Tooltip
                                      title={
                                        <>
                                          {caseRequest?.request?.employeeTitle
                                            ? " ." +
                                              caseRequest?.request
                                                ?.employeeTitle
                                            : ""}
                                          <span className="ml-1">
                                            {textCapitalized(
                                              caseRequest?.request?.lastName
                                            )}
                                            {textCapitalized(
                                              caseRequest?.request?.firstName
                                            )}
                                          </span>
                                        </>
                                      }
                                    >
                                      <Box
                                        sx={{
                                          position: "relative",
                                          display: "inline-flex",
                                          cursor: "default",
                                        }}
                                      >
                                        <span className="material-icons text-primary mr-1">
                                          info
                                        </span>
                                      </Box>
                                    </Tooltip>
                                  }
                                  title={
                                    <span className="d-flex align-items-center flex-wrap justify-content-between ">
                                      <span className="d-flex align-items-center flex-wrap">
                                        <span className="">
                                          <span className="d-flex align-items-center  flex-wrap">
                                            <span
                                              className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-primary mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalCases || 0}
                                              </span>
                                              All cases
                                            </span>

                                            <span
                                              className={`d-flex align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-warning mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalOngoing || 0}
                                              </span>
                                              Ongoing
                                            </span>
                                            <span
                                              className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                // borderRight: "2px solid #ccd0d6",
                                                // borderLeft: "2px solid #ccd0d6",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-success mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalSanctioned || 0}
                                              </span>
                                              Sanctioned Cases
                                            </span>

                                            <span
                                              className={`d-flex  align-items-center px-1 mx-1  text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                // borderRight: "2px solid #ccd0d6",
                                                // borderLeft: "2px solid #ccd0d6",
                                                borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-secondary mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalClosed || 0}
                                              </span>
                                              Closed Cases
                                            </span>

                                            <span
                                              className={`d-flex align-items-center px-1 mx-1 text-dark text-uppercase badge badge-light`}
                                              style={{
                                                cursor: "default",
                                                // borderRight: "2px solid #ccd0d6",
                                                // borderLeft: "2px solid #ccd0d6",
                                                // borderRadius: "8px",
                                              }}
                                            >
                                              <span
                                                className={`badge badge-danger mr-1`}
                                              >
                                                {caseRequest?.request
                                                  ?.totalRejected || 0}
                                              </span>{" "}
                                              Rejected
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  }
                                />
                              </Card>
                            </div>
                          </div>
                          {caseRequest?.request?.hasPendingExitRequest && (
                            <ErrorMessage
                              hasError={true}
                              message={<>Employee has pending exist request!</>}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Unit
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {caseRequest?.request?.unitName}
                            </div>
                          </fieldset>

                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Job position
                            </legend>
                            <div
                              className="form-group1 mb-0"
                              style={{ marginTop: "-5px" }}
                            >
                              {caseRequest?.request?.positionName} (
                              {caseRequest?.request?.levelName}.
                              {caseRequest?.request?.scaleName})
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header  d-flex justify-content-between align-items-center"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      II. Faults
                      {isEditable && (
                        <IconButton
                          disabled={!isEditable}
                          size="small"
                          edge="end"
                          color="primary"
                          onClick={() => {
                            setShowAddFaultForm(true);
                          }}
                        >
                          <span className="material-icons">add</span>
                        </IconButton>
                      )}
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <table className="table-striped table-hover table-sm  fixTableHead">
                            <thead>
                              <tr style={{ backgroundColor: "#f0f2f5" }}>
                                <th scope="col" className="text-left">
                                  Fault
                                </th>

                                <th scope="col" className="text-left">
                                  Category
                                </th>

                                <th scope="col" className="text-left">
                                  Sanction
                                </th>
                                {isEditable && (
                                  <th scope="col" className="text-right">
                                    <span className="mr-3">Actions</span>
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {caseRequest?.faults.map((fault, index) => (
                                <tr key={index}>
                                  <td className="text-left">
                                    <strong> {fault.faultName} </strong>
                                  </td>
                                  <td>{fault.faultCategoryName} Level</td>
                                  <td>{fault.faultSanctionName}</td>
                                  {isEditable && (
                                    <td className="text-right">
                                      <IconButton
                                        size="small"
                                        edge="end"
                                        color="error"
                                        onClick={() => {
                                          setShowFault(fault);
                                          setShowRemoveFaultForm(true);
                                        }}
                                      >
                                        <span className="material-icons">
                                          delete
                                        </span>
                                      </IconButton>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card service-card valid mb-2 mt-3">
                    <div
                      className="card-header"
                      style={{ padding: "0.25rem 1.2rem" }}
                    >
                      III. Description
                    </div>
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ marginTop: "-5px" }}
                      >
                        {caseDescription}
                        {isEditable && (
                          <Button variant="outlined" size="small">
                            <IconButton
                              disabled={!isEditable}
                              size="small"
                              edge="end"
                              color="primary"
                              onClick={() => {
                                setShowCaseDescForm(true);
                              }}
                            >
                              <span className="material-icons">edit</span>
                            </IconButton>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {caseRequest?.request?.reportCommitteeDate && (
                    <>
                      <div className="card service-card valid mb-2 mt-3">
                        <div
                          className="card-header"
                          style={{ padding: "0.25rem 1.2rem" }}
                        >
                          IV. Report of internal disciplinary committee summary
                        </div>
                        <div className="card-body">
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Effected date
                            </legend>
                            <div
                              className="d-flex justify-content-between align-items-center"
                              style={{ marginTop: "-5px" }}
                            >
                              {moment(
                                caseRequest?.request?.reportCommitteeDate ||
                                  null
                              ).format("ll")}
                            </div>
                          </fieldset>
                          <fieldset className="form-group border p-2">
                            <legend
                              className="w-auto px-2"
                              style={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontFamily:
                                  '"Roboto","Helvetica","Arial",sans-serif',
                                fontWeight: 400,
                                fontSize: "0.82rem",
                                // lineHeight: "1em",
                                letterSpacing: "0.00938em",
                              }}
                            >
                              Summary of the conclusions of the committee
                            </legend>
                            <div
                              className="d-flex justify-content-between align-items-center"
                              style={{ marginTop: "-5px" }}
                            >
                              {caseRequest?.request?.reportCommitteeSummary ||
                                ""}
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </>
                  )}
                </Typography>
              </React.Fragment>
            </Box>
          )}
          {tabValue === 1 && (
            <Box sx={{ width: "100%", minHeight: "80vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <ViewCaseAttachments
                    caseId={caseRequest?.request?.id}
                    canEdit={isEditable}
                  />
                </Typography>
              </React.Fragment>
            </Box>
          )}

          {tabValue === 2 && (
            <Box sx={{ width: "100%", minHeight: "80vh" }} className="mt-1">
              <React.Fragment>
                <Typography component={"div"} sx={{ mt: 0, mb: 1 }}>
                  <ViewCaseLogs logs={caseRequest.logs} />
                </Typography>
              </React.Fragment>
            </Box>
          )}
          {[3].includes(tabValue) && (
            <Box sx={{ width: "100%", minHeight: "80vh" }} className="mt-1">
              <div className="card service-card valid mb-2 mt-3">
                <div
                  className="card-header"
                  style={{ padding: "0.25rem 1.2rem" }}
                >
                  Administrative letters
                </div>
                <div className="card-body">
                  {!!caseRequest?.request?.requestExplanationDocRefId && (
                    <ViewExplanationLetterCard
                      caseRequest={caseRequest}
                      faults={faults}
                      onClose={onClose}
                      hasPermissions={hasPermissions}
                      cancelCaseRequest={cancelCaseRequest}
                      reviewCaseRequest={reviewCaseRequest}
                    />
                  )}
                  {!!caseRequest?.request?.employeeResponseDocRefId && (
                    <ViewResponseToExplanationLetterCard
                      caseRequest={caseRequest}
                    />
                  )}
                  {!!caseRequest?.request?.provisionalSuspensionDocRefNo && (
                    <ProvisionalSuspensionCard
                      caseRequest={caseRequest}
                      onClose={onClose}
                      hasPermissions={hasPermissions}
                      user={user}
                    />
                  )}
                  {!!caseRequest?.request?.explanationFeedbackDocRefNo && (
                    <ProvideFeedBackLetterCard
                      caseRequest={caseRequest}
                      onClose={onClose}
                      hasPermissions={hasPermissions}
                      user={user}
                    />
                  )}

                  {!!caseRequest?.request?.sanctionDocReferenceNo && (
                    <SanctionLetterCard
                      caseRequest={caseRequest}
                      onClose={onClose}
                      hasPermissions={hasPermissions}
                    />
                  )}
                </div>
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions className="py-3 mx-0 row justify-content-center">
          <div className="col-12">
            {!!hasPermissions(["IS_HR"]) &&
              [5].includes(caseRequest?.request?.caseStatusId) && (
                <ViewDecisionCard
                  caseRequest={caseRequest}
                  faults={faults}
                  onClose={onClose}
                  reportCaseToTheCommittee={reportCaseToTheCommittee}
                />
              )}
            {!!hasPermissions(["IS_HR"]) &&
              [8].includes(caseRequest?.request?.caseStatusId) && (
                <ViewCommitteeDecisionCard
                  caseRequest={caseRequest}
                  faults={faults}
                  onClose={onClose}
                  reportCaseToTheCommittee={reportCaseToTheCommittee}
                />
              )}

            {[9, 10].includes(caseRequest?.request?.caseStatusId) && (
              <SanctionLetterCard
                caseRequest={caseRequest}
                faults={faults}
                onClose={onClose}
                hasPermissions={hasPermissions}
              />
            )}
            {[6, 7].includes(caseRequest?.request?.caseStatusId) &&
              !caseRequest?.request?.provisionalSuspensionDocRefNo && (
                <div className="row w-100">
                  <div className="col-12">
                    <ProvisionalSuspensionCard
                      caseRequest={caseRequest}
                      onClose={onClose}
                      hasPermissions={hasPermissions}
                      user={user}
                    />
                  </div>
                </div>
              )}

            {[6, 7].includes(caseRequest?.request?.caseStatusId) && (
              <div className="row w-100">
                <div className="col-12">
                  <ProvideFeedBackLetterCard
                    caseRequest={caseRequest}
                    onClose={onClose}
                    hasPermissions={hasPermissions}
                    user={user}
                  />
                </div>
              </div>
            )}
            {[12].includes(caseRequest?.request?.caseStatusId) && (
              <CloseCaseCard
                caseRequest={caseRequest}
                onClose={onClose}
                hasPermissions={hasPermissions}
                user={user}
                onConfirmToCloseCase={onConfirmToCloseCase}
              />
            )}

            {!caseRequest?.request?.requestExplanationDocRefId && (
              <ViewExplanationLetterCard
                caseRequest={caseRequest}
                faults={faults}
                onClose={onClose}
                hasPermissions={hasPermissions}
                cancelCaseRequest={cancelCaseRequest}
                reviewCaseRequest={reviewCaseRequest}
              />
            )}
          </div>
        </DialogActions>
        {showAddFaultForm && (
          <ConfirmationDialog
            disabled={!tmpFaults.length}
            confirmationDialog={showAddFaultForm}
            size="lg"
            title="Add Fault"
            message={
              <>
                <div style={{ width: "400px" }}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    id="tmpFaults"
                    className="w-100"
                    options={filteredFaults.map(
                      ({ faultName, sanction, id }) => ({
                        faultName,
                        sanction,
                        id,
                      })
                    )}
                    value={tmpFaults}
                    onChange={(e, fault) => {
                      const uniqueFaults = removeDuplicates(fault);
                      setTmpFaults(uniqueFaults);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.faultName}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} className="w-100">
                        <div className="row w-100">
                          <div className="col-10">
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              checked={
                                selected ||
                                !!tmpFaults.find(({ id }) => id === option.id)
                              }
                            />
                            {option.faultName}{" "}
                          </div>
                          <div className="col-2">
                            <span className="badge badge-primary text-right float-right">
                              {option.sanction}
                            </span>
                          </div>
                        </div>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        width="w-100"
                        {...params}
                        label={"Select the employee faults"}
                        placeholder={"Select the employee faults"}
                      />
                    )}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowAddFaultForm}
            onYes={() => {
              updateCaseFault(
                {
                  caseId: caseRequest?.request?.id,
                  faults: tmpFaults,
                },
                caseRequest,
                setShowAddFaultForm,
                setTmpFaults
              );
            }}
          />
        )}
        {showRemoveFaultForm && (
          <ConfirmationDialog
            confirmationDialog={showRemoveFaultForm}
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong className="text-danger">remove</strong> this fault @
                  {showFault.faultName}?
                </p>
              </>
            }
            setConfirmationDialog={setShowRemoveFaultForm}
            onYes={() => {
              removeCaseFault(
                {
                  caseFaultId: showFault.id,
                },
                caseRequest,
                setShowRemoveFaultForm
              );
            }}
          />
        )}
        {showCaseDescForm && (
          <ConfirmationDialog
            confirmationDialog={showCaseDescForm}
            disabled={!caseDescription}
            size="lg"
            message={
              <>
                {" "}
                <div style={{ width: "300px" }}>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="caseDescription"
                    label="Case Description .."
                    variant="outlined"
                    placeholder="Case Description .."
                    value={caseDescription}
                    onChange={(e) => {
                      setCaseDescription(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowCaseDescForm}
            onYes={() => {
              updateCaseDesc(
                {
                  caseId: caseRequest?.request?.id,
                  caseDescription: caseDescription,
                },
                caseRequest,
                setShowCaseDescForm
              );
            }}
          />
        )}
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  caseRequest,
  headOfInstitution,
  faults,
}) => {
  return {
    user,
    loading,
    caseRequest,
    headOfInstitution,
    faults,
  };
};
export default connect(mapStateToProps, {
  getHeadOfInstitution,
  getUserDetails,
  getFaultWithSanction,
  updateCaseFault,
  removeCaseFault,
  updateCaseDesc,
  cancelCaseRequest,
  reviewCaseRequest,
  reportCaseToTheCommittee,
  onConfirmToCloseCase,
})(ViewCaseDialog);

const ViewExplanationLetterCard = (props) => {
  const {
    caseRequest,
    faults,
    onClose,
    hasPermissions,
    cancelCaseRequest,
    reviewCaseRequest,
  } = props;

  const [showExplanationLetter, setShowExplanationLetter] = useState(false);
  const [enableToFillLetter, setEnableToFillLetter] = useState(false);
  const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  const [showCaseReviewerStatusId, setShowCaseReviewerStatusId] = useState(0);
  const [caseLogStatusId, setCaseLogStatusId] = useState(0);
  const [showAction, setShowAction] = useState("");

  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] =
    useState(false);
  const [showCaseReviewerForm, setShowCaseReviewerForm] = useState(false);
  const [reason, setReason] = useState("");

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-primary`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong>
                  {!!caseRequest?.request?.requestExplanationDocRefId ? (
                    <>
                      {" "}
                      Letter requesting an employee to provide a written
                      explanation on the administrative faults.
                    </>
                  ) : (
                    <>
                      <Link
                        onClick={() => {
                          setShowExplanationLetter(true);
                          setEnableToFillLetter(false);
                        }}
                      >
                        Letter requesting an employee to provide a written
                        explanation on the administrative faults.
                      </Link>
                    </>
                  )}
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {caseRequest?.request?.requestExplanationDocRefId && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request
                            ?.requestExplanationDocRefId
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}

                  {/* {!!hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                    [1].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <div className="mr-1">
                          <Button
                            variant="contained"
                            color="primary"
                            className="mr-2"
                            startIcon={
                              <span className="material-icons">edit</span>
                            }
                            onClick={() => {
                              setShowExplanationLetter(true);
                              setEnableToFillLetter(true);
                              setShowAction('EDIT');
                            }}
                          >
                            Edit
                          </Button>

                          <Button
                            variant="contained"
                            color="warning"
                            startIcon={
                              <span className="material-icons">cancel</span>
                            }
                            onClick={() =>
                              setShowCancelConfirmationDialog(true)
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </>
                    )} */}
                  {/* {!!hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                    caseRequest?.request?.decisionLevel === 2 &&
                    [1].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-2"
                          startIcon={
                            <span className="material-icons-outlined">
                              task_alt
                            </span>
                          }
                          onClick={() => {
                            setShowExplanationLetter(true);
                            setEnableToFillLetter(true);
                            setShowCaseReviewerStatusId(2);
                            setShowAction("REVIEW");
                          }}
                        >
                          {" "}
                          Confirm
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          startIcon={
                            <span className="material-icons">cancel</span>
                          }
                          onClick={() => {
                            setShowCaseReviewerStatusId(10);
                            setShowCaseReviewerForm(true);
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )} */}

                  {!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                    caseRequest?.request?.decisionLevel === 2 &&
                    [1].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          className="mr-2"
                          startIcon={
                            <span className="material-icons-outlined">
                              task_alt
                            </span>
                          }
                          onClick={() => {
                            setShowExplanationLetter(true);
                            setEnableToFillLetter(true);
                            setShowCaseReviewerStatusId(2);
                            setCaseLogStatusId(3);
                            setShowAction("VERIFY");
                          }}
                        >
                          {" "}
                          Confirm
                        </Button>
                        <Button
                          variant="contained"
                          color="warning"
                          startIcon={
                            <span className="material-icons">cancel</span>
                          }
                          onClick={() => {
                            setShowCaseReviewerStatusId(4);
                            setShowCaseReviewerForm(true);
                            setCaseLogStatusId(6);
                          }}
                        >
                          Reject
                        </Button>
                      </>
                    )}

                  {((hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                    [2].includes(caseRequest?.request?.caseStatusId) &&
                    caseRequest?.request?.decisionLevel === 2) ||
                    (hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                      [1].includes(caseRequest?.request?.caseStatusId) &&
                      caseRequest?.request?.decisionLevel === 1)) && (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        className="mr-2"
                        startIcon={
                          <span className="material-icons-outlined">
                            task_alt
                          </span>
                        }
                        onClick={() => {
                          setShowExplanationLetter(true);
                          setEnableToFillLetter(true);
                          setShowCaseReviewerStatusId(3);
                          setCaseLogStatusId(5);
                          setShowAction("APPROVE");
                        }}
                      >
                        {" "}
                        Approve
                      </Button>

                      {hasPermissions(["IS_HEAD_OF_INSTITUTION"]) && (
                        <Button
                          variant="contained"
                          color="warning"
                          startIcon={
                            <span className="material-icons">cancel</span>
                          }
                          onClick={() => {
                            setShowCaseReviewerStatusId(4);
                            setShowCaseReviewerForm(true);
                            setCaseLogStatusId(6);
                          }}
                        >
                          Reject
                        </Button>
                      )}
                    </>
                  )}
                  {((hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                    [1].includes(caseRequest?.request?.caseStatusId) &&
                    caseRequest?.request?.decisionLevel === 1) ||
                    (hasPermissions(["IS_HR"]) &&
                      [1].includes(caseRequest?.request?.caseStatusId) &&
                      caseRequest?.request?.decisionLevel === 2)) && (
                    <Button
                      variant="contained"
                      color="warning"
                      startIcon={<span className="material-icons">cancel</span>}
                      onClick={() => setShowCancelConfirmationDialog(true)}
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `Letter requesting an employee to provide a written explanation on the administrative faults.`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.requestExplanationDocRefId,
            }}
          />
        )}
        {showExplanationLetter && caseRequest && (
          <ViewExplanationLetterDialog
            showExplanationLetter={showExplanationLetter}
            setShowExplanationLetter={setShowExplanationLetter}
            caseRequest={caseRequest?.request || null}
            caseRequestFaults={caseRequest?.faults || []}
            enableToFillLetter={enableToFillLetter}
            faults={faults}
            action={showAction}
            actionStatusId={showCaseReviewerStatusId}
            caseLogStatusId={caseLogStatusId}
            closeModal={onClose}
          />
        )}

        {showCancelConfirmationDialog && (
          <ConfirmationDialog
            confirmationDialog={showCancelConfirmationDialog}
            message={
              <>
                {" "}
                Are you sure you want to{" "}
                <strong className="text-danger">cancel</strong> the case for{" "}
                <span className="contact_span">
                  {" "}
                  {caseRequest?.request?.employeeTitle
                    ? " ." + caseRequest?.request?.employeeTitle
                    : ""}
                  <span className="ml-1">
                    {textCapitalized(caseRequest?.request?.lastName)}{" "}
                    {textCapitalized(caseRequest?.request?.firstName)}
                  </span>
                </span>
              </>
            }
            setConfirmationDialog={setShowCancelConfirmationDialog}
            onYes={() => {
              cancelCaseRequest(caseRequest?.request.id, onClose);
            }}
          />
        )}

        {showCaseReviewerForm && (
          <ConfirmationDialog
            confirmationDialog={showCaseReviewerForm}
            disabled={!reason}
            size="lg"
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong
                    className={`text-${
                      showCaseReviewerStatusId === 4 ? "danger" : "success"
                    }`}
                  >
                    {showCaseReviewerStatusId === 4
                      ? "reject"
                      : "confirm your review"}
                  </strong>{" "}
                  this case?
                </p>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowCaseReviewerForm}
            onYes={() => {
              reviewCaseRequest(
                {
                  caseId: caseRequest?.request?.id,
                  reason: reason,
                  caseStatusId: showCaseReviewerStatusId,
                  caseLogStatusId: caseLogStatusId,
                },
                onClose
              );
            }}
          />
        )}
      </div>
    </>
  );
};

const ViewResponseToExplanationLetterCard = (props) => {
  const { caseRequest } = props;

  const [onViewProvDocument, setOnViewProvDocument] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded card border-primary hoverable-card`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong
                  className={
                    caseRequest?.request?.employeeResponseDocRefId
                      ? ""
                      : "text-primary"
                  }
                >
                  Letter from the employee providing a response to the requested
                  explanation on the suspected disciplinary faults.
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {caseRequest?.request?.employeeResponseDocRefId && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request
                            ?.employeeResponseDocRefId
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `Letter from the employee providing a response to the requested explanation on the suspected disciplinary faults.`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.employeeResponseDocRefId,
            }}
          />
        )}
      </div>
    </>
  );
};

const ViewDecisionCard = (props) => {
  const { caseRequest, faults, onClose } = props;
  const [filteredFaults, setFilteredFaults] = useState([]);
  const [currentSanction, setCurrentSanction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [isSentToDisciplinaryCommittee, setIsSentToDisciplinaryCommittee] =
    useState(caseRequest?.request?.isSentToDisciplinaryCommittee || -1);
  const [
    isProvisionSuspensionLetterRequired,
    setIsProvisionSuspensionLetterRequired,
  ] = useState(-1);
  const [showSanctionLetter, setShowSanctionLetter] = useState(false);
  const [reason, setReason] = useState("");
  const [showCaseReportingForm, setShowCaseReportingForm] = useState(false);

  const handleGiveSanction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseGiveSanction = () => {
    setAnchorEl(null);
  };

  const handleReportToCommittee = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleCloseReportToCommittee = () => {
    setAnchorEl1(null);
  };

  useEffect(() => {
    if (!!faults.length) {
      let _tmpFaults = [];
      faults.forEach((fault) => {
        if (
          !!caseRequest?.faults?.find(({ faultId }) => fault.id === faultId)
        ) {
          if (
            !_tmpFaults?.find(
              ({ faultSanctionId }) => fault.faultSanctionId === faultSanctionId
            )
          ) {
            _tmpFaults.push(fault);
          }
        }
      });
      setFilteredFaults(_tmpFaults);
    }
  }, [faults]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const open1 = Boolean(anchorEl1);
  const id1 = open1 ? "simple-popover" : undefined;

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        {showSanctionLetter && (
          <ViewSanctionLetterDialog
            showSanctionLetter={showSanctionLetter}
            setShowSanctionLetter={setShowSanctionLetter}
            isSentToDisciplinaryCommittee={isSentToDisciplinaryCommittee}
            currentSanction={{
              id: currentSanction.faultSanctionId,
              name: currentSanction.sanction,
            }}
            caseRequest={caseRequest?.request || null}
            enableToFillLetter={true}
            action={"EDIT"}
            closeModal={onClose}
            actionStatusId={11}
          />
        )}
        {showCaseReportingForm && (
          <ViewProvidingExplanationFeedbackLetterDialog
            showCaseReportingForm={showCaseReportingForm}
            setShowCaseReportingForm={setShowCaseReportingForm}
            faults={filteredFaults}
            caseRequest={caseRequest?.request || null}
            enableToFillLetter={true}
            action={"EDIT"}
            closeModal={onClose}
            isProvisionSuspensionLetterRequired={true}
            actionStatusId={14}
          />
        )}

        <Card
          className={`mb-2 w-100 rounded card border-primary hoverable-card`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <InfoIcon />
              </span>
            }
            title={
              <Typography>
                <strong className={"text-primary"}>
                  After the employee has responded, please either give a
                  sanction or report for investigation.
                </strong>
              </Typography>
            }
            action={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  aria-describedby={id}
                  onClick={handleGiveSanction}
                  style={{ marginRight: "10px" }}
                >
                  Give Sanction
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseGiveSanction}
                  anchorOrigin={{
                    vertical: "left",
                    horizontal: "top",
                  }}
                >
                  <Card sx={{ maxWidth: 400, minWidth: 200 }}>
                    <CardHeader
                      sx={true}
                      action={
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseGiveSanction}
                        >
                          <span className="material-icons">close</span>
                        </IconButton>
                      }
                      subheader="Sanction(s)"
                    />
                    <Divider />
                    <CardContent>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          // defaultValue="female"
                          name="radio-buttons-group"
                        >
                          {filteredFaults.map((fault, index) => (
                            <FormControlLabel
                              key={index + fault.faultSanctionId}
                              value={fault.faultSanctionId}
                              control={<Radio />}
                              label={fault.sanction}
                              onChange={() => setCurrentSanction(fault)}
                              checked={currentSanction === fault}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </CardContent>
                    {currentSanction && (
                      <CardActions
                        disableSpacing
                        className="py-3 d-flex justify-content-center"
                      >
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setIsSentToDisciplinaryCommittee(false);
                            setShowSanctionLetter(true);
                            handleCloseGiveSanction();
                          }}
                        >
                          <TaskAltIcon /> Confirm
                        </Button>
                      </CardActions>
                    )}
                  </Card>
                </Popover>
                {/* <Button
                  variant="contained"
                  color="warning"
                  onClick={handleReportToCommittee}
                >
                  Report to the Committee
                </Button> */}

                <Popover
                  id={id1}
                  open={open1}
                  anchorEl={anchorEl1}
                  onClose={handleCloseReportToCommittee}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Card sx={{ maxWidth: 400, minWidth: 200 }}>
                    <CardHeader
                      sx={true}
                      action={
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseReportToCommittee}
                        >
                          <span className="material-icons">close</span>
                        </IconButton>
                      }
                      subheader="Report to the committee  for investigation"
                    />
                    <Divider />
                    <CardContent>
                      <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">
                          <strong className="text-primary">
                            Do you need to suspend an employee provisionally for
                            an administrative investigation?
                          </strong>
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="no"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            control={<Radio />}
                            label="Yes"
                            value={1}
                            onChange={() =>
                              setIsProvisionSuspensionLetterRequired(1)
                            }
                            checked={isProvisionSuspensionLetterRequired === 1}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="No"
                            value={0}
                            onChange={() =>
                              setIsProvisionSuspensionLetterRequired(0)
                            }
                            checked={isProvisionSuspensionLetterRequired === 0}
                          />
                        </RadioGroup>
                      </FormControl>
                    </CardContent>
                    {isProvisionSuspensionLetterRequired >= 0 && (
                      <CardActions
                        disableSpacing
                        className="py-3 d-flex justify-content-center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setShowCaseReportingForm(true);
                            handleCloseReportToCommittee();
                          }}
                        >
                          <TaskAltIcon /> Continue
                        </Button>
                      </CardActions>
                    )}
                  </Card>
                </Popover>
              </>
            }
          />
        </Card>
      </div>
    </>
  );
};

const SanctionLetterCard = (props) => {
  const { caseRequest, onClose, hasPermissions } = props;

  const [showSanctionLetter, setShowSanctionLetter] = useState(false);
  const [enableToFillLetter, setEnableToFillLetter] = useState(false);
  const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  const [showCaseReviewerStatusId, setShowCaseReviewerStatusId] = useState(0);
  const [showAction, setShowAction] = useState("");

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-${
            !!caseRequest?.request?.sanctionDocReferenceNo
              ? "success"
              : "primary"
          }`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong>
                  {!!caseRequest?.request?.sanctionDocReferenceNo ? (
                    <>
                      {" "}
                      View {caseRequest?.request?.caseSanctionName || ""} Letter
                    </>
                  ) : (
                    <>
                      <Link
                        onClick={() => {
                          setShowSanctionLetter(true);
                          setEnableToFillLetter(false);
                        }}
                      >
                        Prepared {caseRequest?.request?.caseSanctionName || ""}{" "}
                        letter
                      </Link>
                    </>
                  )}
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {caseRequest?.request?.sanctionDocReferenceNo && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request?.sanctionDocReferenceNo
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}

                  {/* {!!hasPermissions(["IS_HR"]) &&
                    [11].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <div className="mr-1">
                          <Button
                            variant="contained"
                            color="primary"
                            className="mr-2"
                            startIcon={
                              <span className="material-icons">edit</span>
                            }
                            onClick={() => {
                              setShowAction("EDIT");
                              setShowSanctionLetter(true);
                              setEnableToFillLetter(true);
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      </>
                    )} */}
                  {/* {!!hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                    caseRequest?.request?.decisionLevel === 2 &&
                    [11].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-2"
                          startIcon={
                            <span className="material-icons-outlined">
                              task_alt
                            </span>
                          }
                          onClick={() => {
                            setShowSanctionLetter(true);
                            setEnableToFillLetter(true);
                            setShowCaseReviewerStatusId(11);
                          }}
                        >
                          {" "}
                          Confirm
                        </Button>
                      </>
                    )} */}

                  {!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                    // caseRequest?.request?.decisionLevel === 2 &&
                    [9].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          className="mr-2"
                          startIcon={
                            <span className="material-icons-outlined">
                              task_alt
                            </span>
                          }
                          onClick={() => {
                            setShowSanctionLetter(true);
                            setEnableToFillLetter(true);
                            setShowCaseReviewerStatusId(10);
                            setShowAction("VERIFY");
                          }}
                        >
                          {" "}
                          Confirm
                        </Button>
                      </>
                    )}

                  {hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                    [10].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          className="mr-2"
                          startIcon={
                            <span className="material-icons-outlined">
                              task_alt
                            </span>
                          }
                          onClick={() => {
                            setShowSanctionLetter(true);
                            setEnableToFillLetter(true);
                            setShowCaseReviewerStatusId(11);
                            setShowAction("APPROVE");
                          }}
                        >
                          {" "}
                          Approve
                        </Button>
                      </>
                    )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `View ${
                caseRequest?.request?.caseSanctionName || ""
              } letter`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.sanctionDocReferenceNo,
            }}
          />
        )}
        {showSanctionLetter && (
          <ViewSanctionLetterDialog
            showSanctionLetter={showSanctionLetter}
            setShowSanctionLetter={setShowSanctionLetter}
            isSentToDisciplinaryCommittee={
              caseRequest?.request?.isSentToDisciplinaryCommittee || false
            }
            currentSanction={{
              id: caseRequest?.request?.caseSanctionId || "",
              name: caseRequest?.request?.caseSanctionName || "",
            }}
            caseRequest={caseRequest?.request || null}
            enableToFillLetter={enableToFillLetter}
            action={showAction}
            closeModal={onClose}
            actionStatusId={showCaseReviewerStatusId}
          />
        )}
      </div>
    </>
  );
};

const ViewCommitteeDecisionCard = (props) => {
  const { caseRequest, faults, onClose } = props;
  const [filteredFaults, setFilteredFaults] = useState([]);
  const [currentSanction, setCurrentSanction] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const [showCommitteeReportForm, setShowCommitteeReportForm] = useState(false);
  const [reason, setReason] = useState("");
  const [showCaseReportingForm, setShowCaseReportingForm] = useState(false);
  const [action, setAction] = useState("");
  const handleGiveSanction = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseGiveSanction = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!!caseRequest?.faults.length) {
      let _tmpFaults = [];
      caseRequest?.faults.forEach((fault) => {
        if (
          !!caseRequest?.faults?.find(({ faultId }) => fault.id === faultId)
        ) {
          if (
            !_tmpFaults?.find(
              ({ faultSanctionId }) => fault.faultSanctionId === faultSanctionId
            )
          ) {
            _tmpFaults.push(fault);
          }
        }
      });
      setFilteredFaults(_tmpFaults);
    }
  }, [faults]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        {showCommitteeReportForm && (
          <CommitteeReportFormDialog
            showCommitteeReportForm={showCommitteeReportForm}
            setShowCommitteeReportForm={setShowCommitteeReportForm}
            isSentToDisciplinaryCommittee={
              caseRequest?.request?.isSentToDisciplinaryCommittee || false
            }
            currentSanction={{
              id: currentSanction?.faultSanctionId || "",
              name: currentSanction?.sanction || "",
            }}
            caseRequest={caseRequest?.request || null}
            enableToFillLetter={true}
            action={action}
            closeModal={onClose}
            actionStatusId={9}
          />
        )}
        {showCaseReportingForm && (
          <ConfirmationDialog
            confirmationDialog={showCaseReportingForm}
            disabled={!reason}
            size="lg"
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong className={`text-success`}>report</strong> this case
                  to the Committee for Investigating?
                </p>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowCaseReportingForm}
            onYes={() => {
              const params = {
                caseId: caseRequest?.request?.id || 0,
                reason: reason,
                caseStatusId: 12,
              };
              reportCaseToTheCommittee(params, onClose);
            }}
          />
        )}
        <Card
          className={`mb-2 w-100 rounded card border-primary hoverable-card`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <InfoIcon />
              </span>
            }
            title={
              <Typography>
                <strong className={"text-primary"}>
                  After the committee investigation, please either issue a
                  sanction or close the case.
                </strong>
              </Typography>
            }
            action={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  aria-describedby={id}
                  onClick={handleGiveSanction}
                  style={{ marginRight: "10px" }}
                >
                  Give Sanction
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleCloseGiveSanction}
                  anchorOrigin={{
                    vertical: "left",
                    horizontal: "top",
                  }}
                >
                  <Card sx={{ maxWidth: 400, minWidth: 200 }}>
                    <CardHeader
                      sx={true}
                      action={
                        <IconButton
                          aria-label="close"
                          onClick={handleCloseGiveSanction}
                        >
                          <span className="material-icons">close</span>
                        </IconButton>
                      }
                      subheader="Sanction(s)"
                    />
                    <Divider />
                    <CardContent>
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          // defaultValue="female"
                          name="radio-buttons-group"
                        >
                          {caseRequest.faults.map((fault, index) => (
                            <FormControlLabel
                              key={index + fault.faultSanctionId}
                              value={fault.faultSanctionId}
                              control={<Radio />}
                              label={fault.faultSanctionName}
                              onChange={() => setCurrentSanction(fault)}
                              checked={currentSanction === fault}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </CardContent>
                    {currentSanction && (
                      <CardActions
                        disableSpacing
                        className="py-3 d-flex justify-content-center"
                      >
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => {
                            setAction("GIVE_SANCTION");
                            setShowCommitteeReportForm(true);
                            handleCloseGiveSanction();
                          }}
                        >
                          <TaskAltIcon /> Confirm
                        </Button>
                      </CardActions>
                    )}
                  </Card>
                </Popover>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => {
                    setAction("CLOSE_CASE");
                    setShowCommitteeReportForm(true);
                  }}
                >
                  Close the case
                </Button>
              </>
            }
          />
        </Card>
      </div>
    </>
  );
};

const ProvideFeedBackLetterCard = (props) => {
  const { caseRequest, onClose, hasPermissions, user } = props;

  const [showCaseReportingForm, setShowCaseReportingForm] = useState(false);
  const [enableToFillLetter, setEnableToFillLetter] = useState(false);
  const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  const [showCaseReviewerStatusId, setShowCaseReviewerStatusId] = useState(0);
  const [showAction, setShowAction] = useState("");

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-primary`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong>
                  {!!caseRequest?.request?.explanationFeedbackDocRefNo ? (
                    <> Feedback letter for the employee's explanation</>
                  ) : (
                    <>
                      <Link
                        onClick={() => {
                          setShowCaseReportingForm(true);
                          setEnableToFillLetter(false);
                        }}
                      >
                        Please view the feedback letter for the employee's
                        explanation
                      </Link>
                    </>
                  )}
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {!caseRequest?.request?.explanationFeedbackDocRefNo && (
                    <>
                      <span className="badge rounded-pill ap-badge-info mr-5 px-2 fw-bold">
                        Waiting for{" "}
                        {caseRequest?.request?.decisionLevel === 1
                          ? "directly supervisor"
                          : "head of institution"}{" "}
                        approval
                      </span>
                    </>
                  )}

                  {caseRequest?.request?.explanationFeedbackDocRefNo && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request
                            ?.explanationFeedbackDocRefNo
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}

                  {!!hasPermissions(["IS_HR"]) &&
                    [6, 7].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <div className="mr-1">
                          <Button
                            variant="contained"
                            color="primary"
                            className="mr-2"
                            startIcon={
                              <span className="material-icons">preview</span>
                            }
                            onClick={() => {
                              setShowCaseReportingForm(true);
                              setEnableToFillLetter(false);
                            }}
                          >
                            View
                          </Button>
                        </div>
                      </>
                    )}

                  {!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                    caseRequest?.request?.decisionLevel === 2 &&
                    [6].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          className="mr-2"
                          startIcon={
                            <span className="material-icons-outlined">
                              task_alt
                            </span>
                          }
                          onClick={() => {
                            setShowCaseReportingForm(true);
                            setEnableToFillLetter(true);
                            setShowCaseReviewerStatusId(7);
                            setShowAction("VERIFY");
                          }}
                        >
                          {" "}
                          Confirm
                        </Button>
                      </>
                    )}

                  {!!hasPermissions(["IS_DOCUMENT_VERIFIER"]) &&
                    caseRequest?.request?.decisionLevel === 2 &&
                    [7].includes(caseRequest?.request?.caseStatusId) && (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mr-2"
                          startIcon={
                            <span className="material-icons-outlined">
                              edit
                            </span>
                          }
                          onClick={() => {
                            setShowCaseReportingForm(true);
                            setEnableToFillLetter(true);
                            setShowCaseReviewerStatusId(7);
                            setShowAction("VERIFY");
                          }}
                        >
                          {" "}
                          Edit
                        </Button>
                      </>
                    )}

                  {((hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                    [7].includes(caseRequest?.request?.caseStatusId) &&
                    caseRequest?.request?.decisionLevel === 2) ||
                    (hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                      [6].includes(caseRequest?.request?.caseStatusId) &&
                      caseRequest?.request?.decisionLevel === 1 &&
                      user.selectedEntitySector.unitId ===
                        caseRequest?.request?.unitId)) && (
                    <>
                      <Button
                        variant="contained"
                        color="success"
                        className="mr-2"
                        startIcon={
                          <span className="material-icons-outlined">
                            task_alt
                          </span>
                        }
                        onClick={() => {
                          setShowCaseReportingForm(true);
                          setEnableToFillLetter(true);
                          setShowCaseReviewerStatusId(8);
                          setShowAction("APPROVE");
                        }}
                      >
                        {" "}
                        Approve
                      </Button>
                    </>
                  )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `View feedback letter for the employee's explanation`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.explanationFeedbackDocRefNo,
            }}
          />
        )}
        {showCaseReportingForm && (
          <ViewProvidingExplanationFeedbackLetterDialog
            showCaseReportingForm={showCaseReportingForm}
            setShowCaseReportingForm={setShowCaseReportingForm}
            caseRequest={caseRequest?.request || null}
            actionStatusId={showCaseReviewerStatusId}
            enableToFillLetter={enableToFillLetter}
            action={showAction}
            closeModal={onClose}
          />
        )}
      </div>
    </>
  );
};

const ProvisionalSuspensionCard = (props) => {
  const { caseRequest, onClose, hasPermissions, user } = props;
  const [onViewProvDocument, setOnViewProvDocument] = useState(false);
  const [showCaseReportingForm, setShowCaseReportingForm] = useState(false);
  const [enableToFillLetter, setEnableToFillLetter] = useState(false);
  const [showAction, setShowAction] = useState("NEW");
  return (
    <>
      {showCaseReportingForm && (
        <RequestProvisionSuspension
          showCaseReportingForm={showCaseReportingForm}
          setShowCaseReportingForm={setShowCaseReportingForm}
          faults={caseRequest.faults || []}
          caseRequest={caseRequest}
          action={showAction}
          closeModal={onClose}
          isProvisionSuspensionLetterRequired={1}
          enableToFillLetter={enableToFillLetter}
        />
      )}
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-${
            !!caseRequest?.request?.isProvisionSuspensionLetterRequired
              ? "primary"
              : "secondary"
          }`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span
                className={`font-weight-bold text-${
                  !!caseRequest?.request?.isProvisionSuspensionLetterRequired
                    ? "primary"
                    : "secondary"
                }`}
              >
                <TextSnippetIcon />
              </span>
            }
            title={
              <Typography>
                <strong>
                  {!!caseRequest?.request?.provisionalSuspensionDocRefNo ? (
                    <> Provisional suspension letter</>
                  ) : (
                    <>
                      {!caseRequest?.request
                        ?.isProvisionSuspensionLetterRequired ? (
                        <>Request provisional suspension letter</>
                      ) : (
                        <>
                          <Link
                            onClick={() => {
                              setShowCaseReportingForm(true);
                              setEnableToFillLetter(false);
                            }}
                          >
                            View provisional suspension letter
                          </Link>
                        </>
                      )}
                    </>
                  )}
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {!!caseRequest?.request
                    ?.isProvisionSuspensionLetterRequired &&
                    !caseRequest?.request?.provisionalSuspensionDocRefNo && (
                      <>
                        <span className="badge rounded-pill ap-badge-info mr-5 px-2 fw-bold">
                          Waiting for head of institution approval
                        </span>
                      </>
                    )}

                  {caseRequest?.request?.provisionalSuspensionDocRefNo && (
                    <IconButton
                      // disableRipple
                      // className="ml-n2"
                      size="small"
                      onClick={() => {
                        setOnViewProvDocument(true);
                      }}
                    >
                      <FilePresentIcon
                        // fontSize="large"
                        style={{
                          color: !!caseRequest?.request
                            ?.provisionalSuspensionDocRefNo
                            ? "#fe4066"
                            : "#c57c7cb8",
                        }}
                      />
                    </IconButton>
                  )}
                  {!!hasPermissions(["IS_HR"]) &&
                    [6, 15].includes(caseRequest?.request?.caseStatusId) &&
                    !!caseRequest.request.isProvisionSuspensionLetterRequired &&
                    !caseRequest?.request?.provisionalSuspensionDocRefNo && (
                      <>
                        <div className="mr-1">
                          <Button
                            variant="contained"
                            color="primary"
                            className="mr-2"
                            startIcon={
                              <span className="material-icons">edit</span>
                            }
                            onClick={() => {
                              if (hasPermissions(["IS_HR"])) {
                                setShowCaseReportingForm(true);
                                setEnableToFillLetter(true);
                                setShowAction("EDIT");
                              } else {
                                showError("Only HR will edit the request");
                              }
                            }}
                          >
                            Edit
                          </Button>
                        </div>
                      </>
                    )}
                  {!caseRequest.request.isProvisionSuspensionLetterRequired &&
                    hasPermissions([
                      "IS_HR",
                      "IS_DOCUMENT_VERIFIER",
                      "IS_HEAD_OF_INSTITUTION",
                      "IS_HEAD_OF_UNIT",
                    ]) &&
                    [6, 15].includes(caseRequest?.request?.caseStatusId) &&
                    !caseRequest?.request?.provisionalSuspensionDocRefNo && (
                      <>
                        <div className="float-right my-0 mx-2">
                          <Button
                            variant="contained"
                            color="primary"
                            className="mr-2"
                            startIcon={
                              <span className="material-icons-outlined">
                                add
                              </span>
                            }
                            onClick={() => {
                              if (hasPermissions(["IS_HR"])) {
                                setShowCaseReportingForm(true);
                                setEnableToFillLetter(true);
                                setShowAction("NEW");
                              } else {
                                showError("Only HR will initiate the request");
                              }
                            }}
                          >
                            {" "}
                            Request
                          </Button>
                        </div>
                      </>
                    )}

                  {hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                    [6, 15].includes(caseRequest?.request?.caseStatusId) &&
                    !!caseRequest.request.isProvisionSuspensionLetterRequired &&
                    !caseRequest?.request?.provisionalSuspensionDocRefNo && (
                      <>
                        <Button
                          variant="contained"
                          color="success"
                          className="mr-2"
                          startIcon={
                            <span className="material-icons-outlined">
                              task_alt
                            </span>
                          }
                          onClick={() => {
                            setShowCaseReportingForm(true);
                            setEnableToFillLetter(true);
                            setShowAction("APPROVE");
                          }}
                        >
                          {" "}
                          Approve
                        </Button>
                      </>
                    )}
                </div>
              </>
            }
          />
        </Card>
        {onViewProvDocument && (
          <PreviewPdfUrl
            showDocument={onViewProvDocument}
            setShowDocument={() => setOnViewProvDocument(false)}
            document={{
              name: `View provisional suspension letter`,
              url:
                process.env.REACT_APP_FILES_URL +
                "/preview/" +
                caseRequest?.request?.provisionalSuspensionDocRefNo,
            }}
          />
        )}
      </div>
    </>
  );
};
const CloseCaseCard = (props) => {
  const { caseRequest, onClose, hasPermissions, onConfirmToCloseCase, user } =
    props;

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const [reason, setReason] = useState("");

  return (
    <>
      <div className="d-flex justify-content-center w-100">
        <Card
          className={`mb-2 w-100 rounded hoverable-card card border-primary`}
          elevation={1}
          variant="elevation"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <CardHeader
            avatar={
              <span className={"font-weight-bold text-primary"}>
                <InfoIcon />
              </span>
            }
            title={
              <Typography>
                <strong>
                  Waiting for{" "}
                  {caseRequest?.request?.decisionLevel === 2
                    ? "head of institution's"
                    : "direct supervisor's"}{" "}
                  confirmation to close a case
                </strong>
              </Typography>
            }
            action={
              <>
                <div
                  className="d-flex flex-row align-items-center pull-right"
                  style={{ borderRadius: "4px" }}
                >
                  {((hasPermissions(["IS_HEAD_OF_UNIT"]) &&
                    [12].includes(caseRequest?.request?.caseStatusId) &&
                    user.selectedEntitySector.unitId ===
                      caseRequest?.request?.unitId &&
                    caseRequest?.request?.decisionLevel === 1) ||
                    (hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
                      [12].includes(caseRequest?.request?.caseStatusId) &&
                      caseRequest?.request?.decisionLevel === 2)) && (
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={
                        <span className="material-icons-outlined">
                          task_alt
                        </span>
                      }
                      onClick={() => setShowConfirmationDialog(true)}
                    >
                      Confirm
                    </Button>
                  )}
                </div>
              </>
            }
          />
        </Card>

        {showConfirmationDialog && (
          <ConfirmationDialog
            confirmationDialog={showConfirmationDialog}
            disabled={!reason}
            size="lg"
            message={
              <>
                <p>
                  Are you sure you want to{" "}
                  <strong className="text-success">confirm</strong> to close
                  this case for{" "}
                </p>
                <div>
                  <TextField
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                    name="reason"
                    label="Reason .."
                    variant="outlined"
                    placeholder="Reason .."
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value || "");
                    }}
                  />
                </div>
              </>
            }
            setConfirmationDialog={setShowConfirmationDialog}
            onYes={() => {
              const params = {
                caseId: caseRequest?.request?.id || 0,
                reason: reason,
                caseStatusId: 13,
              };
              onConfirmToCloseCase(params, setShowConfirmationDialog, onClose);
            }}
          />
        )}
      </div>
    </>
  );
};
