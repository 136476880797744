import {
  Card,
  CardContent,
  IconButton,
  Skeleton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getIndicatorActivities,
  getSubordinates,
} from "../../../store/rbm/actions";
import QuarterCard from "./QuarterCard";
import types from "../../../store/rbm/action-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RBMPageHeader from "./RBMPageHeader";
const Activities = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    user,
    selectedOutput,
    selectedIndicator,
    indicatorActivities,
    loading,
    getIndicatorActivities,
    getSubordinates,
    subordinates,
    showDialog,
    setShowDialog,
    isForMonitoring,
  } = props;

  // useEffect(() => {
  //   document.title = "IPPIS - Activities | " + selectedIndicator?.name;
  // });

  const [totalProgress, setTotalProgress] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [numOfActivities, setNumOfActivities] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!showDialog) getIndicatorActivities(selectedIndicator.id);
  }, [showDialog]);

  useEffect(() => {
    let weight = 0,
      score = 0,
      numOfActivities = 0;
    indicatorActivities.forEach((quarter) => {
      numOfActivities = numOfActivities + quarter.activities.length;
      quarter.activities.forEach((activity) => {
        weight = weight + activity.weight;
        score = score + (activity.score * activity.weight) / 100;
      });
    });

    setTotalWeight(weight.toFixed(2));
    setTotalProgress(score);
    setNumOfActivities(numOfActivities);
  }, [indicatorActivities]);

  // useEffect(
  //   () => {
  //     if (!subordinates.length) getSubordinates();
  //   },
  //
  //   []
  // );

  const onClose = () => {
    setShowDialog(false);
    dispatch({
      type: types.SET_INDICATOR_ACTIVITIES,
      data: [],
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth={`${location.pathname === "/rbm/my-unit" ? "xl" : "lg"}`}
      >
        <DialogTitle className="text-primary">
          <span className="d-flex justify-content-between">
            <Typography className="text-uppercase">
              Activities
              {/* <Tooltip title="Total weight">
                <span
                  style={{ cursor: "pointer" }}
                  className={`badge badge-${
                    +totalWeight === 100 ? "success" : "danger"
                  } ml-1 py-1`}
                >
                  <i className="fas fa-weight-hanging mr-1"></i> {totalWeight}
                </span>
              </Tooltip> */}
            </Typography>
          </span>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className=" font-weight-bold text-uppercase text-primary px-4">
            <Typography component="div" className="mt-3">
              <ol className="breadcrumb py-0 bg-transparent my-0 px-0">
                <li className="breadcrumb-item">
                  <i className="fas fa-folder-open mr-1 "></i>
                  <small>
                    {selectedOutput?.name || user.employments[0].unitName}
                  </small>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <small>{selectedIndicator.name}</small>
                </li>
              </ol>
            </Typography>
            <RBMPageHeader
              labels={[]}
              progress={{
                title: "PROGRESS",
                value: selectedIndicator.score,
              }}
              disabled={loading}
              loading={loading}
              noShadow={true}
            />
          </div>
        </DialogTitle>
        <DialogContent
          className="mx-5 px-0"
          style={{
            maxHeight: `${
              location.pathname === "/rbm/my-unit" ? "75vh" : "50vh"
            }`,
          }}
        >
          <div className="row no-gutter justify-content-center mx-0">
            <div className="col-12  px-1 mx-0">
              <Card
                className="mb-3 px-0"
                variant="outlined"
                style={{
                  // backgroundColor: "#f6f8fa",
                  minHeight: `${
                    location.pathname === "/rbm/my-unit" ? "65vh" : "55vh"
                  }`,
                  border: "none",
                }}
              >
                <CardContent className="p-0">
                  <div>
                    {indicatorActivities.map((quarter, index) => (
                      <QuarterCard
                        totalWeight={totalWeight}
                        quarter={quarter}
                        key={quarter.id}
                        selectedIndicator={selectedIndicator}
                        isForMonitoring={isForMonitoring}
                        // selectedOutput={selectedOutput}
                      />
                    ))}
                    {!indicatorActivities.length && loading && (
                      <div>
                        <Skeleton
                          variant="rectangular"
                          className="mb-3 mt-2"
                          height={118}
                        />
                        <Skeleton
                          variant="rectangular"
                          className="mb-3"
                          height={96}
                        />
                        <Skeleton
                          variant="rectangular"
                          className="mb-3"
                          height={96}
                        />
                        {/* <Skeleton
                          variant="rectangular"
                          className="mb-3"
                          height={96}
                        /> */}
                      </div>
                    )}
                  </div>
                  {/* {!loading &&
                numOfActivities === 0 &&
                location.pathname === "/rbm/my-institution/indicators/activities" && (
                  <div className="jumbotron jumbotron-fluid text-center">
                    <div className="container">
                      <p className="lead">No activities found.</p>
                    </div>
                  </div>
                )} */}
                </CardContent>
              </Card>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  // selectedIndicator,
  indicatorActivities,
  subordinates,
  loading,
  strongPassword,
  user,
}) => {
  return {
    // selectedIndicator,
    indicatorActivities,
    subordinates,
    user,
    loading,
    strongPassword,
  };
};
export default connect(mapStateToProps, {
  getIndicatorActivities,
  getSubordinates,
})(Activities);
