import React, { useState, useEffect } from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  addNewDegree,
  editDegree,
  loadDegrees,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";

const Degrees = (props) => {
  const {
    createDegree,
    setCreateDegree,
    // redux -----------------
    loading,
    degrees,
    addNewDegree,
    editDegree,
    loadDegrees,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [degreeId, setDegreeId] = useState(-1);
  const [degreeName, setDegreeName] = useState("");
  const [formError, setFormError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //PAGINATION
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    if (degrees === null || degrees.length === 0) {
      loadDegrees(setLoadingData);
    } else {
      setLoadingData(false);
    }

    return () => setLoadingData(true);
  }, []);

  const onCloseForm = () => {
    setCreateDegree(false);
    setDegreeId(-1);
    setDegreeName("");
    setFormError(null);
  };

  const onSaveForm = () => {
    if (degreeId === -1 && !createDegree) {
      setFormError({
        element: "GENERAL",
        msg: "Please select competency you want to update.",
      });
      return;
    }
    if (degreeName.length === 0) {
      setFormError({
        element: "NAME",
        msg: "Competency name is required.",
      });
      return;
    }

    if (degreeId >= 0 && !createDegree) {
      editDegree(
        { id: degreeId, name: degreeName },
        (status) => status && onCloseForm()
      );
    } else {
      addNewDegree(degreeName, (status) => status && onCloseForm());
    }
  };

  const IS_EDITING = Boolean(degreeId >= 0);

  const SEARCHED_DATA = !loadingData
    ? searchInput.length === 0 && degrees
      ? degrees
      : degrees.filter((item) =>
          item.name
            .toLocaleLowerCase()
            .includes(searchInput.toLocaleLowerCase())
        )
    : [];
  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };
  const PAGE_COUNT = Math.ceil(SEARCHED_DATA.length / itemsPerPage);
  const PAGE_DATA = paginate(SEARCHED_DATA);

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-2">
        <SearchBox
          className="mb-1"
          onSearch={setSearchInput}
          disabled={loadingData || degrees === null || degrees.length === 0}
          placeholder="Search certificate..."
        />
      </div>

      <table className="table table-striped table-hover table-sm  fixTableHead mt-2">
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Competency</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        {loadingData || degrees === null ? (
          <TableSkeleton cols={3} />
        ) : degrees.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={3}>
                <div className="text-center font-weight-bold">
                  No competency found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((item, index) => (
              <tr key={"competency-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {index + 1 + itemOffset}
                </th>

                <td>{item.name}</td>
                <td align="center">
                  <IconButton
                    aria-label="edit competency"
                    size="small"
                    onClick={() => {
                      setDegreeId(item.id);
                      setDegreeName(item.name);
                    }}
                  >
                    <ModeRoundedIcon fontSize="small" />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createDegree || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Degree" : "Add New Degree"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            <TextField
              id="outlined-basic"
              label="Competency name"
              variant="outlined"
              size="small"
              className="w-100"
              value={degreeName}
              onChange={(e) => setDegreeName(e.target.value)}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase  px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

Degrees.propTypes = {
  createDegree: PropTypes.bool,
  setCreateDegree: PropTypes.func,
};

const mapStateToProps = ({ loading, degrees }) => {
  return {
    loading,
    degrees: degrees.degrees,
  };
};

const mapDispatchToProps = {
  addNewDegree,
  editDegree,
  loadDegrees,
};

export default connect(mapStateToProps, mapDispatchToProps)(Degrees);
