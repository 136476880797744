import React, { useState, useEffect } from "react";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import { Typography, Box, Tooltip } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  Skeleton,
  CardContent,
  Button,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Divider,
  Badge,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  getAdvertisementApplications,
  publishedAdvertisementShotListed,
  getApplicationStatus,
  onAcceptApplication,
  onRejectApplication,
  downloadApplications,
} from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";

import GroupIcon from "@mui/icons-material/Group";
import ApplicationCv from "../../common/components/ApplicationCv";
import RejectionReasonDialog from "../components/RejectionReasonDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import BackdropLoader from "../../common/components/BackdropLoader";
import BadgeIcon from "@mui/icons-material/Badge";
import moment from "moment";
import axios from "axios";
import UserResume from "../../profile/UserResume";
import LiveCandidateLogs from "../../psychometric/components/LiveCandidateLogs";

const ApplicationDialog = (props) => {
  const {
    user,
    loading,
    advertisementApplications,
    getAdvertisementApplications,
    showApplication,
    setShowApplication,
    selectedAdvertisement,
    publishedAdvertisementShotListed,
    onAcceptApplication,
    onRejectApplication,
    downloadApplications,
    getUserDetails,
  } = props;
  const theme = useTheme();
  const { hasPermissions } = useRequiredAuth();
  const defaultIds = "";
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [isShotlisting, setIsShotlisting] = useState(false);

  const [confirmPublishShotList, setConfirmPublishShotList] = useState(false);

  const [filterStatus, setFilterStatus] = useState({ id: "", name: "All" });

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    getActiveApplicationStatuses();

    dispatch({
      type: "SET_ADVERTISEMENT_APPLICATIONS",
      data: [],
    });

    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };

    getAdvertisementApplications(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading
    );
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getAdvertisementApplications(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading
    );
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      currentStatus: filterStatus?.id || defaultIds,
      page: currentPage,
      size: itemPerPage,
    };
    getAdvertisementApplications(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading1
    );
  };

  const onClose = () => {
    setShowApplication(false);
  };

  const [applicationStatuses, setApplicationStatuses] = useState([
    {
      id: "",
      name: "All",
    },
  ]);

  const getActiveApplicationStatuses = async () => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/active-application-statuses/" +
          selectedAdvertisement?.id || ""
      );

      setApplicationStatuses([
        {
          id: "",
          name: "All",
        },
        ...data,
      ]);
    } catch (error) {}
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showApplication}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">RECRUITMENT</span>
            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "6px" }}
              >
                chevron_right
              </span>
            </span>
            <span style={{ color: "rgb(7, 142, 206)" }}>Applications</span>
            <span
              className="material-icons  text-dark"
              style={{ position: "relative", top: "6px" }}
            >
              chevron_right
            </span>
            <span className="text-success">
              {selectedAdvertisement && selectedAdvertisement.positionName}
            </span>
            {selectedAdvertisement &&
              selectedAdvertisement.adStatusId === 7 && (
                <span className="text-danger">(Advertisement Canceled)</span>
              )}{" "}
            {hasPermissions(["IS_CBM"]) &&
              selectedAdvertisement.adStatusId === 0 && (
                <Button
                  disabled={
                    isShotlisting ||
                    !advertisementApplications.length ||
                    !!confirmPublishShotList
                  }
                  onClick={() => {
                    setConfirmPublishShotList(true);
                  }}
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                  className="mr-5 float-right"
                  style={{
                    borderRadius: "8px",
                    height: "38px",
                  }}
                >
                  <span className="material-icons">publish</span>
                  {isShotlisting ? "ShotListing..." : "Publish ShotListing"}
                </Button>
              )}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ height: "650px" }}>
          {confirmPublishShotList && (
            <ConfirmationDialog
              disabled={isShotlisting}
              confirmationDialog={confirmPublishShotList}
              message={`Are you sure you want to publish shortlisting?`}
              setConfirmationDialog={setConfirmPublishShotList}
              noPreFormat={true}
              onYes={() => {
                publishedAdvertisementShotListed(
                  selectedAdvertisement.id,
                  setShowApplication,
                  setIsShotlisting
                );
              }}
            />
          )}

          <Box sx={{ display: "flex", width: "100%" }}>
            {/*  */}

            <div
              className="w-100"
              style={{
                height: "500px",
              }}
            >
              <div
                style={{
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    // padding: ".6em",
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <div className="row">
                    <div className="col-lg-5 mb-2">
                      <Typography variant="h6" noWrap component="div">
                        <span style={{ color: "#2a7790" }}>
                          <GroupIcon className="text-dark mr-2" />
                        </span>
                        <span style={{ color: "rgb(7, 142, 206)" }}>
                          List of{" "}
                          {searchTerm
                            ? "Filtered"
                            : filterStatus
                            ? filterStatus.name
                            : ""}{" "}
                          Applicants ({totalRequests})
                        </span>
                      </Typography>
                    </div>
                    <div className="col-lg-7">
                      <div className="d-flex justify-content-right  align-items-right">
                        <Autocomplete
                          className="mr-2"
                          size="small"
                          id="filterStatus"
                          disabled={
                            isShotlisting ||
                            !advertisementApplications.length ||
                            isLoading1 ||
                            isLoading
                          }
                          defaultValue={null}
                          value={filterStatus || null}
                          options={applicationStatuses}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, currentStatus) => {
                            const query = {
                              searchTerm: "",
                              currentStatus: currentStatus?.id || defaultIds,
                              page: currentPage,
                              size: itemPerPage,
                            };
                            setFilterStatus(currentStatus);
                            getAdvertisementApplications(
                              selectedAdvertisement.id,
                              query,
                              setTotalRequests,
                              setIsLoading1
                            );
                          }}
                          getOptionLabel={(option) => option.name}
                          renderOption={(props, filterStatus) => (
                            <Box
                              component="li"
                              {...props}
                              key={filterStatus.id}
                            >
                              {filterStatus.name}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              style={{
                                width: "200px",
                                height: "35px!important",
                                position: "relative",
                                borderRadius: "8px",
                                padding: "1px!important",
                              }}
                              {...params}
                              label="Filter By"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "filterStatus", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                        <SearchBox
                          disabled={
                            isShotlisting ||
                            !advertisementApplications.length ||
                            isLoading1 ||
                            isLoading
                          }
                          onSearch={(search) => {
                            setSearchTerm(search);

                            if (!search) {
                              const currentPage = 1;
                              setCurrentPage(currentPage);
                              const query = {
                                searchTerm: "",
                                page: currentPage,
                                currentStatus: filterStatus?.id || defaultIds,
                                size: itemPerPage,
                              };
                              getAdvertisementApplications(
                                selectedAdvertisement.id,
                                query,
                                setTotalRequests,
                                setIsLoading1
                              );
                            }
                          }}
                          placeholder="Search…"
                        />
                        <Button
                          disabled={
                            !searchTerm ||
                            isShotlisting ||
                            isLoading1 ||
                            isLoading ||
                            !advertisementApplications.length
                          }
                          onClick={() => onSearch()}
                          variant="contained"
                          size="small"
                          disableElevation
                          className="mr-2 "
                          style={{
                            borderRadius: "8px",
                            height: "38px",
                            border: `1px solid rgb(7, 142, 206)`,
                            color: "#fff",
                            alignItems: "center",
                            fontSize: ".75rem",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textTransform: "initial",
                            backgroundColor: "rgb(7, 142, 206)",
                            marginLeft: "5px",
                            "&:hover": {
                              backgroundColor: "#f0f2f5",
                            },
                          }}
                        >
                          <span className="material-icons">search</span>GO
                        </Button>
                        <span className="ml-0 btn btn-outline-primary btn-sm">
                          <Tooltip title="Download">
                            <IconButton
                              disabled={
                                isLoading ||
                                isLoading1 ||
                                !advertisementApplications.length
                              }
                              className="p-0"
                              color="primary"
                              onClick={() => {
                                const query = {
                                  searchTerm: searchTerm,
                                  currentStatus: filterStatus?.id || defaultIds,
                                  advertisementId: selectedAdvertisement.id,
                                  advertisementName:
                                    selectedAdvertisement.positionName,
                                };
                                downloadApplications(query, setIsDownloading);
                              }}
                            >
                              {isDownloading ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ fontSize: "28px" }}
                                  ></span>
                                  <span className="sr-only">Loading...</span>
                                </>
                              ) : (
                                <span
                                  className="material-icons"
                                  style={{ fontSize: "28px" }}
                                >
                                  cloud_download
                                </span>
                              )}
                            </IconButton>
                          </Tooltip>
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </div>

              <div
                className="mt-0"
                style={{
                  padding: theme.spacing(0.4),
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  <table className="table table-striped table-hover table-sm  fixTableHead">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col">#</th>
                        <th scope="col">Application No</th>
                        <th scope="col" className="text-left">
                          Last Name
                        </th>
                        <th scope="col" className="text-left">
                          First Name
                        </th>

                        <th scope="col" className="text-left">
                          National ID
                        </th>

                        <th scope="col" className="text-left">
                          Email
                        </th>

                        <th scope="col" className="text-left">
                          Phone Number
                        </th>

                        <th scope="col" className="text-left">
                          Application Date
                        </th>

                        <th scope="col" className="text-right">
                          PsychometricTest
                        </th>

                        <th scope="col" className="text-right">
                          Status
                        </th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {isLoading ? (
                      <TableSkeleton cols={11} />
                    ) : (
                      <tbody style={{ height: "100%" }}>
                        <BackdropLoader isLoading={isLoading1} />
                        {advertisementApplications.map((application, index) => (
                          <ApplicantCard
                            application={application}
                            hasPermissions={hasPermissions}
                            onAcceptApplication={onAcceptApplication}
                            onRejectApplication={onRejectApplication}
                            getUserDetails={getUserDetails}
                            index={index}
                            key={application.id}
                            loading={loading}
                          />
                        ))}
                      </tbody>
                    )}
                  </table>

                  <NoRecordMessage
                    isEmpty={!advertisementApplications.length && !isLoading}
                    title={"No record found"}
                  />
                </CardContent>
              </div>

              {/* {showApplicationLetter && (
                <ApplicationLetterDialog
                  showApplicationLetter={showApplicationLetter}
                  setShowApplicationLetter={setShowApplicationLetter}
                  selectedApplication={selectedApplication}
                />
              )} */}
            </div>
          </Box>
        </DialogContent>
        {/* {isShotlisting && (
          <ActionDialog
            showAction={isShotlisting}
            action="Publish ShotListing..."
          />
        )} */}
        <DialogActions className="d-flex justify-content-center py-2">
          {!isLoading && (
            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  // marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRequests / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!advertisementApplications.length && (
                <select
                  className="custom-select custom-select-sm ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                      currentStatus: filterStatus?.id || defaultIds,
                    };
                    getAdvertisementApplications(
                      selectedAdvertisement.id,
                      query,
                      setTotalRequests,
                      setIsLoading
                    );
                  }}
                >
                  {[10, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  advertisementApplications,
  applicationsStatus,
}) => {
  return {
    user,
    loading,
    advertisementApplications,
    applicationsStatus,
  };
};

export default connect(mapStateToProps, {
  getAdvertisementApplications,
  publishedAdvertisementShotListed,
  onAcceptApplication,
  getApplicationStatus,
  onRejectApplication,
  downloadApplications,
  getUserDetails,
})(ApplicationDialog);

export const ApplicantCard = (props) => {
  const {
    loading,
    application,
    index,
    hasPermissions,
    onAcceptApplication,
    onRejectApplication,
    getUserDetails,
  } = props;

  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);
  const [showRejectApplicationForm, setShowRejectApplicationForm] =
    useState(false);

  const [showAcceptApplicationForm, setShowAcceptApplicationForm] =
    useState(false);

  const [showRejectionReason, setShowRejectionReason] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };

  const [reason, setReason] = useState("");

  const [showPSYCandidateLogs, setShowPSYCandidateLogs] = useState(false);

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <th className="text-left">{application.id}</th>
        <td className="text-left"> {application.firstName}</td>
        <td className="text-left"> {application.lastName}</td>
        <td className="text-left"> {application.idNumber}</td>
        <td className="text-left"> {application.email}</td>

        <td className="text-left">{application.phoneNumber}</td>

        <td className="text-left">
          {moment(application.appliedOn).format("lll")}
        </td>
        <td className="text-right">
          {application.psychometricScore !== null
            ? application.psychometricScore + "/100"
            : "N/A"}
        </td>

        <td
          className={`text-right text-${
            application.statusName === "Application received"
              ? "dark"
              : application.statusName === "Accepted" ||
                application.statusName === "Shortlisted"
              ? "success"
              : "danger"
          }`}
        >
          {application.statusName}
        </td>

        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowApplicationCv(true);
                }}
                className="text-primary font-weight-light"
              >
                <span className="material-icons mr-1">open_in_new</span>
                Application Profile
              </MenuItem>
              <Divider className="my-1" />
            </span>
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  getUserDetails(application.userId, handleOpenCurrentProfile);
                }}
                className="text-dark font-weight-light"
              >
                <BadgeIcon className="mr-2 text-primary" />
                {loading ? "Wait...." : "Currently Profile"}
              </MenuItem>
            </span>

            {application.profileAccepted === false && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setShowRejectionReason(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  Reason of Rejection.
                </MenuItem>
              </span>
            )}

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setShowPSYCandidateLogs(true);
                }}
                className="text-primary font-weight-light"
              >
                <span className="material-icons mr-1">list_alt</span>
                Psychometric Logs
              </MenuItem>
              <Divider className="my-1" />
            </span>
          </Menu>
        </td>
      </tr>
      {showApplicationCv && (
        <ApplicationCv
          showApplicationCv={showApplicationCv}
          setShowApplicationCv={setShowApplicationCv}
          application={application}
          dialogActionContents={
            <>
              <div className="row">
                <div className="col-12">
                  {hasPermissions([
                    "IS_HR",
                    "IS_DAF",
                    "IS_RECRUITMENT_MEMBER",
                  ]) &&
                    application.statusId === 0 && (
                      <Button
                        variant="contained"
                        color="success"
                        type="button"
                        disabled={
                          loading ||
                          showRejectApplicationForm ||
                          showAcceptApplicationForm ||
                          (application.profileAccepted !== null &&
                            !!application.profileAccepted)
                        }
                        onClick={() => {
                          setShowAcceptApplicationForm(true);
                        }}
                        className="btn btn-xs"
                      >
                        Accept
                      </Button>
                    )}

                  {hasPermissions([
                    "IS_HR",
                    "IS_DAF",
                    "IS_RECRUITMENT_MEMBER",
                  ]) &&
                    application.statusId === 0 && (
                      <Button
                        variant="contained"
                        color="error"
                        disabled={
                          loading ||
                          showRejectApplicationForm ||
                          showAcceptApplicationForm ||
                          (application.profileAccepted !== null &&
                            !application.profileAccepted)
                        }
                        onClick={() => {
                          setShowRejectApplicationForm(true);
                        }}
                        type="button"
                        className="mr-3 ml-3"
                      >
                        Reject
                      </Button>
                    )}
                </div>
              </div>
            </>
          }
        />
      )}
      {showCurrentProfile && (
        <UserResume
          showProfileDialog={showCurrentProfile}
          setShowProfileDialog={setShowCurrentProfile}
        />
      )}
      {showRejectApplicationForm && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={showRejectApplicationForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-danger">reject</strong> this
                application?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                autoFocus
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setShowRejectApplicationForm}
          onYes={() => {
            onRejectApplication(
              { applicationId: application.id, reason },
              setShowApplicationCv,
              setShowRejectApplicationForm
            );
          }}
        />
      )}

      {showAcceptApplicationForm && (
        <ConfirmationDialog
          confirmationDialog={showAcceptApplicationForm}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-success">accept</strong> this
                application?
              </p>
            </>
          }
          setConfirmationDialog={setShowAcceptApplicationForm}
          onYes={() => {
            onAcceptApplication(
              application.id,
              setShowApplicationCv,
              setShowAcceptApplicationForm
            );
          }}
        />
      )}

      {showRejectionReason && (
        <RejectionReasonDialog
          showRejectionReason={showRejectionReason}
          setShowRejectionReason={setShowRejectionReason}
          selectedApplication={application}
        />
      )}

      <Dialog
        onClose={() => {
          setShowPSYCandidateLogs(false);
        }}
        open={showPSYCandidateLogs}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={`text-primary`}>
          <span className="d-flex align-items-center">
            <span className="material-icons mr-1">list_alt</span>
            Psychometric Test Logs
          </span>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowPSYCandidateLogs(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minHeight: "45vh",
            }}
          >
            <LiveCandidateLogs
              psychometricTestSessionId={application.psychometricTestSessionId}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
