import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";

import {
  CardContent,
  Button,
  IconButton,
  Typography,
  Box,
  Badge,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  getWrittenExams,
  publishWrittenExam,
  onCheckWrittenExamValidation,
  downloadActionExamQuestionTemplate,
} from "../../../store/recruitment/actions";
import SubjectIcon from "@mui/icons-material/Subject";
import AddScheduleExamDialog from "../components/AddScheduleExamDialog";
import moment from "moment";
import PreLoader from "../../common/components/PreLoader";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import UpdateScheduledExamDialog from "../components/UpdateScheduledExamDialog";
import DownloadIcon from "@mui/icons-material/Download";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import WrittenExamQuestionDialog from "../components/WrittenExamQuestion";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CancelWrittenExamDialog from "../components/CancelWrittenExamDialog";
import SensorsIcon from "@mui/icons-material/Sensors";
import ApplicantExamSessionsDialog from "../components/ApplicantExamSessionsDialog";
import AssessmentIcon from "@mui/icons-material/Iso";
import Attandance from "../components/Attandance";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import WrittenExamMarking from "../components/WrittenExamMarking";
import WrittenExamAdditionalMinutesDialog from "../components/WrittenExamAdditionalMinutesDialog";
import Invigilator from "../components/Invigilator";
import GroupIcon from "@mui/icons-material/Group";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { doesExamEnded, doesExamStarted } from "../../common/components/Utils";
import ManageWrittenExamQuestionDialog from "../components/ManageWrittenExamQuestionDialog";
import ExamCenters from "../components/ExamCenters";

const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const ITEM_HEIGHT = 60;

const WrittenExam = (props) => {
  const {
    user,
    loading,
    writtenExams,
    getWrittenExams,
    selectedAdvertisement,
    publishWrittenExam,
    userType,
    onCheckWrittenExamValidation,
    downloadActionExamQuestionTemplate,
  } = props;

  const theme = useTheme();
  const { hasPermissions } = useRequiredAuth();

  const [showCancelWrittenExamForm, setShowCancelWrittenExamForm] =
    useState(false);
  const [showAddScheduleExamForm, setShowScheduleExamForm] = useState(false);
  const [showUpdateScheduledExamForm, setShowUpdateScheduledExamForm] =
    useState(false);
  const [showWrittenExamQuestionsForm, setShowWrittenExamQuestionsForm] =
    useState(false);
  const [showAttandanceLists, setShowAttandanceLists] = useState(false);
  const [showInvigilators, setShowInvigilators] = useState(false);
  const [showExamCenters, setShowExamCenters] = useState(false);
  const [
    showAdditionalMinutesWrittenExamForm,
    setShowAdditionalMinutesWrittenExamForm,
  ] = useState(false);
  const [showWrittenExamMarking, setShowWrittenExamMarking] = useState(false);

  const [
    showUploadWrittenExamQuestionsForm,
    setShowUploadWrittenExamQuestionsForm,
  ] = useState(false);

  const [selectedWrittenExam, setSelectedWrittenExam] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [isExamDateUnlocked, setIsExamDateUnlocked] = useState(false);

  const [showSessions, setShowSessions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // getApplicationStatus(selectedAdvertisement.id);
    //  if (selectedAdvertisement.canScheduleWrittenExam || userType === "ADMIN")
    getWrittenExams(selectedAdvertisement.id, setIsLoading);
  }, []);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        {/*  */}
        <div
          className="w-100"
          style={{
            height: "500px",
          }}
        >
          <div
            style={{
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                // padding: ".6em",
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
            >
              <div className="row">
                <div className="col-lg-3 mb-2">
                  <Typography variant="h6" component="div">
                    <span style={{ color: "#2a7790" }}>
                      <SubjectIcon className="text-dark mr-2" />
                    </span>
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      Written Exam{" "}
                    </span>
                  </Typography>
                </div>
                <div className="col-lg-3 mb-2"></div>
                <div className="col-lg-4 mb-2 ">
                  <button
                    className="btn btn-link btn-sm dropdown-toggle float-right"
                    type="button"
                    onClick={handleOpenMenu}
                  >
                    <DownloadIcon /> Download Exam Template
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleCloseMenu}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "auto",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        // maxHeight: ITEM_HEIGHT * 4.5,
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 2,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 15,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <span>
                      <MenuItem
                        onClick={() => {
                          handleCloseMenu();
                          downloadActionExamQuestionTemplate("Multiple_Choice");
                        }}
                        className="text-dark font-weight-light"
                      >
                        Multiple choice
                      </MenuItem>
                      <Divider className="my-1" />
                    </span>
                    <span>
                      <MenuItem
                        onClick={() => {
                          handleCloseMenu();
                          downloadActionExamQuestionTemplate("Open_Question");
                        }}
                        className="text-dark font-weight-light"
                      >
                        Open question
                      </MenuItem>
                    </span>
                  </Menu>
                </div>
                <div className="col-lg-2 mb-2">
                  <Button
                    disabled={isLoading || loading}
                    onClick={() => {
                      onCheckWrittenExamValidation(
                        selectedAdvertisement.id,
                        setShowScheduleExamForm,
                        setCurrentDate,
                        setIsExamDateUnlocked,
                        "add"
                      );
                    }}
                    style={{
                      ...InactiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                    }}
                    size="md"
                  >
                    <span className="material-icons">add</span> Schedule Exam
                  </Button>
                </div>
              </div>
            </CardContent>
          </div>

          <div
            className="mt-3"
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
            >
              {!isLoading && !!writtenExams.length && (
                <table className="table table-striped table-hover table-sm  fixTableHead">
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col">#</th>
                      <th scope="col" className="text-left">
                        Test Name
                      </th>
                      {/* <th scope="col" className="text-left">
                        Center
                      </th> */}
                      <th scope="col" className="text-left">
                        Seating Date
                      </th>
                      <th scope="col" className="text-center">
                        Duration
                      </th>

                      <th scope="col" className="text-center">
                        Start Time
                      </th>

                      <th scope="col" className="text-center">
                        End Time
                      </th>
                      <th scope="col" className="text-center">
                        AddedMinutes
                      </th>
                      <th scope="col" className="text-center">
                        Total Marks
                      </th>

                      <th scope="col" className="text-center">
                        Status
                      </th>
                      <th scope="col" className="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {writtenExams.map((exam, index) => (
                      <WrittenExamCard
                        selectedAdvertisement={selectedAdvertisement}
                        exam={exam}
                        loading={loading}
                        publishWrittenExam={publishWrittenExam}
                        index={index}
                        key={index}
                        setShowAdditionalMinutesWrittenExamForm={
                          setShowAdditionalMinutesWrittenExamForm
                        }
                        setShowUploadWrittenExamQuestionsForm={
                          setShowUploadWrittenExamQuestionsForm
                        }
                        setShowWrittenExamQuestionsForm={
                          setShowWrittenExamQuestionsForm
                        }
                        setSelectedWrittenExam={setSelectedWrittenExam}
                        setShowUpdateScheduledExamForm={
                          setShowUpdateScheduledExamForm
                        }
                        setShowCancelWrittenExamForm={
                          setShowCancelWrittenExamForm
                        }
                        setShowExamCenters={setShowExamCenters}
                        setShowSessions={setShowSessions}
                        setShowAttandanceLists={setShowAttandanceLists}
                        setShowWrittenExamMarking={setShowWrittenExamMarking}
                        setShowInvigilators={setShowInvigilators}
                        userType={userType}
                        onCheckWrittenExamValidation={
                          onCheckWrittenExamValidation
                        }
                        setCurrentDate={setCurrentDate}
                        setIsExamDateUnlocked={setIsExamDateUnlocked}
                      />
                    ))}
                  </tbody>
                </table>
              )}

              <NoRecordMessage
                isEmpty={!writtenExams.length && !isLoading}
                title="No scheduled written exam found."
              />

              <PreLoader isLoading={isLoading} />
            </CardContent>
          </div>
        </div>
        {showAddScheduleExamForm && (
          <AddScheduleExamDialog
            examType="Written"
            advertisement={selectedAdvertisement}
            showAddScheduleExamForm={showAddScheduleExamForm}
            setShowScheduleExamForm={setShowScheduleExamForm}
            currentDate={currentDate}
            isExamDateUnlocked={isExamDateUnlocked}
          />
        )}
        {showUpdateScheduledExamForm && (
          <UpdateScheduledExamDialog
            examType="Written"
            advertisement={selectedAdvertisement}
            exam={selectedWrittenExam}
            showUpdateScheduledExamForm={showUpdateScheduledExamForm}
            setShowUpdateScheduledExamForm={setShowUpdateScheduledExamForm}
            currentDate={currentDate}
            isExamDateUnlocked={isExamDateUnlocked}
          />
        )}
        {showUploadWrittenExamQuestionsForm && (
          <ManageWrittenExamQuestionDialog
            exam={selectedWrittenExam}
            selectedAdvertisement={selectedAdvertisement}
            showUploadWrittenExamQuestionsForm={
              showUploadWrittenExamQuestionsForm
            }
            setShowUploadWrittenExamQuestionsForm={
              setShowUploadWrittenExamQuestionsForm
            }
          />
        )}
        {showWrittenExamQuestionsForm && (
          <WrittenExamQuestionDialog
            exam={selectedWrittenExam}
            showWrittenExamQuestionsForm={showWrittenExamQuestionsForm}
            setShowWrittenExamQuestionsForm={setShowWrittenExamQuestionsForm}
            userType={userType}
          />
        )}
        {showSessions && (
          <ApplicantExamSessionsDialog
            exam={selectedWrittenExam}
            showSessions={showSessions}
            setShowSessions={setShowSessions}
          />
        )}
        {showWrittenExamMarking && (
          <WrittenExamMarking
            exam={selectedWrittenExam}
            showWrittenExamMarking={showWrittenExamMarking}
            setShowWrittenExamMarking={setShowWrittenExamMarking}
            userType={userType}
          />
        )}
        {showCancelWrittenExamForm && (
          <CancelWrittenExamDialog
            showCancelWrittenExamForm={showCancelWrittenExamForm}
            setShowCancelWrittenExamForm={setShowCancelWrittenExamForm}
            selectedWrittenExam={selectedWrittenExam}
          />
        )}
        {showAttandanceLists && (
          <Attandance
            showAttandanceLists={showAttandanceLists}
            selectedExam={selectedWrittenExam}
            examTypeId="1"
            setShowAttandanceLists={setShowAttandanceLists}
          />
        )}
        {showInvigilators && (
          <Invigilator
            showInvigilators={showInvigilators}
            selectedExam={selectedWrittenExam}
            setShowInvigilators={setShowInvigilators}
          />
        )}
        {showExamCenters && (
          <ExamCenters
            showExamCenters={showExamCenters}
            selectedExam={selectedWrittenExam}
            setShowExamCenters={setShowExamCenters}
          />
        )}

        {showAdditionalMinutesWrittenExamForm && (
          <WrittenExamAdditionalMinutesDialog
            showAdditionalMinutesWrittenExamForm={
              showAdditionalMinutesWrittenExamForm
            }
            selectedWrittenExam={selectedWrittenExam}
            setShowAdditionalMinutesWrittenExamForm={
              setShowAdditionalMinutesWrittenExamForm
            }
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, writtenExams }) => {
  return { user, loading, writtenExams };
};

export default connect(mapStateToProps, {
  getWrittenExams,
  publishWrittenExam,
  onCheckWrittenExamValidation,
  downloadActionExamQuestionTemplate,
})(WrittenExam);

const WrittenExamCard = (props) => {
  const {
    loading,
    exam,
    index,
    setShowCancelWrittenExamForm,
    setSelectedWrittenExam,
    setShowUpdateScheduledExamForm,
    setShowUploadWrittenExamQuestionsForm,
    setCurrentDate,
    onCheckWrittenExamValidation,
    setShowSessions,
    setShowAttandanceLists,
    setShowWrittenExamMarking,
    selectedAdvertisement,
    setShowAdditionalMinutesWrittenExamForm,
    setShowInvigilators,
    publishWrittenExam,
    setShowExamCenters,
    setIsExamDateUnlocked,
    userType,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const disableExamAccessibility = () => {
    return hasPermissions(["IS_ADMIN", "IS_SUPPORTER", "IS_SUPPORT"])
      ? false
      : hasPermissions(["IS_HR", "IS_CBM", "IS_DAF"]) &&
          selectedAdvertisement.disableExamAccessibility;
  };
  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <td className="text-left">
          {" "}
          {exam.advertisement
            ? exam.advertisement.requisition.position.name
            : ""}
        </td>
        {/* <td className="text-left">{exam.venue}</td> */}

        <td className="text-left">
          {" "}
          {exam.seatingDate ? moment(exam.seatingDate).format("ll") : ""}
        </td>
        <td className="text-center"> {exam.durationMin} min</td>

        <td className="text-center">
          {exam.startTime
            ? moment(
                exam.seatingDate.split("T")[0] + " " + exam.startTime
              ).format("HH:mm A")
            : ""}
        </td>

        <td className="text-center">
          {exam.endTime
            ? moment(
                exam.seatingDate.split("T")[0] + " " + exam.endTime
              ).format("HH:mm A")
            : ""}
        </td>

        <td className="text-center">
          {exam.additionalMinutes ? exam.additionalMinutes + " min" : "N/A"}
        </td>

        <td className="text-center">
          <strong>{exam.totalMarks}</strong>
        </td>

        <td className="text-center">
          {exam.isCanceled ? (
            <span className="px-1 py-1 badge  badge-danger">Canceled</span>
          ) : doesExamStarted(exam) && !!exam.isActive ? (
            <span className="px-1 py-1 badge  badge-primary">
              Exam Inprogress
            </span>
          ) : doesExamEnded(exam) &&
            !!exam.isActive &&
            !exam.wasExamMarkingPublished ? (
            <span className="px-1 py-1 badge  badge-warning">Exam Ended</span>
          ) : exam.wasExamMarkingPublished ? (
            <span className="px-1 py-1 badge  badge-success">
              Exam Marking Published
            </span>
          ) : (
            <span className="px-1 py-1 badge  badge-secondary">Scheduled</span>
          )}
        </td>

        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                // maxHeight: ITEM_HEIGHT * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedWrittenExam(exam);
                  setShowExamCenters(true);
                }}
                className="text-dark font-weight-light"
              >
                <ListAltIcon className="mr-2 text-primary" />
                Centers
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedWrittenExam(exam);
                  setShowAttandanceLists(true);
                }}
                className="text-dark font-weight-light"
              >
                <PersonAddAlt1Icon className="mr-2 text-primary" />
                Attendance
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedWrittenExam(exam);
                  setShowInvigilators(true);
                }}
                className="text-dark font-weight-light"
              >
                <GroupIcon className="mr-2 text-primary" />
                Invigilators
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {hasPermissions(["IS_HR"]) &&
              !exam.wasExamMarkingPublished &&
              selectedAdvertisement.adStatusId === 1 && (
                <span>
                  <MenuItem
                    disabled={!!exam.isActive || disableExamAccessibility()}
                    onClick={() => {
                      publishWrittenExam(exam, handleCloseMenu);
                    }}
                    className={
                      !!exam.isActive
                        ? "text-success font-weight-light"
                        : "text-primary font-weight-light"
                    }
                  >
                    <PlayCircleOutlineIcon className="mr-2 text-primary" />
                    {!exam.isActive ? "Start Exam" : "Exam Started"}
                  </MenuItem>
                  <Divider className="my-1" />
                </span>
              )}

            <span>
              <MenuItem
                disabled={disableExamAccessibility()}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedWrittenExam(exam);
                  setShowUploadWrittenExamQuestionsForm(true);
                }}
                className="font-weight-light"
              >
                <LibraryBooksIcon className="mr-2 text-primary" />
                Exam Questions
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {/* <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedWrittenExam(exam);
                  setShowWrittenExamQuestionsForm(true);
                }}
                className="text-dark font-weight-light"
              >
                <VisibilityIcon className="mr-2 text-primary" /> View Exam
                Questions
              </MenuItem>

              <Divider className="my-1" />
            </span> */}

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedWrittenExam(exam);
                  setShowSessions(true);
                }}
                className="text-dark font-weight-light"
              >
                <SensorsIcon className="mr-2 text-primary" /> Sessions
              </MenuItem>
              <Divider className="my-1" />
            </span>
            <span>
              <MenuItem
                disabled={disableExamAccessibility()}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedWrittenExam(exam);
                  setShowWrittenExamMarking(true);
                }}
                className="text-dark font-weight-light"
              >
                <AssessmentIcon className="mr-2 text-primary" /> Marking
              </MenuItem>
              {!exam.isCanceled && <Divider className="my-1" />}
            </span>

            {userType !== "ADMIN" && (
              <span>
                <MenuItem
                  disabled={
                    disableExamAccessibility() ||
                    !hasPermissions(["IS_HR"]) ||
                    selectedAdvertisement.adStatusId === 7 ||
                    exam.isCanceled ||
                    exam.isActive
                  }
                  onClick={() => {
                    handleCloseMenu();
                    setSelectedWrittenExam(exam);
                    onCheckWrittenExamValidation(
                      selectedAdvertisement.id,
                      setShowUpdateScheduledExamForm,
                      setCurrentDate,
                      setIsExamDateUnlocked,
                      "update"
                    );
                  }}
                  className="text-dark font-weight-light"
                >
                  <DriveFileRenameOutlineIcon className="mr-2 text-primary" />{" "}
                  Update
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {userType !== "ADMIN" && (
              <span>
                <MenuItem
                  disabled={
                    disableExamAccessibility() ||
                    !hasPermissions(["IS_HR", "IS_INVIGILATOR"]) ||
                    !exam.isActive
                  }
                  onClick={() => {
                    handleCloseMenu();
                    setShowAdditionalMinutesWrittenExamForm(true);
                    setSelectedWrittenExam(exam);
                  }}
                  className="text-dark font-weight-light"
                >
                  <i className="fas fa-plus mr-2 text-primary"></i>
                  {exam.additionalMinutes && exam.additionalMinutes > 0 ? (
                    <>Added {exam.additionalMinutes} minutes </>
                  ) : (
                    <>Additional Minutes</>
                  )}
                </MenuItem>
              </span>
            )}

            {userType !== "ADMIN" && hasPermissions(["IS_NPSC", "IS_HR"]) && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  disabled={exam.isCanceled || disableExamAccessibility()}
                  onClick={() => {
                    handleCloseMenu();
                    setShowCancelWrittenExamForm(true);
                    setSelectedWrittenExam(exam);
                  }}
                  className="text-danger font-weight-light"
                >
                  <CancelOutlinedIcon className="mr-2 text-primary" />
                  Cancel
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>
    </>
  );
};
