import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Skeleton,
  DialogActions,
  Tooltip,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getJobClassificationEmployees } from "../../../store/structure/actions";
import SearchBox from "../../common/components/SearchBox";
import LoadEmployees from "../../employees/components/LoadEmployees";
const JobClassificationEmployeesDialog = (props) => {
  const {
    user,
    loading,
    jobClassificationEmployees,
    jobClassificationId,
    jobName,
    showJcEmployeesModal,
    setShowJcEmployeesModal,
    getJobClassificationEmployees,
  } = props;

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getJobClassificationEmployees(jobClassificationId);
  }, [jobClassificationId]);

  const onClose = () => {
    setShowJcEmployeesModal(false);
  };

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showJcEmployeesModal}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography
            className="text-uppercase"
            variant="h6"
            noWrap
            component="div"
            style={{ maxWidth: "40vw" }}
          >
            <span
              className="text-dark
            "
            >
              ({jobClassificationEmployees.length}) Employee
              {jobClassificationEmployees.length > 1 && "s"}{" "}
            </span>

            <span> | {jobName}</span>
          </Typography>
          <Tooltip title="Download">
            <span>
              <IconButton
                disabled
                className="p-0"
                color="primary"
                sx={{
                  position: "absolute",
                  right: 54,
                  top: 12,
                }}
              >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers className="pb-0">
          <div
            style={{
              marginLeft: "1em",
              marginRight: "1em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              minHeight: "70vh",
            }}
          >
            <div className="mb-2">
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox onSearch={onSearch} placeholder="Search…" />
              </Box>
            </div>

            {!!jobClassificationEmployees.length && (
              <LoadEmployees
                searchTerm={searchTerm || ""}
                showUnitName={true}
                showActionMenus={false}
                showInstitutionName={true}
                skeletonLoader={loading}
                employees={jobClassificationEmployees}
              />
            )}

            {!jobClassificationEmployees.length && !loading && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5">
                  <p className="lead">No employees found</p>
                </div>
              </div>
            )}

            {loading && !jobClassificationEmployees.length && (
              <div className="container mt-2 px-0" style={{ width: "100%" }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="py-3"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, jobClassificationEmployees }) => {
  return {
    user,
    loading,
    jobClassificationEmployees,
  };
};
export default connect(mapStateToProps, {
  getJobClassificationEmployees,
})(JobClassificationEmployeesDialog);
