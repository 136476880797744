import { IconButton, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { getSupervisors } from "../../../store/structure/actions";
import { assignSupervisorToAppeal } from "../../../store/e-appeal/actions";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import SearchBox from "../../common/components/SearchBox";
const AssignAppealToSupervisor = (props) => {
  const {
    loading,
    appealId,
    showAssignAppealToSupervisor,
    setShowAssignAppealToSupervisor,
    assignSupervisorToAppeal,
    supervisors,
    getSupervisors,
    appealAssignedPositionId,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [supervisorId, setSupervisorId] = useState("0");
  const [filteredSupervisors, setFilteredSupervisors] = useState([
    ...supervisors,
  ]);
  useEffect(() => {
    getSupervisors();
  }, []);

  useEffect(() => {
    const filtered = [...supervisors].filter(
      ({ employeeName, name }) =>
        employeeName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        name.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredSupervisors(filtered);
  }, [searchTeam, supervisors]);
  const onClose = () => {
    setShowAssignAppealToSupervisor(false);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showAssignAppealToSupervisor}
      fullWidth
    >
      <DialogTitle className="text-primary">
        <span className="text-primary">Assign To Supervisor</span>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className="row">
          <div className="col-12">
            <SearchBox
              onSearch={onSearch}
              disabled={!supervisors.length}
              placeholder="Search for supervisors"
            />
          </div>

          <div className="col-12 mt-3">
            {!!supervisors.length && (
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th
                      scope="col"
                      className="text-left text-truncate px-2 py-2"
                    >
                      Supervisor
                    </th>
                    <th
                      scope="col"
                      className="text-left text-truncate px-2 py-2"
                    >
                      Position
                    </th>

                    <th scope="col" className="text-right">
                      Actions
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredSupervisors.map((supervisor, index) => (
                    <tr key={index}>
                      <td className="text-left text-truncate px-2 py-2">
                        {supervisor.employeeName}
                      </td>
                      <td className="text-left text-truncate px-2 py-2">
                        {supervisor.name}
                      </td>
                      <td className="text-right">
                        <button
                          disabled={
                            supervisorId !== "0" ||
                            appealAssignedPositionId === supervisor.id
                          }
                          onClick={() => {
                            setSupervisorId(supervisor.id);
                            assignSupervisorToAppeal(
                              { positionId: supervisor.id, appealId: appealId },
                              setSupervisorId,
                              onClose
                            );
                          }}
                          className={
                            appealAssignedPositionId === supervisor.id
                              ? "btn btn-secondary btn-sm my-1"
                              : "btn btn-primary btn-sm my-1"
                          }
                        >
                          {supervisorId === supervisor.id ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </>
                          ) : (
                            <>
                              Assign{" "}
                              {appealAssignedPositionId === supervisor.id &&
                                "ed"}{" "}
                            </>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <NoRecordMessage
              isEmpty={!filteredSupervisors.length && !loading}
              title="No records found "
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, supervisors }) => {
  return {
    loading,
    supervisors,
  };
};
export default connect(mapStateToProps, {
  getSupervisors,
  assignSupervisorToAppeal,
})(AssignAppealToSupervisor);
