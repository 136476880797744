import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Button,
  CircularProgress,
  DialogActions,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CurrencyFormat from "react-currency-format";

import {
  sendPayrollDeclarations,
  getPayrollDeclarations,
} from "../../../store/payroll/actions";
import { isEmpty, isNull } from "lodash";
import { Box } from "@mui/system";
import { CSVLink } from "react-csv";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const PayrollDeclarationsDialog = (props) => {
  const {
    loading,
    onWait,
    showDeclarations,
    setShowDeclarations,
    payroll,
    sendPayrollDeclarations,
    getPayrollDeclarations,
    payrollDeclarations,
    viewType,
    env,
    type,
    rssbDeclarationError,
  } = props;

  const onClose = () => {
    setShowDeclarations(false);
  };

  useEffect(() => {
    if (showDeclarations && !!payroll) getPayrollDeclarations(payroll.id, type);
  }, [showDeclarations, payroll]);

  const [declarationDetails, setDeclarationDetails] = useState([]);

  useEffect(() => {
    const tmpDeclarationDetails = [];

    if (!!payrollDeclarations?.declarationDetails?.length) {
      payrollDeclarations.declarationDetails.forEach((item) => {
        tmpDeclarationDetails.push({
          period: item.period,
          employeeId: item.employeeId,
          oldEmployeeId: item.oldEmployeeId,
          rssbNumber: item.rssbNumber,
          firstName: item.firstName,
          lastName: item.lastName,
          documentNumber: item.idNumber,
          employmentCategory: item.employmentCategory,
          isRamaMember: item.isRamaMember,
          basePay: +item.basePay,
          housingAllowance: +item.housingAllowance,
          transportAllowance: +item.transportAllowance,
          taxableAllowance: +item.taxableAllowance,
          mileageAllowance: +item.lumpsum,
          totalPayeAmount: +item.totalPayeAmount,
          totalPayeBaseAmount: +item.totalPayeBaseAmount,
          totalPensionAmount: +item.totalPensionAmount,
          totalPensionBaseAmount: +item.totalPensionBaseAmount,
          totalOHAmount: +item.professionalRiskAmount,
          totalOHBaseAmount: +item.professionalRiskBaseAmount,
          totalMedicalAmount: +item.totalMedicalAmount,
          totalMedicalBaseAmount: +item.totalMedicalBaseAmount,
          totalMaternityAmount: +item.totalMaternityAmount,
          totalMaternityBaseAmount: +item.totalMaternityBaseAmount,
          totalCbhiAmount: +item.totalCbhiAmount,
          totalCbhiBaseAmount: +item.totalCbhiBaseAmount,
          partialNet: +item.partialNet,
          grossSalary: +item.grossSalary,
        });
      });

      setDeclarationDetails(tmpDeclarationDetails);
    }
  }, [payrollDeclarations]);

  const downloadExcelFile = async () => {
    if (!!declarationDetails.length)
      document.getElementById("downloadBTN").click();
  };

  const [showConfirmSendToRSSB, setShowConfirmSendToRSSB] = useState(false);

  const dispatch = useDispatch();

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="lg"
        open={showDeclarations}
      >
        <DialogTitle className="text-primary pb-0 mb-0  text-center">
          <>
            PAYROLL DECLARATIONS
            {!isEmpty(payrollDeclarations) && (!loading || onWait) && (
              <>
                <table
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          width: "30%",
                          backgroundColor: "#fff",
                        }}
                      ></th>
                      <th
                        className="py-0 pt-1"
                        style={{
                          backgroundColor: "#4f81bc",
                          verticalAlign: "middle",
                          // border: "solid 1px #4f81bc",
                          color: "#ffff00",
                          fontSize: "14px",
                          width: "40%",
                        }}
                      >
                        <span className="d-flex justify-content-center align-items-center">
                          {payroll.payrollTypeName}
                          <KeyboardArrowRightIcon />
                          {payroll.payrollGroupName}
                          <KeyboardArrowRightIcon />
                          {payroll.periodName}
                        </span>
                      </th>
                      <th
                        style={{
                          width: "30%",
                          backgroundColor: "#fff",
                        }}
                      ></th>
                    </tr>

                    <tr>
                      <th
                        style={{
                          backgroundColor: "#fff",
                        }}
                      ></th>
                      <th
                        className="py-0 pb-2"
                        style={{
                          fontWeight: "bold",
                          //backgroundColor: "#4f81bc",
                          fontSize: "14px",
                          //color: "#ffff00",
                        }}
                      >
                        Tin Number:{payrollDeclarations.tinNumber}
                      </th>
                      <th
                        style={{
                          backgroundColor: "#fff",
                        }}
                      ></th>
                    </tr>
                  </tbody>
                </table>

                <table className="mt-2">
                  <tbody>
                    <tr
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      <th className="py-1">
                        PAYE:{" "}
                        <CurrencyFormat
                          value={payrollDeclarations.totalPayeAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" RWF"}
                        />
                      </th>
                      <th className="py-1">
                        Pension:{" "}
                        <CurrencyFormat
                          value={payrollDeclarations.totalPensionAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" RWF"}
                        />
                      </th>
                      <th className="py-1">
                        Medical:{" "}
                        <CurrencyFormat
                          value={payrollDeclarations.totalMedicalAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" RWF"}
                        />
                      </th>
                      <th className="py-1">
                        MaternityLeave:{" "}
                        <CurrencyFormat
                          value={payrollDeclarations.totalMaternityAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" RWF"}
                        />
                      </th>
                      <th className="py-1">
                        CBHI:{" "}
                        <CurrencyFormat
                          value={payrollDeclarations.totalCbhiAmount}
                          displayType={"text"}
                          thousandSeparator={true}
                          suffix={" RWF"}
                        />
                      </th>
                    </tr>
                    <tr>
                      <th
                        colSpan={5}
                        className="py-0"
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "#4f81bc",
                          fontSize: "14px",
                          color: "#ffff00",
                          textAlign: "center",
                        }}
                      >
                        Details
                      </th>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </>

          {((hasPermissions(["IS_HR"]) && !payroll.isDeclared) ||
            hasPermissions(["IS_ADMIN"])) && (
            <Button
              sx={{
                position: "absolute",
                right: 96,
                top: 12,
              }}
              color="primary"
              variant="contained"
              disabled={loading && !onWait}
              onClick={() => {
                setShowConfirmSendToRSSB(true);
              }}
              size="small"
              startIcon={
                <span
                  style={{ transform: "rotateY(180deg)" }}
                  className="material-icons"
                >
                  reply_all
                </span>
              }
            >
              Send To RSSB
            </Button>
          )}

          <Tooltip title="Download (.csv) File">
            <span>
              <IconButton
                disabled={loading || onWait || !declarationDetails.length}
                className="p-0"
                color="primary"
                // size="large"
                sx={{
                  position: "absolute",
                  right: 54,
                  top: 12,
                }}
                onClick={() => {
                  downloadExcelFile();
                }}
              >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              </IconButton>
            </span>
          </Tooltip>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div
            className="mt-0"
            style={{
              padding: "0px",
              minHeight: "60vh",
              overflow: "auto",
            }}
          >
            <CSVLink
              data={declarationDetails}
              filename={`Declarations-${payroll.payrollTypeName}-${payroll.payrollGroupName}-${payroll.periodName}.csv`}
              className="hidden"
              id="downloadBTN"
              target="_blank"
            />

            {((loading && !onWait) || !!isEmpty(payrollDeclarations)) && (
              <div>
                <Box
                  className="justify-content-center "
                  sx={{ display: "flex" }}
                >
                  {loading && (
                    <>
                      <CircularProgress />
                      <h5 className="mt-2 ml-2">Please wait ...</h5>
                    </>
                  )}

                  {(!loading || onWait) && (
                    <>
                      {!!isEmpty(payrollDeclarations) && (
                        <div className="d-block">
                          <div className="text-center">
                            Payroll not declared
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Box>
              </div>
            )}

            {!isEmpty(payrollDeclarations) && (!loading || onWait) && (
              <div className="d-block">
                <table className="table table-striped table-hover table-sm fixTableHead">
                  <thead>
                    <tr>
                      <th className="py-0 pl-1">#</th>
                      <th className="py-0 pl-1">Period</th>
                      <th className="py-0 pl-1">RSSB Number</th>
                      <th className="py-0 pl-1">LastName</th>
                      <th className="py-0 pl-1">FirstName</th>
                      <th className="py-0 pl-1">BasePay</th>
                      <th className="py-0 pl-1">Housing</th>
                      <th className="py-0 pl-1">Transport</th>
                      <th className="py-0 pl-1">Taxable</th>
                      {/* <th className="py-0 pl-1">Exempted</th> */}
                      <th className="py-0 pl-1">Lumpsum</th>
                      <th
                        className="py-0 pl-1 text-right"
                        style={{ backgroundColor: "#dee2e6" }}
                      >
                        Paye
                      </th>
                      <th
                        className="py-0 pl-1 text-right"
                        style={{ backgroundColor: "#dee2e6" }}
                      >
                        Pension
                      </th>
                      <th
                        className="py-0 pl-1 text-right"
                        style={{ backgroundColor: "#dee2e6" }}
                      >
                        Medical
                      </th>
                      <th
                        className="py-0 pl-1 text-right"
                        style={{ backgroundColor: "#dee2e6" }}
                      >
                        Maternity
                      </th>
                      <th
                        className="py-0 pl-1 text-right"
                        style={{ backgroundColor: "#dee2e6" }}
                      >
                        CBHI
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {payrollDeclarations.declarationDetails.map(
                      (declaration, index) => (
                        <tr key={declaration.id}>
                          <td>{index + 1}</td>
                          <td>{declaration.period}</td>
                          <td>{declaration.rssbNumber}</td>
                          <td>{declaration.lastName}</td>
                          <td>{declaration.firstName}</td>
                          <td>
                            {" "}
                            <CurrencyFormat
                              value={declaration.basePay}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td>
                            {" "}
                            <CurrencyFormat
                              value={declaration.housingAllowance}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td>
                            {" "}
                            <CurrencyFormat
                              value={declaration.transportAllowance}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td>
                            {" "}
                            <CurrencyFormat
                              value={declaration.taxableAllowance}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          {/* <td>
                              {" "}
                              <CurrencyFormat
                                value={declaration.exemptedAllowance}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                            </td> */}
                          <td>
                            {" "}
                            <CurrencyFormat
                              value={declaration.lumpsum}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td
                            className="text-right"
                            style={{ backgroundColor: "#dee2e6" }}
                          >
                            {" "}
                            <CurrencyFormat
                              value={declaration.totalPayeAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td
                            className="text-right"
                            style={{ backgroundColor: "#dee2e6" }}
                          >
                            {" "}
                            <CurrencyFormat
                              value={declaration.totalPensionAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td
                            className="text-right"
                            style={{ backgroundColor: "#dee2e6" }}
                          >
                            {" "}
                            <CurrencyFormat
                              value={declaration.totalMedicalAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td
                            className="text-right"
                            style={{ backgroundColor: "#dee2e6" }}
                          >
                            {" "}
                            <CurrencyFormat
                              value={declaration.totalMaternityAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                          <td
                            className="text-right"
                            style={{ backgroundColor: "#dee2e6" }}
                          >
                            {" "}
                            <CurrencyFormat
                              value={declaration.totalCbhiAmount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          {!!payroll.isDeclared && (
            <span className={`p-1 badge badge-success`}>
              RSSB Declarations sent
            </span>
          )}
        </DialogActions>
      </Dialog>

      {showConfirmSendToRSSB && (
        <ConfirmationDialog
          confirmationDialog={showConfirmSendToRSSB}
          message={`Are you sure to send this declaration to RSSB?`}
          setConfirmationDialog={setShowConfirmSendToRSSB}
          onYes={() => {
            sendPayrollDeclarations(payroll.id, type, setShowConfirmSendToRSSB);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  rssbDeclarationError,
  payrollDeclarations,
  env,
  onWait,
}) => {
  return {
    loading,
    payrollDeclarations,
    env,
    onWait,
    rssbDeclarationError,
  };
};
export default connect(mapStateToProps, {
  sendPayrollDeclarations,
  getPayrollDeclarations,
})(PayrollDeclarationsDialog);
