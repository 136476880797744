const types = {
  SET_TREE_UNITS: "SET_TREE_UNITS",
  SET_UNITS: "SET_UNITS",
  SET_UNIT_TYPES: "SET_UNIT_TYPES",
  DELETE_UNIT: "DELETE_UNIT",
  SELECTED_UNIT: "SELECTED_UNIT",
  SET_POSITIONS: "SET_POSITIONS",
  SET_ENTITY_SECTOR_POSITIONS: "SET_ENTITY_SECTOR_POSITIONS",
  SEARCH_UNIT: "SEARCH_UNIT",
  SEARCH_POSITION: "SEARCH_POSITION",
  SEARCH_CURRENT_POSITIONS: "SEARCH_CURRENT_POSITIONS",
  SET_SALARY_STRUCTURES: "SET_SALARY_STRUCTURES",
  SET_SALARY_STRUCTURE_AS_ADDED: "SET_SALARY_STRUCTURE_AS_ADDED",
  SET_EMPLOYEE_GROUPS: "SET_EMPLOYEE_GROUPS",
  SET_ENTITIES: "SET_ENTITIES",
  SET_HIRING_MODES: "SET_HIRING_MODES",
  ADD_POSITION: "ADD_POSITION",
  SET_ECHELONS: "SET_ECHELONS",
  DELETE_POSITION: "DELETE_POSITION",
  SET_POSITION_PLANNER: "SET_POSITION_PLANNER",
  SET_POSITION_HEAD_UNITY: "SET_POSITION_HEAD_UNITY",
  SET_POSITION_EMPLOYEES: "SET_POSITION_EMPLOYEES",
  SET_SUPERVISORS: "SET_SUPERVISORS",
  UPDATE_POSITION: "UPDATE_POSITION",
  SET_PROFILE_DEREES: "SET_PROFILE_DEREES",
  SET_QUALIFICATIONS: "SET_QUALIFICATIONS",
  UPDATE_QUALIFICATION: "UPDATE_QUALIFICATION",
  ADD_QUALIFICATION: "ADD_QUALIFICATION",
  DELETE_QUALIFICATION: "DELETE_QUALIFICATION",
  SET_PROFESSIONAL_CERTIFICATES: "SET_PROFESSIONAL_CERTIFICATES",
  SET_CERTIFICATE_TYPES: "SET_CERTIFICATE_TYPES",
  SET_PROFESSIONAL_CERTIFICATE_TYPES: "SET_PROFESSIONAL_CERTIFICATE_TYPES",
  ADD_PROFESSIONAL_CERTIFICATE: "ADD_PROFESSIONAL_CERTIFICATE",
  DELETE_PROFESSIONAL_CERTIFICATE: "DELETE_PROFESSIONAL_CERTIFICATE",
  SET_POSITION_KNOWLEDGES: "SET_POSITION_KNOWLEDGES",
  DELETE_POSITION_KNOWLEDGE: "DELETE_POSITION_KNOWLEDGE",
  ADD_POSITION_KNOWLEDGE: "ADD_POSITION_KNOWLEDGE",
  SET_EMPLOYEES: "SET_EMPLOYEES",
  SET_SEARCHED_EMPLOYEES: "SET_SEARCHED_EMPLOYEES",
  SEARCH_EMPLOYEES: "SEARCH_EMPLOYEES",
  SEARCH_CURRENT_EMPLOYEES: "SEARCH_CURRENT_EMPLOYEES",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
  SET_NATIONAL_ID_DETAIL: "SET_NATIONAL_ID_DETAIL",
  SET_BANKS: "SET_BANKS",
  SET_MEDICAL_INSURANCE_TYPES: "SET_MEDICAL_INSURANCE_TYPES",
  SET_EMPLOYEE: "SET_EMPLOYEE",
  SET_SALARAY_INDEX_GRID: "SET_SALARAY_INDEX_GRID",
  SET_JOB_CLASSIFICATIONS: "SET_JOB_CLASSIFICATIONS",
  UPDATE_JOB_CLASSIFICATION: "UPDATE_JOB_CLASSIFICATION",
  SET_JOB_CLASSIFICATION_ALLOWANCES: "SET_JOB_CLASSIFICATION_ALLOWANCES",
  UPDATE_JOB_CLASSIFICATION_ALLOWANCE: "UPDATE_JOB_CLASSIFICATION_ALLOWANCE",
  DELETE_JOB_CLASSIFICATION_ALLOWANCE: "DELETE_JOB_CLASSIFICATION_ALLOWANCE",
  ADD_JOB_CLASSIFICATION_ALLOWANCE: "ADD_JOB_CLASSIFICATION_ALLOWANCE",
  SET_UNIT_ALLOWANCES: "SET_UNIT_ALLOWANCES",
  SET_UNIT_WITHHOLDS: "SET_UNIT_WITHHOLDS",
  ADD_UNIT_ALLOWANCE: "ADD_UNIT_ALLOWANCE",
  UPDATE_UNIT_ALLOWANCE: "UPDATE_UNIT_ALLOWANCE",
  DELETE_UNIT_ALLOWANCE: "DELETE_UNIT_ALLOWANCE",
  ADD_UNIT_WITHHOLD: "ADD_UNIT_WITHHOLD",
  UPDATE_UNIT_CREDITOR: "UPDATE_UNIT_CREDITOR",
  DELETE_UNIT_WITHHOLD: "DELETE_UNIT_WITHHOLD",
  SET_JOB_FIELDS: "SET_JOB_FIELDS",
  ADD_JOB_FIELD: "ADD_JOB_FIELD",
  UPDATE_JOB_FIELD: "UPDATE_JOB_FIELD",
  DELETE_JOB_FIELD: "DELETE_JOB_FIELD",
  SET_ENTITY_SECTORS: "SET_ENTITY_SECTORS",
  UPDATE_SALARY_STRUCTURE: "UPDATE_SALARY_STRUCTURE",
  ADD_SALARY_STRUCTURE: "ADD_SALARY_STRUCTURE",
  DELETE_SALARY_STRUCTURE: "DELETE_SALARY_STRUCTURE",
  SET_VJOB_CLASSIFICATIONS: "SET_VJOB_CLASSIFICATIONS",
  SET_POSITION: "SET_POSITION",
  SET_POSITION_ALLOWANCES: "SET_POSITION_ALLOWANCES",
  ADD_POSITION_ALLOWANCE: "ADD_POSITION_ALLOWANCE",
  DELETE_POSITION_ALLOWANCE: "DELETE_POSITION_ALLOWANCE",
  SET_POSITION_WITHHOLDS: "SET_POSITION_WITHHOLDS",
  ADD_POSITION_WITHHOLD: "ADD_POSITION_WITHHOLD",
  DELETE_POSITION_WITHHOLD: "DELETE_POSITION_WITHHOLD",
  ADD_EMPLOYEE_ALLOWANCE: "ADD_EMPLOYEE_ALLOWANCE",
  SET_EMPLOYEE_ALLOWANCES: "SET_EMPLOYEE_ALLOWANCES",
  DELETE_EMPLOYEE_ALLOWANCE: "DELETE_EMPLOYEE_ALLOWANCE",
  DELETE_EMPLOYEE_WITHHOLD: "DELETE_EMPLOYEE_WITHHOLD",
  SET_EMPLOYEE_WITHHOLDS: "SET_EMPLOYEE_WITHHOLDS",
  ADD_EMPLOYEE_WITHHOLD: "ADD_EMPLOYEE_WITHHOLD",
  UPDATE_ENTITY_SECTOR: "UPDATE_ENTITY_SECTOR",
  SET_JOB_CLASSIFICATION_EMPOYEES: "SET_JOB_CLASSIFICATION_EMPOYEES",
  SET_JOB_CLASSIFICATION_QUALIFICATIONS:
    "SET_JOB_CLASSIFICATION_QUALIFICATIONS",
  ADD_JOB_CLASSIFICATION_QUALIFICATION: "ADD_JOB_CLASSIFICATION_QUALIFICATION",

  DELETE_JOB_CLASSIFICATION_QUALIFICATION:
    "DELETE_JOB_CLASSIFICATION_QUALIFICATION",
  ADD_POSITION_QUALIFICATION: "ADD_POSITION_QUALIFICATION",
  DELETE_POSITION_QUALIFICATION: "DELETE_POSITION_QUALIFICATION",
  ADD_JOB_CLASSIFICATION_FIELD: "ADD_JOB_CLASSIFICATION_FIELD",
  DELETE_JOB_CLASSIFICATION_FIELD: "DELETE_JOB_CLASSIFICATION_FIELD",
  DELETE_JOB_CLASSIFICATION_COMPETENCY: "DELETE_JOB_CLASSIFICATION_COMPETENCY",
  SET_JOB_CLASSIFICATION_COMPETENCIES: "SET_JOB_CLASSIFICATION_COMPETENCIES",
  UPDATE_JOB_CLASSIFICATION_COMPETENCY: "UPDATE_JOB_CLASSIFICATION_COMPETENCY",
  SET_COMPETENCIES: "SET_COMPETENCIES",
  SET_CERTIFICATE_FIELDS: "SET_CERTIFICATE_FIELDS",
  SET_CERTIFICATES: "SET_CERTIFICATES",
  UPDATE_JOB_CLASSIFICATION_CERTIFICATE:
    "UPDATE_JOB_CLASSIFICATION_CERTIFICATE",
  DELETE_JOB_CLASSIFICATION_CERTIFICATE:
    "DELETE_JOB_CLASSIFICATION_CERTIFICATE",
  SET_JOB_CLASSIFICATION_CERTIFICATES: "SET_JOB_CLASSIFICATION_CERTIFICATES",
  SET_POSITION_QUALIFICATIONS: "SET_POSITION_QUALIFICATIONS",
  SET_POSITION_CERTIFICATES: "SET_POSITION_CERTIFICATES",
  UPDATE_POSITION_CERTIFICATE: "UPDATE_POSITION_CERTIFICATE",
  SET_POSITION_COMPETENCIES: "SET_POSITION_COMPETENCIES",
  UPDATE_POSITION_COMPETENCY: "UPDATE_POSITION_COMPETENCY",
  UPDATE_EMPLOYEE_WITHHOLD: "UPDATE_EMPLOYEE_WITHHOLD",
  UPDATE_POSITION_WITHHOLD: "UPDATE_POSITION_WITHHOLD",
  UPDATE_UNIT_WITHHOLD: "UPDATE_UNIT_WITHHOLD",
  SET_ENTITY_SECTOR_ALLOWANCES: "SET_ENTITY_SECTOR_ALLOWANCES",
  ADD_ENTITY_SECTOR_ALLOWANCE: "ADD_ENTITY_SECTOR_ALLOWANCE",
  DELETE_ENTITY_SECTOR_ALLOWANCE: "DELETE_ENTITY_SECTOR_ALLOWANCE",
  SET_SHARED_SCOPES: "SET_SHARED_SCOPES",
  SET_SHARED_POSITION_ALLOWANCES: "SET_SHARED_POSITION_ALLOWANCES",
  ADD_SHARED_POSITION_ALLOWANCE: "ADD_SHARED_POSITION_ALLOWANCE",
  DELETE_SHARED_POSITION_ALLOWANCE: "DELETE_SHARED_POSITION_ALLOWANCE",
  DELETE_SHARED_POSITION_WITHHOLD: "DELETE_SHARED_POSITION_WITHHOLD",
  UPDATE_SHARED_POSITION_WITHHOLD: "UPDATE_SHARED_POSITION_WITHHOLD",
  ADD_SHARED_POSITION_WITHHOLD: "ADD_SHARED_POSITION_WITHHOLD",
  SET_SHARED_POSITION_WITHHOLDS: "SET_SHARED_POSITION_WITHHOLDS",
  SET_VERIFY_RSSB_NUMBER: "SET_VERIFY_RSSB_NUMBER",
  PREVIEW_EMPLOYEE_PAYSLIP: "PREVIEW_EMPLOYEE_PAYSLIP",
  SET_ALLOWANCE_TYPES: "SET_ALLOWANCE_TYPES",
  SET_JOBS: "SET_JOBS",
  ADD_JOB: "ADD_JOB",
  UPDATE_JOB: "UPDATE_JOB",
  DELETE_JOB: "DELETE_JOB",
  SET_LEVELS: "SET_LEVELS",
  UPDATE_LEVEL: "UPDATE_LEVEL",
  SET_ENTITY_CLASSES: "SET_ENTITY_CLASSES",
  SET_JOB_CATEGORIES: "SET_JOB_CATEGORIES",

  SET_EMPLOYEE_BANK_ACCOUNTS: "SET_EMPLOYEE_BANK_ACCOUNTS",
  DELETE_EMPLOYEE_BANK_ACCOUNT: "DELETE_EMPLOYEE_BANK_ACCOUNT",

  SET_EMPLOYEE_ARREARS: "SET_EMPLOYEE_ARREARS",
  DELETE_EMPLOYEE_ARREAR: "DELETE_EMPLOYEE_ARREAR",
  ADD_EMPLOYEE_ARREAR: "ADD_EMPLOYEE_ARREAR",

  SET_PERIODS: "SET_PERIODS",
  SET_RELATIONSHIPS: "SET_RELATIONSHIPS",

  SET_EMPLOYEE_PROFILE: "SET_EMPLOYEE_PROFILE",
  SET_EMPLOYEE_NEXT_OF_KIN: "SET_EMPLOYEE_NEXT_OF_KIN",
  ADD_EMPLOYEE_EMPLOYMENT: "ADD_EMPLOYEE_EMPLOYMENT",
  REMOVE_EMPLOYEE_EMPLOYMENT: "REMOVE_EMPLOYEE_EMPLOYMENT",
  // SET_EMPLOYEE_PROFILE_EMPLOYMENT: "SET_EMPLOYEE_PROFILE_EMPLOYMENT",
  // SET_EMPLOYEE_PROFILE_EDUCATIONS: "SET_EMPLOYEE_PROFILE_EDUCATIONS",
  // SET_EMPLOYEE_PROFILE_CERTIFICATES: "SET_EMPLOYEE_PROFILE_CERTIFICATES",

  SET_EMPLOYEE_QUALIFICATIONS: "SET_EMPLOYEE_QUALIFICATIONS",
  ADD_EMPLOYEE_QUALIFICATION: "ADD_EMPLOYEE_QUALIFICATION",
  REMOVE_EMPLOYEE_QUALIFICATION: "REMOVE_EMPLOYEE_QUALIFICATION",
  UPDATE_EMPLOYEE_QUALIFICATION: "UPDATE_EMPLOYEE_QUALIFICATION",
  SET_COUNTRIES: "SET_COUNTRIES",
  SET_SCHOOLS: "SET_SCHOOLS",

  SET_EMPLOYEE_CERTIFICATES: "SET_EMPLOYEE_CERTIFICATES",
  ADD_EMPLOYEE_CERTIFICATE: "ADD_EMPLOYEE_CERTIFICATE",
  REMOVE_EMPLOYEE_CERTIFICATE: "REMOVE_EMPLOYEE_CERTIFICATE",

  SET_EMPLOYEE_PROMOTIONS: "SET_EMPLOYEE_PROMOTIONS",
  // UPDATE_EMPLOYEE_PROMOTION: "UPDATE_EMPLOYEE_PROMOTION",
  SET_ENTITIES_OVERSIGHTS: "SET_ENTITIES_OVERSIGHTS",

  UPDATE_ENTITIES_OVERSIGHTS: "UPDATE_ENTITIES_OVERSIGHTS",

  SET_ORGANIGRAM_DATA: "SET_ORGANIGRAM_DATA",
  SET_NON_ADDED_POSITION_CERTIFICATES: "SET_NON_ADDED_POSITION_CERTIFICATES",
  CHANGE_ENTITY_STATUS_OVERSIGHT: "CHANGE_ENTITY_STATUS_OVERSIGHT",
  SET_PAYMENT_STATUSES: "SET_PAYMENT_STATUSES",
  SET_EMPLOYEES_PULL_REQUESTS: "SET_EMPLOYEES_PULL_REQUESTS",
  REMOVE_EMPLOYEE_PULL_REQUEST: "REMOVE_EMPLOYEE_PULL_REQUEST",
  SET_EMPLOYEE_EMPLOYMENTS: "SET_EMPLOYEE_EMPLOYMENTS",
  UPDATE_EMPLOYEE_EMPLOYMENT: "UPDATE_EMPLOYEE_EMPLOYMENT",
  UPDATE_EMPLOYEE_CERTIFICATE: "UPDATE_EMPLOYEE_CERTIFICATE",
  ADD_REFERENCE: "ADD_REFERENCE",
  SET_REFERENCES: "SET_REFERENCES",
  SET_EMPLOYEE_LANGUAGES: "SET_EMPLOYEE_LANGUAGES",
  SET_LANGUAGES: "SET_LANGUAGES",
  REMOVE_REFERENCE: "REMOVE_REFERENCE",
  REMOVE_EMPLOYEE_LANGUAGE: "REMOVE_EMPLOYEE_LANGUAGE",
  ADD_EMPLOYEE_LANGUAGE: "ADD_EMPLOYEE_LANGUAGE",
  SET_DISABILITY: "SET_DISABILITY",
  SET_DISABILITY_LEVELS: "SET_DISABILITY_LEVELS",
  REMOVE_EMPLOYEE_DISABILITY: "REMOVE_EMPLOYEE_DISABILITY",
  ADD_EMPLOYEE_DISABILITY: "ADD_EMPLOYEE_DISABILITY",
  SET_EMPLOYEE_DISABILITIES: "SET_EMPLOYEE_DISABILITIES",
  SET_EMPLOYEE_NEXT_KEN: "SET_EMPLOYEE_NEXT_KEN",
  UPDATE_EMPLOYEE_DISABILITY: "UPDATE_EMPLOYEE_DISABILITY",
  UPDATE_EMPLOYEE_LANGUAGE: "UPDATE_EMPLOYEE_LANGUAGE",
  UPDATE_REFERENCE: "UPDATE_REFERENCE",
  SET_SEARCHED_USER_DETAILS: "SET_SEARCHED_USER_DETAILS",
  SET_SEARCHED_USER_SECOND_EMPLOYMENT: "SET_SEARCHED_USER_SECOND_EMPLOYMENT",
  SET_HEAD_OF_INSTITUTION: "SET_HEAD_OF_INSTITUTION",
  SET_HEAD_OF_UNIT: "SET_HEAD_OF_UNIT",

  SET_MISSING_EMPLOYEE_DATA_SUMMARY: "SET_MISSING_EMPLOYEE_DATA_SUMMARY",
  SET_MISSING_EMPLOYEE_DATA: "SET_MISSING_EMPLOYEE_DATA",
  REMOVE_MISSING_EMPLOYEE: "REMOVE_MISSING_EMPLOYEE",
  ADD_NON_ADDED_POSITION_CERTIFICATE: "ADD_NON_ADDED_POSITION_CERTIFICATE",
  REMOVE_NON_ADDED_POSITION_CERTIFICATE:
    "REMOVE_NON_ADDED_POSITION_CERTIFICATE",
  ADD_POSITION_CERTIFICATE: "ADD_POSITION_CERTIFICATE",
  REMOVE_POSITION_CERTIFICATE: "REMOVE_POSITION_CERTIFICATE",
  REMOVE_POSITION_COMPETENCY: "REMOVE_POSITION_COMPETENCY",
  SET_NON_ADDED_POSITION_COMPETENCIES: "SET_NON_ADDED_POSITION_COMPETENCIES",
  ADD_NON_ADDED_POSITION_COMPETENCY: "ADD_NON_ADDED_POSITION_COMPETENCY",
  REMOVE_NON_ADDED_POSITION_COMPETENCY: "REMOVE_NON_ADDED_POSITION_COMPETENCY",
  ADD_POSITION_COMPETENCY: "ADD_POSITION_COMPETENCY",
  SET_RRA_GRADES: "SET_RRA_GRADES",

  SET_RRA_DEPARTMENTS: "SET_RRA_DEPARTMENTS",
  ADD_OR_UPDATE_RRA_DEPARTMENT: "ADD_OR_UPDATE_RRA_DEPARTMENT",
  DELETE_RRA_DEPARTMENT: "DELETE_RRA_DEPARTMENT",
  SET_NO_OF_EMPLOYEE_REQUESTS: "SET_NO_OF_EMPLOYEE_REQUESTS",
  SET_EMPLOYEE_REQUESTS: "SET_EMPLOYEE_REQUESTS",
  UPDATE_EMPLOYEE_REQUEST: "UPDATE_EMPLOYEE_REQUEST",

  SET_CASUAL_SALARIES: "SET_CASUAL_SALARIES",
  ADD_CASUAL_SALARY: "ADD_CASUAL_SALARY",
  DELETE_CASUAL_SALARY: "DELETE_CASUAL_SALARY",
  SET_VERTICAL_RANKS: "SET_VERTICAL_RANKS",
  SET_ENTITY_SECTOR_PLANNING_ENTITY: "SET_ENTITY_SECTOR_PLANNING_ENTITY",
  SET_LETTER_CUSTOMIZATION:"SET_LETTER_CUSTOMIZATION"
};

export default types;
