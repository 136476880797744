import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import TableSkeleton from "../../common/components/TableSkeleton";
import {
  downloadAllInstitutionsPerformanceReport,
  downloadInstitutionEmployeesScores,
  downloadInstitutionPerformanceReport,
  getRBMDashboardData,
  getRBMDashboardSummary,
} from "../../../store/rbm/actions";
import {
  DashboardDoughnutChart,
  DashboardLabel,
  DashboardPieChart,
} from "../../admin/components/StructureDashboard";
import CustomProgressLine from "../components/CustomProgressLine";
import {
  AppBar,
  Autocomplete,
  Box,
  CircularProgress,
  Skeleton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import InstitutionOutputs from "../components/InstitutionOutputs";

const Dashboard = ({
  loading,
  getRBMDashboardData,
  getRBMDashboardSummary,
  downloadAllInstitutionsPerformanceReport,
  downloadInstitutionPerformanceReport,
  downloadInstitutionEmployeesScores,
}) => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingSummary, setLoadingSummary] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardSummary, setDashboardSummary] = useState({
    entities: 0,
    plannedEntities: 0,
    notPlannedEntities: 0,
    planningProcess: 0,
    reportingProgress: 0,
    evaluationProgress: 0,
    scoringProgress: 0,
    indashyikirwa: 0,
    inkomezamihigo: 0,
    inkangurirwamihigo: 0,
    unspecified: 0,
  });
  const [selectedQuarter, setSelectedQuarter] = useState({
    id: "all",
    name: "All Quarter",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [showEntityOutput, setShowEntityOutput] = useState(null);
  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loader, setLoader] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(10);

  const handleLoadingData = (quarterId, query, setLoading) => {
    getRBMDashboardData(quarterId, query, (status, data) => {
      if (setLoading) setLoading(status);
      else setLoadingData(status);
      if (data && !status) {
        setDashboardData(data.records);
        setTotalRecords(data.totalRecords);
      }
    });
  };

  const handleLoadingSummary = (quarterId) => {
    getRBMDashboardSummary(quarterId, (status, data) => {
      setLoadingSummary(status);
      if (data && !status) setDashboardSummary(data);
    });
  };

  useEffect(() => {
    document.title = "IPPIS RBM - Dashboard & Oversight";
    if (!loading) {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };
      handleLoadingData("all", query);
      handleLoadingSummary("all");
    }
    return () => {
      setLoadingData(true);
      setDashboardData([]);
      setDashboardSummary({
        entities: 0,
        plannedEntities: 0,
        notPlannedEntities: 0,
        planningProcess: 0,
        reportingProgress: 0,
        evaluationProgress: 0,
        scoringProgress: 0,
        indashyikirwa: 0,
        inkomezamihigo: 0,
        inkangurirwamihigo: 0,
        unspecified: 0,
      });
      setSelectedQuarter({
        id: "all",
        name: "All Quarter",
      });
      setSearchTerm("");
    };
  }, []);

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };

      handleLoadingData(selectedQuarter.id, query, setLoader);
    }
  };

  const PLANNING_PIE_CHART = {
    labels: ["Planned", "Not Planned"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          dashboardSummary.plannedEntities || 0,
          dashboardSummary.notPlannedEntities || 0,
        ],
        backgroundColor: ["rgba(7, 142, 206, 0.7)", "rgba(255, 193, 7, 0.6)"],
        borderColor: ["rgba(7, 142, 206, 1)", "rgba(255, 193, 7, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const ACTION_PLAN_PIE_CHART = {
    labels: ["Ontrack", "Overdue", "Reported", "Scored", "Overdue"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          dashboardSummary.ontrack,
          dashboardSummary.due,
          dashboardSummary.reported,
          dashboardSummary.scored,
          dashboardSummary.rejected,
        ],
        backgroundColor: [
          "rgba(187, 187, 187, 0.5)",
          "rgba(255, 193, 7, 0.6)",
          "rgba(7, 142, 206, 0.7)",
          "rgba(40, 167, 69, 0.6)",
          "rgba(139, 0, 0, 0.6)",
        ],
        borderColor: [
          "rgba(187, 187, 187, 1)",
          "rgba(255, 193, 7, 1)",
          "rgba(7, 142, 206, 1)",
          "rgba(40, 167, 69, 1)",
          "rgba(139, 0, 0, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const PERFORMANCE_DOUGHNUT_CHART = {
    labels: [
      "Indashyikirwa",
      "Inkomezamihigo",
      "Inkangurirwamihigo",
      "Unspecified",
    ],
    datasets: [
      {
        data: [
          dashboardSummary.indashyikirwa,
          dashboardSummary.inkomezamihigo,
          dashboardSummary.inkangurirwamihigo,
          dashboardSummary.unspecified,
        ],
        backgroundColor: [
          "rgba(80, 181, 88,0.6)",
          "rgba(7, 142, 206, 0.7)",
          "rgba(255, 193, 7, 0.6)",
          "rgba(139, 0, 0, 0.5)",
          "rgba(80, 0, 88,0.6)",
        ],
        borderColor: [
          "rgba(80, 181, 88, 1)",
          "rgba(7, 142, 206, 1)",
          "rgba(255, 193, 7, 1)",
          "rgba(139, 0, 0, 1)",
          "rgba(80, 0, 88, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    handleLoadingData(selectedQuarter.id, query, setLoader);
  };

  return (
    <div className="container-fluid">
      <AppBar
        position="static"
        elevation={0}
        className="app-bar w-100 mt-3 mb-2"
      >
        <Toolbar className="bg-light border">
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <span>RBM DASHBOARD</span>
          </Typography>

          <Autocomplete
            sx={{ width: 180 }}
            className="ml-2"
            size="small"
            disabled={loadingData || loading || loader}
            disablePortal
            disableClearable
            value={selectedQuarter}
            options={[
              {
                id: "all",
                name: "All Quarter",
              },
              {
                id: 1,
                name: "Quarter 1",
              },
              {
                id: 2,
                name: "Quarter 2",
              },
              {
                id: 3,
                name: "Quarter 3",
              },
              {
                id: 4,
                name: "Quarter 4",
              },
            ]}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.name}
            onChange={(e, quarter) => {
              const query = {
                searchTerm: searchTerm,
                page: currentPage,
                size: itemPerPage,
              };
              setSelectedQuarter(quarter);
              handleLoadingData(quarter.id, query, setLoader);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Quarter"
                placeholder="Quarter"
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name}
              </Box>
            )}
          />
        </Toolbar>
      </AppBar>

      {/* labels */}
      <div className="d-flex flex-wrap justify-content-center w-100">
        <DashboardLabel
          labelTitle="Entities"
          labelValue={dashboardSummary.entities}
          loading={loadingSummary}
        />
        <DashboardLabel
          labelTitle="Planned Entities"
          labelValue={dashboardSummary.plannedEntities}
          loading={loadingSummary}
        />
        <DashboardLabel
          labelTitle="Not Planned Entities"
          labelValue={dashboardSummary.notPlannedEntities}
          loading={loadingSummary}
        />
        <DashboardLabel
          labelTitle="Planning Progress"
          labelValue={dashboardSummary.planningProcess}
          valueType="percentage"
          loading={loadingSummary}
        />
        <DashboardLabel
          labelTitle="Reporting Progress"
          labelValue={dashboardSummary.reportingProgress}
          valueType="percentage"
          showProgressBar
          loading={loadingSummary}
        />
        {/* <DashboardLabel
          labelTitle="Evaluation Progress"
          labelValue={
            dashboardSummary.evaluationProgress
          }
          valueType="percentage"
          showProgressBar
          loading={loadingSummary}
        /> */}
        <DashboardLabel
          labelTitle="Scoring Progress"
          labelValue={dashboardSummary.scoringProgress}
          valueType="percentage"
          showProgressBar
          loading={loadingSummary}
        />
        <DashboardLabel
          labelTitle="Indashyikirwa"
          labelValue={dashboardSummary.indashyikirwa}
          // valueType="percentage"
          // showProgressBar
          loading={loadingSummary}
        />
        <DashboardLabel
          labelTitle="Inkomezamihigo"
          labelValue={dashboardSummary.inkomezamihigo}
          // valueType="percentage"
          // showProgressBar
          loading={loadingSummary}
        />
        <DashboardLabel
          labelTitle="Inkangurirwamihigo"
          labelValue={dashboardSummary.inkangurirwamihigo}
          // valueType="percentage"
          // showProgressBar
          loading={loadingSummary}
        />
        <DashboardLabel
          labelTitle="Unspecified"
          labelValue={dashboardSummary.unspecified}
          // valueType="percentage"
          // showProgressBar
          loading={loadingSummary}
        />
      </div>

      {/* pie and doughnut charts */}
      <div className="row justify-content-center w-100 my-3 px-4">
        <div className="col-12 col-md-6 col-lg-4">
          <DashboardPieChart
            pieTitle="Planning status"
            pieData={PLANNING_PIE_CHART}
            loading={loadingSummary}
          />
        </div>

        <div className="col-12 col-md-6 col-lg-4 my-2 my-md-0">
          <DashboardPieChart
            pieTitle="Action Plan status"
            pieData={ACTION_PLAN_PIE_CHART}
            loading={loadingSummary}
          />
        </div>

        <div className="col-12 col-md-6 col-lg-4 my-md-0 my-2">
          <DashboardDoughnutChart
            chartTitle="Performance status"
            chartData={PERFORMANCE_DOUGHNUT_CHART}
            loading={loadingSummary}
          />
        </div>
      </div>

      {/* quarter progress statistics */}
      {false && (
        <div
          className="rounded table-responsive py-2 px-2 dashboard-card mt-5 mb-4"
          style={{ border: "1px solid rgb(198 205 209)" }}
        >
          <table className="table table-sm">
            <caption style={{ backgroundColor: "#e9ecef" }} className="mb-2">
              Quarter Progress Status
            </caption>
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{
                    borderLeft: "3px solid #dee2e6",
                  }}
                ></th>
                <th
                  className="text-center"
                  // style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Reporting Progress
                </th>
                <th
                  className="text-center"
                  style={{
                    borderLeft: "15px solid #dee2e6",
                    borderRight: "3px solid #dee2e6",
                  }}
                >
                  Evaluation Progress
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th
                  className="text-center"
                  style={{
                    fontSize: 19,
                    borderLeft: "3px solid #dee2e6",
                  }}
                >
                  Q1
                </th>
                <td style={{ borderLeft: "3px solid #dee2e6" }}>
                  <ReportingProgress
                    pending={23}
                    overdue={1}
                    reported={23}
                    loading={loadingData}
                  />
                </td>
                <td
                  style={{
                    borderLeft: "15px solid #dee2e6",
                    borderRight: "3px solid #dee2e6",
                  }}
                >
                  <CustomProgressLine value={12} height={28} />
                </td>
              </tr>
              <tr>
                <th
                  className="text-center"
                  style={{
                    fontSize: 19,
                    borderLeft: "3px solid #dee2e6",
                  }}
                >
                  Q2
                </th>
                <td style={{ borderLeft: "3px solid #dee2e6" }}>
                  <ReportingProgress
                    pending={18}
                    overdue={8}
                    reported={20}
                    loading={loadingData}
                  />
                </td>
                <td
                  style={{
                    borderLeft: "15px solid #dee2e6",
                    borderRight: "3px solid #dee2e6",
                  }}
                >
                  <CustomProgressLine value={85} height={28} />
                </td>
              </tr>
              <tr>
                <th
                  className="text-center"
                  style={{
                    fontSize: 19,
                    borderLeft: "3px solid #dee2e6",
                  }}
                >
                  Q3
                </th>
                <td style={{ borderLeft: "3px solid #dee2e6" }}>
                  <ReportingProgress
                    pending={12}
                    overdue={0}
                    reported={8}
                    loading={loadingData}
                  />
                </td>
                <td
                  style={{
                    borderLeft: "15px solid #dee2e6",
                    borderRight: "3px solid #dee2e6",
                  }}
                >
                  <CustomProgressLine value={56} height={28} />
                </td>
              </tr>
              <tr>
                <th
                  className="text-center"
                  style={{
                    fontSize: 19,
                    borderLeft: "3px solid #dee2e6",
                    borderBottom: "3px solid #dee2e6",
                  }}
                >
                  Q4
                </th>
                <td
                  style={{
                    borderLeft: "3px solid #dee2e6",
                    borderBottom: "3px solid #dee2e6",
                  }}
                >
                  <ReportingProgress
                    pending={18}
                    overdue={5}
                    reported={0}
                    loading={loadingData}
                  />
                </td>
                <td
                  style={{
                    borderLeft: "15px solid #dee2e6",
                    borderRight: "3px solid #dee2e6",
                    borderBottom: "3px solid #dee2e6",
                  }}
                >
                  <CustomProgressLine value={0} height={28} />
                </td>
              </tr>
            </tbody>
          </table>
          <div
            className="d-inline-flex justify-content-center w-100"
            style={{ fontSize: 12, marginTop: "-8px !important" }}
          >
            <div
              className="badge-info text-center px-2 py-1 mx-1"
              style={{ minWidth: 65 }}
            >
              Pending
            </div>
            <div
              className="badge-warning text-center px-2 py-1 mx-1"
              style={{ minWidth: 65 }}
            >
              Overdue
            </div>
            <div
              className="badge-success text-center px-2 py-1 mx-1"
              style={{ minWidth: 65 }}
            >
              Reported
            </div>
          </div>
        </div>
      )}

      {/* RBM Oversight */}
      <div
        className="rounded table-responsive py-2 px-2 dashboard-card mt-6 mb-4"
        style={{ border: "1px solid rgb(198 205 209)" }}
      >
        <table className="table table-sm">
          <caption style={{ backgroundColor: "#e9ecef" }} className="absolute">
            RBM Oversight
            <button
              className="btn btn-sm btn-primary float-right mx-2"
              onClick={() => downloadAllInstitutionsPerformanceReport()}
              disabled={loading || loadingData || loader}
            >
              All Institutions
            </button>
          </caption>
          {/* <caption className="w-100">
          <RBMPageHeader
            labels={[
              {
                title: "Institutions",
                value: dashboardSummary.entities : 0,
                type: "number",
              },
              {
                title: "Items(Activities)",
                value: dashboardSummary.TOTAL_ITEMS : 0,
                type: "number",
              },              
              {
                title: "Reported",
                value: dashboardSummary.TOTAL_REPORTED : 0,
                type: "number",
              },{
                title: "Scored",
                value: dashboardSummary.TOTAL_SCORED : 0,
                type: "number",
              },
              {
                title: "Due",
                value: dashboardSummary.TOTAL_OVERDUE : 0,
                type: "number",
              },{
                title: "Target achieved",
                value: (dashboardSummary.TOTAL_ACHIEVED : 0).toFixed(2),
                type: "number",
              },
              // {
              //   title: "Progress",
              //   value: (dashboardSummary.entities : 0).toFixed(2),
              //   type: "percentage",
              // },
            ]}
            progress={{
              title: "ACTIVITIES SUMMARY",
              value: 0,
            }}
            // disabled={loading}
            disabled={true}
            loading={loading}
          />
          </caption> */}
          <caption className="w-100">
            {/* <div className="w-100"> */}
            <input
              type="text"
              value={searchTerm}
              className="form-control"
              id="filter-entity"
              placeholder="Search..."
              onChange={(e) => onSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const query = {
                    searchTerm: searchTerm,
                    page: currentPage,
                    size: itemPerPage,
                  };
                  handleLoadingData(selectedQuarter.id, query, setLoader);
                }
              }}
              disabled={loadingData || !dashboardData || loader}
            />
            {/* </div> */}
          </caption>
          <thead>
            <tr>
              <th
                className="text-center"
                style={{ borderLeft: "3px solid #dee2e6" }}
              >
                #
              </th>
              <th
                className="text-center"
                style={{ borderLeft: "3px solid #dee2e6" }}
              >
                Institutions
              </th>
              <th
                className="text-center"
                style={{ borderLeft: "3px solid #dee2e6" }}
              >
                Items
              </th>
              <th
                className="text-center"
                style={{
                  borderLeft: "3px solid #dee2e6",
                  background: "#ddd",
                }}
              >
                OnTrack
              </th>
              <th
                className="text-center"
                style={{
                  borderLeft: "3px solid #dee2e6",
                  background: "#ffac07",
                }}
              >
                Overdue
              </th>
              <th
                className="text-center"
                style={{
                  borderLeft: "3px solid #dee2e6",
                  background: "#278fff",
                }}
              >
                Reported
              </th>
              <th
                className="text-center"
                style={{
                  borderLeft: "3px solid #dee2e6",
                  background: "#1bbcd6",
                }}
              >
                Scored
              </th>
              {/* <th
                className="text-center"
                style={{
                  borderLeft: "3px solid #dee2e6",
                  background: "#27c04a",
                }}
              >
                Target Achieved
              </th> */}
              <th
                className="text-center"
                style={{
                  borderLeft: "3px solid #dee2e6",
                  borderRight: "3px solid #dee2e6",
                }}
              >
                Actions
              </th>
            </tr>
          </thead>
          {!loadingData ? (
            <tbody style={{ fontSize: "13px" }}>
              {!!dashboardData.length ? (
                dashboardData.map((item, index) => (
                  <tr key={item.no}>
                    <td
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderBottom:
                          index + 1 === dashboardData.length
                            ? "3px solid #dee2e6"
                            : "none",
                      }}
                    >
                      {item.no}
                    </td>
                    <td
                      className="text-left"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderBottom:
                          index + 1 === dashboardData.length
                            ? "3px solid #dee2e6"
                            : "none",
                      }}
                    >
                      <div className="d-inline-flex justify-content-between align-items-center w-100">
                        {item.entityName}

                        {item.progress !== null && (
                          <div style={{ minWidth: 80 }}>
                            <CustomProgressLine value={item.progress || 0} />
                          </div>
                        )}
                      </div>
                    </td>
                    <td
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderBottom:
                          index + 1 === dashboardData.length
                            ? "3px solid #dee2e6"
                            : "none",
                      }}
                    >
                      {item.numOfItems || "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderBottom:
                          index + 1 === dashboardData.length
                            ? "3px solid #dee2e6"
                            : "none",
                        background: "#ebecedc7",
                      }}
                    >
                      {item.onTrack || "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderBottom:
                          index + 1 === dashboardData.length
                            ? "3px solid #dee2e6"
                            : "none",
                        background: "#ffac0769",
                      }}
                    >
                      {item.overdue || "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderBottom:
                          index + 1 === dashboardData.length
                            ? "3px solid #dee2e6"
                            : "none",
                        background: "#278fff9c",
                      }}
                    >
                      {item.reported || "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderBottom:
                          index + 1 === dashboardData.length
                            ? "3px solid #dee2e6"
                            : "none",
                        background: "#1bbcd687",
                      }}
                    >
                      {item.scored || "-"}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        borderLeft: "3px solid #dee2e6",
                        borderRight: "3px solid #dee2e6",
                        borderBottom:
                          index + 1 === dashboardData.length
                            ? "3px solid #dee2e6"
                            : "none",
                      }}
                    >
                      <div className="d-inline-flex py-1">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() =>
                            setShowEntityOutput({
                              id: item.entityId,
                              name: item.entityName,
                            })
                          }
                          disabled={loading || loadingData}
                        >
                          Action Plan
                        </button>
                        <button
                          className="btn btn-sm mx-1 btn-primary"
                          onClick={() =>
                            downloadInstitutionPerformanceReport(
                              item.entitySectorId,
                              item.id
                            )
                          }
                          disabled={loading || loadingData}
                        >
                          Institution
                        </button>
                        <button
                          className="btn btn-sm btn-secondary"
                          onClick={() =>
                            downloadInstitutionEmployeesScores(
                              item.entitySectorId,
                              item.id
                            )
                          }
                          disabled={loading || loadingData}
                        >
                          Employees
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="text-center py-4"
                    style={{ border: "3px solid #dee2e6" }}
                    colSpan={8}
                  >
                    <span style={{ fontSize: "17px", fontWeight: "600" }}>
                      Something Went Wrong!
                    </span>
                    <br />
                    <button
                      className="btn btn-sm btn-primary mt-1"
                      onClick={() => {
                        setSearchTerm("");
                        const query = {
                          searchTerm: "",
                          page: 1,
                          size: itemPerPage,
                        };
                        handleLoadingData("all", query, setLoader);
                      }}
                    >
                      Try Again
                    </button>
                  </td>
                </tr>
              )}

              {/* totals */}
              {/* {dashboardData && dashboardData.oversight.length ? (
                <tr>
                  <th
                    style={{ border: "3px solid #dee2e6" }}
                    className="text-center bg-light"
                    colSpan={2}
                  >
                    Total
                  </th>
                  <th
                    style={{ border: "3px solid #dee2e6" }}
                    className="text-center"
                  >
                    {dashboardSummary.TOTAL_ITEMS}
                  </th>
                  <th
                    style={{
                      border: "3px solid #dee2e6",
                      background: "#ffac0780",
                    }}
                    className="text-center"
                  >
                    {dashboardSummary.TOTAL_OVERDUE}
                  </th>
                  <th
                    style={{
                      border: "3px solid #dee2e6",
                      background: "#278fffc7",
                    }}
                    className="text-center"
                  >
                    {dashboardSummary.TOTAL_REPORTED}
                  </th>
                  <th
                    style={{
                      border: "3px solid #dee2e6",
                      background: "#1bbcd6bf",
                    }}
                    className="text-center"
                  >
                    {dashboardSummary.TOTAL_SCORED}
                  </th>
                  <th
                    style={{
                      border: "3px solid #dee2e6",
                      background: "#27c04aa1",
                    }}
                    className="text-center"
                  >
                    {(dashboardSummary.TOTAL_ACHIEVED || 0).toFixed(2)}%
                  </th>
                  <th
                    style={{ border: "3px solid #dee2e6" }}
                    className="text-center bg-light "
                  ></th>
                </tr>
              ) : null} */}
            </tbody>
          ) : (
            <TableSkeleton cols={8} rows={15} />
          )}
        </table>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div className="mt-3">
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                <>
                  <span className="pr-1 d-none d-md-inline">Next</span>
                  <i className="fas fa-angle-double-right"></i>
                </>
              }
              previousLabel={
                <>
                  <i className="fas fa-angle-double-left"></i>
                  <span className="pl-1  d-none d-md-inline">Previous</span>
                </>
              }
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              pageCount={Math.ceil(totalRecords / itemPerPage)}
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
              previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
              nextLinkClassName="btn btn-outline-info btn-sm ml-1"
              activeLinkClassName="active"
            />
          </div>

          {!!dashboardData.length && (
            <select
              className="custom-select custom-select-sm mr-3 ml-2"
              style={{ width: "100px" }}
              value={itemPerPage}
              onChange={(event) => {
                const itemPerPage = +event.target.value;
                setItemPerPage(itemPerPage);
                const query = {
                  searchTerm: searchTerm,
                  page: currentPage,
                  size: itemPerPage,
                };
                handleLoadingData(selectedQuarter.id, query, setLoader);
              }}
            >
              {[5, 10, 50].map((item, index) => (
                <option key={index}>{item}</option>
              ))}
            </select>
          )}
        </div>
      </div>
      {showEntityOutput && (
        <InstitutionOutputs
          entity={showEntityOutput}
          onCloseEntity={() => setShowEntityOutput(null)}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loader ? "d-block" : "d-none"
        }`}
      >
        {loader && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return { loading, user };
};
const mapDispatchToProps = {
  getRBMDashboardData,
  getRBMDashboardSummary,
  downloadAllInstitutionsPerformanceReport,
  downloadInstitutionPerformanceReport,
  downloadInstitutionEmployeesScores,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const ReportingProgress = ({ pending, overdue, reported, loading }) => {
  if (loading)
    return (
      <div className="d-inline-flex align-items-center w-100 mt-1">
        <Skeleton variant="rectangular" width="100%" height={29} />
        <Skeleton variant="rectangular" width="100%" height={29} />
        <Skeleton variant="rectangular" width="100%" height={29} />
      </div>
    );

  const TOTAL = pending + overdue + reported;
  const result = {
    pending: Number(((pending * 100) / TOTAL).toFixed(1)),
    overdue: Number(((overdue * 100) / TOTAL).toFixed(1)),
    reported: Number(((reported * 100) / TOTAL).toFixed(1)),
  };
  return (
    <div
      className="d-inline-flex align-items-center w-100"
      style={{ fontSize: "12px" }}
    >
      {result.pending > 0 ? (
        <div
          className={`${
            result.pending > 4 ? "px-1" : ""
          } text-center badge-info d-inline-flex justify-content-center align-items-center`}
          style={{ width: `${result.pending}%`, height: 30, fontWeight: "600" }}
          title={`${result.pending}% pending`}
        >
          {result.pending}
          {result.pending > 4 ? "%" : ""}
        </div>
      ) : null}
      {result.overdue > 0 ? (
        <div
          className={`${
            result.overdue > 4 ? "px-1" : ""
          } text-center badge-warning d-inline-flex justify-content-center align-items-center`}
          style={{ width: `${result.overdue}%`, height: 30, fontWeight: "600" }}
          title={`${result.overdue}% overdue`}
        >
          {result.overdue}
          {result.overdue > 4 ? "%" : ""}
        </div>
      ) : null}
      {result.reported > 0 ? (
        <div
          className={`${
            result.reported > 4 ? "px-1" : ""
          } text-center badge-success d-inline-flex justify-content-center align-items-center`}
          style={{
            width: `${result.reported}%`,
            height: 30,
            fontWeight: "600",
          }}
          title={`${result.reported}% reported`}
        >
          {result.reported}
          {result.reported > 4 ? "%" : ""}
        </div>
      ) : null}
    </div>
  );
};
