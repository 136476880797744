import {
  IconButton,
  TextField,
  Autocomplete,
  Divider,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Skeleton,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  saveUnitAllowance,
  getUnitAllowances,
  deleteUnitAllowance,
} from "../../../store/structure/actions";
import { getAllowances } from "../../../store/payroll/actions";
import { useTheme } from "@mui/material/styles";
import { showError } from "../../../app/toastify";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const UnitAllowances = (props) => {
  const {
    loading,
    selectedUnit,
    getAllowances,
    saveUnitAllowance,
    getUnitAllowances,
    deleteUnitAllowance,
    unitAllowances,
    allowances,
    showUnitAllowanceDialog,
    setShowUnitAllowanceDialog,
  } = props;

  const [allowance, setAllowance] = useState(null);
  const [selectedUnitAllowance, setSelectedUnitAllowance] = useState(null);
  const [confirmRemoveUnitAllowance, setConfirmRemoveUnitAllowance] =
    useState(false);
  const theme = useTheme();

  const [formData, setFormData] = useState({
    unitId: selectedUnit.unitId,
    allowanceId: "",
  });

  const [add, setAdd] = useState(false);

  const [errors, setErrors] = useState({
    allowanceHasError: false,
  });

  useEffect(() => {
    getAllowances();
    getUnitAllowances(selectedUnit.unitId);

    setAdd(add);
  }, [selectedUnit]);

  useEffect(() => {
    const add = unitAllowances.length > 0 ? false : true;

    setAdd(add);
  }, [unitAllowances]);

  const formValidator = () => {
    const error = {
      allowanceHasError: false,
      hasError: false,
    };

    if (!formData.allowanceId) {
      error.allowanceHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveUnitAllowance(formData, setFormData, setAdd, setAllowance);
    }
  };

  const onClose = () => {
    setShowUnitAllowanceDialog(false);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showUnitAllowanceDialog}
      fullWidth
    >
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          Allowances @{selectedUnit.unitName}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div>
          {confirmRemoveUnitAllowance && (
            <ConfirmationDialog
              confirmationDialog={confirmRemoveUnitAllowance}
              message={`Are you sure you want to remove this allowance @${
                selectedUnitAllowance.allowance &&
                selectedUnitAllowance.allowance.name
              }?`}
              setConfirmationDialog={setConfirmRemoveUnitAllowance}
              onYes={() => {
                deleteUnitAllowance(
                  selectedUnitAllowance,
                  setConfirmRemoveUnitAllowance,
                  setSelectedUnitAllowance
                );
              }}
            />
          )}

          {loading && (
            <div className="container mt-2" style={{ width: "100%" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </div>
          )}

          {!loading && (
            <div>
              {!add && (
                <Button
                  className="mb-2"
                  onClick={() => {
                    setAdd(true);
                  }}
                  style={{
                    ...ActiveButton,
                    padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                    float: "right",
                  }}
                  size="sm"
                >
                  <span className="material-icons">add</span>
                </Button>
              )}
              {add && (
                <div
                  className="elevated rounded p-0 mt-3"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #17a2b8",
                  }}
                >
                  {!!allowances.length && (
                    <div className="row mt-3 p-3">
                      <div className="col-12">
                        <Autocomplete
                          size="small"
                          id="allowance"
                          className="mb-3"
                          defaultValue={null}
                          value={allowance}
                          options={allowances}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(event, allowance) => {
                            setAllowance(allowance || null);
                            const allowanceId = allowance ? allowance.id : "";
                            setFormData({ ...formData, allowanceId });
                          }}
                          getOptionLabel={(option) =>
                            option.name + "- RWF " + option.amount
                          }
                          renderOption={(props, allowance) => (
                            <Box component="li" {...props}>
                              {allowance.name} - RWF {allowance.amount}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Allowances"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "allowance", // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />

                        {errors.allowanceHasError && (
                          <div className="text-danger mb-2">
                            Allowance is required{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <Divider className="my-1" />
                  <div className="d-flex justify-content-center py-0 p-2">
                    <button
                      onClick={onSave}
                      type="button"
                      className="btn btn-primary text-uppercase"
                      disabled={loading}
                    >
                      {loading ? "Wait..." : "Save"}
                    </button>{" "}
                    {!!unitAllowances.length && (
                      <React.Fragment>
                        &nbsp; &nbsp;
                        <button
                          onClick={() => setAdd(false)}
                          type="button"
                          className="btn btn-default text-uppercase"
                          disabled={loading}
                        >
                          Cancel
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )}

              {!!unitAllowances.length && (
                <React.Fragment>
                  <table className="table table-bordered mt-3">
                    <thead>
                      <tr>
                        <th colSpan={4} style={{ textAlign: "center" }}>
                          Allowances
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {unitAllowances.map((unitAllowance, uaIndex) => (
                        <tr
                          key={uaIndex}
                          style={{
                            background: uaIndex % 2 === 0 ? "" : "#f6f7f7",
                            borderBottom:
                              "2px dotted rgb(7, 142, 206)!important",
                          }}
                        >
                          <td>{uaIndex + 1}</td>
                          <td>
                            {" "}
                            {unitAllowance.allowance &&
                              unitAllowance.allowance.name}{" "}
                          </td>
                          <td>
                            RWF{" "}
                            {unitAllowance.allowance &&
                              unitAllowance.allowance.amount}{" "}
                          </td>
                          <td>
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => {
                                setSelectedUnitAllowance(unitAllowance);
                                setConfirmRemoveUnitAllowance(true);
                              }}
                              className=" mr-2"
                            >
                              <span className="material-icons">delete</span>
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, allowances, unitAllowances }) => {
  return {
    loading,
    allowances,
    unitAllowances,
  };
};
export default connect(mapStateToProps, {
  saveUnitAllowance,
  getUnitAllowances,
  deleteUnitAllowance,
  getAllowances,
})(UnitAllowances);
