import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { CardContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FindUserDetails from "../../employees/components/FindUserDetails";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const FindEmployeeToUnlock = (props) => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    document.title = "IPPIS - Unlock employee for 30 days";
  });
  const { user } = props;
  const theme = useTheme();
  const { hasPermissions } = useRequiredAuth();
  useEffect(() => {
    if (
      !(
        hasPermissions([
          "IS_TRAINING_MIFOTRA_VIEWER",
          "IS_TRAINING_MIFOTRA_VERIFIER",
        ]) ||
        (hasPermissions(["IS_HEAD_OF_INSTITUTION"]) &&
          user?.selectedEntitySector?.id === "2000000000-010200000000000000-02")
      ) &&
      location.pathname.includes("/training/unlock-employee-for-thirty-days")
    )
      return history.push("/unauthorized");
  }, [user]);
  return (
    <>
      <div className="ml-5 mr-5">
        <Box>
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-12">
                <Typography variant="h6" noWrap component="div">
                  <span>FIND EMPLOYEE</span>
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <FindUserDetails
                canVerifyProfile={false}
                canGiveAccess={false}
                canEditProfile={false}
                canTrainingDatesUnlocked={true}
              />
            </CardContent>
          </div>
        </Box>
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, {})(FindEmployeeToUnlock);
