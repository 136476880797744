import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Autocomplete,
  Box,
  LinearProgress,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getNonAddedPositionCertificates,
  savePositionCertificate,
} from "../../../store/structure/actions";
import { showError } from "../../toastify";

const AddPositionCertificateDialog = (props) => {
  const {
    loading,
    onWait,
    nonAddedPositionCertificates,
    getNonAddedPositionCertificates,
    savePositionCertificate,

    positionId,
    showCertificateForm,
    setShowCertificateForm,
  } = props;

  const [formData, setFormData] = useState({
    certificateId: null,
    positionId: positionId,
    added: true,
  });
  const [certificate, setCertificate] = useState(null);

  const [errors, setErrors] = useState({
    certificateHasError: false,
  });

  useEffect(() => {
    if (!nonAddedPositionCertificates.length)
      getNonAddedPositionCertificates(positionId);

    // Cleanup function to cancel the asynchronous task if the component is unmounted
    return () => {
      // Cancel or clean up any asynchronous tasks here
      // For example, if you're using Axios for API calls, you can cancel the request
      // axiosCancelToken.cancel();
    };
  }, []);

  const formValidator = () => {
    const error = {
      certificateHasError: false,
      hasError: false,
    };

    if (!certificate) {
      error.certificateHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      savePositionCertificate(formData, certificate, onClose);
    }
  };

  const onClose = () => {
    setFormData({
      certificateId: null,
      positionId: positionId,
    });
    setShowCertificateForm(false);
    setCertificate(null);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showCertificateForm}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Add New Position Certificate
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        {onWait && !loading && <LinearProgress />}

        <DialogContent dividers>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row">
              <div className="col-12">
                <Autocomplete
                  size="small"
                  className="mt-3"
                  id="certificate"
                  defaultValue={null}
                  value={certificate || null}
                  options={nonAddedPositionCertificates}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, c) => {
                    setCertificate(c);
                    const certificateId = c.id;
                    setFormData({ ...formData, certificateId });
                  }}
                  getOptionLabel={(option) => option.certificateName}
                  renderOption={(props, certificate) => (
                    <Box component="li" {...props}>
                      {certificate.certificateName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select Certificate"
                      {...params}
                      label="Select Certificate"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
                {errors.certificateHasError && (
                  <div className="text-danger mb-2">
                    certificate is required{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-3">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={onWait || loading}
          >
            {onWait ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, nonAddedPositionCertificates }) => {
  return {
    loading,
    onWait,
    nonAddedPositionCertificates,
  };
};
export default connect(mapStateToProps, {
  getNonAddedPositionCertificates,
  savePositionCertificate,
})(AddPositionCertificateDialog);
