import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  Toolbar,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  InputAdornment,
  Select,
  Badge,
  IconButton,
  Menu,
  LinearProgress,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Divider,
  CircularProgress,
} from "@mui/material";

import {
  getUserDetails,
  getSkillsDashboardSummary,
  getSkillsDegreeData,
  getSkillsSchoolData,
  getSkillsFieldData,
} from "../../../store/profile/actions";

import { getEntitySectors } from "../../../store/structure/actions";

import {
  getCountries,
  getDegrees,
  getEducationalInstitutions,
  getQualifications,
  getQualificationFields,
} from "../../../store/profile/actions";
import { Pie } from "react-chartjs-2";

import {
  BarChartJs,
  ReBarChart,
} from "../../common/components/DashboardCharts";
import { numberFormatter, totalSum } from "../../common/components/Utils";
import { isEmpty } from "lodash";
import TableSkeleton from "../../common/components/TableSkeleton";

const getSchoolYears = (currentDate = new Date()) => {
  const d = new Date(currentDate);
  const currentYear = d.getFullYear();
  let i = 0;
  let result = [];
  while (i <= 50) {
    result.push({ id: i + 1, name: (+currentYear - i).toString() });
    i++;
  }
  return result;
};

const genders = [
  { id: "M", name: "Male" },
  { id: "F", name: "Female" },
];
const defaultFilter = {
  minAge: null,
  maxAge: null,
  gender: null,
  minYearsExperience: null,
  maxYearsExperience: null,
  entitySectorId: null,

  positionId: null,
  levelId: null,
  degreeId: null,
  fieldOfStudyId: null,
  qualificationId: null,
  qualificationFieldId: null,
  nationalId: null,
  placeOfBirth: null,
  countryOfStudyId: "RW",
  schoolId: null,
  yearOfCompletion: null,
};
const Dashboard = (props) => {
  const {
    loading,
    getSkillsDashboardSummary,
    entitySectors,
    getEntitySectors,
    getSkillsDegreeData,
    getCountries,
    getDegrees,
    getEducationalInstitutions,
    getQualifications,
    getQualificationFields,
    lookup,
    totalSkillsSummary,
    getSkillsSchoolData,
    getSkillsFieldData,
  } = props;

  const [fields, setFields] = useState({ ...defaultFilter });

  const [totalSummaryLoader, setTotalSummaryLoader] = useState(false);

  const [degreesData, setDegreesData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [fieldData, setFieldData] = useState([]);

  const [degreesDataLoader, setDegreesDataLoader] = useState(false);

  const handleLoadSkillsDashboardNumbers = () => {
    return getSkillsDashboardSummary({ ...fields }, setTotalSummaryLoader);
  };

  const handleLoadSkillsDegreeData = () => {
    return getSkillsDegreeData({ ...fields }, (status, data) => {
      setDegreesDataLoader(status);
      setDegreesData(data);
    });
  };
  const handleLoadSkillsSchoolData = () => {
    return getSkillsSchoolData({ ...fields }, (status, data) => {
      setSchoolData(data);
    });
  };

  const handleLoadSkillsFieldData = () => {
    return getSkillsFieldData({ ...fields }, (status, data) => {
      setFieldData(data);
    });
  };
  useEffect(() => {
    handleLoadSkillsDashboardNumbers();
    handleLoadSkillsDegreeData();
    handleLoadSkillsSchoolData();
    handleLoadSkillsFieldData();
  }, []);
  const onFind = async (fields) => {
    setFields(fields);
    handleLoadSkillsDashboardNumbers();
    handleLoadSkillsDegreeData();
    handleLoadSkillsSchoolData();
    handleLoadSkillsFieldData();
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="d-flex align-items-center justify-content-between">
              <Typography variant="h6" noWrap component="div">
                <span>SKILLS</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>Dashboard</span>
                </span>
              </Typography>
            </Toolbar>
            <div className="mx-1">
              <div className="row">
                <div className="col-3">
                  <div
                    className="elevated p-3"
                    style={{
                      backgroundColor: "#fafafa",
                      border: "1px solid #17a2b8",
                      minHeight: "auto",
                    }}
                  >
                    <Filters
                      loading={loading}
                      entitySectors={entitySectors}
                      getEntitySectors={getEntitySectors}
                      getCountries={getCountries}
                      getDegrees={getDegrees}
                      getEducationalInstitutions={getEducationalInstitutions}
                      getQualifications={getQualifications}
                      getQualificationFields={getQualificationFields}
                      lookup={lookup}
                      onSearch={onFind}
                    />
                  </div>
                </div>
                <div className="col-9">
                  <div
                    style={{
                      marginLeft: "0em",
                      paddingRight: "1em",
                      background: "rgb(227, 232, 244)!important",
                      boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                      MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                      height: "80vh",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                  >
                    <div className="row">
                      <div className="col-12 col-sm-12 col-lg-12">
                        <div className="d-flex flex-column mb-3 mt-3 w-100">
                          <div className="d-flex  flex-wrap justify-content-center w-100">
                            <div
                              style={{ color: "#078ece", width: "165px" }}
                              className="bg-white border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                            >
                              <div className="fw-semibold font-weight-bold  text-center">
                                Population
                              </div>
                              <div
                                style={{ color: "rgb(130, 202, 157)" }}
                                className="fs-6 font-weight-bolder  text-center"
                              >
                                {!!totalSummaryLoader ? (
                                  <>
                                    {" "}
                                    <CircularProgress size={20} thickness={4} />
                                  </>
                                ) : (
                                  <>
                                    {numberFormatter(
                                      totalSkillsSummary?.population || 0
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              style={{ color: "#078ece", width: "165px" }}
                              className="bg-white border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                            >
                              <div className="fw-semibold font-weight-bold  text-center">
                                Hired
                              </div>
                              <div
                                style={{ color: "rgb(130, 202, 157)" }}
                                className="fs-6 font-weight-bolder  text-center"
                              >
                                {!!totalSummaryLoader ? (
                                  <>
                                    {" "}
                                    <CircularProgress size={20} thickness={4} />
                                  </>
                                ) : (
                                  <>
                                    {numberFormatter(
                                      totalSkillsSummary?.hired || 0
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              style={{ color: "#078ece", width: "165px" }}
                              className="bg-white border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                            >
                              <div className="fw-semibold font-weight-bold  text-center">
                                Qualifications
                              </div>
                              <div
                                style={{ color: "rgb(130, 202, 157)" }}
                                className="fs-6 font-weight-bolder  text-center"
                              >
                                {!!totalSummaryLoader ? (
                                  <>
                                    {" "}
                                    <CircularProgress size={20} thickness={4} />
                                  </>
                                ) : (
                                  <>
                                    {numberFormatter(
                                      totalSkillsSummary?.qualifications || 0
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              style={{ color: "#078ece", width: "165px" }}
                              className="bg-white border-dashed border-gray-300 border-dashed rounded min-w-80px py-2 px-2 mx-2 mb-1 mt-1"
                            >
                              <div className="fw-semibold font-weight-bold  text-center">
                                Fields
                              </div>
                              <div
                                style={{ color: "rgb(130, 202, 157)" }}
                                className="fs-6 font-weight-bolder  text-center"
                              >
                                {!!totalSummaryLoader ? (
                                  <>
                                    {" "}
                                    <CircularProgress size={20} thickness={4} />
                                  </>
                                ) : (
                                  <>
                                    {numberFormatter(
                                      totalSkillsSummary?.fields || 0
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-6 col-sm-6 col-lg-6 mt-5">
                        <div
                          className="rounded mt-2 py-3"
                          style={{
                            textAlign: "center",
                            border: "solid #e4e6ef",
                            borderRadius: "10px !important",
                          }}
                        >
                          <h6 style={{ color: "rgba(255, 206, 86, 1)" }}>
                            {!!fields?.qualificationFieldId
                              ? "Employment by qualification"
                              : "Employment by field of study"}
                          </h6>

                          <div style={{ padding: "10px" }}>
                            {!!fields?.qualificationFieldId ? (
                              <>
                                <ReBarChart
                                  labels={{
                                    xAxisKey: "qualificationName",
                                    barKey1: "hiredUsers",
                                    barKey2: "totalUsers",
                                  }}
                                  data={fieldData}
                                />
                              </>
                            ) : (
                              <>
                                <BarChartJs
                                  data={{
                                    labelKey: "fieldName",
                                    datasetLabelKeys: [
                                      { name: "hiredUsers", color: "#4caf50" },
                                      { name: "totalUsers", color: "#2196f3" },
                                    ],
                                    datasets: fieldData,
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 mt-5">
                        <div
                          className="rounded"
                          style={{
                            // width: "200px",
                            border: "solid #e4e6ef",
                            borderRadius: "10px !important",
                          }}
                        >
                          {!isEmpty(totalSkillsSummary) &&
                            !!totalSkillsSummary?.genderCharts && (
                              <>
                                <Pie
                                  redraw={true}
                                  options={{
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        position: "bottom",
                                      },
                                      title: {
                                        display: true,
                                        text: "Gender",
                                      },
                                    },
                                  }}
                                  data={totalSkillsSummary.genderCharts}
                                />
                              </>
                            )}
                        </div>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 mt-5">
                        <div
                          className="rounded"
                          style={{
                            // width: "200px",
                            border: "solid #e4e6ef",
                            borderRadius: "10px !important",
                          }}
                        >
                          {!isEmpty(totalSkillsSummary) &&
                            !!totalSkillsSummary.educationOriginCharts && (
                              <>
                                <Pie
                                  className="mt-3"
                                  redraw={true}
                                  options={{
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        position: "bottom",
                                      },
                                      title: {
                                        display: true,
                                        text: "Education Origin",
                                      },
                                    },
                                  }}
                                  data={
                                    totalSkillsSummary.educationOriginCharts
                                  }
                                />
                              </>
                            )}
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6 mt-5">
                        <div
                          className="rounded mt-2 py-3"
                          style={{
                            textAlign: "center",
                            border: "solid #e4e6ef",
                            borderRadius: "10px !important",
                          }}
                        >
                          <h6 style={{ color: "rgba(255, 206, 86, 1)" }}>
                            Education Level
                          </h6>

                          <ReBarChart
                            labels={{
                              xAxisKey: "degreeName",
                              barKey1: "hiredUsers",
                              barKey2: "totalUsers",
                            }}
                            data={
                              degreesData?.filter(
                                (item) => item.degreeName !== "Total"
                              ) || []
                            }
                          />
                        </div>
                      </div>

                      <div className="col-6 col-sm-6 col-lg-6 mt-5">
                        <div
                          className="rounded mt-2 py-3"
                          style={{
                            textAlign: "center",
                            border: "solid #e4e6ef",
                            borderRadius: "10px !important",
                          }}
                        >
                          <h6 style={{ color: "rgba(255, 206, 86, 1)" }}>
                            Employment by Schools
                          </h6>

                          <ReBarChart
                            labels={{
                              xAxisKey: "schoolName",
                              barKey1: "hiredUsers",
                              barKey2: "totalUsers",
                            }}
                            data={
                              schoolData?.filter(
                                (item) => item.schoolName !== null
                              ) || []
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-12 col-sm-12 col-lg-12">
                        <table className="table table-striped table-hover table-sm  fixTableHead">
                          <caption
                            style={{ backgroundColor: "#e9ecef" }}
                            className="mb-2"
                          >
                            View Education Level Summary
                          </caption>

                          <thead>
                            <tr style={{ backgroundColor: "#f0f2f5" }}>
                              <th scope="col">Level</th>
                              <th scope="col">GraduatedLocally</th>
                              <th scope="col">GraduatedAbroad</th>
                              <th scope="col">TotalMale</th>
                              <th scope="col">TotalFemale</th>
                              <th scope="col">TotalHired</th>
                              <th scope="col">MaleHired</th>
                              <th scope="col">FemaleHired</th>
                              <th scope="col">NotHired</th>
                              <th scope="col">Qualifications</th>
                              <th scope="col">TotaPopulation</th>
                            </tr>
                          </thead>

                          {!!degreesDataLoader ? (
                            <TableSkeleton cols={11} rows={15} />
                          ) : (
                            <>
                              <tbody>
                                {degreesData.map((degree, index) => (
                                  <tr key={index}>
                                    <th
                                      scope="row"
                                      style={{ backgroundColor: "#f0f2f5" }}
                                    >
                                      {degree.degreeName}
                                    </th>
                                    <td>{degree.graduatedLocally}</td>
                                    <td>{degree.graduatedAbroad}</td>
                                    <td>{degree.maleUsers}</td>
                                    <td>{degree.femaleUsers}</td>
                                    <td>{degree.hiredUsers}</td>

                                    <td>{degree.hiredMaleUsers}</td>
                                    <td>{degree.hiredFemaleUsers}</td>

                                    <td>{degree.notHiredUsers}</td>
                                    <td>{degree.numberOfQualifications}</td>
                                    <td>{degree.totalUsers}</td>
                                  </tr>
                                ))}
                              </tbody>
                              {!!degreesData.length && (
                                <tfoot>
                                  <tr>
                                    <th
                                      scope="row"
                                      style={{ backgroundColor: "#f0f2f5" }}
                                    >
                                      Total
                                    </th>
                                    <th>
                                      {totalSum(
                                        degreesData,
                                        "graduatedLocally"
                                      )}
                                    </th>
                                    <th>
                                      {totalSum(degreesData, "graduatedAbroad")}{" "}
                                    </th>
                                    <th>
                                      {totalSum(degreesData, "maleUsers")}{" "}
                                    </th>
                                    <th>
                                      {totalSum(degreesData, "femaleUsers")}{" "}
                                    </th>
                                    <th>
                                      {totalSum(degreesData, "hiredUsers")}
                                    </th>

                                    <th>
                                      {totalSum(degreesData, "hiredMaleUsers")}
                                    </th>
                                    <th>
                                      {totalSum(
                                        degreesData,
                                        "hiredFemaleUsers"
                                      )}
                                    </th>

                                    <th>
                                      {totalSum(degreesData, "notHiredUsers")}
                                    </th>
                                    <th>
                                      {totalSum(
                                        degreesData,
                                        "numberOfQualifications"
                                      )}
                                    </th>
                                    <th>
                                      {totalSum(degreesData, "totalUsers")}
                                    </th>
                                  </tr>
                                </tfoot>
                              )}
                            </>
                          )}
                        </table>
                        {!degreesDataLoader && !degreesData.length && (
                          <div className="jumbotron jumbotron-fluid text-center  mt-0">
                            <div className="container py-5">
                              <p className="lead">No Data</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AppBar>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  entitySectors,
  lookup,
  totalSkillsSummary,
}) => {
  return { user, loading, entitySectors, lookup, totalSkillsSummary };
};
export default connect(mapStateToProps, {
  getUserDetails,
  getSkillsDashboardSummary,
  getEntitySectors,
  getCountries,
  getDegrees,
  getEducationalInstitutions,
  getQualifications,
  getQualificationFields,
  getSkillsDegreeData,
  getSkillsSchoolData,
  getSkillsFieldData,
})(Dashboard);

const Filters = (props) => {
  const {
    loading,
    entitySectors,
    getEntitySectors,
    getCountries,
    getDegrees,
    getEducationalInstitutions,
    getQualifications,
    getQualificationFields,
    lookup,
    onSearch,
  } = props;

  const [entitySector, setEntitySector] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(entitySectors);
  const [gender, setGender] = useState(null);
  const [qualificationField, setQualificationField] = useState(null);

  const [minAge, setMinAge] = useState(null);
  const [maxAge, setMaxAge] = useState(null);

  const [minYearsExperience, setMinYearsExperience] = useState(null);
  const [maxYearsExperience, setMaxYearsExperience] = useState(null);

  const [skillProfileLoader, setSkillProfileLoader] = useState(false);

  const [country, setCountry] = useState(null);
  const [institution, setInstitution] = useState(null);
  const [degree, setDegree] = useState(null);
  const [qualification, setQualification] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (!lookup.countries) getCountries();
    if (!lookup.degrees) getDegrees();
    if (!lookup.qualificationFields) getQualificationFields();
  }, []);

  useEffect(() => {
    if (!!country) getEducationalInstitutions(country.id);
  }, [country]);

  useEffect(() => {
    if (!!degree) getQualifications(degree.id);
  }, [degree]);

  useEffect(() => {
    if (!entitySectors.length) getEntitySectors();
  }, []);

  useEffect(() => {
    // Filter options based on the keyword
    const filtered = [...entitySectors].filter(
      (option) =>
        option?.entity?.name.toLowerCase().includes(keyword.toLowerCase()) ||
        (option?.entity?.acronym &&
          option?.entity?.acronym.toLowerCase().includes(keyword.toLowerCase()))
    );
    if (filtered.length === 1) {
      setEntitySector(filtered[0]);
    }
    setFilteredOptions(filtered);
  }, [keyword, entitySectors]);

  const fields = () => {
    return {
      minAge: minAge || null,
      maxAge: maxAge || null,
      gender: gender?.id || null,
      minYearsExperience: minYearsExperience || null,
      maxYearsExperience: maxYearsExperience || null,
      entitySectorId: entitySector?.id || null,

      positionId: null,
      levelId: null,
      degreeId: degree?.id || null,
      fieldOfStudyId: qualificationField?.id || null,
      qualificationId: qualification?.id || null,
      qualificationFieldId: qualificationField?.id || null,
      nationalId: null,
      placeOfBirth: null,
      countryOfStudyId: country?.id || "RW",
      schoolId: institution?.id || null,
      yearOfCompletion: year?.name || null,
    };
  };

  const onFind = async (e) => {
    e.preventDefault();
    onSearch({ ...fields() });
  };
  const filterDegrees = () =>
    lookup.degrees?.filter(({ id }) => id !== 9 && id !== 10) || [];

  return (
    <>
      <form onSubmit={onFind}>
        <div className="row">
          <div className="col-12 mb-1">
            {skillProfileLoader && <LinearProgress />}
          </div>
          <div
            className="col-12"
            style={{
              height: "55vh",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <div className="row">
              <div className="col-12 mb-2 mt-2">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={qualificationField}
                  options={lookup.qualificationFields || []}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, qualificationField) => {
                    setQualificationField(qualificationField || null);
                    setDegree(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, qualificationField) => (
                    <Box component="li" {...props} key={qualificationField.id}>
                      {qualificationField.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Search by field of study"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "country" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className="col-12 mb-2">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={country}
                  options={lookup.countries || []}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, country) => {
                    setCountry(country || null);
                    setInstitution(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, country) => (
                    <Box component="li" {...props} key={country.id}>
                      {country.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Search by country of study"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "country" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className="col-12 mb-2">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={institution}
                  options={lookup.educationalInstitutions || []}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, institute) => {
                    setInstitution(institute || null);
                    // setFormError(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, institute) => (
                    <Box component="li" {...props} key={institute.id}>
                      {institute.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Search by university"
                      placeholder="Search by university"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "institution" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className="col-12 mb-2">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={degree}
                  options={filterDegrees()}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, degree) => {
                    setDegree(degree || null);
                    setQualification(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, degree) => (
                    <Box component="li" {...props} key={degree.id}>
                      {degree.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Search by degree"
                      placeholder="Search by degree"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "degree" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>
              <div className="col-12 mb-2">
                <Autocomplete
                  size="small"
                  defaultValue={null}
                  value={year}
                  options={getSchoolYears()}
                  disabled={loading}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, year) => {
                    setYear(year || null);
                    // setFormError(null);
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, year) => (
                    <Box component="li" {...props} key={year.id}>
                      {year.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      style={{
                        height: "25px !important",
                        position: "relative",
                        borderRadius: "8px",
                        padding: "1px !important",
                      }}
                      {...params}
                      label="Year of completion"
                      placeholder="Year of completion"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "name", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                {/* {formError && formError.type === "year" && (
                  <small className="text-danger">{formError.msg}</small>
                )} */}
              </div>

              <div className="col-12 mb-2">
                <div className="row">
                  <div className="col-12">
                    <fieldset className="form-group border p-2 mt-2">
                      <legend
                        className="w-100"
                        style={{
                          color: "rgba(0, 0, 0, 0.6)",
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                          fontWeight: 400,
                          fontSize: "0.82rem",
                          lineHeight: "1em",
                          letterSpacing: "0.00938em",
                        }}
                      >
                        Filter by gender
                      </legend>
                      <div className="form-group1 row">
                        {genders.map((row, index) => (
                          <FormControlLabel
                            className="ml-4"
                            key={index + row.id}
                            value={row.name}
                            control={
                              <Radio
                                className="col-6"
                                onClick={() => {
                                  if (row === gender) setGender(null);
                                }}
                              />
                            }
                            label={row.name}
                            onChange={() => setGender(row)}
                            checked={row === gender}
                          />
                        ))}
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-1">
                <fieldset className="form-group border p-2 mt-2">
                  <legend
                    className="w-auto px-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      fontWeight: 400,
                      fontSize: "0.82rem",
                      lineHeight: "1em",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Age range
                  </legend>
                  <div className="form-group1">
                    <div className="row">
                      <div className="col-5">
                        <TextField
                          fullWidth
                          size="small"
                          rows={2}
                          name="minAge"
                          label={`Min age`}
                          placeholder={`Min age`}
                          variant="outlined"
                          className="font-weight-bold"
                          value={minAge || ""}
                          onChange={(e) => {
                            setMinAge(e.target.value || "");
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <Divider className="mt-3" />
                      </div>
                      <div className="col-5">
                        <TextField
                          fullWidth
                          size="small"
                          rows={2}
                          name="maxAge"
                          label={`Max age`}
                          placeholder={`Max age`}
                          variant="outlined"
                          className="font-weight-bold"
                          value={maxAge || ""}
                          onChange={(e) => {
                            setMaxAge(e.target.value || "");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className="col-12 mb-2">
                <fieldset className="form-group border p-2 mt-2">
                  <legend
                    className="w-auto px-2"
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                      fontWeight: 400,
                      fontSize: "0.82rem",
                      lineHeight: "1em",
                      letterSpacing: "0.00938em",
                    }}
                  >
                    Work experience range
                  </legend>
                  <div className="form-group1 mb-1">
                    <div className="row">
                      <div className="col-5">
                        <TextField
                          fullWidth
                          size="small"
                          rows={2}
                          name="minYearsExperience"
                          label={`Min year exp.`}
                          placeholder={`Min year exp.`}
                          variant="outlined"
                          className="font-weight-bold"
                          value={minYearsExperience || ""}
                          onChange={(e) => {
                            setMinYearsExperience(e.target.value || "");
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <Divider className="mt-3" />
                      </div>
                      <div className="col-5">
                        <TextField
                          fullWidth
                          size="small"
                          rows={2}
                          name="maxYearsExperience"
                          label={`Max year exp.`}
                          placeholder={`Max year exp.`}
                          variant="outlined"
                          className="font-weight-bold"
                          value={maxYearsExperience || ""}
                          onChange={(e) => {
                            setMaxYearsExperience(e.target.value || "");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="col-12 py-2">
            <Divider />
          </div>
          <div className="col-12">
            <button
              disabled={skillProfileLoader}
              type="submit"
              className="btn btn-primary text-uppercase ml-1 d-flex align-items-center float-right"
            >
              <span className="material-icons">search</span>
              Search
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
