import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { doPreviewEmployeePaySlip } from "../../../store/structure/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const PreviewEmployeePaySlipDialog = (props) => {
  const {
    loading,
    showEmployeePaySlip,
    setShowEmployeePaySlip,
    selectedEmployee,
    setSelectedEmployee,
    previewEmployeePaySlip,
    doPreviewEmployeePaySlip,
  } = props;

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const theme = useTheme();

  const [filterByYear, setFilterByYear] = useState(null);
  const [filterByMonth, setFilterByMonth] = useState(null);

  // console.log(previewEmployeePaySlip);
  useEffect(() => {
    const date = new Date();
    const currentYear = date.getFullYear();
    let currentMonth = date.getMonth();

    currentMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth;
    const period = currentYear + "-" + currentMonth;

    setFilterByYear(currentYear.toString());
    setFilterByMonth(currentMonth.toString());

    doPreviewEmployeePaySlip(selectedEmployee.id, period);
  }, [selectedEmployee]);

  const getYears = () => {
    let years = [];
    for (let year = 2000; year < 2022; year++) {
      years.push(year.toString());
    }
    return years.reverse();
  };
  const getMonths = () => {
    let months = [];
    for (let i = 1; i < 12; i++) {
      const m = i < 10 ? "0" + i : i;
      months.push(m.toString());
    }
    return months;
  };

  const onClose = () => {
    setShowEmployeePaySlip(false);
    setSelectedEmployee(null);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeePaySlip}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle className="text-primary pb-0 mb-0">
          PaySlip
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent
          style={{ height: previewEmployeePaySlip ? "85vh" : "auto" }}
        >
          <div className="row mt-4">
            <div className="col-lg-3 mt-3">
              <Autocomplete
                size="small"
                id="year"
                defaultValue={null}
                value={filterByYear || null}
                options={getYears()}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, year) => {
                  setFilterByYear(year || null);
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, year) => (
                  <Box component="li" {...props} key={year}>
                    {year}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "35px!important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px!important",
                    }}
                    {...params}
                    label="Filter By Year"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "year", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </div>

            <div className="col-lg-3 mt-3">
              <Autocomplete
                size="small"
                id="month"
                defaultValue={null}
                value={filterByMonth || null}
                options={getMonths()}
                isOptionEqualToValue={(option, value) => option === value}
                onChange={(event, month) => {
                  setFilterByMonth(month || null);
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, month) => (
                  <Box component="li" {...props} key={month}>
                    {month}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    style={{
                      height: "35px!important",
                      position: "relative",
                      borderRadius: "8px",
                      padding: "1px!important",
                    }}
                    {...params}
                    label="Filter By Month"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "month", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </div>
            <div className="col-lg-3 mt-3">
              <Button
                onClick={() => {
                  const period = filterByYear + "-" + filterByMonth;
                  doPreviewEmployeePaySlip(selectedEmployee.id, period);
                }}
                style={{
                  ...ActiveButton,
                  padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                  float: "left",
                }}
                size="small"
              >
                <span className="material-icons">search</span> Search
              </Button>
            </div>
          </div>

          <div className="mt-4" style={{ height: "100%" }}>
            <NoRecordMessage
              isEmpty={!previewEmployeePaySlip && !loading}
              title="No payslip found."
            />

            <PreLoader isLoading={loading && !previewEmployeePaySlip} />

            {!loading && previewEmployeePaySlip && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={`${previewEmployeePaySlip}`}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, previewEmployeePaySlip }) => {
  return {
    loading,
    previewEmployeePaySlip,
  };
};
export default connect(mapStateToProps, {
  doPreviewEmployeePaySlip,
})(PreviewEmployeePaySlipDialog);
