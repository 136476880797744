import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  Typography,
  Skeleton,
  Divider,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addEmployeePBFSettings,
  getActiveCostCenters,
  getEmployeePBFSettings,
  getPBFIndexGroup,
  getSourceOfFunds,
  removeEmployeePBFSettings,
  updateEmployeePBFSettings,
} from "../../../store/payroll/actions";
import { useTheme } from "@mui/material/styles";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const EmployeePBFSettingsDialog = ({
  loading,
  employee,
  periods,
  costCenters,
  sourceOfFunds,
  pbfIndexGroup,
  entitySectorId,
  employeePbfSettings,
  getActiveCostCenters,
  getSourceOfFunds,
  getPBFIndexGroup,
  addEmployeePBFSettings,
  getEmployeePBFSettings,
  updateEmployeePBFSettings,
  removeEmployeePBFSettings,
  setShowPBFSettingsDialog,
}) => {
  const [period, setPeriod] = useState(null);
  const [selectedCostCenter, setSelectedCostCenter] = useState(null);
  const [selectedSourceOfFund, setSelectedSourceOfFund] = useState(null);
  const [selectedPBFIndexGroup, setSelectedPBFIndexGroup] = useState(null);
  const theme = useTheme();
  const [formData, setFormData] = useState({
    periodId: "",
    oldPeriodId: "",
    costCenterId: "",
    sourceOfFundId: "",
    pbfIndexGroupId: "",
    noOfDays: 0,
    pbfGrossAmount: 0,
    mark: 0,
    isArrear: false,
    isPayingTPR: true,
    isPayingCBHI: false,
    levelId: employee.levelId,
    employeeId: employee.id,
    employeePositionId: employee.employeePositionId,
    entitySectorId,
    employeeGroupId: employee.employeeGroupId,
    positionId: employee.positionId,
  });
  const [employeePBFSettings, setEmployeePBFSettings] = useState([]);
  const [confirmRemoveEmployeePBF, setConfirmRemoveEmployeePBF] =
    useState(false);
  const [selectedEmployeePBF, setSelectedEmployeePBF] = useState(null);
  const [saving, setSaving] = useState(false);
  const [add, setAdd] = useState(false);
  const [error, setError] = useState({ field: "", message: "" });

  useEffect(() => {
    if (costCenters.length === 0) {
      getActiveCostCenters();
    }

    if (sourceOfFunds.length === 0) {
      getSourceOfFunds();
    }
    if (pbfIndexGroup.length === 0) {
      getPBFIndexGroup();
    }
  }, []);

  useEffect(() => {
    if (
      employee &&
      !employeePbfSettings.find(
        (x) => x.employeePositionId === employee.employeePositionId
      )
    ) {
      getEmployeePBFSettings(employee.employeePositionId);
    }
  }, [employee]);

  useEffect(() => {
    if (
      employeePbfSettings.find(
        (x) => x.employeePositionId === employee.employeePositionId
      )
    ) {
      setEmployeePBFSettings(
        employeePbfSettings.filter(
          (x) => x.employeePositionId === employee.employeePositionId
        )
      );
    }
  }, [employeePbfSettings]);

  const handleSave = () => {
    if (!formData.costCenterId) {
      setError({ field: "coste-center", message: "Cost center is required" });
      return;
    }
    if (!formData.sourceOfFundId) {
      setError({
        field: "source-of-fund",
        message: "Source of fund is required",
      });
      return;
    }
    if (!formData.pbfIndexGroupId) {
      setError({
        field: "pbf-index-group",
        message: "PBF Index Group is required",
      });
      return;
    }
    if (!formData.periodId) {
      setError({ field: "period", message: "Period is required" });
      return;
    }
    if (!formData.noOfDays) {
      setError({ field: "days", message: "Days are required" });
      return;
    }
    if (+formData.noOfDays < 1) {
      setError({ field: "days", message: "Days can't be less than 1" });
      return;
    }
    if (!formData.mark) {
      setError({ field: "marks", message: "Marks are required" });
      return;
    }
    if (+formData.mark < 50) {
      setError({ field: "marks", message: "Marks can't be less than 50" });
      return;
    }

    if (formData.oldPeriodId) {
      updateEmployeePBFSettings(formData, (status, res) => {
        setSaving(status);
        if (!status && res) handleCloseForm();
      });
      return;
    } else {
      addEmployeePBFSettings(formData, (status, res) => {
        setSaving(status);
        if (!status && res) handleCloseForm();
      });
      return;
    }
  };

  const handleSetFormForUpdate = (employeePBFItem) => {
    setFormData({
      costCenterId: employeePBFItem.costCenterId,
      sourceOfFundId: employeePBFItem.sourceOfFundId,
      pbfIndexGroupId: employeePBFItem.pbfIndexGroupId,
      noOfDays: employeePBFItem.nOfDays,
      mark: employeePBFItem.mark,
      isArrear: employeePBFItem.isArrear,
      isPayingTPR: employeePBFItem.isPayingTPR,
      oldPeriodId: employeePBFItem.periodId,
      periodId: employeePBFItem.periodId,
      employeeId: employeePBFItem.employeeId,
      positionId: employee.positionId,
      levelId: employeePBFItem.levelId,
      employeePositionId: employeePBFItem.employeePositionId,
      entitySectorId: employeePBFItem.entitySectorId,
      employeeGroupId: employeePBFItem.employeeGroupId,
      isPayingCBHI: employeePBFItem.isPayingCBHI,
    });
    setAdd(true);

    setSelectedCostCenter(employeePBFItem.costCenter);
    setSelectedSourceOfFund(employeePBFItem.sourceOfFund);
    const _pbfIndexGroup = employeePBFItem.pbfIndexGroup
      ? employeePBFItem.pbfIndexGroup
      : pbfIndexGroup.find((x) => x.id === employeePBFItem.pbfIndexGroupId);

    setSelectedPBFIndexGroup(_pbfIndexGroup);
    setPeriod(employeePBFItem.period);
  };

  const handleCloseForm = () => {
    setAdd(false);
    setFormData({
      periodId: "",
      oldPeriodId: "",
      costCenterId: "",
      sourceOfFundId: "",
      pbfIndexGroupId: "",
      noOfDays: 0,
      mark: 0,
      isArrear: false,
      isPayingTPR: true,
      isPayingCBHI: false,
      levelId: employee.levelId,
      employeeId: employee.id,
      employeePositionId: employee.employeePositionId,
      entitySectorId,
      employeeGroupId: employee.employeeGroupId,
      positionId: employee.positionId,
    });
    setError({
      periodIdHasError: false,
      costCenterHasError: false,
      sourceOfFundHasError: false,
      pbfIndexGroupIdHasError: false,
    });
    setSelectedCostCenter(null);
    setSelectedSourceOfFund(null);
    setSelectedPBFIndexGroup(null);
    setPeriod(null);
  };

  const handleDeletePBF = () => {
    if (!selectedEmployeePBF) {
      showError("Please select the PBF Setting you want to delete!");
      return;
    }

    removeEmployeePBFSettings(
      selectedEmployeePBF.periodId,
      selectedEmployeePBF.employeeId,
      (status, res) => {
        setSaving(status);
        if (!status && res) {
          setSelectedEmployeePBF(null);
          setConfirmRemoveEmployeePBF(false);
        }
      }
    );
  };

  const isHealthSector =
    entitySectorId.split("-")[2] === "06" ||
    ["7000000000-080000000000000000-04"].includes(entitySectorId);

  return (
    <>
      <Dialog
        onClose={() => setShowPBFSettingsDialog(null)}
        open={true}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              {employee && (
                <div className="header_cont">
                  <span className="ml-0 text-dark">
                    Employee PBF settings |{" "}
                  </span>{" "}
                  <span className="ml-0">
                    {employee.lastName} {employee.firstName}
                  </span>{" "}
                </div>
              )}
            </div>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setShowPBFSettingsDialog(null)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent className="py-0">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="elevated rounded p-3"
                style={{
                  backgroundColor: "#fafafa",
                  border: "1px solid #17a2b8",
                  minHeight: "62vh",
                }}
              >
                {loading && !saving && (
                  <div className="container mt-5" style={{ width: "100%" }}>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                  </div>
                )}

                {(!loading || saving) && (
                  <div>
                    {!add && employeePBFSettings.length !== 0 && (
                      <Button
                        className="mb-2"
                        onClick={() => {
                          setAdd(true);
                        }}
                        style={{
                          ...ActiveButton,
                          padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                          float: "right",
                        }}
                        size="sm"
                      >
                        <span className="material-icons">add</span>
                      </Button>
                    )}
                    {(add || employeePBFSettings.length === 0) && (
                      <div
                        className="elevated rounded p-0 mt-3 p-3"
                        style={{
                          backgroundColor: "#fafafa",
                          border: "1px solid #17a2b8",
                        }}
                      >
                        <div className="row mt-2">
                          <div className="col-6">
                            <Autocomplete
                              size="small"
                              id="costCenters"
                              defaultValue={null}
                              value={selectedCostCenter}
                              options={costCenters}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, costCenter) => {
                                setSelectedCostCenter(costCenter || null);
                                setFormData({
                                  ...formData,
                                  costCenterId: costCenter?.id || "",
                                });
                                setError({ field: "", message: "" });
                              }}
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, costCenter) => (
                                <Box component="li" {...props}>
                                  {costCenter.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Cost center"
                                  placeholder="Cost center"
                                  inputProps={{
                                    ...params.inputProps,
                                    // autoComplete: "costCenter", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                              disabled={loading || saving}
                            />
                            {error.field === "coste-center" && (
                              <small className="text-danger mb-1">
                                {error.message}
                              </small>
                            )}
                          </div>
                          <div className="col-6">
                            <Autocomplete
                              size="small"
                              id="sourceOfFunds"
                              defaultValue={null}
                              value={selectedSourceOfFund}
                              options={sourceOfFunds}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, sourceOfFund) => {
                                setSelectedSourceOfFund(sourceOfFund || null);
                                setFormData({
                                  ...formData,
                                  sourceOfFundId: sourceOfFund?.id || "",
                                });
                                setError({ field: "", message: "" });
                              }}
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, costCenter) => (
                                <Box component="li" {...props}>
                                  {costCenter.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Source of fund"
                                  placeholder="Source of fund"
                                  inputProps={{
                                    ...params.inputProps,
                                    // autoComplete: "sourceOfFund", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                              disabled={loading || saving}
                            />
                            {error.field === "source-of-fund" && (
                              <small className="text-danger mb-1 ">
                                {error.message}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="mb-3 col-6">
                            <Autocomplete
                              size="small"
                              id="pbfIndexGroupId"
                              name="pbfIndexGroupId"
                              defaultValue={null}
                              value={selectedPBFIndexGroup}
                              options={pbfIndexGroup}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, pbfIndexGroup) => {
                                setSelectedPBFIndexGroup(pbfIndexGroup || null);
                                setFormData({
                                  ...formData,
                                  pbfIndexGroupId: pbfIndexGroup?.id,
                                });
                                setError({ field: "", message: "" });
                              }}
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, period) => (
                                <Box component="li" {...props}>
                                  {period.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="PBF Index Group"
                                  placeholder="PBF Index Group"
                                  inputProps={{
                                    ...params.inputProps,
                                    // autoComplete: "pbfIndexGroup",
                                  }}
                                />
                              )}
                              disabled={loading || saving}
                            />

                            {error.field === "pbf-index-group" && (
                              <small className="text-danger mb-3">
                                {error.message}
                              </small>
                            )}
                          </div>
                          <div className="mb-3 col-6">
                            <Autocomplete
                              size="small"
                              id="periodId"
                              name="periodId"
                              defaultValue={null}
                              value={period}
                              options={periods}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, period) => {
                                setPeriod(period || null);
                                setFormData({
                                  ...formData,
                                  periodId: period?.id,
                                });
                                setError({ field: "", message: "" });
                              }}
                              getOptionLabel={(option) => option.name}
                              renderOption={(props, period) => (
                                <Box component="li" {...props}>
                                  {period.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Period"
                                  placeholder="Period"
                                  inputProps={{
                                    ...params.inputProps,
                                    // autoComplete: "period", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                              disabled={loading || saving}
                            />

                            {error.field === "period" && (
                              <small className="text-danger mb-3">
                                {error.message}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className={isHealthSector ? "col-3" : "col-6"}>
                            <TextField
                              type="number"
                              fullWidth
                              size="small"
                              label="Number of Days"
                              placeholder="Number of Days"
                              name="noOfDays"
                              variant="outlined"
                              value={formData.noOfDays}
                              onKeyDown={() => {
                                if (+formData.noOfDays === 0)
                                  setFormData({
                                    ...formData,
                                    noOfDays: "",
                                  });
                              }}
                              onBlur={() => {
                                if (formData.noOfDays === "")
                                  setFormData({
                                    ...formData,
                                    noOfDays: 0,
                                  });
                              }}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  noOfDays: e.target.value,
                                });
                                setError({ field: "", message: "" });
                              }}
                              disabled={loading || saving}
                            />
                            {error.field === "days" && (
                              <small className="text-danger mb-3">
                                {error.message}
                              </small>
                            )}
                          </div>
                          {isHealthSector && (
                            <div className="col-3">
                              <TextField
                                type="number"
                                fullWidth
                                size="small"
                                label="PBF Gross Amount"
                                placeholder="PBF Gross Amount"
                                name="pbfGrossAmount"
                                variant="outlined"
                                value={formData.pbfGrossAmount}
                                onKeyDown={() => {
                                  if (+formData.pbfGrossAmount === 0)
                                    setFormData({
                                      ...formData,
                                      pbfGrossAmount: "",
                                    });
                                }}
                                onBlur={() => {
                                  if (formData.pbfGrossAmount === "")
                                    setFormData({
                                      ...formData,
                                      pbfGrossAmount: 0,
                                    });
                                }}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    pbfGrossAmount: e.target.value,
                                  });
                                  setError({ field: "", message: "" });
                                }}
                                disabled={loading || saving}
                              />
                              {error.field === "pbfGrossAmount" && (
                                <small className="text-danger mb-3">
                                  {error.message}
                                </small>
                              )}
                            </div>
                          )}
                          <div className="col-2">
                            <TextField
                              type="number"
                              fullWidth
                              size="small"
                              label="Marks"
                              placeholder="Marks"
                              name="marks"
                              variant="outlined"
                              value={formData.mark}
                              onKeyDown={() => {
                                if (+formData.marks === 0)
                                  setFormData({
                                    ...formData,
                                    mark: "",
                                  });
                              }}
                              onBlur={() => {
                                if (formData.marks === "")
                                  setFormData({
                                    ...formData,
                                    mark: 0,
                                  });
                              }}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  mark: e.target.value,
                                });
                                setError({ field: "", message: "" });
                              }}
                              disabled={loading || saving}
                            />
                            {error.field === "marks" && (
                              <small className="text-danger mb-3">
                                {error.message}
                              </small>
                            )}
                          </div>
                          <div className="col-2">
                            <div
                              className={`d-flex align-items-center w-fit ${
                                loading || saving
                                  ? "disabled"
                                  : "cursor-pointer"
                              }`}
                              onClick={() => {
                                if (!loading && !saving)
                                  setFormData({
                                    ...formData,
                                    isPayingTPR: !formData.isPayingTPR,
                                  });
                              }}
                            >
                              {formData.isPayingTPR ? (
                                <span
                                  className="material-icons mr-1"
                                  style={{ color: "#007bff" }}
                                >
                                  check_box
                                </span>
                              ) : (
                                <span className="material-icons-outlined mr-1">
                                  check_box_outline_blank
                                </span>
                              )}
                              Is Paying TPR
                            </div>

                            <div
                              className={`d-flex align-items-center w-fit ${
                                loading || saving
                                  ? "disabled"
                                  : "cursor-pointer"
                              }`}
                              onClick={() => {
                                if (!loading && !saving)
                                  setFormData({
                                    ...formData,
                                    isArrear: !formData.isArrear,
                                  });
                              }}
                            >
                              {formData.isArrear ? (
                                <span
                                  className="material-icons mr-1"
                                  style={{ color: "#007bff" }}
                                >
                                  check_box
                                </span>
                              ) : (
                                <span className="material-icons-outlined mr-1">
                                  check_box_outline_blank
                                </span>
                              )}
                              Is Arrear
                            </div>
                          </div>
                          <div className="col-2">
                            <div
                              className={`d-flex align-items-center w-fit ${
                                loading || saving
                                  ? "disabled"
                                  : "cursor-pointer"
                              }`}
                              onClick={() => {
                                if (!loading && !saving)
                                  setFormData({
                                    ...formData,
                                    isPayingCBHI: !formData.isPayingCBHI,
                                  });
                              }}
                            >
                              {formData.isPayingCBHI ? (
                                <span
                                  className="material-icons mr-1"
                                  style={{ color: "#007bff" }}
                                >
                                  check_box
                                </span>
                              ) : (
                                <span className="material-icons-outlined mr-1">
                                  check_box_outline_blank
                                </span>
                              )}
                              Is Paying Mutuelle
                            </div>
                          </div>
                        </div>

                        <Divider className="mt-3 mb-1" />
                        <div className="d-flex justify-content-center py-0 p-2">
                          <button
                            onClick={handleSave}
                            type="button"
                            className="btn btn-primary text-uppercase"
                            disabled={loading || saving}
                          >
                            {saving ? "Wait..." : "Save"}
                          </button>
                          &nbsp; &nbsp;
                          <button
                            onClick={handleCloseForm}
                            type="button"
                            className="btn btn-default text-uppercase"
                            disabled={loading || saving}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}

                    {!!employeePBFSettings?.length && (
                      <React.Fragment>
                        <table className="table table-bordered table-sm mt-3">
                          <thead>
                            <tr>
                              <th colSpan={12} style={{ textAlign: "center" }}>
                                EMPLOYEE PBF
                              </th>
                            </tr>
                            <tr>
                              <td style={{ backgroundColor: "#e9ecef" }}>#</td>

                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-left"
                              >
                                Coste center
                              </td>

                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-left"
                              >
                                Source of fund
                              </td>

                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-left"
                              >
                                Payroll Group
                              </td>

                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-left"
                              >
                                PBF Index Group
                              </td>

                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-left"
                              >
                                Period
                              </td>

                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-center"
                              >
                                Type
                              </td>

                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-center"
                              >
                                TPR
                              </td>

                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-center"
                              >
                                Days
                              </td>
                              <td
                                style={{ backgroundColor: "#e9ecef" }}
                                className="text-center"
                              >
                                Marks
                              </td>

                              <td style={{ backgroundColor: "#e9ecef" }}></td>
                            </tr>
                          </thead>
                          <tbody>
                            {employeePBFSettings.map(
                              (employeePBFItem, index) => (
                                <EmployeePBFItem
                                  key={
                                    employeePBFItem.periodId +
                                    "-" +
                                    employeePBFItem.employeeId
                                  }
                                  employeePBFItem={employeePBFItem}
                                  setFormData={setFormData}
                                  handleSetFormForUpdate={
                                    handleSetFormForUpdate
                                  }
                                  index={index}
                                  isDisabled={loading || saving || add}
                                  setSelectedEmployeePBF={
                                    setSelectedEmployeePBF
                                  }
                                  setConfirmRemoveEmployeePBF={
                                    setConfirmRemoveEmployeePBF
                                  }
                                />
                              )
                            )}
                          </tbody>
                        </table>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="py-3"></DialogActions>
      </Dialog>

      {confirmRemoveEmployeePBF && (
        <ConfirmationDialog
          confirmationDialog={true}
          disabled={saving}
          message={`Are you sure you want to remove this PBF Settings?`}
          setConfirmationDialog={() => {
            setConfirmRemoveEmployeePBF(false);
            setSelectedEmployeePBF(null);
          }}
          onYes={handleDeletePBF}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  costCenters,
  periods,
  sourceOfFunds,
  pbfIndexGroup,
  employeePbfSettings,
}) => {
  return {
    loading,
    user,
    entitySectorId: user.selectedEntitySector.id,
    costCenters,
    periods,
    sourceOfFunds,
    pbfIndexGroup,
    employeePbfSettings,
  };
};
export default connect(mapStateToProps, {
  getActiveCostCenters,
  getSourceOfFunds,
  getPBFIndexGroup,
  addEmployeePBFSettings,
  getEmployeePBFSettings,
  updateEmployeePBFSettings,
  removeEmployeePBFSettings,
})(EmployeePBFSettingsDialog);

const EmployeePBFItem = (props) => {
  const {
    employeePBFItem,
    handleSetFormForUpdate,
    setConfirmRemoveEmployeePBF,
    setSelectedEmployeePBF,
    index,
    isDisabled,
  } = props;

  return (
    <tr
      style={{
        background: index % 2 === 0 ? "" : "#f6f7f7",
        borderBottom: "2px dotted rgb(7, 142, 206)!important",
      }}
    >
      <td>{index + 1}</td>
      <td>{employeePBFItem?.costCenter?.name || "N/A"}</td>
      <td>{employeePBFItem?.sourceOfFund?.name || "N/A"}</td>
      <td>{employeePBFItem?.payrollGroup?.name || "N/A"}</td>
      <td>{employeePBFItem?.pbfIndexGroup?.name || "N/A"}</td>
      <td>{employeePBFItem?.period?.name || "N/A"}</td>
      <td className="text-center">
        {employeePBFItem?.isArrear ? "Arrear" : "Normal"}
      </td>
      <td className="text-center">
        {employeePBFItem?.isPayingTPR ? "Is Paying TPR" : "-"}
      </td>
      <td className="text-center">{employeePBFItem?.nOfDays || 0}</td>
      <td className="text-center">{employeePBFItem?.mark || 0}</td>

      <td className="text-center">
        {/* {employeePBFItem.status === "Unpaid" ? ( */}
        <div className="d-flex align-items-center justify-content-center">
          <IconButton
            size="small"
            color="inherit"
            onClick={() => handleSetFormForUpdate(employeePBFItem)}
            className="mr-2"
            disabled={isDisabled}
          >
            <span className="material-icons-round">edit</span>
          </IconButton>

          <IconButton
            size="small"
            color="error"
            onClick={() => {
              setSelectedEmployeePBF(employeePBFItem);
              setConfirmRemoveEmployeePBF(true);
            }}
            disabled={isDisabled}
          >
            <span className="material-icons">delete</span>
          </IconButton>
        </div>
        {/* ) : (
          "-"
        )} */}
      </td>
    </tr>
  );
};
