import React, { useEffect, useState } from "react";

import { Pie, Bar as Bar2 } from "react-chartjs-2";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  LabelList,
  ResponsiveContainer,
} from "recharts";

import ChartDataLabels from "chartjs-plugin-datalabels"; // For displaying labels on bars
import { numberFormatter } from "./Utils";

const options = {
  indexAxis: "y", // Makes the chart horizontal
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    datalabels: {
      anchor: "end",
      align: "end",
      formatter: (value, context) => {
        // const total = context.chart.data.datasets[1].data[context.dataIndex]; // Access total data
        // const percentage = ((value / total) * 100).toFixed(0); // Calculate percentage
        return `${numberFormatter(value)}`; // Return formatted string
      },
      color: "#000", // Label color
      font: {
        size: 10, // Label font size
      },
    },
  },
  scales: {
    x: {
      title: {
        display: false,
        text: "Number of Employees",
      },
      ticks: {
        callback: (value) => {
          return null; //numberFormatter(value);
        },
      },
    },
    y: {
      title: {
        display: false,
        text: "Education Level",
      },
      stacked: false, // Set to false to separate the bars
    },
  },
  layout: {
    padding: {
      left: 20, // Add margin on the left
      right: 20, // Add margin on the right
      top: 20, // Add margin on the top
      bottom: 20, // Add margin on the bottom
    },
    margin: {
      left: 20, // Add margin on the left
      right: 20, // Add margin on the right
      top: 20, // Add margin on the top
      bottom: 20, // Add margin on the bottom
    },
  },
};

export const ReBarChart = (props) => {
  const { labels, data } = props;

  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 5, right: 50, left: 0, bottom: 5, float: "left" }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={labels?.xAxisKey || "name"}
            tick={{ angle: -90, textAnchor: "end" }} // Rotate the labels to vertical alignment
            interval={0} // Show all labels
            height={150} // Set height to allow space for rotated labels
          />
          <YAxis tickFormatter={numberFormatter} tick={false} />
          <Tooltip formatter={(value) => numberFormatter(value)} />{" "}
          {/* Format tooltip numbers */}
          <Legend layout="horizontal" verticalAlign="top" align="right" />
          <Bar dataKey={labels?.barKey1 || ""} fill="#82ca9d" minPointSize={5}>
            {/* Custom formatter for Hired with centered alignment */}
            <LabelList
              dataKey={labels?.barKey1 || ""}
              position="top"
              formatter={(value) => {
                // const total = data[index];
                // const percentage = ((value / entry.payload.total) * 100).toFixed(0); // Calculate percentage
                return `${numberFormatter(value)}`; // Return formatted string
              }}
              style={{
                fontSize: "8px",
                textAnchor: "left",
              }} // Center-aligned text
            />
          </Bar>
          <Bar
            dataKey={labels?.barKey2 || ""}
            fill="rgb(7, 142, 206)"
            minPointSize={10}
          >
            <LabelList
              dataKey={labels?.barKey2 || ""}
              position="top"
              style={{ fontSize: "10px" }}
              formatter={numberFormatter}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export const BarChartJs = (props) => {
  const { width, height, data } = props;

  const [labels, setLabels] = useState([]);
  const [datasetData, setDatasetData] = useState([]);
  useEffect(() => {
    setLabels(
      data?.datasets?.map((item) => item[data?.labelKey || "name"]) || []
    );

    const datasetData =
      data?.datasetLabelKeys?.map((key) => ({
        label: key?.name || "",
        data: data?.datasets?.map((item) => item[key?.name || ""]) || [], // Dynamic data based on the key
        backgroundColor: key?.color || "#000", // Use the color map
        barThickness: 10, // Set bar thickness
        maxBarThickness: 30, // Maximum bar thickness
        barPercentage: 0.9, // Space between bars within each category
      })) || [];

    setDatasetData(datasetData);
  }, [data]);

  return (
    <>
      <Bar2
        data={{
          labels: labels, // ["UR", "UK", "AUCA", "ETP", "KING DAVID", "SOS"],
          datasets: datasetData,
        }}
        options={options}
        plugins={[ChartDataLabels]}
      />
    </>
  );
};
