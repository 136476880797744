import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
export const routers = [
  {
    name: "InProgress",
    path: "/recruitment/appeals",
    to: "/recruitment/appeals/in-progress",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF", "IS_NPSC", "IS_NPSC_APPROVER","IS_RDB_SECOND_LEVEL"],
  },
  {
    name: "NPSC Appeals",
    path: "/recruitment/npsc",
    to: "/recruitment/appeals/npsc",
    permissions: ["IS_RECRUITMENT_ADMIN"],
  },

  {
    name: "Overdue",
    path: "/recruitment/appeals",
    to: "/recruitment/appeals/overdue",
    permissions: ["IS_NPSC", "IS_NPSC_APPROVER"],
  },
  {
    name: "Archived",
    path: "/recruitment/appeals",
    to: "/recruitment/appeals/archived",
    permissions: ["IS_HR", "IS_CBM", "IS_DAF", "IS_NPSC", "IS_NPSC_APPROVER","IS_RDB_SECOND_LEVEL"],
  },
];

const AppealLeftSide = () => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <div className="list-group mt-4 ml-3">
        {routers.map((router, index) => (
          <React.Fragment key={index + 1}>
            {hasPermissions(router.permissions) && (
              <Link
                style={{ textDecoration: "none", color: "#fff" }}
                to={router.to}
                className={`list-group-item list-group-item-action  ${
                  location.pathname.includes(router.to)
                    ? "active"
                    : "text-dark "
                }`}
              >
                <span className="text-truncate  menu-item">{router.name}</span>
              </Link>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default AppealLeftSide;
