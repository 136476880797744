import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  assignRolePermissions,
  getPermissions,
} from "../../../store/admin/actions";
import { showError } from "../../../app/toastify";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AssignPermissionDialog = (props) => {
  const {
    loading,
    permissions,
    showAssignPermissionForm,
    setShowAssignPermissionForm,
    selectedRole,
    assignRolePermissions,
    getPermissions,
  } = props;

  const [notAssignedPermissions, setNotAssignedPermissions] = useState([]);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    getPermissions();
  }, []);
  useEffect(() => {
    let notAssignedPermissionss = [];
    permissions.forEach((permission) => {
      if (selectedRole.permissions && selectedRole.permissions.length > 0) {
        if (
          !selectedRole.permissions.find(({ name }) => name === permission.name)
        ) {
          notAssignedPermissionss.push(permission);
        }
      } else {
        notAssignedPermissionss.push(permission);
      }
    });
    setNotAssignedPermissions(notAssignedPermissionss);
  }, [permissions, selectedRole]);

  const onSave = () => {
    if (!formData.length) {
      showError("Please fill out all required fields");
      return false;
    }
    let rolePermissons = [];
    formData.forEach((el) => {
      rolePermissons.push({ roleId: selectedRole.id, permission: el.name });
    });
    assignRolePermissions(
      selectedRole,
      formData,
      setFormData,
      rolePermissons,
      setShowAssignPermissionForm,
      setNotAssignedPermissions
    );
  };

  const onClose = () => {
    setShowAssignPermissionForm(false);
    setFormData([]);
    setNotAssignedPermissions([]);
  };
  // console.log(permissions);
  // console.log(notAssignedPermissions);
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAssignPermissionForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Assign Permissions
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={notAssignedPermissions}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={(event, prmssions) => {
              setFormData(prmssions);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Permissions"
                placeholder="Permissions"
              />
            )}
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, permissions }) => {
  return {
    loading,
    permissions,
  };
};
export default connect(mapStateToProps, {
  assignRolePermissions,
  getPermissions,
})(AssignPermissionDialog);
