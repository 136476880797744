import {
  Alert,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TableSkeleton from "../../common/components/TableSkeleton";
import RBMPageHeader from "./RBMPageHeader";
import CustomProgressLine from "./CustomProgressLine";
import { getEntityIndicatorByOutput } from "../../../store/rbm/actions";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { connect } from "react-redux";
import InstitutionIndicatorActivities from "./InstitutionIndicatorActivities";
import ClickableTD from "../../common/components/ClickableTd";

const InstitutionOutputIndicators = (props) => {
  const {
    output,
    loading,
    selectedFiscalYear,
    onClose,
    getEntityIndicatorByOutput,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [indicators, setIndicators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [indicatorSummary, setIndicatorSummary] = useState({
    allIndicators: 0,
    totalWeight: 0,
    numOfActivities: 0,
    dueActivities: 0,
    reportedActivities: 0,
    scoredActivities: 0,
    achievedTarget: 0,
    score: 0,
  });
  const [filteredIndicators, setFilteredIndicators] = useState([...indicators]);
  useEffect(() => {
    if (!!selectedFiscalYear.id) {
      getEntityIndicatorByOutput(
        output,
        setIndicators,
        setIndicatorSummary,
        setLoadingData
      );
    }
  }, [selectedFiscalYear?.id]);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    const filtered =
      searchTerm.length === 0
        ? [...indicators]
        : [...indicators].filter(
            ({ name, unassignedActivities }) =>
              (searchTerm.toLocaleLowerCase() === "unassigned" &&
                unassignedActivities > 0) ||
              (name || "").toLowerCase().includes(searchTerm.toLowerCase())
          );

    setFilteredIndicators(filtered);
    paginate(filtered);
  }, [searchTerm, indicators]);

  useEffect(() => {
    paginate(filteredIndicators);
  }, [itemOffset, itemsPerPage, indicators, filteredIndicators]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredIndicators.length || 0;

    setCurrentPage(event.selected * itemsPerPage);

    setItemOffset(newOffset);
  };
  if (selectedIndicator) {
    return (
      <InstitutionIndicatorActivities
        indicator={selectedIndicator}
        onClose={() => setSelectedIndicator(null)}
      />
    );
  }
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth={`xl`}
    >
      <DialogTitle>
        <Typography className="text-primary text-uppercase">
          <span className="d-flex align-items-center">
            <span className="badge badge-pill badge-primary mx-1">
              SELECTED OUTPUT
            </span>
            <span>{output.name}</span>
          </span>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
        <div className="mt-3 px-2">
          <RBMPageHeader
            labels={[
              {
                title: "Indicators",
                value: indicatorSummary.allIndicators,
                type: "number",
              },
              {
                title: "Activities",
                value: indicatorSummary.numOfActivities || 0,
                type: "number",
              },
              {
                title: "Unassigned",
                value: indicatorSummary.unassigned || 0,
                type: "number",
                color: "warning",
                onClick: () => {
                  if (indicatorSummary.unassigned > 0) {
                    setSearchTerm("unassigned");
                  }
                },
              },
              {
                title: "Due",
                value: indicatorSummary.dueActivities || 0,
                type: "number",
              },
              {
                title: "Reported",
                value: indicatorSummary.reportedActivities || 0,
                type: "number",
              },
              {
                title: "Scored",
                value: indicatorSummary.scoredActivities || 0,
                type: "number",
              },
              {
                title: "Target achieved",
                value: (indicatorSummary.achievedTarget || 0).toFixed(2),
                type: "percentage",
              },
            ]}
            progress={{
              title: "OVERALL INDICATOR PROGRESS",
              value: (indicatorSummary.score || 0).toFixed(2),
            }}
            // disabled={loading}
            disabled={true}
            loading={loading}
          />

          <div className="w-100">
            <input
              type="text"
              value={searchTerm}
              className="form-control"
              id="filter-indicator"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={loadingData || loading}
            />
          </div>
        </div>
      </DialogTitle>

      <DialogContent className="mx-4 px-2">
        {output.searchTerm && (
          <Alert severity="error" className="mb-2">
            Unassigned indicators
          </Alert>
        )}
        <div className="table-responsive w-100">
          <table
            className={`table table-sm ${
              !loading && !loadingData ? "table-hover" : ""
            }`}
          >
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  #
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Indicator
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Baseline
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  MeasurementUnit
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Annual Target
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Q1 Target
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Q2 Target
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Q3 Target
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Q4 Target
                </th>
                <th
                  className="text-center"
                  style={{
                    borderLeft: "3px solid #dee2e6",
                    borderRight: !output.searchTerm ? "" : "3px solid #dee2e6",
                  }}
                >
                  Activities
                </th>
                {!output.searchTerm && (
                  <th
                    className="text-center"
                    style={{ borderLeft: "3px solid #dee2e6" }}
                  >
                    Unassigned
                  </th>
                )}
                {!output.searchTerm && (
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                    }}
                  >
                    Reported
                  </th>
                )}
                {!output.searchTerm && (
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "3px solid #dee2e6",
                      borderRight: "3px solid #dee2e6",
                    }}
                  >
                    Target Achieved
                  </th>
                )}
              </tr>
            </thead>
            {!loadingData ? (
              <tbody style={{ fontSize: "13px" }}>
                {paginatedItems[0] ? (
                  paginatedItems.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {currentPage + index + 1}
                      </td>
                      <td
                        className="text-left"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        <div className="d-inline-flex justify-content-between align-items-center w-100">
                          {item.name}

                          {item.score !== null && (
                            <div style={{ minWidth: 80 }}>
                              <CustomProgressLine value={item.score || 0} />
                            </div>
                          )}
                        </div>
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.baseline || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.measurementUnit || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.annualTarget || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.quarter1Target || "0"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.quarter2Target || "0"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.quarter3Target || "0"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.quarter4Target || "0"}
                      </td>
                      <ClickableTD
                        onClick={() =>
                          setSelectedIndicator({
                            name: item.name,
                            id: item.id,
                          })
                        }
                        borderBottom={
                          paginatedItems.length === index + 1
                            ? "3px solid #dee2e6"
                            : ""
                        }
                        borderRight={
                          !output.searchTerm ? "" : "3px solid #dee2e6"
                        }
                        disabled={loading || loadingData}
                      >
                        {item.numOfActivities}
                      </ClickableTD>
                      {!output.searchTerm && (
                        <ClickableTD
                          onClick={() =>
                            setSelectedIndicator({
                              name: item.name,
                              id: item.id,
                            })
                          }
                          borderBottom={
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : ""
                          }
                          disabled={loading || loadingData}
                        >
                          {item.unassignedActivities}
                        </ClickableTD>
                      )}
                      {!output.searchTerm && (
                        <ClickableTD
                          onClick={() =>
                            setSelectedIndicator({
                              name: item.name,
                              id: item.id,
                            })
                          }
                          borderBottom={
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : ""
                          }
                          disabled={loading || loadingData}
                        >
                          {item.submissions}
                        </ClickableTD>
                      )}
                      {!output.searchTerm && (
                        <td
                          className="text-center"
                          style={{
                            borderLeft: "3px solid #dee2e6",
                            borderRight: "3px solid #dee2e6",
                            borderBottom:
                              paginatedItems.length === index + 1
                                ? "3px solid #dee2e6"
                                : "",
                          }}
                        >
                          {item.achievedTarget || "-"}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      className="text-center py-4"
                      style={{ border: "3px solid #dee2e6" }}
                      colSpan={!output.searchTerm ? 13 : 10}
                    >
                      <span style={{ fontSize: "17px", fontWeight: "600" }}>
                        No Data Found!
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <TableSkeleton cols={!output.searchTerm ? 13 : 10} rows={15} />
            )}
          </table>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-0">
        <div>
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1"
            activeLinkClassName="active"
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, selectedFiscalYear }) => {
  return {
    loading,
    selectedFiscalYear,
  };
};
export default connect(mapStateToProps, { getEntityIndicatorByOutput })(
  InstitutionOutputIndicators
);
