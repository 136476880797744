import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { connect } from "react-redux";

import { selectModule } from "../../../store/common/actions";
import { useRequiredAuth } from "../guard/requiredAuth";

const ModulesMenu = (props) => {
  const { selectModule, user, ippisModules, selectedModule } = props;
  const { hasPermissions } = useRequiredAuth();
  const history = useHistory();
  const location = useLocation();
  return (
    <>
      <div className="d-flex modules-menu  align-items-center flex-wrap ">
        {ippisModules?.map((mod, index) => (
          <React.Fragment key={index}>
            {(user?.selectedEntitySector?.permissions[mod.permit] ||
              user?.selectedEntitySector?.permissions.IS_ADMIN) &&
              mod.isActive && (
                <>
                  {(mod.id !== "TRAINING" ||
                    (!!user.selectedEntitySector.canUseTrainingModule &&
                      mod.id === "TRAINING") ||
                    (user.selectedEntitySector.sectorId === 2 &&
                      mod.id === "TRAINING")) &&
                      (mod.id !== "RECRUITMENT" ||
                        (!!user?.canAccessRecruitment &&
                          mod.id === "RECRUITMENT")) 
                          && (
                    <button
                      className={`mr-1 py-0 btn btn-link btn-sm  ${
                        location.pathname.startsWith(`${mod?.to}`)
                          ? " active  "
                          : " "
                      }`}
                      onClick={() => selectModule(mod, history)}
                    >
                      <span className="d-inline-flex align-items-center">
                        <img
                          src={require(`../../assets/icons/${mod.icon}`)}
                          width="24"
                          alt=""
                          className="mr-1"
                        />
                        {mod.shortName}
                      </span>
                    </button>
                  )}
                </>
              )}
          </React.Fragment>
        ))}
    <button
          className={`mr-1 py-0 btn btn-link btn-sm   ${
            location.pathname.includes("/psrp-portal") ? " active  " : " "
          }`}
          onClick={() => {
            selectModule(
              {
                ...selectedModule,
                shortName: "PSRP Portal",
                fullName: "PSRP Portal",
                to: "/psrp-portal",
              },
              history
            );

            history.push("/psrp-portal");
          }}
        >
          <span className="d-inline-flex align-items-center py-0">
            <span className="material-icons ">launch</span>
            PSRP Portal
          </span>
        </button>
        {hasPermissions(["IS_ADMIN"]) && (
          <button
            className={`mr-1 py-0 btn btn-link btn-sm  ${
              location.pathname.includes("/admin") ? " active  " : " "
            }`}
            onClick={() => {
              selectModule(
                {
                  ...selectedModule,
                  shortName: "Administration",
                  fullName: "System Administration",
                  to: "/admin",
                },
                history
              );
              history.push("/admin");
            }}
          >
            <span className="d-inline-flex align-items-center py-0">
              <span className="material-icons ">security</span>
              Admin
            </span>
          </button>
        )}

        {hasPermissions(["IS_SUPPORTER", "IS_ADMIN"]) && (
          <button
            className={`mr-1 py-0 btn btn-link btn-sm  ${
              location.pathname.includes("/support") ? " active  " : " "
            }`}
            onClick={() => {
              selectModule(
                {
                  ...selectedModule,
                  shortName: "Support",
                  fullName: "System Support",
                  to: "/support",
                },
                history
              );

              history.push("/support");
            }}
          >
            <span className="d-inline-flex align-items-center py-0">
              <span className="material-icons ">help</span>
              Support
            </span>
          </button>
        )}

        <button
          className={`mr-1 py-0 btn btn-link btn-sm   ${
            location.pathname.includes("/user/profile") ? " active  " : " "
          }`}
          onClick={() => {
            selectModule(
              {
                ...selectedModule,
                shortName: "My Profile",
                fullName: "My Profile",
                to: "/user/profile",
              },
              history
            );

            history.push("/user/profile");
          }}
        >
          <span className="d-inline-flex align-items-center py-0">
            <span className="material-icons ">account_circle</span>
            My Profile
          </span>
        </button>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, ippisModules, selectedModule }) => {
  return {
    user,
    ippisModules,
    selectedModule,
  };
};
export default connect(mapStateToProps, { selectModule })(ModulesMenu);
