import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { mergeUserIds } from "../../../store/support/actions";
import { connect } from "react-redux";
import { findEmployeeDetails } from "../../../store/structure/actions";
const MergeDuplicateUser = (props) => {
  const {
    theRealUserId,
    showMergeDuplicateUserDialog,
    setShowMergeDuplicateUserDialog,
    employeeSearchTerm,
    mergeUserIds,
    setIsSearching,
    findEmployeeDetails,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    toBeDeletedUserId: "",
    theRealUserId: theRealUserId,
    employeeSearchTerm: employeeSearchTerm,
  });
  const [errors, setErrors] = useState({
    toBeDeletedUserId: false,
  });
  const onClose = () => {
    setFormData({
      toBeDeletedUserId: "",
      theRealUserId: theRealUserId,
    });
    setErrors({
      toBeDeletedUserId: false,
    });

    setShowMergeDuplicateUserDialog(false);
  };

  const onSubmit = () => {
    let hasError = false;
    if (!formData.toBeDeletedUserId) {
      setErrors({
        ...errors,
        toBeDeletedUserId: true,
      });
      hasError = true;
    }

    if (hasError) return;

    mergeUserIds(formData, (status, res) => {
      setIsSubmitting(status);
      if (!status && res) {
        findEmployeeDetails(
          {
            entitySectorId: null,
            searchKeyword: employeeSearchTerm,
          },
          setIsSearching
        );
        onClose();
      }
    });
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showMergeDuplicateUserDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="text-dark pb-2 ">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "70%" }}
        >
          Merge user
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      {(isSubmitting ) && <LinearProgress />}
      <DialogContent className="py-2">
        <TextField
          error={errors.toBeDeletedUserId}
          helperText={"user id is missing"}
          fullWidth
          size="small"
          label="Enter user id to be deleted"
          type="number"
          name="toBeDeletedUserId"
          variant="outlined"
          defaultChecked
          placeholder="Enter user id to be deleted"
          className="mb-3"
          disabled={isSubmitting}
          value={formData.toBeDeletedUserId || ""}
          onBlur={() => {
            if (formData.toBeDeletedUserId === "")
              setFormData({
                ...formData,
                toBeDeletedUserId: "",
              });
          }}
          onChange={(e) => {
            const toBeDeletedUserId = e.target.value;
            setFormData({
              ...formData,
              toBeDeletedUserId: toBeDeletedUserId,
            });
            setErrors({
              ...errors,
              toBeDeletedUserId: false,
            });
          }}
        />

        <div className="d-flex justify-content-center">
          <button
            onClick={onSubmit}
            type="button"
            className="btn btn-sm btn-primary text-uppercase px-5"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Wait..." : "Submit"}
          </button>
        </div>
      </DialogContent>
      <DialogActions className="py-2"></DialogActions>
    </Dialog>
  );
};

export default connect(null, {
  mergeUserIds,
  findEmployeeDetails,
})(MergeDuplicateUser);
