import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTheme } from "@mui/material/styles";
import AdjustIcon from "@mui/icons-material/Adjust";
import {
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Chip,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { saveEmployeePromotion } from "../../../store/structure/actions";
import moment from "moment";

const ActiveButton = {
  borderRadius: "8px",
  height: "40px",

  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
};

const EmployeePromotionsDialog = (props) => {
  const {
    loading,
    onWait,
    employee,
    showEmployeePromotionsDialog,
    setShowEmployeePromotionsDialog,
    saveEmployeePromotion,
    employeePromotions,
  } = props;

  const theme = useTheme();

  const [scales, setScales] = useState([]);

  const filterScares = () => {
    const scales = [];

    employeePromotions.forEach((echelon) => {
      if (echelon.id < employee.scaleId) scales.push(echelon);
    });

    setScales(scales);
  };

  useEffect(() => {
    filterScares();
  }, [employeePromotions, employee, showEmployeePromotionsDialog]);

  // console.log(employeePromotions);

  // const steps = ["II", "III"];

  const [showPromotionDialog, setShowPromotionDialog] = useState(false);

  const [isDemotion, setIsDemotion] = useState(false);

  return (
    <>
      <PromotionDialog
        employeePromotions={employeePromotions}
        showPromotionDialog={showPromotionDialog}
        setShowPromotionDialog={setShowPromotionDialog}
        saveEmployeePromotion={saveEmployeePromotion}
        onWait={onWait}
        loading={loading}
        employee={employee}
        isDemotion={isDemotion}
        setIsDemotion={setIsDemotion}
      />

      <Dialog
        onClose={() => setShowEmployeePromotionsDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={showEmployeePromotionsDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-dark">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            Employee promotions |{" "}
            <strong className="text-primary">
              {employee?.lastName} {employee?.firstName}
            </strong>{" "}
          </Typography>
          <Tooltip title="Download">
            <span>
              <IconButton
                disabled
                className="p-0"
                color="primary"
                sx={{
                  position: "absolute",
                  right: 54,
                  top: 12,
                }}
              >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            aria-label="close"
            onClick={() => setShowEmployeePromotionsDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>

          {/* <Divider className="my-1" /> */}
        </DialogTitle>

        <DialogContent>
          <div
            className=" "
            style={{
              minHeight: "30vh",
              // backgroundColor: "#fafafa",
              // border: "1px solid #17a2b8",
            }}
          >
            {/* <h6 className=" text-center mb-4">{employee.positionName}</h6> */}

            <div className="text-center">
              <Chip
                fullWidth
                style={{
                  borderRadius: "0px",
                  width: "100%",
                  backgroundColor: "#eee",
                  fontWeight: "bold",
                }}
                label={employee.positionName}
                variant="outlined"
                className=" mb-4 text-uppercase"
              />
            </div>

            {/* <Divider /> */}
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={-1} alternativeLabel>
                {scales.map((scale, index) => (
                  <Step key={scale.id}>
                    <StepLabel
                      StepIconComponent={() => (
                        <AdjustIcon
                          sx={{
                            color: (theme) => theme.palette.grey[500],
                          }}
                        />
                      )}
                    >
                      {employee.levelName}.{scale.name}
                      <br />
                      {!!scale.createdOn && (
                        <small className="">
                          {moment(scale.createdOn).format("DD")}
                          <sup>
                            {moment(scale.createdOn).format("Do").slice(-2)}
                          </sup>{" "}
                          {moment(scale.createdOn).format("MMM YY")}
                        </small>
                      )}
                    </StepLabel>
                  </Step>
                ))}
                <Step active>
                  <StepLabel
                    StepIconComponent={() => <AdjustIcon color="primary" />}
                  >
                    {employee.levelName}.{employee.scaleName}
                    <br />
                    <span className="text-success">(Active)</span>
                  </StepLabel>
                </Step>
              </Stepper>
            </Box>

            <div className="d-flex justify-content-center mt-4">
              <Button
                className="mb-2 mx-2"
                style={{
                  ...ActiveButton,
                  border: `1px solid rgb(7, 142, 206)`,
                  backgroundColor: "rgb(7, 142, 206)",
                  "&:hover": {
                    backgroundColor: "#f0f2f5",
                  },
                  padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                }}
                size="sm"
                onClick={() => {
                  setIsDemotion(false);
                  setShowPromotionDialog(true);
                }}
              >
                <span className="material-icons">add_circle_outline</span>
                Promote
              </Button>

              <Button
                className="mb-2 mx-2"
                style={{
                  ...ActiveButton,
                  border: `1px solid #dc3545`,
                  backgroundColor: "#dc3545",
                  "&:hover": {
                    backgroundColor: "#f0f2f5",
                  },
                  padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                }}
                size="sm"
                onClick={() => {
                  setIsDemotion(true);
                  setShowPromotionDialog(true);
                }}
              >
                <span className="material-icons">delete_forever</span>
                Demote
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait, employeePromotions }) => {
  return {
    loading,
    onWait,
    employeePromotions,
  };
};
export default connect(mapStateToProps, { saveEmployeePromotion })(
  EmployeePromotionsDialog
);

const PromotionDialog = (props) => {
  const {
    employeePromotions,
    showPromotionDialog,
    setShowPromotionDialog,
    onWait,
    loading,
    employee,
    saveEmployeePromotion,
    isDemotion,
    setIsDemotion,
  } = props;
  const theme = useTheme();

  const [echelon, setEchelon] = useState(null);

  useEffect(() => {
    setEchelon(null);
  }, [showPromotionDialog]);

  const filteremployeePromotions = () =>
    isDemotion
      ? employeePromotions.filter(({ id }) => id < employee.scaleId)
      : employeePromotions.filter(({ id }) => id > employee.scaleId);

  const [errors, setErrors] = useState({
    echelonHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      echelonHasError: false,
      hasError: false,
    };

    if (!echelon) {
      error.echelonHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      saveEmployeePromotion(
        {
          employeePositionId: employee.employeePositionId,
          scaleId: echelon.id,
        },
        setShowPromotionDialog
      );
    }
  };

  return (
    <Dialog
      onClose={() => setShowPromotionDialog(false)}
      aria-labelledby="customized-dialog-title"
      open={showPromotionDialog}
    >
      <DialogTitle className="text-dark pb-0">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          {isDemotion ? "Demote" : "Promote"}
        </Typography>

        <IconButton
          aria-label="close"
          onClick={() => setShowPromotionDialog(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div style={{ width: "192px" }}>
          <Autocomplete
            size="small"
            className="mt-3"
            id="scale"
            name="scale"
            defaultValue={null}
            value={echelon}
            options={filteremployeePromotions()}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, echelon) => {
              setEchelon(echelon || null);

              setErrors({
                ...errors,
                echelonHasError: false,
              });
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, echelon) => (
              <Box component="li" {...props}>
                {echelon.name}{" "}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select scale"
                placeholder="Select scale"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "Scale",
                }}
              />
            )}
          />
          {errors.echelonHasError && (
            <small className="text-danger">Please, select scale</small>
          )}
          <div className="d-flex justify-content-center mt-4">
            <Button
              disabled={onWait || loading}
              className="mb-2"
              style={{
                ...ActiveButton,
                border: isDemotion
                  ? "`1px solid #dc3545`"
                  : `1px solid rgb(7, 142, 206)`,
                backgroundColor: isDemotion ? "#dc3545" : "rgb(7, 142, 206)",
                "&:hover": {
                  backgroundColor: isDemotion ? "" : "#f0f2f5",
                },

                padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
              }}
              size="sm"
              onClick={onSave}
            >
              {onWait
                ? "Wait..."
                : isDemotion
                ? "Confirm demote"
                : "Confirm promote"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
