import { Skeleton } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getRequisitionCompetencies } from "../../../store/recruitment/actions";
import ReactPaginate from "react-paginate";
import ListSkeleton from "../../common/components/ListSkeleton";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from "@mui/material";

const RequisitionCompetency = (props) => {
  const { requisitionId, requisitionCompetencies, getRequisitionCompetencies } =
    props;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getRequisitionCompetencies(requisitionId, setIsLoading);
  }, [requisitionId]);

  const getNo = (idToSearch) =>
    requisitionCompetencies.findIndex(({ id }) => id === idToSearch);
  return (
    <>
      {!isLoading && !!requisitionCompetencies.length && (
        <div className="col-lg-12 mt-3">
          <strong> Competencies and Key Technical Skills</strong>

          <List
            className="mt-2"
            sx={{
              borderRadius: "8px",
              border: `1px solid #c8c6c6`,
              backgroundColor: "#f9f9f9",
            }}
            dense
          >
            {requisitionCompetencies.map((row, index) => (
              <React.Fragment key={row.id}>
                <ListItem dense>
                  <ListItemAvatar>
                    <Avatar
                      style={{
                        fontSize: "16px",
                        border: "1px solid #c8c6c6",
                      }}
                      className="bg-white text-dark "
                    >
                      {getNo(row.id) + 1}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <span className={`${"font-weight-light"}`}>
                        {row.competency.name}
                      </span>
                    }
                  />
                </ListItem>

                {requisitionCompetencies.length > index + 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </div>
      )}

      {isLoading && (
        <div className="col-lg-12 mt-3">
          <ListSkeleton />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, requisitionCompetencies }) => {
  return {
    loading,
    requisitionCompetencies,
  };
};
export default connect(mapStateToProps, {
  getRequisitionCompetencies,
})(RequisitionCompetency);
