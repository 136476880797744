const types = {
  USER_FOUND: "USER_FOUND",
  SET_ENTITY_SECTORS: "SET_ENTITY_SECTORS",
  SET_ENTITY_SECTOR_UNITS: "SET_ENTITY_SECTOR_UNITS",
  SET_COST_CENTERS: "SET_COST_CENTERS",
  SET_ENTITY_SECTOR_COST_CENTERS: "SET_ENTITY_SECTOR_COST_CENTERS",
  SET_ENTITY_SECTOR_CREDITORS: "SET_ENTITY_SECTOR_CREDITORS",
  SET_ENTITY_SECTOR_PAYROLL_TYPES: "SET_ENTITY_SECTOR_PAYROLL_TYPES",
  SET_EXAM_CENTERS: "SET_EXAM_CENTERS",
  ADD_EXAM_CENTER: "ADD_EXAM_CENTER",
  EDIT_EXAM_CENTER: "EDIT_EXAM_CENTER",
  EDIT_EXAM_CENTER_STATUS: "EDIT_EXAM_CENTER_STATUS",
  SET_CENTER_IP_ADDRESS: "SET_CENTER_IP_ADDRESS",
  ADD_CENTER_IP_ADDRESS: "ADD_CENTER_IP_ADDRESS",
  EDIT_CENTER_IP_ADDRESS: "EDIT_CENTER_IP_ADDRESS",
  EDIT_CENTER_IP_ADDRESS_STATUS: "EDIT_CENTER_IP_ADDRESS_STATUS",
};

export default types;
