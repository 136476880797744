import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  FormControlLabel,
  Typography,
  FormGroup,
  Checkbox,
  InputAdornment,
  Skeleton,
  Tabs,
  Tab,
  Divider,
  FormLabel,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Chip,
  Alert,
  Tooltip,
} from "@mui/material";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  // getPosition,

  // getSalaryStructure,
  getEmployeeAllowances,
  getEmployeeWithholds,
  doVerifyRSSBNumber,
  addOrUpdateMedical,
  // getEmployeeBankAccounts,
  addOrUpdateEmployeeBankAccount,
  deleteEmployeeBankAccount,
  getEmployeeArrears,
  getCasualSalaries,
  savePaymentStatus,
  saveEmployeeVerticalRank,
} from "../../../store/structure/actions";
import { getActiveCurrencies } from "../../../store/payroll/actions";

import EmployeeAllowances from "./EmployeeAllowances";
import EmployeeDeductions from "./EmployeeDeductions";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { showError } from "../../toastify";

import { useTheme } from "@mui/material/styles";
import NumberFormat from "react-number-format";
import CurrencyFormat from "react-currency-format";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import EmployeeArrears from "./EmployeeArrears";
import EmployeeContract from "./../contractuals/components/EmployeeContract";
import { currencyFormat } from "../../common/components/Utils";
import EmployeePBFScores from "./EmployeePBFScores";
import axios from "axios";
import CasualSalaries from "./CasualSalaries";

// const ActiveButton = {
//   borderRadius: "8px",
//   height: "32px",
//   border: `1px solid rgb(7, 142, 206)`,
//   color: "#fff",
//   alignItems: "center",
//   fontSize: ".75rem",
//   whiteSpace: "nowrap",
//   overflow: "hidden",
//   textOverflow: "ellipsis",
//   textTransform: "initial",
//   backgroundColor: "rgb(7, 142, 206)",
//   "&:hover": {
//     backgroundColor: "#f0f2f5",
//   },
// };

// const ActiveRedButton = {
//   float: "left",
//   borderRadius: "8px",
//   height: "40px",
//   border: `1px solid #dc3545`,
//   display: "flex",
//   color: "#fff",
//   alignItems: "center",
//   fontSize: ".75rem",
//   whiteSpace: "nowrap",
//   overflow: "hidden",
//   textOverflow: "ellipsis",
//   textTransform: "initial",
//   backgroundColor: "#dc3545",
//   "&:hover": {
//     backgroundColor: "#f0f2f5",
//   },
// };

const EmployeeSalarySettingsDialog = (props) => {
  const {
    loading,
    onWait,
    showSalarySettingsDialog,
    setShowSalarySettingsDialog,
    employee,
    getEmployeeAllowances,
    getEmployeeWithholds,
    banks,
    medicalInsuranceTypes,
    verifyRssbNumber,
    doVerifyRSSBNumber,
    addOrUpdateMedical,

    employeeBankAccounts,

    // getEmployeeBankAccounts,
    addOrUpdateEmployeeBankAccount,
    deleteEmployeeBankAccount,
    getActiveCurrencies,
    getEmployeeArrears,
    getCasualSalaries,
    paymentStatuses,
    savePaymentStatus,
    currencies,
    enableToEditOnlyRSSB,
    calculationMethods,
    verticalRanks,
    saveEmployeeVerticalRank,
  } = props;
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getEmployeeAllowances(employee.employeePositionId);
    getEmployeeWithholds(employee.id, employee.employeePositionId);
    getEmployeeArrears(employee.employeePositionId);
    getCasualSalaries(employee.employeePositionId);
  }, [employee.id]);

  const [medicalInsurance, setMedicalInsurance] = useState(null);
  const [employeeForm, setEmployeeForm] = useState({
    idNumber: "",
    lastName: "",
    firstName: "",
    civilStatus: "",
    countryBirth: "",
    gender: "",
    fatherName: "",
    motherName: "",
    birthDate: "",
    birthPlace: "",
    photo: "",
    email: "",
    phoneNumber: "",
    positionId: "",
    levelId: "",
    scaleId: "",
    employeeGroupId: "",
    employeeGroupName: "",
    spouse: "",
    villageId: "",
    signature: "",
    applicationNumber: "",
    nin: "",
    nationalityId: "",
    unitName: "",
    bankId: "",
    bankId2: "",
    accountNumber: "",
    accountNumber2: "",
    medicalInsuranceNumber: "",
    csrNumber: "",
    medicalInsuranceId: "",
    unitId: employee.unitId,
    statusId: 0,
    isActing: false,
    employmentDate: "",
    status: "In service",
    isShared: false,
    isSharing: false,
    position: "",
    profileImage: "",
    countryOfDomicile: "",
    amount: 0,
  });

  const [errors, setErrors] = useState({
    idNumberHasError: false,
    positionHasError: false,
    employeeGroupHasError: false,
    bankHasError: false,
    bank2HasError: false,
    medicalInsuranceHasError: false,
    emailHasError: false,
    phoneHasError: false,
    accountNumberHasError: false,
    accountNumber2HasError: false,
    medicalInsuranceNumberHasError: false,
    csrNumberHasError: false,
    employmentDateHasError: false,
  });

  const insuranceFormValidator = () => {
    const error = {
      medicalInsuranceHasError: false,
      csrNumberHasError: false,
      hasError: false,
    };

    if (!medicalInsurance) {
      error.medicalInsuranceHasError = true;
      error.hasError = true;
    }

    if (
      !employeeForm.csrNumber &&
      medicalInsurance &&
      medicalInsurance.id === 1
    ) {
      error.csrNumberHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const [showAddMedical, setShowAddMedical] = useState(false);
  const [showPaymentStatusDialog, setShowPaymentStatusDialog] = useState(false);

  const [showAddBankAccount, setShowAddBankAccount] = useState(false);
  // const [showAddBankAccount2, setShowAddBankAccount2] = useState(false);
  const [confirmRemoveBank2, setConfirmRemoveBank2] = useState(false);

  const [medical, setMedical] = useState({
    rssbNumber: "",
    medicalInsuranceNo: "",
    medicalInsuranceTypeId: 1,
  });

  const [bankAccount, setBankAccount] = useState({
    id: null,
    bankId: "",
    currencyId: "RWF",
    accountNumber: "",
    accountName: "",
    isSecondAccount: false,
    calculationMethodId: 1,
    amount: 0,
  });

  const [isVerifying, setIsVerifying] = useState(false);

  const bank1 = () =>
    employeeBankAccounts.find(
      ({ isSecondAccount }) => isSecondAccount === false
    );
  const bank2 = () =>
    employeeBankAccounts.find(
      ({ isSecondAccount }) => isSecondAccount === true
    );

  const [showRankDialog, setShowRankDialog] = useState(false);

  return (
    <>
      <Dialog
        onClose={() => setShowSalarySettingsDialog(false)}
        open={showSalarySettingsDialog}
        fullWidth
        maxWidth={"lg"}
      >
        <DialogTitle className="text-primary">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "70%" }}
          >
            <div className="headerorg mb-0">
              {employee && (
                <div className="header_cont">
                  <span className="ml-0 text-dark">
                    Employee Salary settings |{" "}
                  </span>{" "}
                  <span className="ml-0">
                    {employee.lastName} {employee.firstName}
                  </span>{" "}
                </div>
              )}
            </div>
          </Typography>
          {/* <Tooltip title="Download">
            <span>
              <IconButton
                disabled
                className="p-0"
                color="primary"
                sx={{
                  position: "absolute",
                  right: 54,
                  top: 12,
                }}
              >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              </IconButton>
            </span>
          </Tooltip> */}
          <IconButton
            aria-label="close"
            onClick={() => setShowSalarySettingsDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
          <div className="row">
            <div className="col-sm-12 mb-3 d-flex justify-content-center">
              <Tabs
                // orientation="vertical"
                // variant="scrollable"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
              >
                <Tab
                  icon={
                    <span className="material-icons mr-1">
                      settings_applications
                    </span>
                  }
                  label="Basic settings"
                  {...a11yProps(0)}
                />

                <Tab
                  disabled={enableToEditOnlyRSSB}
                  // className={
                  //   employee.employeeGroupId === 1 ||
                  //   employee.employeeGroupId === 2 ||
                  //   employee.employeeGroupId === 4
                  //     ? ""
                  //     : "d-none"
                  // }
                  icon={
                    <span className="material-icons mr-1">bookmark_add</span>
                  }
                  label="Allowances"
                  {...a11yProps(1)}
                />

                <Tab
                  disabled={enableToEditOnlyRSSB}
                  icon={
                    <span className="material-icons mr-1">bookmark_remove</span>
                  }
                  label="DEDUCTIONS"
                  {...a11yProps(2)}
                />

                <Tab
                  disabled={enableToEditOnlyRSSB}
                  icon={<span className="material-icons mr-1">history</span>}
                  label="Arrears"
                  {...a11yProps(3)}
                />
                <Tab
                  disabled={enableToEditOnlyRSSB}
                  className={
                    ![3, 6].includes(employee.employeeGroupId) ? "d-none" : ""
                  }
                  icon={
                    <span className="material-icons mr-1">description</span>
                  }
                  label="Contract"
                  {...a11yProps(4)}
                />

                <Tab
                  disabled={enableToEditOnlyRSSB}
                  className={employee.employeeGroupId !== 5 ? "d-none" : ""}
                  icon={<span className="material-icons mr-1">list_alt</span>}
                  label="Casual Salaries"
                  {...a11yProps(5)}
                />
              </Tabs>
            </div>
          </div>
        </DialogTitle>

        <DialogContent className="py-0">
          <React.Fragment>
            <div className="row">
              <div className="col-sm-12 ">
                <TabPanel value={value} index={0}>
                  <React.Fragment>
                    <div
                      className="p-3 rounded"
                      style={{
                        minHeight: "62vh",
                        border: "1px solid #17a2b8",
                      }}
                    >
                      {![3, 5].includes(employee.employeeGroupId) && (
                        <div className="d-flex justify-content-between">
                          <div></div>

                          <div className="d-flex justify-content-center  align-items-center mb-3">
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="filled"
                              className="bg-secondary text-light"
                              size="small"
                              label={
                                <>
                                  Level:{" "}
                                  {employee.levelName +
                                    "." +
                                    employee.scaleName}
                                </>
                              }
                            />
                            <span className="material-icons mx-2 text-secondary">
                              play_arrow
                            </span>

                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="outlined"
                              className=" "
                              size="small"
                              label={<>Index: {employee.index || 0}</>}
                            />
                            <span className="px-2">{"X"}</span>
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="outlined"
                              className=" "
                              size="small"
                              label={
                                <>IndexValue: {employee.indexValue || 0}</>
                              }
                            />
                            {/* <span className="px-2">{"="}</span> */}
                            <span className="material-icons mx-2 text-secondary">
                              play_arrow
                            </span>
                            <Chip
                              style={{
                                borderRadius: "0px",
                                fontWeight: "bold",
                              }}
                              variant="filled"
                              color="primary"
                              size="small"
                              label={
                                <>
                                  BasePay:{" "}
                                  {currencyFormat(
                                    +employee.indexValue * +employee.index
                                  )}
                                  RWF
                                </>
                              }
                            />
                          </div>
                          <span className="d-flex align-items-center">
                            <span className="d-block text-center">
                              <span className="d-flex align-items-center text-dark">
                                <small className="">Diplomatic rank</small>
                              </span>
                              <small className={`font-weight-bold`}>
                                [{employee.verticalRank || "-Not set-"}]
                              </small>
                            </span>
                            <span className="p-1">
                              <IconButton
                                onClick={() => {
                                  setShowRankDialog(true);
                                }}
                                edge="end"
                                color="primary"
                              >
                                <span className="material-icons">
                                  arrow_upward
                                </span>
                              </IconButton>
                            </span>
                          </span>
                        </div>
                      )}

                      <Divider />
                      <React.Fragment>
                        <div className="row mt-2">
                          <div className="col-8">
                            <div
                              className="px-3 mt-2"
                              style={{
                                border: "1px solid #d1cdcd",
                                borderRadius: "10px",
                                // backgroundColor: "#fafafa",
                              }}
                            >
                              <div className="row">
                                <div className="col-12 d-flex justify-content-between">
                                  <h6 className="mt-2">RSSB Contributions</h6>
                                  <span
                                    style={{
                                      backgroundColor: "#fff",
                                      border: "1px solid #c8c6c6",
                                      borderTopRightRadius: "10px",
                                    }}
                                    className="p-1 mr-n3"
                                  >
                                    <IconButton
                                      disabled={employee.statusId === 30}
                                      onClick={() => {
                                        setMedical({
                                          medicalInsuranceTypeId:
                                            employee.medicalInsuranceTypeId,
                                          rssbNumber: employee.rssbNumber,

                                          medicalInsuranceNo:
                                            employee.medicalInsuranceNo,
                                        });
                                        setShowAddMedical(true);
                                      }}
                                      size="small"
                                      edge="end"
                                      color="primary"
                                    >
                                      {!!employee.rssbNumber && (
                                        <span className="material-icons">
                                          edit
                                        </span>
                                      )}
                                      {!employee.rssbNumber && (
                                        <span className="material-icons">
                                          add
                                        </span>
                                      )}
                                    </IconButton>
                                  </span>
                                </div>

                                <div className="col-12 text-left">
                                  <List
                                    disablePadding
                                    sx={{
                                      width: "100%",
                                    }}
                                    dense
                                    className="pb-2"
                                  >
                                    <ListItem
                                      className="pt-0"
                                      style={{ cursor: "default" }}
                                      secondaryAction={
                                        <span className="d-flex align-items-center">
                                          <span className="d-block text-right mr-5">
                                            <span className="d-block">
                                              RSSB No:{" "}
                                              {!!employee.rssbNumber && (
                                                <Chip
                                                  style={{
                                                    borderRadius: "0px",
                                                  }}
                                                  size="small"
                                                  label={
                                                    <strong>
                                                      {employee.rssbNumber}
                                                    </strong>
                                                  }
                                                  variant="outlined"
                                                />
                                              )}
                                              {!employee.rssbNumber && (
                                                <span className="text-danger">
                                                  --not set--
                                                </span>
                                              )}
                                            </span>
                                            {((!!employee.rssbNumber &&
                                              !employee.rssbVerified) ||
                                              !!isVerifying) && (
                                              <span className="d-block text-right">
                                                {!employee.rssbVerified && (
                                                  <span className="d-flex align-items-center justify-content-end text-danger">
                                                    <span className="material-icons">
                                                      warning
                                                    </span>
                                                    <span>
                                                      RSSB Not Verified
                                                    </span>
                                                  </span>
                                                )}
                                                {!!employee.rssbNumber &&
                                                  !isVerifying && (
                                                    <span
                                                      className="btn btn-link btn-sm p-0 m-0"
                                                      onClick={() =>
                                                        doVerifyRSSBNumber(
                                                          employee,
                                                          setIsVerifying
                                                        )
                                                      }
                                                    >
                                                      Verify now
                                                    </span>
                                                  )}
                                                {isVerifying && (
                                                  <span className="btn btn-link btn-sm p-0 m-0">
                                                    Verifying...
                                                  </span>
                                                )}
                                              </span>
                                            )}

                                            {!!employee.rssbNumber &&
                                              employee.rssbVerified &&
                                              !isVerifying && (
                                                <span
                                                  onClick={() =>
                                                    doVerifyRSSBNumber(
                                                      employee,
                                                      setIsVerifying
                                                    )
                                                  }
                                                  className="btn btn-link btn-sm d-flex align-items-center justify-content-end text-success"
                                                >
                                                  <span>RSSB Verified</span>{" "}
                                                  <span className="material-icons">
                                                    verified
                                                  </span>
                                                </span>
                                              )}
                                          </span>
                                        </span>
                                      }
                                    >
                                      <ListItemAvatar>
                                        <Avatar
                                          style={{
                                            border: "1px solid #c8c6c6",
                                            backgroundColor: "#fff",
                                          }}
                                          className="text-dark "
                                        >
                                          <span className="material-icons">
                                            local_hospital
                                          </span>
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <span>
                                            Medical Insurance:{" "}
                                            <span
                                              className={`text-uppercase badge  ${
                                                employee.medicalInsuranceTypeId ===
                                                0
                                                  ? "badge-danger "
                                                  : "badge-primary"
                                              }`}
                                            >
                                              {
                                                employee.medicalInsuranceTypeName
                                              }
                                            </span>
                                          </span>
                                        }
                                        secondary={
                                          <span>
                                            No:{" "}
                                            {!!employee.medicalInsuranceNo && (
                                              <Chip
                                                style={{ borderRadius: "0px" }}
                                                size="small"
                                                label={
                                                  <strong>
                                                    {
                                                      employee.medicalInsuranceNo
                                                    }
                                                  </strong>
                                                }
                                                variant="outlined"
                                              />
                                            )}
                                            {!employee.medicalInsuranceNo && (
                                              <span className="text-danger">
                                                --not set--
                                              </span>
                                            )}
                                          </span>
                                        }
                                      />
                                    </ListItem>
                                  </List>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 pr-0">
                            <div
                              className=" p-3"
                              style={
                                {
                                  // border: "1px solid #d1cdcd",
                                  // backgroundColor: "#fafafa",
                                }
                              }
                            >
                              <div className="row">
                                {/* <div className="col-12 text-right pr-4">
                                  <h6 className="mb-2">Payment status</h6>
                                </div> */}

                                <div className="col-12 text-left mt-3 pr-0">
                                  <List
                                    // disablePadding
                                    // sx={{
                                    //   width: "100%",
                                    //   borderRadius: "8px",
                                    //   backgroundColor: "transparent",
                                    //   // border: `1px solid #c8c6c6`,
                                    // }}
                                    sx={{
                                      width: "100%",
                                      borderRadius: "8px",
                                      // border: `1px solid #c8c6c6`,
                                    }}
                                    dense
                                    className="mt-2"
                                  >
                                    <ListItem
                                      // disablePadding
                                      style={{ cursor: "default" }}
                                      secondaryAction={
                                        <span className="d-flex align-items-center">
                                          <span className="d-block text-center">
                                            <span className="d-flex align-items-center text-dark">
                                              <span>Payment status</span>
                                            </span>
                                            <span
                                              className={`badge badge-${
                                                paymentStatuses.find(
                                                  ({ id }) =>
                                                    id === employee.statusId
                                                )?.id === 0
                                                  ? "success"
                                                  : "warning"
                                              }`}
                                            >
                                              {
                                                paymentStatuses.find(
                                                  ({ id }) =>
                                                    id === employee.statusId
                                                )?.name
                                              }
                                            </span>
                                          </span>
                                          <span
                                            style={{
                                              backgroundColor: "#fff",
                                              border: "1px solid #c8c6c6",
                                            }}
                                            className="p-1 rounded ml-2"
                                          >
                                            <IconButton
                                              disabled={
                                                enableToEditOnlyRSSB ||
                                                employee.statusId === 30
                                              }
                                              onClick={() => {
                                                setShowPaymentStatusDialog(
                                                  true
                                                );
                                              }}
                                              size="small"
                                              edge="end"
                                              color="primary"
                                            >
                                              <span className="material-icons">
                                                edit
                                              </span>
                                            </IconButton>
                                          </span>
                                        </span>
                                      }
                                    >
                                      <ListItemText primary={""} />
                                    </ListItem>
                                  </List>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                      {/* <Divider /> */}
                      <React.Fragment>
                        <div
                          className=" p-3 mt-3"
                          style={{
                            border: "1px solid #d1cdcd",
                            borderRadius: "10px",
                            // backgroundColor: "#fafafa",
                          }}
                        >
                          <div className="row">
                            <div className="col-12 text-left">
                              <h6 className="mb-2">Bank accounts</h6>
                            </div>
                            <div className="col-12 text-left">
                              <div className="row">
                                <div className="col-6">
                                  <List
                                    sx={{
                                      width: "100%",
                                      borderRadius: "8px",
                                      border: `1px solid #c8c6c6`,
                                    }}
                                    dense
                                    className={`${
                                      !!bank1() ? "bg-light" : ""
                                    }  mt-2`}
                                  >
                                    <ListItem
                                      style={{ cursor: "default" }}
                                      secondaryAction={
                                        <span className="d-flex align-items-center">
                                          <span className="badge badge-primary">
                                            Primary account
                                          </span>

                                          <span
                                            style={{
                                              backgroundColor: "#fff",
                                              border: "1px solid #c8c6c6",
                                            }}
                                            className="p-1 rounded ml-2"
                                          >
                                            <IconButton
                                              disabled={
                                                enableToEditOnlyRSSB ||
                                                employee.statusId === 30
                                              }
                                              size="small"
                                              edge="end"
                                              color="primary"
                                              onClick={() => {
                                                if (!!bank1())
                                                  setBankAccount(bank1());
                                                else
                                                  setBankAccount({
                                                    ...bankAccount,
                                                    isSecondAccount: false,
                                                  });

                                                setShowAddBankAccount(true);
                                              }}
                                            >
                                              {!!bank1() && (
                                                <span className="material-icons">
                                                  edit
                                                </span>
                                              )}
                                              {!bank1() && (
                                                <span className="material-icons">
                                                  add
                                                </span>
                                              )}
                                            </IconButton>
                                          </span>
                                        </span>
                                      }
                                    >
                                      <ListItemAvatar>
                                        <Avatar
                                          style={{
                                            border: "1px solid #c8c6c6",
                                            backgroundColor: "#fff",
                                          }}
                                          className=" text-dark "
                                        >
                                          1
                                        </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        component="div"
                                        primary={
                                          <>
                                            {bank1() && (
                                              <strong className="text-primary">
                                                {bank1()?.accountNumber}{" "}
                                              </strong>
                                            )}

                                            {!bank1() && (
                                              <span className="lead text-danger">
                                                --Not set--
                                              </span>
                                            )}
                                          </>
                                        }
                                        secondary={
                                          <>
                                            {bank1() && (
                                              <span>
                                                {!!bank1()?.accountName && (
                                                  <strong>
                                                    {bank1()?.accountName}
                                                    <br />
                                                  </strong>
                                                )}
                                                {bank1()?.bank?.name}{" "}
                                                <span className="text-info">
                                                  ({bank1()?.currencyId})
                                                </span>
                                              </span>
                                            )}
                                          </>
                                        }
                                      />
                                    </ListItem>
                                  </List>
                                </div>
                                {!!bank1() && (
                                  <div className="col-6">
                                    <List
                                      sx={{
                                        width: "100%",
                                        borderRadius: "8px",
                                        border: `${
                                          !!bank2()
                                            ? "1px solid #c8c6c6"
                                            : "none"
                                        } `,
                                      }}
                                      dense
                                      className={`${
                                        !!bank2() ? "bg-light" : ""
                                      }  mt-2`}
                                    >
                                      <ListItem
                                        style={{ cursor: "default" }}
                                        secondaryAction={
                                          <span className="d-flex align-items-center">
                                            <span>
                                              {!!bank2() && (
                                                <span className="badge badge-secondary">
                                                  Secondary account
                                                </span>
                                              )}
                                              {!!bank2()?.accountNumber && (
                                                <span className="d-block text-center">
                                                  {+bank2()
                                                    ?.calculationMethodId !==
                                                    2 && (
                                                    <span
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                      className={`badge badge-light text-info`}
                                                    >
                                                      {bank2()?.currencyId}
                                                    </span>
                                                  )}

                                                  <CurrencyFormat
                                                    value={bank2()?.amount || 0}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                  />
                                                  {+bank2()
                                                    ?.calculationMethodId ===
                                                    2 && <>%</>}
                                                </span>
                                              )}
                                            </span>

                                            <span
                                              style={{
                                                backgroundColor: "#fff",
                                                border: "1px solid #c8c6c6",
                                              }}
                                              className="p-1 rounded ml-2"
                                            >
                                              <IconButton
                                                disabled={
                                                  enableToEditOnlyRSSB ||
                                                  employee.statusId === 30
                                                }
                                                size="small"
                                                edge="end"
                                                color="primary"
                                                onClick={() => {
                                                  // console.log(bank2());
                                                  if (!!bank2())
                                                    setBankAccount(bank2());
                                                  else
                                                    setBankAccount({
                                                      ...bankAccount,
                                                      isSecondAccount: true,
                                                    });

                                                  setShowAddBankAccount(true);
                                                }}
                                              >
                                                {bank2() && (
                                                  <span className="material-icons">
                                                    edit
                                                  </span>
                                                )}
                                                {!bank2() && (
                                                  <span className="material-icons">
                                                    add
                                                  </span>
                                                )}
                                              </IconButton>
                                              {bank2() && (
                                                <IconButton
                                                  disabled={
                                                    enableToEditOnlyRSSB ||
                                                    employee.statusId === 30
                                                  }
                                                  size="small"
                                                  edge="end"
                                                  color="error"
                                                  onClick={() =>
                                                    setConfirmRemoveBank2(true)
                                                  }
                                                >
                                                  <span className="material-icons">
                                                    delete_outline
                                                  </span>
                                                </IconButton>
                                              )}
                                            </span>
                                          </span>
                                        }
                                      >
                                        {!!bank2() && (
                                          <ListItemAvatar>
                                            <Avatar
                                              style={{
                                                border: "1px solid #c8c6c6",
                                                backgroundColor: "#fff",
                                              }}
                                              className=" text-dark "
                                            >
                                              2
                                            </Avatar>
                                          </ListItemAvatar>
                                        )}
                                        <ListItemText
                                          primary={
                                            <>
                                              {bank2() && (
                                                <strong className="text-primary">
                                                  {bank2()?.accountNumber}
                                                </strong>
                                              )}

                                              {!bank2() && (
                                                <span className="lead d-block text-right mr-4">
                                                  --No second account--
                                                </span>
                                              )}
                                            </>
                                          }
                                          secondary={
                                            <>
                                              {bank2() && (
                                                <span>
                                                  {!!bank2()?.accountName && (
                                                    <strong>
                                                      {bank2()?.accountName}{" "}
                                                      <br />
                                                    </strong>
                                                  )}
                                                  {bank2()?.bank?.name}{" "}
                                                  <span className="text-info">
                                                    ({bank2()?.currencyId})
                                                  </span>
                                                </span>
                                              )}
                                            </>
                                          }
                                        />
                                      </ListItem>
                                    </List>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>

                      <FormGroup
                        row
                        className=" mt-3 d-flex justify-content-center border border-info bg-light"
                      >
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <Checkbox
                              disabled
                              // disabled={loading}
                              checked={employee?.payTPR || false}
                            />
                          }
                          label="Pay TPR"
                        />
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <Checkbox
                              disabled
                              // disabled={loading}
                              checked={employee?.payCSR || false}
                            />
                          }
                          label="Pay CSR"
                        />
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <Checkbox
                              disabled
                              // disabled={loading}
                              checked={employee?.payMedical || false}
                            />
                          }
                          label="Pay Medical"
                        />
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <Checkbox
                              disabled
                              // disabled={loading}
                              checked={employee?.payMaternity || false}
                            />
                          }
                          label="Pay Maternity"
                        />
                        <FormControlLabel
                          className="mb-0"
                          control={
                            <Checkbox
                              disabled
                              // disabled={loading}
                              checked={employee?.payCBHI || false}
                            />
                          }
                          label="Pay CBHI"
                        />
                      </FormGroup>
                    </div>

                    {showRankDialog && (
                      <RankDialog
                        showDialog={showRankDialog}
                        setShowDialog={setShowRankDialog}
                        saveEmployeeVerticalRank={saveEmployeeVerticalRank}
                        loading={loading}
                        verticalRanks={verticalRanks}
                        employee={employee}
                        onWait={onWait}
                      />
                    )}

                    {showPaymentStatusDialog && (
                      <PaymentStatusDialog
                        showPaymentStatusDialog={showPaymentStatusDialog}
                        setShowPaymentStatusDialog={setShowPaymentStatusDialog}
                        savePaymentStatus={savePaymentStatus}
                        loading={loading}
                        paymentStatuses={paymentStatuses}
                        employee={employee}
                        onWait={onWait}
                      />
                    )}

                    {showAddMedical && (
                      <AddOrUpdateMedical
                        showAddMedical={showAddMedical}
                        setShowAddMedical={setShowAddMedical}
                        addOrUpdateMedical={addOrUpdateMedical}
                        loading={loading}
                        medical={medical}
                        medicalInsuranceTypes={medicalInsuranceTypes}
                        setMedical={setMedical}
                        employee={employee}
                        onWait={onWait}
                      />
                    )}

                    {showAddBankAccount && (
                      <AddOrUpdateBankAccount
                        showAddBankAccount={showAddBankAccount}
                        setShowAddBankAccount={setShowAddBankAccount}
                        bankAccount={bankAccount}
                        setBankAccount={setBankAccount}
                        banks={banks}
                        currencies={currencies}
                        employee={employee}
                        loading={loading}
                        onWait={onWait}
                        addOrUpdateEmployeeBankAccount={
                          addOrUpdateEmployeeBankAccount
                        }
                        calculationMethods={calculationMethods}
                      />
                    )}

                    {confirmRemoveBank2 && (
                      <ConfirmationDialog
                        confirmationDialog={confirmRemoveBank2}
                        message={`Are you sure you want to remove second account?`}
                        setConfirmationDialog={setConfirmRemoveBank2}
                        onYes={() => {
                          deleteEmployeeBankAccount(
                            bank2()?.id,
                            setConfirmRemoveBank2
                          );
                        }}
                      />
                    )}
                  </React.Fragment>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <React.Fragment>
                    <div
                      className="elevated rounded p-3"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #17a2b8",
                        minHeight: "62vh",
                      }}
                    >
                      <EmployeeAllowances
                        disabled={employee.statusId === 30}
                        selectedEmployee={employee}
                      />
                    </div>
                  </React.Fragment>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <React.Fragment>
                    <div
                      className="elevated rounded p-3"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #17a2b8",
                        minHeight: "62vh",
                      }}
                    >
                      <EmployeeDeductions
                        disabled={employee.statusId === 30}
                        selectedEmployee={employee}
                      />
                    </div>
                  </React.Fragment>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <React.Fragment>
                    <div
                      className="elevated rounded p-3"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #17a2b8",
                        minHeight: "62vh",
                      }}
                    >
                      <EmployeeArrears
                        // disabled={employee.statusId === 30}
                        selectedEmployee={employee}
                      />
                    </div>
                  </React.Fragment>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <React.Fragment>
                    <div
                      className="elevated rounded p-3"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #17a2b8",
                        minHeight: "62vh",
                      }}
                    >
                      <EmployeeContract
                        employeeId={employee.id}
                        disabled={employee.statusId === 30}
                        defaultData={{
                          basePay: employee.basePay,
                          employeePositionId: employee.employeePositionId,
                        }}
                      />
                    </div>
                  </React.Fragment>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <React.Fragment>
                    <div
                      className="elevated rounded p-3"
                      style={{
                        backgroundColor: "#fafafa",
                        border: "1px solid #17a2b8",
                        minHeight: "62vh",
                      }}
                    >
                      <CasualSalaries
                        disabled={employee.statusId === 30}
                        selectedEmployee={employee}
                      />
                    </div>
                  </React.Fragment>
                </TabPanel>
              </div>
            </div>
          </React.Fragment>
        </DialogContent>
        <DialogActions className="py-3"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  onWait,
  user,
  banks,
  currencies,
  medicalInsuranceTypes,
  verifyRssbNumber,
  employeeBankAccounts,
  paymentStatuses,
  calculationMethods,
  verticalRanks,
}) => {
  return {
    loading,
    onWait,
    user,
    banks,
    currencies,
    medicalInsuranceTypes,
    verifyRssbNumber,
    employeeBankAccounts,
    paymentStatuses,
    calculationMethods,
    verticalRanks,
  };
};
export default connect(mapStateToProps, {
  getEmployeeAllowances,
  getEmployeeWithholds,
  doVerifyRSSBNumber,
  addOrUpdateMedical,
  getActiveCurrencies,
  // getEmployeeBankAccounts,
  addOrUpdateEmployeeBankAccount,
  deleteEmployeeBankAccount,
  getEmployeeArrears,
  getCasualSalaries,
  savePaymentStatus,
  saveEmployeeVerticalRank,
})(EmployeeSalarySettingsDialog);

const AddOrUpdateMedical = (props) => {
  const {
    showAddMedical,
    setShowAddMedical,
    loading,
    addOrUpdateMedical,
    medical,
    setMedical,
    medicalInsuranceTypes,
    employee,
    onWait,
  } = props;

  const [medicalInsuranceType, setMedicalInsuranceType] = useState(null);

  useEffect(() => {
    setMedicalInsuranceType(
      medicalInsuranceTypes.find(
        ({ id }) => id === medical.medicalInsuranceTypeId
      )
    );
  }, [medical]);

  const [errors, setErrors] = useState({
    rssbNumberHasError: false,
    medicalInsuranceNoHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      medicalInsuranceTypeIdHasError: false,
      rssbNumberHasError: false,
      medicalInsuranceNoHasError: false,
      hasError: false,
    };

    if (!medical.rssbNumber) {
      error.rssbNumberHasError = true;
      error.hasError = true;
    }

    if (!medical.medicalInsuranceNo) {
      error.medicalInsuranceNoHasError = true;
      error.hasError = true;
    }

    if (
      !medical.medicalInsuranceTypeId &&
      medical.medicalInsuranceTypeId !== 0
    ) {
      error.medicalInsuranceTypeIdHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator())
      addOrUpdateMedical(
        {
          ...medical,
          medicalInsuranceTypeName: medicalInsuranceType?.name || null,
        },
        employee,
        setShowAddMedical,
        setMedical
      );
  };

  const onClose = () => {
    setShowAddMedical(false);
    setMedical({
      medicalInsuranceTypeId: null,
      rssbNumber: "",
      medicalInsuranceNo: "",
    });
  };

  return (
    <Dialog onClose={onClose} open={showAddMedical} fullWidth maxWidth="xs">
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          RSSB/Medical Contributions
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="row no-gutters mt-2">
          <div className="col-12">
            <Autocomplete
              size="small"
              id="medicalInsurance"
              defaultValue={null}
              value={medicalInsuranceType || null}
              options={medicalInsuranceTypes}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, medicalInsuranceType) => {
                setMedicalInsuranceType(medicalInsuranceType || null);

                setMedical({
                  ...medical,
                  medicalInsuranceTypeId: medicalInsuranceType?.id,
                });

                // const medicalInsuranceHasError = false;

                if (!!medicalInsuranceType)
                  setErrors({
                    ...errors,
                    medicalInsuranceTypeIdHasError: false,
                  });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, medicalInsuranceType) => (
                <Box component="li" {...props}>
                  {medicalInsuranceType.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Medical Insurance"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "Medical Insurance", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {errors.medicalInsuranceTypeIdHasError && (
              <small className="text-danger mt-1">
                Medical Insurance is required{" "}
              </small>
            )}
          </div>
          <div className="col-12">
            <TextField
              size="small"
              className="mt-3"
              name="medicalInsuranceNo"
              fullWidth
              label=" Medical Insurance Number"
              placeholder=" Medical Insurance Number"
              variant="outlined"
              value={medical.medicalInsuranceNo}
              onChange={(e) => {
                setErrors({ ...errors, medicalInsuranceNoHasError: false });
                setMedical({
                  ...medical,
                  medicalInsuranceNo: e.target.value || "",
                });
              }}
            />
            {errors.medicalInsuranceNoHasError && (
              <small className="text-danger mt-1">
                Medical Insurance Number is required{" "}
              </small>
            )}
          </div>
          <div className="col-12">
            <TextField
              size="small"
              className="mt-3"
              name="rssbNumber"
              fullWidth
              label="RSSB Number"
              placeholder="RSSB Number"
              variant="outlined"
              value={medical.rssbNumber}
              onChange={(e) => {
                setErrors({ ...errors, rssbNumberHasError: false });
                setMedical({ ...medical, rssbNumber: e.target.value || "" });
              }}
            />
            {errors.rssbNumberHasError && (
              <small className="text-danger mt-1">
                RSSB Number is required{" "}
              </small>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="py-3 d-flex justify-content-center">
        <Button
          disabled={loading || onWait}
          type="button"
          variant="contained"
          color="primary"
          className="px-5"
          onClick={onSave}
        >
          {onWait ? "Wait..." : "Save"}
        </Button>
        {/* <button
          type="button"
          className="btn btn-primary text-uppercase ml-2 px-5"
          disabled={loading || onWait}
          onClick={onSave}
        >
          {onWait ? "Wait..." : "Save"}
        </button> */}
      </DialogActions>
    </Dialog>
  );
};

const AddOrUpdateBankAccount = (props) => {
  const {
    bankAccount,
    setBankAccount,
    banks,
    currencies,
    employee,
    loading,
    onWait,
    addOrUpdateEmployeeBankAccount,
    showAddBankAccount,
    setShowAddBankAccount,
    calculationMethods,
  } = props;

  const [bank, setBank] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [calculationMethod, setCalculationMethod] = useState(null);

  useEffect(() => {
    setBank(banks.find(({ id }) => id === bankAccount.bankId));
    setCurrency(currencies.find(({ id }) => id === bankAccount.currencyId));

    setCalculationMethod(
      calculationMethods.find(
        ({ id }) => id === bankAccount.calculationMethodId
      ) || null
    );
  }, [bankAccount]);

  const onClose = () => {
    setShowAddBankAccount(false);
    setBankAccount({
      id: null,
      bankId: "",
      currencyId: "RWF",
      accountNumber: "",
      accountName: "",
      isSecondAccount: false,
      amount: 0,
    });
  };

  const [errors, setErrors] = useState({
    bankIdHasError: false,
    currencyHasError: false,
    accountNumberHasError: false,
    accountNameHasError: false,
    amountHasError: false,
    calculationMethodHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      bankIdHasError: false,
      currencyHasError: false,
      accountNumberHasError: false,
      accountNameHasError: false,
      amountHasError: false,
      calculationMethodHasError: false,
      hasError: false,
    };

    if (!bankAccount?.bankId) {
      error.bankIdHasError = true;
      error.hasError = true;
    }

    if (!bankAccount?.currencyId) {
      error.currencyHasError = true;
      error.hasError = true;
    }

    if (!bankAccount?.accountNumber) {
      error.accountNumberHasError = true;
      error.hasError = true;
    }

    if (!bankAccount?.accountName) {
      error.accountNameHasError = true;
      error.hasError = true;
    }

    if (!bankAccount?.amount && bankAccount?.isSecondAccount) {
      error.amountHasError = true;
      error.hasError = true;
    }

    if (!calculationMethod) {
      error.calculationMethodHasError = true;
      error.hasError = true;
    }

    if (
      calculationMethod?.id === 2 &&
      (+bankAccount?.amount < 1 || +bankAccount?.amount > 100)
    ) {
      error.amountHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      // showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator())
      addOrUpdateEmployeeBankAccount(
        {
          ...bankAccount,
          employeeId: employee.id,
          isEmbassy: employee.isEmbassy,
        },
        setShowAddBankAccount,
        setBankAccount
      );
  };

  return (
    <Dialog onClose={onClose} open={showAddBankAccount} fullWidth maxWidth="xs">
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          {bankAccount.id ? "Edit" : "Add"} Employee Bank Account
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div
          className={`alert alert-${
            !bankAccount.isSecondAccount ? "primary" : "secondary"
          } text-center`}
        >
          {/* This is{" "} */}
          <span>
            {!bankAccount.isSecondAccount ? "Primary" : "Second"}
          </span>{" "}
          bank account for <br />{" "}
          <span className="alert-link text-uppercase">
            {employee.lastName} {employee.firstName}
          </span>
        </div>
        <div className="row no-gutters mt-2">
          <div className="col-12">
            <Autocomplete
              size="small"
              id="bankId"
              defaultValue={null}
              value={bank || null}
              options={banks}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, bank) => {
                setBank(bank || null);

                setBankAccount({
                  ...bankAccount,
                  bankId: bank?.id,
                });

                if (!!bank)
                  setErrors({
                    ...errors,
                    bankIdHasError: false,
                  });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, bank) => (
                <Box component="li" {...props}>
                  {bank.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Bank"
                  placeholder="Select Bank"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "Select Bank",
                  }}
                />
              )}
            />
            {errors.bankIdHasError && (
              <small className="text-danger mt-1">Bank is required </small>
            )}
          </div>
          <div className="col-12">
            <TextField
              size="small"
              name="accountNumber"
              fullWidth
              label="Account Number"
              placeholder="Account Number"
              className="mt-3"
              variant="outlined"
              value={bankAccount.accountNumber}
              onChange={(e) => {
                setBankAccount({
                  ...bankAccount,
                  accountNumber: e.target.value || "",
                });

                setErrors({
                  ...errors,
                  accountNumberHasError: false,
                });
              }}
            />

            {errors.accountNumberHasError && (
              <small className="text-danger mt-1">
                Account Number is required{" "}
              </small>
            )}
          </div>

          <div className="col-12">
            <TextField
              size="small"
              name="accountName"
              fullWidth
              label="Account Name"
              placeholder="Account Name"
              className="mt-3"
              variant="outlined"
              value={bankAccount.accountName}
              onChange={(e) => {
                setBankAccount({
                  ...bankAccount,
                  accountName: e.target.value || "",
                });

                setErrors({
                  ...errors,
                  accountNameHasError: false,
                });
              }}
            />
            {errors.accountNameHasError && (
              <small className="d-block text-danger">
                Account Name is required{" "}
              </small>
            )}
            <div
              className="alert alert-warning py-0"
              style={{ fontSize: "12px" }}
            >
              Please, ensure that the account name is written exactly as it
              appears on the bank.
            </div>
          </div>

          <div className="col-12">
            <Autocomplete
              size="small"
              id="currencyId"
              defaultValue={null}
              value={currency || null}
              options={currencies}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, currency) => {
                setCurrency(currency || null);

                setBankAccount({
                  ...bankAccount,
                  currencyId: currency?.id,
                });

                if (!!currency)
                  setErrors({
                    ...errors,
                    currencyHasError: false,
                  });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, currency) => (
                <Box component="li" {...props}>
                  {currency.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Currency"
                  placeholder="Currency"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "currency",
                  }}
                />
              )}
            />
            {errors.currencyHasError && (
              <small className="text-danger mt-1">Currency is required </small>
            )}
          </div>

          {!!bankAccount.isSecondAccount && (
            <>
              <div className="col-6 mt-3 pr-1">
                <Autocomplete
                  size="small"
                  id="Method"
                  defaultValue={null}
                  value={calculationMethod}
                  options={calculationMethods}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, calculationMethod) => {
                    setCalculationMethod(calculationMethod);
                    setBankAccount({
                      ...bankAccount,
                      calculationMethodId: calculationMethod?.id || 1,
                    });
                    setErrors({
                      ...errors,
                      calculationMethodHasError: false,
                    });
                  }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, calculationMethod) => (
                    <Box component="li" {...props}>
                      {calculationMethod.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Method"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />

                {errors.calculationMethodHasError && (
                  <small className="text-danger mt-1">Required </small>
                )}
              </div>
              <div className="col-6 mt-3 pl-1">
                <NumberFormat
                  style={{ backgroundColor: "#d1ecf1" }}
                  fullWidth
                  size="small"
                  label={`${
                    calculationMethod?.id === 1 ? "Amount" : "Percentage(%)"
                  }`}
                  name="amount"
                  variant="outlined"
                  placeholder={`${
                    calculationMethod?.id === 1
                      ? `${currency?.id || "RWF"} 0`
                      : "% "
                  }`}
                  prefix={`${
                    calculationMethod?.id === 1
                      ? `${currency?.id || "RWF"} `
                      : "% "
                  }`}
                  customInput={TextField}
                  value={bankAccount.amount}
                  thousandSeparator={true}
                  onBlur={() => {
                    if (bankAccount.amount === "")
                      setBankAccount({
                        ...bankAccount,
                        amount: 0,
                      });
                  }}
                  onKeyDown={() => {
                    if (+bankAccount.amount === 0)
                      setBankAccount({
                        ...bankAccount,
                        amount: "",
                      });
                  }}
                  onChange={(e) => {
                    const amount =
                      calculationMethod?.id === 1
                        ? e.target.value.split(`${currency?.id || "RWF"} `)
                        : e.target.value.split("% ");

                    setBankAccount({
                      ...bankAccount,
                      amount: !!amount[1] ? amount[1].replace(/,/g, "") : 0,
                    });

                    setErrors({
                      ...errors,
                      amountHasError: false,
                    });
                  }}
                />

                {errors.amountHasError && (
                  <small className="text-danger mt-1">
                    {!!+bankAccount.amount
                      ? "Invalid amount"
                      : `${
                          calculationMethod?.id === 1 ? "Amount" : "Percentage"
                        } is required`}
                  </small>
                )}
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions className="py-3 d-flex justify-content-center">
        <Button
          variant="contained"
          color="primary"
          type="button"
          className=" px-5"
          disabled={loading || onWait}
          onClick={onSave}
        >
          {onWait ? "Wait..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const PaymentStatusDialog = (props) => {
  const {
    showPaymentStatusDialog,
    setShowPaymentStatusDialog,
    savePaymentStatus,
    loading,
    paymentStatuses,
    employee,
    onWait,
  } = props;

  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    if (showPaymentStatusDialog)
      setPaymentStatus(
        paymentStatuses.find(({ id }) => id === employee.statusId)
      );
    // else setPaymentStatus(null)
  }, [employee, showPaymentStatusDialog]);

  const [errors, setErrors] = useState({
    paymentStatusHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      paymentStatusHasError: false,
      hasError: false,
    };

    if (!paymentStatus) {
      error.paymentStatusHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator())
      savePaymentStatus(
        {
          employeePositionId: employee.employeePositionId,
          statusId: paymentStatus?.id,
        },
        setShowPaymentStatusDialog
      );
  };

  const onClose = () => {
    setShowPaymentStatusDialog(false);
  };

  return (
    <Dialog
      onClose={onClose}
      open={showPaymentStatusDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          Payment status
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="row no-gutters mt-2">
          <div className="col-12">
            <Autocomplete
              size="small"
              id="medicalInsurance"
              defaultValue={null}
              value={paymentStatus || null}
              options={paymentStatuses}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, paymentStatus) => {
                setPaymentStatus(paymentStatus || null);

                // const medicalInsuranceHasError = false;

                if (!!paymentStatus)
                  setErrors({
                    ...errors,
                    paymentStatusHasError: false,
                  });
              }}
              getOptionLabel={(option) => option.name}
              renderOption={(props, paymentStatus) => (
                <Box component="li" {...props}>
                  {paymentStatus.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment Status"
                  placeholder="Payment Status"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "Payment Status", // disable autocomplete and autofill
                  }}
                />
              )}
            />
            {errors.paymentStatusHasError && (
              <small className="text-danger mt-1">
                Payment Status is required{" "}
              </small>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="py-3 d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-primary text-uppercase ml-2"
          disabled={loading || onWait}
          onClick={onSave}
        >
          {onWait ? "Wait..." : "Save"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

const RankDialog = (props) => {
  const {
    showDialog,
    setShowDialog,
    saveEmployeeVerticalRank,
    loading,
    verticalRanks,
    employee,
    onWait,
  } = props;

  const [rank, setRank] = useState(null);

  useEffect(() => {
    if (showDialog)
      setRank(verticalRanks.find(({ id }) => id === employee.verticalRankId));
  }, [employee, showDialog]);

  const onSave = () => {
    saveEmployeeVerticalRank(
      {
        employeePositionId: employee.employeePositionId,
        verticalRankId: rank?.id,
      },
      setShowDialog
    );
  };

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog onClose={onClose} open={showDialog} fullWidth maxWidth="xs">
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          Diplomatic rank
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="row no-gutters mt-2">
          <div className="col-12">
            <Autocomplete
              size="small"
              defaultValue={null}
              value={rank || null}
              options={verticalRanks}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, rank) => {
                setRank(rank || null);
              }}
              getOptionLabel={(option) =>
                option.rankName +
                ` - (${option.levelName}) - I.V: ${option.indexValue}`
              }
              renderOption={(props, rank) => (
                <Box
                  component="li"
                  {...props}
                  style={{
                    borderBottom: "1px solid #d1cdcd",
                  }}
                >
                  <div>
                    <span className="d-block text-primary">
                      {rank.rankName} {` - (${rank.levelName})`}
                    </span>
                    <small className="badge badge-pill font-weight-light px-0">
                      IndexValue: {rank.indexValue}
                    </small>
                  </div>
                  {/* <div>{rank.indexValue}</div> */}
                  {/* {` - ${rank.levelName} - I.V: ${rank.indexValue}`} */}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Diplomatic rank"
                  placeholder="Diplomatic rank"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className="py-3 d-flex justify-content-center">
        <button
          type="button"
          className="btn btn-primary text-uppercase ml-2 px-5"
          disabled={loading || onWait || !rank}
          onClick={onSave}
        >
          {onWait ? "Wait..." : "Save"}
        </button>
      </DialogActions>
    </Dialog>
  );
};
