import React, { useState, useEffect, Fragment } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import { connect } from "react-redux";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  changeCenterStatus,
  addNewCenter,
  getCenters,
  updateCenter,
  getCenterIps,
  addNewIpAddress,
  updateIpAddress,
  changeIpAddressStatus,
} from "../../../store/support/actions";
import DnsRoundedIcon from "@mui/icons-material/DnsRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { showError } from "../../toastify";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CopyToClipboard from "../../common/components/CopyToClipboard";

const ExamCenter = (props) => {
  const {
    createCenter,
    setCreateCenter,
    // redux -----------------
    loading,
    examCenters,
    centerIpAddress,
    getCenters,
    updateCenter,
    addNewCenter,
    changeCenterStatus,
    getCenterIps,
    addNewIpAddress,
    updateIpAddress,
    changeIpAddressStatus,
  } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingIps, setLoadingIps] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    id: null,
    name: "",
  });
  const [formIpData, setFormIpData] = useState({
    id: null,
    ip: "",
    stopOthers: false,
  });
  const [showIpAddress, setShowIpAddress] = useState(null);
  const [changeStatus, setChangeStatus] = useState(null);
  const [changeIpStatus, setChangeIpStatus] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showIpForm, setShowIpForm] = useState(false);
  const [formError, setFormError] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [filteredCenter, setFilteredCenter] = useState([...examCenters]);
  const [currentIpAddress, setCurrentIpAddress] = useState([]);
  //PAGINATION
  const [paginatedCenterDate, setPaginatedCenterDate] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [forcePage, setForcePage] = useState(undefined);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!examCenters.length) {
      getCenters(setLoadingData);
    } else {
      setLoadingData(false);
    }

    return () => {
      setLoadingData(true);
      setFormError("");
      setSearchInput("");
    };
  }, []);

  useEffect(() => {
    let data = examCenters.filter((item) => item.isActive === !showInactive);
    if (!!searchInput) {
      data = data.filter(
        (item) =>
          item.id === !+searchInput ||
          item.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }
    setFilteredCenter(data);
  }, [examCenters, showInactive, searchInput]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedCenterDate(filteredCenter.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredCenter.length / itemsPerPage));
  }, [filteredCenter, itemOffset, itemsPerPage]);

  useEffect(() => {
    if (!!showIpAddress) {
      const ipAddress = centerIpAddress.filter(
        (item) => item.centerId === showIpAddress.id
      );
      if (!ipAddress[0]) {
        getCenterIps(showIpAddress.id, (status, data) => {
          setLoadingIps(status);
          if (!status && !!data) setCurrentIpAddress(data);
        });
      } else {
        setCurrentIpAddress(ipAddress);
      }
    } else {
      setLoadingIps(false);
      setCurrentIpAddress([]);
    }
  }, [showIpAddress]);

  const onCloseForm = () => {
    setCreateCenter(false);
    setShowForm(false);
    setFormData({ id: null, name: "" });
    setFormError("");
    setChangeStatus(null);
    setFormIpData({ id: null, ip: "", stopOthers: false });
    setShowIpForm(false);
    setChangeIpStatus(null);
  };

  const isIpAddressValid = (value) => {
    const ipPattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipPattern.test(value);
  };

  const onSaveForm = (action = "center") => {
    if (action === "center") {
      if (!formData.name.length) {
        setFormError("Center name is required!");
        return;
      }

      if (!!formData.name.length && !!formData.id) {
        updateCenter(
          formData.id,
          {
            name: formData.name,
          },
          (status, res) => {
            setIsSubmitting(status);
            if (!status && res) onCloseForm();
          }
        );
      } else {
        addNewCenter(
          {
            name: formData.name,
          },
          (status, res) => {
            setIsSubmitting(status);
            if (!status && res) onCloseForm();
          }
        );
      }
    }

    if (action === "ip") {
      if (!showIpAddress?.id) {
        showError("Please select center first!");
        return;
      }

      if (!formIpData.ip.length) {
        setFormError("IP Address is required!");
        return;
      }

      if (!isIpAddressValid(formIpData.ip)) {
        setFormError("Invalid IP Address format!");
        return;
      }

      if (!!formIpData.ip.length && !!formIpData.id) {
        updateIpAddress(
          formIpData.id,
          {
            ip: formIpData.ip,
            stopOthers: formIpData.stopOthers,
          },
          (status, res) => {
            setIsSubmitting(status);
            if (!status && !!res) {
              let ips = [...currentIpAddress];
              const index = ips.findIndex((item) => item.id === formIpData.id);
              if (index >= 0) ips[index].ip = formIpData.ip;
              if (!!formIpData.stopOthers) {
                ips = ips.map((item) =>
                  item.id === formIpData.id
                    ? item
                    : { ...item, isActive: false }
                );
              }
              setCurrentIpAddress([...ips]);
              onCloseForm();
            }
          }
        );
      } else {
        addNewIpAddress(
          {
            centerId: showIpAddress?.id,
            ip: formIpData.ip,
            stopOthers: formIpData.stopOthers,
          },
          (status, data) => {
            setIsSubmitting(status);
            if (!status && data) {
              setCurrentIpAddress([...currentIpAddress, data]);
              onCloseForm();
            }
          }
        );
      }
    }
  };

  const handleChangeStatus = (action = "center") => {
    if (action === "center") {
      if (!!changeStatus.id) {
        changeCenterStatus(changeStatus.id, (status, res) => {
          setIsSubmitting(status);
          if (!status && res) onCloseForm();
        });
      }
    }
    if (action === "ip") {
      if (!!changeIpStatus.id) {
        changeIpAddressStatus(changeIpStatus.id, (status, res) => {
          setIsSubmitting(status);
          if (!status && res) onCloseForm();
        });
      }
    }
  };

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredCenter.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center">
        <div className="col-11">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={loadingData || examCenters.length === 0}
            placeholder="Search cost center..."
          />
        </div>
        <div className="col-1 mt-2 mt-md-0 px-0">
          <Button
            onClick={() => {
              setShowInactive(!showInactive);
              setItemOffset(0);
              setForcePage(0);
              setCurrentIpAddress(null);
              setFormData({
                id: null,
                name: "",
              });
              setFormIpData({
                id: null,
                ip: "",
                stopOthers: false,
              });
              setShowIpAddress(null);
              setChangeStatus(null);
              setChangeIpStatus(null);
            }}
            variant="outlined"
            size="small"
            disableElevation
            // className="mr-2"
            style={{
              borderRadius: "8px",
              height: "32px",
              border: showInactive
                ? `1px solid rgb(7, 142, 206)`
                : `1px solid rgb(206, 7, 7)`,
              backgroundColor: "#fff",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
              color: showInactive ? "rgb(7, 142, 206)" : "rgb(206, 7, 7)",
            }}
            disabled={loading}
          >
            {showInactive ? "Active" : "Inactive"}
          </Button>
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Exam Center</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {loadingData ? (
          <TableSkeleton cols={3} />
        ) : paginatedCenterDate.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={3}>
                <div className="text-center font-weight-bold my-2">
                  No cost center found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {paginatedCenterDate.map((item, index) => (
              <Fragment key={"exam-center-" + item.id}>
                <tr>
                  <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                    {+itemOffset + +index + 1}
                  </th>
                  <td>{item.name}</td>
                  <td align="center">
                    <div className="border bg-white rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated w-content">
                      <IconButton
                        aria-label="edit center"
                        size="small"
                        onClick={() => {
                          setFormData(item);
                          setShowForm(true);
                        }}
                        disabled={!!showIpAddress && !showIpAddress?.isActive}
                        className="mr-1"
                      >
                        <ModeRoundedIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        aria-label="change center status"
                        size="small"
                        onClick={() => setChangeStatus(item)}
                      >
                        {item.isActive ? (
                          <HighlightOffRoundedIcon
                            fontSize="small"
                            color="error"
                          />
                        ) : (
                          <CheckCircleOutlineRoundedIcon
                            fontSize="small"
                            color="warning"
                          />
                        )}
                      </IconButton>
                      <IconButton
                        aria-label="view center ips"
                        size="small"
                        onClick={() => {
                          if (showIpAddress?.id === item.id) {
                            setShowIpAddress(null);
                          } else {
                            setShowIpAddress(item);
                          }
                        }}
                        disabled={!!showIpAddress && !showIpAddress?.isActive}
                      >
                        <DnsRoundedIcon fontSize="small" color="info" />
                      </IconButton>
                    </div>
                  </td>
                </tr>
                {showIpAddress?.id === item.id && (
                  <tr>
                    <td colSpan={3}>
                      <div className="mt-1 text-center rounded elevated-2 pb-1 mb-1">
                        {/* Heading and Add Button in the Same Row */}
                        <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                          <h3 className="font-bold text-center mx-auto">
                            IP Address For {showIpAddress?.name} Center
                          </h3>

                          <IconButton
                            className="mr-2"
                            aria-label="add new ip address"
                            disabled={
                              loadingData ||
                              loading ||
                              loadingIps ||
                              !showIpAddress?.isActive
                            }
                            color="primary"
                            onClick={() => setShowIpForm(true)}
                          >
                            <AddCircleRoundedIcon />
                          </IconButton>
                        </div>
                        <table
                          className={`table table-hover table-sm ${
                            loadingIps ? "" : "table-stripeds"
                          } rounded bg-success4`}
                        >
                          <thead>
                            <tr>
                              <th className="border border-gray-300 px-4 py-2">
                                #
                              </th>
                              <th className="border border-gray-300 px-4 py-2">
                                IP Address
                              </th>
                              <th className="border border-gray-300 px-4 py-2">
                                Status
                              </th>
                              <th
                                className="border border-gray-300 px-4 py-2"
                                style={{ maxWidth: "65px" }}
                              ></th>
                            </tr>
                          </thead>
                          {loadingIps ? (
                            <TableSkeleton cols={4} />
                          ) : currentIpAddress.length === 0 ? (
                            <tbody>
                              <tr>
                                <td colSpan={4}>
                                  <div className="text-center font-weight-bold my-2">
                                    No IP Address Found!
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody>
                              {currentIpAddress.map((ipItem, index) => (
                                <tr
                                  key={item.id}
                                  className="text-center rounded"
                                >
                                  <td className="border border-gray-300 px-4 py-2 rounded">
                                    {index + 1}
                                  </td>
                                  <td className="border border-gray-300 px-4 py-2">
                                    <CopyToClipboard value={ipItem.ip} />
                                  </td>
                                  <td className="border border-gray-300 px-4 py-2">
                                    {!!ipItem.isActive ? (
                                      <Chip
                                        color="primary"
                                        label="Active"
                                        size="small"
                                      />
                                    ) : (
                                      <Chip
                                        color="warning"
                                        label="Inactive"
                                        size="small"
                                      />
                                    )}
                                  </td>
                                  <td
                                    align="center"
                                    style={{ maxWidth: "65px" }}
                                  >
                                    <div
                                      className="rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated-0"
                                      style={{ maxWidth: "65px" }}
                                    >
                                      <IconButton
                                        aria-label="edit center"
                                        size="small"
                                        disabled={
                                          loading ||
                                          loadingData ||
                                          loadingIps ||
                                          isSubmitting ||
                                          !showIpAddress?.isActive
                                        }
                                        onClick={() => {
                                          setFormIpData({
                                            id: ipItem.id,
                                            ip: ipItem.ip,
                                            stopOthers: false,
                                          });
                                          setShowIpForm(true);
                                        }}
                                        className="mr-1"
                                      >
                                        <ModeRoundedIcon fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        aria-label="change center status"
                                        size="small"
                                        disabled={
                                          loading ||
                                          loadingData ||
                                          loadingIps ||
                                          isSubmitting ||
                                          !showIpAddress?.isActive
                                        }
                                        onClick={() =>
                                          setChangeIpStatus(ipItem)
                                        }
                                      >
                                        {ipItem.isActive ? (
                                          <HighlightOffRoundedIcon
                                            fontSize="small"
                                            color="error"
                                          />
                                        ) : (
                                          <CheckCircleOutlineRoundedIcon
                                            fontSize="small"
                                            color="warning"
                                          />
                                        )}
                                      </IconButton>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        )}
      </table>

      {pageCount > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={5}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
          forcePage={forcePage}
        />
      )}

      <Dialog
        onClose={onCloseForm}
        aria-labelledby="customized-dialog-title"
        open={showForm || createCenter}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {!!formData.id ? "Edit Center of Exam" : "Add New Center of Exam"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseForm}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            id="outlined-basic"
            label="Center name"
            variant="outlined"
            size="small"
            className="w-100 mt-2"
            disabled={loading || loadingData || isSubmitting}
            value={formData.name}
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
              setFormError("");
            }}
          />
          {!!formError && (
            <span className="text-danger" style={{ fontSize: "13px" }}>
              {formError}
            </span>
          )}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-3">
          <button
            onClick={() => onSaveForm("center")}
            type="button"
            className="btn btn-primary text-uppercase px-4"
            disabled={loading || loadingData || isSubmitting}
          >
            {isSubmitting
              ? !!formData.id
                ? "Updating..."
                : "Saving..."
              : "Save"}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={onCloseForm}
        aria-labelledby="customized-dialog-title"
        open={showIpForm && !!showIpAddress?.id}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {!!formIpData.id ? "Edit IP Address" : "Add New IP Address"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseForm}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            id="outlined-basic"
            label="IP Address"
            variant="outlined"
            size="small"
            className="w-100 text-center mt-2"
            disabled={loading || loadingData || isSubmitting}
            value={formIpData.ip}
            onChange={(e) => {
              setFormIpData({ ...formIpData, ip: e.target.value });
              setFormError("");
            }}
          />
          {!!formError && (
            <span className="text-danger" style={{ fontSize: "13px" }}>
              {formError}
            </span>
          )}

          {!formIpData.id && (
            <FormControlLabel
              className="mt-1"
              control={<Checkbox />}
              label="Stop Other IP Address"
              size="small"
              checked={formIpData.stopOthers}
              onChange={() =>
                setFormIpData({
                  ...formIpData,
                  stopOthers: !formIpData.stopOthers,
                })
              }
            />
          )}
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-3">
          <button
            onClick={() => onSaveForm("ip")}
            type="button"
            className="btn btn-primary text-uppercase px-4"
            disabled={loading || loadingData || isSubmitting || !!formError}
          >
            {isSubmitting
              ? !!formIpData.id
                ? "Updating..."
                : "Saving..."
              : "Save"}
          </button>
        </DialogActions>
      </Dialog>

      {(!!changeStatus || !!changeIpStatus) && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to ${
            !!changeStatus?.isActive || !!changeIpStatus?.isActive
              ? "de-activate"
              : "activate"
          } ${changeStatus?.name || changeIpStatus?.ip} ${
            changeIpStatus?.ip ? "IP Address" : "center"
          }?`}
          setConfirmationDialog={() => {
            setChangeStatus(null);
            setChangeIpStatus(null);
          }}
          onYes={() => handleChangeStatus(!!changeStatus ? "center" : "ip")}
        />
      )}
    </div>
  );
};

const mapStateToProps = ({ loading, examCenters, centerIpAddress }) => {
  return {
    loading,
    examCenters,
    centerIpAddress,
  };
};

const mapDispatchToProps = {
  getCenters,
  updateCenter,
  addNewCenter,
  changeCenterStatus,
  getCenterIps,
  addNewIpAddress,
  updateIpAddress,
  changeIpAddressStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamCenter);
