import React from "react";
import { Link, useLocation } from "react-router-dom";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { connect } from "react-redux";

const Breadcrumb = (props) => {
  const location = useLocation();
  const { hasPermissions } = useRequiredAuth();
  const { user } = props;

  return (
    <>
      <div className="d-flex bread-crumb align-items-center flex-wrap">
        {hasPermissions(["IS_SD_MANAGER"]) && (
          <>
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/skills-dashboard/dashboard"
              className={`px-1 rounded ${
                location.pathname.includes("/skills-dashboard/dashboard")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">Dashboard</span>
            </Link>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/skills-dashboard/profile"
              className={`px-1 rounded ${
                location.pathname.includes("/skills-dashboard/profile")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">User Profiles</span>
            </Link>
            <HorizontalRuleIcon className="rotated" />
            <Link
              style={{ textDecoration: "none", color: "#fff" }}
              to="/skills-dashboard/qualifications"
              className={`px-1 rounded ${
                location.pathname.includes("/skills-dashboard/qualifications")
                  ? " active  "
                  : " "
              }`}
            >
              <span className="text-truncate menu-item">User Qualifications</span>
            </Link>

            
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, {})(Breadcrumb);
