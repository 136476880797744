import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SearchBox from "../../common/components/SearchBox";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  getSearchedPanelist,
  addPanelist,
  getPanels,
} from "../../../store/recruitment/actions";
import PreLoader from "../../common/components/PreLoader";
import AddIcon from "@mui/icons-material/Add";
import RECRUITMENT_ACTION_TYPE from "../../../store/recruitment/action-types";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import ErrorMessage from "../../common/components/ErrorMessage";
import { showError } from "../../toastify";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};
const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};

const AddPanelist = (props) => {
  const {
    setShowPanelist,
    selectedExam,
    searchedPanelist,
    getSearchedPanelist,
    addPanelist,
    getPanels,
    panels,
  } = props;
  const [searchTeam, setSearchTeam] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isAddingPanelist, setIsAddingPanelist] = useState(false);
  const dispatch = useDispatch();
  const [panel, setPanel] = useState(panels.length === 1 ? panels[0] : null);
  const [errors, setErrors] = useState({
    panelError: false,
  });
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  useEffect(() => {
    getPanels(selectedExam.id);
  }, [selectedExam]);
  const onSubmit = () => {
    if (searchTeam)
      getSearchedPanelist(
        {
          searchTerm: searchTeam,
          examId: selectedExam.id,
          advertisementId: selectedExam.advertisementId,
        },
        setIsSearching
      );
  };
  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        <SearchBox
          disabled={isSearching || isAddingPanelist}
          onSearch={onSearch}
          placeholder="Enter user email or phone number…"
        />
        <Button
          className="ml-2 px-1"
          disabled={isSearching || isAddingPanelist}
          onClick={() => onSubmit()}
          style={{
            ...ActiveButton,
            float: "right",
          }}
          size="md"
        >
          {isSearching ? (
            <>
              {" "}
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            "Search"
          )}
        </Button>

        <Button
          className="ml-2"
          disabled={isSearching || isAddingPanelist}
          onClick={() => {
            setSearchTeam("");
            setShowPanelist(false);
            dispatch({
              type: RECRUITMENT_ACTION_TYPE.SET_SEARCHED_PANELISTS,
              data: null,
            });
          }}
          style={{
            ...InactiveButton,
            // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
            float: "right",
          }}
          size="md"
        >
          <TrendingFlatIcon />
        </Button>
      </div>

      <PreLoader className="mt-3" isLoading={isSearching} />

      <NoRecordMessage
        className="mt-3"
        isEmpty={!searchedPanelist && !isSearching}
        title={"Please search by email or phone number for existing user!"}
      />
      {!isSearching && searchedPanelist && (
        <div className="mt-3">
          <div className="card-body d-flex justify-content-center flex-wrap pe-0">
            <table className="rounded">
              <tbody>
                <tr>
                  <th scope="row">Names</th>
                  <td>
                    <span style={{ fontSize: "14px" }}>
                      {searchedPanelist.lastName} {searchedPanelist.firstName}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Email</th>
                  <td>
                    {" "}
                    <span style={{ fontSize: "14px" }}>
                      {searchedPanelist.email}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">PhoneNumber</th>
                  <td>
                    <span style={{ fontSize: "14px" }}>
                      {searchedPanelist.phoneNumber}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th scope="row">IDNumber</th>
                  <td>
                    <span style={{ fontSize: "14px" }}>
                      {searchedPanelist.idNumber}
                    </span>
                  </td>
                </tr>

                <tr>
                  <th scope="row">Assign Panel</th>
                  <td>
                    <Autocomplete
                      size="small"
                      id="panel"
                      defaultValue={null}
                      value={panel}
                      options={panels}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, panel) => {
                        setPanel(panel || null);
                        setErrors({
                          ...errors,
                          panelError: false,
                        });
                      }}
                      getOptionLabel={(option) => option?.panel}
                      renderOption={(props, panel) => (
                        <Box component="li" {...props}>
                          {panel?.panel}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          required={true}
                          {...params}
                          label="Select panel"
                          placeholder="Select panel"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "panel", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      hasError={errors.panelError}
                      message="panel is required"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} className="text-center">
                    {" "}
                    <button
                      disabled={isAddingPanelist}
                      onClick={() => {
                        let panelError = false;
                        if (!panel) {
                          panelError = true;
                        }
                        setErrors({
                          ...errors,
                          panelError,
                        });
                        if (!panel) return showError("Panel is required");
                        addPanelist(
                          {
                            userId: searchedPanelist.id,
                            examId: selectedExam.id,
                            panelId: panel.id,
                          },
                          setIsAddingPanelist,
                          setShowPanelist,
                          setPanel
                        );
                      }}
                      className="btn btn-primary"
                    >
                      {isAddingPanelist ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="sr-only">Loading...</span>
                        </>
                      ) : (
                        <>Save</>
                      )}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, searchedPanelist, panels }) => {
  return {
    loading,
    searchedPanelist,
    panels,
  };
};
export default connect(mapStateToProps, {
  getSearchedPanelist,
  addPanelist,
  getPanels,
})(AddPanelist);

// export default AddPanelist;
