import {
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TableSkeleton from "../../common/components/TableSkeleton";
import RBMPageHeader from "./RBMPageHeader";
import { getActivityByIndicatorId } from "../../../store/rbm/actions";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { connect } from "react-redux";

const InstitutionIndicatorActivities = (props) => {
  const {
    indicator,
    loading,
    selectedFiscalYear,
    onClose,
    getActivityByIndicatorId,
  } = props;
  const [loadingData, setLoadingData] = useState(true);
  const [activities, setActivities] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const [activitySummary, setActivitySummary] = useState({
    items: 0,
    unassigned: 0,
    due: 0,
    reported: 0,
    scored: 0,
    achievedTarget: 0,
    score: 0,
  });

  const [filteredActivities, setFilteredActivities] = useState([...activities]);
  useEffect(() => {
    if (!!selectedFiscalYear.id) {
      getActivityByIndicatorId(
        indicator.id,
        setActivities,
        setActivitySummary,
        setLoadingData
      );
    }
  }, [selectedFiscalYear?.id]);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    const filtered =
      searchTerm.length === 0
        ? [...activities]
        : [...activities].filter(({ name }) =>
            (name || "").toLowerCase().includes(searchTerm.toLowerCase())
          );

    setFilteredActivities(filtered);
    paginate(filtered);
  }, [searchTerm, activities]);

  useEffect(() => {
    paginate(filteredActivities);
  }, [itemOffset, itemsPerPage, activities, filteredActivities]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredActivities.length || 0;

    setCurrentPage(event.selected * itemsPerPage);

    setItemOffset(newOffset);
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth={`xl`}
      disableEscapeKeyDown={true}
    >
      <DialogTitle>
        <Typography className="text-primary text-uppercase">
          <span className="d-flex align-items-center">
            <span className="badge badge-pill badge-primary mx-1">
              SELECTED INDICATOR
            </span>
            <span>{indicator.name}</span>
          </span>
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
        <div className="mt-3 px-2">
          <RBMPageHeader
            labels={[
              {
                title: "Activities",
                value: activitySummary.items || 0,
                type: "number",
              },
              {
                title: "Unassigned",
                value: activitySummary.unassigned || 0,
                type: "number",
                color: "warning",
                onClick: () => {
                  if (activitySummary.unassigned > 0) {
                    setSearchTerm("unassigned");
                  }
                },
              },
              {
                title: "Due",
                value: activitySummary.due || 0,
                type: "number",
              },
              {
                title: "Reported",
                value: activitySummary.reported || 0,
                type: "number",
              },
              {
                title: "Scored",
                value: activitySummary.scored || 0,
                type: "number",
              },
              {
                title: "Target achieved",
                value: (activitySummary.achievedTarget || 0).toFixed(2),
                type: "percentage",
              },
            ]}
            progress={{
              title: "ACTIVITIES SUMMARY",
              value: activitySummary.score || 0,
            }}
            // disabled={loading}
            disabled={true}
            loading={loading}
          />

          <div className="w-100">
            <input
              type="text"
              value={searchTerm}
              className="form-control"
              id="filter-activities"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
              disabled={loadingData || loading}
            />
          </div>
        </div>
      </DialogTitle>

      <DialogContent className="mx-4 px-2">
        <div className="table-responsive w-100">
          <table
            className={`table table-sm ${
              !loading && !loadingData ? "table-hover" : ""
            }`}
          >
            <thead>
              <tr>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  #
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Activity
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Weight
                </th>
                <th
                  className="text-center"
                  style={{ borderLeft: "3px solid #dee2e6" }}
                >
                  Quarter
                </th>
                <th
                  className="text-center"
                  style={{
                    borderLeft: "3px solid #dee2e6",
                    borderRight: "3px solid #dee2e6",
                  }}
                >
                  Target Share
                </th>
              </tr>
            </thead>
            {!loadingData ? (
              <tbody style={{ fontSize: "13px" }}>
                {paginatedItems[0] ? (
                  paginatedItems.map((item, index) => (
                    <tr key={index}>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {currentPage + index + 1}
                      </td>
                      <td
                        className="text-left"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.name}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.weight || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.quarterId || "-"}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          borderLeft: "3px solid #dee2e6",
                          borderRight: "3px solid #dee2e6",
                          borderBottom:
                            paginatedItems.length === index + 1
                              ? "3px solid #dee2e6"
                              : "",
                        }}
                      >
                        {item.targetShare || "0"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      className="text-center py-4"
                      style={{ border: "3px solid #dee2e6" }}
                      colSpan={5}
                    >
                      <span style={{ fontSize: "17px", fontWeight: "600" }}>
                        No Data Found!
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <TableSkeleton cols={5} rows={15} />
            )}
          </table>
        </div>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-0">
        <div>
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <span className="pr-1 d-none d-md-inline">Next</span>
                <i className="fas fa-angle-double-right"></i>
              </>
            }
            previousLabel={
              <>
                <i className="fas fa-angle-double-left"></i>
                <span className="pl-1  d-none d-md-inline">Previous</span>
              </>
            }
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
            previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
            nextLinkClassName="btn btn-outline-info btn-sm ml-1"
            activeLinkClassName="active"
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ loading, selectedFiscalYear }) => {
  return {
    loading,
    selectedFiscalYear,
  };
};
export default connect(mapStateToProps, { getActivityByIndicatorId })(
  InstitutionIndicatorActivities
);
