import React, { useState, useEffect, useCallback, useMemo } from "react";
import AppBar from "@mui/material/AppBar";
import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { connect, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";

import {
  getQuestionBankQuestions,
  getQuestionBankKnowledgeDomains,
  getKnowledgeLevels,
  deleteQuestionBankQuestion,
  disableQuestionBankQuestion,
  requestToEdit,
} from "../../../store/psychometric/actions";

import { getQuestionTypes } from "../../../store/recruitment/actions";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";

// import ExamSettingDialog from "./ExamSettingDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import axios from "axios";
// import UploadQuestionsDialog from "../components/UploadQuestionsDialog";
import AddOrUpdateQuestionDialog from "../components/AddOrUpdateQuestionDialog";
import AlertDialog from "../../common/components/AlertDialog";

import { Link, useLocation } from "react-router-dom";
import { showError } from "../../toastify";

import defaultPofile from "../../assets/default-profile.jpg";

// import { CSVLink } from "react-csv";
import QuestionLogsDialog from "../components/QuestionLogsDialog";

const ITEM_HEIGHT = 60;
const defaultFilter = {
  id: "",
  name: "All",
};

const defaultQuestionOption = [
  {
    id: "",
    optionNumber: "A",
    en: "",
    fr: "",
    kn: "",
    matchEn: "",
    matchFr: "",
    matchKn: "",
    rateValue: 0,
    isCorrect: false,
  },
  {
    id: "",
    optionNumber: "B",
    en: "",
    fr: "",
    kn: "",
    matchEn: "",
    matchFr: "",
    matchKn: "",
    rateValue: 0,
    isCorrect: false,
  },
  {
    id: "",
    optionNumber: "C",
    en: "",
    fr: "",
    kn: "",
    matchEn: "",
    matchFr: "",
    matchKn: "",
    rateValue: 0,
    isCorrect: false,
  },
  {
    id: "",
    optionNumber: "D",
    en: "",
    fr: "",
    kn: "",
    matchEn: "",
    matchFr: "",
    matchKn: "",
    rateValue: 0,
    isCorrect: false,
  },
];

const defaultQuestion = {
  id: "",
  en: "",
  fr: "",
  kn: "",
  questionTimeInSec: 0,
  score: 0,
  questionTypeId: 1,
  knowledgeLevelId: "",
  knowledgeSubDomainId: "",
  options: [...defaultQuestionOption],
  questionImage: "",
  actionType: "CREATE",
};

const QuestionBankQuestions = (props) => {
  const {
    user,
    loading,
    questionBankKnowledgeDomains,
    questionBankQuestions,
    getQuestionBankKnowledgeDomains,
    getQuestionBankQuestions,
    getKnowledgeLevels,
    getQuestionTypes,

    knowledgeLevels,
    questionTypes,
    deleteQuestionBankQuestion,
    disableQuestionBankQuestion,
    requestToEdit,
  } = props;

  const [question, setQuestion] = useState({ ...defaultQuestion });

  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [showQuestionsUploadDialog, setShowQuestionsUploadDialog] =
    useState(false);
  const [showAddQuestionDialog, setShowAddQuestionDialog] = useState(false);

  const [filteredDomain, setFilteredDomain] = useState(defaultFilter);

  const [filteredSubDomain, setFilteredSubDomain] = useState(defaultFilter);

  const [filteredQuestionType, setFilteredQuestionType] =
    useState(defaultFilter);

  const [filteredKnowledgeLevel, setFilteredKnowledgeLevel] =
    useState(defaultFilter);

  useEffect(() => {
    getQuestionBankKnowledgeDomains();

    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      knowledgeDomainId: filteredDomain?.id || "",
      knowledgeSubDomainId: filteredSubDomain?.id || "",

      questionTypeId: filteredQuestionType?.id || "",
      knowledgeLevelId: filteredKnowledgeLevel?.id || "",
    };
    getQuestionBankQuestions(query, setTotalRequests, setIsLoading);
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      knowledgeDomainId: filteredDomain?.id || "",
      knowledgeSubDomainId: filteredSubDomain?.id || "",

      questionTypeId: filteredQuestionType?.id || "",
      knowledgeLevelId: filteredKnowledgeLevel?.id || "",
    };
    getQuestionBankQuestions(query, setTotalRequests, setIsLoading);
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,

      page: currentPage,
      size: itemPerPage,

      knowledgeDomainId: filteredDomain?.id || "",
      knowledgeSubDomainId: filteredSubDomain?.id || "",

      questionTypeId: filteredQuestionType?.id || "",
      knowledgeLevelId: filteredKnowledgeLevel?.id || "",
    };
    getQuestionBankQuestions(query, setTotalRequests, setIsLoading1);
  };

  const [anchorElType, setAnchorElType] = useState(null);
  const typeMenuOpen = Boolean(anchorElType);
  const handleOpenTypeMenu = (event) => {
    setAnchorElType(event.currentTarget);
  };
  const handleCloseTypeMenu = () => {
    setAnchorElType(null);
  };

  const [anchorElLevel, setAnchorElLevel] = useState(null);
  const levelMenuOpen = Boolean(anchorElLevel);
  const handleOpenLevelMenu = (event) => {
    setAnchorElLevel(event.currentTarget);
  };
  const handleCloseLevelMenu = () => {
    setAnchorElLevel(null);
  };

  useEffect(() => {
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      knowledgeDomainId: filteredDomain.id || "",
      knowledgeSubDomainId: filteredSubDomain.id || "",
      questionTypeId: filteredQuestionType?.id || "",
      knowledgeLevelId: filteredKnowledgeLevel?.id || "",
    };
    getQuestionBankQuestions(query, setTotalRequests, setIsLoading);
  }, [
    filteredDomain,
    filteredSubDomain,
    filteredQuestionType,
    filteredKnowledgeLevel,
  ]);

  const { hasPermissions } = useRequiredAuth();

  const [newQuestionsCount, setNewQuestionsCount] = useState(0);

  useEffect(() => {
    countNewQuestions();
  }, [questionBankQuestions]);

  const countNewQuestions = async () => {
    try {
      const { data } = await axios.get("/api/psy/count-new-questions");
      setNewQuestionsCount(data.number || 0);
    } catch (error) {}
  };

  return (
    <>
      <div className="bg-light border">
        <AppBar
          position="static"
          elevation={0}
          style={{ overflow: "hidden" }}
          className="app-bar pr-2"
        >
          <Toolbar className=" bg-light ">
            <Typography
              variant="span"
              noWrap
              component="div"
              className=" text-uppercase d-inline-flex align-items-center"
            >
              <span className="d-inline-flex align-items-center ">
                {!!filteredDomain?.id && (
                  <>
                    {filteredDomain.name}
                    <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                      <span
                        className="material-icons  text-dark"
                        style={{ position: "relative", top: "4px" }}
                      >
                        chevron_right
                      </span>
                    </span>
                  </>
                )}
                {!!filteredSubDomain?.id && (
                  <>
                    {filteredSubDomain.name}
                    <span className="mr-1 ml-1 " style={{ color: "#2a7790" }}>
                      <span
                        className="material-icons  text-dark"
                        style={{ position: "relative", top: "4px" }}
                      >
                        chevron_right
                      </span>
                    </span>
                  </>
                )}

                {!filteredDomain?.id && !filteredSubDomain?.id && (
                  <>
                    All Questions
                    <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                      <span
                        className="material-icons  text-dark"
                        style={{ position: "relative", top: "4px" }}
                      >
                        chevron_right
                      </span>
                    </span>
                  </>
                )}
              </span>

              <span style={{ color: "#007bff" }}>
                ({totalRequests} Question{totalRequests > 1 ? "s" : ""})
              </span>
            </Typography>

            <Box sx={{ flexGrow: 1 }} />

            {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
              <div className="d-flex justify-content-between">
                {/* <CSVLink
                  data={questionBankQuestions}
                  filename={`question-bank-questions.csv`}
                  className="hidden"
                  id="downloadBTN"
                  target="_blank"
                />
                <Tooltip title="Download questions">
                  <span className="mr-2">
                    <IconButton
                      size="small"
                      disabled={!questionBankQuestions.length}
                      // className="mr-1"
                      color="primary"
                      onClick={() =>
                        document.getElementById("downloadBTN").click()
                      }
                    >
                      <span className="material-icons">file_download</span>
                    </IconButton>
                  </span>
                </Tooltip> */}

                <Link to="/recruitment/psychometric/temp">
                  <Badge
                    showZero
                    badgeContent={newQuestionsCount}
                    color="error"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Button
                      variant="text"
                      size="small"
                      disableElevation
                      className="mr-2 "
                    >
                      <span>New questions</span>
                    </Button>
                  </Badge>
                </Link>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <div className="container-fluid px-4">
          <div className="row mx-0 ">
            <div className="col-md-3 border rounded bg-light pr-0">
              <div
                className="px-2 py-2"
                style={{
                  maxHeight: "67vh",
                  overflow: "auto",
                }}
              >
                {questionBankKnowledgeDomains.map((domain) => (
                  <div key={domain.id}>
                    <h5
                      className={`h6 pl-2 py-2 border text-uppercase cursor-pointer hover-blue d-flex justify-content-between align-items-center ${
                        filteredDomain?.id === domain.id ? "active" : ""
                      }`}
                      style={{
                        backgroundColor: "#eee",
                        borderRadius: "8px",
                      }}
                      onClick={() => {
                        setFilteredDomain(
                          filteredDomain.id === domain.id
                            ? defaultFilter
                            : domain
                        );

                        setFilteredSubDomain(defaultFilter);
                      }}
                    >
                      <span>{domain.name}</span>

                      <span
                        className="float-right"
                        style={{
                          marginTop: "-1px",
                        }}
                      >
                        <small>
                          {filteredDomain?.id === domain.id && (
                            <i className="fas fa-thumbtack mr-2"></i>
                          )}
                        </small>

                        <small
                          className="border"
                          style={{
                            backgroundColor: "#cfcdcd",
                            padding: "4px 15px",
                            borderRadius: 10,
                            fontWeight: 700,
                          }}
                        >
                          {domain?.numberOfQuestions}
                        </small>
                      </span>
                    </h5>
                    <ul className="pl-4" style={{ listStyle: "none" }}>
                      {domain?.subDomains?.map((subDomain) => (
                        <li
                          className={`mb-2 d-flex justify-content-between cursor-pointer hover-blue align-items-center  ${
                            filteredSubDomain?.id === subDomain.id
                              ? "active"
                              : ""
                          }`}
                          key={subDomain.id}
                          onClick={() => {
                            setFilteredDomain(
                              filteredSubDomain.id === subDomain.id
                                ? defaultFilter
                                : domain
                            );
                            setFilteredSubDomain(
                              filteredSubDomain.id === subDomain.id
                                ? defaultFilter
                                : subDomain
                            );
                          }}
                        >
                          {subDomain.name}
                          <span>
                            <small>
                              {filteredSubDomain?.id === subDomain.id && (
                                <i className="fas fa-thumbtack mr-2"></i>
                              )}
                            </small>

                            <small
                              style={{
                                backgroundColor: "#eee",
                                padding: "4px 15px",
                                borderRadius: 10,
                                fontWeight: 700,
                              }}
                            >
                              {subDomain.numberOfQuestions}
                            </small>
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-9 ">
              <CardContent
                className="border rounded pt-2"
                style={{
                  maxHeight: "67vh",
                  overflow: "auto",
                }}
              >
                <div
                  className="rounded"
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <table className="table table-striped">
                    {/* {!isLoading && !!questionBankQuestions.length && ( */}
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <th scope="col">No</th>
                        <th scope="col" className="text-left py-1">
                          <span className="d-flex  align-items-center">
                            Title
                            <div className="d-flex justify-content-right  align-items-center ml-2">
                              <SearchBox
                                onKeyPress={onSearch}
                                onSearch={(search) => {
                                  setSearchTerm(search);

                                  if (!search) {
                                    const currentPage = 1;
                                    setCurrentPage(currentPage);
                                    const query = {
                                      searchTerm: "",
                                      page: currentPage,

                                      size: itemPerPage,
                                      knowledgeDomainId:
                                        filteredDomain?.id || "",
                                      knowledgeSubDomainId:
                                        filteredSubDomain?.id || "",

                                      questionTypeId:
                                        filteredQuestionType?.id || "",
                                      knowledgeLevelId:
                                        filteredKnowledgeLevel?.id || "",
                                    };
                                    getQuestionBankQuestions(
                                      query,
                                      setTotalRequests,
                                      setIsLoading1
                                    );
                                  }
                                }}
                                placeholder="Search…"
                              />
                              <Button
                                disabled={!searchTerm}
                                onClick={() => onSearch()}
                                variant="contained"
                                size="small"
                                disableElevation
                                className="mr-2 "
                                style={{
                                  borderRadius: "8px",
                                  height: "38px",
                                  border: `1px solid #007bff`,
                                  color: "#fff",
                                  alignItems: "center",
                                  fontSize: ".75rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  textTransform: "initial",
                                  backgroundColor: "#007bff",
                                  marginLeft: "5px",
                                  "&:hover": {
                                    backgroundColor: "#f0f2f5",
                                  },
                                }}
                              >
                                <span className="material-icons">search</span>
                                GO
                              </Button>
                            </div>
                          </span>
                        </th>
                        <th scope="col" className="text-right">
                          <div className="d-flex flex-column align-items-end">
                            <span className="d-flex  align-items-center  justify-content-right">
                              <IconButton
                                onClick={handleOpenTypeMenu}
                                size="small"
                                className="mr-1"
                              >
                                <span className="material-icons">
                                  filter_list
                                </span>
                              </IconButton>
                              <Menu
                                anchorEl={anchorElType}
                                id="account-menu"
                                open={typeMenuOpen}
                                onClose={handleCloseTypeMenu}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                {[defaultFilter, ...questionTypes].map(
                                  (questionType, index) => (
                                    <MenuItem
                                      key={index}
                                      onClick={() => {
                                        handleCloseTypeMenu();
                                        setFilteredQuestionType(questionType);
                                      }}
                                    >
                                      {questionType.name}
                                    </MenuItem>
                                  )
                                )}
                              </Menu>
                              Type
                            </span>
                            {!!filteredQuestionType.id && (
                              <small className="text-primary d-block text-truncate mt-n2">
                                {filteredQuestionType.name}{" "}
                                <i className="fas fa-thumbtack"></i>{" "}
                              </small>
                            )}
                          </div>
                        </th>
                        <th scope="col" className="text-right">
                          <div className="d-flex flex-column align-items-end">
                            <span className="d-flex  align-items-center justify-content-right">
                              <IconButton
                                onClick={handleOpenLevelMenu}
                                size="small"
                                className="mr-1"
                              >
                                <span className="material-icons">
                                  filter_list
                                </span>
                              </IconButton>
                              <Menu
                                anchorEl={anchorElLevel}
                                id="account-menu"
                                open={levelMenuOpen}
                                onClose={handleCloseLevelMenu}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: "visible",
                                    filter:
                                      "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 1.5,
                                    "& .MuiAvatar-root": {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    "&:before": {
                                      content: '""',
                                      display: "block",
                                      position: "absolute",
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: "background.paper",
                                      transform:
                                        "translateY(-50%) rotate(45deg)",
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                {[defaultFilter, ...knowledgeLevels].map(
                                  (knowledgeLevel, index) => (
                                    <MenuItem
                                      key={index}
                                      onClick={() => {
                                        handleCloseLevelMenu();

                                        setFilteredKnowledgeLevel(
                                          knowledgeLevel
                                        );
                                      }}
                                    >
                                      {knowledgeLevel.name}
                                    </MenuItem>
                                  )
                                )}
                              </Menu>
                              Level
                            </span>
                            {!!filteredKnowledgeLevel.id && (
                              <small className="text-primary d-block text-truncate mt-n2">
                                {filteredKnowledgeLevel.name}{" "}
                                <i className="fas fa-thumbtack"></i>{" "}
                              </small>
                            )}
                          </div>
                        </th>
                        <th scope="col" className="text-right">
                          Domain
                        </th>
                        <th scope="col" className="text-right">
                          Score
                        </th>
                        <th scope="col" className="text-right">
                          Duration
                        </th>

                        <th scope="col" className="text-right">
                          Views
                        </th>
                        <th scope="col" className="text-center">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    {/* )} */}
                    {isLoading && !questionBankQuestions.length ? (
                      <TableSkeleton cols={10} />
                    ) : (
                      <tbody>
                        <tr>
                          <td>
                            <BackdropLoader isLoading={isLoading1} />
                          </td>
                        </tr>

                        {questionBankQuestions?.map((question, index) => (
                          <QuestionCard
                            loading={loading}
                            question={question}
                            index={index}
                            key={index}
                            dispatch={dispatch}
                            setShowAddQuestionDialog={setShowAddQuestionDialog}
                            setQuestion={setQuestion}
                            deleteQuestionBankQuestion={
                              deleteQuestionBankQuestion
                            }
                            disableQuestionBankQuestion={
                              disableQuestionBankQuestion
                            }
                            requestToEdit={requestToEdit}
                          />
                        ))}
                      </tbody>
                    )}
                  </table>
                  <NoRecordMessage
                    isEmpty={!questionBankQuestions?.length && !isLoading}
                    title={"No record found"}
                  />
                  {!isLoading && (
                    <div
                      className="d-flex justify-content-center  align-items-center"
                      style={{ overflow: "hidden" }}
                    >
                      <div className="mt-3">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={
                            <>
                              <span className="pr-1 d-none d-md-inline">
                                Next
                              </span>
                              <i className="fas fa-angle-double-right"></i>
                            </>
                          }
                          previousLabel={
                            <>
                              <i className="fas fa-angle-double-left"></i>
                              <span className="pl-1  d-none d-md-inline">
                                Previous
                              </span>
                            </>
                          }
                          pageRangeDisplayed={3}
                          // marginPagesDisplayed={1}
                          onPageChange={handlePageClick}
                          pageCount={Math.ceil(totalRequests / itemPerPage)}
                          renderOnZeroPageCount={null}
                          containerClassName="pagination"
                          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                          nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                          activeLinkClassName="active"
                        />
                      </div>

                      {!!questionBankQuestions?.length && (
                        <select
                          className="custom-select custom-select-sm ml-2"
                          style={{ width: "100px" }}
                          value={itemPerPage}
                          onChange={(event) => {
                            const itemPerPage = +event.target.value;
                            window.localStorage.setItem(
                              "ITEM_PER_PAGE",
                              itemPerPage
                            );
                            setItemPerPage(itemPerPage);
                            const query = {
                              searchTerm: searchTerm,
                              page: currentPage,
                              size: itemPerPage,
                              knowledgeDomainId: filteredDomain?.id || "",
                              knowledgeSubDomainId: filteredSubDomain?.id || "",

                              questionTypeId: filteredQuestionType?.id || "",
                              knowledgeLevelId:
                                filteredKnowledgeLevel?.id || "",
                            };
                            getQuestionBankQuestions(
                              query,
                              setTotalRequests,
                              setIsLoading
                            );
                          }}
                        >
                          {[10, 50, 100].map((item, index) => (
                            <option key={index}>{item}</option>
                          ))}
                        </select>
                      )}
                    </div>
                  )}
                </div>
              </CardContent>
            </div>
          </div>
        </div>

        {showAddQuestionDialog && (
          <AddOrUpdateQuestionDialog
            showDialog={showAddQuestionDialog}
            setShowDialog={setShowAddQuestionDialog}
            question={question}
            setQuestion={setQuestion}
            defaultQuestionOption={defaultQuestionOption}
            defaultQuestion={defaultQuestion}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  questionBankQuestions,
  questionBankKnowledgeDomains,
  knowledgeLevels,
  questionTypes,
}) => {
  return {
    user,
    loading,
    questionBankQuestions,
    questionBankKnowledgeDomains,
    knowledgeLevels,
    questionTypes,
  };
};
export default connect(mapStateToProps, {
  getQuestionBankQuestions,
  getQuestionBankKnowledgeDomains,
  getKnowledgeLevels,
  getQuestionTypes,
  deleteQuestionBankQuestion,
  disableQuestionBankQuestion,
  requestToEdit,
})(QuestionBankQuestions);

const QuestionCard = (props) => {
  const {
    loading,
    question,
    index,
    dispatch,

    setShowAddQuestionDialog,
    setQuestion,
    deleteQuestionBankQuestion,
    disableQuestionBankQuestion,
    requestToEdit,
  } = props;

  const { hasPermissions } = useRequiredAuth();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [questionImage, setQuestionImage] = useState("");
  const [showQuestionImage, setShowQuestionImage] = useState(false);

  const [confirmDeleteQuestionDialog, setConfirmDeleteQuestionDialog] =
    useState(false);

  const [confirmDisableQuestionDialog, setConfirmDisableQuestionDialog] =
    useState(false);

  const getQuestionImage = async (questionId) => {
    try {
      dispatch({ type: "START_LOADING" });
      const { data } = await axios.get("/api/psy/question-image/" + questionId);
      dispatch({ type: "END_LOADING" });

      setQuestionImage(data.questionImage);
      setShowQuestionImage(true);
    } catch (error) {}
  };

  const onEditQuestion = async (questionId, handleCloseMenu) => {
    try {
      dispatch({ type: "START_LOADING" });
      const { data } = await axios.get(
        "/api/psy/editable-questions/" + questionId
      );
      dispatch({ type: "END_LOADING" });
      setQuestion(data);
      handleCloseMenu();
      setShowAddQuestionDialog(true);
    } catch (error) {}
  };

  const [showEditRequestDialog, setShowEditRequestDialog] = useState(false);
  const [showQuestionLogs, setShowQuestionLogs] = useState(false);
  const [editorMessage, setEditorMessage] = useState("");
  const [editor, setEditor] = useState(null);

  const findEditor = async () => {
    try {
      dispatch({ type: "START_LOADING" });

      setEditor(null);

      const { data } = await axios.post("/api/psy/find-editor", {
        employeeSearchTerm,
        searchType,
      });

      setEditor(data);

      dispatch({ type: "END_LOADING" });
    } catch (error) {
      dispatch({ type: "END_LOADING" });

      showError(error);
    }
  };

  const onFindEditor = async (e) => {
    e.preventDefault();
    findEditor();
  };

  const [employeeSearchTerm, setEmployeeSearchTerm] = useState(null);
  const [searchType, setSearchType] = useState("firstName");

  return (
    <>
      <tr
        style={{
          cursor: "default",
        }}
      >
        <td style={{ backgroundColor: "#f0f2f5" }}>{question.no}</td>
        <td className="text-left">
          {!!question.hasImage && (
            <Tooltip title="View image">
              <IconButton
                className="ml-n2"
                disabled={loading}
                onClick={() => getQuestionImage(question.id)}
              >
                <PhotoSizeSelectActualIcon />
              </IconButton>
            </Tooltip>
          )}

          <p>
            <span className="font-weight-bold">Eng: </span>
            {question.en || "N/A"}
          </p>
          <p>
            <span className="font-weight-bold">Fr: </span>
            {question.fr || "N/A"}
          </p>
          <p>
            <span className="font-weight-bold">Kin: </span>
            {question.kn || "N/A"}
          </p>
        </td>
        <td className="text-right">{question.questionTypeName}</td>
        <td className="text-right">{question.knowledgeLevelName}</td>
        <td className="text-right">
          <strong>{question.knowledgeDomainName}</strong> <br />
          <small className="text-primary">
            {question.knowledgeSubDomainName}
          </small>
        </td>
        <td className="text-right">{question.score} Marks</td>

        <td className="text-right">{question.questionTimeInSec} secs</td>
        <td className="text-right">
          <Tooltip title={`${question.views} views`}>
            <span>
              {question.views} <i className="fas fa-eye"></i>
            </span>
          </Tooltip>
        </td>

        <td className="text-center">
          <span className="d-flex align-items-center">
            <Tooltip
              title={`${
                !question.pendingAction
                  ? !question.isDisabled
                    ? "Active"
                    : "Disabled"
                  : question.pendingAction + " MODE"
              } `}
            >
              {!question.pendingAction ? (
                <span
                  style={{ fontSize: "14px" }}
                  className={`material-icons text-${
                    !question.isDisabled ? "success" : "danger"
                  }`}
                >
                  fiber_manual_record
                </span>
              ) : (
                <span>
                  <span className="badge badge-primary">
                    {question.pendingAction}
                  </span>
                  <span className="text-primary">
                    {question.editorName || "Editor"}
                  </span>
                </span>
              )}
            </Tooltip>

            <IconButton
              size="small"
              onClick={handleOpenMenu}
              //  onMouseMove={handleOpenMenu}
              aria-label="settings"
            >
              <span className="material-icons">more_vert</span>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  // maxHeight: ITEM_HEIGHT * 4.5,
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                  }}
                  className="text-primary font-weight-bold"
                >
                  <span className="material-icons mr-2">preview</span> Preview
                </MenuItem>
              </span> */}

              {hasPermissions([
                "IS_QUESTION_BANK_ADMIN",
                "IS_QUESTION_BANK_EDITOR",
              ]) && (
                <span>
                  <MenuItem
                    disabled={loading || !!question.pendingAction}
                    onClick={() => {
                      onEditQuestion(question.id, handleCloseMenu);
                    }}
                    className="text-dark"
                  >
                    <span className="material-icons mr-2">edit_note</span>
                    Edit
                  </MenuItem>
                </span>
              )}

              {hasPermissions([
                "IS_QUESTION_BANK_ADMIN",
                "IS_QUESTION_BANK_EDITOR",
              ]) && (
                <span>
                  <MenuItem
                    disabled={loading || !!question.pendingAction}
                    onClick={() => {
                      handleCloseMenu();
                      setShowEditRequestDialog(true);
                    }}
                    className="text-dark font-weight-light"
                  >
                    <span className="material-icons mr-2">
                      edit_notifications
                    </span>
                    Assign editor
                  </MenuItem>
                </span>
              )}

              {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
                <span>
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      setShowQuestionLogs(true);
                    }}
                    className="text-dark font-weight-light"
                  >
                    <span className="material-icons mr-2">list_alt</span> Logs
                  </MenuItem>
                </span>
              )}

              {hasPermissions([
                "IS_QUESTION_BANK_ADMIN",
                "IS_QUESTION_BANK_EDITOR",
              ]) && (
                <span>
                  <Divider className="my-1" />
                  <MenuItem
                    disabled={loading || !!question.pendingAction}
                    onClick={() => {
                      setConfirmDisableQuestionDialog(true);
                      handleCloseMenu();
                    }}
                    className={`text-${
                      !!question.isDisabled ? "success" : "warning"
                    } font-weight-light`}
                  >
                    {!question.isDisabled && (
                      <span className="material-icons mr-2">close</span>
                    )}
                    {!!question.isDisabled && (
                      <span className="material-icons mr-2">check</span>
                    )}
                    {!!question.isDisabled ? "Enable" : "Disable"}
                  </MenuItem>
                </span>
              )}

              {/* {hasPermissions(["IS_QUESTION_BANK_ADMIN"]) && (
                <span>
                  <Divider className="my-1" />
                  <MenuItem
                    disabled={loading || !!question.pendingAction}
                    onClick={() => {
                      setConfirmDeleteQuestionDialog(true);
                      handleCloseMenu();
                    }}
                    className="text-danger font-weight-light"
                  >
                    <span className="material-icons mr-2">delete_forever</span>
                    Remove
                  </MenuItem>
                </span>
              )} */}
            </Menu>
          </span>
        </td>
      </tr>

      {showQuestionImage && (
        <AlertDialog
          showAlert={showQuestionImage}
          setShowAlert={setShowQuestionImage}
          maxWidth="xs"
          children={
            <div className="mt-3">
              {!!questionImage ? (
                <img
                  src={`data:image/png;base64,${questionImage}`}
                  className="img-fluid border rounded"
                  alt="default Image"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                <span>No image found</span>
              )}
            </div>
          }
        />
      )}

      {confirmDisableQuestionDialog && (
        <ConfirmationDialog
          confirmationDialog={confirmDisableQuestionDialog}
          message={
            <>
              Are you sure you want to{" "}
              <strong
                className={`text-${
                  !!question.isDisabled ? "success" : "danger"
                }`}
              >
                {!!question.isDisabled ? "enable" : "disable"}
              </strong>{" "}
              this quesition?
            </>
          }
          setConfirmationDialog={setConfirmDisableQuestionDialog}
          onYes={() => {
            disableQuestionBankQuestion(
              { questionId: question.id, isDisabled: !question.isDisabled },
              setConfirmDisableQuestionDialog
            );
          }}
        />
      )}

      {confirmDeleteQuestionDialog && (
        <ConfirmationDialog
          confirmationDialog={confirmDeleteQuestionDialog}
          message="Are you sure you want to delete this quesition?"
          setConfirmationDialog={setConfirmDeleteQuestionDialog}
          noPreFormat={true}
          onYes={() => {
            deleteQuestionBankQuestion(
              question.id,
              setConfirmDeleteQuestionDialog
            );
          }}
        />
      )}

      {showEditRequestDialog && (
        <EditRequestDialog
          loading={loading}
          showDialog={showEditRequestDialog}
          setShowDialog={setShowEditRequestDialog}
          dispatch={dispatch}
          question={question}
          requestToEdit={requestToEdit}
        />
      )}

      {showQuestionLogs && (
        <QuestionLogsDialog
          loading={loading}
          showDialog={showQuestionLogs}
          setShowDialog={setShowQuestionLogs}
          dispatch={dispatch}
          question={question}
        />
      )}
    </>
  );
};

const EditRequestDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    dispatch,
    question,
    requestToEdit,
  } = props;

  const [showEditRequestDialog, setShowEditRequestDialog] = useState(false);
  const [editorMessage, setEditorMessage] = useState("");
  const [editor, setEditor] = useState(null);

  const findEditor = async () => {
    try {
      dispatch({ type: "START_LOADING" });

      setEditor(null);

      const { data } = await axios.post(
        "/api/structure/user-details-by-search-terms",
        {
          employeeSearchTerm,
          searchType,
        }
      );

      dispatch({ type: "END_LOADING" });

      if (data?.length === 1) setEditor(data[0]);
      else showError("Invalid search. Too many record was found.");
    } catch (error) {
      dispatch({ type: "END_LOADING" });

      showError(error);
    }
  };

  const onFindEditor = async (e) => {
    e.preventDefault();
    findEditor();
  };

  const [employeeSearchTerm, setEmployeeSearchTerm] = useState(null);
  const [searchType, setSearchType] = useState("email");

  const onClose = () => {
    setShowDialog(false);
  };

  const onSave = () => {
    requestToEdit(
      { questionId: question?.id, editorId: editor?.id, editorMessage },
      onClose
    );
  };

  return (
    <>
      <Dialog onClose={onClose} open={showDialog} fullWidth maxWidth="md">
        <DialogTitle className="text-primary">
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">Assign editor</span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={onFindEditor}>
            <div className="row mx-0 mb-3 no-gutters">
              <div className="col-3">
                <FormControl size="small" fullWidth className="mb-2">
                  <InputLabel id="searchTypeLabel">Search By</InputLabel>
                  <Select
                    labelId="searchTypeLabel"
                    id="searchType"
                    value={searchType}
                    label="Search By"
                    onChange={(e) => {
                      setSearchType(e.target.value);
                    }}
                  >
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="phoneNumber">Phone Number</MenuItem>
                    <MenuItem value="id">IPPIS User ID</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-7 pl-2">
                <TextField
                  fullWidth
                  size="small"
                  rows={4}
                  name="searchTerm"
                  label={`Search by (${searchType})`}
                  placeholder={`Search by (${searchType})`}
                  variant="outlined"
                  className="font-weight-bold mb-2"
                  value={employeeSearchTerm || ""}
                  onChange={(e) => {
                    setEmployeeSearchTerm(e.target.value || "");
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          style={{
                            borderRadius: "0px",
                          }}
                          className={`badge badge-primary`}
                        >
                          {searchType}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-2">
                <button
                  disabled={loading || !employeeSearchTerm}
                  type="submit"
                  className="btn btn-primary btn-block text-uppercase ml-1 d-flex align-items-center "
                >
                  <span className="material-icons">search</span>
                  Search
                </button>
              </div>
            </div>
          </form>
          <div
            className="elevated rounded p-3"
            style={{
              backgroundColor: "#fafafa",
              border: "1px solid #17a2b8",
            }}
          >
            <div className="row mt-3">
              <div className="col-2 pr-0 justify-content-center">
                <div
                  style={{
                    minHeight: "128px",
                    overflow: "hidden",
                  }}
                  className="text-center"
                >
                  {editor?.profilePicture && (
                    <img
                      className="mt-0 rounded"
                      src={"data:image/jpg;base64," + editor?.profilePicture}
                      style={{
                        border: "1px solid #a6d3df",
                        height: "128px",
                        overflow: "hidden",
                      }}
                    />
                  )}
                  {!editor?.profilePicture && (
                    <img
                      className="mt-0  rounded text-center"
                      src={defaultPofile}
                      style={{
                        height: "128px",
                        width: "100px",
                        overflow: "hidden",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </div>

                <div className="text-primary text-center text-uppercase">
                  NID photo
                </div>
              </div>

              <div className="col-10 row align-items-start">
                <div className="col-4 mb-3 ">
                  <TextField
                    style={{ backgroundColor: "#e9ecef" }}
                    fullWidth
                    size="small"
                    name="userId"
                    label="IPPIS User Id"
                    variant="outlined"
                    className="font-weight-bold"
                    value={editor?.id || " "}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className="col-4 mb-3 ">
                  <TextField
                    style={{ backgroundColor: "#e9ecef" }}
                    fullWidth
                    size="small"
                    name="email"
                    label="Email"
                    variant="outlined"
                    className="font-weight-bold"
                    value={editor?.email || " "}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className="col-4 mb-3 ">
                  <TextField
                    style={{ backgroundColor: "#e9ecef" }}
                    fullWidth
                    size="small"
                    name="phoneNumber"
                    label="Phone number"
                    variant="outlined"
                    className="font-weight-bold"
                    value={editor?.phoneNumber || " "}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className="col-12 mb-3 ">
                  <TextField
                    style={{ backgroundColor: "#e9ecef" }}
                    fullWidth
                    size="small"
                    name="Names"
                    label="Names"
                    variant="outlined"
                    className="font-weight-bold"
                    value={
                      !!editor
                        ? (editor?.lastName || "") +
                          " " +
                          (editor?.firstName || "")
                        : " "
                    }
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className="col-12 mb-2">
                  <TextField
                    style={{ backgroundColor: "#e9ecef" }}
                    fullWidth
                    size="small"
                    name="position"
                    label="Position"
                    variant="outlined"
                    className="font-weight-bold mb-2"
                    value={editor?.positionName || " "}
                    InputProps={{ readOnly: true }}
                  />
                </div>
                <div className="col-12 mb-2">
                  <TextField
                    style={{ backgroundColor: "#e9ecef" }}
                    fullWidth
                    size="small"
                    name="entityName"
                    label="Institution"
                    variant="outlined"
                    className="font-weight-bold mb-2"
                    value={editor?.entityName || " "}
                    InputProps={{ readOnly: true }}
                  />
                </div>

                <div className="col-12 mb-2">
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    size="small"
                    name="editorMessage"
                    label="Message"
                    variant="outlined"
                    className="font-weight-bold mb-2"
                    value={editorMessage || " "}
                    onChange={(e) => setEditorMessage(e.target.value || "")}
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onSave}
                  type="button"
                  className="px-5"
                  disabled={loading || !editor || !editorMessage}
                >
                  Assign editor
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          {/* <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            type="button"
            className="px-5"
            disabled={loading}
          >
            {loading ? "Wait..." : "Confirm"}
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};
