import React from "react";

function NoResults({ message = "--No record found--" }) {
  return (
    <div className="jumbotron d-flex flex-column justify-content-center align-items-center">
      <span className="lead" style={{ fontSize: "18px" }}>
        {message}
      </span>
    </div>
  );
}

export default NoResults;
