import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import BackdropLoader from "./components/BackdropLoader";

import { getPSRPUserToken } from "../../store/common/actions";

const PSRP_Redirect = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { user, getPSRPUserToken } = props;

  useEffect(() => {
    if (isEmpty(user)) return history.push("/");
  }, []);
  useEffect(() => {
    getPSRPUserToken();
  }, []);
  return (
    <React.Fragment>
      {" "}
      <>
        <>
          <div style={{ marginTop: "0px" }}>
            <div style={{ backgroundColor: "#078ece", height: "6px" }}></div>
            <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
            <div style={{ backgroundColor: "#199e05", height: "2px" }}></div>
          </div>
        </>
        <div className={`container-fluid pt-2 px-4 `} id="main-container">
          {" "}
          <BackdropLoader isLoading={true} />{" "}
          <div
            style={{
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "53%",
              left: "47%",
              marginTop: "-25px",
              marginLeft: "-10px",
            }}
          >
            <strong> Please wait ....</strong>{" "}
          </div>
        </div>
      </>
    </React.Fragment>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};
export default connect(mapStateToProps, { getPSRPUserToken })(PSRP_Redirect);
