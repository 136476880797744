import React, { useState, useEffect } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ModeRoundedIcon from "@mui/icons-material/ModeRounded";
import {
  activateCostCenter,
  addNewCostCenter,
  getAdminCostCenter,
  getAdminCountry,
  getAdminCreditor,
  updateCostCenter,
} from "../../../store/admin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SearchBox from "../../common/components/SearchBox";
import TableSkeleton from "../../common/components/TableSkeleton";
import ReactPaginate from "react-paginate";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const CostCenter = (props) => {
  const {
    createCostCenter,
    setCreateCostCenter,
    // redux -----------------
    loading,
    addNewCostCenter,
    updateCostCenter,
    adminCountries,
    getAdminCountry,
    adminCostCenter,
    getAdminCostCenter,
    activateCostCenter,
  } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [filterCountry, setFilterCountry] = useState(null);
  const [deleteCostCenter, setDeleteCostCenter] = useState(null);
  const [costCenterId, setCostCenterId] = useState("");
  const [costCenterName, setCostCenterName] = useState("");
  const [tinNumber, setTinNumber] = useState("");
  const [newCostCenterId, setNewCostCenterId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [formError, setFormError] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  //PAGINATION
  const [itemOffset, setItemOffset] = useState(0);
  const [forcePage, setForcePage] = useState(undefined);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!adminCostCenter.length) {
      getAdminCostCenter(setLoadingData);
    } else {
      setLoadingData(false);
    }

    if (!adminCountries.length) getAdminCountry();

    return () => {
      setLoadingData(true);
      setCostCenterId("");
      setCostCenterName("");
      setFormError(null);
      setSearchInput("");
      setSelectedCountry(null);
    };
  }, []);

  const onCloseForm = () => {
    setCreateCostCenter(false);
    setCostCenterId("");
    setCostCenterName("");
    setSelectedCountry(null);
    setNewCostCenterId("");
    setTinNumber("");
  };

  const onSaveForm = () => {
    if (!costCenterId.length && !createCostCenter) {
      setFormError({
        element: "GENERAL",
        msg: "Please select cost center you want to update.",
      });
      return;
    }
    if (!newCostCenterId.length && createCostCenter) {
      setFormError({
        element: "COST_CENTER_ID",
        msg: "IFMIS id is required!",
      });
      return;
    }
    if (newCostCenterId.length !== 10) {
      setFormError({
        element: "COST_CENTER_ID",
        msg: "IFMIS id must be 10 number!",
      });
      return;
    }
    if (costCenterName.length === 0) {
      setFormError({ element: "NAME", msg: "Cost center name is required." });
      return;
    }

    if (costCenterId.length && !createCostCenter) {
      updateCostCenter(
        costCenterId,
        {
          name: costCenterName,
          tinNumber: tinNumber.length ? tinNumber : null,
          ifmisCode: newCostCenterId,
          country: selectedCountry,
        },
        (status) => status && onCloseForm()
      );
    } else {
      addNewCostCenter(
        {
          name: costCenterName,
          tinNumber: tinNumber.length ? tinNumber : null,
          ifmisCode: newCostCenterId,
          country: selectedCountry,
        },
        (status) => status && onCloseForm()
      );
    }
  };

  const handleFilter = (data) => {
    let result = [...data];
    result = result.filter((item) => item.isActive === !showInactive);
    // if (filterBasePayroll)
    //   result = result.filter(
    //     (item) => item.basePayrollComponentId === filterBasePayroll?.id
    //   );

    return result;
  };

  const handleChangeStatus = () => {
    if (deleteCostCenter)
      activateCostCenter(
        deleteCostCenter.id,
        (status) => status && setDeleteCostCenter(null)
      );
  };

  const IS_EDITING = Boolean(costCenterId.length);
  let SEARCHED_DATA = !loadingData
    ? searchInput.length === 0
      ? adminCostCenter
      : adminCostCenter.filter(
          (item) =>
            item.name
              .toLocaleLowerCase()
              .includes(searchInput.toLocaleLowerCase()) ||
            (item.tinNumber || "").includes(searchInput) ||
            (item.ifmisCode || "").includes(searchInput)
        )
    : [];
  SEARCHED_DATA = handleFilter(SEARCHED_DATA);
  const PAGE_COUNT = Math.ceil((SEARCHED_DATA || []).length / itemsPerPage);

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    return items.slice(itemOffset, endOffset);
  };

  const PAGE_DATA = paginate(SEARCHED_DATA || []);

  const handlePageClick = (event) => {
    setForcePage(undefined);
    const newOffset =
      (event.selected * itemsPerPage) % SEARCHED_DATA.length || 0;

    setItemOffset(newOffset);
  };

  const setForUpdate = (costCenterItem) => {
    setCostCenterId(costCenterItem.id);
    setNewCostCenterId(costCenterItem.id);
    setCostCenterName(costCenterItem.name);
    setTinNumber(costCenterItem.tinNumber || "");
    setSelectedCountry(costCenterItem.country);
  };

  return (
    <div className="my-2 elevated rounded p-2">
      <div className="mb-3 row align-items-center">
        <div className="col-12 col-sm-8">
          <SearchBox
            className="mb-1"
            onSearch={setSearchInput}
            disabled={loadingData || adminCostCenter.length === 0}
            placeholder="Search cost center..."
          />
        </div>
        <div className="col-6 col-sm-3 mt-2 mt-md-0">
          <Autocomplete
            disabled={loading || loadingData}
            disablePortal
            getOptionLabel={(option) => option.name}
            renderOption={(props, country) => (
              <Box component="li" {...props}>
                {country?.name}
              </Box>
            )}
            options={adminCountries}
            size="small"
            value={filterCountry || null}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by country"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "country",
                }}
              />
            )}
            onChange={(event, country) => setFilterCountry(country || null)}
          />
        </div>
        <div className="col-4 col-sm-1 mt-2 mt-md-0 px-0">
          <Button
            onClick={() => {
              setShowInactive(!showInactive);
              setItemOffset(0);
              setForcePage(0);
            }}
            variant="outlined"
            size="small"
            disableElevation
            // className="mr-2"
            style={{
              borderRadius: "8px",
              height: "32px",
              border: showInactive
                ? `1px solid rgb(7, 142, 206)`
                : `1px solid rgb(206, 7, 7)`,
              backgroundColor: "#fff",
              alignItems: "center",
              fontSize: ".75rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textTransform: "initial",
              color: showInactive ? "rgb(7, 142, 206)" : "rgb(206, 7, 7)",
            }}
            disabled={loading}
          >
            {showInactive ? "Active" : "Inactive"}
          </Button>
        </div>
      </div>

      <table
        className={`table table-hover table-sm ${
          loadingData ? "" : "table-striped"
        } fixTableHead mt-2`}
      >
        <thead>
          <tr style={{ backgroundColor: "#f0f2f5" }}>
            <th scope="col">#</th>
            <th scope="col">Cost center</th>
            <th className="text-center">Tin Number</th>
            <th className="text-center">IFMIS Code</th>
            <th className="text-center">Country</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        {loadingData ? (
          <TableSkeleton cols={6} />
        ) : PAGE_DATA.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={6}>
                <div className="text-center font-weight-bold my-2">
                  No cost center found!
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {PAGE_DATA.map((costCenterItem, index) => (
              <tr key={"costCenter-" + index}>
                <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
                  {costCenterItem && costCenterItem.id}
                </th>
                <td>{costCenterItem.name}</td>
                <td align="center">
                  {costCenterItem.tinNumber ? costCenterItem.tinNumber : "-"}
                </td>
                <td align="center">{costCenterItem.ifmisCode}</td>
                <td align="center">
                  {costCenterItem.country ? costCenterItem.country.name : "-"}
                </td>

                <td align="center">
                  <div className="border bg-white rounded px-1 my-1 d-flex flex-row align-items-center justify-content-center elevated w-content">
                    <IconButton
                      aria-label="edit bank"
                      size="small"
                      onClick={() => setForUpdate(costCenterItem)}
                      className="mr-1"
                    >
                      <ModeRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="change bank status"
                      size="small"
                      onClick={() => {
                        setDeleteCostCenter({
                          id: costCenterItem.id,
                          isActive: costCenterItem.isActive,
                        });
                      }}
                    >
                      {costCenterItem.isActive ? (
                        <HighlightOffRoundedIcon
                          fontSize="small"
                          color="error"
                        />
                      ) : (
                        <CheckCircleOutlineRoundedIcon
                          fontSize="small"
                          color="warning"
                        />
                      )}
                    </IconButton>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {PAGE_COUNT > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel={
            <span>
              <span className="pr-1 d-none d-md-inline">Next</span>
              <i className="fas fa-angle-double-right"></i>
            </span>
          }
          previousLabel={
            <span>
              <i className="fas fa-angle-double-left"></i>
              <span className="pl-1 d-none d-md-inline">Previous</span>
            </span>
          }
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          onPageChange={handlePageClick}
          pageCount={PAGE_COUNT}
          renderOnZeroPageCount={null}
          containerClassName="pagination mt-2"
          pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
          nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
          activeLinkClassName="active"
          forcePage={forcePage}
        />
      )}

      {!loadingData && (
        <Dialog
          onClose={onCloseForm}
          aria-labelledby="customized-dialog-title"
          open={Boolean(createCostCenter || IS_EDITING)}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="overline" display="block">
              {IS_EDITING ? "Edit Cost center" : "Add New Cost center"}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseForm}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            {formError && formError.element === "GENERAL" && (
              <Alert severity="error" className="mb-1">
                {formError.msg}
              </Alert>
            )}

            {createCostCenter && (
              <TextField
                id="outlined-basic"
                label="IFMIS Code"
                variant="outlined"
                size="small"
                className="w-100"
                disabled={loading || loadingData}
                value={newCostCenterId}
                onChange={(e) => {
                  setNewCostCenterId(e.target.value);
                  setFormError(null);
                }}
              />
            )}
            {formError && formError.element === "COST_CENTER_ID" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <TextField
              id="outlined-basic"
              label="Cost center"
              variant="outlined"
              size="small"
              className={`w-100 ${createCostCenter ? "mt-3" : ""}`}
              disabled={loading || loadingData}
              value={costCenterName}
              onChange={(e) => {
                setCostCenterName(e.target.value);
                setFormError(null);
              }}
            />
            {formError && formError.element === "NAME" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            <TextField
              id="outlined-basic"
              label="Tin Number"
              variant="outlined"
              size="small"
              className="w-100 mt-3"
              disabled={loading || loadingData}
              value={tinNumber}
              onChange={(e) => {
                setTinNumber(e.target.value);
                setFormError(null);
              }}
            />
            {formError && formError.element === "TIN_NUMBER" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}

            {/* Country list */}
            <Autocomplete
              disabled={loading || loadingData}
              disablePortal
              getOptionLabel={(option) => option.name}
              renderOption={(props, country) => (
                <Box component="li" {...props}>
                  {country?.name}
                </Box>
              )}
              options={adminCountries}
              size="small"
              value={selectedCountry || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select country"
                  inputProps={{
                    ...params.inputProps,
                    // autoComplete: "country",
                  }}
                />
              )}
              onChange={(event, country) => {
                setSelectedCountry(country || null);
                setFormError(null);
              }}
              className="mt-3"
            />
            {formError && formError.element === "COUNTRY" && (
              <span className="text-danger" style={{ fontSize: "13px" }}>
                {formError.msg}
              </span>
            )}
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-3">
            <button
              onClick={onSaveForm}
              type="button"
              className="btn btn-primary text-uppercase px-4"
              disabled={loading}
            >
              {loading ? "Wait..." : "Save"}
            </button>
          </DialogActions>
        </Dialog>
      )}

      {deleteCostCenter && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to ${
            !deleteCostCenter.isActive ? "activate" : "de-activate"
          } this cost center?`}
          setConfirmationDialog={() => setDeleteCostCenter(null)}
          onYes={handleChangeStatus}
        />
      )}
    </div>
  );
};

CostCenter.propTypes = {
  createCostCenter: PropTypes.bool,
  setCreateCostCenter: PropTypes.func,
};

const mapStateToProps = ({ loading, adminPayroll }) => {
  return {
    loading,
    adminCostCenter: adminPayroll.adminCostCenter,
    adminCountries: adminPayroll.adminCountries,
  };
};

const mapDispatchToProps = {
  getAdminCreditor,
  addNewCostCenter,
  updateCostCenter,
  activateCostCenter,
  getAdminCostCenter,
  getAdminCountry,
};

export default connect(mapStateToProps, mapDispatchToProps)(CostCenter);
