import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  IconButton,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
  ListItemButton,
  ListItemText,
  List,
  ListSubheader,
  Card,
  CardHeader,
  ListItem,
  CardActions,
  ListItemAvatar,
  Avatar,
} from "@mui/material";

import { connect } from "react-redux";
import NoRecordMessage from "../../common/components/NoRecordMessage";

import { setKnowledgeDomain } from "../../../store/psychometric/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ReactPaginate from "react-paginate";

const KnowledgeDomains = (props) => {
  const { loading, user, knowledgeDomains, setKnowledgeDomain } = props;

  const [name, setName] = useState("");
  const [showAdd, setShowAdd] = useState(false);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  useEffect(() => {
    paginate(knowledgeDomains);
  }, [itemOffset, itemsPerPage, knowledgeDomains]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % knowledgeDomains.length || 0;

    setItemOffset(newOffset);
  };

  const getNo = (data, key, idToSearch) =>
    data.findIndex((item) => item[key] === idToSearch);

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    isActive: true,
  });

  return (
    <Card className="border elevated-1 hoverable-card" variant="outlined">
      <div
        className="mb-3"
        style={{
          height: "55vh",
          overflow: "auto",
        }}
      >
        <List
          dense
          component="div"
          subheader={
            <ListSubheader
              className=" border-bottom  font-weight-bold d-flex justify-content-between"
              component="div"
              style={{
                backgroundColor: "#007bff",
                color: "#fff",
                // backgroundColor: "#e6ebf1",
              }}
            >
              Knowledge Domains
              <IconButton color="inherit" onClick={() => setShowAdd(true)}>
                <span className="material-icons">add</span>
              </IconButton>
            </ListSubheader>
          }
        >
          {paginatedItems?.map((knowledgeDomain, index) => (
            <KnowledgeDomainCard
              index={getNo(knowledgeDomains, "id", knowledgeDomain.id)}
              key={knowledgeDomain.id}
              loading={loading}
              user={user}
              knowledgeDomain={knowledgeDomain}
              setKnowledgeDomain={setKnowledgeDomain}
              setFormData={setFormData}
              setShowAdd={setShowAdd}
            />
          ))}
        </List>
        <NoRecordMessage
          isEmpty={!paginatedItems?.length}
          title="No record found. "
        />
      </div>

      <ReactPaginate
        breakLabel="..."
        nextLabel={
          <>
            {/* <span className="pr-1 d-none d-md-inline">Next</span> */}
            <i className="fas fa-angle-double-right"></i>
          </>
        }
        previousLabel={
          <>
            <i className="fas fa-angle-double-left"></i>
            {/* <span className="pl-1  d-none d-md-inline">Previous</span> */}
          </>
        }
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        onPageChange={handlePageClick}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination"
        pageLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        previousLinkClassName="btn btn-outline-dark btn-sm mr-1 ml-1"
        nextLinkClassName="btn btn-outline-dark btn-sm ml-1"
        activeLinkClassName="active"
      />

      {showAdd && (
        <Dialog
          onClose={() => setShowAdd(false)}
          open={showAdd}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle className="text-primary">
            <Typography variant="h6" noWrap component="div">
              <span className="text-primary">Add New Domain</span>
            </Typography>

            <IconButton
              aria-label="close"
              onClick={() => setShowAdd(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                minHeight: "5vh",
              }}
            >
              <TextField
                autoFocus
                fullWidth
                size="small"
                name="knowledgeDomainName"
                label={`Name`}
                placeholder={`Name`}
                variant="outlined"
                className="mt-3"
                value={formData.name || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    name: e.target.value || "",
                  });
                }}
              />
            </div>
          </DialogContent>
          <DialogActions className="d-flex justify-content-center py-4">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                setKnowledgeDomain({ ...formData }, () => {
                  setFormData({
                    id: null,
                    name: "",
                    isActive: true,
                  });
                  setShowAdd(false);
                })
              }
              type="button"
              className="px-5"
              disabled={loading || !formData.name}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
};

const mapStateToProps = ({ user, loading, knowledgeDomains }) => {
  return {
    user,
    loading,
    knowledgeDomains,
  };
};

export default connect(mapStateToProps, { setKnowledgeDomain })(
  KnowledgeDomains
);

const KnowledgeDomainCard = (props) => {
  const {
    loading,
    user,
    knowledgeDomain,
    index,
    setKnowledgeDomain,
    setFormData,
    setShowAdd,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showConfirmDisable, setShowConfirmDisable] = useState(false);

  return (
    <>
      <ListItem
        divider
        key={knowledgeDomain.id}
        secondaryAction={
          <span>
            <IconButton
              size="small"
              onClick={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();

                    setFormData({
                      id: knowledgeDomain.id,
                      name: knowledgeDomain.name,
                      isActive: knowledgeDomain.isActive,
                    });
                    setShowAdd(true);
                  }}
                  className={`text-primary`}
                >
                  <span className="material-icons mr-2">edit</span>
                  Edit
                </MenuItem>
              </span>

              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowConfirmDisable(true);
                  }}
                  className={`text-${
                    !knowledgeDomain.isActive ? "success" : "danger"
                  } font-weight-light`}
                >
                  {knowledgeDomain.isActive && (
                    <span className="material-icons mr-2">close</span>
                  )}
                  {!knowledgeDomain.isActive && (
                    <span className="material-icons mr-2">check</span>
                  )}
                  {knowledgeDomain.isActive ? "Disable" : "Enable"}
                </MenuItem>
              </span>
            </Menu>
          </span>
        }
      >
        <ListItemAvatar>
          <Avatar variant="square">{index + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<span>{knowledgeDomain.name}</span>}
          secondary={
            <>
              <span className="">{knowledgeDomain.knowledgeDomainName}</span>
              <span
                className={`d-inline-flex align-items-center text-${
                  knowledgeDomain.isActive ? "success" : "danger"
                }`}
              >
                <span
                  style={{ fontSize: "12px" }}
                  className={`material-icons text-${
                    knowledgeDomain.isActive ? "success" : "danger"
                  }`}
                >
                  fiber_manual_record
                </span>
                {knowledgeDomain.isActive ? "Active" : "Disabled"}
              </span>
            </>
          }
        />
      </ListItem>

      {showConfirmDisable && (
        <ConfirmationDialog
          confirmationDialog={showConfirmDisable}
          message={
            <>
              Are you sure you want to{" "}
              <strong
                className={`text-${
                  knowledgeDomain.isActive ? "danger" : "success"
                }`}
              >
                {knowledgeDomain.isActive ? "disable" : "enable"}
              </strong>{" "}
              this domain?
            </>
          }
          setConfirmationDialog={setShowConfirmDisable}
          noPreFormat={true}
          onYes={() => {
            setKnowledgeDomain(
              {
                id: knowledgeDomain.id,
                name: knowledgeDomain.name,
                isActive: !knowledgeDomain.isActive,
              },
              () => setShowConfirmDisable(false)
            );
          }}
        />
      )}
    </>
  );
};
