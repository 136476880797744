import { defaultState } from "./state";
import support from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...support };

const userFound = (userFoundState = defaultState.userFound, action) => {
  switch (action.type) {
    case types.USER_FOUND:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.userFound;

    default:
      return userFoundState;
  }
};

const entitySectors = (
  entitySectorsState = defaultState.entitySectors,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTORS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.entitySectors;

    default:
      return entitySectorsState;
  }
};

const entitySectorUnits = (
  entitySectorUnitsState = defaultState.entitySectorUnits,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_UNITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.entitySectorUnits;

    default:
      return entitySectorUnitsState;
  }
};

// const costCenters = (costCentersState = defaultState.costCenters, action) => {
//   switch (action.type) {
//     case types.SET_COST_CENTERS:
//       return action.data;
//     case types.CLEAN_STATE:
//       return defaultState.costCenters;
//     default:
//       return costCentersState;
//   }
// };

const entitySectorCostCenters = (
  entitySectorCostCentersState = defaultState.entitySectorCostCenters,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_COST_CENTERS:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.entitySectorCostCenters;
    default:
      return entitySectorCostCentersState;
  }
};
const entitySectorCreditors = (
  entitySectorCreditorsState = defaultState.entitySectorCreditors,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_CREDITORS:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.entitySectorCreditors;
    default:
      return entitySectorCreditorsState;
  }
};

const entitySectorPayrollTypes = (
  entitySectorPayrollTypesState = defaultState.entitySectorPayrollTypes,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_PAYROLL_TYPES:
      return action.data;
    case types.CLEAN_STATE:
      return defaultState.entitySectorPayrollTypes;
    default:
      return entitySectorPayrollTypesState;
  }
};

const examCenters = (examCenterState = defaultState.examCenters, action) => {
  switch (action.type) {
    case types.SET_EXAM_CENTERS:
      return action.data;
    case types.ADD_EXAM_CENTER:
      return [...examCenterState, action.data];
    case types.EDIT_EXAM_CENTER: {
      const centers = [...examCenterState];
      const index = centers.findIndex((item) => item.id === action.data.id);
      if (index >= 0) {
        centers[index].name = action.data.name;
      }
      return centers;
    }
    case types.EDIT_EXAM_CENTER_STATUS: {
      const centers = [...examCenterState];
      const index = centers.findIndex((item) => item.id === action.data);
      if (index >= 0) {
        centers[index].isActive = !centers[index].isActive;
      }
      return centers;
    }
    case types.CLEAN_STATE:
      return defaultState.examCenters;
    default:
      return examCenterState;
  }
};

const centerIpAddress = (
  centerIpAddressState = defaultState.centerIpAddress,
  action
) => {
  switch (action.type) {
    case types.SET_CENTER_IP_ADDRESS:
      return [...centerIpAddressState, ...action.data];
    case types.ADD_CENTER_IP_ADDRESS: {
      let ips = [...centerIpAddressState];
      if (!!action.data.stopOthers) {
        ips = ips.map((item) => ({ ...item, isActive: false }));
      }
      return [...ips, action.data];
    }
    case types.EDIT_CENTER_IP_ADDRESS: {
      let ips = [...centerIpAddressState];
      const index = ips.findIndex((item) => item.id === action.data.id);
      if (index >= 0) ips[index].ip = action.data.ip;
      if (!!action.data.stopOthers) {
        ips = ips.map((item) =>
          item.id === action.data.id ? item : { ...item, isActive: false }
        );
      }
      return [...ips];
    }
    case types.EDIT_CENTER_IP_ADDRESS_STATUS: {
      const ips = [...centerIpAddressState];
      const index = ips.findIndex((item) => item.id === action.data);
      if (index >= 0) {
        ips[index].isActive = !ips[index].isActive;
      }
      return ips;
    }
    case types.CLEAN_STATE:
      return defaultState.centerIpAddress;
    default:
      return centerIpAddressState;
  }
};

export default {
  userFound,
  entitySectors,
  entitySectorUnits,
  entitySectorCostCenters,
  entitySectorCreditors,
  entitySectorPayrollTypes,
  examCenters,
  centerIpAddress,
};
