import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import { connect, useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import SearchBox from "../../common/components/SearchBox";
import UnitDrawer from "../components/UnitDrawer";
import {
  Skeleton,
  CardContent,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Button,
  Fab,
  Tooltip,
  IconButton,
} from "@mui/material";

import {
  getUnitPositionsByPagination,
  getEntitySectorPositions,
  setSearchPositions,
  getSalaryStructure,
  getSharedScopes,
  getAllowanceTypes,
  getDegrees,
  getSupervisors,
  getEmployeeGroups,
  getHiringModes,
  getLevels,
  getEchelons,
  downloadPositions,
  getRRAGrades,
  getRRADepartments,
} from "../../../store/structure/actions";
import {
  getSourceOfFunds,
  getPayrollGroups,
  getCostCenters,
  getCalculationMethods,
  getBasePayrollComponents,
  getAllowances,
  getCreditors,
} from "../../../store/payroll/actions";

import PositionCard from "../components/PositionCard";
import AddPostionDialog from "../components/AddPostionDialog";
import AddSharedPostionDialog from "../components/AddSharedPostionDialog";

import types from "../../../store/structure/action-types";
import { isEmpty } from "lodash";
import ReactPaginate from "react-paginate";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const arrangingPostions = (data) => {
  let tempRes = [...data];
  let temPositions1 = [];
  let temPositions2 = [];
  let temPositions3 = [];

  tempRes.forEach((e) => {
    if (e.isHeadOfInstitution || e.isHeadOfUnit) temPositions1.push({ ...e });
    else if (e.isShared) temPositions3.push({ ...e });
    else temPositions2.push({ ...e });
  });

  return [...temPositions1, ...temPositions2, ...temPositions3];
};

const actions = [
  { icon: <AddIcon />, name: "Add New Position", key: 1 },
  { icon: <ArrowCircleDownIcon />, name: "Pull shared position", key: 2 },
];

const Positions = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Org. Structure | Positions";
  });

  const {
    // selectedUnit,
    user,
    getUnitPositionsByPagination,
    getEntitySectorPositions,
    positions,
    loading,
    setSearchPositions,
    searchCurrentPositions,
    getSourceOfFunds,
    getPayrollGroups,
    getCostCenters,
    getSalaryStructure,
    getSharedScopes,

    allowances,
    creditors,
    allowanceTypes,
    calculationMethods,
    basePayrollComponents,

    getAllowances,
    getCreditors,
    getAllowanceTypes,
    getCalculationMethods,
    getBasePayrollComponents,

    sharedScopes,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    salaryStructures,
    degrees,
    getDegrees,
    supervisors,
    getSupervisors,
    employeeGroups,
    hiringModes,
    levels,
    echelons,
    getEmployeeGroups,
    getHiringModes,
    getLevels,
    getEchelons,
    downloadPositions,

    rraGrades,
    getRRAGrades,

    rraDepartments,
    getRRADepartments,
  } = props;

  const [showPostionForm, setShowPositionForm] = useState(false);
  const [showSharedPostionForm, setShowSharedPositionForm] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const { hasPermissions } = useRequiredAuth();
  const [totalPositions, setTotalPositions] = useState(0);
  const [positionLoader, setPositionLoader] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [positionLoaderOnNextPage, setPositionLoaderOnNextPage] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(selectedUnit)) {
      dispatch({
        type: types.SET_POSITIONS,
        data: [],
      });
    }

    if (!employeeGroups.length) getEmployeeGroups();
    if (!hiringModes.length) getHiringModes();
    if (!levels.length) getLevels();
    if (!echelons.length) getEchelons();

    if (!rraGrades.length) getRRAGrades();
    if (!rraDepartments.length) getRRADepartments();

    // if (!sourceOfFunds.length)
    getSourceOfFunds();
    if (!payrollGroups.length) getPayrollGroups(user.selectedEntitySector.id);
    // if (!costCenters.length)
    getCostCenters(user.selectedEntitySector.id);

    if (!degrees.length) getDegrees();
    if (!allowanceTypes.length) getAllowanceTypes();
    if (!creditors.length) getCreditors();
    if (!allowances.length) getAllowances();
    if (!calculationMethods.length) getCalculationMethods();
    if (!basePayrollComponents.length) getBasePayrollComponents();
    if (!sharedScopes.length) getSharedScopes();
    if (!salaryStructures.length)
      getSalaryStructure(user.selectedEntitySector.id);

    if (
      !supervisors.length ||
      (!!supervisors.length && !!supervisors[0].employeePositionId)
    )
      getSupervisors();
  }, []);

  const onSearch = (term) => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
    };
    getUnitPositionsByPagination(
      selectedUnit.unitId,
      query,
      setTotalPositions,
      setPositionLoader
    );
  };

  const getNo = (idToSearch, unit_id) =>
    arrangingPostions(positions).findIndex(
      ({ id, unitId }) => id === idToSearch && unitId === unit_id
    );

  const onSelectUnit = (unit) => {
    setSelectedUnit(unit);
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };
    getUnitPositionsByPagination(
      unit.unitId,
      query,
      setTotalPositions,
      setPositionLoader
    );
  };
  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };
    getUnitPositionsByPagination(
      selectedUnit.unitId,
      query,
      setTotalPositions,
      setPositionLoaderOnNextPage
    );
  };

  return (
    <>
      <div className="d-flex">
        <div className="structure-unit-drawer">
          <UnitDrawer onSelectUnit={onSelectUnit} />
        </div>

        <div className="structure-main-content w-100">
          <AppBar position="static" elevation={0} className="app-bar">
            <Toolbar className="px-3 tool-bar">
              <Typography
                className="text-uppercase "
                variant="h6"
                noWrap
                component="div"
                style={{ maxWidth: "40vw" }}
              >
                {" "}
                {user?.selectedEntitySector?.isLocked && (
                  <Tooltip title="Structure was locked">
                    <i className="fas fa-lock text-danger"></i>
                  </Tooltip>
                )}{" "}
                <span
                  className="px-2 ml-1"
                  style={{
                    textDecoration: "initial",
                    backgroundColor: "#e9ecef",
                  }}
                >
                  {isEmpty(selectedUnit)
                    ? "--No unit selected--"
                    : selectedUnit.unitName}
                </span>
                {/* <Box sx={{ flexGrow: 1 }} /> */}
              </Typography>

              {!isEmpty(selectedUnit) && (
                <Box
                  sx={{ display: { xs: "flex", md: "flex" } }}
                  className="ml-2"
                >
                  <SearchBox
                    onSearch={(search) => {
                      setSearchTerm(search);
                      if (!search) {
                        const currentPage = 1;
                        setCurrentPage(currentPage);
                        const query = {
                          searchTerm: "",
                          page: currentPage,
                          size: itemPerPage,
                        };
                        getUnitPositionsByPagination(
                          selectedUnit.unitId,
                          query,
                          setTotalPositions,
                          setPositionLoaderOnNextPage
                        );
                      }
                    }}
                    placeholder="Search…"
                  />
                  <Button
                    disabled={!searchTerm}
                    onClick={() => onSearch()}
                    variant="contained"
                    size="small"
                    disableElevation
                    className="mr-2 "
                    style={{
                      borderRadius: "8px",
                      height: "38px",
                      border: `1px solid rgb(7, 142, 206)`,
                      color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      backgroundColor: "rgb(7, 142, 206)",
                      marginLeft: "5px",
                      "&:hover": {
                        backgroundColor: "#f0f2f5",
                      },
                    }}
                  >
                    <span className="material-icons">search</span>GO
                  </Button>
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{ display: { xs: "flex", md: "flex" } }}
                className="mr-2"
              >
                {hasPermissions(["IS_HR"]) && (
                  <>
                    {!(
                      selectedUnit.unitId === "1903000000-150200000000000000" ||
                      selectedUnit.unitId === "1903000000-150201000000000000"
                    ) && (
                      <Button
                        disabled={!!user?.selectedEntitySector?.isLocked}
                        onClick={() => {
                          if (isEmpty(selectedUnit))
                            return alert("No unit selected.");
                          setShowPositionForm(true);
                        }}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          border: `1px solid rgb(7, 142, 206)`,
                          color: "#fff",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                          backgroundColor: "rgb(7, 142, 206)",
                        }}
                      >
                        <span className="material-icons">add</span>
                        Add position
                      </Button>
                    )}
                    <Button
                      disabled={!!user?.selectedEntitySector?.isLocked}
                      onClick={() => {
                        if (isEmpty(selectedUnit))
                          return alert("No unit selected.");
                        setShowSharedPositionForm(true);
                      }}
                      variant="contained"
                      size="small"
                      disableElevation
                      className="mr-2"
                      style={{
                        borderRadius: "8px",
                        height: "32px",
                        border: `1px solid #0785c0`,
                        color: "#0785c0",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "#eaeade",
                      }}
                    >
                      <span className="material-icons">get_app</span>
                      Pull shared position
                    </Button>
                    <Tooltip title="Download">
                      <span className="mr-2">
                        <IconButton
                          disabled={
                            isDownloading ||
                            !positions.length ||
                            isEmpty(selectedUnit)
                          }
                          className="p-0 mr-1"
                          color="primary"
                          onClick={() =>
                            downloadPositions(
                              selectedUnit.unitId,
                              selectedUnit.unitName,
                              setIsDownloading
                            )
                          }
                        >
                          {isDownloading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ fontSize: "28px" }}
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </>
                          ) : (
                            <span
                              className="material-icons"
                              style={{ fontSize: "28px" }}
                            >
                              cloud_download
                            </span>
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
              </Box>
            </Toolbar>
          </AppBar>

          <div
            style={{
              marginLeft: "1em",
              marginRight: "1em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "55vh",
              overflow: "auto",
            }}
          >
            <CardContent className="pl-0 pt-0 pb-0">
              {!isEmpty(selectedUnit) && (
                <table className="table table-striped table-hover table-sm  fixTableHead">
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col">#</th>
                      <th scope="col">
                        <Box
                          sx={{ display: { xs: "flex", md: "flex" } }}
                          className="align-items-center"
                        >
                          <span className="badge badge-secondary">
                            {totalPositions}
                          </span>{" "}
                          <span className="mx-2"> Positions </span>
                        </Box>
                      </th>
                      <th scope="col" className="text-left">
                        HiringMode
                      </th>
                      <th scope="col" className="text-center">
                        Level
                      </th>
                      <th scope="col" style={{ textAlign: "right" }}>
                        Index
                      </th>
                      <th scope="col" style={{ textAlign: "right" }}>
                        IndexValue
                      </th>

                      <th style={{ textAlign: "right" }} scope="col">
                        BasePay
                      </th>
                      <th scope="col" className="text-center">
                        Employee Group
                      </th>

                      {/* <th scope="col" className="text-center">
                        #Vacants
                      </th> */}

                      <th scope="col" className="text-right">
                        #Posts
                      </th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>

                  {positionLoader ? (
                    <TableSkeleton cols={10} rows={15} />
                  ) : (
                    <tbody>
                      <tr>
                        <td>
                          <BackdropLoader
                            isLoading={!!positionLoaderOnNextPage}
                          />
                        </td>
                      </tr>

                      {[...arrangingPostions(positions)].map(
                        (position, index) => (
                          <PositionCard
                            isEntitySectorLocked={
                              user.selectedEntitySector.isLocked
                            }
                            key={`${position.unitId}-${position.id}`}
                            positionIndex={getNo(position.id, position.unitId)}
                            position={position}
                            selectedUnit={selectedUnit}
                          />
                        )
                      )}
                    </tbody>
                  )}
                </table>
              )}

              {!positions.length && !positionLoader && (
                <div className="jumbotron jumbotron-fluid text-center">
                  <div className="container py-5 ">
                    {!isEmpty(selectedUnit) && (
                      <p className="lead">No record found</p>
                    )}
                    {isEmpty(selectedUnit) && (
                      <p className="lead">Select unit to view positions</p>
                    )}
                  </div>
                </div>
              )}
            </CardContent>

            {showPostionForm && (
              <AddPostionDialog
                showPostionForm={showPostionForm}
                setShowPositionForm={setShowPositionForm}
                selectedUnit={selectedUnit}
              />
            )}

            {showSharedPostionForm && (
              <AddSharedPostionDialog
                showSharedPostionForm={showSharedPostionForm}
                setShowSharedPositionForm={setShowSharedPositionForm}
                selectedUnit={selectedUnit}
              />
            )}
          </div>

          <div
            className="d-flex justify-content-center  align-items-center"
            style={{ overflow: "hidden" }}
          >
            <div className="mt-3">
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                // marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={Math.ceil(totalPositions / itemPerPage)}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                activeLinkClassName="active"
              />
            </div>

            {!!positions.length && (
              <select
                className="custom-select custom-select-sm ml-2"
                style={{ width: "100px" }}
                value={itemPerPage}
                onChange={(event) => {
                  const itemPerPage = +event.target.value;
                  window.localStorage.setItem("ITEM_PER_PAGE", itemPerPage);
                  setItemPerPage(itemPerPage);
                  const query = {
                    searchTerm: searchTerm,
                    page: currentPage,
                    size: itemPerPage,
                  };
                  getUnitPositionsByPagination(
                    selectedUnit.unitId,
                    query,
                    setTotalPositions,
                    setPositionLoaderOnNextPage
                  );
                }}
              >
                {[10, 50, 100].map((item, index) => (
                  <option key={index}>{item}</option>
                ))}
              </select>
            )}
          </div>

          {/* {!isEmpty(selectedUnit) && !user.selectedEntitySector.isLocked && (
            <SpeedDial
              ariaLabel="SpeedDial openIcon example"
              sx={{ position: "absolute", bottom: 65, right: 35 }}
              icon={
                <SpeedDialIcon
                  onClick={() => setShowPositionForm(true)}
                  openIcon={<CloseIcon />}
                />
              }
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() => {
                    if (action.key === 1) {
                      setShowPositionForm(true);
                    }

                    if (action.key === 2) {
                      setShowSharedPositionForm(true);
                    }
                  }}
                />
              ))}
            </SpeedDial>
          )} */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({
  user,
  selectedUnit,
  positions,
  loading,
  searchCurrentPositions,
  calculationMethods,
  basePayrollComponents,
  sharedScopes,
  sourceOfFunds,
  payrollGroups,
  costCenters,
  salaryStructures,
  allowances,
  creditors,
  allowanceTypes,
  supervisors,
  degrees,
  employeeGroups,
  hiringModes,
  levels,
  echelons,
  rraGrades,
  rraDepartments,
}) => {
  return {
    user,
    selectedUnit,
    positions,
    loading,
    searchCurrentPositions,
    calculationMethods,
    basePayrollComponents,
    sharedScopes,
    sourceOfFunds,
    payrollGroups,
    costCenters,
    salaryStructures,
    allowances,
    creditors,
    allowanceTypes,
    degrees,
    supervisors,
    employeeGroups,
    hiringModes,
    levels,
    echelons,
    rraGrades,
    rraDepartments,
  };
};

export default connect(mapStateToProps, {
  getUnitPositionsByPagination,
  getEntitySectorPositions,
  setSearchPositions,
  getSourceOfFunds,
  getPayrollGroups,
  getCostCenters,
  getSalaryStructure,
  getSharedScopes,
  getCalculationMethods,
  getBasePayrollComponents,
  getAllowances,
  getCreditors,
  getAllowanceTypes,
  getDegrees,
  getSupervisors,
  getEmployeeGroups,
  getHiringModes,
  getLevels,
  getEchelons,
  downloadPositions,
  getRRAGrades,
  getRRADepartments,
})(Positions);
