import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Skeleton,
  DialogActions,
  Tooltip,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPositionEmployees } from "../../../store/structure/actions";

import LoadEmployees from "../../employees/components/LoadEmployees";
const PositionEmployeesDialog = (props) => {
  const {
    user,
    loading,
    positionEmployees,
    position,
    showPostionEmployeesModal,
    setShowPostionEmployeesModal,
    getPositionEmployees,
  } = props;
  const [employeeLoader, setEmployeeLoader] = useState(false);
  useEffect(() => {
    getPositionEmployees(position.id, setEmployeeLoader);
  }, []);

  const onClose = () => {
    setShowPostionEmployeesModal(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showPostionEmployeesModal}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle className="text-primary">
          <Typography
            className="text-uppercase"
            variant="h6"
            noWrap
            component="div"
            style={{ maxWidth: "40vw" }}
          >
            <span
              className="text-dark
            "
            >
              ({positionEmployees.length}) Employee
              {positionEmployees.length > 1 && "s"}{" "}
            </span>

            <span> | {position.name}</span>
          </Typography>

          <Tooltip title="Download">
            <span>
              <IconButton
                disabled
                className="p-0"
                color="primary"
                sx={{
                  position: "absolute",
                  right: 54,
                  top: 12,
                }}
              >
                <span className="material-icons" style={{ fontSize: "28px" }}>
                  cloud_download
                </span>
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div
            style={{
              marginLeft: "1em",
              marginRight: "1em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              height: "70vh",
            }}
          >
            {!!positionEmployees.length && (
              <LoadEmployees
                searchTerm=""
                showUnitName={false}
                showActionMenus={false}
                skeletonLoader={employeeLoader}
                employees={positionEmployees}
              />
            )}

            {!positionEmployees.length && !employeeLoader && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container py-5">
                  <p className="lead">No employees found</p>
                </div>
              </div>
            )}

            {employeeLoader && !positionEmployees.length && (
              <div className="container mt-2 px-0" style={{ width: "100%" }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation={false} />
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions className="py-3"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ user, loading, positionEmployees }) => {
  return {
    user,
    loading,
    positionEmployees,
  };
};
export default connect(mapStateToProps, {
  getPositionEmployees,
})(PositionEmployeesDialog);
