import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { connect } from "react-redux";

import {
  CardContent,
  Button,
  IconButton,
  Typography,
  Box,
  Badge,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  getOralExams,
  deleteOralExam,
  onCheckOralExamValidation,
} from "../../../store/recruitment/actions";
import SubjectIcon from "@mui/icons-material/Subject";
import AddScheduleExamDialog from "../components/AddScheduleExamDialog";
import moment from "moment";
import PreLoader from "../../common/components/PreLoader";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import UpdateScheduledExamDialog from "../components/UpdateScheduledExamDialog";
import CancelOralExamDialog from "../components/CancelOralExamDialog";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Attandance from "../components/Attandance";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import OralExamMarking from "../components/OralExamMarking";
import AssessmentIcon from "@mui/icons-material/Iso";
import GroupIcon from "@mui/icons-material/Group";
import Panelist from "../components/Panelist";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { doesExamEnded, doesExamStarted } from "../../common/components/Utils";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Panel from "../components/Panel";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "35px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const ITEM_HEIGHT = 60;

const OralExam = (props) => {
  const {
    user,
    loading,
    oralExams,
    getOralExams,
    selectedAdvertisement,
    deleteOralExam,
    onCheckOralExamValidation,
  } = props;

  const theme = useTheme();
  const { hasPermissions } = useRequiredAuth();

  const [showAddScheduleExamForm, setShowScheduleExamForm] = useState(false);
  const [showUpdateScheduledExamForm, setShowUpdateScheduledExamForm] =
    useState(false);
  const [confirmRemoveOralExam, setConfirmRemoveOralExam] = useState(false);
  const [selectedOralExam, setSelectedOralExam] = useState(null);

  const [showCancelOralExamForm, setShowCancelOralExamForm] = useState(false);
  const [showAttandanceLists, setShowAttandanceLists] = useState(false);
  const [showPanelLists, setShowPanelLists] = useState(false);
  const [showPanels, setShowPanels] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [showOralExamMarking, setShowOralExamMarking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExamDateUnlocked, setIsExamDateUnlocked] = useState(false);

  useEffect(
    () => {
      // getApplicationStatus(selectedAdvertisement.id);
      // if (selectedAdvertisement.canScheduleOralExam)
      getOralExams(selectedAdvertisement.id, setIsLoading);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAdvertisement]
  );

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        {/*  */}
        <div
          className="w-100"
          style={{
            height: "500px",
          }}
        >
          <div
            style={{
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                // padding: ".6em",
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
            >
              <div className="row">
                <div className="col-lg-3 mb-2">
                  <Typography variant="h6" component="div">
                    <span style={{ color: "#2a7790" }}>
                      <SubjectIcon className="text-dark mr-2" />
                    </span>
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      Oral Exam{" "}
                    </span>
                  </Typography>
                </div>
                <div className="col-lg-3 mb-2"></div>
                <div className="col-lg-4 mb-2"></div>
                <div className="col-lg-2 mb-2">
                  <Button
                    disabled={isLoading || loading}
                    onClick={() => {
                      onCheckOralExamValidation(
                        selectedAdvertisement.id,
                        setShowScheduleExamForm,
                        setCurrentDate,
                        setIsExamDateUnlocked,
                        "add"
                      );
                    }}
                    style={{
                      ...InactiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                    }}
                    size="md"
                  >
                    <span className="material-icons">add</span> Schedule Exam
                  </Button>
                </div>
              </div>
            </CardContent>
          </div>

          <div
            className="mt-3"
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
            >
              {!isLoading && !!oralExams.length && (
                <table className="table table-striped table-hover table-sm  fixTableHead">
                  <thead>
                    <tr style={{ backgroundColor: "#f0f2f5" }}>
                      <th scope="col">#</th>
                      <th scope="col" className="text-left">
                        Test Name
                      </th>
                      <th scope="col" className="text-left">
                        Venue
                      </th>
                      <th scope="col" className="text-left">
                        Seating Date
                      </th>
                      {/* <th scope="col" className="text-center">
                        Duration
                      </th> */}

                      <th scope="col" className="text-center">
                        Start Time
                      </th>

                      {/* <th scope="col" className="text-center">
                        End Time
                      </th> */}
                      <th scope="col" className="text-center">
                        Total Marks
                      </th>

                      <th scope="col" className="text-center">
                        Status
                      </th>
                      <th scope="col" className="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {oralExams.map((exam, index) => (
                      <OralExamCard
                        selectedAdvertisement={selectedAdvertisement}
                        exam={exam}
                        index={index}
                        key={index}
                        setShowUpdateScheduledExamForm={
                          setShowUpdateScheduledExamForm
                        }
                        setSelectedOralExam={setSelectedOralExam}
                        setShowCancelOralExamForm={setShowCancelOralExamForm}
                        setShowAttandanceLists={setShowAttandanceLists}
                        setShowOralExamMarking={setShowOralExamMarking}
                        setShowPanelLists={setShowPanelLists}
                        setShowPanels={setShowPanels}
                        onCheckOralExamValidation={onCheckOralExamValidation}
                        setCurrentDate={setCurrentDate}
                        setIsExamDateUnlocked={setIsExamDateUnlocked}
                      />
                    ))}
                  </tbody>
                </table>
              )}

              <NoRecordMessage
                isEmpty={!oralExams.length && !isLoading}
                title="No scheduled oral exam found."
              />

              <PreLoader isLoading={isLoading} />
            </CardContent>
          </div>
        </div>
        {showAddScheduleExamForm && (
          <AddScheduleExamDialog
            examType="Oral"
            advertisement={selectedAdvertisement}
            showAddScheduleExamForm={showAddScheduleExamForm}
            setShowScheduleExamForm={setShowScheduleExamForm}
            currentDate={currentDate}
            isExamDateUnlocked={isExamDateUnlocked}
          />
        )}
        {showUpdateScheduledExamForm && (
          <UpdateScheduledExamDialog
            examType="Oral"
            advertisement={selectedAdvertisement}
            exam={selectedOralExam}
            showUpdateScheduledExamForm={showUpdateScheduledExamForm}
            setShowUpdateScheduledExamForm={setShowUpdateScheduledExamForm}
            currentDate={currentDate}
            isExamDateUnlocked={isExamDateUnlocked}
          />
        )}
        {showCancelOralExamForm && (
          <CancelOralExamDialog
            showCancelOralExamForm={showCancelOralExamForm}
            setShowCancelOralExamForm={setShowCancelOralExamForm}
            selectedOralExam={selectedOralExam}
          />
        )}
        {showAttandanceLists && (
          <Attandance
            showAttandanceLists={showAttandanceLists}
            selectedExam={selectedOralExam}
            examTypeId="2"
            setShowAttandanceLists={setShowAttandanceLists}
          />
        )}
        {showPanelLists && (
          <Panelist
            showPanelLists={showPanelLists}
            selectedExam={selectedOralExam}
            setShowPanelLists={setShowPanelLists}
          />
        )}
        {showPanels && (
          <Panel
            showPanels={showPanels}
            selectedExam={selectedOralExam}
            setShowPanels={setShowPanels}
          />
        )}

        {showOralExamMarking && (
          <OralExamMarking
            exam={selectedOralExam}
            showOralExamMarking={showOralExamMarking}
            setShowOralExamMarking={setShowOralExamMarking}
          />
        )}
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, oralExams }) => {
  return { user, loading, oralExams };
};

export default connect(mapStateToProps, {
  getOralExams,
  deleteOralExam,
  onCheckOralExamValidation,
})(OralExam);

const OralExamCard = (props) => {
  const {
    exam,
    index,
    setShowCancelOralExamForm,
    setSelectedOralExam,
    setShowUpdateScheduledExamForm,
    setShowAttandanceLists,
    setShowOralExamMarking,
    selectedAdvertisement,
    setShowPanelLists,
    setCurrentDate,
    onCheckOralExamValidation,
    setIsExamDateUnlocked,
    setShowPanels,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <td className="text-left">
          {" "}
          {exam.advertisement
            ? exam.advertisement.requisition.position.name
            : ""}
        </td>
        <td className="text-left">{exam.venue}</td>
        <td className="text-left">
          {" "}
          {exam.seatingDate ? moment(exam.seatingDate).format("ll") : ""}
        </td>
        {/* <td className="text-center"> {exam.durationMin} min</td> */}
        <td className="text-center">{exam.startTime}</td>
        {/* <td className="text-center">{exam.endTime}</td> */}
        <td className="text-center">{exam.totalMarks}</td>

        <td className="text-center">
          {exam.isCanceled ? (
            <span className="px-1 py-1 badge  badge-danger">Canceled</span>
          ) : doesExamStarted(exam) ? (
            <span className="px-1 py-1 badge  badge-primary">Exam Started</span>
          ) : exam.wasExamMarkingPublished ? (
            <span className="px-1 py-1 badge  badge-success">
              Exam Marking Published
            </span>
          ) : (
            <span className="px-1 py-1 badge  badge-secondary">Scheduled</span>
          )}
        </td>
        <td className="text-center">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                maxHeight: ITEM_HEIGHT * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedOralExam(exam);
                  setShowPanels(true);
                }}
                className="text-dark font-weight-light"
              >
                <ListAltIcon className="mr-2 text-primary" />
                Panels
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedOralExam(exam);
                  setShowPanelLists(true);
                }}
                className="text-dark font-weight-light"
              >
                <GroupIcon className="mr-2 text-primary" />
                Panel members
              </MenuItem>
              <Divider className="my-1" />
            </span>
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedOralExam(exam);
                  setShowAttandanceLists(true);
                }}
                className="text-dark font-weight-light"
              >
                <PersonAddAlt1Icon className="mr-2 text-primary" />
                Attendance
              </MenuItem>
              <Divider className="my-1" />
            </span>
            <span>
              <MenuItem
                onClick={() => {
                  handleCloseMenu();
                  setSelectedOralExam(exam);
                  setShowOralExamMarking(true);
                }}
                className="text-dark font-weight-light"
              >
                <AssessmentIcon className="mr-2 text-primary" /> Marking
              </MenuItem>
              {!exam.isCanceled && <Divider className="my-1" />}
            </span>

            <span>
              <MenuItem
                disabled={
                  !hasPermissions(["IS_HR"]) ||
                  selectedAdvertisement.adStatusId === 7 ||
                  exam.isCanceled
                }
                onClick={() => {
                  handleCloseMenu();
                  setSelectedOralExam(exam);
                  onCheckOralExamValidation(
                    selectedAdvertisement.id,
                    setShowUpdateScheduledExamForm,
                    setCurrentDate,
                    setIsExamDateUnlocked,
                    "update"
                  );
                }}
                className="text-dark font-weight-light"
              >
                <DriveFileRenameOutlineIcon className="mr-2 text-primary" />{" "}
                Update
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={
                  !hasPermissions(["IS_NPSC", "IS_HR"]) || exam.isCanceled
                }
                onClick={() => {
                  handleCloseMenu();
                  setShowCancelOralExamForm(true);
                  setSelectedOralExam(exam);
                }}
                className="text-danger font-weight-light"
              >
                <CancelOutlinedIcon className="mr-2 text-primary" />
                Cancel
              </MenuItem>
            </span>
          </Menu>
        </td>
      </tr>
    </>
  );
};
