import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getPsychometricLanguages,
  getPositionPsychometricLanguages,
  getPositionKnowledgeSubDomains,
  deletePositionPsychometricLanguage,
  deletePositionKnowledgeSubDomain,
  savePositionPsychometricLanguage,
  savePositionKnowledgeSubDomain,
} from "../../../store/psychometric/actions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import ListSkeleton from "../../common/components/ListSkeleton";
import AddIcon from "@mui/icons-material/Add";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PositionPsychometric = (props) => {
  const {
    user,
    loading,
    onWait,
    position,

    knowledgeSubDomains,
    psychometricLanguages,
    positionPsychometricLanguages,
    positionKnowledgeSubDomains,

    deletePositionPsychometricLanguage,
    deletePositionKnowledgeSubDomain,

    savePositionPsychometricLanguage,
    savePositionKnowledgeSubDomain,

    getPositionPsychometricLanguages,
    getPositionKnowledgeSubDomains,
  } = props;

  useEffect(() => {
    getPositionPsychometricLanguages(position.id);
    getPositionKnowledgeSubDomains(position.id);
  }, [position.id]);

  const [showLanguageForm, setShowLanguageForm] = useState(false);
  const [showDomainForm, setShowDomainForm] = useState(false);

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      {position && (
        <div className="mt-3">
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th className="text-uppercase">Job Level</th>
                <th className="text-uppercase">Knowledge Level</th>
                <th className="text-right text-uppercase">Question pool</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{position.levelName || "N/A"}</td>

                <td>{position.knowledgeLevelName || "N/A"}</td>
                <td className="text-right">{position.questionPool || "0"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="mt-3">
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th colSpan={4}>
                <span className="d-flex justify-content-between text-primary text-uppercase">
                  Psychometric Languages
                  <Button
                    disabled={
                      loading || positionPsychometricLanguages.length === 3
                    }
                    onClick={() => {
                      setShowLanguageForm(true);
                    }}
                    className="my-1"
                    color="primary"
                    variant="contained"
                    style={{
                      // float: "right",
                      borderRadius: "8px",
                      height: "26px",
                      // border: `1px solid #007bff`,
                      color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      // backgroundColor: "#007bff",
                      // "&:hover": {
                      //   backgroundColor: "#f0f2f5",
                      // },
                    }}
                    size="md"
                  >
                    <AddIcon />
                  </Button>
                </span>
              </th>
            </tr>

            {!!positionPsychometricLanguages.length && (
              <tr>
                <th style={{ width: "84px" }}>#</th>
                <th>Language</th>
                <th className="text-right">Question pool</th>
                <th></th>
              </tr>
            )}
          </thead>

          <tbody>
            {!!positionPsychometricLanguages.length ? (
              positionPsychometricLanguages?.map((position, index) => (
                <LanguageCard
                  key={position.id}
                  position={position}
                  index={index}
                  deletePositionPsychometricLanguage={
                    deletePositionPsychometricLanguage
                  }
                />
              ))
            ) : (
              <tr>
                {" "}
                <td colSpan={4} className="text-center">
                  No record
                </td>{" "}
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="mt-3">
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th colSpan={5}>
                <span className="d-flex justify-content-between text-primary text-uppercase">
                  Psychometric Domains
                  <Button
                    disabled={loading}
                    onClick={() => {
                      setShowDomainForm(true);
                    }}
                    className="my-1"
                    color="primary"
                    variant="contained"
                    style={{
                      // float: "right",
                      borderRadius: "8px",
                      height: "26px",
                      // border: `1px solid #007bff`,
                      color: "#fff",
                      alignItems: "center",
                      fontSize: ".75rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "initial",
                      // backgroundColor: "#007bff",
                      // "&:hover": {
                      //   backgroundColor: "#f0f2f5",
                      // },
                    }}
                    size="md"
                  >
                    <AddIcon />
                  </Button>
                </span>
              </th>
            </tr>
            {!!positionKnowledgeSubDomains.length && (
              <tr>
                <th style={{ width: "84px" }}>#</th>
                <th>Domain</th>
                <th>Sub Domain</th>
                <th className="text-right">Question pool</th>
                <th></th>
              </tr>
            )}
          </thead>

          <tbody>
            {!!positionKnowledgeSubDomains.length ? (
              positionKnowledgeSubDomains.map((position, index) => (
                <DomainCard
                  key={position.id}
                  position={position}
                  index={index}
                  deletePositionKnowledgeSubDomain={
                    deletePositionKnowledgeSubDomain
                  }
                />
              ))
            ) : (
              <td colSpan={5} className="text-center">
                No record
              </td>
            )}

            <tr></tr>
          </tbody>
        </table>
      </div>

      {showLanguageForm && (
        <LanguageForm
          user={user}
          loading={loading}
          showDialog={showLanguageForm}
          setShowDialog={setShowLanguageForm}
          position={position}
          psychometricLanguages={psychometricLanguages.filter(
            ({ id }) =>
              !positionPsychometricLanguages.find(
                ({ psychometricLanguageId }) => psychometricLanguageId === id
              )
          )}
          savePositionPsychometricLanguage={savePositionPsychometricLanguage}
        />
      )}

      {showDomainForm && (
        <DomainForm
          user={user}
          loading={loading}
          showDialog={showDomainForm}
          setShowDialog={setShowDomainForm}
          position={position}
          knowledgeSubDomains={knowledgeSubDomains.filter(
            ({ id }) =>
              !positionKnowledgeSubDomains.find(
                ({ knowledgeSubDomainId }) => knowledgeSubDomainId === id
              )
          )}
          savePositionKnowledgeSubDomain={savePositionKnowledgeSubDomain}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  onWait,
  knowledgeSubDomains,
  psychometricLanguages,
  positionPsychometricLanguages,
  positionKnowledgeSubDomains,
}) => {
  return {
    user,
    loading,
    onWait,
    knowledgeSubDomains,
    psychometricLanguages,
    positionPsychometricLanguages,
    positionKnowledgeSubDomains,
  };
};
export default connect(mapStateToProps, {
  getPsychometricLanguages,
  getPositionPsychometricLanguages,
  getPositionKnowledgeSubDomains,
  deletePositionPsychometricLanguage,
  deletePositionKnowledgeSubDomain,
  savePositionPsychometricLanguage,
  savePositionKnowledgeSubDomain,
})(PositionPsychometric);

const LanguageCard = (props) => {
  const { position, index, deletePositionPsychometricLanguage } = props;

  const [confirmRemoveLanguage, setConfirmRemoveLanguage] = useState(false);

  return (
    <>
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{position?.psychometricLanguageName}</td>
        <td className="text-right">{position?.questionPool}</td>
        <td className="text-right" style={{ width: "96px" }}>
          <IconButton
            size="small"
            className="ml-2"
            onClick={() => {
              setConfirmRemoveLanguage(true);
            }}
            aria-label="settings"
          >
            <span className="material-icons text-danger">delete_forever</span>
          </IconButton>
        </td>
      </tr>

      {confirmRemoveLanguage && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveLanguage}
          message={
            <>
              Are you sure you want to{" "}
              <strong className="text-danger">remove</strong> this language?
            </>
          }
          setConfirmationDialog={setConfirmRemoveLanguage}
          onYes={() => {
            deletePositionPsychometricLanguage(position.id, () =>
              setConfirmRemoveLanguage(false)
            );
          }}
        />
      )}
    </>
  );
};

const DomainCard = (props) => {
  const { position, index, deletePositionKnowledgeSubDomain } = props;
  const [confirmRemoveDomain, setConfirmRemoveDomain] = useState(false);

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{position?.knowledgeDomainName}</td>
        <td>{position?.knowledgeSubDomainName}</td>
        <td className="text-right">{position?.questionPool}</td>
        <td className="text-right" style={{ width: "96px" }}>
          {" "}
          <IconButton
            size="small"
            className="ml-2"
            onClick={() => {
              setConfirmRemoveDomain(true);
            }}
            aria-label="settings"
          >
            <span className="material-icons text-danger">delete_forever</span>
          </IconButton>
        </td>
      </tr>

      {confirmRemoveDomain && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveDomain}
          message={
            <>
              Are you sure you want to{" "}
              <strong className="text-danger">remove</strong> this domain?
            </>
          }
          setConfirmationDialog={setConfirmRemoveDomain}
          onYes={() => {
            deletePositionKnowledgeSubDomain(position.id, () =>
              setConfirmRemoveDomain(false)
            );
          }}
        />
      )}
    </>
  );
};

const LanguageForm = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    position,

    psychometricLanguages,
    savePositionPsychometricLanguage,
  } = props;

  const [languages, setLanguages] = useState([]);

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog
      // onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle className="text-primary">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          Add Psychometric Language
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <>
          <Autocomplete
            multiple
            className="mt-2"
            size="small"
            id="language"
            defaultValue={[]}
            value={languages}
            options={psychometricLanguages}
            // disableCloseOnSelect
            onChange={(event, language) => {
              setLanguages(language || []);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <ListItem dense {...props}>
                <ListItemAvatar>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={option.name}
                  secondary={
                    <strong>{option.questionPool + " questions"}</strong>
                  }
                />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select language"
                placeholder="Select language"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center pb-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const payload = [];

            languages.forEach(({ id }) =>
              payload.push({
                id: position.id + "-" + id.toString().padStart(2, "0"),
                positionId: position.id,
                psychometricLanguageId: id,
                createdBy: user.id,
              })
            );

            savePositionPsychometricLanguage({ languages: payload }, onClose);
          }}
          type="button"
          className="px-4"
          disabled={loading || !languages.length}
        >
          {loading ? "Wait..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DomainForm = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    position,
    knowledgeSubDomains,
    savePositionKnowledgeSubDomain,
  } = props;

  const [domains, setDomains] = useState([]);

  const onClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog
      // onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={showDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="text-primary">
        <Typography
          variant="overline"
          className="text-truncate"
          display="block"
          style={{ maxWidth: "90%" }}
        >
          Add Psychometric Domain
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <>
          <Autocomplete
            multiple
            className="mt-3"
            size="small"
            id="language"
            defaultValue={[]}
            value={domains}
            options={knowledgeSubDomains}
            // disableCloseOnSelect
            onChange={(event, domain) => {
              setDomains(domain || []);
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <ListItem dense {...props}>
                <ListItemAvatar>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={option.name}
                  secondary={
                    <>
                      <>{option.knowledgeDomainName}</>
                      {" / "}
                      <strong>{option.questionPool + " questions"}</strong>
                    </>
                  }
                />
              </ListItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select domain"
                placeholder="Select domain"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </>
      </DialogContent>
      <DialogActions className="d-flex justify-content-center py-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const payload = [];

            domains.forEach(({ id }) =>
              payload.push({
                id: position.id + "-" + id.toString().padStart(5, "0"),
                positionId: position.id,
                knowledgeSubDomainId: id,
                createdBy: user.id,
              })
            );

            savePositionKnowledgeSubDomain({ domains: payload }, onClose);
          }}
          type="button"
          className="px-4"
          disabled={loading || !domains.length}
        >
          {loading ? "Wait..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
