import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";

import SearchBox from "../../common/components/SearchBox";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  CardContent,
  Toolbar,
  TextField,
  Autocomplete,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getAppealAdvertisements,
  cancelAdvertisement,
} from "../../../store/recruitment/actions";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import AppealDialog from "./AppealDialog";
import TableSkeleton from "../../common/components/TableSkeleton";

const Appeal = (props) => {
  const { user, loading, appealAdvertisements, getAppealAdvertisements } =
    props;
  const theme = useTheme();
  const [searchTeam, setSearchTeam] = useState("");
  const { hasPermissions } = useRequiredAuth();
  const [selectedAdvertisement, setSelectedAdvertisement] = useState(null);

  const [filterStatus, setFilterStatus] = useState(null);
  const [showAppeal, setShowAppeal] = useState(false);
  const [filteredAdvertisements, setFilteredAdvertisements] = useState([
    ...appealAdvertisements,
  ]);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    getAppealAdvertisements(setShowLoader);
  }, []);

  useEffect(() => {
    doFilterByStatusName();
  }, [filterStatus, appealAdvertisements]);

  const doFilterByStatusName = () => {
    const filterStatusName = filterStatus
      ? filterStatus.name.toString().toLowerCase()
      : null;

    let filtered = [...appealAdvertisements].filter(({ appealStatus }) =>
      appealStatus.toString().toLowerCase().includes(filterStatusName)
    );
    if (filterStatusName === null) filtered = [...appealAdvertisements];

    setFilteredAdvertisements(filtered);
    paginate(filtered);
  };
  const filterAdvertisementStatuses = () => {
    let tempFilteredStatus = [];
    appealAdvertisements.forEach((advertisment) => {
      if (
        !tempFilteredStatus.find(
          ({ name }) => name === advertisment.appealStatus
        )
      )
        tempFilteredStatus.push({
          name: advertisment.appealStatus,
        });
    });

    return tempFilteredStatus;
  };

  useEffect(() => {
    const filtered = [...appealAdvertisements].filter(
      ({ positionName, adStatusName }) =>
        positionName.toLowerCase().includes(searchTeam.toLowerCase()) ||
        adStatusName.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredAdvertisements(filtered);
    paginate(filtered);
  }, [searchTeam, appealAdvertisements]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 10;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(filteredAdvertisements);
  }, [itemOffset, itemsPerPage, appealAdvertisements, filteredAdvertisements]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredAdvertisements.length || 0;

    setItemOffset(newOffset);
  };

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="row">
              <div className="col-lg-6">
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  // sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <span>RECRUITMENT</span>
                  <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                    <span
                      className="material-icons  text-dark"
                      style={{ position: "relative", top: "4px" }}
                    >
                      chevron_right
                    </span>
                  </span>

                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    Appeal Advertisements({filteredAdvertisements.length})
                  </span>
                </Typography>
              </div>

              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 mt-2">
                    <Autocomplete
                      size="small"
                      id="filterStatus"
                      disabled={!appealAdvertisements.length}
                      defaultValue={null}
                      value={filterStatus}
                      options={filterAdvertisementStatuses()}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      onChange={(event, filterStatus) => {
                        setFilterStatus(filterStatus || null);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, filterStatus) => (
                        <Box component="li" {...props} key={filterStatus.name}>
                          {filterStatus.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            width: "100%",
                            height: "35px!important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px!important",
                          }}
                          {...params}
                          label="Filter By"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "filterStatus", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-lg-6 mt-2">
                    <SearchBox onSearch={onSearch} placeholder="Search…" />
                  </div>
                </div>
              </div>
            </Toolbar>
          </AppBar>

          <div
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              margin: "1.2em",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                padding: ".1em",
                height: "auto",
                minHeight: "auto",
                overflow: "auto",
              }}
            >
              <table className="table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>

                    <th scope="col" className="text-left">
                      Title
                    </th>
                    {hasPermissions(["IS_NPSC", "IS_NPSC_APPROVER"]) && (
                      <th scope="col" className="text-left">
                        Institution
                      </th>
                    )}
                    {/* <th scope="col" className="text-left">
                      Unit
                    </th> */}
                    <th scope="col" className="text-center">
                      All appeals
                    </th>
                    <th scope="col" className="text-center">
                      Pending appeals
                    </th>
                    <th scope="col" className="text-center">
                      Responded appeals
                    </th>
                    {!hasPermissions(["IS_NPSC", "IS_NPSC_APPROVER"]) && (
                      <th scope="col" className="text-center">
                        Second level appeals
                      </th>
                    )}

                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      LastAppealedOn
                    </th>
                    <th scope="col" className="text-center"></th>
                  </tr>
                </thead>

                {showLoader ? (
                  <TableSkeleton cols={9} />
                ) : (
                  <tbody>
                    {paginatedItems.map((advertisement, index) => (
                      <AppealCard
                        advertisement={advertisement}
                        setShowAppeal={setShowAppeal}
                        setSelectedAdvertisement={setSelectedAdvertisement}
                        index={index}
                        key={index}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <NoRecordMessage
                isEmpty={!filteredAdvertisements.length && !showLoader}
                title={"No Results found"}
              />

              {showAppeal && (
                <AppealDialog
                  showAppeal={showAppeal}
                  setShowAppeal={setShowAppeal}
                  setSelectedAdvertisement={setSelectedAdvertisement}
                  selectedAdvertisement={selectedAdvertisement}
                />
              )}
            </CardContent>
          </div>

          <div
            className="justify-content-center mb-3"
            style={{ overflow: "hidden" }}
          >
            {!!paginatedItems.length &&
              appealAdvertisements.length >= itemsPerPage && (
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              )}
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, appealAdvertisements }) => {
  return { user, loading, appealAdvertisements };
};
export default connect(mapStateToProps, {
  getAppealAdvertisements,
  cancelAdvertisement,
})(Appeal);

const AppealCard = (props) => {
  const { advertisement, index, setShowAppeal, setSelectedAdvertisement } =
    props;

  const { hasPermissions } = useRequiredAuth();

  return (
    <>
      <tr
        className="appeal-hovered"
        onClick={() => {
          setShowAppeal(true);
          setSelectedAdvertisement(advertisement);
        }}
      >
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>

        <td className="text-left"> {advertisement.positionName}</td>
        {hasPermissions(["IS_NPSC", "IS_NPSC_APPROVER"]) && (
          <th className="text-left">{advertisement.entityName}</th>
        )}
        {/* <td className="text-left"> {advertisement.unitName}</td> */}
        <td className="text-center">
          <span className="p-2 badge badge-secondary">
            {advertisement.totalAppeals}
          </span>
        </td>
        <td className="text-center">
          <span className="p-2 badge badge-warning">
            {advertisement.pendingAppeals}
          </span>
        </td>
        <td className="text-center">
          <span className="p-2 badge badge-success">
            {advertisement.respondedAppeals}
          </span>
        </td>
        {!hasPermissions(["IS_NPSC", "IS_NPSC_APPROVER"]) && (
          <td className="text-center">
            <span className="p-2 badge badge-primary">
              {" "}
              {advertisement.totalSecondLevelAppeals}
            </span>
          </td>
        )}

        <td className="text-center">
          <span
            className={`badge px-1 py-1 badge-${
              advertisement.appealStatus === "Archived"
                ? "success"
                : advertisement.appealStatus === "Inprogress"
                ? "info"
                : "danger"
            }`}
          >
            {advertisement.appealStatus === "Inprogress"
              ? "In progress ..."
              : advertisement.appealStatus}
          </span>
        </td>
        <td className="text-center">{advertisement.lastAppealedOn}</td>

        <td className="text-center">
          <button
            type="button"
            className="btn btn-success m-2"
            onClick={() => {
              setShowAppeal(true);
              setSelectedAdvertisement(advertisement);
            }}
          >
            <OpenInNewIcon /> Open
          </button>
        </td>
      </tr>
    </>
  );
};
