import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import {
  Toolbar,
  Typography,
  Box,
  Button,
  MenuItem,
  IconButton,
  Badge,
  Menu,
} from "@mui/material";
import { getSignedFiles } from "../../../store/e-files/actions";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import NoResults from "../../common/components/NoResults";
import TableSkeleton from "../../common/components/TableSkeleton";
import moment from "moment";
import DownloadFile from "../../common/components/DownloadFile";

const ESign = (props) => {
  const { getSignedFiles, signedFiles, loading } = props;
  const { hasPermissions } = useRequiredAuth();
  useEffect(() => {
    getSignedFiles();
  }, []);
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar
            position="static"
            elevation={0}
            style={{ overflow: "hidden" }}
            className="app-bar w-100"
          >
            <Toolbar className="d-flex align-items-center justify-content-between">
              <Typography
                variant="h6"
                noWrap
                component="div"
                // sx={{ display: { xs: "none", sm: "block" } }}
              >
                <span>Document</span>
                <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
                  <span
                    className="material-icons  text-dark"
                    style={{ position: "relative", top: "4px" }}
                  >
                    chevron_right
                  </span>
                </span>

                <span style={{ color: "rgb(7, 142, 206)" }}>
                  <span style={{ color: "rgb(7, 142, 206)" }}>
                    Signed Documents
                  </span>
                </span>
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
      </Box>
      <div className="card service-card valid mb-2 mt-1">
        <div className="card-body">
          <div className="row">
            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">Name</th>
                  <th scope="col">Owner</th>
                  <th scope="col">Type</th>
                  <th scope="col">Size</th>
                  <th scope="col">Created</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>

              {loading ? (
                <TableSkeleton cols={6} />
              ) : (
                <tbody>
                  {signedFiles.map((document, index) => (
                    <ItemCard
                      key={index}
                      document={document}
                      loading={loading}
                    />
                  ))}
                </tbody>
              )}
            </table>

            {signedFiles.length === 0 && !loading && (
              <div className="col-lg-12">
                <NoResults />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading, signedFiles }) => {
  return { user, loading, signedFiles };
};
export default connect(mapStateToProps, {
  getSignedFiles,
})(ESign);

const ItemCard = (props) => {
  const { document, index, loading } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showAction, setShowAction] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <tr>
        <td>
          <div className="d-flex">
            <span
              className="material-icons-outlined pr-3"
              style={{
                color:
                  document.fileTypeId === 3
                    ? "#FFC300"
                    : document.fileTypeId === 2
                    ? "#16ace9"
                    : "#afc2ca",
              }}
            >
              {document.fileIcon}
            </span>{" "}
            <span className="p-1">{document.fileName}</span>
          </div>
        </td>
        <td>
          <div className="d-flex">
            {" "}
            <span
              className="material-icons-outlined p-0"
              style={{ color: "green", fontSize: "12px" }}
            >
              draw
            </span>
            {document.ownerName}
          </div>
        </td>
        <td>{document.extension}</td>
        <td>{document.size}</td>
        <td>{moment(document.createdOn).format("LLL")}</td>
        <td className="text-right">
          <>
            <IconButton
              size="small"
              className="ml-2"
              onClick={handleOpenMenu}
              // onMouseMove={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "auto",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowAction(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1 p-0">
                    download_for_offline
                  </span>
                  Download
                </MenuItem>
              </span>
            </Menu>
          </>
        </td>
      </tr>
      {showAction && (
        <DownloadFile
          showAction={showAction}
          setShowAction={setShowAction}
          action={`Download ${document.fileName}....`}
          docId={document.id}
          fileName={document.id + "." + document.extension.toLowerCase()}
        />
      )}
    </>
  );
};
