import Educations from "./components/Educations";
import Identity from "./components/Identity";
import Experiences from "./components/Experiences";
import Certificates from "./components/Certificates";
import Languages from "./components/Languages";
import Disabilities from "./components/Disabilities";
import Referees from "./components/Referees";
import ProfileSummary from "./components/ProfileStatus";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CVUpload from "./components/CVUpload";
import Publications from "./components/Publications";
import { connect } from "react-redux";
import defaultProfile from "../assets/default-profile.jpg";
import axios from "axios";
import { useEffect, useState } from "react";
import UserResumeDetail from "./UserResumeDetail";

const UserResume = (props) => {
  const {
    userProfile,
    showProfileDialog,
    setShowProfileDialog,
    canEdit = false,
    allowedToEditEmail = false,
    allowedToEditEmploymentDate = false,
    enabledToViewPhoto=false
  } = props;

  const onClose = () => {
    setShowProfileDialog(false);
  };

  return (
    <>
      <Dialog
        // onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showProfileDialog}
        fullWidth={true}
        maxWidth={"xl"}
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            <span className="text-dark">Current Profile | </span>
            {userProfile && userProfile.lastName}{" "}
            {userProfile && userProfile.firstName}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <UserResumeDetail
            canEdit={canEdit}
            allowedToEditEmail={allowedToEditEmail}
            allowedToEditEmploymentDate={allowedToEditEmploymentDate}
            enabledToViewPhoto={enabledToViewPhoto}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};
const mapStateToProps = ({ userProfile }) => {
  return { userProfile };
};
export default connect(mapStateToProps, {})(UserResume);
