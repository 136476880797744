import {
  IconButton,
  TextField,
  InputAdornment,
  MenuItem,
  Menu,
  Badge,
  Divider,
  Autocomplete,
  Box,
  LinearProgress,
} from "@mui/material";
import {
  findEmployeeDetails,
  unlockTrainingRequestFor30Days,
  getEntitySectors,
  setSecondEmployment,
} from "../../../store/structure/actions";
import { getUserDetails } from "../../../store/profile/actions";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import TableSkeleton from "../../common/components/TableSkeleton";
import UserResume from "../../profile/UserResume";
import { showError } from "../../toastify";
import moment from "moment";
import ChangeEmploymentData from "../../support/components/ChangeEmploymentData";
import AssignEmployeeRole from "../../support/components/AssignEmployeeRole";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import MergeDuplicateUser from "../../support/components/MergeDuplicateUser";

const FindUserDetails = (props) => {
  const {
    loading,
    user,
    searchedUserDetails,
    findEmployeeDetails,
    canVerifyProfile,
    canEditProfile,
    canGiveAccess,
    getUserDetails,
    unlockTrainingRequestFor30Days,
    canTrainingDatesUnlocked = false,
    entitySectors,
    getEntitySectors,
    canEditEndDate,
    canApplyProrata,
    canEditIndexTopUp,
    setSecondEmployment,
  } = props;

  const dispatch = useDispatch();

  const [employeeSearchTerm, setEmployeeSearchTerm] = useState(null);
  const [searchType, setSearchType] = useState(
    "employee name(s),phone number, email or national Id"
  );
  const [isSearching, setIsSearching] = useState(false);
  const [entity, setEntity] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(entitySectors);
  const [setAsSecondEmployment, setSetAsSecondEmployment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: "SET_SEARCHED_USER_DETAILS",
      data: [],
    });
  }, []);
  useEffect(() => {
    if (!entitySectors.length) getEntitySectors();
  }, []);

  const onFind = async (e) => {
    e.preventDefault();

    findEmployeeDetails(
      {
        entitySectorId: entity?.id || null,
        searchKeyword: employeeSearchTerm,
      },
      setIsSearching
    );
  };

  useEffect(() => {
    // Filter options based on the keyword
    const filtered = [...entitySectors].filter(
      (option) =>
        option.entity.name.toLowerCase().includes(keyword.toLowerCase()) ||
        (option.entity.acronym &&
          option.entity.acronym.toLowerCase().includes(keyword.toLowerCase()))
    );
    if (filtered.length === 1) {
      setEntity(filtered[0]);
    }
    setFilteredOptions(filtered);
  }, [keyword, entitySectors]);

  const handleKeywordChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    if (!value) setFilteredOptions(entitySectors);
    else setKeyword(value);
  };

  const handleChangeSecondEmployment = () => {
    if (!setAsSecondEmployment) {
      showError("Please select employee");
      return;
    }
    if (!setAsSecondEmployment.isAvailable) {
      showError("This action is restricted to active employee!");
      return;
    }

    setSecondEmployment(
      {
        employeePositionId: setAsSecondEmployment.employeePositionId,
        isSecondEmployment: !setAsSecondEmployment.isSecondary,
      },
      (status, res) => {
        setIsLoading(status);
        if (!status && res) {
          setSetAsSecondEmployment(null);
        }
      }
    );
  };

  return (
    <>
      <div
        className="elevated rounded p-3"
        style={{
          backgroundColor: "#fafafa",
          border: "1px solid #17a2b8",
          minHeight: "75vh",
        }}
      >
        <form onSubmit={onFind} autoComplete="off">
          <div className="row  mb-3">
            <div className="col-3">
              <Autocomplete
                loading={loading}
                size="small"
                id="entity"
                defaultValue={null}
                value={entity}
                options={filteredOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, entity) => {
                  setEntity(entity || null);
                }}
                getOptionLabel={(option) =>
                  option?.entity.name +
                  (entity?.entity.acronym
                    ? "(" + entity?.entity.acronym + ")"
                    : "")
                }
                renderOption={(props, entity) => (
                  <Box component="li" {...props}>
                    {entity?.entity.name}
                    {entity?.entity.acronym && <>({entity?.entity.acronym})</>}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // required={true}
                    {...params}
                    label="Select the institution"
                    placeholder="Select the institution"
                    onChange={(e) => {
                      handleKeywordChange(e);
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "entity", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <div className="col-12 mb-2">{loading && <LinearProgress />}</div>
            </div>
            <div className="col-7">
              <TextField
                fullWidth
                size="small"
                rows={4}
                name="searchTerm"
                label={`Search by ${searchType}`}
                placeholder={`Search by ${searchType}`}
                variant="outlined"
                className="font-weight-bold mb-2"
                value={employeeSearchTerm || ""}
                onChange={(e) => {
                  setEmployeeSearchTerm(e.target.value || "");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        style={{
                          borderRadius: "0px",
                        }}
                        className={`badge badge-primary`}
                      >
                        {searchType}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-2">
              <button
                disabled={isSearching || !employeeSearchTerm}
                type="submit"
                className="btn btn-primary btn-block text-uppercase ml-1 d-flex align-items-center "
              >
                <span className="material-icons">search</span>
                Search
              </button>
            </div>
          </div>
        </form>

        <table className="table table-striped table-hover table-sm  fixTableHead">
          <thead>
            <tr style={{ backgroundColor: "#f0f2f5" }}>
              <th scope="col">#</th>
              <th scope="col">Employee ID</th>
              <th scope="col">Names</th>
              <th scope="col">National ID</th>
              <th scope="col">Phone number</th>
              <th scope="col">Email</th>
              <th scope="col">Position</th>
              {canEditEndDate && <th scope="col">Employment Date</th>}
              <th scope="col">Institution</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          {isSearching && !searchedUserDetails.length ? (
            <TableSkeleton cols={canEditEndDate ? 11 : 10} />
          ) : (
            <tbody>
              {searchedUserDetails.map((employee, index) => (
                <UserItem
                  key={index}
                  index={index}
                  employee={employee}
                  canVerifyProfile={canVerifyProfile}
                  canEditProfile={canEditProfile}
                  getUserDetails={getUserDetails}
                  canTrainingDatesUnlocked={canTrainingDatesUnlocked}
                  unlockTrainingRequestFor30Days={
                    unlockTrainingRequestFor30Days
                  }
                  canEditEndDate={canEditEndDate}
                  canApplyProrata={canApplyProrata}
                  canEditIndexTopUp={canEditIndexTopUp}
                  setSetAsSecondEmployment={setSetAsSecondEmployment}
                  employeeSearchTerm={employeeSearchTerm}
                  setIsSearching={setIsSearching}
                />
              ))}
            </tbody>
          )}
        </table>
        {!searchedUserDetails.length && !isSearching && (
          <div className="jumbotron jumbotron-fluid text-center  mt-0">
            <div className="container py-5">
              <p className="lead">No record found</p>
            </div>
          </div>
        )}
      </div>

      {!!setAsSecondEmployment && (
        <ConfirmationDialog
          confirmationDialog={true}
          disabled={isLoading}
          message={`Are you sure you want to set as ${
            setSetAsSecondEmployment.isSecondary ? "first" : "second"
          } employment?`}
          setConfirmationDialog={() => setSetAsSecondEmployment(null)}
          onYes={handleChangeSecondEmployment}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  loading,
  user,
  searchedUserDetails,
  entitySectors,
}) => {
  return {
    loading,
    user,
    searchedUserDetails,
    entitySectors,
  };
};
export default connect(mapStateToProps, {
  findEmployeeDetails,
  getUserDetails,
  unlockTrainingRequestFor30Days,
  getEntitySectors,
  setSecondEmployment,
})(FindUserDetails);

const UserItem = ({
  index,
  employee,
  canEditProfile,
  loading,
  getUserDetails,
  canTrainingDatesUnlocked,
  unlockTrainingRequestFor30Days,
  canEditEndDate,
  canApplyProrata,
  canEditIndexTopUp,
  setSetAsSecondEmployment,
  employeeSearchTerm,
  setIsSearching,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermissions } = useRequiredAuth();

  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showEmployeeProfileDialog, setShowEmployeeProfileDialog] =
    useState(false);
  const [showMergeDuplicateUserDialog, setShowMergeDuplicateUserDialog] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [takeAction, setTakeAction] = useState(null);

  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowEmployeeProfileDialog(true);
  };

  return (
    <>
      <tr key={index}>
        <th scope="row" style={{ backgroundColor: "#f0f2f5" }}>
          {index + 1}
        </th>
        <td> {employee.id}</td>
        <td>
          {employee.lastName} {employee.firstName}
        </td>
        <td>{employee.idNumber}</td>

        <td>{employee.phoneNumber}</td>
        <td>{employee.email}</td>
        <td>
          {employee?.positionName || "N/A"}{" "}
          {!!employee.isEmployee && !!employee.isAvailable && (
            <>
              {" "}
              <strong className="badge badge-primary">CurrentActive</strong>
            </>
          )}
        </td>
        {canEditEndDate && (
          <td>
            {!!employee?.startDate
              ? moment(employee?.startDate).format("L")
              : "N/A"}{" "}
            -{" "}
            {!!employee?.endDate
              ? moment(employee?.endDate).format("L")
              : "Present"}
          </td>
        )}
        <td>{employee?.entityName || "N/A"}</td>
        <td>
          {!!employee.isEmployee ? (
            <>
              {!!employee.isAvailable ? (
                <>
                  <strong className="badge badge-success float-right">
                    In Service
                  </strong>
                </>
              ) : (
                <>
                  <strong className="badge badge-danger float-right">
                    Not in Service
                  </strong>
                </>
              )}
              {!!employee.isAvailable && !!employee.isTrainingDatesUnlocked && (
                <>
                  <br />
                  <strong className="text-primary ml-2">
                    The training request unlocked for 30 days
                  </strong>
                </>
              )}
            </>
          ) : (
            <>
              <strong className="badge badge-warning float-right">
                Not Employee
              </strong>
            </>
          )}
        </td>
        <td>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <span>
              <MenuItem
                onClick={() => {
                  getUserDetails(employee.id, handleOpenCurrentProfile);
                }}
                className="text-primary font-weight-light"
              >
                <span className="material-icons mr-1">account_circle</span>
                {loading ? "Wait...." : "Currently Profile"}
              </MenuItem>
            </span>

            {!!employee.isEmployee &&
              !!employee.isAvailable &&
              !!canTrainingDatesUnlocked && (
                <>
                  <Divider className="my-1" />
                  <span>
                    <MenuItem
                      onClick={() => {
                        if (!employee.isAvailable) {
                          showError("This employee is not currently available");
                        } else {
                          unlockTrainingRequestFor30Days(
                            {
                              id: employee.id,
                              isTrainingDatesUnlocked:
                                !employee.isTrainingDatesUnlocked,
                            },
                            handleCloseMenu,
                            setIsLoading
                          );
                        }
                      }}
                      className="text-dark font-weight-light"
                    >
                      {!!employee.isTrainingDatesUnlocked ? (
                        <>
                          <span className="fas fa-lock text-success mr-1"></span>{" "}
                          {isLoading
                            ? "Wait...."
                            : "Lock Training Request for 30 days"}
                        </>
                      ) : (
                        <>
                          <span className="fas fa-unlock text-danger mr-1"></span>{" "}
                          {isLoading
                            ? "Wait...."
                            : "Unlock Training Request for 30 days"}
                        </>
                      )}
                    </MenuItem>
                  </span>
                </>
              )}

            {!!canEditEndDate && hasPermissions(["IS_SUPPORT", "IS_ADMIN","IS_SUPPORTER"]) && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  onClick={() => {
                    setTakeAction("change-end-date");
                    handleCloseMenu();
                  }}
                  className="text-primary font-weight-light"
                >
                  <span className="material-icons-round mr-1">
                    border_color
                  </span>
                  Employment Date
                </MenuItem>
              </span>
            )}

            {!!canEditIndexTopUp &&
              hasPermissions(["IS_SUPPORT", "IS_ADMIN","IS_SUPPORTER"]) && (
                <span>
                  <Divider className="my-1" />
                  <MenuItem
                    onClick={() => {
                      setTakeAction("apply-index-topup");
                      handleCloseMenu();
                    }}
                    className="text-primary font-weight-light"
                  >
                    <span className="material-icons-round mr-1">
                      auto_graph
                    </span>
                    Apply Index Topup
                  </MenuItem>
                </span>
              )}

            {!!canEditIndexTopUp &&
              !!employee.isAvailable &&
              hasPermissions(["IS_SUPPORT", "IS_ADMIN","IS_SUPPORTER"]) && (
                <span>
                  <Divider className="my-1" />
                  <MenuItem
                    onClick={() => {
                      setTakeAction("assign-role");
                      handleCloseMenu();
                    }}
                    className="text-primary font-weight-light"
                  >
                    <span className="material-icons-round mr-1">
                      admin_panel_settings
                    </span>
                    Assign Role
                  </MenuItem>
                </span>
              )}

            {!!canApplyProrata &&
              hasPermissions(["IS_SUPPORT", "IS_ADMIN","IS_SUPPORTER"]) && (
                <span>
                  <Divider className="my-1" />
                  <MenuItem
                    onClick={() => {
                      setTakeAction("apply-prorata");
                      handleCloseMenu();
                    }}
                    className="text-primary font-weight-light"
                  >
                    <span className="material-icons-round mr-1">edit_note</span>
                    Apply Prorata
                  </MenuItem>
                </span>
              )}

            {!!employee.isAvailable &&
              hasPermissions(["IS_SUPPORT", "IS_ADMIN","IS_SUPPORTER"]) && (
                <span>
                  <Divider className="my-1" />
                  <MenuItem
                    onClick={() => {
                      setSetAsSecondEmployment(employee);
                      handleCloseMenu();
                    }}
                    className={`${
                      employee.isSecondary ? "text-warning " : "text-dark"
                    } font-weight-light`}
                  >
                    <span className="material-icons-round mr-1">work</span>
                    {employee.isSecondary ? "Unset " : "Set"} As Second
                    Employment
                  </MenuItem>
                </span>
              )}

            {!!canEditProfile && hasPermissions(["IS_SUPPORT", "IS_ADMIN","IS_SUPPORTER"]) && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                  }}
                  className="text-danger font-weight-light"
                >
                  <span className="material-icons mr-1">delete</span>
                  Delete
                </MenuItem>
              </span>
            )}

            {hasPermissions(["IS_SUPPORT", "IS_ADMIN","IS_SUPPORTER"]) && (
              <span>
                <Divider className="my-1" />
                <MenuItem
                  onClick={() => {
                    setShowMergeDuplicateUserDialog(true);
                    handleCloseMenu();
                  }}
                  className="text-warning font-weight-light"
                >
                  <span className="material-icons mr-1">merge</span>
                  Merge duplicated user
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>

      {showEmployeeProfileDialog && (
        <UserResume
          showProfileDialog={showEmployeeProfileDialog}
          setShowProfileDialog={setShowEmployeeProfileDialog}
          canEdit={canEditProfile}
          allowedToEditEmail={canEditProfile}
          allowedToEditEmploymentDate={false}
        />
      )}

      {!!takeAction && takeAction !== "assign-role" && (
        <ChangeEmploymentData
          actionData={{ employee, action: takeAction }}
          setActionData={setTakeAction}
        />
      )}

      {takeAction === "assign-role" && (
        <AssignEmployeeRole
          actionData={{ employee, action: takeAction }}
          setActionData={setTakeAction}
        />
      )}
      {showMergeDuplicateUserDialog && (
        <MergeDuplicateUser
          showMergeDuplicateUserDialog={showMergeDuplicateUserDialog}
          setShowMergeDuplicateUserDialog={setShowMergeDuplicateUserDialog}
          theRealUserId={employee.id}
          employeeSearchTerm={employeeSearchTerm}
          setIsSearching={setIsSearching}
        />
      )}
    </>
  );
};
