import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert,
  Button,
  Card,
  CardContent,
  DialogActions,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { updateTinNumber } from "../../../store/payroll/actions";

const EditTinDialog = (props) => {
  const {
    loading,
    showDialog,
    setShowDialog,
    payroll,
    updateTinNumber,
    viewType,
    type,
    query,
  } = props;

  const onClose = () => {
    setShowDialog(false);
  };

  const [formData, setFormData] = useState({
    id: payroll.costCenterId,
    name: payroll.costCenterName,
    tinNumber: payroll.tinNumber,
  });

  const [errors, setErrors] = useState({
    tinNumberHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      tinNumberHasError: false,
    };

    if (!formData.tinNumber) {
      error.tinNumberHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator()) {
      updateTinNumber(
        {
          query,
          payload: {
            id: formData.id,
            tinNumber: formData.tinNumber,
            type,
          },
        },
        () => setShowDialog(false)
      );
    }
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        fullWidth
        maxWidth="xs"
        open={showDialog}
      >
        <DialogTitle className=" ">
          <Typography>Update TIN Number</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="row mt-1">
            <div className="col-12">
              <TextField
                style={{ backgroundColor: "#eee" }}
                fullWidth
                size="small"
                name="costCenter"
                label="Cost Center"
                variant="outlined"
                placeholder="Cost Center"
                value={formData.name}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                fullWidth
                size="small"
                autoFocus
                name="tinNumber"
                label="TIN Number"
                variant="outlined"
                placeholder="TIN Number"
                value={formData.tinNumber}
                // InputProps={{
                //   readOnly: type !== "Temp",
                // }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    tinNumber: e.target.value || "",
                  });
                  setErrors({ ...errors, tinNumberHasError: false });
                }}
              />

              {errors.tinNumberHasError && (
                <small className="text-danger">TIN Number is required </small>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          {/* {viewType !== "admin" && ( */}
          <div className="text-center">
            <Button
              className="px-5"
              variant="contained"
              color="primary"
              disabled={
                loading ||
                // type !== "Temp" ||
                formData.tinNumber === payroll.tinNumber ||
                !formData.tinNumber
              }
              onClick={onSave}
            >
              {loading ? "WAIT..." : "SAVE"}
            </Button>
          </div>
          {/* )} */}
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  updateTinNumber,
})(EditTinDialog);
