import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { saveOrUpdateRole } from "../../../store/admin/actions";
import { showError } from "../../../app/toastify";

const AddOrUpdateRoleDialog = (props) => {
  const {
    loading,
    isEditing,
    setIsEditing,
    showRoleForm,
    setShowRoleForm,
    selectedRole,
    saveOrUpdateRole,
  } = props;

  const [formData, setFormData] = useState({
    id: null,
    name: "",
  });

  const [errors, setErrors] = useState({
    nameHasError: false,
  });

  useEffect(() => {
    if (isEditing)
      setFormData({
        id: selectedRole.id,
        name: selectedRole.name,
      });
    else
      setFormData({
        id: "",
        name: "",
      });
  }, [selectedRole, isEditing]);

  const formValidator = () => {
    const error = {
      nameHasError: false,
      hasError: false,
    };

    if (!formData.name) {
      error.nameHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (isEditing && selectedRole && selectedRole.name === formData.name) {
      showError("No update yet");
      return false;
    }
    if (!formValidator()) {
      saveOrUpdateRole(
        formData,
        setFormData,
        setIsEditing,
        setShowRoleForm,
        isEditing
      );
    }
  };

  const onClose = () => {
    setShowRoleForm(false);
    setIsEditing(false);

    setFormData({
      id: "",
      name: "",
    });
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showRoleForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {isEditing ? "Edit" : "Add New"} Role
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            size="small"
            rows={4}
            name="name"
            autoFocus
            label="role"
            variant="outlined"
            placeholder="Role"
            className="mb-3"
            value={formData.name}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            onChange={(e) => {
              const name = e.target.value;
              const id = isEditing
                ? selectedRole.id
                : name.toUpperCase().replace(/ /g, "_");
              const nameHasError = false;
              setErrors({ ...errors, nameHasError });
              setFormData({ ...formData, name, id });
            }}
          />
          {errors.nameHasError && (
            <div className="text-danger mb-2">Role is required </div>
          )}

          {formData.id && <span> ID: {formData.id} </span>}
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  saveOrUpdateRole,
})(AddOrUpdateRoleDialog);
