import React, { useState } from "react";
import {
  Chip,
  ListItem,
  Divider,
  ListItemText,
  Tooltip,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import PaidIcon from "@mui/icons-material/Paid";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArchivePayrollDialog from "./ArchivePayrollDialog";
import CancelPayrollDialog from "./CancelPayrollDialog";

import {
  doSendToEFMIS,
  markPayrollAsPaid,
  downloadPDFBankTransfer,
  downloadPDFDeductions,
  getPayrollSupportingDocuments,
  resendToBRD,
} from "../../../store/payroll/actions";
import { connect } from "react-redux";

import moment from "moment";
import PayrollDocIdsDialog from "./PayrollDocIdsDialog";
import PayrollDeclarationsDialog from "./PayrollDeclarationsDialog";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import { currencyFormat } from "../../common/components/Utils";
import PayrollPreviewDialog from "./PayrollPreviewDialog";
import UnArchivePayrollDialog from "./UnArchivePayrollDialog";
import PayrollSupportingDocsDialog from "./PayrollSupportingDocsDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import axios from "axios";
import EditTinDialog from "./EditTinDialog";

const PayrollCard = (props) => {
  const {
    loading,
    type,
    payroll,
    payrollIndex,
    doSendToEFMIS,
    markPayrollAsPaid,
    downloadPDFBankTransfer,
    downloadPDFDeductions,
    viewType,
    getPayrollSupportingDocuments,
    env,
    resendToBRD,
    query,
  } = props;

  const [showDoPayrollArchiveForm, setShowDoPayrollArchiveForm] =
    useState(false);

  const [showMarkAsPaidDialog, setShowMarkAsPaidDialog] = useState(false);

  const [showCancelPayrollForm, setShowCancelPayrollForm] = useState(false);
  const [showUnArchivePayrollDialog, setShowUnArchivePayrollDialog] =
    useState(false);

  const [selectedPayroll, setSelectedPayroll] = useState(null);
  const [showPayroll, setShowPayroll] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // const sendToEFMIS = (payroll) => {

  // };

  const [showDocIds, setShowDocIds] = useState(false);
  const [showDeclarations, setShowDeclarations] = useState(false);
  const [showConfirmSendToIFMIS, setShowConfirmSendToIFMIS] = useState(false);
  const [showSupportingDocsDialog, setShowSupportingDocsDialog] =
    useState(false);

  const [showEditTinDialog, setShowEditTinDialog] = useState(false);

  const { hasPermissions } = useRequiredAuth();

  const [docIds, setDocIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getPayrollDocIds = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        "/api/payroll/docids/" + type + "/" + payroll.id
      );
      setIsLoading(false);

      setDocIds(data);

      setShowDocIds(true);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [showConfirmSendToBRD, setShowConfirmSendToBRD] = useState(false);

  return (
    <>
      <tr>
        <td>{payrollIndex}</td>
        {viewType !== "admin" && (
          <td className="text-right">
            <span>{payroll.id}</span>
            {/* <br />
            <small>RSSB Declarations sent</small> */}
          </td>
        )}
        {viewType === "admin" && (
          <td>
            <span className="d-block">{payroll.entityName}</span>
            <span className="badge badge-info">{payroll.sectorName}</span>
          </td>
        )}
        <td
          style={{
            backgroundColor: "#e5f6fd",
            borderTop: "none",
            borderLeft: "1px solid #dee2e6",
            width: "58px",
          }}
        >
          {
            <IconButton
              size="small"
              color="inherit"
              className={`ml-2 rssb-btn text-${
                !!payroll.hasDocIds ? "primary" : "danger"
              }`}
              aria-label="settings"
              onClick={() => {
                getPayrollDocIds();
              }}
            >
              <Badge color="info">
                {!!payroll.hasDocIds && (
                  <span className="material-icons">link</span>
                )}
                {!payroll.hasDocIds && (
                  <span className="material-icons">link_off</span>
                )}
              </Badge>
            </IconButton>
          }
        </td>
        <td
          style={{
            backgroundColor: "#e5f6fd",
            borderTop: "none",
            borderRight: "1px solid #dee2e6",
            width: "58px",
          }}
        >
          <IconButton
            disabled={loading}
            size="small"
            color="inherit"
            className={`ml-2 rssb-btn text-${
              payroll.isDeclared ? "primary" : "danger"
            }`}
            aria-label="settings"
            onClick={() => {
              setShowDeclarations(true);
            }}
          >
            <Badge color="info">
              {payroll.isDeclared && (
                <span className="material-icons">check_box</span>
              )}

              {!payroll.isDeclared && (
                <span
                  className="material-icons"
                  style={{ transform: "rotate(180deg)" }}
                >
                  file_download_off
                </span>
              )}
            </Badge>
          </IconButton>
        </td>
        <td>
          <Tooltip title="Click to edit">
            <span
              className="btn btn-link btn-sm"
              onClick={() => setShowEditTinDialog(true)}
            >
              {payroll.tinNumber}
            </span>
          </Tooltip>
        </td>

        <td>{payroll.periodName}</td>

        <td>
          {payroll.payrollTypeName}{" "}
          {!!payroll.arrearTypeName ? `(${payroll.arrearTypeName})` : ""}
        </td>

        <td>{payroll.costCenterName}</td>
        <td>{payroll.payrollGroupName}</td>
        <td>{payroll.sourceOfFundName}</td>
        <td className="text-right">
          {currencyFormat(payroll.totalSalary * 1.0)}
        </td>
        <td className="text-right">{currencyFormat(payroll.lumpsum * 1.0)}</td>

        <td className="text-right">
          {currencyFormat(payroll.totalPaid * 1.0)}
        </td>
        {viewType !== "admin" && <td>{payroll.opNumber}</td>}
        <td>
          {!!payroll.lastProcessingTime
            ? moment(payroll.lastProcessingTime).format("yyyy-MM-DD HH:mm:ss")
            : ""}
        </td>

        <td className="text-center">
          <span className="bdage badge-secondary px-1">
            {payroll.numberOfEmployees}
          </span>
        </td>

        <td className="text-center">
          {payroll.statusName && (
            <span
              className={`badge py-1 ${
                payroll.statusId.includes("REJECTED") ||
                payroll.statusId.includes("CANCEL")
                  ? "badge-danger"
                  : payroll.statusId === "GENERATED"
                  ? "badge-pill"
                  : payroll.statusId === "VERIFIED"
                  ? "badge-secondary"
                  : payroll.statusId === "REGISTERED" ||
                    payroll.statusId === "SENT_TO_TREASURY" ||
                    payroll.statusId === "VISA"
                  ? "badge-primary"
                  : payroll.statusId === "APPROVED_LEVEL_1" ||
                    payroll.statusId === "APPROVED_LEVEL_2" ||
                    payroll.statusId === "RECEIVED_BY_BANK" ||
                    payroll.statusId === "SEND_TO_BANK" ||
                    payroll.statusId === "SIGNED_AS_PAID"
                  ? "badge-success"
                  : "badge-info"
              }`}
              style={{ width: "96px" }}
            >
              {payroll.statusName}
            </span>
          )}
          {!payroll.statusName && (
            <span
              className={`badge py-1 badge-primary`}
              style={{ width: "96px" }}
            >
              Processing...
            </span>
          )}{" "}
        </td>

        <td>
          <IconButton
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedPayroll(payroll);
                  setShowPayroll(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">visibility</span>
                Preview
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    getPayrollSupportingDocuments(
                      payroll.id,
                      type,
                      handleCloseMenu,
                      setShowSupportingDocsDialog
                    );
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">description</span>{" "}
                  Supporting Docs
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {type === "Temp" && viewType !== "admin" && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();

                    setShowConfirmSendToIFMIS(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span
                    style={{ transform: "rotateY(180deg)" }}
                    className="material-icons mr-1"
                  >
                    reply_all
                  </span>{" "}
                  Resend to IFMIS
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  setShowDeclarations(true);
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">content_paste</span>
                Declarations
              </MenuItem>
              <Divider className="my-1" />
            </span>

            {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    downloadPDFBankTransfer(payroll.id, type, handleCloseMenu);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">file_download</span>{" "}
                  Bank transfers
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {hasPermissions(["IS_HR", "IS_DAF", "IS_CBM"]) && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    downloadPDFDeductions(payroll.id, type, handleCloseMenu);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span className="material-icons mr-1">file_download</span>{" "}
                  Deductions
                </MenuItem>
                {viewType !== "admin" && <Divider className="my-1" />}
              </span>
            )}

            {type === "Temp" && viewType !== "admin" && (
              <span>
                <MenuItem
                  disabled={loading || payroll.statusId === "APPROVED_LEVEL_2"}
                  onClick={() => {
                    handleCloseMenu();
                    // setSelectedPayroll(payroll);
                    setShowMarkAsPaidDialog(true);
                  }}
                  className="text-success font-weight-light"
                >
                  <span className="material-icons mr-1">check_box</span> Mark as
                  Paid
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {type === "Temp" &&
              viewType !== "admin" &&
              hasPermissions(["IS_HR", "IS_CBM"]) && (
                <span>
                  <MenuItem
                    disabled={loading}
                    onClick={() => {
                      handleCloseMenu();
                      setSelectedPayroll(payroll);
                      setShowDoPayrollArchiveForm(true);
                    }}
                    className="text-primary font-weight-light"
                  >
                    <span className="material-icons mr-1">archive</span> Archive
                  </MenuItem>
                  <Divider className="my-1" />
                </span>
              )}

            {type === "Temp" &&
              hasPermissions(["IS_HR", "IS_DAF", "IS_CBM", "IS_ADMIN"]) && (
                <span>
                  <MenuItem
                    disabled={loading}
                    onClick={() => {
                      handleCloseMenu();
                      setSelectedPayroll(payroll);
                      setShowCancelPayrollForm(true);
                    }}
                    className="text-danger font-weight-light"
                  >
                    <span className="material-icons mr-1">highlight_off</span>{" "}
                    Cancel
                  </MenuItem>
                </span>
              )}

            {type === "Archived" && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setShowConfirmSendToBRD(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <span
                    // style={{ transform: "rotateY(180deg)" }}
                    className="material-icons mr-1"
                  >
                    cast_for_education
                  </span>
                  Resend to BRD
                </MenuItem>
                <Divider className="my-1" />
              </span>
            )}

            {type === "Archived" && hasPermissions(["IS_CBM"]) && (
              <span>
                <MenuItem
                  disabled={loading}
                  onClick={() => {
                    handleCloseMenu();
                    setSelectedPayroll(payroll);
                    setShowUnArchivePayrollDialog(true);
                  }}
                  className="text-danger font-weight-light"
                >
                  <span className="material-icons mr-1">unarchive</span>{" "}
                  Unarchive
                </MenuItem>
              </span>
            )}
          </Menu>
        </td>
      </tr>

      {showEditTinDialog && (
        <EditTinDialog
          showDialog={showEditTinDialog}
          setShowDialog={setShowEditTinDialog}
          payroll={payroll}
          viewType={viewType}
          type={type}
          query={query}
        />
      )}

      {showDocIds && (
        <PayrollDocIdsDialog
          showDocIds={showDocIds}
          setShowDocIds={setShowDocIds}
          payroll={payroll}
          docIds={docIds}
          viewType={viewType}
          type={type}
          getPayrollDocIds={getPayrollDocIds}
        />
      )}

      {showSupportingDocsDialog && (
        <PayrollSupportingDocsDialog
          showSupportingDocsDialog={showSupportingDocsDialog}
          setShowSupportingDocsDialog={setShowSupportingDocsDialog}
          payroll={payroll}
          viewType={viewType}
          type={type}
        />
      )}

      {showDeclarations && (
        <PayrollDeclarationsDialog
          showDeclarations={showDeclarations}
          setShowDeclarations={setShowDeclarations}
          payroll={payroll}
          viewType={viewType}
          type={type}
        />
      )}

      {showPayroll && (
        <PayrollPreviewDialog
          showPayroll={showPayroll}
          setShowPayroll={setShowPayroll}
          selectedPayroll={payroll}
          type={type}
          viewType={viewType}
        />
      )}

      {showDoPayrollArchiveForm && (
        <ArchivePayrollDialog
          selectedPayroll={payroll}
          showDoPayrollArchiveForm={showDoPayrollArchiveForm}
          setShowDoPayrollArchiveForm={setShowDoPayrollArchiveForm}
        />
      )}

      {showUnArchivePayrollDialog && (
        <UnArchivePayrollDialog
          selectedPayroll={payroll}
          showUnArchivePayrollDialog={showUnArchivePayrollDialog}
          setShowUnArchivePayrollDialog={setShowUnArchivePayrollDialog}
        />
      )}

      {showCancelPayrollForm && (
        <CancelPayrollDialog
          selectedPayroll={payroll}
          showCancelPayrollForm={showCancelPayrollForm}
          setShowCancelPayrollForm={setShowCancelPayrollForm}
        />
      )}

      {showConfirmSendToIFMIS && (
        <ConfirmationDialog
          confirmationDialog={showConfirmSendToIFMIS}
          message={`Are you sure you want to resend this payroll to IFMIS?`}
          setConfirmationDialog={setShowConfirmSendToIFMIS}
          onYes={() => {
            doSendToEFMIS(payroll.id, setShowConfirmSendToIFMIS);
          }}
        />
      )}

      {showMarkAsPaidDialog && (
        <ConfirmationDialog
          btnMessage={"mark as paid"}
          confirmationDialog={showMarkAsPaidDialog}
          message={`Are you sure you want to mark this payroll as paid?`}
          setConfirmationDialog={setShowMarkAsPaidDialog}
          onYes={() => {
            markPayrollAsPaid(payroll.id, setShowMarkAsPaidDialog);
          }}
        />
      )}

      {showConfirmSendToBRD && (
        <ConfirmationDialog
          confirmationDialog={showConfirmSendToBRD}
          message={`Are you sure you want to resend BRD payments?`}
          setConfirmationDialog={setShowConfirmSendToBRD}
          onYes={() => {
            resendToBRD(payroll.id, () => setShowConfirmSendToBRD(false));
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, env }) => {
  return {
    loading,
    env,
  };
};
export default connect(mapStateToProps, {
  doSendToEFMIS,
  markPayrollAsPaid,
  downloadPDFBankTransfer,
  downloadPDFDeductions,
  getPayrollSupportingDocuments,
  resendToBRD,
})(PayrollCard);
