import { Dialog, Box, CircularProgress, DialogContent } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { downloadPaySlip } from "../../../store/payroll/actions";

const DownloadPaySlipDialog = (props) => {
  const {
    loading,
    showDownloadPaySlip,
    setShowDownloadPaySlip,
    setSelectedPayHistory,
    selectedPayHistory,
    downloadPaySlip,
  } = props;

  // console.log(previewEmployeePaySlip);
  useEffect(() => {
    downloadPaySlip(
      selectedPayHistory.payrollId,
      selectedPayHistory.employeeId,
      onClose
    );
  }, [selectedPayHistory]);

  const onClose = () => {
    setShowDownloadPaySlip(false);
    // setSelectedPayHistory(null);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDownloadPaySlip}
        maxWidth="sm"
      >
        <DialogContent>
          <div>
            {loading && (
              <Box className="justify-content-center " sx={{ display: "flex" }}>
                <CircularProgress />
                <h5 className="mt-2 ml-2">Downloading ...</h5>
              </Box>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading }) => {
  return {
    loading,
  };
};
export default connect(mapStateToProps, {
  downloadPaySlip,
})(DownloadPaySlipDialog);
