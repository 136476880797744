import React, { useState, useEffect, useCallback } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { alpha, styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import {
  getTreeUnits,
  deleteUnit,
  setSearchUnit,
  getUnitTypes,
} from "../../../store/structure/actions";
import {
  getCalculationMethods,
  getBasePayrollComponents,
  getCreditors,
} from "../../../store/payroll/actions";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import {
  IconButton,
  Tooltip,
  Chip,
  Menu,
  MenuItem,
  Divider,
  Badge,
} from "@mui/material";
import AddOrUpdateUnitDialog from "../components/AddOrUpdateUnitDialog";
import SearchBox from "../../common/components/SearchBox";
import AppBar from "@mui/material/AppBar";
import { Typography, Box, Toolbar } from "@mui/material";
import UnitAllowances from "../components/UnitAllowances";
import UnitDeductions from "../components/UnitDeductions";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import SetPlanningEntityDialog from "../components/SetPlanningEntityDialog";

const Units = (props) => {
  useEffect(() => {
    document.title = "IPPIS - Org. Structure | Units";
  });

  const {
    loading,
    user,
    units,
    getTreeUnits,
    treeUnits,
    deleteUnit,
    setSearchUnit,
    creditors,
    basePayrollComponents,
    calculationMethods,
    getCalculationMethods,
    getBasePayrollComponents,
    getCreditors,
    getUnitTypes,
    unitTypes,
  } = props;

  const [expandedNodes, setExpandedNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState("");
  const [parentUnit, setParentUnit] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [showUnitForm, setShowUnitForm] = useState(false);
  const [showPlanningForm, setShowPlanningForm] = useState(false);
  const [confirmRemoveUnit, setConfirmRemoveUnit] = useState(false);
  const [unitToBeRemoved, setUnitToBeRemoved] = useState(null);
  const [showUnitAllowanceDialog, setShowUnitAllowanceDialog] = useState(false);
  const [showUnitDeductionDialog, setShowUnitDeductionDialog] = useState(false);
  const [refreshUnits, setRefreshUnits] = useState(false);

  const handleToggleNode = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  };

  const handleSelectNode = (event, nodeId) => {
    setSelectedNode(nodeId);
  };
  const handleAddUnitDialog = (event, node) => {
    setParentUnit(node);
    setShowUnitForm(true);
    setSelectedUnit("");
    setIsEditing(false);
    event.stopPropagation();
  };
  //handleEditUnitDialog
  const handleEditUnitDialog = (event, node) => {
    setSelectedUnit(node);
    setIsEditing(true);
    setParentUnit("");
    setShowUnitForm(true);

    event.stopPropagation();
  };

  const handleUnitAllowanceDialog = (event, node) => {
    setSelectedUnit(node);
    setShowUnitAllowanceDialog(true);
    event.stopPropagation();
  };

  const handleUnitDeductionDialog = (event, node) => {
    setSelectedUnit(node);
    setShowUnitDeductionDialog(true);

    event.stopPropagation();
  };

  const handleChangePlanningEntityDialog = (event, node) => {
    setShowPlanningForm(true);
    setSelectedUnit(node);
    setIsEditing(false);
    event.stopPropagation();
  };

  useEffect(() => {
    if (isEmpty(treeUnits))
      getTreeUnits(user.selectedEntitySector.unitId, setExpandedNodes);
    else {
      const ids = [];
      units.forEach(({ unitId }) => ids.push(unitId));
      setExpandedNodes([...ids]);
    }

    if (!calculationMethods.lenght) getCalculationMethods();
    if (!basePayrollComponents.lenght) getBasePayrollComponents();
    if (!creditors.lenght) getCreditors();

    if (!unitTypes.lenght) getUnitTypes();
  }, []);

  useEffect(() => {
    if (refreshUnits) {
      getTreeUnits(user.selectedEntitySector.unitId, setExpandedNodes);
      setRefreshUnits(false);
    }
  }, [refreshUnits]);

  const labelClicked = useCallback((event, unitId) => {
    setSelectedNode(unitId);
    event.stopPropagation();
  }, []);

  const onSearch = (term) => {
    setTimeout(() => {
      setSearchUnit(term, units, setExpandedNodes);
    }, 200);
  };

  const renderTree = (node) => (
    <StyledTreeItem
      style={{ padding: "0" }}
      key={node.unitId}
      nodeId={node.unitId}
      label={
        <TreeListItem
          user={user}
          // setHoveredNode={setHoveredNode}
          labelClicked={labelClicked}
          // hoveredNode={hoveredNode}
          selectedNode={selectedNode}
          node={node}
          handleAddUnitDialog={handleAddUnitDialog}
          handleEditUnitDialog={handleEditUnitDialog}
          setUnitToBeRemoved={setUnitToBeRemoved}
          setConfirmRemoveUnit={setConfirmRemoveUnit}
          handleUnitAllowanceDialog={handleUnitAllowanceDialog}
          handleUnitDeductionDialog={handleUnitDeductionDialog}
          handleChangePlanningEntityDialog={handleChangePlanningEntityDialog}
        />
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((childNode) => renderTree(childNode))
        : null}
    </StyledTreeItem>
  );

  return (
    <>
      <AppBar position="static" elevation={0} className="app-bar w-100">
        <Toolbar
          style={{ minHeight: "0px", borderBottom: "3px solid #e9ecef" }}
          className=" my-0 py-1 bg-light"
        >
          <Typography
            variant="p"
            noWrap
            component="div"
            className="d-flex ilign-items-center"
          >
            <span className="text-uppercase text-info font-weight-bold">
              {user?.selectedEntitySector?.isLocked && (
                <Tooltip title="Structure was locked">
                  <i className="fas fa-lock text-danger"></i>
                </Tooltip>
              )}{" "}
              {user?.selectedEntitySector?.entityName}
            </span>
            <span className="mr-1 ml-1 py-0 my-0" style={{ color: "#2a7790" }}>
              {/* <ChevronRightIcon className="text-dark" /> */}
              <HorizontalRuleIcon className="rotated text-dark" />
            </span>
            <span className="text-uppercase ">
              {user.selectedEntitySector.sectorName}
            </span>
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "flex", md: "flex" } }}>
            <SearchBox onSearch={onSearch} placeholder="Search…" />
          </Box>
        </Toolbar>
      </AppBar>

      {!isEmpty(treeUnits) && (
        <div
          className="mt-n2"
          style={{
            backgroundColor: "#fff",
            borderRadius: "0.5rem",
            boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
            MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            height: "69vh",
            overflowY: "scroll",
          }}
        >
          <TreeView
            style={{
              padding: "1em",
            }}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<CloseSquare />}
            expanded={expandedNodes}
            selected={selectedNode}
            onNodeToggle={handleToggleNode}
            onNodeSelect={handleSelectNode}
          >
            {renderTree(treeUnits)}
          </TreeView>

          {showUnitForm && (
            <AddOrUpdateUnitDialog
              parentUnit={parentUnit}
              selectedUnit={selectedUnit}
              setIsEditing={setIsEditing}
              showUnitForm={showUnitForm}
              setShowUnitForm={setShowUnitForm}
              setExpandedNodes={setExpandedNodes}
              isEditing={isEditing}
              units={units}
            />
          )}

          {showUnitAllowanceDialog && (
            <UnitAllowances
              showUnitAllowanceDialog={showUnitAllowanceDialog}
              setShowUnitAllowanceDialog={setShowUnitAllowanceDialog}
              handleUnitAllowanceDialog={handleUnitAllowanceDialog}
              selectedUnit={selectedUnit}
            />
          )}

          {showUnitDeductionDialog && (
            <UnitDeductions
              creditors={creditors}
              calculationMethods={calculationMethods}
              basePayrollComponents={basePayrollComponents}
              showUnitDeductionDialog={showUnitDeductionDialog}
              setShowUnitDeductionDialog={setShowUnitDeductionDialog}
              handleUnitDeductionDialog={handleUnitDeductionDialog}
              selectedUnit={selectedUnit}
            />
          )}

          {confirmRemoveUnit && (
            <ConfirmationDialog
              confirmationDialog={confirmRemoveUnit}
              message={`Are you sure you want to remove this unit @${unitToBeRemoved.unitName}?`}
              setConfirmationDialog={setConfirmRemoveUnit}
              onYes={() => {
                deleteUnit(
                  unitToBeRemoved,
                  setConfirmRemoveUnit,
                  setUnitToBeRemoved,
                  setExpandedNodes,
                  units
                );
              }}
            />
          )}

          {!!showPlanningForm && (
            <SetPlanningEntityDialog
              selectedUnit={selectedUnit}
              setSelectedUnit={setSelectedUnit}
              showPlanningForm={showPlanningForm}
              setShowPlanningForm={setShowPlanningForm}
              setExpandedNodes={setExpandedNodes}
              setRefreshUnits={setRefreshUnits}
            />
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  units,
  treeUnits,
  basePayrollComponents,
  calculationMethods,
  creditors,
  unitTypes,
}) => {
  return {
    user,
    units,
    treeUnits,
    loading,
    basePayrollComponents,
    calculationMethods,
    creditors,
    unitTypes,
  };
};
export default connect(mapStateToProps, {
  getTreeUnits,
  deleteUnit,
  setSearchUnit,
  getCalculationMethods,
  getBasePayrollComponents,
  getCreditors,
  getUnitTypes,
})(Units);

const TreeListItem = (props) => {
  const [hoveredNode, setHoveredNode] = useState("");

  const {
    user,
    labelClicked,
    selectedNode,
    node,
    handleEditUnitDialog,
    setUnitToBeRemoved,
    setConfirmRemoveUnit,
    handleAddUnitDialog,
    handleChangePlanningEntityDialog,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const { hasPermissions } = useRequiredAuth();

  return (
    <span
      onMouseOut={() => setHoveredNode("")}
      onMouseOver={() => setHoveredNode(node.unitId)}
      onClick={(e) => labelClicked(e, node.unitId)}
      className="d-flex justify-content-between  align-items-center"
      style={{ maxWidth: "100%" }}
    >
      <span
        className={`d-block text-truncate ${
          selectedNode !== node.unitId && "add-padding"
        }`}
        style={{ maxWidth: "70%" }}
      >
        <Tooltip title={node.unitName}>
          <span
            className="mr-2"
            style={{
              cursor: "default",
            }}
          >
            {" "}
            {node.unitName}
          </span>
        </Tooltip>
        {!node.isOnStructure && (
          <Chip
            style={{ borderRadius: "0px" }}
            className="ml-1 small-chip"
            label="Not on structure"
            color="error"
            size="small"
            variant="outlined"
          />
        )}
      </span>

      {/* className={`${
          selectedNode === node.unitId || hoveredNode === node.unitId ? "" : ""
        }`} */}

      <span>
        <span className="badge badge-secondary disabled">
          {node.unitTypeName || "N/A"}
        </span>
        {!!node.isPlanningEntity && (
          <span className="badge badge-primary disabled ml-2">
            RBM Planning Entity
          </span>
        )}
        {hasPermissions(["IS_HR"]) && (
          <>
            <IconButton
              size="small"
              className="ml-2"
              onClick={handleOpenMenu}
              // onMouseMove={handleOpenMenu}
              aria-label="settings"
            >
              <Badge color="info">
                <span className="material-icons">more_vert</span>
              </Badge>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleCloseMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 2,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 15,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* <MenuItem
            onClick={(e) => {
              handleCloseMenu();
              handleUnitDeductionDialog(e, node);
            }}
            className="text-dark font-weight-light"
          >
            <span className="material-icons  mr-1">bookmark_remove</span>
            WithHolds
          </MenuItem>
          <Divider className="my-1" /> */}

              <MenuItem
                disabled={user.selectedEntitySector.isLocked}
                onClick={(e) => {
                  handleCloseMenu();
                  handleAddUnitDialog(e, node);
                }}
                className="text-primary font-weight-bolder"
              >
                {!user.selectedEntitySector.isLocked && (
                  <span className="material-icons  mr-1">add</span>
                )}
                {user.selectedEntitySector.isLocked && (
                  <i className="fas fa-lock"></i>
                )}
                Add new
                {user.selectedEntitySector.isLocked && (
                  <span className="badge badge-light">(Locked)</span>
                )}
              </MenuItem>
              <Divider className="my-1" />

              {!!node.parentUnitId && (
                <span>
                  <MenuItem
                    disabled={user.selectedEntitySector.isLocked}
                    onClick={(e) => {
                      handleCloseMenu();
                      handleEditUnitDialog(e, node);
                    }}
                    className="text-dark"
                  >
                    {!user.selectedEntitySector.isLocked && (
                      <span className="material-icons  mr-1">edit</span>
                    )}
                    {user.selectedEntitySector.isLocked && (
                      <i className="fas fa-lock"></i>
                    )}
                    Edit
                    {user.selectedEntitySector.isLocked && (
                      <span className="badge badge-light">(Locked)</span>
                    )}
                  </MenuItem>
                  <Divider className="my-1" />
                </span>
              )}

              <span>
                <MenuItem
                  onClick={(e) => {
                    handleCloseMenu();
                    handleChangePlanningEntityDialog(e, node);
                  }}
                  className="text-dark font-weight-light"
                >
                  <IconButton color="primary" size="small" className=" ">
                    <span className="material-icons">settings</span>
                  </IconButton>{" "}
                  RBM Entity
                </MenuItem>
                <Divider className="my-1" />
              </span>
              {/* <span>
            <MenuItem
              onClick={(e) => {
                handleCloseMenu();
                handleUnitAllowanceDialog(e, node);
              }}
              className="text-dark font-weight-light"
            >
              <IconButton
              color="primary"
              size="small"
              className=" "
            >
              <span className="material-icons">settings</span>
              </IconButton> Allowances
            </MenuItem>
            <Divider className="my-1" />
          </span> */}

              <span>
                <MenuItem
                  disabled={user.selectedEntitySector.isLocked}
                  onClick={() => {
                    handleCloseMenu();
                    setUnitToBeRemoved(node);
                    setConfirmRemoveUnit(true);
                  }}
                  className="text-danger"
                >
                  {!user.selectedEntitySector.isLocked && (
                    <span className="material-icons mr-1">delete_outline</span>
                  )}
                  {user.selectedEntitySector.isLocked && (
                    <i className="fas fa-lock"></i>
                  )}
                  Remove{" "}
                  {user.selectedEntitySector.isLocked && (
                    <span className="badge badge-light">(Locked)</span>
                  )}
                </MenuItem>
              </span>
            </Menu>
          </>
        )}
      </span>
    </span>
  );
};

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  })
);
