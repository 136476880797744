import moment from "moment-timezone";
const timezone = "Africa/Kigali";
export const currencyFormat = (num, currency = "") => {
  return num?(
    (+num * 1.0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
    " " +
    currency
  ):0;
};

export const totalSum = (data, colum) => {
  var total = 0;
  if (!!data.length)
    data.forEach(function (item, index) {
      total += item[colum];
    });
  return total;
};

export const isNull = (value, defaultVal = 0) => {
  return value ? value : defaultVal;
};
export const textCapitalized = (text, isTextCapitalize = true) => {
  if (text) {
    const lowerText = text.toLowerCase();
    const capitalized = isTextCapitalize ? (
      <span className="text-capitalize">
        {lowerText.charAt(0).toUpperCase() + lowerText.slice(1)}
      </span>
    ) : (
      lowerText.charAt(0).toUpperCase() + lowerText.slice(1)
    );
    return capitalized;
  }
  return "";
};
export const numberFormatter = (value) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(1)}M`; // Converts to 'M' for millions
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(1)}K`; // Converts to 'K' for thousands
  }
  return value; // Returns the number unchanged if less than 1000
};

// export const doesExamStarted = (exam) => {
//   const seatingDate = moment(exam.seatingDate)
//     .tz(timezone)
//     .format("YYYY-MM-DD");

//   const startDate = moment(seatingDate + " " + exam.startTime)
//     .tz(timezone)
//     .format("YYYY-MM-DD HH:mm");
//   const endDate = moment(seatingDate + " " + exam.endTime)
//     .tz(timezone)
//     .format("YYYY-MM-DD HH:mm");
//   if (
//     startDate < moment().tz(timezone).format("YYYY-MM-DD HH:mm") &&
//     endDate > moment().tz(timezone).format("YYYY-MM-DD HH:mm")
//   )
//     return true;
//   else return false;
// };

export const doesExamStarted = (exam) => {
  const seatingDate = moment(exam?.seatingDate)
    .tz(timezone)
    .format("YYYY-MM-DD");
  const startDate = moment(`${seatingDate} ${exam?.startTime}`).tz(timezone);
  const endDate = moment(`${seatingDate} ${exam?.endTime}`).tz(timezone);
  const currentMoment = moment().tz(timezone);
  const additionalMinutes = exam?.additionalMinutes || 0;

  // Add additional minutes to start and end times
  const startDateWithAdditionalMinutes = startDate
    .clone()
    .add(additionalMinutes, "minutes");
  const endDateWithAdditionalMinutes = endDate
    .clone()
    .add(additionalMinutes, "minutes");

  return currentMoment.isBetween(
    startDateWithAdditionalMinutes,
    endDateWithAdditionalMinutes
  );
};

// export const doesExamEnded = (exam) => {
//   const seatingDate = moment(exam.seatingDate)
//     .tz(timezone)
//     .format("YYYY-MM-DD");

//   const endDate = moment(seatingDate + " " + exam.endTime)
//     .tz(timezone)
//     .format("YYYY-MM-DD HH:mm");
//   if (endDate < moment().tz(timezone).format("YYYY-MM-DD HH:mm")) return true;
//   else return false;
// };
export const doesExamEnded = (exam) => {
  const seatingDate = moment(exam.seatingDate)
    .tz(timezone)
    .format("YYYY-MM-DD");
  const endDate = moment(`${seatingDate} ${exam.endTime}`).tz(timezone);
  const additionalMinutes = exam?.additionalMinutes || 0;
  // Add additional minutes to the end time
  const endDateWithAdditionalMinutes = endDate
    .clone()
    .add(additionalMinutes, "minutes");

  return endDateWithAdditionalMinutes.isBefore(moment().tz(timezone));
};

export const Editor = {};
Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "table",
  "image",
  "video",
];

export const SOCKET_EVENTS = {
  RECRUITMENT: {
    LOCK_APPLICANT_SESSION_CLIENT: "LOCK_APPLICANT_SESSION_CLIENT",
    START_APPLICANT_SESSION_CLIENT: "START_APPLICANT_SESSION_CLIENT",
    UNLOCK_APPLICANT_SESSION_CLIENT: "UNLOCK_APPLICANT_SESSION_CLIENT",
    APPLICANT_CHANGE_SESSION_DEVICE_CLIENT:
      "APPLICANT_CHANGE_SESSION_DEVICE_CLIENT",

    LOCK_APPLICANT_SESSION_SERVER: "LOCK_APPLICANT_SESSION_SERVER",
    START_APPLICANT_SESSION_SERVER: "START_APPLICANT_SESSION_SERVER",
    UNLOCK_APPLICANT_SESSION_SERVER: "UNLOCK_APPLICANT_SESSION_SERVER",
    APPLICANT_CHANGE_SESSION_DEVICE_SERVER:
      "APPLICANT_CHANGE_SESSION_DEVICE_SERVER",
  },
};

export const trainingAttachments = [
  {
    key: "SHORT_TERM",
    length: 1,
    documents: [
      {
        id: 1,
        name: "a selection report ",
      },
      {
        id: 2,
        name: "an invitation letter from the training institute or training organizer",
      },
      {
        id: 3,
        name: "an employee’s appointment letter ",
      },
      {
        id: 4,
        name: "a copy of booking Itinerary",
      },
      {
        id: 5,
        name: "a job description",
      },
      ,
      {
        id: 6,
        name: "a training program from the training institute or training organizer",
      },
    ],
  },
  {
    key: "LONG_TERM",
    length: 2,
    documents: [
      {
        id: 1,
        name: "a selection report ",
      },
      {
        id: 2,
        name: "a signed contract with High Education Council (HEC)",
      },
      {
        id: 3,
        name: "signed Training contract with the Employer reflecting the years the employee will serve after completion of studies.",
      },
      {
        id: 4,
        name: "admission letter from the University",
      },
      {
        id: 5,
        name: "a copy of booking Itinerary",
      },
      {
        id: 6,
        name: "an employee’s Appointment letter",
      },
      {
        id: 7,
        name: "a job description",
      },

      {
        id: 8,
        name: "a training program from the training institute or training organizer",
      },
    ],
  },
];

export const titles = ["Mr", "Ms", "Miss"];
export const employmentCategories = [
  "Permanent staff",
  "Contractual staff",
  // "On Probation",
];
export const highestAcademicLevels = [
  "A1",
  "A2",
  "Bachelor’s level",
  "Master’s level",
  "PhD level",
];
export const trainingAreas = [
  "Financial",
  "Energy",
  "Transport",
  "Infrastructure",
  "Agriculture",
  "Natural Resources",
  "Investment",
  "Trade and Industry",
  "ICT",
  "Health",
  "Education",
  "Urbanization and Rural settlement",
  "Water and Sanitation",
  "Sports and Culture",
  "Social Protection",
  "Private Sector and youth development",
  "Governance and Decentralization",
  "JRLOS",
  "Environment and  Natural Resources",
  "Other",
];
export const trainingCategories = [
  { id: 1, key: "SHORT_TERM", name: "Short term training" },
  { id: 2, key: "LONG_TERM", name: "Long term training" },
  { id: 3, key: "SHORT_TERM", name: "Professional Certification" },
];
export const trainingFunds = [
  "Government",
  "Development Partner",
  // "Both",
  "Self-Sponsored",
  "Other",
];
export const expectedTrainingAwards = [
  "Completion certificate",
  "Professional Certificate",
  "Diploma",
  "Post graduate",
  "Master",
  "PhD",
];
export const trainingInitiators = [
  "Institution",
  "Supervising Entity/Line Ministry",
  "Employee",
  "Other",
];

export const idNumberPattern = [
  /[1-3]/,
  " ",
  /[1-2]/,
  /[0|9]/,
  /\d/,
  /\d/,
  " ",
  /[7-8]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

export const requestStatusColor = (data = [], status = 0) => {
  const hasStatus = data.filter((row) => {
    return row.statusIds.find((id) => +id === +status);
  });

  return !!hasStatus.length ? hasStatus[0].className : "";
};



export const textCapitalizing = (value) => {
  if (value) {
    const lowerText = value.toLowerCase();
    const capitalized = lowerText.split(" ");
    const listOfWordsShouldNotUppercase = [
      // Conjunctions
      "and",
      "but",
      "or",
      "nor",
      "for",
      "yet",
      "so",
      "although",
      "because",
      "since",
      "unless",
      "while",
      "whereas",
      "whether",
      "if",
      "once",
      "unless",
      "provided",
      "that",
      "rather",
      "than",
      "after",
      "before",
      "though",
      "until",
      "when",
      "whenever",
      "wherever",
      "as",
      "much",
      "as",
      "either",
      "neither",
      "both",
      "as well as",
      "not only",
      "but also",
      "whether",
      "or not",
      "just as",
      "so that",
      "such that",
      "in order to",
      "now that",
      "even if",
      "even though",
      "as if",
      "as though",
      "in case",
      "provided that",
      "assuming that",
      "given that",
      "while",
      "whereas",
      "despite",
      "in spite of",
      "regardless",
      "granted",
      "that",
      "although",
      "though",
      "even though",
      "however",
      "nevertheless",
      "nonetheless",
      "still",
      "conversely",
      "on the other hand",
      "otherwise",
      "instead",
      "thus",
      "therefore",
      "hence",
      "accordingly",
      "as a result",
      "meanwhile",
      "subsequently",
      "in the meantime",
      "eventually",
      "afterward",
      "consequently",
      "since",
      "then",
      "next",
      "finally",
      "before",
      "earlier",
      "previously",
      "formerly",
      "prior to",
      "at first",
      "simultaneously",
      "soon",
      "later",
      "at the same time",
      "meanwhile",
      "after",
      "afterwards",
      "as soon as",
      "in the end",
      "in conclusion",
      "to summarize",
      "to conclude",
      "all in all",
      "in brief",
      "in summary",
      "on the whole",
      "overall",
      "ultimately",
      "to sum up",
      "generally",
      "usually",
      "typically",
      "often",
      "sometimes",
      "occasionally",
      "rarely",
      "seldom",
      "hardly ever",
      "never",
      "frequently",
      "constantly",
      "regularly",
      "habitually",
      "always",
      "everyday",
      "each day",
      "yearly",
      "monthly",
      "weekly",
      "daily",
      "hourly",
      "momentarily",
      "occasionally",
      "from time to time",
      "periodically",
      "sporadically",
      "rarely",
      "seldom",
      "infrequently",
      "hardly ever",
      "never",

      // Prepositions
      "about",
      "above",
      "across",
      "after",
      "against",
      "along",
      "among",
      "around",
      "as",
      "at",
      "before",
      "behind",
      "below",
      "beneath",
      "beside",
      "between",
      "beyond",
      "by",
      "despite",
      "down",
      "during",
      "except",
      "for",
      "from",
      "in",
      "inside",
      "into",
      "like",
      "near",
      "of",
      "off",
      "on",
      "onto",
      "out",
      "outside",
      "over",
      "past",
      "regarding",
      "round",
      "since",
      "through",
      "throughout",
      "to",
      "toward",
      "under",
      "underneath",
      "until",
      "up",
      "upon",
      "with",
      "within",
      "without",

      // Article
      "the",
      "a",
      "an",
    ];
    for (var i = 0; i < capitalized.length; i++) {
      const char = !listOfWordsShouldNotUppercase.find(
        (text) => text.toLowerCase() === capitalized[i].toLowerCase()
      )
        ? capitalized[i].charAt(0).toUpperCase()
        : capitalized[i].charAt(0).toLowerCase();

      capitalized[i] = char + capitalized[i].slice(1);
    }

    return capitalized.join(" ");
  }
  return "";
};

export const openCenteredPopup = (url, width = 550, height = 450) => {
  // Calculate the position to center the popup window on the screen
  const left = (window.innerWidth - width) / 2;
  const top = (window.innerHeight - height) / 2;

  // Options for the popup window
  const popupOptions = `
    width=${width},
    height=${height},
    left=${left},
    top=${top},
    resizable=yes,
    scrollbars=yes
  `;

  // Open the popup window
  window.open(url, "_blank", popupOptions);
};
export const countWords = (text) => {
  // Split the input text by spaces to count words
  const words = text ? text.trim().split(/\s+/) : "";
  return words.length;
};

export const removeDuplicates = (array) => {
  const uniqueValues = [];
  const uniqueIds = new Set();

  array.forEach((item) => {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id);
      uniqueValues.push(item);
    }
  });

  return uniqueValues;
};

export const removeDuplicateIndicatorAssignments = (array) => {
  const hashIndicators = {};
  const result = [];

  for (const item of array || []) {
    const key = `${item.employeeId}-${item.indicatorId}`;

    if (!hashIndicators[key]) {
      result.push(item);
      hashIndicators[key] = {
        employeeId: item.employeeId,
        indicatorId: item.indicatorId,
      };
    } else {
      const index = result.findIndex(
        (i) =>
          i.employeeId === item.employeeId && i.indicatorId === item.indicatorId
      );
      if (index > -1) {
        result[index].q1TargetShare =
          item.q1TargetShare || result[index].q1TargetShare;
        result[index].q2TargetShare =
          item.q2TargetShare || result[index].q2TargetShare;
        result[index].q3TargetShare =
          item.q3TargetShare || result[index].q3TargetShare;
        result[index].q4TargetShare =
          item.q4TargetShare || result[index].q4TargetShare;
      }
    }
  }

  return result;
};

export const generateProbationData = (array) => {
  //   const result =array.reduce((acc, quarter) => {
  //     return acc.concat(quarter.activities);
  // }, [])
  return [
    {
      quarterId: 1,
      activities: array.flatMap((quarter) => quarter.activities),
    },
  ];
};

export const getContractActivitySummary = (data) => {
  if (!data[0]) return null;

  let totalActivities = 0;
  let contractId = null;

  data.forEach((quarter) => {
    totalActivities += quarter.activities.length;

    if (quarter.activities.length > 0 && !contractId) {
      contractId = quarter.activities[0].contractId;
    }
  });

  return {
    totalActivities: totalActivities,
    contractId: contractId,
  };
};

export const getFaultDictionaryFormat = (faults) => {
  // Extract fault names
  const faultNames = faults.map((fault) => fault.faultName);

  // Repeat fault names accordingly and format them
  const formattedFaults = faultNames.map(
    (name, index) => `${name.toLowerCase()}`
  );

  // Join the formatted fault names with 'and' for the last element
  const formattedString =
    formattedFaults.slice(0, -1).join(", ") +
    " and " +
    formattedFaults.slice(-1);
  return formattedString;
};

export const otherLeaveStatus = [
  { id: 3, name: "New Request" },
  { id: 4, name: "Approved" },
  { id: 5, name: "On-Leave" },
  { id: 6, name: "Taken" },
  { id: 7, name: "Rejected" },
];
