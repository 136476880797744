import { Alert, InputAdornment, Skeleton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import APPLE_STORE from "../../assets/app-stores/apple-store.png";
import PLAY_STORE from "../../assets/app-stores/play-store.png";

import {
  changePassword,
  setAuthenticatorApp,
} from "../../../store/common/actions";

const MyAccount = (props) => {
  const { user, loading, changePassword, setAuthenticatorApp } = props;

  const history = useHistory();

  const isPasswordStrong = (password) => {
    if (
      password &&
      password.length > 7 &&
      // eslint-disable-next-line no-useless-escape
      /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password) &&
      /\d/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password)
    )
      return true;
    return false;
  };

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [error, setError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [loadingQrCode, setLoadingQrCode] = useState(null);
  const [authenticatorQrCode, setAuthenticatorQrCode] = useState(null);

  const checkPassworMatch = (password) => {
    if (!!password && formData.newPassword !== password)
      setConfirmError(`Password confirmation failed`);
    else setConfirmError("");
  };

  const checkStrongPassword = (password) => {
    if (!!password && !isPasswordStrong(password)) setError(`Weak password`);
    else setError("");
  };

  useEffect(() => {
    setAuthenticatorApp((status, res) => {
      setLoadingQrCode(status);
      setAuthenticatorQrCode(res);
    });
  }, []);

  const onSave = () => {
    changePassword(formData, history);
  };

  return (
    <>
      <div className="row mt-3 justify-content-center px-4">
        <div className="col-12 col-md-5 text-center">
          <div className="rounded px-4" style={{ backgroundColor: "#f6f8fa" }}>
            <div className="text-uppercase py-3">My account</div>

            <TextField
              fullWidth
              size="small"
              name="email"
              autoFocus
              label="Email"
              variant="outlined"
              placeholder="Email"
              className="mb-3 "
              style={{ backgroundColor: "#eee" }}
              disabled
              value={user.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="material-icons">alternate_email</span>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              size="small"
              name="oldPassword"
              type="password"
              autoFocus
              label="Old Password"
              variant="outlined"
              placeholder="Old Password"
              className="mb-3"
              value={formData.oldPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="material-icons">lock</span>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                const oldPassword = e.target.value;
                setFormData({ ...formData, oldPassword });
              }}
            />
            <TextField
              error={!!error}
              helperText={error}
              fullWidth
              size="small"
              name="newPassword"
              autoFocus
              type="password"
              label="New Password"
              variant="outlined"
              placeholder="New Password"
              className="mb-3"
              value={formData.newPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="material-icons">password</span>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                const newPassword = e.target.value;
                checkStrongPassword(newPassword);
                setFormData({
                  ...formData,
                  newPassword,
                  confirmNewPassword: "",
                });
                setConfirmError("");
              }}
            />
            <TextField
              error={!!confirmError}
              helperText={confirmError}
              fullWidth
              size="small"
              type="password"
              name="confirmNewPassword"
              autoFocus
              label="Confirm New Password"
              variant="outlined"
              placeholder="Confirm New Password"
              className="mb-3"
              value={formData.confirmNewPassword}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span className="material-icons">password</span>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                const confirmNewPassword = e.target.value;
                checkPassworMatch(confirmNewPassword);
                setFormData({ ...formData, confirmNewPassword });
              }}
            />

            <div className="alert alert-danger">
              Password must contain at least 8 characters with Capital letter,
              Small letter, (1/more) Digit(s) and (1/more) special character(s).
            </div>

            <div className="d-flex justify-content-center">
              <button
                onClick={onSave}
                type="button"
                className="btn btn-primary text-uppercase  px-4"
                disabled={
                  loading ||
                  error ||
                  confirmError ||
                  !!!formData.confirmNewPassword
                }
              >
                {loading ? "Wait..." : "Change password"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Alert
        variant="standard"
        severity="info"
        className="mt-3 mx-auto"
        style={{ maxWidth: "400px" }}
      >
        Set Google or Microsoft Authenticator
      </Alert>

      <div className="d-flex align-items-center flex-column justify-content-center mt-3">
        {!!authenticatorQrCode && !loadingQrCode ? (
          <img
            src={authenticatorQrCode.qrCode}
            className="mb-1"
            alt="authenticator qrcode"
          />
        ) : (
          <Skeleton
            variant="rounded"
            width={150}
            height={150}
            className="mb-1"
          />
        )}
        <p className="text-left mb-2">
          Scan this QR Code with{" "}
          <span className="font-weight-bold">
            Google or Microsoft Authenticator
          </span>{" "}
          to get the code
        </p>
        <div className="text-center mb-2">
          <p className="mb-1">Don't have an authenticator app?</p>

          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={PLAY_STORE}
              alt="Google Play Store"
              style={{ maxWidth: "120px" }}
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id38849760"
            target="_blank"
            className="mr-1"
            rel="noreferrer"
          >
            <img
              src={APPLE_STORE}
              alt="Apple Store"
              style={{ maxWidth: "120px" }}
            />
          </a>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return { user, loading };
};
export default connect(mapStateToProps, {
  changePassword,
  setAuthenticatorApp,
})(MyAccount);
