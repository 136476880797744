import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import AppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import SearchBox from "../../common/components/SearchBox";
import { Typography, Box, Tooltip } from "@mui/material";
import { connect } from "react-redux";
import { CardContent, Toolbar } from "@mui/material";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { OrgChart } from "d3-org-chart";
import { getOrganigramData } from "../../../store/structure/actions";
import defaultProfile from "../../assets/default-profile.jpg";

const Organigram = (props) => {
  const { user, loading, organigramData, getOrganigramData } = props;

  useEffect(() => {
    document.title = "IPPIS - Org. Structure | Organigram";
  });

  // const [data, setData] = useState(null);

  // let addNodeChildFunc = null;

  // function addNode() {
  //   const node = {
  //     nodeId: "new Node",
  //     parentNodeId: "O-6066",
  //   };

  //   addNodeChildFunc(node);
  // }

  // function onNodeClick(nodeId) {
  //   // console.log('d3', d3.event);
  //   alert("clicked " + nodeId);
  // }

  useEffect(() => {
    if (!organigramData.length)
      getOrganigramData(user.selectedEntitySector.unitId);
    // console.log(organigramData);
  }, []);

  // const formartData = () => {
  //   return organigramData.map((item) => ({
  //     id: item.unitId,
  //     parentId: item.parentUnitId,
  //     name: item.unitName,
  //   }));
  // };
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <div className="w-100">
          <AppBar position="static" elevation={0} className="app-bar w-100">
            <Toolbar
              style={{ minHeight: "0px", borderBottom: "3px solid #e9ecef" }}
              className=" my-0 py-1 bg-light"
            >
              <Typography
                variant="p"
                noWrap
                component="div"
                className="d-flex ilign-items-center"
              >
                <span className="text-uppercase text-info">
                  {user.selectedEntitySector.isLocked && (
                    <Tooltip title="Structure was locked">
                      <i className="fas fa-lock text-danger"></i>
                    </Tooltip>
                  )}{" "}
                  {user.selectedEntitySector.entityName}
                </span>
                <span
                  className="mr-1 ml-1 py-0 my-0"
                  style={{ color: "#2a7790" }}
                >
                  {/* <ChevronRightIcon className="text-dark" /> */}
                  <HorizontalRuleIcon className="rotated text-dark" />
                </span>
                <span className="text-uppercase">
                  {user.selectedEntitySector.sectorName}
                </span>
              </Typography>

              {/* <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <SearchBox onSearch={onSearch} placeholder="Search…" />
              </Box> */}
            </Toolbar>
          </AppBar>
          <div className="container-fluid text-center">
            <div>
              {!!organigramData.length && (
                <OrgChartComponent
                  // setClick={(click) => (addNodeChildFunc = click)}
                  // onNodeClick={onNodeClick}
                  data={organigramData}
                />
              )}
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, organigramData }) => {
  return { user, loading, organigramData };
};
export default connect(mapStateToProps, { getOrganigramData })(Organigram);

const OrgChartComponent = (props) => {
  const d3Container = useRef(null);
  let chart = null;

  // function addNode(node) {
  //   chart.addNode(node);
  // }

  // props.setClick(addNode);

  // We need to manipulate DOM
  useLayoutEffect(() => {
    if (props.data && d3Container.current) {
      if (!chart) {
        chart = new OrgChart();
      }
      chart
        .container(d3Container.current)
        .data(props.data)
        .nodeWidth((d) => 450)
        .initialZoom(0.7)
        .nodeHeight((d) => (d.data.positions.length > 0 ? 400 : 0))
        .childrenMargin((d) => 40)
        .compactMarginBetween((d) => 15)
        .compactMarginPair((d) => 80)
        .nodeContent(function (d, i, arr, state) {
          return `
          <div
            style="padding-top:30px;background-color:none;margin-left:1px;height:${
              d.height
            }px;border-radius:2px;overflow:visible"
          >
            <div
              style="height:${
                d.height - 32
              }px;padding-top:0px;background-color:white;border:1px solid lightgray; "
            >
              <div style="margin-top:10px;">
                <div
                  style="background-color:#088dce;width:${
                    d.width - 2
                  }px;border-radius:1px; padding:8px; color:#fff;font-size:16px;font-weight:bold"
                >
                  ${
                    d.data.name
                  } ${d.data.positions.length > 0 ? `(${d.data.positions.length})` : ""}
                </div>
              </div>

              <div style="padding:20px;text-align:center; height:${
                d.height / 1.5
              }px; overflow-y:auto">

              ${d.data.positions.length === 0 ? `<div>No postion</div>` : ""}
                ${d.data.positions.map(
                  (position) => `
                          <div style="padding:5px; color:#404040;font-size:16px; border: 1px solid rgba(42,119,144,.25);">
                            ${position.name} <span style="font-weight:bold">(${
                    position.level
                  })</span> 
                            <small style="float:right;display:flex;align-items:center;">                              
                            ${
                              position.numberOfPosts -
                              position.numberOfVacantPosts
                            }/${position.numberOfPosts}
                            </small>
                          </div>`
                )}
              </div>

              ${
                d.data.positions.length > 0
                  ? `
              <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px; margin-top:10px">
                <div style="color: #088dce; font-weight:bold">                  
                  ${d.data._directSubordinates} Direct Subordinates
                </div>
                <div style="color: #088dce; font-weight:bold">                 
                  ${d.data._totalSubordinates} Total Subordinates
                </div>
              </div>
              `
                  : ""
              }
              
            </div>
          </div>`;
        })
        .render()
        .expandAll();
    }
  }, [props.data, d3Container.current]);

  return (
    <div>
      <div className="text-right pt-1">
        <button
          type="button"
          className="btn py-0  btn-secondary btn-sm d-inline-flex justify-content-center align-items-center "
          onClick={() => !!chart && chart.exportImg({ full: true })}
        >
          <span className="material-icons">download</span>
          Download
        </button>
      </div>

      <div
        ref={d3Container}
        style={{ height: "1200px", backgroundColor: "#f6f6f6" }}
      />
    </div>
  );
};
