import { Dialog, Box, CircularProgress, DialogContent } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";

const DownloadFile = (props) => {
  const { showAction, setShowAction, action, docId, fileName } = props;

  useEffect(() => {
    downloadFile();
  }, []);

  const downloadFile = async () => {
    try {
      const response = await axios({
        url: process.env.REACT_APP_FILES_URL + "/download/" + docId, // Gateway server endpoint
        method: "GET",
        responseType: "blob", // Important for handling binary data
      });

      // Create a blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); // Set file name
      document.body.appendChild(link);
      link.click();
      link.remove();
      setShowAction(false);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={showAction}
        maxWidth="sm"
      >
        <DialogContent>
          <div>
            <Box className="justify-content-center " sx={{ display: "flex" }}>
              <CircularProgress />
              <h5 className="mt-2 ml-2">{action}</h5>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DownloadFile;
