import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CardContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getApplicantWrittenExamAnswers } from "../../../store/recruitment/actions";
import PreLoader from "../../common/components/PreLoader";
import { useTheme } from "@mui/material/styles";
import PercentIcon from "@mui/icons-material/Percent";
import moment from "moment";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import SearchBox from "../../common/components/SearchBox";
import AddOpenQuestionMarkDialog from "./AddOpenQuestionMarkDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const ApplicantWrittenExamAnswerCard = (props) => {
  const {
    writtenExamMark,
    writtenExamMarkAnswer,
    setSelectedWrittenExamMarkAnswer,
    setShowScore,
    userType,
    exam,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <tr
        className={
          !!writtenExamMarkAnswer?.examQuestion?.isExamQuestionCanceled
            ? "exam-question-canceled"
            : ""
        }
      >
        <th
          scope="row"
          style={{
            backgroundColor: !!writtenExamMarkAnswer?.examQuestion
              ?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "#f0f2f5",
          }}
        >
          Q.
          {writtenExamMarkAnswer.examQuestion &&
            writtenExamMarkAnswer.examQuestion.questionNo}
        </th>

        <td
          className="text-left"
          style={{
            width: "760px",
            backgroundColor: !!writtenExamMarkAnswer?.examQuestion
              ?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "",
          }}
        >
          {!!writtenExamMarkAnswer?.examQuestion?.isExamQuestionCanceled && (
            <span className="badge badge-danger px-1 py-1 mr-2">
              {" "}
              Question Canceled{" "}
            </span>
          )}
          {writtenExamMarkAnswer.examQuestion &&
            writtenExamMarkAnswer.examQuestion.question &&
            writtenExamMarkAnswer.examQuestion.question.description}
        </td>
        {writtenExamMark.examTypeId === 1 && (
          <td
            className="text-left"
            style={{
              backgroundColor: !!writtenExamMarkAnswer?.examQuestion
                ?.isExamQuestionCanceled
                ? "rgb(245, 144, 144)"
                : "",
            }}
          >
            {writtenExamMarkAnswer.examQuestion &&
              writtenExamMarkAnswer.examQuestion.question &&
              !!writtenExamMarkAnswer.examQuestion.question
                .multipleChoiceOptions.length && (
                <>
                  {writtenExamMarkAnswer.examQuestion.question.multipleChoiceOptions.map(
                    (option, index) => (
                      <p key={index}>
                        {option.optionNumber}: {option.description}{" "}
                        {option.isCorrect && (
                          <span className="badge badge-success ml-1">
                            {" "}
                            Correct
                          </span>
                        )}
                      </p>
                    )
                  )}
                </>
              )}
          </td>
        )}

        <td
          className="text-left"
          style={{
            backgroundColor: !!writtenExamMarkAnswer?.examQuestion
              ?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "",
          }}
        >
          {writtenExamMark.examTypeId === 1 && (
            <span>
              {writtenExamMarkAnswer.isCorrect ? (
                <TaskAltIcon className="text-success" />
              ) : (
                <HighlightOffIcon className="text-danger" />
              )}
            </span>
          )}
          <p
            dangerouslySetInnerHTML={{
              __html: writtenExamMarkAnswer.answer,
            }}
          ></p>
        </td>

        <td
          className="text-center"
          style={{
            backgroundColor: !!writtenExamMarkAnswer?.examQuestion
              ?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "",
          }}
        >
          <div
            className="text-center"
            style={{
              margin: "0 auto",
              padding: "2px",
              width: "50px",
              height: "auto",
              border: `solid 3px #344457`,
              borderRadius: "50px",
              textAlign: "center",
            }}
          >
            {writtenExamMarkAnswer.score === null
              ? "-"
              : writtenExamMarkAnswer.score}{" "}
            /{" "}
            {writtenExamMarkAnswer.examQuestion &&
              writtenExamMarkAnswer.examQuestion.marks}
          </div>
        </td>

        {userType !== "ADMIN" && (
          <td
            className="text-center"
            style={{
              width: "100px",
              backgroundColor: !!writtenExamMarkAnswer?.examQuestion
                ?.isExamQuestionCanceled
                ? "rgb(245, 144, 144)"
                : "",
            }}
          >
            {!!exam?.isExaminer && writtenExamMark.examTypeId === 2 && (
              <button
                disabled={exam.wasExamMarkingPublished}
                onClick={() => {
                  setSelectedWrittenExamMarkAnswer(writtenExamMarkAnswer);
                  setShowScore(true);
                }}
                type="button"
                title="Edit"
                className="btn btn-primary btn-sm"
              >
                Add mark
              </button>
            )}
          </td>
        )}
      </tr>
    </>
  );
};

const ApplicantWrittenExamAnswerDialog = (props) => {
  const {
    loading,
    showApplicantWrittenExamAnswers,
    setShowApplicantWrittenExamAnswers,
    getApplicantWrittenExamAnswers,
    applicantWrittenExamAnswers,
    applicantWrittenExamResult,
    userType,
    exam,
  } = props;

  const [searchTeam, setSearchTeam] = useState("");
  const [showScore, setShowScore] = useState(false);
  const [selectedWrittenExamMarkAnswer, setSelectedWrittenExamMarkAnswer] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [
    filterApplicantWrittenExamAnswers,
    setFilterApplicantWrittenExamAnswers,
  ] = useState([...applicantWrittenExamAnswers]);

  useEffect(() => {
    if (applicantWrittenExamResult)
      getApplicantWrittenExamAnswers(
        applicantWrittenExamResult.id,
        setIsLoading
      );
  }, []);

  useEffect(() => {
    const filtered = [...applicantWrittenExamAnswers].filter(
      ({ answer, examQuestion }) =>
        answer.toLowerCase().includes(searchTeam.toLowerCase()) ||
        (examQuestion.question &&
          examQuestion.question.description
            .toLowerCase()
            .includes(searchTeam.toLowerCase()))
    );

    setFilterApplicantWrittenExamAnswers(filtered);
  }, [searchTeam, applicantWrittenExamAnswers]);

  const onClose = () => {
    setShowApplicantWrittenExamAnswers(false);
  };
  const theme = useTheme();
  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showApplicantWrittenExamAnswers}
        fullScreen
        maxWidth={"xl"}
      >
        <DialogTitle>
          <Typography variant="h6" noWrap component="div">
            <span className="text-dark">Results</span>
            <span className="mr-1 ml-1" style={{ color: "#2a7790" }}>
              <span
                className="material-icons  text-dark"
                style={{ position: "relative", top: "5px" }}
              >
                chevron_right
              </span>
            </span>

            <span style={{ color: "rgb(7, 142, 206)" }}>
              {applicantWrittenExamResult
                ? applicantWrittenExamResult.examTypeName
                : ""}{" "}
              Answer Sheet
            </span>
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div
            className="w-100"
            style={{
              height: "520px",
            }}
          >
            <div
              style={{
                height: "auto",
                maxHeight: "auto",
                border: "2px solid #e0f4f3",
                borderRadius: "0.5rem",
                overflow: "visible",
                boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
              }}
            >
              <CardContent
                style={{
                  // padding: ".6em",
                  height: "auto",
                  minHeight: "auto",
                  overflow: "hidden",
                }}
              >
                <NoRecordMessage
                  isEmpty={!applicantWrittenExamResult}
                  title="No Answer Sheet found"
                />

                {applicantWrittenExamResult && (
                  <div className="row">
                    <div className="col-lg-4 border-right">
                      <div className="row">
                        <div className="col-2">ID</div>
                        <div className="col-10">
                          <strong>:</strong>
                          <strong className="ml-1 text-truncate">
                            {applicantWrittenExamResult.userId}
                          </strong>{" "}
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-2">Names</div>
                        <div className="col-10">
                          {" "}
                          <strong>:</strong>
                          <strong className="ml-1 text-truncate">
                            {applicantWrittenExamResult.firstName}{" "}
                            {applicantWrittenExamResult.lastName}
                          </strong>{" "}
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-2">Email</div>
                        <div className="col-10">
                          {" "}
                          <strong>:</strong>
                          <small className="ml-1 text-truncate">
                            {applicantWrittenExamResult.email}
                          </small>{" "}
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-2">Phone</div>
                        <div className="col-10">
                          {" "}
                          <strong>:</strong>
                          <small className="ml-1 text-truncate">
                            {applicantWrittenExamResult.phoneNumber}
                          </small>{" "}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 border-right">
                      <div className="row">
                        <div className="col-12">
                          <div
                            className=" text-center mt-4"
                            style={{
                              margin: "0 auto",
                              // padding:'3px',
                              width: "120px",
                              height: "120px",
                              border: `solid 4px ${
                                applicantWrittenExamResult.isPassed === 1
                                  ? "#28a745"
                                  : "#dc3545"
                              }`,
                              borderRadius: "100%",
                              textAlign: "center",
                            }}
                          >
                            <span
                              className="text-center"
                              style={{
                                position: "relative",
                                top: "38px",
                              }}
                            >
                              <strong
                                style={{ fontSize: "22px", fontFamily: "800" }}
                              >
                                {applicantWrittenExamResult.totalScore}
                              </strong>
                              {applicantWrittenExamResult.totalMarks === 100 ? (
                                <PercentIcon style={{ fontSize: "20px" }} />
                              ) : (
                                <span
                                  style={{
                                    fontSize: "22px",
                                    fontFamily: "800",
                                  }}
                                >
                                  /{applicantWrittenExamResult.totalMarks}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="mx-auto col-6 text-center">
                          {applicantWrittenExamResult.isPassed === 1 ? (
                            <span className="badge  badge-success ml-1">
                              {applicantWrittenExamResult.status}
                            </span>
                          ) : (
                            <span className="badge  badge-danger ml-1">
                              {applicantWrittenExamResult.status}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="row">
                        <div className="col-12">
                          <strong>Submitted</strong>
                          <strong className="text-truncate">
                            :
                            <span
                              className="ml-1"
                              style={{ color: "rgb(7, 142, 206)" }}
                            >
                              {applicantWrittenExamResult.submittedOn
                                ? moment(
                                    applicantWrittenExamResult.submittedOn
                                  ).format("MMMM Do YYYY HH:mm A")
                                : ""}{" "}
                            </span>
                          </strong>{" "}
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-12">
                          <div className="input-group input-group-sm mt-2">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                Score
                              </span>
                            </div>
                            <div className="form-control">
                              <div className="form-check form-check-inline">
                                <div
                                  style={{
                                    padding: "0px",
                                    marginTop: "-10px",
                                    width: "70px",
                                    height: "auto",

                                    borderRadius: "50px",
                                    textAlign: "center",
                                  }}
                                >
                                  {applicantWrittenExamResult.score}
                                  {applicantWrittenExamResult.totalMarks ===
                                  100 ? (
                                    <PercentIcon style={{ fontSize: "13px" }} />
                                  ) : (
                                    <>
                                      /{applicantWrittenExamResult.totalMarks}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-2">
                          <div className="input-group input-group-sm mt-2">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="basic-addon1"
                              >
                                Topup score
                              </span>
                            </div>
                            <div className="form-control">
                              <div className="form-check form-check-inline">
                                <div
                                  style={{
                                    padding: "0px",
                                    marginTop: "-10px",
                                    width: "70px",
                                    height: "auto",
                                    border: `solid 2px $000`,
                                    borderRadius: "50px",
                                    textAlign: "center",
                                  }}
                                >
                                  {applicantWrittenExamResult.topupScore}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </CardContent>
            </div>
            {!isLoading && !!applicantWrittenExamAnswers.length && (
              <div
                className="mt-3"
                style={{
                  padding: theme.spacing(0.4),
                  height: "auto",
                  maxHeight: "auto",
                  backgroundColor: "#fff",
                  borderRadius: "0.5rem",
                  overflow: "visible",
                  boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
                  MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
                }}
              >
                <CardContent
                  style={{
                    height: "auto",
                    minHeight: "auto",
                    overflow: "hidden",
                  }}
                >
                  {applicantWrittenExamResult && (
                    <div className="row mt-3">
                      <div
                        className={
                          !!applicantWrittenExamAnswers.length
                            ? "col-lg-7"
                            : "col-lg-10"
                        }
                      >
                        {!!applicantWrittenExamAnswers.length && (
                          <span>
                            Total Q/S: {applicantWrittenExamAnswers.length}
                          </span>
                        )}
                      </div>
                      <div
                        className={
                          !!applicantWrittenExamAnswers.length
                            ? "col-lg-5"
                            : "col-lg-2"
                        }
                      >
                        {!!applicantWrittenExamAnswers.length && (
                          <SearchBox
                            onSearch={onSearch}
                            placeholder="Search…"
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <div className="row mt-1">
                    <div className="col-lg-12">
                      <NoRecordMessage
                        isEmpty={
                          !filterApplicantWrittenExamAnswers.length && !loading
                        }
                        title="No Question answers found. "
                      />
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-lg-12 mt-1">
                      {!!filterApplicantWrittenExamAnswers.length &&
                        applicantWrittenExamResult && (
                          <table className="table table-striped table-hover table-sm  fixTableHead">
                            <thead>
                              <tr style={{ backgroundColor: "#f0f2f5" }}>
                                <th scope="col">#</th>

                                <th scope="col" className="text-left">
                                  Question
                                </th>

                                {applicantWrittenExamResult.examTypeId ===
                                  1 && (
                                  <th scope="col" className="text-left">
                                    Assertion
                                  </th>
                                )}

                                <th scope="col" className="text-left">
                                  Candidate Answer
                                </th>
                                <th scope="col" className="text-center">
                                  Marks
                                </th>

                                {userType !== "ADMIN" && (
                                  <th scope="col" className="text-center">
                                    Actions
                                  </th>
                                )}
                              </tr>
                            </thead>

                            <tbody>
                              {filterApplicantWrittenExamAnswers.map(
                                (writtenExamMarkAnswer, index) => (
                                  <ApplicantWrittenExamAnswerCard
                                    key={index}
                                    exam={exam}
                                    writtenExamMark={applicantWrittenExamResult}
                                    writtenExamMarkAnswer={
                                      writtenExamMarkAnswer
                                    }
                                    setSelectedWrittenExamMarkAnswer={
                                      setSelectedWrittenExamMarkAnswer
                                    }
                                    setShowScore={setShowScore}
                                    userType={userType}
                                  />
                                )
                              )}
                            </tbody>
                          </table>
                        )}
                    </div>
                  </div>
                </CardContent>
              </div>
            )}
            {showScore && (
              <AddOpenQuestionMarkDialog
                showScore={showScore}
                setShowScore={setShowScore}
                writtenExamMarkAnswer={selectedWrittenExamMarkAnswer}
              />
            )}
            <PreLoader isLoading={isLoading} />
          </div>
        </DialogContent>

        <DialogActions className="d-flex justify-content-center py-3"></DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({
  loading,
  applicantWrittenExamAnswers,
  applicantWrittenExamResult,
}) => {
  return {
    loading,
    applicantWrittenExamAnswers,
    applicantWrittenExamResult,
  };
};
export default connect(mapStateToProps, {
  getApplicantWrittenExamAnswers,
})(ApplicantWrittenExamAnswerDialog);
