import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
  Button,
  Typography,
  Tabs,
  Tab,
  Skeleton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Chip,
  Divider,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MaskedInput from "react-text-mask";
import LinearProgress from "@mui/material/LinearProgress";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import axios from "axios";

import {
  getNationalIdDetail,
  getUnitPositions,
  getBanks,
  getRelationships,
  getEmployee,
  updatePersonalInfo,
  updateEmployment,
  updateEmployeeInsurance,
  addOrUpdateNextOfKin,
  updateBanking,
  deleteNextOfKin,
  getEmployeeQualifications,
  getEmployeeCertificates,
  saveEmployeeProfessionalEmail,
  saveEmployee,
} from "../../../store/structure/actions";
import { showError } from "../../toastify";
import { TabPanel, a11yProps } from "../../common/components/TabPanel";

import moment from "moment";

import defaultPofile from "../../assets/default-profile.jpg";

import { useTheme } from "@mui/material/styles";
import joi from "joi";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { idNumberPattern } from "../../common/components/Utils";

const professionalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { allow: ["rw"] },
  }),
});

const personalEmailValidator = joi.object({
  email: joi.string().email({
    minDomainSegments: 2,
    tlds: { deny: ["rw"] },
  }),
});

const ActiveButton = {
  borderRadius: "8px",
  height: "40px",

  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
};

const EmployeePersonalInfoDialog = (props) => {
  const {
    loading,
    onWait,
    employee,
    banks,
    // selectedEmployee,
    showEmployeeProfileDialog,
    setShowEmployeeProfileDialog,
    // getEmployee,
    // getUnitPositions,
    positions,
    // getBanks,
    relationships,
    updatePersonalInfo,
    verifyRssbNumber,
    // nationalIdDetail,
    // getNationalIdDetail,
    // updateEmployment,
    // updateEmployeeInsurance,
    // updateBanking,
    employeeProfile,
    getRelationships,
    addOrUpdateNextOfKin,
    deleteNextOfKin,
    getEmployeeQualifications,
    getEmployeeCertificates,
    saveEmployeeProfessionalEmail,
    selectedUnit,
    isPullRequest,
    setShowConfirmDialog,
    saveEmployee,
  } = props;

  useEffect(() => {
    if (!relationships.length) getRelationships();
    getEmployeeQualifications(employee.id);
    getEmployeeCertificates(employee.id);
  }, []);

  const [position, setPosition] = useState(null);

  const [employeeForm, setEmployeeForm] = useState({
    idNumber: "",
    email: "",
    phoneNumber: "",
    gender: "",
    countryOfBirth: "",
    firstName: "",
    lastName: "",
    civilStatus: "",
    fatherNames: "",
    motherNames: "",
    dateOfBirth: "",
    placeOfBirth: "",
    employmentDate: new Date(),
  });

  const [errors, setErrors] = useState({
    idNumberHasError: false,
    emailHasError: false,
    emailError: "",
    phoneNumberHasError: false,
    employmentDateHasError: false,
    positionHasError: false,
    hasError: false,
  });

  const personalInfoFormValidator = () => {
    const error = {
      idNumberHasError: false,
      emailHasError: false,
      positionHasError: false,
      emailError: "",
      employmentDateHasError: false,
      phoneNumberHasError: false,
      hasError: false,
    };

    if (employeeForm.idNumber.length < 21) {
      error.idNumberHasError = true;
      error.hasError = true;
    }

    if (!employeeForm.phoneNumber) {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    if (!employeeForm.email) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (
      !!employeeForm.email &&
      personalEmailValidator.validate({ email: employeeForm.email }).error
    ) {
      error.emailHasError = true;
      error.emailError = "Invalid personal email";
      error.hasError = true;
    }

    if (isPullRequest && !position) {
      error.positionHasError = true;
      error.hasError = true;
    }

    if (isPullRequest && !employeeForm.employmentDate) {
      error.employmentDateHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all required fields");

      return true;
    }
    return false;
  };

  const onClose = () => {
    setShowEmployeeProfileDialog(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showNextOfKinDialog, setShowNextOfKinDialog] = useState(false);
  const [showProfessionalEmailDialog, setShowProfessionalEmailDialog] =
    useState(false);

  const [nextOfKin, setNextOfKin] = useState({
    employeeId: "",
    idNumber: "",
    relationshipId: "",
    foreName: "",
    surnames: "",
    bankId: "",
    bankAccountNumber: "",
    phoneNumber: "",
    email: "",
    nidVerified: false,
  });

  const [changed, setChanged] = useState(false);

  const [isVerifing, setIsVerifing] = useState(false);
  const [wasVerified, setWasVerified] = useState(false);
  const [idHasError, setIdHasError] = useState(false);

  const getNo = (idToSearch, data, key = "id") => {
    return data.findIndex((item) => item[key] === idToSearch) + 1;
  };

  useEffect(() => {
    if (employee) {
      setEmployeeForm({
        idNumber: employee.idNumber,
        email: employee.email,
        phoneNumber: employee.phoneNumber,
        gender: employee.gender,
        countryOfBirth: employee.countryOfBirth,
        firstName: employee.firstName,
        lastName: employee.lastName,
        civilStatus: employee.civilStatus,
        fatherNames: employee.fatherNames,
        motherNames: employee.motherNames,
        dateOfBirth: employee.dateOfBirth,
        placeOfBirth: employee.placeOfBirth,
        employmentDate: new Date(),
      });

      setWasVerified(employee.nidVerified);
    }
  }, [employee]);

  const getNidDetails = async (idNumber) => {
    try {
      setWasVerified(false);
      setIsVerifing(true);

      const { data } = await axios.get(
        "/api/external/nid-number-detail/" + idNumber
      );

      const dateOfBirthArray = data.dateOfBirth.split("/");

      setEmployeeForm({
        ...employeeForm,
        idNumber: idNumber,
        gender: data.sex,
        countryOfBirth: data.birthCountry,
        firstName: data.foreName,
        lastName: data.surnames,
        civilStatus: data.maritalStatus,
        fatherNames: data.fatherNames,
        motherNames: data.motherNames,
        dateOfBirth:
          dateOfBirthArray[2] +
          "-" +
          dateOfBirthArray[1] +
          "-" +
          dateOfBirthArray[0],
        placeOfBirth: data.placeOfBirth,
      });

      setChanged(true);
      setIdHasError(false);
      setWasVerified(true);
      setIsVerifing(false);
    } catch (error) {
      setChanged(false);
      setIdHasError(true);
      setWasVerified(false);
      setIsVerifing(false);
    }
  };

  const doUpdatePersonalInfo = () => {
    if (!personalInfoFormValidator()) {
      if (isPullRequest) {
        const formData = new FormData();
        formData.append("employeeId", employee.id);
        formData.append("idNumber", employeeForm.idNumber);
        formData.append("positionId", position.id);
        formData.append("employmentDate", employeeForm.employmentDate);
        formData.append("phoneNumber", employeeForm.phoneNumber);
        formData.append("personalEmail", employeeForm.email);
        formData.append("professionalEmail", employee.professionalEmail);
        formData.append("isPullRequest", 1);

        saveEmployee(formData, setShowConfirmDialog, isPullRequest);
      } else
        updatePersonalInfo(
          {
            idNumber: employeeForm.idNumber,
            employeeId: employee.id,
            phoneNumber: employeeForm.phoneNumber,
            email: employeeForm.email,
          },
          setChanged
        );
    }
  };

  return (
    <>
      <div className=" mt-1">
        {employee && (
          <React.Fragment>
            <div className="row">
              <div className="col-sm-12">
                <React.Fragment>
                  <div>
                    <div className="row no-gutters">
                      <div className="col-3 justify-content-center  text-center">
                        <div
                          style={{
                            minHeight: "150px",
                            overflow: "hidden",
                          }}
                        >
                          {employee && (
                            <>
                              {employeeProfile.profilePicture && (
                                <img
                                  className="mt-0 rounded  text-center"
                                  src={
                                    "data:image/jpg;base64," +
                                    employeeProfile.profilePicture
                                  }
                                  style={{
                                    border: "1px solid #a6d3df",
                                    height: "192px",
                                    // width: "130px",
                                    overflow: "hidden",
                                    // borderRadius: "50%",
                                  }}
                                />
                              )}
                              {!employeeProfile.profilePicture && (
                                <img
                                  className="mt-0  rounded text-center"
                                  src={defaultPofile}
                                  style={{
                                    // border: "1px solid #a6d3df",
                                    height: "192px",
                                    width: "180px",
                                    overflow: "hidden",
                                    borderRadius: "50%",
                                  }}
                                />
                              )}
                            </>
                          )}
                        </div>

                        <div className="text-primary">
                          <strong>ID:{employee.id}</strong>{" "}
                        </div>
                        <label
                          className="text-center text-uppercase mb-1 mt-n1"
                          style={{ fontSize: "12px" }}
                        >
                          <span style={{ fontSize: "10px" }}>
                            Professional email
                          </span>{" "}
                          <br />{" "}
                          <button
                            style={{
                              border: `${
                                !!employee.professionalEmail
                                  ? "1px solid rgb(166, 211, 223)"
                                  : "1px solid red"
                              }`,
                              borderRadius: "3px",
                              color: "#000",
                              backgroundColor: "#fff",
                              fontWeight: "600",
                            }}
                            onClick={() => setShowProfessionalEmailDialog(true)}
                          >
                            <span
                              className={`text-${
                                !!employee.professionalEmail
                                  ? "primary"
                                  : "danger"
                              } `}
                            >
                              {!!employee.professionalEmail && (
                                <i className="fas fa-edit  mr-1"></i>
                              )}
                              {!employee.professionalEmail && (
                                <i className="far fa-plus-square mr-1"></i>
                              )}
                              {employee.professionalEmail ||
                                "Add Professional Email"}{" "}
                            </span>
                          </button>
                        </label>
                      </div>

                      <div className="col-9 row">
                        <div className="col-4 justify-content-center">
                          {!idHasError && !isVerifing && !wasVerified && (
                            <span className="material-icons loaderIcon text-danger">
                              help
                            </span>
                          )}

                          {/* <SearchIcon className="loaderIcon" /> */}
                          {!!isVerifing && (
                            <i
                              className="fas fa-spinner spinner loaderIcon text-primary"
                              style={{ fontSize: "22px" }}
                            ></i>
                          )}

                          {!isVerifing && !!idHasError && (
                            <span className="loaderIcon text-danger d-flex align-items-center">
                              <span className="material-icons text-danger">
                                report_problem
                              </span>
                              <span style={{ fontSize: "10px" }}>Error</span>
                            </span>
                          )}

                          {!idHasError && !isVerifing && !!wasVerified && (
                            <span className="loaderIcon text-success d-flex align-items-center">
                              <span className="material-icons text-success ">
                                verified
                              </span>
                              <span style={{ fontSize: "10px" }}>Verified</span>
                            </span>
                          )}

                          <MaskedInput
                            mask={idNumberPattern}
                            className="form-control mb-3"
                            placeholder="NID Number"
                            guide={false}
                            value={employeeForm.idNumber}
                            onKeyPress={(e) => {
                              if (
                                e.key === "Enter" &&
                                e.target.value.length === 21
                              ) {
                                getNidDetails(e.target.value);
                              }
                            }}
                            onChange={(e) => {
                              setEmployeeForm({
                                ...employeeForm,
                                idNumber: e.target.value,
                              });

                              if (e.target.value.length === 21) {
                                getNidDetails(e.target.value);
                              } else {
                                setIdHasError(false);
                                setWasVerified(false);
                                setIsVerifing(false);
                              }

                              setErrors({
                                ...errors,
                                idNumberHasError: false,
                              });
                            }}
                          />

                          {errors.idNumberHasError && (
                            <div className="text-danger mb-1">
                              ID Number is required
                            </div>
                          )}

                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="lastName"
                            disabled={true}
                            label="Last Name"
                            variant="outlined"
                            className="mb-3"
                            value={employeeForm.lastName}
                          />

                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="firstName"
                            disabled={true}
                            label="First Name"
                            variant="outlined"
                            className="mb-3"
                            value={employeeForm.firstName}
                          />

                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="civilStatus"
                            disabled={true}
                            label="Civil Status"
                            variant="outlined"
                            value={employeeForm.civilStatus}
                          />
                        </div>

                        <div className="col-4  text-left">
                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="gender"
                            disabled={true}
                            label="Gender"
                            variant="outlined"
                            className="mb-3 font-weight-bold"
                            value={employeeForm.gender}
                          />

                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="fatherNames"
                            value={employeeForm.fatherNames}
                            disabled={true}
                            label="Father Names"
                            variant="outlined"
                            className="mb-3"
                          />

                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="motherNames"
                            value={employeeForm.motherNames}
                            disabled={true}
                            label="Mother Names"
                            variant="outlined"
                            className="mb-3"
                          />

                          <TextField
                            disabled={
                              showNextOfKinDialog || showProfessionalEmailDialog
                            }
                            fullWidth
                            size="small"
                            rows={4}
                            autoComplete="false"
                            name="phone"
                            label="Phone"
                            variant="outlined"
                            value={employeeForm.phoneNumber}
                            onChange={(e) => {
                              setEmployeeForm({
                                ...employeeForm,
                                phoneNumber: e.target.value,
                              });

                              setErrors({
                                ...errors,
                                phoneNumberHasError: false,
                              });

                              if (
                                e.target.value !== employee.phoneNumber ||
                                employee.email !== employeeForm.email
                              )
                                setChanged(true);
                              else setChanged(false);
                            }}
                          />
                          {errors.phoneNumberHasError && (
                            <small className="text-danger mt-1">
                              Phone Number is required
                            </small>
                          )}
                        </div>

                        <div className="col-4 text-left">
                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="countryOfBirth"
                            value={employeeForm.countryOfBirth || ""}
                            disabled={true}
                            label="Country of Birth"
                            variant="outlined"
                            className="mb-3"
                          />

                          <TextField
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="dateOfBirth"
                            value={
                              employeeForm.dateOfBirth
                                ? moment(employeeForm.dateOfBirth).format(
                                    "DD/MM/yyyy"
                                  )
                                : ""
                            }
                            disabled={true}
                            label="Date Of Birth"
                            variant="outlined"
                            className="mb-3"
                          />

                          <TextField
                            disabled
                            style={{ backgroundColor: "#e9ecef" }}
                            fullWidth
                            size="small"
                            rows={4}
                            name="placeOfBirth"
                            value={employeeForm.placeOfBirth || ""}
                            label="Place of Birth"
                            variant="outlined"
                            className="mb-3"
                          />

                          <TextField
                            disabled={
                              showNextOfKinDialog || showProfessionalEmailDialog
                            }
                            fullWidth
                            size="small"
                            rows={4}
                            autoComplete="false"
                            name="email"
                            label="Personal email"
                            placeholder="Personal email"
                            variant="outlined"
                            value={employeeForm.email}
                            onChange={(e) => {
                              setEmployeeForm({
                                ...employeeForm,
                                email: e.target.value,
                              });

                              setErrors({
                                ...errors,
                                emailHasError: false,
                                emailError: "",
                              });

                              if (
                                e.target.value !== employee.email ||
                                employee.phoneNumber !==
                                  employeeForm.phoneNumber
                              )
                                setChanged(true);
                              else setChanged(false);
                            }}
                          />

                          {errors.emailHasError && (
                            <small className="text-danger">
                              {errors.emailError ||
                                "Personal email is required"}
                            </small>
                          )}
                        </div>
                        {isPullRequest && (
                          <div className="col-8 mt-3 text-left">
                            <Autocomplete
                              size="small"
                              id="positionPull"
                              name="positionPull"
                              defaultValue={null}
                              value={position}
                              options={[...positions].filter(
                                (position) => position.numberOfVacantPosts > 0
                              )}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              onChange={(event, position) => {
                                setPosition(position || null);
                                if (!!position) setChanged(true);

                                setErrors({
                                  ...errors,
                                  positionHasError: false,
                                });
                              }}
                              getOptionLabel={(option) =>
                                getNo(option?.id, positions) +
                                ":" +
                                option?.name
                              }
                              renderOption={(props, position) => (
                                <Box component="li" {...props}>
                                  {getNo(position?.id, positions)}:{" "}
                                  {position?.name}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  required={true}
                                  {...params}
                                  label="Select position"
                                  placeholder="Select position"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "positionPull", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />

                            {errors.positionHasError && (
                              <small className="text-danger mb-1">
                                Please, select position
                              </small>
                            )}
                          </div>
                        )}

                        {isPullRequest && (
                          <div className="col-4 mt-3 text-left">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DesktopDatePicker
                                label="Employment date"
                                inputFormat="MM/dd/yyyy"
                                value={employeeForm.employmentDate}
                                onChange={(date) => {
                                  setEmployeeForm({
                                    ...employeeForm,
                                    employmentDate: date,
                                  });

                                  setChanged(true);

                                  setErrors({
                                    ...errors,
                                    employmentDateHasError: false,
                                  });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    required={true}
                                    fullWidth
                                    size="small"
                                    {...params}
                                  />
                                )}
                              />

                              {errors.employmentDateHasError && (
                                <small className="text-danger mt-1">
                                  Employment date is required{" "}
                                </small>
                              )}
                            </LocalizationProvider>
                          </div>
                        )}
                      </div>

                      <div className="col-12 text-center ">
                        <button
                          disabled={
                            loading || !changed || showProfessionalEmailDialog
                          }
                          onClick={doUpdatePersonalInfo}
                          type="button"
                          className={`btn btn-primary text-uppercase  px-4  ${
                            !!isPullRequest ? "mt-3" : "mt-n4"
                          }`}
                        >
                          {loading && !showProfessionalEmailDialog
                            ? "Updating..."
                            : "Save changes"}
                        </button>
                      </div>
                      <div className="col-12 mt-3">
                        <div
                          className=" rounded px-3 pt-2 pb-3 "
                          style={{
                            border: "1px solid #d1cdcd",
                            backgroundColor: "#fafafa",
                            backgroundColor: "#f0f1ec",
                          }}
                        >
                          <div className="row ">
                            <div className="col-12 text-left py-0">
                              <h6 className="text-uppercase">Next of kin</h6>
                            </div>
                            <div className="col-12 text-left ">
                              <List
                                sx={{
                                  width: "100%",
                                  borderRadius: "8px",
                                  border: `1px solid #c8c6c6`,
                                }}
                                dense
                                className="bg-light "
                              >
                                <ListItem
                                  style={{
                                    cursor: "default",
                                  }}
                                  secondaryAction={
                                    <span className="d-flex align-items-center">
                                      {!!employeeProfile.nextOfKin && (
                                        <>
                                          {employeeProfile.nextOfKin
                                            .genderId === "M" && (
                                            <span className="material-icons mr-1">
                                              male
                                            </span>
                                          )}

                                          {employeeProfile.nextOfKin
                                            .genderId === "F" && (
                                            <span className="material-icons mr-1">
                                              female
                                            </span>
                                          )}
                                        </>
                                      )}
                                      {!!employeeProfile.nextOfKin && (
                                        <span className="badge badge-primary">
                                          {
                                            employeeProfile.nextOfKin
                                              .relationshipName
                                          }
                                        </span>
                                      )}

                                      <span
                                        style={{
                                          backgroundColor: "#fff",
                                          border: "1px solid #c8c6c6",
                                        }}
                                        className="p-1 rounded ml-2"
                                      >
                                        <IconButton
                                          size="small"
                                          edge="end"
                                          color="primary"
                                          onClick={() => {
                                            setShowNextOfKinDialog(true);

                                            if (!!employeeProfile.nextOfKin)
                                              setNextOfKin({
                                                employeeId:
                                                  employeeProfile.nextOfKin
                                                    .employeeId,
                                                idNumber:
                                                  employeeProfile.nextOfKin
                                                    .idNumber,
                                                relationshipId:
                                                  employeeProfile.nextOfKin
                                                    .relationshipId,
                                                foreName:
                                                  employeeProfile.nextOfKin
                                                    .firstName,
                                                surnames:
                                                  employeeProfile.nextOfKin
                                                    .lastName,
                                                bankId:
                                                  employeeProfile.nextOfKin
                                                    .bankId,
                                                bankAccountNumber:
                                                  employeeProfile.nextOfKin
                                                    .bankAccountNumber,
                                                phoneNumber:
                                                  employeeProfile.nextOfKin
                                                    .phoneNumber,
                                                email:
                                                  employeeProfile.nextOfKin
                                                    .email,

                                                nidVerified:
                                                  employeeProfile.nextOfKin
                                                    .nidVerified,
                                              });
                                          }}
                                        >
                                          {!employeeProfile.nextOfKin && (
                                            <span className="material-icons">
                                              add
                                            </span>
                                          )}
                                          {!!employeeProfile.nextOfKin && (
                                            <span className="material-icons">
                                              edit
                                            </span>
                                          )}
                                        </IconButton>
                                      </span>
                                    </span>
                                  }
                                >
                                  <ListItemAvatar>
                                    <Avatar>
                                      <span className="material-icons">
                                        person_pin
                                      </span>
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <span>
                                        {!employeeProfile.nextOfKin && (
                                          <span className="lead d-block  text-center mr-4">
                                            --Next kin not added--
                                          </span>
                                        )}
                                        {!!employeeProfile.nextOfKin && (
                                          <strong>
                                            {employeeProfile.nextOfKin.lastName}{" "}
                                            {
                                              employeeProfile.nextOfKin
                                                .firstName
                                            }
                                          </strong>
                                        )}
                                      </span>
                                    }
                                    secondary={
                                      <>
                                        {!!employeeProfile.nextOfKin && (
                                          <>
                                            <span>
                                              {" "}
                                              Bank account:{" "}
                                              {
                                                employeeProfile.nextOfKin
                                                  .bankAccountNumber
                                              }
                                              {" ( "}
                                              {
                                                employeeProfile.nextOfKin
                                                  .bankName
                                              }
                                              {" ) "}
                                            </span>
                                            <br />
                                            <span>
                                              {" "}
                                              Contacts:{" "}
                                              {
                                                employeeProfile.nextOfKin
                                                  .phoneNumber
                                              }{" "}
                                              /{" "}
                                              {employeeProfile.nextOfKin.email}{" "}
                                            </span>
                                          </>
                                        )}
                                      </>
                                    }
                                  />
                                </ListItem>
                              </List>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <AddOrUpdateProfessionalEmail
                    showProfessionalEmailDialog={showProfessionalEmailDialog}
                    setShowProfessionalEmailDialog={
                      setShowProfessionalEmailDialog
                    }
                    employee={employee}
                    loading={loading}
                    onWait={onWait}
                    saveEmployeeProfessionalEmail={
                      saveEmployeeProfessionalEmail
                    }
                  />
                </React.Fragment>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      {showNextOfKinDialog && (
        <AddOrUpdateNextOfKin
          showNextOfKinDialog={showNextOfKinDialog}
          setShowNextOfKinDialog={setShowNextOfKinDialog}
          addOrUpdateNextOfKin={addOrUpdateNextOfKin}
          loading={loading}
          nextOfKin={nextOfKin}
          relationships={relationships}
          setNextOfKin={setNextOfKin}
          employee={employee}
          onWait={onWait}
          banks={banks}
          isEdit={!!employeeProfile.nextOfKin}
        />
      )}
    </>
  );
};

const mapStateToProps = ({
  nationalIdDetail,
  loading,
  onWait,
  positions,
  banks,
  relationships,
  verifyRssbNumber,
  employeeProfile,
}) => {
  return {
    nationalIdDetail,
    loading,
    onWait,
    positions,
    banks,
    relationships,
    verifyRssbNumber,
    employeeProfile,
  };
};
export default connect(mapStateToProps, {
  getNationalIdDetail,
  getEmployee,
  getUnitPositions,
  getBanks,
  getRelationships,
  updatePersonalInfo,
  updateEmployment,
  updateEmployeeInsurance,
  updateBanking,
  deleteNextOfKin,
  addOrUpdateNextOfKin,
  getEmployeeQualifications,
  getEmployeeCertificates,
  saveEmployeeProfessionalEmail,
  saveEmployee,
})(EmployeePersonalInfoDialog);

const AddOrUpdateNextOfKin = (props) => {
  const {
    showNextOfKinDialog,
    setShowNextOfKinDialog,
    loading,
    addOrUpdateNextOfKin,
    nextOfKin,
    setNextOfKin,
    relationships,
    employee,
    onWait,
    banks,
    isEdit,
  } = props;

  const [bank, setBank] = useState(null);
  const [relationship, setRelationship] = useState(null);

  useEffect(() => {
    setRelationship(
      relationships.find(({ id }) => id === nextOfKin.relationshipId)
    );

    setBank(banks.find(({ id }) => id === nextOfKin.bankId));
  }, [nextOfKin]);

  const [errors, setErrors] = useState({
    idNumberHasError: false,
    relationshipIdHasError: false,
    bankAccountHasError: false,
    bankIdHasError: false,
    phoneNumberHasError: false,
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      idNumberHasError: false,
      relationshipIdHasError: false,
      bankAccountHasError: false,
      bankIdHasError: false,
      phoneNumberHasError: false,
      hasError: false,
    };

    if (nextOfKin.idNumber.length < 21) {
      error.idNumberHasError = true;
      error.hasError = true;
    }

    if (!nextOfKin.relationshipId) {
      error.relationshipIdHasError = true;
      error.hasError = true;
    }

    if (!nextOfKin.bankAccountNumber) {
      error.bankAccountHasError = true;
      error.hasError = true;
    }

    if (!nextOfKin.bankId) {
      error.bankIdHasError = true;
      error.hasError = true;
    }

    if (!nextOfKin.phoneNumber) {
      error.phoneNumberHasError = true;
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      showError("Please fill out all the required fields");
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator())
      addOrUpdateNextOfKin(
        {
          ...nextOfKin,
          employeeId: employee.id,
        },

        setShowNextOfKinDialog,
        setNextOfKin
      );
  };

  const [isVerifing, setIsVerifing] = useState(false);
  // const [wasVerified, setWasVerified] = useState(false);
  const [idHasError, setIdHasError] = useState(false);

  const onClose = () => {
    setShowNextOfKinDialog(false);
    setNextOfKin({ relationshipId: null, rssbNumber: "" });
  };

  const verifyNidNumber = async (idNumber) => {
    try {
      setNextOfKin({
        ...nextOfKin,

        nidVerified: false,
      });
      setIsVerifing(true);

      const { data } = await axios.get(
        "/api/external/verify-nid-number/" + idNumber
      );

      setNextOfKin({
        ...nextOfKin,
        idNumber,
        foreName: data.foreName,
        surnames: data.surnames,
        nidVerified: true,
      });

      setIdHasError(false);
      setIsVerifing(false);
    } catch (error) {
      setIdHasError(true);
      setNextOfKin({
        ...nextOfKin,

        nidVerified: false,
      });
      setIsVerifing(false);
    }
  };

  // useEffect(() => {
  //   if (!!nextOfKin.idNumber && nextOfKin.idNumber.length === 21)
  //     verifyNidNumber(nextOfKin.idNumber);
  // }, []);

  return (
    <>
      <Dialog
        onClose={onClose}
        open={showNextOfKinDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            {isEdit ? "Edit" : "Add"} Next Of Kin
          </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className="row no-gutters mt-2">
            <div className="col-6">
              {!idHasError && !isVerifing && !nextOfKin.nidVerified && (
                <SearchIcon className="loaderIcon" />
              )}

              {!!isVerifing && (
                <i
                  className="fas fa-spinner spinner loaderIcon text-primary"
                  style={{ fontSize: "22px" }}
                ></i>
              )}

              {!isVerifing && !!idHasError && (
                <span className="loaderIcon text-danger d-flex align-items-center">
                  <span className="material-icons text-danger">
                    report_problem
                  </span>
                  <span style={{ fontSize: "10px" }}>Error</span>
                </span>
              )}

              {!idHasError && !isVerifing && !!nextOfKin.nidVerified && (
                <span className="loaderIcon text-success d-flex align-items-center">
                  <span className="material-icons text-success ">verified</span>
                  <span style={{ fontSize: "10px" }}>Verified</span>
                </span>
              )}
              <MaskedInput
                mask={idNumberPattern}
                className="form-control"
                placeholder="NID Number"
                name="NIDNumber"
                guide={false}
                value={nextOfKin.idNumber}
                onKeyPress={(e) => {
                  setErrors({ ...errors, idNumberHasError: false });

                  if (e.key === "Enter" && e.target.value.length === 21) {
                    verifyNidNumber(e.target.value);
                  }
                }}
                onChange={(e) => {
                  setIdHasError(false);
                  setNextOfKin({
                    ...nextOfKin,

                    nidVerified: false,
                  });
                  setIsVerifing(false);

                  setNextOfKin({
                    ...nextOfKin,
                    idNumber: e.target.value,
                  });

                  if (e.target.value.length === 21) {
                    verifyNidNumber(e.target.value);
                  }
                }}
              />

              {!idHasError && !isVerifing && !!nextOfKin.nidVerified && (
                <span
                  className="text-primary mt-1"
                  style={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {nextOfKin.surnames} {nextOfKin.foreName}
                </span>
              )}

              {errors.idNumberHasError && (
                <small className="text-danger mt-1">Invalid NID Number</small>
              )}
            </div>

            <div className="col-6 pl-3">
              <Autocomplete
                size="small"
                id="relationshipId"
                defaultValue={null}
                value={relationship || null}
                options={relationships}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, relationship) => {
                  setRelationship(relationship || null);

                  setNextOfKin({
                    ...nextOfKin,
                    relationshipId: relationship?.id,
                  });

                  // const nextOfKinInsuranceHasError = false;

                  if (!!relationship)
                    setErrors({
                      ...errors,
                      relationshipIdHasError: false,
                    });
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, relationship) => (
                  <Box component="li" {...props}>
                    {relationship.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Relationships"
                    placeholder="Relationships"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "Relationships", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              {errors.relationshipIdHasError && (
                <small className="text-danger mt-1">
                  Relationship is required{" "}
                </small>
              )}
            </div>

            <div className="col-6">
              <TextField
                size="small"
                className="mt-3"
                name="bankAccount"
                fullWidth
                label="Bank Account"
                placeholder="Bank Account"
                variant="outlined"
                value={nextOfKin.bankAccountNumber}
                onChange={(e) => {
                  setErrors({ ...errors, bankAccountHasError: false });
                  setNextOfKin({
                    ...nextOfKin,
                    bankAccountNumber: e.target.value || "",
                  });
                }}
              />

              {errors.bankAccountHasError && (
                <small className="text-danger mt-1">
                  Bank account is required{" "}
                </small>
              )}
            </div>

            <div className="col-6 pl-3">
              <Autocomplete
                size="small"
                className="mt-3"
                id="bank"
                defaultValue={null}
                value={bank || null}
                options={banks}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, bank) => {
                  setBank(bank || null);

                  setNextOfKin({
                    ...nextOfKin,
                    bankId: bank?.id,
                  });

                  // const nextOfKinInsuranceHasError = false;

                  if (!!bank)
                    setErrors({
                      ...errors,
                      bankIdHasError: false,
                    });
                }}
                getOptionLabel={(option) => option.name}
                renderOption={(props, bank) => (
                  <Box component="li" {...props}>
                    {bank.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select bank"
                    placeholder="Select bank"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "banks", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              {errors.bankIdHasError && (
                <small className="text-danger mt-1">Bank is required </small>
              )}
            </div>
            <div className="col-6">
              <TextField
                size="small"
                className="mt-3"
                name="nextOfKinPhoneNumber"
                fullWidth
                autoComplete={false}
                label="Phone number"
                placeholder="Phone number"
                variant="outlined"
                value={nextOfKin.phoneNumber}
                onChange={(e) => {
                  setErrors({ ...errors, phoneNumberHasError: false });
                  setNextOfKin({
                    ...nextOfKin,
                    phoneNumber: e.target.value || "",
                  });
                }}
              />
              {errors.phoneNumberHasError && (
                <small className="text-danger mt-1">
                  Phone number is required{" "}
                </small>
              )}
            </div>
            <div className="col-6 pl-3">
              <TextField
                type="email"
                size="small"
                autoComplete={false}
                className="mt-3"
                name="nextOfKinEmail"
                fullWidth
                label="Email (Optional)"
                placeholder="Email (Optional)"
                variant="outlined"
                value={nextOfKin.email}
                onChange={(e) => {
                  setNextOfKin({
                    ...nextOfKin,
                    email: e.target.value || "",
                  });
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="py-3 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary text-uppercase ml-2"
            disabled={loading || !nextOfKin.nidVerified}
            onClick={onSave}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddOrUpdateProfessionalEmail = (props) => {
  const theme = useTheme();

  const {
    showProfessionalEmailDialog,
    setShowProfessionalEmailDialog,
    employee,
    loading,
    onWait,
    saveEmployeeProfessionalEmail,
  } = props;

  const onClose = () => {
    setShowProfessionalEmailDialog(false);
  };

  useEffect(() => {
    if (!showProfessionalEmailDialog) setEmail("");
    else setEmail(employee.professionalEmail || "");
  }, [showProfessionalEmailDialog]);

  const [email, setEmail] = useState(employee.professionalEmail || "");

  const [errors, setErrors] = useState({
    emailHasError: false,
    emailError: "",
    hasError: false,
  });

  const formValidator = () => {
    const error = {
      emailHasError: false,
      emailError: "",
      hasError: false,
    };

    if (!email) {
      error.emailHasError = true;
      error.hasError = true;
    }

    if (professionalEmailValidator.validate({ email }).error) {
      error.emailHasError = true;
      error.emailError = "Invalid professional email";
      error.hasError = true;
    }

    setErrors(error);

    if (error.hasError) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    if (!formValidator())
      saveEmployeeProfessionalEmail(
        {
          employeeId: employee.id,
          professionalEmail: email,
        },
        setShowProfessionalEmailDialog
      );
  };

  return (
    <Dialog onClose={onClose} open={showProfessionalEmailDialog}>
      <DialogTitle className="text-primary">
        <Typography variant="overline" display="block">
          {employee.professionalEmail ? "Edit" : "Add"} Professional Email
        </Typography>

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ width: "280px" }}>
          <TextField
            className="mt-2"
            size="small"
            id="professionalEmail"
            fullWidth
            autoFocus
            name="professionalEmail"
            type="email"
            label="Type professional email"
            variant="outlined"
            placeholder="Type professional email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value || "");

              setErrors({
                ...errors,
                emailError: "",
                emailHasError: false,
              });
            }}
          />
          {errors.emailHasError && (
            <small className="text-danger">
              {errors.emailError || "Professional email is required"}
            </small>
          )}
          <div className="d-flex justify-content-center mt-3">
            <Button
              disabled={onWait || loading}
              className="mb-2"
              style={{
                ...ActiveButton,
                border: `1px solid rgb(7, 142, 206)`,
                backgroundColor: "rgb(7, 142, 206)",
                "&:hover": {
                  backgroundColor: "#f0f2f5",
                },

                padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
              }}
              size="sm"
              onClick={onSave}
            >
              {onWait ? "Wait..." : "Save"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
