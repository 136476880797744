import { Tooltip, Skeleton, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getUnityIndicators,
  downloadUnitPerformanceReport,
  getUnits,
} from "../../../store/rbm/actions";
import IndicatorCard from "../components/IndicatorCard";
import IndicatorForm from "../components/IndicatorForm";
import RBMPageHeader from "../components/RBMPageHeader";
import OutputCard from "../components/OutputCard";
import axios from "axios";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const UnitIndicators = (props) => {
  const {
    loading,
    getUnits,
    units,
    unitIndicators,
    getUnityIndicators,
    user,
    downloadUnitPerformanceReport,
    selectedFiscalYear,
    selectedUnit,
    selectedPlanningEntityId,
  } = props;

  const [isEditing, setIsEditing] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecods, setTotalRecods] = useState(0);
  const [loader, setLoader] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(10);

  const handleLoadUnityIndicators = (query) => {
    if (!selectedUnit) getUnityIndicators(query, setTotalRecods, setLoader);
    else if (selectedUnit)
      getUnityIndicators(
        { ...query, unitId: selectedUnit?.id, action: selectedUnit?.action },
        setTotalRecods,
        setLoader
      );
  };

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };

    if (!!selectedFiscalYear.id) handleLoadUnityIndicators(query);

    if (!units.length) getUnits();
  }, [selectedFiscalYear?.id, selectedPlanningEntityId]);

  const [showIndicatorForm, setShowIndicatorForm] = useState(false);

  const [unitIndicatorsSummary, setUnitIndicatorsSummary] = useState({
    allAssignments: 0,
    ontrackAssignments: 0,
    dueAssignments: 0,
    reportedAssignments: 0,
    scoredAssignments: 0,
    rejectedAssignments: 0,
    unassignedItems: 0,
    score: 0,
  });

  const getUserEmployments = () =>
    user.employments.filter(
      ({ entitySectorId }) => entitySectorId === user.selectedEntitySector.id
    );

  useEffect(() => {
    document.title =
      "IPPIS - RBM Unit Indicators | " + !selectedUnit
        ? getUserEmployments()[0]?.unitName?.toUpperCase()
        : selectedUnit?.name?.toUpperCase();
  });

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };

      handleLoadUnityIndicators(query);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    handleLoadUnityIndicators(query);
  };

  const getUnitIndicatorSummary = async () => {
    const { data } = await axios.get(
      `/api/rbm/unit-indicator-summary/${selectedUnit?.id || ""}`
    );

    setUnitIndicatorsSummary(data);
  };

  useEffect(() => {
    getUnitIndicatorSummary();
  }, [unitIndicators]);

  return (
    <>
      <div className="row no-gutter justify-content-center">
        <div className="col-12 col-lg-11 px-0 px-md-3">
          <h6 className="d-flex justify-content-between">
            <span className="d-flex align-items-center">
              <span className="text-uppercase ">
                {!selectedUnit ? "My Unit" : selectedUnit?.name} Assignments
                {!!unitIndicatorsSummary.unassignedItems && (
                  <Tooltip title="View">
                    <span
                      onClick={() => {
                        const _currentPage = 1;
                        setCurrentPage(_currentPage);

                        const _searchTerm =
                          searchTerm === "unassigned" ? "" : "unassigned";
                        setSearchTerm(_searchTerm);

                        const query = {
                          searchTerm: _searchTerm,
                          page: _currentPage,
                          size: itemPerPage,
                        };

                        handleLoadUnityIndicators(query);
                      }}
                      className={`badge text-danger ml-2`}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {searchTerm !== "unassigned" && (
                        <CheckBoxOutlineBlankOutlinedIcon />
                      )}
                      {searchTerm === "unassigned" && <CheckBoxOutlinedIcon />}
                      {unitIndicatorsSummary.unassignedItems} Unassigned
                    </span>
                  </Tooltip>
                )}
              </span>
            </span>
          </h6>

          <div className="mb-3">
            <RBMPageHeader
              labels={[
                {
                  title: "Items",
                  value: unitIndicators.length || 0,
                  type: "number",
                },
                {
                  title: "Assignments",
                  value: unitIndicatorsSummary.allAssignments || 0,
                  type: "number",
                },
                {
                  title: "Ontrack",
                  value: unitIndicatorsSummary.ontrackAssignments || 0,
                  type: "number",
                },
                {
                  title: "Due",
                  value: unitIndicatorsSummary.dueAssignments || 0,
                  type: "number",
                },
                {
                  title: "Reported",
                  value: unitIndicatorsSummary.reportedAssignments || 0,
                  type: "number",
                },
                {
                  title: "Scored",
                  value: unitIndicatorsSummary.scoredAssignments || 0,
                  type: "number",
                },
                {
                  title: "Rejected",
                  value: unitIndicatorsSummary.rejectedAssignments || 0,
                  type: "number",
                },
              ]}
              progress={{
                title: "PROGRESS",
                value: unitIndicatorsSummary.score,
              }}
              buttons={[
                {
                  fun: () =>
                    downloadUnitPerformanceReport(user.employments[0].unitId),
                  title: "DOWNLOAD",
                },
              ]}
              loading={loading}
            />
          </div>

          <div className="rbm-shadow rounded px-4 py-3 ">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                value={searchTerm}
                id="filter"
                placeholder="Search..."
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };

                    handleLoadUnityIndicators(query);
                  }
                }}
              />
            </div>
            {unitIndicators &&
              unitIndicators.map((indicator, index) => (
                <React.Fragment key={indicator.id}>
                  {indicator.itemTypeId === 1 && (
                    <OutputCard
                      output={indicator}
                      totalWeight={0}
                      isUnitIndicator={true}
                      isForMonitoring={!!selectedUnit}
                    />
                  )}
                  {indicator.itemTypeId === 2 && (
                    <IndicatorCard
                      // index={getNo(indicator.id)}
                      indicator={indicator}
                      setIsEditing={setIsEditing}
                      setShowIndicatorForm={setShowIndicatorForm}
                      // selectedOutput={selectedOutput}
                      isForMonitoring={!!selectedUnit}
                    />
                  )}
                </React.Fragment>
              ))}

            {!unitIndicators[0] && loading && !unitIndicators.length && (
              <div>
                <Skeleton
                  variant="rectangular"
                  className="mb-3 mt-2"
                  height={118}
                />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
              </div>
            )}

            {!unitIndicators[0] && !loading && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container">
                  <p className="lead">No indicator found.</p>
                </div>
              </div>
            )}

            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecods / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!unitIndicators.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    handleLoadUnityIndicators(query);
                  }}
                >
                  {[10, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
      </div>

      <IndicatorForm
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        showIndicatorForm={showIndicatorForm}
        setShowIndicatorForm={setShowIndicatorForm}
      />

      <div
        className={`page-loading-void position-fixed ${
          loader && !!unitIndicators[0] ? "d-block" : "d-none"
        }`}
      >
        {loader && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({
  unitIndicators,
  loading,
  units,
  user,
  selectedIndicator,
  strongPassword,
  unitIndicatorsSummary,
  selectedUserEntity,
  selectedFiscalYear,
  selectedPlanningEntityId,
}) => {
  return {
    unitIndicators,
    loading,
    units,
    user,
    selectedIndicator,
    strongPassword,
    unitIndicatorsSummary,
    selectedUserEntity,
    selectedFiscalYear,
    selectedPlanningEntityId,
  };
};
export default connect(mapStateToProps, {
  getUnityIndicators,
  getUnits,
  downloadUnitPerformanceReport,
})(UnitIndicators);
