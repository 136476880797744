import { Tooltip, Skeleton, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import {
  getOutputs,
  downloadInstitutionPerformanceReport,
  downloadInstitutionEmployeesScores,
  downloadActionPlanTemplate,
  removeActionPlan,
} from "../../../store/rbm/actions";
import OutputCard from "../components/OutputCard";
import OutputForm from "../components/OutputForm";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import AddIcon from "@mui/icons-material/Add";
import RBMPageHeader from "../components/RBMPageHeader";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import AddActionPlan from "../components/AddActionPlan";
import OutputAssignmentForm from "../components/OutputAssignmentForm";
import DeleteSweepRoundedIcon from "@mui/icons-material/DeleteSweepRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import axios from "axios";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const Outputs = (props) => {
  const {
    outputs,
    getOutputs,
    selectedPlanningEntityId,
    selectedFiscalYear,
    loading,
    user,
    downloadInstitutionPerformanceReport,
    downloadActionPlanTemplate,
    removeActionPlan,
  } = props;

  const [outputSummary, setOutputSummary] = useState({
    allOutputs: 0,
    numOfIndicators: 0,
    allAssignments: 0,
    ontrackAssignments: 0,
    dueAssignments: 0,
    reportedAssignments: 0,
    scoredAssignments: 0,
    rejectedAssignments: 0,
    unassignedIndicators: 0,
    score: 0,
  });

  const getOutputsSummary = async () => {
    const { data } = await axios.get("/api/rbm/outputs-summary");

    setOutputSummary(data);
  };

  useEffect(() => {
    getOutputsSummary();
  }, [outputs]);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecods, setTotalRecods] = useState(0);
  const [loader, setLoader] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [deleteActionPlan, setDeleteActionPlan] = useState(false);
  const [deletingActionPlan, setDeletingActionPlan] = useState(false);

  useEffect(() => {
    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
    };

    if (!!selectedFiscalYear.id) getOutputs(query, setTotalRecods, setLoader);
  }, [selectedFiscalYear?.id, selectedPlanningEntityId]);

  useEffect(() => {
    document.title =
      "IPPIS - RBM Outputs | " +
      user.selectedEntitySector?.entityName +
      " | " +
      selectedFiscalYear?.name;
  });

  const { hasPermissions } = useRequiredAuth();

  const [showUploadActionPlanDialod, setShowUploadActionPlanDialod] =
    useState(false);

  const [outputFormOpen, setOutputFormOpen] = useState(false);

  const [showAssignmentForm, setShowAssignmentForm] = useState(false);

  const handleRemoveActionPlan = () => {
    removeActionPlan((status) => {
      setDeletingActionPlan(!status);
      if (status) {
        setDeleteActionPlan(false);
      }
    });
  };

  const onSearch = (term) => {
    setSearchTerm(term);

    if (term === "") {
      const _currentPage = 1;
      setCurrentPage(_currentPage);

      const _searchTerm = "";
      setSearchTerm(_searchTerm);

      const query = {
        searchTerm: _searchTerm,
        page: _currentPage,
        size: itemPerPage,
      };

      getOutputs(query, setTotalRecods, setLoader);
    }
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);

    const query = {
      searchTerm: searchTerm,
      page: currentPage,
      size: itemPerPage,
    };

    getOutputs(query, setTotalRecods, setLoader);
  };

  const keySet = !!outputs.find(({ isRRAKey }) => isRRAKey === true);

  return (
    <>
      <div className="row no-gutter justify-content-center">
        <div className="col-12 col-lg-11 px-0 px-md-3">
          <h6 className="d-flex justify-content-between">
            <span className="d-flex align-items-center">
              <span className="text-uppercase">
                My institution outputs
                {/* :{" "}
                <span className="text-primary">
                  {getUserEmployments()[0]?.entityName?.toUpperCase()}
                </span> */}
                {hasPermissions(["CAN_ADD_NEW_OUTPUT"]) && (
                  <Tooltip title="Assign All Outputs">
                    <span>
                      <Button
                        disabled={loading}
                        onClick={() => setShowAssignmentForm(true)}
                        color="primary"
                        variant="outlined"
                        disableElevation
                        style={{
                          borderRadius: "8px",
                          height: "24px",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textTransform: "initial",
                        }}
                        size="md"
                        className="ml-2 px-1"
                      >
                        <span className="material-icons">person_pin</span>
                        Assign All Outputs
                      </Button>
                    </span>
                  </Tooltip>
                )}
                {!!outputSummary.unassignedOutputs && (
                  <Tooltip title="View">
                    <span
                      onClick={() => {
                        const _currentPage = 1;
                        setCurrentPage(_currentPage);

                        const _searchTerm =
                          searchTerm === "unassigned-output"
                            ? ""
                            : "unassigned-output";
                        setSearchTerm(_searchTerm);

                        const query = {
                          searchTerm: _searchTerm,
                          page: _currentPage,
                          size: itemPerPage,
                        };

                        getOutputs(query, setTotalRecods, setLoader);
                      }}
                      className={`badge text-danger ml-2`}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {searchTerm !== "unassigned-output" && (
                        <CheckBoxOutlineBlankOutlinedIcon />
                      )}
                      {searchTerm === "unassigned-output" && (
                        <CheckBoxOutlinedIcon />
                      )}
                      {outputSummary.unassignedOutputs} Unassigned outputs
                    </span>
                  </Tooltip>
                )}
                {!!outputSummary.unassignedIndicators && (
                  <Tooltip title="View">
                    <span
                      onClick={() => {
                        const _currentPage = 1;
                        setCurrentPage(_currentPage);

                        const _searchTerm =
                          searchTerm === "unassigned" ? "" : "unassigned";
                        setSearchTerm(_searchTerm);

                        const query = {
                          searchTerm: _searchTerm,
                          page: _currentPage,
                          size: itemPerPage,
                        };

                        getOutputs(query, setTotalRecods, setLoader);
                      }}
                      className={`badge text-danger ml-2`}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {searchTerm !== "unassigned" && (
                        <CheckBoxOutlineBlankOutlinedIcon />
                      )}
                      {searchTerm === "unassigned" && <CheckBoxOutlinedIcon />}
                      {outputSummary.unassignedIndicators} Unassigned indicators
                    </span>
                  </Tooltip>
                )}
              </span>
            </span>

            <span>
              {(!outputs || outputs.length === 0) &&
                hasPermissions(["CAN_ADD_NEW_OUTPUT"]) &&
                !hasPermissions(["IS_TECHNICAL_HEAD"]) && (
                  <Tooltip title="Download Action Plan Template">
                    <span>
                      <Button
                        disabled={loading}
                        onClick={() => downloadActionPlanTemplate()}
                        variant="text"
                        size="small"
                        disableElevation
                        className="mr-2"
                      >
                        <DownloadIcon /> Download Action Plan
                      </Button>
                    </span>
                  </Tooltip>
                )}

              {hasPermissions(["CAN_ADD_NEW_OUTPUT"]) &&
                !hasPermissions(["IS_TECHNICAL_HEAD"]) &&
                (!outputs || outputs.length === 0) && (
                  <Tooltip title="Upload Action Plan">
                    <span>
                      <Button
                        disabled={loading}
                        onClick={() => setShowUploadActionPlanDialod(true)}
                        variant="contained"
                        size="small"
                        disableElevation
                        className="mr-2"
                        style={{
                          borderRadius: "8px",
                          height: "32px",
                          border: `1px solid #0785c0`,
                          color: "#0785c0",
                          alignItems: "center",
                          fontSize: ".75rem",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          backgroundColor: "#eaeade",
                        }}
                      >
                        <UploadIcon />
                        Upload Action Plan
                      </Button>
                    </span>
                  </Tooltip>
                )}

              {outputs &&
                outputs.length > 0 &&
                hasPermissions(["CAN_ADD_NEW_OUTPUT"]) &&
                !hasPermissions(["IS_TECHNICAL_HEAD"]) && (
                  <Tooltip title="Remove Action Plan">
                    <span>
                      <Button
                        disabled={loading}
                        onClick={() => setDeleteActionPlan(true)}
                        variant="outlined"
                        size="small"
                        disableElevation
                        className="mr-2"
                        color="error"
                      >
                        <DeleteSweepRoundedIcon /> Remove Action Plan
                      </Button>
                    </span>
                  </Tooltip>
                )}

              {hasPermissions(["CAN_ADD_NEW_OUTPUT"]) && (
                <Tooltip title="Add New Output">
                  <span>
                    <Button
                      disabled={loading}
                      onClick={() => setOutputFormOpen(true)}
                      color="primary"
                      variant="contained"
                      style={{
                        float: "right",
                        borderRadius: "8px",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                      }}
                      size="md"
                      className="ml-2"
                    >
                      <AddIcon />
                    </Button>
                  </span>
                </Tooltip>
              )}
            </span>
          </h6>

          {outputs && (
            <>
              <div className="mb-3">
                <RBMPageHeader
                  labels={[
                    {
                      title: "Outputs",
                      value: outputSummary.allOutputs,
                      type: "number",
                    },
                    {
                      title: "Indicators",
                      value: outputSummary.numOfIndicators || 0,
                      type: "number",
                    },
                    {
                      title: "Assignments",
                      value: outputSummary.allAssignments || 0,
                      type: "number",
                    },
                    {
                      title: "Ontrack",
                      value: outputSummary.ontrackAssignments || 0,
                      type: "number",
                    },
                    {
                      title: "Due",
                      value: outputSummary.dueAssignments || 0,
                      type: "number",
                    },
                    {
                      title: "Reported",
                      value: outputSummary.reportedAssignments || 0,
                      type: "number",
                    },
                    {
                      title: "Scored",
                      value: outputSummary.scoredAssignments || 0,
                      type: "number",
                    },
                    {
                      title: "Rejected",
                      value: outputSummary.rejectedAssignments || 0,
                      type: "number",
                    },
                  ]}
                  progress={{
                    title: "PROGRESS",
                    value: outputSummary.score,
                  }}
                  buttons={[
                    {
                      fun: () =>
                        downloadInstitutionPerformanceReport(
                          user.selectedEntitySector.id,
                          selectedPlanningEntityId
                        ),
                      title: "DOWNLOAD",
                    },
                  ]}
                  disabled={loading}
                  loading={loading}
                />
              </div>
            </>
          )}

          <div className="rbm-shadow rounded px-4 py-3 ">
            <div className="form-group mb-2">
              <input
                name="search"
                type="text"
                className="form-control"
                value={searchTerm}
                id="filter"
                placeholder="Search..."
                onChange={(e) => onSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };

                    getOutputs(query, setTotalRecods, setLoader);
                  }
                }}
              />
            </div>
            {outputs &&
              outputs.map((output, index) => (
                <OutputCard key={index} output={output} keySet={keySet} />
              ))}

            {!outputs[0] && loading && (
              <div>
                <Skeleton
                  variant="rectangular"
                  className="mb-3 mt-2"
                  height={118}
                />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
                <Skeleton variant="rectangular" className="mb-3" height={96} />
              </div>
            )}

            {!outputs[0] && !loading && (
              <div className="jumbotron jumbotron-fluid text-center">
                <div className="container">
                  <p className="lead">No output found.</p>
                </div>
              </div>
            )}

            <div
              className="d-flex justify-content-center  align-items-center"
              style={{ overflow: "hidden" }}
            >
              <div className="mt-3">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalRecods / itemPerPage)}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                  activeLinkClassName="active"
                />
              </div>

              {!!outputs.length && (
                <select
                  className="custom-select custom-select-sm mr-3 ml-2"
                  style={{ width: "100px" }}
                  value={itemPerPage}
                  onChange={(event) => {
                    const itemPerPage = +event.target.value;
                    setItemPerPage(itemPerPage);
                    const query = {
                      searchTerm: searchTerm,
                      page: currentPage,
                      size: itemPerPage,
                    };
                    getOutputs(query, setTotalRecods, setLoader);
                  }}
                >
                  {[10, 50, 100].map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
      </div>

      <OutputForm
        isEditing={false}
        setIsEditing={() => {}}
        closeOutputForm={() => setOutputFormOpen(false)}
        outputFormOpen={outputFormOpen}
        totalRecods={totalRecods}
        setTotalRecods={setTotalRecods}
      />

      <AddActionPlan
        setTotalRecods={setTotalRecods}
        open={showUploadActionPlanDialod}
        onClose={() => setShowUploadActionPlanDialod(false)}
      />

      {showAssignmentForm && (
        <OutputAssignmentForm
          showAssignmentForm={showAssignmentForm}
          setShowAssignmentForm={setShowAssignmentForm}
        />
      )}

      {deleteActionPlan && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to remove this Action Plan?`}
          setConfirmationDialog={() => setDeleteActionPlan(false)}
          onYes={handleRemoveActionPlan}
          loading={deletingActionPlan}
        />
      )}

      <div
        className={`page-loading-void position-fixed ${
          loader && !!outputs[0] ? "d-block" : "d-none"
        }`}
      >
        {loader && (
          <CircularProgress
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              color: "rgb(7, 142, 206)",
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
            size={40}
            thickness={4}
          />
        )}
      </div>

      <br />
    </>
  );
};

const mapStateToProps = ({
  outputs,
  selectedFiscalYear,
  loading,
  user,
  selectedPlanningEntityId,
}) => {
  return {
    outputs,
    selectedFiscalYear,
    loading,
    user,
    selectedPlanningEntityId,
  };
};
export default connect(mapStateToProps, {
  getOutputs,
  downloadInstitutionPerformanceReport,
  downloadInstitutionEmployeesScores,
  downloadActionPlanTemplate,
  removeActionPlan,
})(Outputs);
