import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getAdvertisementForSupport,
  updateAdvertisementForSupport,
} from "../../../store/support/actions";
import TableSkeleton from "../../common/components/TableSkeleton";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";

const Advertisements = (props) => {
  const { getAdvertisementForSupport, updateAdvertisementForSupport } = props;
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [advertisements, setAdvertisements] = useState([]);
  const [searchType, setSearchType] = useState("Advertisement ID");
  const [searchValue, setSearchValue] = useState("");
  const [formData, setFormData] = useState({
    id: null,
    action: null,
    value: null,
    prevValue: null,
  });
  const [showAlert, setShowAlert] = useState(false);

  const onFind = async (e) => {
    e.preventDefault();

    if (!searchValue) {
      return;
    }

    getAdvertisementForSupport(searchValue, (status, data) => {
      setIsSearching(status);
      if (!status && data) {
        setAdvertisements(data);
      }
    });
  };

  const handleRequest = () => {
    updateAdvertisementForSupport(formData.id, formData, (status, res) => {
      setIsSubmitting(status);
      if (!status && res) {
        getAdvertisementForSupport(formData.id, (status, data) => {
          setIsSearching(status);
          if (!status && data) {
            setAdvertisements(data);
            setFormData({
              id: null,
              action: null,
              value: null,
              prevValue: null,
            });
            setShowAlert(false);
          }
        });
      }
    });
  };

  return (
    <>
      <div
        className="elevated rounded p-3"
        style={{
          backgroundColor: "#fafafa",
          border: "1px solid #17a2b8",
          minHeight: "75vh",
        }}
      >
        <form onSubmit={onFind} autoComplete="off">
          <div className="row  mb-3">
            {/* <div className="col-3">
              <Autocomplete
                loading={loading}
                size="small"
                id="entity"
                defaultValue={null}
                value={entity}
                options={filteredOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, entity) => {
                  setEntity(entity || null);
                }}
                getOptionLabel={(option) =>
                  option?.entity.name +
                  (entity?.entity.acronym
                    ? "(" + entity?.entity.acronym + ")"
                    : "")
                }
                renderOption={(props, entity) => (
                  <Box component="li" {...props}>
                    {entity?.entity.name}
                    {entity?.entity.acronym && <>({entity?.entity.acronym})</>}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    // required={true}
                    {...params}
                    label="Select the institution"
                    placeholder="Select the institution"
                    onChange={(e) => {
                      handleKeywordChange(e);
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "entity", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <div className="col-12 mb-2">{loading && <LinearProgress />}</div>
            </div> */}
            <div className="col-10">
              <TextField
                fullWidth
                size="small"
                // rows={4}
                name="searchTerm"
                label={`Search by ${searchType}`}
                placeholder={`Search by ${searchType}`}
                variant="outlined"
                className="font-weight-bold mb-2"
                value={searchValue || ""}
                onChange={(e) => {
                  setSearchValue(e.target.value || "");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        style={{
                          borderRadius: "0px",
                        }}
                        className={`badge badge-primary`}
                      >
                        {searchType}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="col-2">
              <button
                disabled={isSearching || !searchValue}
                type="submit"
                className="btn btn-primary btn-block text-uppercase ml-1 d-flex align-items-center "
              >
                <span className="material-icons">search</span>
                Search
              </button>
            </div>
          </div>
        </form>

        <table className="table table-striped table-hover table-sm  fixTableHead">
          <thead>
            <tr style={{ backgroundColor: "#f0f2f5" }}>
              <th>#</th>
              <th>Entity</th>
              <th>Unit</th>
              <th>Position</th>
              <th
                style={{
                  maxWidth: "150px",
                }}
              >
                #Posts
              </th>
              <th>WrittenExam</th>
              <th>OralExam</th>
              <th>Psy.Test</th>
              <th>EnableExamKey</th>
              <th>EnableIP</th>
              <th>UnlockedWrittenExamDate</th>
              <th>UnlockedOralExamDate</th>
              <th>DisableExamAccessibility</th>
              <th scope="col" className="text-left">
                PublicationDate
              </th>
              <th scope="col" className="text-left">
                Deadline
              </th>
              <th>Status</th>
            </tr>
          </thead>
          {isSearching && !advertisements.length ? (
            <TableSkeleton cols={15} />
          ) : (
            <tbody>
              {advertisements.map((req) => (
                <tr key={req.id}>
                  <td>{req.id}</td>
                  <td>
                    {req.entityName} / <b>{req.sectorName}</b>
                  </td>
                  <td>{req.unitName}</td>
                  <td>{req.positionName}</td>
                  <td
                    className={`text-center`}
                    style={{
                      maxWidth: "150px",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        maxWidth: "150px",
                      }}
                    >
                      {req.numberOfPosts}
                    </div>
                  </td>
                  <td>{req.willHaveWrittenExam ? "Allowed" : "Disallowed"}</td>
                  <td>{req.willHaveOralExam ? "Allowed" : "Disallowed"}</td>
                  <td>
                    {req.willHavePsychometricTest ? "Allowed" : "Disallowed"}
                  </td>

                  <td>
                    <IconButton
                      aria-label="change status of enable Exam Key"
                      size="small"
                      onClick={() => {
                        setFormData({
                          id: req.id,
                          action: "enableExamKey",
                          value: !req.enableExamKey,
                          prevValue: req.enableExamKey,
                        });
                        setShowAlert(true);
                      }}
                      disabled={isSubmitting || !!formData.id}
                    >
                      {req.enableExamKey ? (
                        <CheckBoxRoundedIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      )}
                    </IconButton>
                  </td>

                  <td>
                    <IconButton
                      aria-label="change status of enable IP"
                      size="small"
                      onClick={() => {
                        setFormData({
                          id: req.id,
                          action: "enableIP",
                          value: !req.enableIP,
                          prevValue: req.enableIP,
                        });
                        setShowAlert(true);
                      }}
                      disabled={isSubmitting || !!formData.id}
                    >
                      {req.enableIP ? (
                        <CheckBoxRoundedIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      )}
                    </IconButton>
                  </td>

                  <td>
                    <IconButton
                      aria-label="change status of Written Exam Date Unlocked"
                      size="small"
                      onClick={() => {
                        setFormData({
                          id: req.id,
                          action: "isWrittenExamDateUnlocked",
                          value: !req.isWrittenExamDateUnlocked,
                          prevValue: req.isWrittenExamDateUnlocked,
                        });
                        setShowAlert(true);
                      }}
                      disabled={isSubmitting || !!formData.id}
                    >
                      {req.isWrittenExamDateUnlocked ? (
                        <CheckBoxRoundedIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      )}
                    </IconButton>
                  </td>

                  <td>
                    <IconButton
                      aria-label="change status of Oral Exam Date Unlocked"
                      size="small"
                      onClick={() => {
                        setFormData({
                          id: req.id,
                          action: "isOralExamDateUnlocked",
                          value: !req.isOralExamDateUnlocked,
                          prevValue: req.isOralExamDateUnlocked,
                        });
                        setShowAlert(true);
                      }}
                      disabled={isSubmitting || !!formData.id}
                    >
                      {req.isOralExamDateUnlocked ? (
                        <CheckBoxRoundedIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      )}
                    </IconButton>
                  </td>

                  <td>
                    <IconButton
                      aria-label="change status of disable Exam Accessibility"
                      size="small"
                      onClick={() => {
                        setFormData({
                          id: req.id,
                          action: "disableExamAccessibility",
                          value: !req.disableExamAccessibility,
                          prevValue: req.disableExamAccessibility,
                        });
                        setShowAlert(true);
                      }}
                      disabled={isSubmitting || !!formData.id}
                    >
                      {req.disableExamAccessibility ? (
                        <CheckBoxRoundedIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      ) : (
                        <CheckBoxOutlineBlankRoundedIcon
                          fontSize="inherit"
                          color="inherit"
                        />
                      )}
                    </IconButton>
                  </td>


                  <td className="text-center">
                    {moment(req.openingDate).format("DD/MM/YYYY")}
                  </td>
                  <td className="text-center">
                    {moment(req.closingDate).format("DD/MM/YYYY")}
                  </td>

                  <td>{req.statusName}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {!advertisements.length && !isSearching && (
          <div className="jumbotron jumbotron-fluid text-center  mt-0">
            <div className="container py-5">
              <p className="lead">No record found</p>
            </div>
          </div>
        )}
      </div>

      {showAlert && !!formData.id && (
        <ConfirmationDialog
          confirmationDialog={true}
          message={`Are you sure you want to ${
            formData.action === "isWrittenExamDateUnlocked" && formData.value
              ? " unlock written exam date"
              : formData.action === "isWrittenExamDateUnlocked" &&
                !formData.value
              ? "lock written exam date"
              : formData.action === "isOralExamDateUnlocked" && formData.value
              ? " unlock oral exam date"
              : formData.action === "isOralExamDateUnlocked" && !formData.value
              ? "lock oral exam date"
              : formData.action === "enableExamKey" && formData.value
              ? "enable exam key"
              : formData.action === "enableExamKey" && !formData.value
              ? "disable exam key"
              : formData.action === "enableIP" && formData.value
              ? "enable IP"
                : formData.action === "disableExamAccessibility" && formData.value
              ? "Disable Hr or CBM on Exam Accessibility"
              : formData.action === "enableIP" && !formData.value
              ? "disable IP"
              : "  do unknown action"
          }?`}
          
          setConfirmationDialog={() => {
            setFormData({
              id: null,
              action: null,
              value: null,
              prevValue: null,
            });
            setShowAlert(false);
          }}
          onYes={handleRequest}
        />
      )}
    </>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
export default connect(mapStateToProps, {
  getAdvertisementForSupport,
  updateAdvertisementForSupport,
})(Advertisements);
