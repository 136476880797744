import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getApplicationCV } from "../../../../store/profile/actions";
import PreviewPdfUrl from "../../../common/components/PreviewPdfUrl";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import NoResults from "../../../common/components/NoResults";
const CVUpload = (props) => {
  const { applicationId, applicationCV, getApplicationCV } = props;

  const [onViewDocument, setOnViewDocument] = useState(false);

  useEffect(() => {
    getApplicationCV(applicationId);
  }, []);

  return (
    <>
      {!!applicationCV && (
        <div
          className="d-flex mb-2 justify-content-between border rounded elevated align-items-center p-2"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div
            className="d-flex flex-row align-items-center px-1 py-2  cursor-pointer"
            style={{
              // border: "2px solid #adacac",
              borderRadius: "4px",
              height: "100%",
            }}
            onClick={() => {
              setOnViewDocument(true);
            }}
          >
            {!!applicationCV && (
              <PictureAsPdfRoundedIcon
                fontSize="large"
                style={{ color: "#fe4066" }}
              />
            )}
            <div className="ml-2 px-3">
              <span> Uploaded CV </span>
              <div style={{ fontSize: "10px" }} className="font-weight-bold">
                PDF
              </div>
            </div>
          </div>
        </div>
      )}

      {!!applicationId && !applicationCV && (
        <NoResults message="No Application CV" />
      )}

      {onViewDocument && !!applicationCV && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `CV`,
            url: process.env.REACT_APP_FILES_URL + "/preview/" + applicationCV,
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, applicationCV }) => ({
  loading,
  applicationCV,
});

export default connect(mapStateToProps, { getApplicationCV })(CVUpload);
