import {
  IconButton,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  Autocomplete,
  TextField,
  Button,
  Badge,
  Menu,
  MenuItem,
  Divider,
  Typography,
  DialogActions,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import ReactPaginate from "react-paginate";

import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import SearchBox from "../../common/components/SearchBox";
import { currencyFormat, totalSum } from "../../common/components/Utils";
import {
  getPayHistories,
  downloadPayHistory,
} from "../../../store/payroll/actions";
import PayHistoryDetailDialog from "./PayHistoryDetailDialog";
import ActionDialog from "../../common/components/ActionDialog";
import DownloadPaySlipDialog from "./DownloadPaySlipDialog";

const ActiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "#fff",
  alignItems: "center",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  backgroundColor: "rgb(7, 142, 206)",
  "&:hover": {
    backgroundColor: "#f0f2f5",
  },
};
const ITEM_HEIGHT = 60;

const EmployeePayHistoryDialog = (props) => {
  const {
    user,
    loading,
    payHistories,
    getPayHistories,
    downloadPayHistory,
    showEmployeePayHistory,
    setShowEmployeePayHistory,
    selectedEmployee,
  } = props;

  const [filteredPayHistories, setFilteredPayHistories] = useState([
    ...payHistories,
  ]);

  const [filterByYear, setFilterByYear] = useState(null);
  const [filterByMonth, setFilterByMonth] = useState(null);

  const [showPayHistoryDetail, setShowPayHistoryDetail] = useState(false);

  const [showDownloadPaySlip, setShowDownloadPaySlip] = useState(false);
  const [showDownloadPayHistory, setShowDownloadPayHistory] = useState(false);

  const [selectedPayHistory, setSelectedPayHistory] = useState(null);

  const theme = useTheme();

  const getFilterByYears = () => {
    let tempFilterByYears = [];
    filteredPayHistories.forEach((payHistory, i) => {
      if (!tempFilterByYears.find(({ name }) => name === payHistory.year))
        tempFilterByYears.push({
          id: i,
          name: payHistory.year,
        });
    });

    return tempFilterByYears;
  };

  const getFilterByMonths = () => {
    let tempFilterByMonths = [];
    payHistories.forEach((payHistory, i) => {
      if (!tempFilterByMonths.find(({ name }) => name === payHistory.month))
        tempFilterByMonths.push({
          id: i,
          name: payHistory.month,
        });
    });

    return tempFilterByMonths;
  };

  useEffect(() => {
    if (!!selectedEmployee) getPayHistories(selectedEmployee.id);
  }, [selectedEmployee]);

  useEffect(() => {
    const tempYear = filterByYear
      ? filterByYear.name.toString().toLowerCase()
      : null;
    const tempMonth = filterByMonth ? filterByMonth.name.toLowerCase() : null;
    const searching =
      !!tempYear && !!tempMonth
        ? "01." + tempMonth + "." + tempYear
        : !!tempMonth
        ? tempMonth
        : !!tempYear
        ? tempYear
        : "";

    const filtered = [...payHistories].filter(
      ({ payrollType, payrollId, year, month, dateHumanReadable }) =>
        payrollType.toLowerCase().includes(searching.toLowerCase()) ||
        payrollId.toLowerCase().includes(searching.toLowerCase()) ||
        year.toString().toLowerCase().includes(searching) ||
        month.toLowerCase().includes(searching) ||
        dateHumanReadable.toLowerCase().includes(searching)
    );

    setFilteredPayHistories(filtered);
    paginate(filtered);
  }, [filterByYear, filterByMonth, payHistories]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 12;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(() => {
    paginate(filteredPayHistories);
  }, [itemOffset, itemsPerPage, payHistories, filteredPayHistories]);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredPayHistories.length || 0;

    setItemOffset(newOffset);
  };

  const onClose = () => {
    setShowEmployeePayHistory(false);
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showEmployeePayHistory}
        maxWidth={"lg"}
        fullWidth
      >
        <DialogTitle className="text-dark">
          <Typography
            variant="overline"
            className="text-truncate"
            display="block"
            style={{ maxWidth: "90%" }}
          >
            Employee Pay History |{" "}
            <strong className="text-primary">
              {selectedEmployee?.lastName} {selectedEmployee?.firstName}
            </strong>{" "}
          </Typography>

          <Tooltip title="Download">
            <IconButton
              className="p-0"
              color="primary"
              // size="large"
              sx={{
                position: "absolute",
                right: 54,
                top: 12,
              }}
              onClick={() => {
                if (filterByYear && filterByMonth)
                  downloadPayHistory(
                    selectedEmployee.id,
                    setShowDownloadPayHistory,
                    "?year=" +
                      filterByYear.name +
                      "&month=" +
                      filterByMonth.name
                  );
                else if (filterByYear)
                  downloadPayHistory(
                    selectedEmployee.id,
                    setShowDownloadPayHistory,
                    "?year=" + filterByYear.name
                  );
                else
                  downloadPayHistory(
                    selectedEmployee.id,
                    setShowDownloadPayHistory
                  );
              }}
            >
              <span className="material-icons" style={{ fontSize: "28px" }}>
                cloud_download
              </span>
            </IconButton>
          </Tooltip>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers style={{ height: "85vh" }} className="py-0">
          <div className="w-100 ">
            <div
              style={{
                overflow: "hidden",
              }}
            >
              <div className="d-flex  flex-wrap justify-content-center mt-3">
                <div className="text-center border-dashed border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                  <div className="fw-semibold text-gray-400">
                    Total Base Pay
                  </div>
                  <div className="fs-6 fw-bold text-gray-700">
                    {currencyFormat(totalSum(filteredPayHistories, "basePay"))}
                  </div>
                </div>

                <div className="text-center border-dashed border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                  <div className="fw-semibold text-gray-400">
                    Total Partial Net
                  </div>
                  <div className="fs-6 fw-bold text-gray-700">
                    {currencyFormat(
                      totalSum(filteredPayHistories, "partialNet")
                    )}
                  </div>
                </div>

                <div className="text-center border-dashed border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                  <div className="fw-semibold text-gray-400">
                    Total Net Lumpsum
                  </div>
                  <div className="fs-6 fw-bold text-gray-700">
                    {currencyFormat(
                      totalSum(filteredPayHistories, "netLumpsum")
                    )}
                  </div>
                </div>

                <div className="text-center border-dashed border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                  <div className="fw-semibold text-gray-400">
                    Total Withheld
                  </div>
                  <div className="fs-6 fw-bold text-gray-700">
                    {currencyFormat(
                      totalSum(filteredPayHistories, "withHolds")
                    )}
                  </div>
                </div>

                <div className="text-center border-dashed border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-1 mt-1">
                  <div className="fw-semibold text-gray-400">
                    Total Net Salary
                  </div>
                  <div className="fs-6 fw-bold text-gray-700">
                    {currencyFormat(
                      totalSum(filteredPayHistories, "netSalary")
                    )}
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                {/* <div className="col-lg-4 mt-3">
                  <SearchBox
                    disabled={!payHistories.length}
                    onSearch={onSearch}
                    placeholder="Search…"
                  />
                </div> */}
                <div className="col-lg-6 mt-3">
                  <Autocomplete
                    size="small"
                    id="filterByYear"
                    disabled={!payHistories.length}
                    defaultValue={null}
                    value={filterByYear}
                    options={getFilterByYears()}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, filterByYear) => {
                      setFilterByYear(filterByYear || null);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, filterByYear) => (
                      <Box component="li" {...props} key={filterByYear.id}>
                        {filterByYear.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{
                          height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                        }}
                        {...params}
                        label="Filter By Year"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "filterByYear", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-lg-6 mt-3">
                  <Autocomplete
                    size="small"
                    id="filterByMonth"
                    disabled={!payHistories.length}
                    defaultValue={null}
                    value={filterByMonth}
                    options={getFilterByMonths()}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={(event, filterByMonth) => {
                      setFilterByMonth(filterByMonth || null);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, filterByMonth) => (
                      <Box component="li" {...props} key={filterByMonth.id}>
                        {filterByMonth.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        style={{
                          height: "35px!important",
                          position: "relative",
                          borderRadius: "8px",
                          padding: "1px!important",
                        }}
                        {...params}
                        label="Filter By Month"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "filterByMonth", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="mt-2">
                {!!filteredPayHistories.length && (
                  <table className="table table-striped table-hover table-sm ">
                    <thead>
                      <tr style={{ backgroundColor: "#f0f2f5" }}>
                        <td
                          style={{ backgroundColor: "#e9ecef", width: "48px" }}
                        >
                          Period
                        </td>
                        <td style={{ backgroundColor: "#e9ecef" }}>
                          Insitution
                        </td>
                        <td style={{ backgroundColor: "#e9ecef" }}>Position</td>
                        <td style={{ backgroundColor: "#e9ecef" }}>Level</td>
                        <td style={{ backgroundColor: "#e9ecef" }}>Index</td>
                        <td style={{ backgroundColor: "#e9ecef" }}>I.V</td>
                        <td style={{ backgroundColor: "#e9ecef" }}>Payroll</td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Basic S.
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Housing
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Transport
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Taxable
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Exempted
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Gross S.
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Gross Lp.
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Net Lp.
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Partial N.
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Withheld
                        </td>
                        <td
                          className="text-right"
                          style={{ backgroundColor: "#e9ecef" }}
                        >
                          Net Salary
                        </td>
                        <td style={{ backgroundColor: "#e9ecef" }}></td>
                      </tr>
                    </thead>

                    <tbody style={{ fontSize: "9px" }}>
                      {paginatedItems.map((payHistory, index) => (
                        <PayHistoryCard
                          key={index}
                          index={index}
                          payHistory={payHistory}
                          setSelectedPayHistory={setSelectedPayHistory}
                          setShowPayHistoryDetail={setShowPayHistoryDetail}
                          setShowDownloadPaySlip={setShowDownloadPaySlip}
                          loading={loading}
                        />
                      ))}
                    </tbody>
                  </table>
                )}

                <NoRecordMessage
                  isEmpty={!filteredPayHistories.length && !loading}
                  title="No record found."
                  message="You don't have any pay history."
                />

                <PreLoader isLoading={loading && !payHistories.length} />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="justify-content-center">
          <div style={{ overflow: "hidden" }}>
            {!!paginatedItems.length && payHistories.length >= itemsPerPage && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <span className="pr-1 d-none d-md-inline">Next</span>
                    <i className="fas fa-angle-double-right"></i>
                  </>
                }
                previousLabel={
                  <>
                    <i className="fas fa-angle-double-left"></i>
                    <span className="pl-1  d-none d-md-inline">Previous</span>
                  </>
                }
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                activeLinkClassName="active"
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
      {showPayHistoryDetail && (
        <PayHistoryDetailDialog
          showPayHistoryDetail={showPayHistoryDetail}
          setShowPayHistoryDetail={setShowPayHistoryDetail}
          selectedPayHistory={selectedPayHistory}
          setSelectedPayHistory={setSelectedPayHistory}
          selectedEmployee={selectedEmployee}
          setShowDownloadPaySlip={setShowDownloadPaySlip}
        />
      )}
      {showDownloadPaySlip && (
        <DownloadPaySlipDialog
          selectedPayHistory={selectedPayHistory}
          setSelectedPayHistory={setSelectedPayHistory}
          showDownloadPaySlip={showDownloadPaySlip}
          setShowDownloadPaySlip={setShowDownloadPaySlip}
        />
      )}

      {showDownloadPayHistory && (
        <ActionDialog
          showAction={showDownloadPayHistory}
          action="Downloading ..."
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, payHistories }) => {
  return { user, loading, payHistories };
};
export default connect(mapStateToProps, {
  getPayHistories,
  downloadPayHistory,
})(EmployeePayHistoryDialog);

const PayHistoryCard = (props) => {
  const {
    payHistory,
    index,
    setShowPayHistoryDetail,
    setSelectedPayHistory,
    setShowDownloadPaySlip,
    loading,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <tr key={index}>
        <td>{payHistory.periodId}</td>
        <td
          style={{
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {payHistory.entityName}
        </td>
        <td
          style={{
            maxWidth: "128px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {payHistory.positionName}
        </td>
        <td>{payHistory.levelName}</td>
        <td>{payHistory.index}</td>
        <td>{payHistory.indexValue}</td>
        <td>{payHistory.payrollType}</td>
        <td className="text-right">{currencyFormat(payHistory.basePay)}</td>
        <td className="text-right">
          {currencyFormat(payHistory.housingAllowance)}
        </td>
        <td className="text-right">
          {currencyFormat(payHistory.transportAllowance)}
        </td>
        <td className="text-right">
          {currencyFormat(payHistory.taxableAllowance)}
        </td>
        <td className="text-right">
          {currencyFormat(payHistory.exemptedAllowance)}
        </td>
        <td className="text-right">{currencyFormat(payHistory.grossSalary)}</td>
        <td className="text-right">{currencyFormat(payHistory.lumpsum)}</td>
        <td className="text-right">{currencyFormat(payHistory.netLumpsum)}</td>
        <td className="text-right">{currencyFormat(payHistory.partialNet)}</td>
        <td className="text-right">{currencyFormat(payHistory.withHolds)}</td>
        <td
          style={{ backgroundColor: "#e9ecef", fontWeight: "bolder" }}
          className="text-right"
        >
          {currencyFormat(payHistory.netSalary)}
        </td>
        <td className="text-right">
          <IconButton
            color="primary"
            size="small"
            className="ml-2"
            onClick={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedPayHistory(payHistory);
                  setShowPayHistoryDetail(true);
                }}
                className="text-primary font-weight-bolder"
              >
                <span className="material-icons mr-1">visibility</span>
                Preview
              </MenuItem>
              <Divider className="my-1" />
            </span>

            <span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  handleCloseMenu();
                  setSelectedPayHistory(payHistory);
                  setShowDownloadPaySlip(true);
                }}
                className="text-dark font-weight-light"
              >
                <span className="material-icons mr-1">file_download</span>
                Pay slip
              </MenuItem>
            </span>
          </Menu>
          {/* <IconButton
            color="primary"
            size="small"
            className="p-0"
            onClick={() => {
              setSelectedPayHistory(payHistory);
              setShowPayHistoryDetail(true);
            }}
            aria-label="settings"
          >
            <span className="material-icons">more_vert</span>
          </IconButton> */}
        </td>
      </tr>
    </>
  );
};
