import {
  getApplicantNotAttended,
  addApplicantAttend,
  getPanels,
} from "../../../store/recruitment/actions";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PreLoader from "../../common/components/PreLoader";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import MaskedInput from "react-text-mask";
import { Autocomplete, Box, TextField } from "@mui/material";
import ErrorMessage from "../../common/components/ErrorMessage";
import { showError } from "../../toastify";
export const idNumberPattern = [
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const OralExamApplicantsNotAttended = (props) => {
  const {
    loading,
    examId,
    examTypeId,
    applicantsNotAttended,
    getApplicantNotAttended,
    addApplicantAttend,
    getPanels,
    panels,
    currentPanel,
    disablePanelsOption,
  } = props;

  const [applicantId, setApplicantId] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [idNumber, setIdNumber] = useState("");
  const [panel, setPanel] = useState(
    currentPanel ? currentPanel : panels.length === 1 ? panels[0] : null
  );
  const [errors, setErrors] = useState({
    panelError: false,
  });
  useEffect(() => {
    if (!panels.length) getPanels(examId);
  }, [examId]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "SET_APPLICANTS_NOT_ATTENDED",
      data: [],
    });
  }, []);

  return (
    <>
      <div className="row mt-0">
        <div className="col-12">
          <MaskedInput
            mask={idNumberPattern}
            className="form-control"
            placeholder="Enter Id Number"
            guide={false}
            value={idNumber}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !!e.target.value.length >= 1) {
                setIdNumber(e.target.value);
                setApplicantId("0");
                getApplicantNotAttended(
                  examId,
                  examTypeId,
                  e.target.value,
                  setIsLoading
                );
              }
            }}
            onChange={(e) => {
              if (e.target.value.length === 21) {
                setApplicantId("0");
                setIdNumber(e.target.value);
                getApplicantNotAttended(
                  examId,
                  examTypeId,
                  e.target.value,
                  setIsLoading
                );
              }
            }}
          />
        </div>

        <div className="col-12 mt-2">
          {!!applicantsNotAttended.length && (
            <>
              <table className="rounded" style={{ overflowY: "auto" }}>
                <tbody>
                  <tr>
                    <th scope="row">Names</th>
                    <td>
                      <span style={{ fontSize: "14px" }}>
                        {applicantsNotAttended[0].lastName.toUpperCase()}{" "}
                        {applicantsNotAttended[0].firstName.toUpperCase()}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">Email</th>
                    <td>
                      {" "}
                      <span style={{ fontSize: "14px" }}>
                        {applicantsNotAttended[0].email}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Phone</th>
                    <td>
                      <span style={{ fontSize: "14px" }}>
                        {applicantsNotAttended[0].phoneNumber}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">IDNumber</th>
                    <td>
                      <span style={{ fontSize: "14px" }}>
                        {applicantsNotAttended[0].idNumber}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">AssignPanel</th>
                    <td>
                      <Autocomplete
                        size="small"
                        id="panel"
                        disabled={disablePanelsOption}
                        defaultValue={null}
                        value={panel}
                        options={panels}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(event, panel) => {
                          setPanel(panel || null);
                          setErrors({
                            ...errors,
                            panelError: false,
                          });
                        }}
                        getOptionLabel={(option) => option?.panel}
                        renderOption={(props, panel) => (
                          <Box component="li" {...props}>
                            {panel?.panel}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            disabled={disablePanelsOption}
                            required={true}
                            {...params}
                            label="Select panel"
                            placeholder="Select panel"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "panel", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      <ErrorMessage
                        hasError={errors.panelError}
                        message="panel is required"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="text-panel">
                      {" "}
                      <button
                        onClick={() => {
                          let panelError = false;
                          if (!panel) {
                            panelError = true;
                          }
                          setErrors({
                            ...errors,
                            panelError,
                          });
                          if (!panel) {
                            showError("Center is required");
                          } else {
                            setApplicantId(applicantsNotAttended[0].id);
                            addApplicantAttend(
                              {
                                ...applicantsNotAttended[0],
                                examId,
                                examTypeId,
                                panelId: panel?.id,
                              },
                              setApplicantId,
                              setIdNumber
                            );
                          }
                        }}
                        disabled={applicantId === applicantsNotAttended[0].id}
                        className="btn btn-success"
                      >
                        {applicantId === applicantsNotAttended[0].id ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                          <>Add</>
                        )}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          <NoRecordMessage
            isEmpty={!applicantsNotAttended.length && !isLoading}
            title="No Result"
          />

          <PreLoader isLoading={isLoading && !applicantsNotAttended.length} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ loading, applicantsNotAttended, panels }) => {
  return {
    loading,
    applicantsNotAttended,
    panels,
  };
};
export default connect(mapStateToProps, {
  getApplicantNotAttended,
  addApplicantAttend,
  getPanels,
})(OralExamApplicantsNotAttended);
