import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { connect, useDispatch } from "react-redux";

import {
  CardContent,
  Button,
  IconButton,
  Typography,
  Box,
  Badge,
  Divider,
  Menu,
  MenuItem,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import {
  getApplicantOverallResults,
  requestJobOffer,
  skipCandidate,
  cancelJobOffer,
  employCandidate,
  downloadOverallResults,
  getRecruitmentRequest,
} from "../../../store/recruitment/actions";
import { getUserDetails } from "../../../store/profile/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PercentIcon from "@mui/icons-material/Percent";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import ApplicationCv from "../../common/components/ApplicationCv";
import BadgeIcon from "@mui/icons-material/Badge";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import WidgetViewContentDialog from "../../common/components/WidgetViewContentDialog";
import moment from "moment";
import TableSkeleton from "../../common/components/TableSkeleton";
import BackdropLoader from "../../common/components/BackdropLoader";
import SearchBox from "../../common/components/SearchBox";
import ReactPaginate from "react-paginate";
import EmployCandidateDialog from "../components/EmployCandidateDialog";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import UserResume from "../../profile/UserResume";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import NewRecruitmentRequestDialog from "../components/NewRecruitmentRequestDialog";
import ApproveRecruitmentRequestDialog from "../components/ApproveRecruitmentRequestDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
const ITEM_HEIGHT = 60;

const OverallResults = (props) => {
  const {
    loading,
    applicantOverallResults,
    getApplicantOverallResults,
    selectedAdvertisement,
    requestJobOffer,
    skipCandidate,
    cancelJobOffer,
    employCandidate,
    userType,
    downloadOverallResults,
    getUserDetails,
    getRecruitmentRequest,
    env,
  } = props;

  const theme = useTheme();
  const { hasPermissions } = useRequiredAuth();

  const defaultIds = "";
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(
    window.localStorage.getItem("ITEM_PER_PAGE")
      ? +window.localStorage.getItem("ITEM_PER_PAGE")
      : 10
  );
  const [totalRequests, setTotalRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const [filterStatus, setFilterStatus] = useState({ id: "", name: "All" });
  // console.log(selectedAdvertisement);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    // getActiveApplicationStatuses();

    dispatch({
      type: "SET_APPLICANT_OVERALL_RESULTS",
      data: [],
    });

    const _currentPage = 1;
    setCurrentPage(_currentPage);

    const _searchTerm = "";
    setSearchTerm(_searchTerm);

    const query = {
      searchTerm: _searchTerm,
      page: _currentPage,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };

    getApplicantOverallResults(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading
    );
  }, []);

  const onSearch = () => {
    if (!searchTerm) return false;
    const query = {
      searchTerm: searchTerm,
      page: 1,
      size: itemPerPage,
      currentStatus: filterStatus?.id || defaultIds,
    };
    getApplicantOverallResults(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading
    );
  };

  const handlePageClick = ({ selected }) => {
    const currentPage = selected + 1;
    setCurrentPage(currentPage);
    const query = {
      searchTerm: searchTerm,
      currentStatus: filterStatus?.id || defaultIds,
      page: currentPage,
      size: itemPerPage,
    };
    getApplicantOverallResults(
      selectedAdvertisement.id,
      query,
      setTotalRequests,
      setIsLoading1
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        {/*  */}

        <div
          className="w-100"
          style={{
            height: "500px",
          }}
        >
          <div
            style={{
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                // padding: ".6em",
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
            >
              <div className="row">
                <div className="col-lg-5 mb-2">
                  <Typography variant="h6" noWrap component="div">
                    <span style={{ color: "rgb(7, 142, 206)" }}>
                      Overall Results ({totalRequests})
                    </span>
                  </Typography>
                </div>
                <div className="col-lg-7">
                  <div className="d-flex justify-content-right  align-items-right">
                    <Autocomplete
                      className="mr-2"
                      size="small"
                      id="filterStatus"
                      disabled={
                        // !applicantOverallResults.length ||
                        isLoading1 || isLoading
                      }
                      defaultValue={null}
                      value={filterStatus || null}
                      options={[
                        { id: "Passed All Exam", name: "Passed All Exam" },
                        { id: "Not All Passed", name: "Not All Passed" },
                        { id: "Passed Written", name: "Passed Written" },
                        {
                          id: "Not Passed Written",
                          name: "Not Passed Written",
                        },
                        { id: "Passed Oral", name: "Passed Oral" },
                        { id: "Not Passed Oral", name: "Not Passed Oral" },
                        { id: "", name: "All" },
                      ]}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, currentStatus) => {
                        const query = {
                          searchTerm: "",
                          currentStatus: currentStatus?.id || defaultIds,
                          page: currentPage,
                          size: itemPerPage,
                        };
                        setFilterStatus(currentStatus);
                        getApplicantOverallResults(
                          selectedAdvertisement.id,
                          query,
                          setTotalRequests,
                          setIsLoading1
                        );
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, filterStatus) => (
                        <Box component="li" {...props} key={filterStatus.id}>
                          {filterStatus.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          style={{
                            width: "200px",
                            height: "35px!important",
                            position: "relative",
                            borderRadius: "8px",
                            padding: "1px!important",
                          }}
                          {...params}
                          label="Filter By"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "filterStatus", // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    <SearchBox
                      disabled={
                        // !applicantOverallResults.length ||
                        isLoading1 || isLoading
                      }
                      onSearch={(search) => {
                        setSearchTerm(search);

                        if (!search) {
                          const currentPage = 1;
                          setCurrentPage(currentPage);
                          const query = {
                            searchTerm: "",
                            page: currentPage,
                            currentStatus: filterStatus?.id || defaultIds,
                            size: itemPerPage,
                          };
                          getApplicantOverallResults(
                            selectedAdvertisement.id,
                            query,
                            setTotalRequests,
                            setIsLoading1
                          );
                        }
                      }}
                      placeholder="Search…"
                    />
                    <Button
                      disabled={
                        !searchTerm ||
                        isLoading1 ||
                        isLoading ||
                        !applicantOverallResults.length
                      }
                      onClick={() => onSearch()}
                      variant="contained"
                      size="small"
                      disableElevation
                      className="mr-2 "
                      style={{
                        borderRadius: "8px",
                        height: "38px",
                        border: `1px solid rgb(7, 142, 206)`,
                        color: "#fff",
                        alignItems: "center",
                        fontSize: ".75rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "initial",
                        backgroundColor: "rgb(7, 142, 206)",
                        marginLeft: "5px",
                        "&:hover": {
                          backgroundColor: "#f0f2f5",
                        },
                      }}
                    >
                      <span className="material-icons">search</span>GO
                    </Button>
                    <span className="ml-0 btn btn-outline-primary btn-sm">
                      <Tooltip title="Download">
                        <IconButton
                          disabled={isLoading || isLoading1}
                          className="p-0"
                          color="inherit"
                          onClick={() => {
                            const query = {
                              searchTerm: searchTerm,
                              currentStatus: filterStatus?.id || defaultIds,
                              advertisementId: selectedAdvertisement.id,
                              advertisementName:
                                selectedAdvertisement.positionName,
                            };
                            downloadOverallResults(query, setIsDownloading);
                          }}
                        >
                          {isDownloading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{ fontSize: "28px" }}
                              ></span>
                              <span className="sr-only">Loading...</span>
                            </>
                          ) : (
                            <span
                              className="material-icons"
                              style={{ fontSize: "28px" }}
                            >
                              cloud_download
                            </span>
                          )}
                        </IconButton>
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </div>
            </CardContent>
          </div>

          <div
            className="mt-0"
            style={{
              padding: theme.spacing(0.4),
              height: "auto",
              maxHeight: "auto",
              backgroundColor: "#fff",
              borderRadius: "0.5rem",
              overflow: "visible",
              // boxShadow: "0 5px 5px 0 rgb(42 119 144 / 5%)",
              // MozBoxShadow: "0 5px 5px 0 rgba(42,119,144,.05)",
            }}
          >
            <CardContent
              style={{
                height: "auto",
                minHeight: "auto",
                overflow: "hidden",
              }}
            >
              <table className="table table-striped table-hover table-sm  fixTableHead">
                <thead>
                  <tr style={{ backgroundColor: "#f0f2f5" }}>
                    <th scope="col">#</th>
                    <th scope="col">ApplicationNumber</th>
                    <th scope="col" className="text-left">
                      FirstName
                    </th>
                    <th scope="col" className="text-left">
                      LastName
                    </th>
                    <th scope="col" className="text-left">
                      Email
                    </th>
                    <th scope="col" className="text-left">
                      Phone
                    </th>
                    {/* {!!selectedAdvertisement.willHaveWrittenExam && ( */}
                    <th scope="col" className="text-center">
                      Written Exam
                    </th>
                    {/* )} */}
                    {/* {!!selectedAdvertisement.willHaveOralExam && ( */}
                    <th scope="col" className="text-center">
                      Oral Exam
                    </th>
                    {/* )} */}

                    <th scope="col" className="text-center">
                      Total Score
                    </th>
                    <th scope="col" className="text-right">
                      Status
                    </th>

                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                {isLoading ? (
                  <TableSkeleton cols={11} />
                ) : (
                  <tbody style={{ height: "100%" }}>
                    <BackdropLoader isLoading={isLoading1} />
                    {applicantOverallResults.map((result, index) => (
                      <OverallResultCard
                        selectedAdvertisement={selectedAdvertisement}
                        result={result}
                        index={index}
                        key={index}
                        userType={userType}
                        requestJobOffer={requestJobOffer}
                        cancelJobOffer={cancelJobOffer}
                        employCandidate={employCandidate}
                        skipCandidate={skipCandidate}
                        getUserDetails={getUserDetails}
                        loading={loading}
                        getRecruitmentRequest={getRecruitmentRequest}
                        env={env}
                      />
                    ))}
                  </tbody>
                )}
              </table>

              <NoRecordMessage
                isEmpty={!applicantOverallResults.length && !isLoading}
                title={"No record found"}
              />
              {!isLoading && (
                <div
                  className="d-flex justify-content-center  align-items-center"
                  style={{ overflow: "hidden" }}
                >
                  <div className="mt-3">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <span className="pr-1 d-none d-md-inline">Next</span>
                          <i className="fas fa-angle-double-right"></i>
                        </>
                      }
                      previousLabel={
                        <>
                          <i className="fas fa-angle-double-left"></i>
                          <span className="pl-1  d-none d-md-inline">
                            Previous
                          </span>
                        </>
                      }
                      pageRangeDisplayed={3}
                      // marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      pageCount={Math.ceil(totalRequests / itemPerPage)}
                      renderOnZeroPageCount={null}
                      containerClassName="pagination"
                      pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                      previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1"
                      nextLinkClassName="btn btn-outline-info btn-sm ml-1"
                      activeLinkClassName="active"
                    />
                  </div>

                  {!!applicantOverallResults.length && (
                    <select
                      className="custom-select custom-select-sm ml-2"
                      style={{ width: "100px" }}
                      value={itemPerPage}
                      onChange={(event) => {
                        const itemPerPage = +event.target.value;
                        window.localStorage.setItem(
                          "ITEM_PER_PAGE",
                          itemPerPage
                        );
                        setItemPerPage(itemPerPage);
                        const query = {
                          searchTerm: searchTerm,
                          page: currentPage,
                          size: itemPerPage,
                          currentStatus: filterStatus?.id || defaultIds,
                        };
                        getApplicantOverallResults(
                          selectedAdvertisement.id,
                          query,
                          setTotalRequests,
                          setIsLoading
                        );
                      }}
                    >
                      {[10, 50, 100].map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>
                  )}
                </div>
              )}
            </CardContent>
          </div>

          {/* {showApplicationLetter && (
                <ApplicationLetterDialog
                  showApplicationLetter={showApplicationLetter}
                  setShowApplicationLetter={setShowApplicationLetter}
                  selectedApplication={selectedApplication}
                />
              )} */}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading, applicantOverallResults, env }) => {
  return { user, loading, applicantOverallResults, env };
};

export default connect(mapStateToProps, {
  requestJobOffer,
  skipCandidate,
  cancelJobOffer,
  employCandidate,
  getApplicantOverallResults,
  downloadOverallResults,
  getUserDetails,
  getRecruitmentRequest,
})(OverallResults);

const OverallResultCard = (props) => {
  const {
    result,
    selectedAdvertisement,
    userType,
    requestJobOffer,
    skipCandidate,
    cancelJobOffer,
    getUserDetails,
    loading,
    getRecruitmentRequest,
    env,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermissions } = useRequiredAuth();
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const [showApplicationCv, setShowApplicationCv] = useState(false);
  const [confirmToRequestJobOffer, setConfirmToRequestJobOffer] =
    useState(false);
  const [confirmToSkipCandidate, setConfirmToSkipCandidate] = useState(false);

  const [confirmToEmploy, setConfirmToEmploy] = useState(false);
  const [showRequestForm, setShowRequestForm] = useState(false);

  const [confirmToCancelJobOffer, setConfirmToCancelJobOffer] = useState(false);
  const [reason, setReason] = useState("");
  const [reason1, setReason1] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [showCurrentProfile, setShowCurrentProfile] = useState(false);
  const [approveRequestForm, setApproveRequestForm] = useState(false);
  const handleOpenCurrentProfile = () => {
    handleCloseMenu();
    setShowCurrentProfile(true);
  };
  return (
    <>
      <tr
        className={
          result.isEmployed
            ? "overall-employed"
            : result.isJobOfferExpired
            ? "overall-expired"
            : result.isJobOfferRequested && result.jobOfferStatusId === 2
            ? "overall-offered"
            : ""
        }
      >
        <th
          style={{
            backgroundColor: "#f0f2f5",
          }}
        >
          {result.no}
        </th>
        <th
          style={{
            backgroundColor: "#f0f2f5",
          }}
        >
          {result.applicantId}
        </th>
        <td className="text-left"> {result.firstName}</td>
        <td className="text-left">{result.lastName}</td>
        <td className="text-left">{result.email}</td>
        <td className="text-left">{result.phoneNumber}</td>
        {/* {!!selectedAdvertisement.willHaveWrittenExam && ( */}
        <td className="text-center">
          {result.writtenExamTotalMarks > 0 ? (
            <>
              {result.writtenExamTotalScore}/{result.writtenExamTotalMarks}
            </>
          ) : (
            "-"
          )}
          {!!result.wrTopupScore && (
            <>
              <strong className="text-primary" style={{ fontSize: "10px" }}>
                <br />
                [Top-up] {result.writtenExamScore || "-"}
                <sup className="ml-1 text-success">+{result.wrTopupScore}</sup>
              </strong>
            </>
          )}
        </td>
        {/* )} */}

        {/* {!!selectedAdvertisement.willHaveOralExam && ( */}
        <td className="text-center">
          {result.oralExamTotalScore > 0 ? (
            <>
              {result.oralExamTotalScore}/{result.oralExamTotalMarks}
            </>
          ) : (
            "-"
          )}

          {!!result.oTopupScore && (
            <>
              <strong className="text-primary" style={{ fontSize: "10px" }}>
                <br />
                [Top-up] {result.oralExamScore || "-"}
                <sup className="ml-1 text-success">+{result.oTopupScore}</sup>
              </strong>
            </>
          )}
        </td>
        {/* )} */}

        <td className="text-center">
          <div
            className="text-center"
            style={{
              margin: "0 auto",
              padding: "2px",
              width: "57px",
              height: "auto",
              border: `solid 3px ${
                result.score >= 70
                  ? "#28a745"
                  : result.score >= 50
                  ? "rgb(7, 142, 206)"
                  : "#dc3545"
              }`,
              borderRadius: "50px",
              textAlign: "center",
            }}
          >
            {result.score}
            <PercentIcon style={{ fontSize: "13px" }} />
          </div>
        </td>

        <td className="text-right">
          {result.isMarkedAsCheated ? (
            <span className="px-1 py-1 badge badge-danger">
              {result.status}
            </span>
          ) : result.isJobOfferSkipped ? (
            <span className="px-1 py-1 badge badge-secondary">
              {result.jobOfferStatusName}
            </span>
          ) : result.isJobOfferExpired ? (
            <span className="px-1 py-1 badge badge-warning">Offer expired</span>
          ) : result.isJobOfferRequested ? (
            <>
              {result.jobOfferStatusId === 7 ? (
                <>
                  <span
                    className={
                      result.recruitmentRequestStatusId === 1
                        ? "px-1 py-1 badge badge-primary"
                        : "px-1 py-1 badge badge-warning"
                    }
                  >
                    {result.recruitmentRequestStatusName}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className={
                      result.jobOfferStatusId === 5
                        ? "px-1 py-1 badge badge-success"
                        : result.jobOfferStatusId === 1
                        ? "px-1 py-1 badge badge-secondary"
                        : result.jobOfferStatusId === 2
                        ? "px-1 py-1 badge badge-primary"
                        : "px-1 py-1 badge badge-danger"
                    }
                  >
                    {result.jobOfferStatusName}
                  </span>
                </>
              )}
            </>
          ) : (
            <span
              className={
                result.score >= 70
                  ? "px-1 py-1 text-success"
                  : result.score >= 50
                  ? "px-1 py-1 text-primary"
                  : "px-1 py-1 text-danger"
              }
            >
              {result.status}
            </span>
          )}

          {!!result.employmentDate && (
            <>
              <br />
              <span className="badge badge-pill">
                In service: {moment(result.employmentDate).format("ll")}
              </span>
            </>
          )}
        </td>

        <td className="text-left">
          <IconButton
            size="small"
            onClick={handleOpenMenu}
            // onMouseMove={handleOpenMenu}
            aria-label="settings"
          >
            <Badge color="info">
              <span className="material-icons">more_vert</span>
            </Badge>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "auto",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                maxHeight: ITEM_HEIGHT * 4.5,
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 2,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <span>
              {userType !== "ADMIN" && (
                <MenuItem
                  disabled={
                    result.jobOfferStatusId !== 2 ||
                    result.isJobOfferExpired ||
                    !result.isJobOfferRequested ||
                    result.score < 70 ||
                    result.isEmployed ||
                    !hasPermissions(["IS_HR"])
                  }
                  onClick={() => {
                    handleCloseMenu();
                    setConfirmToEmploy(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <PersonAddAlt1Icon className="mr-2 text-success" />
                  Employ
                </MenuItem>
              )}

              {/* {[2,7].includes(result.jobOfferStatusId) && env!=='prod' && ( */}
              {/* <> */}
              {<Divider className="my-1" />}

              <MenuItem
                disabled={
                  loading ||
                  !hasPermissions(["IS_HR"]) ||
                  [1, 2].includes(result.recruitmentRequestStatusId)
                }
                onClick={() => {
                  handleCloseMenu();
                  setShowRequestForm(true);
                }}
                className="text-dark font-weight-light"
              >
                <BookmarkAddIcon className="mr-2 text-primary" />
                {loading ? "Wait.." : "Request Prov. Appointment"}
              </MenuItem>

              {/* </> */}
              {/* )} */}

              {userType !== "ADMIN" && <Divider className="my-1" />}
              {[1, 2, 3].includes(result.recruitmentRequestStatusId) &&
                env !== "prod" && (
                  <>
                    <MenuItem
                      onClick={() => {
                        getRecruitmentRequest(
                          result.recruitmentRequestId,
                          setApproveRequestForm,
                          handleCloseMenu
                        );
                      }}
                      className="text-dark font-weight-light"
                    >
                      <VisibilityIcon className="mr-2 text-primary" />
                      View Prov. Appointment
                    </MenuItem>
                    <Divider className="my-1" />
                  </>
                )}

              <>
                {result.isJobOfferRequested ? (
                  <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                      setOpenDialog(true);
                    }}
                    className="text-dark font-weight-light"
                  >
                    <WorkOutlineIcon className="mr-2 text-primary" />
                    View Job Offer
                  </MenuItem>
                ) : (
                  <>
                    <MenuItem
                      disabled={
                        result.jobOfferStatusId === 3 ||
                        result.jobOfferStatusId === 2 ||
                        result.jobOfferStatusId === 1 ||
                        result.isJobOfferExpired ||
                        result.score < 70 ||
                        result.isEmployed ||
                        !hasPermissions(["IS_HR"])
                      }
                      onClick={() => {
                        handleCloseMenu();
                        setConfirmToRequestJobOffer(true);
                      }}
                      className="text-dark font-weight-light"
                    >
                      <WorkOutlineIcon className="mr-2 text-primary" />
                      Request Job Offer
                    </MenuItem>
                    <Divider className="my-1" />
                    <MenuItem
                      disabled={
                        result.jobOfferStatusId === 3 ||
                        result.jobOfferStatusId === 2 ||
                        result.jobOfferStatusId === 1 ||
                        result.isJobOfferExpired ||
                        result.score < 70 ||
                        result.isEmployed ||
                        result.isJobOfferSkipped ||
                        !hasPermissions(["IS_HR"])
                      }
                      onClick={() => {
                        handleCloseMenu();
                        setConfirmToSkipCandidate(true);
                      }}
                      className="text-dark font-weight-light"
                    >
                      <SkipNextIcon className="mr-2 text-primary" />
                      Skip This Candidate
                    </MenuItem>
                  </>
                )}
              </>

              {userType !== "ADMIN" && <Divider className="my-1" />}

              <span>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    setShowApplicationCv(true);
                  }}
                  className="text-primary font-weight-light"
                >
                  <span className="material-icons mr-1">open_in_new</span>
                  Application Profile
                </MenuItem>
                <Divider className="my-1" />
              </span>
              <MenuItem
                disabled={loading}
                onClick={() => {
                  getUserDetails(result.userId, handleOpenCurrentProfile);
                }}
                className="text-dark font-weight-light"
              >
                <BadgeIcon className="mr-2 text-primary" />
                {loading ? "Wait...." : "Currently Profile"}
              </MenuItem>

              {userType !== "ADMIN" && <Divider className="my-1" />}
              {userType !== "ADMIN" && (
                <MenuItem
                  disabled={
                    !result.isJobOfferRequested ||
                    // result.score < 70 ||
                    result.isEmployed ||
                    !result.isJobOfferExpired ||
                    !hasPermissions(["IS_HR"])
                  }
                  onClick={() => {
                    handleCloseMenu();
                    setConfirmToCancelJobOffer(true);
                  }}
                  className="text-dark font-weight-light"
                >
                  <CancelOutlinedIcon className="mr-2 text-danger" />
                  Revoke Job Offer
                </MenuItem>
              )}
            </span>
          </Menu>
        </td>
      </tr>

      {openDialog && (
        <WidgetViewContentDialog
          openDialog={openDialog}
          content={
            <>
              <table>
                <tbody>
                  <tr>
                    <th scope="row">Status</th>
                    <td>
                      {result.isJobOfferExpired ? (
                        <span className="px-1 py-1 badge badge-warning">
                          Offer expired
                        </span>
                      ) : result.isJobOfferRequested ? (
                        <>
                          {result.jobOfferStatusId === 7 ? (
                            <>
                              <span
                                className={
                                  result.recruitmentRequestStatusId === 1
                                    ? "px-1 py-1 badge badge-primary"
                                    : "px-1 py-1 badge badge-warning"
                                }
                              >
                                {result.recruitmentRequestStatusName}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className={
                                  result.jobOfferStatusId === 5
                                    ? "px-1 py-1 badge badge-success"
                                    : result.jobOfferStatusId === 1
                                    ? "px-1 py-1 badge badge-secondary"
                                    : result.jobOfferStatusId === 2
                                    ? "px-1 py-1 badge badge-primary"
                                    : "px-1 py-1 badge badge-danger"
                                }
                              >
                                {result.jobOfferStatusName}
                              </span>
                            </>
                          )}
                        </>
                      ) : (
                        <span
                          className={
                            result.score >= 70
                              ? "px-1 py-1 text-success"
                              : result.score >= 50
                              ? "px-1 py-1 text-primary"
                              : "px-1 py-1 text-danger"
                          }
                        >
                          {result.status}
                        </span>
                      )}
                    </td>
                  </tr>
                  {result.jobOfferExpiredOn && (
                    <tr>
                      <th scope="row">Expiration Date</th>
                      <td>{moment(result.jobOfferExpiredOn).format("LLL")}</td>
                    </tr>
                  )}

                  {result.offeredOn && (
                    <tr>
                      <th scope="row">Requested On</th>
                      <td>{moment(result.offeredOn).format("LLL")}</td>
                    </tr>
                  )}
                  {result.offeredBy && (
                    <tr>
                      <th scope="row">Requested By</th>
                      <td>{result.offeredBy}</td>
                    </tr>
                  )}

                  {result.revokedOn && (
                    <tr>
                      <th scope="row">Revoked On</th>
                      <td>{moment(result.revokedOn).format("LLL")}</td>
                    </tr>
                  )}
                  {/* {result.revokedEmployee && (
                    <tr>
                      <th scope="row">Revoked By</th>
                      <td>{result.revokedEmployee}</td>
                    </tr>
                  )} */}

                  {result.revokedEmployee && (
                    <tr>
                      <th scope="row">
                        {result.jobOfferStatusId === 6
                          ? "SkippedBy"
                          : "RevokedBy"}{" "}
                      </th>
                      <td>{result.revokedEmployee}</td>
                    </tr>
                  )}

                  {result.candidateRespondedOn && (
                    <tr>
                      <th scope="row">Candidate Responded On</th>
                      <td>
                        {moment(result.candidateRespondedOn).format("LLL")}
                      </td>
                    </tr>
                  )}

                  {result.rejectionReason && (
                    <tr>
                      <th scope="row">RejectionReason</th>
                      <td>{result.rejectionReason}</td>
                    </tr>
                  )}
                  {result.revokedReason && (
                    <tr>
                      <th scope="row">
                        {result.jobOfferStatusId === 6
                          ? "SkippedReason"
                          : "RevokedReason"}{" "}
                      </th>
                      <td>{result.revokedReason}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          }
          title={
            <>
              View Job Offer of {result.firstName} {result.lastName}
            </>
          }
          setOpenDialog={setOpenDialog}
        />
      )}

      {confirmToSkipCandidate && (
        <ConfirmationDialog
          confirmationDialog={confirmToSkipCandidate}
          message={
            <>
              Are you sure you want to skip this candidate @{" "}
              <strong className="text-primary">
                {result.firstName} {result.lastName}
              </strong>
              ?
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                autoFocus
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason1}
                onChange={(e) => {
                  setReason1(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setConfirmToSkipCandidate}
          onYes={() => {
            skipCandidate(
              {
                applicationId: result.applicantId,
                reason: reason1,
              },
              setReason1,
              setConfirmToSkipCandidate
            );
          }}
        />
      )}

      {confirmToRequestJobOffer && (
        <ConfirmationDialog
          confirmationDialog={confirmToRequestJobOffer}
          message={
            <>
              Are you sure you want to send job offer of{" "}
              <strong className="text-primary">
                {result.firstName} {result.lastName}
              </strong>{" "}
              for{" "}
              <strong className="badge badge-secondary">
                {selectedAdvertisement.numberOfPosts}
              </strong>{" "}
              Vacant Post
              {selectedAdvertisement.numberOfPosts > 1 ? "s" : ""}?
            </>
          }
          setConfirmationDialog={setConfirmToRequestJobOffer}
          onYes={() => {
            requestJobOffer(
              {
                numberOfPosts: selectedAdvertisement.numberOfPosts,
                score: result.score,
                applicationId: result.applicantId,
                userId: result.userId,
              },
              setConfirmToRequestJobOffer
            );
          }}
        />
      )}
      {confirmToCancelJobOffer && (
        <ConfirmationDialog
          disabled={!reason}
          confirmationDialog={confirmToCancelJobOffer}
          message={
            <>
              <p>
                Are you sure you want to{" "}
                <strong className="text-danger">revoke</strong> job offer
                request for{" "}
                <strong className="text-primary">
                  {result.firstName} {result.lastName}
                </strong>
                ?
              </p>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={4}
                name="Reason"
                autoFocus
                label="Type your reason .."
                variant="outlined"
                placeholder="Type your reason  .."
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value || "");
                }}
              />
            </>
          }
          setConfirmationDialog={setConfirmToCancelJobOffer}
          onYes={() => {
            cancelJobOffer(
              result.jobOfferId,
              reason,
              setReason,
              setConfirmToCancelJobOffer
            );
          }}
        />
      )}
      {showRequestForm && (
        <NewRecruitmentRequestDialog
          showRequestForm={showRequestForm}
          setShowRequestForm={setShowRequestForm}
          selectedApplication={result}
          requestTypeId={1}
        />
      )}
      {confirmToEmploy && (
        <EmployCandidateDialog
          showEmployeeForm={confirmToEmploy}
          setShowEmployeeForm={setConfirmToEmploy}
          application={result}
          isFromWaitingList={false}
        />
        // <ConfirmationDialog
        //   confirmationDialog={confirmToEmploy}
        //   message={`Are you sure you want to employ ${result.firstName} ${result.lastName}?`}
        //   setConfirmationDialog={setConfirmToEmploy}
        //   onYes={() => {
        //     employCandidate(
        //       {
        //         positionId: result.positionId,
        //         employeeId: result.userId,
        //         employeeGroupId: result.employeeGroupId,
        //         levelId: result.levelId,
        //         scaleId: result.scaleId,
        //         applicantId: result.applicantId,
        //         advertisementId: result.advertisementId,
        //         jobOfferId: result.jobOfferId,
        //       },
        //       setConfirmToEmploy
        //     );
        //   }}
        // />
      )}
      {showCurrentProfile && (
        <UserResume
          showProfileDialog={showCurrentProfile}
          setShowProfileDialog={setShowCurrentProfile}
        />
      )}
      {showApplicationCv && (
        <ApplicationCv
          showApplicationCv={showApplicationCv}
          setShowApplicationCv={setShowApplicationCv}
          application={{
            id: result.applicantId,
            idNumber: result.idNumber2,
            phoneNumber: result.phoneNumber,
            userId: result.userId,
            lastName: result.lastName,
            firstName: result.firstName,
            email: result.email,
          }}
        />
      )}
      {!!approveRequestForm && (
        <ApproveRecruitmentRequestDialog
          approveRequestForm={approveRequestForm}
          setApproveRequestForm={setApproveRequestForm}
          selectedRequest={result}
        />
      )}
    </>
  );
};
