import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { assignRoleModules, getModules } from "../../../store/admin/actions";
import { showError } from "../../../app/toastify";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AssignModuleDialog = (props) => {
  const {
    loading,
    modules,
    showAssignModuleForm,
    setShowAssignModuleForm,
    selectedRole,
    assignRoleModules,
    getModules,
  } = props;

  const [notAssignedModules, setNotAssignedModules] = useState([]);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    getModules();
  }, []);
  useEffect(() => {
    let notAssignedModuless = [];
    modules.forEach((module) => {
      if (selectedRole.modules && selectedRole.modules.length > 0) {
        if (!selectedRole.modules.find(({ id }) => id === module.id)) {
          notAssignedModuless.push(module);
        }
      } else {
        notAssignedModuless.push(module);
      }
    });
    setNotAssignedModules(notAssignedModuless);
  }, [modules, selectedRole]);

  const onSave = () => {
    if (!formData.length) {
      showError("Please fill out all required fields");
      return false;
    }
    let roleModules = [];
    formData.forEach((el) => {
      roleModules.push({ roleId: selectedRole.id, moduleId: el.id });
    });
    assignRoleModules(
      setFormData,
      roleModules,
      setShowAssignModuleForm,
      setNotAssignedModules
    );
  };

  const onClose = () => {
    setShowAssignModuleForm(false);
    setFormData([]);
    setNotAssignedModules([]);
  };
  // console.log(Modules);
  // console.log(notAssignedModules);
  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showAssignModuleForm}
        fullWidth
      >
        <DialogTitle className="text-primary">
          <Typography variant="overline" display="block">
            Assign Modules
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={notAssignedModules}
            disableCloseOnSelect
            getOptionLabel={(option) => option.shortName}
            onChange={(event, modules) => {
              setFormData(modules);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.shortName}
              </li>
            )}
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} label="Modules" placeholder="Modules" />
            )}
          />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center py-4">
          <button
            onClick={onSave}
            type="button"
            className="btn btn-primary text-uppercase  px-4"
            disabled={loading}
          >
            {loading ? "Wait..." : "Save"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, modules }) => {
  return {
    loading,
    modules,
  };
};
export default connect(mapStateToProps, {
  assignRoleModules,
  getModules,
})(AssignModuleDialog);
