import {
  ListItemButton,
  ListItemText,
  List,
  ListSubheader,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getTempQuestions,
  deleteAllTempQuestions,
  deleteTempQuestion,
  confirmUploadedExam,
} from "../../../store/recruitment/actions";

import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import {
  doesExamEnded,
  doesExamStarted,
  totalSum,
} from "../../common/components/Utils";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import UploadExamDialog from "./UploadExamDialog";
import SearchBox from "../../common/components/SearchBox";
import EditUploadedExamDialog from "./EditUploadedExamDialog";
import ActionDialog from "../../common/components/ActionDialog";
import WrittenExamQuestionDialog from "./WrittenExamQuestion";
import AlertDialog from "../../common/components/AlertDialog";
import { useRequiredAuth } from "../../common/guard/requiredAuth";

const WrittenExamQuestionCard = (props) => {
  const { exam, index, setShowEditQuestion, setSelectedQuestion } = props;
  const { hasPermissions } = useRequiredAuth();
  return (
    <>
      <tr>
        <th
          scope="row"
          style={{
            backgroundColor: !!exam?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "#f0f2f5",
          }}
        >
          {exam.questionNo}
        </th>

        <td
          className="text-left"
          style={{
            width: "760px",
            backgroundColor: !!exam?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "",
          }}
        >
          {!!exam?.isExamQuestionCanceled && (
            <span className="badge badge-danger px-1 py-1 mr-2">
              {" "}
              Question Canceled{" "}
            </span>
          )}
          {exam.description}
        </td>
        <td
          className="text-left"
          style={{
            backgroundColor: !!exam?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "",
          }}
        >
          {exam && exam.assertionA && (
            <p>
              A: {exam.assertionA}{" "}
              {exam.assertionA.includes("*") && (
                <span className="badge badge-success ml-1"> Correct</span>
              )}
            </p>
          )}

          {exam && exam.assertionB && (
            <p>
              B: {exam.assertionB}{" "}
              {exam.assertionB.includes("*") && (
                <span className="badge badge-success ml-1"> Correct</span>
              )}
            </p>
          )}
          {exam && exam.assertionC && (
            <p>
              C: {exam.assertionC}{" "}
              {exam.assertionC.includes("*") && (
                <span className="badge badge-success ml-1"> Correct</span>
              )}
            </p>
          )}

          {exam && exam.assertionD && (
            <p>
              D: {exam.assertionD}{" "}
              {exam.assertionD.includes("*") && (
                <span className="badge badge-success ml-1"> Correct</span>
              )}
            </p>
          )}
        </td>
        <td
          className="text-left"
          style={{
            backgroundColor: !!exam?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "",
          }}
        >
          {" "}
          {exam.questionType.name}
        </td>

        <td
          className="text-center"
          style={{
            backgroundColor: !!exam?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "",
          }}
        >
          {" "}
          {exam.marks}
        </td>

        <td
          className="text-center"
          style={{
            backgroundColor: !!exam?.isExamQuestionCanceled
              ? "rgb(245, 144, 144)"
              : "",
          }}
        >
          <button
            disabled={doesExamEnded(exam)}
            onClick={() => {
              setSelectedQuestion(exam);
              setShowEditQuestion(true);
            }}
            type="button"
            title="Edit"
            className="btn btn-primary btn-sm"
          >
            <i className="fas fa-edit"></i>
          </button>
        </td>
      </tr>
    </>
  );
};

const TempExamQuestion = (props) => {
  const {
    loading,
    exam,
    getTempQuestions,
    tempQuestions,
    deleteAllTempQuestions,
    confirmUploadedExam,
    setShowUploadWrittenExamQuestionsForm,
    selectedAdvertisement,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showEditQuestion, setShowEditQuestion] = useState(false);
  const [showUploadExamForm, setShowUploadExamForm] = useState(false);
  const [confirmRemoveAll, setConfirmRemoveAll] = useState(false);
  const [searchTeam, setSearchTeam] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showWrittenExamQuestionsForm, setShowWrittenExamQuestionsForm] =
    useState(false);
  const [filteredTempQuestions, setFilteredTempQuestions] = useState([
    ...tempQuestions,
  ]);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    getTempQuestions(exam.id);
  }, [exam]);

  useEffect(() => {
    const filtered = [...tempQuestions].filter(({ description }) =>
      description.toLowerCase().includes(searchTeam.toLowerCase())
    );

    setFilteredTempQuestions(filtered);
  }, [searchTeam, tempQuestions]);

  const onSearch = (term) => {
    setSearchTeam(term);
  };

  return (
    <>
      {showWrittenExamQuestionsForm && (
        <WrittenExamQuestionDialog
          exam={exam}
          showWrittenExamQuestionsForm={showWrittenExamQuestionsForm}
          setShowWrittenExamQuestionsForm={setShowWrittenExamQuestionsForm}
        />
      )}
      <PreLoader isLoading={loading} />

      <div className="row mt-3">
        <div className={!!tempQuestions.length ? "col-lg-7" : "col-lg-10"}>
          {!!tempQuestions.length && (
            <span>
              Total Q/S: {filteredTempQuestions.length} | Marks:{" "}
              {totalSum(filteredTempQuestions, "marks")}%
            </span>
          )}
        </div>
        <div className={!!tempQuestions.length ? "col-lg-5" : "col-lg-2"}>
          <div className="row">
            <div className="col-1"></div>
            <div className={!!tempQuestions.length ? "col-lg-5" : "col-lg-12"}>
              {!loading &&
                !tempQuestions.length &&
                hasPermissions(["IS_HR", "IS_INVIGILATOR"]) &&
                !doesExamEnded(exam) && (
                  <button
                    style={{ float: "right" }}
                    onClick={() => setShowUploadExamForm(true)}
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    <i className="fas fa-upload"></i> Upload
                  </button>
                )}

              {!!tempQuestions.length && (
                <SearchBox onSearch={onSearch} placeholder="Search…" />
              )}
            </div>

            {!!tempQuestions.length && (
              <div className="col-3">
                <button
                  style={{ float: "right" }}
                  onClick={() => {
                    let tempErrors = [];
                    tempQuestions.forEach((question) => {
                      let hasNoQuestionNo = false,
                        hasNoAssertion = false,
                        hasNoDescription = false,
                        hasNoMarks = false;
                      if (
                        question.questionTypeId === 1 &&
                        !(
                          question.assertionA.trim().charAt(0) === "*" ||
                          question.assertionB.trim().charAt(0) === "*" ||
                          question.assertionC.trim().charAt(0) === "*" ||
                          question.assertionD.trim().charAt(0) === "*"
                        )
                      ) {
                        hasNoAssertion = true;
                      }
                      if (!question.questionNo) {
                        hasNoQuestionNo = true;
                      }
                      if (!question.description) {
                        hasNoDescription = true;
                      }
                      if (!question.marks || question.marks <= 0) {
                        hasNoMarks = true;
                      }
                      if (
                        hasNoAssertion ||
                        hasNoAssertion ||
                        hasNoQuestionNo ||
                        hasNoDescription ||
                        hasNoMarks
                      ) {
                        tempErrors.push({
                          no: question.questionNo,
                          hasNoAssertion,
                          hasNoQuestionNo,
                          hasNoDescription,
                          hasNoMarks,
                        });
                      }
                    });

                    setErrors(tempErrors);

                    if (!tempErrors.length)
                      confirmUploadedExam(
                        exam.id,
                        setIsConfirming,
                        setShowUploadWrittenExamQuestionsForm
                      );
                    else setShowAlert(true);
                  }}
                  type="button"
                  className="btn btn-success btn-sm"
                  disabled={loading}
                >
                  <i className="fas fa-check"></i> Confirm
                </button>
              </div>
            )}
            {
              <AlertDialog
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                maxWidth="sm"
                children={
                  <>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        borderRadius: "2px",
                        border: "1px solid red",
                      }}
                      component="div"
                      subheader={
                        <ListSubheader
                          component="div"
                          style={{ borderBottom: "1px solid red" }}
                        >
                          Error{errors.length > 1 && "s"} found(
                          {errors.length})
                        </ListSubheader>
                      }
                    >
                      {errors.map((error, index) => (
                        <ListItemButton key={index}>
                          <ListItemText
                            primary={
                              <span className="text-danger">
                                <span className="pl-1">
                                  {" "}
                                  {error.no && "Q." + error.no + ":"}
                                </span>

                                {error.hasNoAssertion && (
                                  <span className="pl-1">
                                    {" "}
                                    No correct answer,{" "}
                                  </span>
                                )}

                                {error.hasNoDescription &&
                                  error.hasNoQuestionNo &&
                                  error.hasNoMarks && (
                                    <span className="pl-1">
                                      Question number, marks and description are
                                      required{" "}
                                    </span>
                                  )}
                                {error.hasNoDescription &&
                                  !error.hasNoQuestionNo &&
                                  error.hasNoMarks && (
                                    <span className="pl-1">
                                      marks and description are required{" "}
                                    </span>
                                  )}
                                {error.hasNoDescription &&
                                  error.hasNoQuestionNo &&
                                  !error.hasNoMarks && (
                                    <span className="pl-1">
                                      Question number and description are
                                      required{" "}
                                    </span>
                                  )}
                                {!error.hasNoDescription &&
                                  error.hasNoQuestionNo &&
                                  error.hasNoMarks && (
                                    <span className="pl-1">
                                      Question number and marks are required{" "}
                                    </span>
                                  )}
                                {!error.hasNoDescription &&
                                  !error.hasNoQuestionNo &&
                                  error.hasNoMarks && (
                                    <span className="pl-1">
                                      Marks is required or equal to zero{" "}
                                    </span>
                                  )}
                                {!error.hasNoDescription &&
                                  error.hasNoQuestionNo &&
                                  !error.hasNoMarks && (
                                    <span className="pl-1">
                                      Question number is required
                                    </span>
                                  )}
                                {error.hasNoDescription &&
                                  !error.hasNoQuestionNo &&
                                  !error.hasNoMarks && (
                                    <span className="pl-1">
                                      Description is required
                                    </span>
                                  )}
                              </span>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </>
                }
              />
            }

            {!!tempQuestions.length && (
              <div className="col-3">
                <button
                  disabled={loading}
                  style={{ float: "right" }}
                  onClick={() => setConfirmRemoveAll(true)}
                  type="button"
                  className="btn btn-danger btn-sm"
                >
                  <i className="fas fa-trash"></i> Delete All
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-12 mt-2">
          <NoRecordMessage
            isEmpty={!filteredTempQuestions.length && !loading}
            title="No uploaded questions found. "
          />
        </div>
        <div className="col-lg-12 mt-3">
          {!!filteredTempQuestions.length && (
            <table className="table table-striped table-hover table-sm  fixTableHead">
              <thead>
                <tr style={{ backgroundColor: "#f0f2f5" }}>
                  <th scope="col">#</th>

                  <th scope="col" className="text-left">
                    Description
                  </th>

                  <th scope="col" className="text-left">
                    Assertion
                  </th>

                  <th scope="col" className="text-left">
                    Question Type
                  </th>
                  <th scope="col" className="text-center">
                    Marks
                  </th>

                  <th scope="col" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>

              <tbody>
                {filteredTempQuestions.map((tempQuestion, index) => (
                  <WrittenExamQuestionCard
                    exam={tempQuestion}
                    index={index}
                    key={index}
                    setShowEditQuestion={setShowEditQuestion}
                    setSelectedQuestion={setSelectedQuestion}
                  />
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      {isConfirming && (
        <ActionDialog showAction={isConfirming} action="Confirming ..." />
      )}

      {confirmRemoveAll && (
        <ConfirmationDialog
          confirmationDialog={confirmRemoveAll}
          message={`Are you sure you want to remove all questions?`}
          setConfirmationDialog={setConfirmRemoveAll}
          onYes={() => {
            deleteAllTempQuestions(exam.id, setConfirmRemoveAll);
          }}
        />
      )}

      {showUploadExamForm && (
        <UploadExamDialog
          exam={exam}
          showUploadExamForm={showUploadExamForm}
          setShowUploadExamForm={setShowUploadExamForm}
          selectedAdvertisement={selectedAdvertisement}
        />
      )}

      {showEditQuestion && (
        <EditUploadedExamDialog
          question={selectedQuestion}
          showEditQuestion={showEditQuestion}
          setShowEditQuestion={setShowEditQuestion}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ loading, tempQuestions }) => {
  return {
    loading,
    tempQuestions,
  };
};
export default connect(mapStateToProps, {
  getTempQuestions,
  deleteAllTempQuestions,
  deleteTempQuestion,
  confirmUploadedExam,
})(TempExamQuestion);
