import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import AddInvigilator from "./AddInvigilator";
import {
  getInvigilators,
  removeInvigilator,
} from "../../../store/recruitment/actions";
import NoRecordMessage from "../../common/components/NoRecordMessage";
import PreLoader from "../../common/components/PreLoader";
import RemoveIcon from "@mui/icons-material/Remove";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";
import moment from "moment";
import { useRequiredAuth } from "../../common/guard/requiredAuth";
import { doesExamEnded } from "../../common/components/Utils";
import { showError } from "../../toastify";
import SearchBox from "../../common/components/SearchBox";
const InactiveButton = {
  float: "left",
  borderRadius: "8px",
  height: "40px",
  border: `1px solid rgb(7, 142, 206)`,
  display: "flex",
  color: "rgb(7, 142, 206)",
  alignItems: "left",
  fontSize: ".75rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "rgb(7, 142, 206)!important",
  },
};

const Invigilator = (props) => {
  const {
    loading,
    showInvigilators,
    setShowInvigilators,
    selectedExam,
    invigilators,
    getInvigilators,
    removeInvigilator,
  } = props;
  const { hasPermissions } = useRequiredAuth();
  const [searchTeam, setSearchTeam] = useState("");
  const [showAddInvigilator, setShowInvigilator] = useState(false);
  const [selectedInvigilator, setSelectedInvigilator] = useState(null);
  const [invigilatorId, setInvigilatorId] = useState("0");

  const [filteredInvigilators, setFilteredInvigilators] = useState([
    ...invigilators,
  ]);

  const [confirmRemoveInvigilator, setConfirmRemoveInvigilator] =
    useState(false);
  const onClose = () => {
    setShowInvigilators(false);
  };
  const onSearch = (term) => {
    setSearchTeam(term);
  };
  useEffect(() => {
    const filtered = invigilators.filter(
      ({ user, examCenter }) =>
        user?.firstName?.toLowerCase().includes(searchTeam.toLowerCase()) ||
        user?.lastName?.toLowerCase().includes(searchTeam.toLowerCase()) ||
        user?.phoneNumber?.toLowerCase().includes(searchTeam.toLowerCase()) ||
        user?.email?.toLowerCase().includes(searchTeam.toLowerCase()) ||
        examCenter?.center?.name
          ?.toLowerCase()
          .includes(searchTeam.toLowerCase())
    );

    setFilteredInvigilators(filtered);
  }, [searchTeam, invigilators]);
  useEffect(() => {
    getInvigilators(selectedExam.id);
  }, [selectedExam]);

  return (
    <>
      <Dialog open={showInvigilators} fullWidth maxWidth="md">
        <DialogTitle>
          <Typography variant="h6" noWrap component="div">
            {showAddInvigilator ? (
              <span style={{ color: "rgb(7, 142, 206)" }}>Add invigilator</span>
            ) : (
              <>
                <span style={{ color: "rgb(7, 142, 206)" }}>
                  Invigilators({invigilators.length})
                </span>
                {/* <span className="ml-2">
                  @
                  {selectedExam.advertisement
                    ? selectedExam.advertisement.requisition.position.name
                    : ""}
                </span> */}
              </>
            )}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              height: "500px",
            }}
          >
            {showAddInvigilator ? (
              <>
                <AddInvigilator
                  selectedExam={selectedExam}
                  setShowInvigilator={setShowInvigilator}
                />
              </>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <SearchBox
                    disabled={loading || !invigilators.length}
                    onSearch={onSearch}
                    placeholder="Enter name, email or phone number…"
                  />
                  <Button
                    className="ml-2"
                    onClick={() => {
                      if (hasPermissions(["IS_CBM", "IS_HR"])) {
                        // if (doesExamEnded(selectedExam)) {
                        //   showError("Exam Ended");
                        //   return false;
                        // }

                        if (selectedExam.wasExamMarkingPublished) {
                          showError("Exam Published");
                          return false;
                        }
                        setShowInvigilator(true);
                      } else {
                        showError("Make sure that you are HR or CBM!");
                      }
                    }}
                    style={{
                      ...InactiveButton,
                      // padding: theme.spacing(1.5, 1.5, 1.5, 1.5),
                      float: "right",
                    }}
                    size="md"
                  >
                    <span className="material-icons">add</span> Add
                  </Button>
                </div>
                <div className="mt-3">
                  <PreLoader isLoading={loading && !invigilators.length} />

                  <NoRecordMessage
                    isEmpty={!loading && !filteredInvigilators.length}
                    title={"No Invigilators found!"}
                  />

                  {!loading && !!filteredInvigilators.length && (
                    <div className="mt-3">
                      <table className="table table-striped table-hover table-sm  fixTableHead">
                        <thead>
                          <tr style={{ backgroundColor: "#f0f2f5" }}>
                            <th scope="col" className="text-left">
                              Names
                            </th>
                            <th scope="col" className="text-left">
                              Email
                            </th>
                            <th scope="col" className="text-left">
                              PhoneNumber
                            </th>
                            <th scope="col" className="text-left">
                              IDNumber
                            </th>
                            <th scope="col" className="text-left">
                              Center
                            </th>

                            <th scope="col" className="text-left">
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredInvigilators.map((invigilator, index) => (
                            <tr key={index}>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {invigilator.user.lastName}{" "}
                                  {invigilator.user.firstName}
                                </span>
                                {!!invigilator.isExaminer && (
                                  <span className="px-3 badge badge-info float-right">
                                    {" "}
                                    Examiner
                                  </span>
                                )}
                              </td>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {" "}
                                  {invigilator.user.email}
                                </span>
                              </td>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {invigilator.user.phoneNumber}
                                </span>
                              </td>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {invigilator.user.idNumber}
                                </span>
                              </td>
                              <td className="text-left">
                                <span style={{ fontSize: "10px" }}>
                                  {invigilator?.examCenter?.center?.name}
                                </span>
                              </td>

                              <td className="text-left">
                                {" "}
                                <button
                                  disabled={
                                    invigilatorId === invigilator.id ||
                                    !hasPermissions(["IS_CBM", "IS_HR"]) ||
                                    selectedExam.wasExamMarkingPublished
                                  }
                                  onClick={() => {
                                    setSelectedInvigilator(invigilator);
                                    setConfirmRemoveInvigilator(true);
                                  }}
                                  className="btn btn-danger"
                                >
                                  {invigilatorId === invigilator.id ? (
                                    <>
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </>
                                  ) : (
                                    <RemoveIcon />
                                  )}
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                {confirmRemoveInvigilator && (
                  <ConfirmationDialog
                    confirmationDialog={confirmRemoveInvigilator}
                    message={`Are you sure you want to remove @${selectedInvigilator.user.firstName} ${selectedInvigilator.user.lastName} from Invigilators?`}
                    setConfirmationDialog={setConfirmRemoveInvigilator}
                    onYes={() => {
                      removeInvigilator(
                        selectedInvigilator,
                        setInvigilatorId,
                        setConfirmRemoveInvigilator,
                        setSelectedInvigilator
                      );
                    }}
                  />
                )}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, invigilators }) => {
  return {
    loading,
    invigilators,
  };
};
export default connect(mapStateToProps, {
  getInvigilators,
  removeInvigilator,
})(Invigilator);
